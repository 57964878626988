/* eslint-disable no-console */
/* eslint-disable react/no-multi-comp */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react/prop-types */
import React from "react";
import cloneDeep from "lodash/cloneDeep";

export const DisplayProps = data => (
  <div style={{ margin: "1rem 0" }}>
    <pre
      style={{
        background: "#f6f8fa",
        color: "#000",
        fontSize: ".65rem",
        padding: ".5rem"
      }}
    >
      <strong>state:</strong> {JSON.stringify(data, null, 2)}
    </pre>
  </div>
);

export function HelpText() {
  return (
    <div>
      <h1>How to reproduce?</h1>
      <ol>
        <li>Open console</li>
        <li>Fill field and click "Submit". You will see success message</li>
      </ol>
    </div>
  );
}

// Async Validation
export const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));
// How to use sleep?
// sleep(300).then(() => {
// console.log("Timeout started");
// });

// TODO: Need to test
export function objectToQueryString(params) {
  // const queryString = new URLSearchParams(window.location.search);
  const queryString = Object.keys(params)
    .map(key => key + "=" + params[key])
    .join("&");
  // Extracting query String key value pairs
  for (const param of queryString.entries()) {
    console.log(param);
  }
}

// TODO: Need to test
export const objectToQueryStringNew = initialObj => {
  const reducer =
    (obj, parentPrefix = null) =>
    (prev, key) => {
      const val = obj[key];
      const keyTo = encodeURIComponent(key);
      const prefix = parentPrefix ? `${parentPrefix}[${key}]` : keyTo;

      if (val == null || typeof val === "function") {
        prev.push(`${prefix}=`);
        return prev;
      }

      if (["number", "boolean", "string"].includes(typeof val)) {
        prev.push(`${prefix}=${encodeURIComponent(val)}`);
        return prev;
      }

      prev.push(Object.keys(val).reduce(reducer(val, prefix), []).join("&"));
      return prev;
    };

  return Object.keys(initialObj).reduce(reducer(initialObj), []).join("&");
};
// -> Input
// objectToQueryString({
//   name: "John Doe",
//   age: 20,
//   children: [{ name: "Foo Doe" }, { name: "Bar Doe" }],
//   wife: {
//     name: "Jane Doe"
//   }
// });
// output -> name=John%20Doe&age=20&children[0]

// This method will eliminate properties from given object/array
export const stripPropertiesFromObjectArray = (objArr, propArr) => {
  const objArrayCopy = cloneDeep(objArr);
  const newObjArr = objArrayCopy.map(obj => {
    const keys = Object.keys(obj);
    keys.forEach(key => {
      if (propArr.includes(key)) {
        delete obj[key];
      }
    });
    return obj;
  });
  return newObjArr;
};

const generateRandomIntegerInRange = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const generatePartId = () => {
  return generateRandomIntegerInRange(100, 9999);
};
