import isNaN from "lodash/isNaN";
import moment from "moment";

// @todo: check if this function is used anywhere in the app and delete if not.
const convertMinutesToHours = minutes => +(+minutes / 60).toFixed(2);

/**
 * Formats the original labor time value to the correct number format.
 * If the value provided is either not parseable or not a number, it returns the provided fallback value instead.
 * @param {string|number} val Original time value. It can be a string or a number.
 * @param {number} fallback Amount to return if the value is not parseable.
 * @returns {number}
 */
function formatLaborTime(val, fallback) {
  const valueType = typeof val;
  if (valueType === "string") {
    const parsedVal = parseFloat(val);
    return isNaN(parsedVal)
      ? fallback
      : Math.round((parsedVal + Number.EPSILON) * 100) / 100;
  } else if (valueType === "number" && !isNaN(val)) {
    return Math.round((val + Number.EPSILON) * 100) / 100;
  } else {
    return fallback;
  }
}

function getNoOfDays(startDate, endDate) {
  if (startDate === endDate) return 1;
  if (startDate && endDate) {
    // To set two dates to two variables
    const date1 = new Date(startDate);
    const date2 = new Date(endDate);

    // To calculate the time difference of two dates
    const differenceInTime = date2.getTime() - date1.getTime();

    // To calculate the no. of days between two dates
    const differenceInDays = differenceInTime / (1000 * 3600 * 24);

    return differenceInDays;
  } else return 0;
}

function convertMinutesToHM(minutes) {
  console.log("convertMinutesToHM", minutes);
  const duration = moment.duration(minutes, "minutes");
  const hours = Math.floor(duration.asHours());
  const mins = String(duration.minutes()).padStart(2, "0");
  return `${hours}:${mins}`;
}

/**
 * Formats the dateTime value to the 'hh:mm am/pm' format.
 * If the value provided is either not parseable or not a number, it returns the provided fallback value instead.
 * @param {Date | string} dateTime its a dateTime value
 * @param {string} timeZone its timeZone for now local timezone is passed to it
 * @returns {string}
 */
const convertDateTimeToTimeFormat = (dateTime, timeZone) => {
  // Convert the input to a Date object
  const date = new Date(dateTime);

  // Format the time to 'hh:mm AM/PM' based on the specified timezone
  const formattedTime = new Intl.DateTimeFormat("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
    timeZone // Use the passed timezone
  }).format(date);

  return formattedTime;
};

/**
 * Update the isoDate with new time and modifier in UTC timezone YYYY-MM-DDTHH:MM:SSZ.
 * @param {string} isoDate its a dateTime value
 * @param {string} time its a time value "hh:mm"
 * @param {string} modifier its a string value "am/pm"
 * @returns {string}
 */
// Helper function to merge date from workStarted/workEnded with time from input in UTC format always
function parseTimeWithDate(isoDate, time, modifier) {
  const date = new Date(isoDate); // Create a date object from the ISO string
  let [hours, minutes] = time.split(":");
  hours = hours.length === 0 ? "00" : hours.length === 1 ? "0" + hours : hours;
  minutes =
    minutes?.length === 0 || minutes?.length === undefined
      ? "00"
      : minutes?.length === 1
      ? minutes + "0"
      : minutes;

  // Convert hours to 24-hour format based on AM/PM
  if (modifier.toLowerCase() === "pm" && hours !== "12") {
    hours = parseInt(hours, 10) + 12;
  } else if (modifier.toLowerCase() === "am" && hours === "12") {
    hours = 0;
  }

  // Set hours and minutes for the date in the local timezone
  date.setHours(parseInt(hours, 10));
  date.setMinutes(parseInt(minutes, 10));
  date.setSeconds(0); // Optionally reset seconds to 0

  // Convert the date to the specified timezone
  const options = {
    timeZone: "UTC",
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: false
  };

  const formatter = new Intl.DateTimeFormat([], options);
  const parts = formatter.formatToParts(date);
  // Construct the UTC date string
  const formattedDate = `${parts.find(p => p.type === "year").value}-${
    parts.find(p => p.type === "month").value
  }-${parts.find(p => p.type === "day").value}T${
    parts.find(p => p.type === "hour").value
  }:${parts.find(p => p.type === "minute").value}:${
    parts.find(p => p.type === "second").value
  }Z`;
  return formattedDate;
}

// Regex pattern to match the time in {1 to 12}:{00 to 59} format
const timePattern = /^(0?[0-9]|1[0-2]):([0-5][0-9])$/;

// Allow only digits and colon
const allowedKeys = [
  "0",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  ":",
  "Backspace",
  "ArrowLeft",
  "ArrowRight",
  "Delete",
  "Tab"
];

/**
 * Return if workStarted is before workEnded or not
 * @param {string} workStarted its a dateTime value
 * @param {string} workEnded its a dateTime value
 * @returns {boolean}
 */
function isWorkStartedBeforeWorkEnded(workStarted, workEnded) {
  // Create Date objects from the ISO strings
  const start = new Date(workStarted);
  const end = new Date(workEnded);

  // Compare the two dates
  return start < end;
}

//* return browser locale timezone
function getLocalTimezone() {
  // eslint-disable-next-line new-cap
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

export {
  convertMinutesToHours,
  formatLaborTime,
  getNoOfDays,
  convertMinutesToHM,
  convertDateTimeToTimeFormat,
  parseTimeWithDate,
  timePattern,
  allowedKeys,
  isWorkStartedBeforeWorkEnded,
  getLocalTimezone
};
