/* eslint-disable */
import React from "react";
import isEmpty from "lodash/isEmpty";
import cloneDeep from "lodash/cloneDeep";
import PropTypes from "prop-types";
import { freezeObject } from "../utils/helper.util";
import { serviceTypes, priceSourceLabels } from "../constants/parts.constants";
import {
  resetDmsPending,
  transformPartsWithDMSParts
} from "../utils/parts.util";

let defaultState = {
  appType: "SQ",
  isPartsView: false,
  debugMode: false,
  userPermissions: {},
  make: "",
  dealerProperties: {},
  dealerMakes: [],
  rawOperationDetails: null,
  selectedVehicleAttrs: null,
  lookupPartsList: [], // first grid to manage selected field state in list
  serviceParts: [], // second grid -> update list from first grid selections/delete part; -> will add part to first grid with selected = false
  individualPartRecord: null,
  payTypeCode: "", // read paytype selected from labor component
  menuServiceOriginalParts: [],
  initialServiceParts: [],
  apiCallPending: false
};

defaultState = freezeObject(defaultState);

const PartsLookupContext = React.createContext(defaultState);

const Actions = {
  SET_APP_TYPE: "SET_APP_TYPE",
  SET_IS_PARTS_VIEW: "SET_IS_PARTS_VIEW",
  SET_RAWOPERATION_DETAILS: "SET_RAWOPERATION_DETAILS",
  SET_LOOKUP_PARTS_LIST: "SET_LOOKUP_PARTS_LIST",
  SET_SERVICE_PARTS: "SET_SERVICE_PARTS",
  SET_SERVICE_PART_PRICE: "SET_SERVICE_PART_PRICE",
  SET_SERVICE_PART_QTY: "SET_SERVICE_PART_QTY",
  SET_SERVICE_PART_NAME: "SET_SERVICE_PART_NAME",
  SET_SERVICE_PART_TYPE: "SET_SERVICE_PART_TYPE",
  SET_SERVICE_PART_UNIT_COST_PRICE: "SET_SERVICE_PART_UNIT_COST_PRICE",
  SET_SERVICE_TYPE: "SET_SERVICE_TYPE",
  SET_PAYTYPE_CODE: "SET_PAYTYPE_CODE",
  REMOVE_SERVICE_PART: "REMOVE_SERVICE_PART",
  SET_MAKE: "SET_MAKE",
  SET_DEALER_PROPERTIES: "SET_DEALER_PROPERTIES",
  SET_DEALER_MAKES: "SET_DEALER_MAKES",
  SET_CUSTOM_PART_RECORD: "SET_CUSTOM_PART_RECORD",
  SET_DEBUG_MODE: "SET_DEBUG_MODE",
  SET_USER_PERMISSIONS: "SET_USER_PERMISSIONS",
  SET_MENU_SERVICE_ORIGINAL_PARTS: "SET_MENU_SERVICE_ORIGINAL_PARTS",
  UPDATE_MENU_SERVICE_ORIGINAL_PARTS: "UPDATE_MENU_SERVICE_ORIGINAL_PARTS",
  SET_INITIAL_SERVICE_PARTS: "SET_INITIAL_SERVICE_PARTS",
  SET_TECH_VIEW: "SET_TECH_VIEW",
  SET_MANAGER_VIEW: "SET_MANAGER_VIEW",
  SET_API_CALL_PENDING: "SET_API_CALL_PENDING"
};

const partsLookupReducer = (state, action) => {
  switch (action.type) {
    // * flag for checking if erp call for fetching parts
    case Actions.SET_API_CALL_PENDING: {
      return {
        ...state,
        apiCallPending: action.payload
      };
    }
    case Actions.SET_DEBUG_MODE: {
      return {
        ...state,
        debugMode: action.payload
      };
    }
    case Actions.SET_USER_PERMISSIONS: {
      return {
        ...state,
        userPermissions: action.payload
      };
    }
    case Actions.SET_MAKE: {
      return {
        ...state,
        make: action.payload
      };
    }
    case Actions.SET_DEALER_PROPERTIES: {
      return {
        ...state,
        dealerProperties: action.payload
      };
    }
    case Actions.SET_DEALER_MAKES: {
      return {
        ...state,
        dealerMakes: action.payload
      };
    }
    case Actions.SET_RAWOPERATION_DETAILS: {
      return {
        ...state,
        rawOperationDetails: action.payload
      };
    }
    case Actions.SET_SELECTED_VEHICLE_ATTRS: {
      return {
        ...state,
        selectedVehicleAttrs: action.payload
      };
    }
    case Actions.SET_LOOKUP_PARTS_LIST: {
      const parts = action.payload;
      const lookupParts = !isEmpty(parts) ? parts : [];
      // console.log("SET_LOOKUP_PARTS_LIST", lookupParts);
      return {
        ...state,
        lookupPartsList: lookupParts
      };
    }
    case Actions.SET_SERVICE_PARTS: {
      const serviceParts = cloneDeep(action.payload);
      if (state.isPartsView) {
        resetDmsPending(serviceParts, false);
      }
      return {
        ...state,
        serviceParts: [...serviceParts]
      };
    }
    case Actions.SET_SERVICE_PART_TYPE: {
      const { purchaseType, rowId } = action.payload;
      const filteredParts = state.serviceParts.filter(
        part => part.rowId !== rowId
      );
      const updatedPart = state.serviceParts.find(part => part.rowId === rowId);
      const updatedPartIndex = state.serviceParts.findIndex(
        part => part.rowId === rowId
      );
      updatedPart.purchaseType = purchaseType;
      if (state.serviceType === serviceTypes.MENU) {
        updatedPart.purchaseType = purchaseType;
      }
      const newParts = [
        ...filteredParts?.slice(0, updatedPartIndex),
        updatedPart,
        ...filteredParts?.slice(updatedPartIndex)
      ];
      return {
        ...state,
        serviceParts: newParts
      };
    }
    // Note: This action used to rely on partId. Now it uses unique rowId coming from API.
    case Actions.REMOVE_SERVICE_PART: {
      const removedPart = action.payload;
      const newParts = state.serviceParts.filter(
        part => part.rowId !== removedPart.rowId
      );
      return {
        ...state,
        serviceParts: [...newParts]
      };
    }
    // Note: This action used to rely on partId. Now it uses unique rowId coming from API.
    case Actions.SET_SERVICE_PART_PRICE: {
      const { value, rowId } = action.payload;
      const filteredParts = state.serviceParts.filter(
        part => part.rowId !== rowId
      );
      const updatedPart = state.serviceParts.find(part => part.rowId === rowId);
      const updatedPartIndex = state.serviceParts.findIndex(
        part => part.rowId === rowId
      );
      updatedPart.partPriceSource = priceSourceLabels.MANUAL;
      updatedPart.dmsPrice = null;
      updatedPart.unitPrice = Number(value);
      updatedPart.partsPrice = updatedPart.unitPrice * updatedPart.quantity;
      // * making this change to retain order of parts on update
      const newParts = [
        ...filteredParts?.slice(0, updatedPartIndex),
        updatedPart,
        ...filteredParts?.slice(updatedPartIndex)
      ];
      return {
        ...state,
        serviceParts: newParts
      };
    }
    // Note: This action used to rely on partId. Now it uses unique rowId coming from API.
    case Actions.SET_SERVICE_PART_QTY: {
      const { qty, rowId } = action.payload;
      // console.log("SET_SERVICE_PART_QTY params", action.payload);
      const filteredParts = state.serviceParts.filter(
        part => part.rowId !== rowId
      );
      const updatedPart = state.serviceParts.find(part => part.rowId === rowId);
      const updatedPartIndex = state.serviceParts.findIndex(
        part => part.rowId === rowId
      );
      updatedPart.quantity = Number(qty);
      updatedPart.partsPrice = updatedPart.quantity * updatedPart.unitPrice;
      // this logic is common for all services & menu
      updatedPart.adjustedQuantity = Number(qty);
      // * making this change to retain order of parts on update
      const newParts = [
        ...filteredParts?.slice(0, updatedPartIndex),
        updatedPart,
        ...filteredParts?.slice(updatedPartIndex)
      ];
      // console.log("newParts, updatedPart", newParts, updatedPart);
      return {
        ...state,
        serviceParts: newParts
      };
    }
    // Note: This action used to rely on partId. Now it uses unique rowId coming from API.
    case Actions.SET_SERVICE_PART_NAME: {
      // TODO: revisit to see if we can simplify this logic
      const { name, rowId } = action.payload;
      const filteredParts = state.serviceParts.filter(
        part => part.rowId !== rowId
      );
      const updatedPart = state.serviceParts.find(part => part.rowId === rowId);
      const updatedPartIndex = state.serviceParts.findIndex(
        part => part.rowId === rowId
      );
      updatedPart.partName = name;
      if (state.serviceType === serviceTypes.MENU) {
        updatedPart.description = name;
      }
      // * making this change to retain order of parts on update
      const newParts = [
        ...filteredParts?.slice(0, updatedPartIndex),
        updatedPart,
        ...filteredParts?.slice(updatedPartIndex)
      ];
      return {
        ...state,
        serviceParts: newParts
      };
    }
    // Note: This action used to rely on partId. Now it uses unique rowId coming from API.
    case Actions.SET_SERVICE_PART_UNIT_COST_PRICE: {
      //* Cost price is now editable from parts dashboard
      const { unitCost, rowId } = action.payload;
      const filteredParts = state.serviceParts.filter(
        part => part.rowId !== rowId
      );
      const updatedPart = state.serviceParts.find(part => part.rowId === rowId);
      const updatedPartIndex = state.serviceParts.findIndex(
        part => part.rowId === rowId
      );
      if (unitCost !== updatedPart?.costPrice) {
        updatedPart.unitCostOverride = true;
        updatedPart.unitCost = unitCost;
      } else {
        updatedPart.unitCost = unitCost;
        updatedPart.unitCostOverride = false;
      }
      // * making this change to retain order of parts on update
      const newParts = [
        ...filteredParts?.slice(0, updatedPartIndex),
        updatedPart,
        ...filteredParts?.slice(updatedPartIndex)
      ];
      return {
        ...state,
        serviceParts: newParts
      };
    }
    case Actions.SET_SERVICE_TYPE: {
      return {
        ...state,
        serviceType: action.payload
      };
    }
    case Actions.SET_CUSTOM_PART_RECORD: {
      return {
        ...state,
        individualPartRecord: action.payload
      };
    }
    case Actions.SET_MENU_SERVICE_ORIGINAL_PARTS: {
      return {
        ...state,
        menuServiceOriginalParts: action.payload
      };
    }
    case Actions.UPDATE_MENU_SERVICE_ORIGINAL_PARTS: {
      const { partsPricingAndInventory } = action.payload;
      const newOriginalParts = transformPartsWithDMSParts(
        partsPricingAndInventory,
        state.menuServiceOriginalParts,
        state.serviceType
      );
      return {
        ...state,
        menuServiceOriginalParts: newOriginalParts
      };
    }
    case Actions.SET_PAYTYPE_CODE: {
      return {
        ...state,
        payTypeCode: action.payload
      };
    }
    case Actions.SET_INITIAL_SERVICE_PARTS: {
      const initialServiceParts = cloneDeep(action.payload);
      return {
        ...state,
        initialServiceParts: [...initialServiceParts]
      };
    }
    case Actions.SET_APP_TYPE: {
      return {
        ...state,
        appType: action.payload
      };
    }
    // @csr-logic
    case Actions.SET_IS_PARTS_VIEW: {
      return {
        ...state,
        isPartsView: action.payload
      };
    }
    default: {
      console.log(`Unhandled action type: ${action.type}`);
    }
  }
};

const PartsLookupProvider = ({ children, ctxGtmEvent }) => {
  const [state, dispatch] = React.useReducer(partsLookupReducer, defaultState);
  const value = { state, dispatch, ctxGtmEvent };
  return (
    <PartsLookupContext.Provider value={value}>
      {children}
    </PartsLookupContext.Provider>
  );
};

PartsLookupProvider.propTypes = {
  children: PropTypes.node.isRequired
};

const usePartsLookupContext = () => {
  const context = React.useContext(PartsLookupContext);
  if (context === undefined) {
    throw new Error(
      "usePartsLookupContext must be used within a PartsLookupProvider"
    );
  }
  return {
    state: context.state,
    dispatch: context.dispatch,
    ctxGtmEvent: context.ctxGtmEvent
  };
};

export {
  PartsLookupProvider,
  usePartsLookupContext,
  Actions,
  PartsLookupContext
};
