/* eslint-disable no-console */
import isNull from "lodash/isNull";
import * as gtag from "./gtag-manager.util";
import { gtmEventData } from "./gtag-event.data";
import { doesEmpty, isEmptyObject } from "../../../utils/object";

/* 
Call this method in the page to capture any click events 
Example: 
trackGAEvent("ga.dashboard.copy_quote_click");
*/
export function trackGAEvent(key) {
  const tagObj = gtmEventData[key];
  tagObj.event = "productEvent";
  gtag.trackEvent(tagObj);
  printEvent(key);
}

export function trackGAEventWithParam(key, { result = "", location = "" }) {
  const eventObj = gtmEventData[key];
  eventObj.event = "productEvent";
  const { eventProperties } = eventObj;
  eventProperties.result = result;
  eventProperties.location = location || eventProperties.location;
  gtag.trackEvent(eventObj);
  printEvent(key);
}

export function printEvent(key) {
  console.log("GA Event", gtmEventData[key]);
}

/**
 * Method used to track sub-pages within SPA using GA4 eventName "virtualPageview"
 * hits are logged in GA4 (under realtime content, behavior/ site content)
 Example: 
 trackGAPageview("pagename");
 */
export function trackGAPageview(pageName) {
  const eventobj = {
    event: "productEvent",
    eventName: "virtualPageview",
    eventProperties: {
      location: pageName
    }
  };
  gtag.trackPage(eventobj);
}

/* 
GA Event - Capture API Http errors 
Example:
  const eventObj = {
    event: "productEvent",
    eventName: "APIError"  // eg: "JSError"
    eventProperties: {
        result: "API error message", // or pass custom message about JSError
        location: "save-service-button" 
      }
 };
*/
export function trackGAError(logObj) {
  // console.error("trackError", logObj);
  const eventobj = {
    event: "productEvent",
    eventName: logObj.eventName,
    eventProperties: {
      result: logObj.message,
      location: logObj.serviceName
    }
  };
  gtag.trackEvent(eventobj);
}

// TODO: Track API Error when API has no data but http status as 200
export function trackGAUnknowError(response, config) {
  if (!isEmptyObject(response) && !doesEmpty(response.errorCode)) {
    const error = response.errorCode;
    const logObj = {
      eventName: "APIError",
      method: config.method,
      statusCode: config.statusCode,
      serviceName: config.url,
      message: !error ? "UNKNOWN Error" : error
    };
    trackGAError(logObj);
  }
}

/* @feature: primary method called in App.js to track service quoting launched from which parent application  */
export function trackLaunchHub(appSource, applicationName) {
  let parentAppName = "";
  if (appSource === "IND") {
    parentAppName = "DealerPortal";
  } else if (appSource === "SCH") {
    parentAppName = "Scheduling";
  }
  if (!isNull(parentAppName)) {
    const params = {
      event: "productEvent",
      eventName: "virtualPageview",
      eventProperties: {
        result: applicationName + " launched from " + parentAppName,
        location: "/dashboard"
      }
    };
    gtag.trackEvent(params);
  }
}
