import util from "./util";
import moment from "moment";

export default class format {
  static #ZERO_DECIMAL_VALUE = "0";
  static #ZERO_CURRENCY_DISPLAY_VALUE = "$0.00";
  static #RELATIVE_DATE_STRING_INVALID_DATE_RESULT = "Invalid Date";

  /**
   * Formats a value as a decimal number.
   * If the value is not parseable as a number, it will be treated as zero.
   * If the value is a whole number, it will get formatted as an integer.
   * If the absolute value is < 1, there will be a leading zero.
   * @param val Value to format.
   * @param precision Max number of digits to keep after the decimal. Defaults to 3.
   * @returns {string} Formatted value.
   */
  static decimal(val, precision) {
    const valueType = typeof val;

    if (["undefined", "null", "object"].includes(valueType)) {
      return this.#ZERO_DECIMAL_VALUE;
    }

    const intlResult = new Intl.NumberFormat("en-US", {
      minimumIntegerDigits: 1,
      minimumFractionDigits: 0,
      maximumFractionDigits: precision === undefined ? 3 : precision
    }).format(val ?? 0);

    if (intlResult === "NaN") {
      return this.#ZERO_DECIMAL_VALUE;
    }

    return intlResult;
  }

  /**
   * Formats a value as a decimal number for setting an input field's value.
   * If the value is not parseable as a number, it will be treated as zero.
   * If the value is a whole number, it will get formatted as an integer.
   * If the absolute value is < 1, there will be a leading zero.
   * @param val Value to format.
   * @param precision Max number of digits to keep after the decimal. Defaults to 3.
   * @returns {string} Formatted value.
   */
  static decimalInput(val, precision) {
    const valueType = typeof val;

    // If the input value is blank, keep it that way.
    if (valueType === "string" && val.trim().length === 0) {
      return "";
    }

    // If the input value is not a string or actual number, leave it empty.
    if (valueType !== "string" && (valueType !== "number" || isNaN(val))) {
      return "";
    }

    return this.decimal(val, precision);
  }

  /**
   * Formats a value as USD currency.
   * If the value is not parseable as a number, it will be treated as zero.
   * @param val Value to format as currency.
   * @returns {string} Formatted currency value.
   */
  static currency(val) {
    let cleanVal = val;
    const valueType = typeof cleanVal;

    if (["undefined", "null", "object"].includes(valueType)) {
      return this.#ZERO_CURRENCY_DISPLAY_VALUE;
    }

    if (valueType === "string") {
      // If the input value is blank, return the zero display text.
      if (cleanVal.trim().length === 0) {
        return this.#ZERO_CURRENCY_DISPLAY_VALUE;
      }
      // Remove any form of USD currency prefix, and any commas or underscores.
      cleanVal = cleanVal.replace(/^(US|USD|US\$|\$)/, "").replace(/[,_]/g, "");
    }

    const intlResult = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD"
    }).format(cleanVal ?? 0);

    if (intlResult === "$NaN") {
      return this.#ZERO_CURRENCY_DISPLAY_VALUE;
    }

    return intlResult;
  }

  /**
   * Formats a value as USD currency, for use as an input field's value.
   * If the value is not parseable as a number, it will be treated as zero.
   * @param val Value to format as currency.
   * @returns {string} Formatted currency value.
   */
  static currencyInput(val) {
    let cleanVal = val;
    const valueType = typeof cleanVal;

    if (valueType === "string") {
      // If the input value is blank, keep it that way.
      if (cleanVal.trim().length === 0) {
        return "";
      }
      // Remove any form of USD currency prefix, and any commas or underscores.
      cleanVal = cleanVal.replace(/^(US|USD|US\$|\$)/, "").replace(/[,_]/g, "");
    }

    // If the input value is not a string or actual number, leave it empty.
    if (valueType !== "string" && (valueType !== "number" || isNaN(cleanVal))) {
      return "";
    }

    return this.currency(cleanVal).replace(/[$]/g, "");
  }

  /**
   * Converts a string to "Sentence case". The first word will be capitalized,
   * as well as certain known acronyms such as "ID" and "VIN".
   * @param text
   * @returns {string}
   */
  static sentenceCase(text) {
    const string = (text ?? "").toString();
    // const matches = (/\b(id|url|vin)\b/gi).exec(string);
    // console.debug(text, string, matches);
    return (
      string.substring(0, 1).toUpperCase() + string.substring(1).toLowerCase()
    ).replace(/\b(id|url|vin)\b/gi, match => match.toUpperCase());
  }

  /**
   * Converts a string to "Pascal case". The first word will be capitalized,
   * and rest will be small eg. "DEALER POLICY" will become "Dealer Policy"
   * and "DEALERPOLICY" will become "Dealerpolicy"
   * @param {string} s
   * @returns {string}
   */
  static toPascalCase(s) {
    return s.replace(/\w+/g, function (w) {
      return w[0].toUpperCase() + w.slice(1).toLowerCase();
    });
  }

  /**
   * Takes date as input and
   * return values like "Today", "Yesterday", "Tomorrow",
   * "{n} days ago", "{n} days from now"
   * @param {string|Date} val takes a date input string or date format
   * @returns {string} relative date as string,
   *   or "Invalid Date" if the input is not a recognizable date
   */
  static relativeDateString(val) {
    const type = util.getType(val);
    const cleanedValue = type === "String" ? val.trim() : val;

    if (["String", "Date"].includes(type)) {
      let date = moment(cleanedValue);
      if (!date.isValid()) {
        // fallback via native Date if moment couldn't parse it
        date = moment(new Date(cleanedValue));
        if (!date.isValid()) {
          return this.#RELATIVE_DATE_STRING_INVALID_DATE_RESULT;
        }
      }

      date = date.startOf("day");
      const today = moment().startOf("day");
      const diff = date.diff(today, "days");

      if (diff === 0) {
        return "Today";
      } else if (diff === 1) {
        return "Tomorrow";
      } else if (diff === -1) {
        return "Yesterday";
      } else if (diff > 1) {
        return `${diff} days from now`;
      } else if (isNaN(diff) || diff === null) {
        return this.#RELATIVE_DATE_STRING_INVALID_DATE_RESULT;
      } else {
        return `${-diff} days ago`;
      }
    } else {
      return this.#RELATIVE_DATE_STRING_INVALID_DATE_RESULT;
    }
  }
}
