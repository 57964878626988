/* eslint-disable no-console */
export function toEmptyStringIfUndefined(value) {
  return value === undefined || value === null ? "" : value.toString();
}

export const isEmptyString = str => {
  return (
    (typeof str === "string" && !str.trim()) ||
    typeof str === "undefined" ||
    str === null
  );
};

export function toNumberZeroIfUndefined(value) {
  return value === undefined || value === null ? "0" : value.toString();
}

export function isDifferentValue(newVal, oldVal) {
  // console.log("Field compare new vs old", newVal, oldVal);
  const newValue = toEmptyStringIfUndefined(newVal);
  const oldValue = toEmptyStringIfUndefined(oldVal);
  return newValue !== oldValue;
}

/* eslint-enable no-console */
