/* eslint-disable no-console */
import React, { useState, useContext } from "react";
import QuoteDetails from "./card-details.component";
// import FormPane from "./components/FormPane";
import GenericSlider from "../../ui/sliders/GenericSlider";
import { AppContext } from "../../../state/app-context";
import isEmpty from "lodash/isEmpty";
// import axios from "axios";

function CardView() {
  const { localeStrings, pastQuotes } = useContext(AppContext);
  const [showSlide, setShowSlide] = useState(false);
  const [flexWidth] = useState(false);
  const [sliderWidth] = useState(300);
  const confirmationId = null;
  // const [quoteRecord, setQuoteRecord] = useState(null);
  // const [pastQuotesData, setPastQuotesData] = useState([]);

  // The useEffect() hook fires any time that the component is rendered.
  // An empty array is passed as the second argument so that the effect only fires once.
  // useEffect(() => {
  //   axios
  //     .get("/data/poc-mockPastQuotes.json")
  //     .then(response => setPastQuotesData(response.data));
  // }, []);

  /*
  const quoteSelected = (quote, name) => {
    console.log("quote selected", name);
    setQuoteId(name);
    setQuoteRecord(quote);
    setShowSlide(!showSlide);
  };
  */

  const hideSlider = () => {
    setShowSlide(false);
  };

  const quoteWidget = null;
  /*
  if (!quoteRecord) {
    quoteWidget = <span>No Quote selected</span>;
  } else {
    quoteWidget = (
      <div className="">
        <div className="past-box">
          <div className="past-info">
            <b>Confirmation: {quoteRecord.confirmationId}</b>
            <span>{quoteRecord.items}</span>
            <span>{quoteRecord.createdBy}</span>
            <div>
              Status:
              {quoteRecord.statusCode}
            </div>
          </div>
        </div>
        <br />
        <FormPane quote={quoteRecord} />
      </div>
    );
  }
  */

  let sliderView = (
    <GenericSlider
      title={confirmationId}
      htmlId="gridSlider"
      toggleSlider={hideSlider}
      showSlide={showSlide}
      sliderWidth={sliderWidth}
      flexWidth={flexWidth}
    >
      {quoteWidget}
    </GenericSlider>
  );
  sliderView = null;
  return (
    <>
      <h3>{localeStrings["sq.nav.past_quotes_lbl"]}</h3>
      {!isEmpty(pastQuotes) ? (
        <div id="pastQuotesGrid" className="past-grid">
          {pastQuotes.map(function (quote, index) {
            const keycode = "key-" + index.toString();
            return (
              <QuoteDetails
                key={keycode}
                quote={quote}
                // clicked={() => quoteSelected(quote, quote.name)}
              />
            );
          })}
        </div>
      ) : (
        <div> No records found </div>
      )}

      {sliderView}
    </>
  );
}

export default CardView;
/* eslint-enable no-console */
