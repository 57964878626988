import Button from "@cx/ui/Button";
import TextInput from "@cx/ui/TextInput";
import React, { useState } from "react";

const PaymentNotes = ({ currentPaymentLine, onPaymentNoteChange }) => {
  const [isNoteButtonClick, setNoteButtonClick] = useState(false);
  return currentPaymentLine?.notes === null && !isNoteButtonClick ? (
    <Button
      buttonStyle="link"
      htmlId="btnLink"
      onClick={() => setNoteButtonClick(true)}
      className="split-payment-notes-button"
    >
      Note
    </Button>
  ) : (
    <div>
      <div className="service-customer-split-payment-notes-content">
        <div>
          <span className="split-label-color">Note</span>
        </div>
        <span className="split-character-string split-label-color">
          Character count: {currentPaymentLine?.notes?.length || 0} /40
        </span>
      </div>
      <TextInput
        htmlId="CauseInput"
        className="m-r-lg"
        label="Note"
        name="notes"
        autoFocus
        value={currentPaymentLine?.notes || ""}
        displayLabel={false}
        rows={1}
        onChange={onPaymentNoteChange}
        maxLength={40}
      />
    </div>
  );
};
export default PaymentNotes;
