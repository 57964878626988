/* eslint-disable no-console */
import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { AppContext } from "../../../../state/app-context";
import FeedbackTool from "../../../feedback-tool";
import { redirectToCSRPage } from "../../../../api/app.util";
import { appSources, appTypes } from "../../../../constants/app.constants";
import "./page-404.scss";
const NotFound = () => {
  const history = useHistory();
  const appContext = useContext(AppContext);
  const { appSource } = appContext;
  const [showFeedback, setShowFeedback] = useState(false);
  // !deprecated const isNotProd = !["stg4", "prod"].includes(appEnv);
  // @note - This handler will decide landing page newquote or dashboard based on appSource
  const backToHome = () => {
    // @csr-logic
    const { quote, appType } = appContext;
    const csrPage = redirectToCSRPage(appSource, appType, quote);
    const standalonePage = !csrPage ? "/dashboard" : csrPage;
    let path = appSource === appSources.IND ? standalonePage : "/newquote";
    if (appSource !== appSources.IND) {
      path = appType === appTypes.CSR ? "/rodetails" : "/newquote";
    }
    history.push(`${path}${window.location.search}`);
  };

  const openFeedbackModal = () => {
    setShowFeedback(true);
  };

  const closeFeedbackModal = () => {
    setShowFeedback(false);
  };
  const okFeedbackAction = () => {
    console.log("Feedback modal ok action triggered");
  };
  let feedbackModal = null;
  if (showFeedback) {
    feedbackModal = (
      <FeedbackTool
        screenName="Service Quoting"
        show={showFeedback}
        okAction={okFeedbackAction}
        closeModal={closeFeedbackModal}
      />
    );
  }

  const bodyHtml = (
    <div className="content_box_404">
      <h2> Sorry, the page you were looking for was not available.</h2>

      <p>The address may have been typed incorrectly.</p>
      <p>It may be a broken or outdated link.</p>

      <span className="link_404" onClick={backToHome}>
        GO TO HOME
      </span>
      <span className="link_404" onClick={openFeedbackModal}>
        Report a Problem
      </span>
    </div>
  );

  return (
    <section className="page_404">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 ">
            <div className="col-sm-10 col-sm-offset-1  text-center">
              <div className="four_zero_four_bg">{bodyHtml}</div>
            </div>
          </div>
        </div>
      </div>
      {feedbackModal}
    </section>
  );
};

export default NotFound;
