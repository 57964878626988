import isEmpty from "lodash/isEmpty";
import { toEmptyStringIfUndefined } from "./string.util";
// Write all Text Formatters
export function formatPhoneNumber(input, allowSeven = false) {
  let phone = "";
  if (!input || isNaN(input)) return "";
  phone = input.toString();
  if (phone.length === 10) {
    phone = phone.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
    return phone;
  }
  if (phone.length === 7 && allowSeven) {
    phone = phone.replace(/(\d{3})(\d{4})/, "$1-$2");
    return phone;
  }
  return "";
}

/**
 *
 * @param {string} as "service quoting is reusable application"
 * @returns {string} as "Service Quoting Is Reusable Application"
 */
export function toMixedCase(str) {
  if (!str || str === "") {
    return "";
  }
  const words = str.split(" ");
  const mixedCaseWords = words.map(word => {
    const lowerCase = word.toLowerCase();
    const mixedCase = lowerCase.charAt(0).toUpperCase() + lowerCase.slice(1);
    return mixedCase;
  });
  const finalWord = mixedCaseWords.join(" ");
  return finalWord;
}

/**
 *
 * @param {number} number
 * @returns {string}
 */
export function formatNumberWithThousands(number) {
  return !number ? "" : number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

/**
 *
 * @param {string} value
 * @returns {string}
 */
export function upperCaseFirstLetter(value) {
  if (typeof value === "string") {
    return value.charAt(0).toUpperCase() + value.slice(1);
  }
  return value;
}

export function formatPhoneNumberWithTypeLabel(phoneNumbers) {
  let formattedPhoneWithLabel = "";

  if (!isEmpty(phoneNumbers.home)) {
    formattedPhoneWithLabel = `${formatPhoneNumber(
      phoneNumbers.home,
      true
    )} (Home)`;
  } else if (!isEmpty(phoneNumbers.mobile)) {
    formattedPhoneWithLabel = `${formatPhoneNumber(
      phoneNumbers.mobile
    )} (Mobile)`;
  } else if (!isEmpty(phoneNumbers.work)) {
    formattedPhoneWithLabel = `${formatPhoneNumber(phoneNumbers.work)} (Work)`;
  }

  return formattedPhoneWithLabel;
}

export function formatCustomerMobileNumber(phoneNumbers) {
  let formattedPhoneWithLabel = "";
  if (!isEmpty(phoneNumbers.mobile)) {
    formattedPhoneWithLabel = `${formatPhoneNumber(
      phoneNumbers.mobile
    )} (Mobile)`;
  }
  return formattedPhoneWithLabel;
}

/**
 *
 *
 * @function blankValueFormatter
 * @param {object} params
 * @return {string} val
 */
export function blankValueFormatter(params) {
  const val = params.value;
  if (toEmptyStringIfUndefined(val)) {
    return val;
  } else {
    return "-";
  }
}

/**
 *
 * TODO - Need to deprecate
 * @param {string} inputValue
 * @return {string} price
 */
export function formatPrice(inputValue) {
  let value = inputValue;
  if (typeof value === "string") {
    value = value.toString().replace(",", "");
  }
  if (value !== null || value !== undefined || value !== "" || !isNaN(value)) {
    const price = Math.round(value * 100) / 100;
    return priceValueFormatter(price.toFixed(2));
  }
  return "";
}
// Advance price formatter
// input value can be 654321, null, 0, "", "65.34"
export function priceValueFormatter(amount) {
  const options = { style: "currency", currency: "USD" };
  const priceFormat = new Intl.NumberFormat("en-US", options).format(
    amount || 0
  );
  return priceFormat;
}
/**
 *
 *
 * @param {string} value
 * @return {string} price
 */
function internalFormatPrice(value) {
  const price = Math.round(value * 100) / 100;
  if (!isNaN(price) && price >= 0) {
    return "$".concat(price.toFixed(2));
  }
  return "-";
}

export const priceFormatter = params => {
  if (
    params.value === "" ||
    params.value === undefined ||
    params.value === null
  ) {
    return "-";
  }
  return internalFormatPrice(params.value);
};
