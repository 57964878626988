import React, { useEffect, useState } from "react";
import format from "../../utils/format";
import CustomerPayPaymentLinesComponent from "./customer-pay-payment-lines.component";
import Dropdown from "@cx/ui/Dropdown";
import isNull from "lodash/isNull";
import cloneDeep from "lodash/cloneDeep";
import { v4 as uuidv4 } from "uuid";
import isEqual from "lodash/isEqual";
import { PAYER_TYPES, PAYMENT_TYPES } from "../../constants/csr.constants";
import Badge from "@cx/ui/Badge";
import moment from "moment";
import CustomerPayTypePaymentMethodsComponent from "./components/customer-payType-payment-methods.component";

const CustomerPaymentComponent = ({
  payerConfig,
  updateCustomerPayerChecked,
  chargeAccountDetails,
  legitPayers,
  deletePaymentLines
}) => {
  const [selectedPaymentLines, setSelectedPaymentLines] = useState([]);

  useEffect(() => {
    if (payerConfig.show === true) {
      setSelectedPaymentLines(payerConfig?.payer?.payments);
    }
  }, [payerConfig]);

  const onPaymentLineSelect = cxEvent => {
    const temporaryLocalId = uuidv4();
    const object = {
      paymentMethod: cxEvent.target.value,
      paymentAmount: null,
      paymentId: temporaryLocalId,
      drawerNumber: null,
      checkNumber: null,
      localPaymentId: temporaryLocalId,
      notes: null
    };
    if (cxEvent.target.value === PAYMENT_TYPES.CHARGE) {
      object["allowCharge"] =
        chargeAccountDetails?.isInGoodStanding === true &&
        chargeAccountDetails?.availableCredit?.amount >
          payerConfig?.payer?.finalPrice;
    }
    setSelectedPaymentLines([...selectedPaymentLines, object]);
    updateCustomerPayerChecked([...selectedPaymentLines, object], true);
  };

  const checkCashOrChargeAlreadyAdded = paymentMethod => {
    const isPaymentMethodAdded = selectedPaymentLines?.some(
      line => line?.paymentMethod === paymentMethod
    );
    return isPaymentMethodAdded;
  };

  const options = [
    {
      label: "Credit card",
      onSelect: onPaymentLineSelect,
      value: PAYMENT_TYPES.CREDIT_CARD
    },
    {
      label: "Cash",
      onSelect: onPaymentLineSelect,
      value: PAYMENT_TYPES.CASH,
      disabled: checkCashOrChargeAlreadyAdded(PAYMENT_TYPES.CASH)
    },
    {
      label: "Check",
      onSelect: onPaymentLineSelect,
      value: PAYMENT_TYPES.CHECK
    },
    {
      label: "Charge account",
      onSelect: onPaymentLineSelect,
      value: PAYMENT_TYPES.CHARGE,
      disabled: checkCashOrChargeAlreadyAdded(PAYMENT_TYPES.CHARGE)
    },
    {
      label: "Dealer policy",
      onSelect: onPaymentLineSelect,
      value: PAYMENT_TYPES.DEALER_POLICY
    }
  ];

  if (isNull(chargeAccountDetails)) {
    options?.map(
      (c, i) => c.value === PAYMENT_TYPES.CHARGE && options.splice(i, 1)
    );
  }

  const removePaymentLine = paymentLine => {
    const clonedSelectedPaymentLines = cloneDeep(selectedPaymentLines);
    clonedSelectedPaymentLines?.map(
      (line, i) =>
        line.paymentId ===
          (paymentLine.paymentId !== null
            ? paymentLine.paymentId
            : paymentLine.localPaymentId) &&
        clonedSelectedPaymentLines.splice(i, 1)
    );

    setSelectedPaymentLines(clonedSelectedPaymentLines);
    const isPaymentChanged = !isEqual(
      clonedSelectedPaymentLines,
      legitPayers?.find(pay => pay.payType === PAYER_TYPES.C)?.payments
    );
    updateCustomerPayerChecked(clonedSelectedPaymentLines, isPaymentChanged);
    if (paymentLine?.paymentId !== paymentLine.localPaymentId) {
      const payLineIndex = selectedPaymentLines?.findIndex(
        pay => pay?.paymentId === paymentLine?.paymentId
      );
      deletePaymentLines(
        paymentLine,
        payLineIndex,
        payerConfig?.payer?.payerId
      );
    }
  };

  const updateSelectedPaymentLines = updatedPaymentLine => {
    const clonedSelectedPaymentLines = cloneDeep(selectedPaymentLines);
    clonedSelectedPaymentLines?.forEach((line, index, array) => {
      if (
        line.paymentId ===
        (updatedPaymentLine.paymentId !== null
          ? updatedPaymentLine.paymentId
          : updatedPaymentLine.localPaymentId)
      ) {
        array[index] = updatedPaymentLine;
      }
    });

    setSelectedPaymentLines(clonedSelectedPaymentLines);
    const isPaymentChanged = !isEqual(
      clonedSelectedPaymentLines,
      legitPayers?.find(pay => pay.payType === PAYER_TYPES.C)?.payments
    );
    updateCustomerPayerChecked(clonedSelectedPaymentLines, isPaymentChanged);
  };

  const getBalanceDueAmount = () => {
    let collectedAmount = 0;
    selectedPaymentLines?.map(
      line =>
        (collectedAmount =
          collectedAmount +
          parseFloat(
            line?.paymentAmount !== null && line?.paymentAmount !== ""
              ? line?.paymentAmount
              : 0
          ))
    );
    const amountDue = parseFloat(payerConfig?.payer?.finalPrice);
    const balanceDue = amountDue?.toFixed(2) - collectedAmount.toFixed(2);
    const className =
      balanceDue == 0.0 ? "balance-class-green" : "balance-class-red";
    return { className, balanceDue, collectedAmount };
  };

  return (
    <>
      <hr className="spit-payment-horizontal-divider" />

      <div className="cust-pay-split-payment-container">
        <div className="customer-pay-balance">
          <span
            className={`customer-pay-balance-text ${
              payerConfig?.payer === undefined || payerConfig?.payer?.closedDate
                ? "pay-strong-text"
                : "bold-text"
            }`}
          >
            Customer Pay
          </span>
          {payerConfig?.isClosed ? (
            <div className="customer-pay-balance-badge">
              <Badge
                htmlId="badgeDefault"
                color={payerConfig?.isClosed ? "purple" : "gray"}
              >
                Closed -{" "}
                {moment(payerConfig?.payer?.closedDate).format("MM/DD/YYYY")}
              </Badge>
            </div>
          ) : null}
          <span
            className={`customer-pay-balance-amount ${
              payerConfig?.payer === undefined || payerConfig?.payer?.closedDate
                ? "pay-strong-amount"
                : " pay-bold-text"
            }`}
          >
            {payerConfig?.show === true
              ? format.currency(payerConfig?.payer.finalPrice)
              : format.currency(0)}
          </span>
        </div>
        {payerConfig?.show == true &&
        !payerConfig?.isClosed &&
        payerConfig?.payer?.finalPrice != 0 ? (
          <>
            {selectedPaymentLines?.length > 0 ? (
              <CustomerPayPaymentLinesComponent
                payerConfig={payerConfig}
                selectedPaymentLines={selectedPaymentLines}
                chargeAccountDetails={chargeAccountDetails}
                removePaymentLine={removePaymentLine}
                updateSelectedPaymentLines={updateSelectedPaymentLines}
              />
            ) : null}

            <div className="customer-pay-payment-lines">
              <div className="customer-pay-payment-lines-dropdown">
                <Dropdown
                  buttonStyle="secondary"
                  htmlId="dropdownSecondaryButton"
                  name="dropdownSecondaryButton"
                  options={options}
                  onSelect={onPaymentLineSelect}
                >
                  Add payment line
                </Dropdown>
              </div>
              <div className="customer-pay-payment-lines-balance">
                <div className="customer-payment-span">
                  <span className="customer-payment-text-amount-due">
                    Amount due {"  "}
                  </span>
                  <span className="customer-payment-value">
                    {format.currency(
                      payerConfig?.payer?.finalPrice?.toFixed(2)
                    )}
                  </span>
                </div>
                <div className="customer-payment-span">
                  <span className="customer-payment-text-amount-collected">
                    Amount collected
                  </span>
                  <span className="customer-payment-value">
                    {format.currency(
                      getBalanceDueAmount().collectedAmount.toFixed(2)
                    )}
                  </span>
                </div>
                <div className="customer-payment-span">
                  <span
                    className={`customer-payment-text-balance ${
                      getBalanceDueAmount().className
                    }`}
                  >
                    Balance
                  </span>
                  <span
                    className={`customer-payment-value-balance ${
                      getBalanceDueAmount().className
                    }`}
                  >
                    {getBalanceDueAmount().balanceDue < 0 ? "+  " : null}
                    {format.currency(
                      Math.abs(getBalanceDueAmount().balanceDue).toFixed(2)
                    )}
                  </span>
                </div>
              </div>
            </div>
          </>
        ) : null}

        {payerConfig?.show == true && payerConfig?.isClosed ? (
          payerConfig?.payer?.payments?.length > 0 ? (
            <CustomerPayTypePaymentMethodsComponent payerConfig={payerConfig} />
          ) : null
        ) : null}
      </div>
    </>
  );
};

export default CustomerPaymentComponent;
