import RadioButtonList from "@cx/ui/RadioButtonList";
import cloneDeep from "lodash/cloneDeep";
import React, { useEffect, useMemo, useState } from "react";
import { FEES_TYPES } from "../../../constants/adjustment.constant";
import isArray from "lodash/isArray";
import { PAYER_TYPES } from "../../../constants/csr.constants";

const ROFeePayerSelectComponent = ({
  selectedFee,
  quoteSummary,
  setSelectedFee
}) => {
  const [selectedPayer, setSelectedPayer] = useState("");

  useEffect(() => {
    setSelectedPayer(selectedFee?.payerObject);
  }, [selectedFee]);

  const onPayerChange = cxEvent => {
    const payerObject = quoteSummary?.payers?.find(
      payer => payer.payType === cxEvent.target.value
    );
    const clonedSelectedFee = cloneDeep(selectedFee);
    clonedSelectedFee.payerObject = payerObject;
    clonedSelectedFee.feeDiscountPayerPayCode = payerObject?.payType;
    if (selectedFee?.feesType === FEES_TYPES.PERCENT) {
      clonedSelectedFee.appliedFee = Math.min(
        (clonedSelectedFee?.payerObject?.subTotalLineLaborBasePrice *
          clonedSelectedFee?.percentage) /
          100,
        clonedSelectedFee?.feeMax
      );
    }
    setSelectedFee(clonedSelectedFee);
    setSelectedPayer(payerObject);
  };

  const enabledPayers = useMemo(() => {
    const selectedFeePayTypes = isArray(selectedFee?.payTypes)
      ? selectedFee?.payTypes
      : selectedFee?.payTypes?.split(",");
    const propertyValues = quoteSummary?.payers?.map(item => item["payType"]);
    const commonPayers = selectedFeePayTypes.filter(item =>
      propertyValues.includes(item)
    );
    return commonPayers;
  }, [selectedFee, quoteSummary]);

  return (
    <RadioButtonList
      htmlId="radioButtonListDefault"
      name="radioButtonListDefault"
      className="payer-selection-radio-group"
      displayLabel={false}
      label="Payer"
      onChange={onPayerChange}
      layout="horizontal"
      value={selectedPayer?.payType}
      options={[
        {
          value: "C",
          label: "Customer pay",
          disabled: !enabledPayers?.includes("C")
        },
        {
          value: "W",
          label: "Warranty",
          disabled: !enabledPayers?.includes("W")
        },
        {
          value: "I",
          label: "Internal",
          disabled: !enabledPayers?.includes("I")
        },
        {
          value: PAYER_TYPES.S,
          label: "Service contract",
          disabled: !enabledPayers?.includes(PAYER_TYPES.S)
        }
      ]}
    />
  );
};

export default ROFeePayerSelectComponent;
