import React, { Component, useContext } from "react";
import PropTypes from "prop-types";
import * as gtmEvent from "../../../utils/gtag/gtag-event.util";
import {
  formatErrorLocation,
  limitStringCharacters
} from "../../../utils/format";
import ErrorNotification from "./error-notification.component";
import {
  LogLevel,
  writeLogEntry,
  logError
} from "../../../../services/log.service";

const initialError = {
  hasError: false,
  error: null,
  errorInfo: null,
  removeError: () => {}
};

const ErrorContext = React.createContext({ ...initialError });

class ErrorProvider extends Component {
  constructor(props) {
    super(props);
    this.state = { ...initialError };

    this.removeError = this.removeError.bind(this);
  }

  componentDidMount() {
    window.onerror = this.handleError;
  }

  componentWillUnmount() {
    window.onerror = null;
  }

  /**
   *
   * @param {object} error
   * @param {object} errorInfo
   *
   * This method only catches react render errors
   */
  componentDidCatch(error, errorInfo) {
    this.setState({
      hasError: true,
      error: {
        message: error?.message || new Error(error),
        stack: error?.stack
      },
      errorInfo
    });
    logError(error, "ErrorProvider.componentDidCatch");
  }

  /**
   *
   * @param {string} message
   * @param {string} url
   * @param {number} line
   * @param {number} column
   * @param {object} errorObj
   *
   * This method catches all core/unhandled JS errors
   */
  handleError = (message, url, line, column, errorObj) => {
    let errorMessage = message;
    let errorSource = null;
    try {
      errorMessage = limitStringCharacters(errorObj?.message);
      errorSource = formatErrorLocation(errorObj);
    } catch (err) {
      console.error("Unable to parse error object.", err);
    }
    writeLogEntry(LogLevel.ERROR, {
      event: "ErrorProvider.handleError",
      errorMessage,
      errorLocation: errorSource,
      file: url,
      line,
      column
    });
    const logObj = {
      eventName: "JSError",
      eventProperties: {
        result: errorMessage,
        location: errorSource,
        file: url,
        line,
        column
      }
    };
    gtmEvent.trackGAError(logObj);

    this.setState({
      hasError: true,
      error: {
        message: errorObj?.message || new Error(message),
        stack: errorObj?.stack
      },
      errorInfo: { url, line, column }
    });
  };

  removeError() {
    this.setState({ ...initialError });
  }

  render() {
    return (
      <ErrorContext.Provider
        value={{ ...this.state, removeError: this.removeError }}
      >
        {this.props.isDebug && this.state.errorInfo?.componentStack ? (
          <ErrorNotification />
        ) : (
          this.props.children
        )}
      </ErrorContext.Provider>
    );
  }
}

const useErrorBoundary = () => {
  return useContext(ErrorContext);
};

ErrorProvider.defaultProps = {
  isDebug: false
};

ErrorProvider.propTypes = {
  isDebug: PropTypes.bool
};

export { ErrorProvider, useErrorBoundary, ErrorContext };
