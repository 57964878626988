import isEmpty from "lodash/isEmpty";
import isNull from "lodash/isNull";
import _isObject from "lodash/isObject";
import cloneDeep from "lodash/cloneDeep";
import {
  payTypeCodes,
  roStatusOptions,
  serviceTypes
} from "./edit-service.constants";
import { appTypes } from "../../constants/app.constants";
import { ServiceTypes as serviceTypesConstant } from "../../features/page-wrapper/constants/page-wrapper.constants";

/* This function checks for error object has strings with {null,"", undefined} in given object
  and returns count for error strings. */
const hasErrorStrings = errors => {
  let errCount = 0;
  if (Object.keys(errors).length) {
    const array1 = Object.values(errors);
    const iterator = array1.values();
    for (const value of iterator) {
      if (value === "" || value === null) {
        // In case of valid error string
      } else if (value && typeof value === "string") {
        errCount++;
      }
    }
  }
  return errCount === 0 ? false : true;
};
// Call this util to show speed bumps when edit service is modified
const hasTouched = touched => {
  let errCount = 0;
  if (Object.keys(touched).length) {
    const array1 = Object.values(touched);
    const iterator = array1.values();
    for (const value of iterator) {
      if (value === "" || value === null) {
        // In case of valid error string
      } else if (value && typeof value === "string") {
        errCount++;
      }
    }
  }
  return errCount === 0 ? false : true;
};

/**
 * Message utility
 *
 * @param {object} errors
 * @param {object} service
 * @returns string
 */
const getWarningMessages = (errors, service) => {
  return Object.keys(errors)
    .filter(error => errors[error])
    .map(error => {
      if (error === "vehicleAttributes") {
        return "Vehicle attributes";
      } else if (error === "serviceNotes") {
        return "Notes";
      } else if (error === "opCode") {
        return "Op code";
      } else if (error === "laborTime") {
        return "Hours";
      } else if (error === "laborPrice") {
        return "Total labor";
      } else if (error === "parts") {
        return "Parts";
      } else if (error === "partsPrice") {
        return "Parts price";
      } else if (error === "serviceOptions") {
        return "Service options";
      } else if (error === "technicianList") {
        return "Technician not selected! Please select technician";
      } else if (error === "technicianFlagTime") {
        return "Flag time not added for technician! Please add flag time";
      } else if (error === "payTypes") {
        return `The service ${
          service.name
        } can't be added without pay type for dealer ${service.dealer} ${
          service.confirmationId ? `, quote ${service.confirmationId}` : ""
        }`;
      }
      return null;
    })
    .join(", ")
    .replace(/,$/, ".");
};
const extractAllPartsFromRawOperation = rawOperationDetails => {
  const parts = [];
  const { laborApps } = rawOperationDetails;
  if (!isEmpty(laborApps)) {
    laborApps.forEach(op => {
      if (!isEmpty(op.parts)) {
        parts.push(op.parts);
      }
    });
  }
  console.log("extractAllPartsFromRawOperation", parts);
  return parts;
};

const freezeObject = obj => {
  const objKeys = Object.keys(obj);
  for (const objKey of objKeys) {
    if (_isObject(obj[objKey])) {
      obj[objKey] = Object.freeze(obj[objKey]);
    }
  }
  return Object.freeze(obj);
};

// This method will eliminate properties from given object/array
const stripPropertiesFromObjectArray = (objArr, propArr) => {
  const objArrayCopy = cloneDeep(objArr);
  const newObjArr = objArrayCopy.map(obj => {
    const keys = Object.keys(obj);
    keys.forEach(key => {
      if (propArr.includes(key)) {
        delete obj[key];
      }
    });
    return obj;
  });
  return newObjArr;
};

// Util find for rawTireService exist in rawOperationDetails for Dealer tire service case and if found returns true
const isDealerTireService = rawOperationDetails => {
  let recordFound = false;
  if (!isEmpty(rawOperationDetails)) {
    const { laborApps } = rawOperationDetails;
    if (!isEmpty(laborApps)) {
      const rawTireService = laborApps[0].rawTireService || null;
      if (!isEmpty(rawTireService)) {
        if (!isEmpty(rawTireService.services)) {
          recordFound = true;
        }
      }
    }
  }
  return recordFound;
};

/**
 * Filters an array of tech objects based on certain conditions.
 *
 * @param {Array} tech - The array of tech objects to be filtered.
 * @returns {Array} - The filtered array of tech objects.
 */
const checkTechAssigned = tech =>
  tech?.filter(
    c =>
      /**
       * Conditions for filtering:
       * - Check if techId is null
       * - Check if flagTimeEnd is null
       * - Check if actualTimeStart includes the string "Invalid"
       */
      isNull(c.techId) ||
      isNull(c.flagTimeEnd) ||
      c?.actualTimeStart?.includes("Invalid")
  );

/**
 * Filters out elements from the given array where the correctionCode is either
 * undefined, null, or an empty string.
 *
 * @param {Array} tech - The array to filter.
 * @returns {Array} - The filtered array.
 */
const techHasCorrectionCode = tech =>
  tech?.filter(c => [undefined, null, ""].includes(c?.correctionCode)) || [];

/**
 * Checks if the payment type is internal and certain conditions are met.
 * @param {string} payTypeCode - The payment type code.
 * @param {string} subTypeId - The sub-type ID.
 * @param {string} allocationSubTypeId - The allocation sub-type ID.
 * @param {boolean} isDMSPInternalAccountsAvailable - is DMSP_INTERNAL_ACCOUNTS exeter flag available or not
 * @returns {boolean} - True if the payment type is internal and conditions are met, otherwise false.
 */
const isInternalPaymentType = (
  payTypeCode,
  subTypeId,
  allocationSubTypeId,
  internalAccount,
  isDMSPInternalAccountsAvailable
) => {
  if (isDMSPInternalAccountsAvailable) {
    return payTypeCode === payTypeCodes.INTERNAL && !internalAccount;
  }
  return (
    payTypeCode === payTypeCodes.INTERNAL && !(subTypeId && allocationSubTypeId)
  );
};

/**
 * Checks if the service type is global repair and certain conditions are met.
 * @param {string} serviceType - The service type.
 * @param {any} laborApp - The labor app.
 * @returns {boolean} - True if the service type is global repair and conditions are met, otherwise false.
 */
const isGlobalRepairService = (serviceType, laborApp) => {
  return serviceType === serviceTypes.GLOBALREPAIR && isEmpty(laborApp);
};

/**
 * Checks if the application type is CSR with warranty and certain conditions are met.
 * @param {string} appType - The application type.
 * @param {string} payTypeCode - The payment type code.
 * @param {any} currentEditingService - The currently editing service.
 * @param {any} cause - The cause.
 * @param {any} correction - The correction.
 * @param {any} complaint - The complaint.
 * @returns {boolean} - True if the application type is CSR with warranty and conditions are met, otherwise false.
 */
const isCSRWithWarranty = (
  appType,
  payTypeCode,
  currentEditingService,
  cause,
  correction,
  complaint
) => {
  const isCsrWithWarranty =
    appType === appTypes.CSR && payTypeCode === payTypeCodes.WARRANTY;

  if (isCsrWithWarranty) {
    if (isNull(currentEditingService)) {
      return isEmpty(complaint);
    } else {
      return (
        !isEmpty(currentEditingService) &&
        (isEmpty(cause) || isEmpty(correction) || isEmpty(complaint))
      );
    }
  }

  return false;
};

/**
 * Checks if technicians have a correction code for CSR with warranty.
 * @param {string} appType - The application type.
 * @param {string} payTypeCode - The payment type code.
 * @param {any} technicians - The array of technicians.
 * @returns {boolean} - True if technicians have a correction code, otherwise false.
 */
const hasCorrectionCode = (appType, payTypeCode, technicians) => {
  return (
    appType === appTypes.CSR &&
    payTypeCode === payTypeCodes.WARRANTY &&
    techHasCorrectionCode(technicians)?.length > 0
  );
};

/**
 * Checks if technicians are assigned for CSR.
 * @param {string} appType - The application type.
 * @param {any} technicians - The array of technicians.
 * @returns {boolean} - True if technicians are assigned, otherwise false.
 */
const isTechAssigned = (appType, technicians) => {
  return appType === appTypes.CSR && checkTechAssigned(technicians)?.length > 0;
};

/**
 * Checks if the save button should be disabled based on various conditions.
 * @param {object} data - The data object containing relevant information.
 * @param {string} data.payTypeCode - The payment type code.
 * @param {string} data.subTypeId - The sub-type ID.
 * @param {string} data.allocationSubTypeId - The allocation sub-type ID.
 * @param {any} data.technicians - The array of technicians.
 * @param {any} data.cause - The cause.
 * @param {any} data.correction - The correction.
 * @param {any} data.complaint - The complaint.
 * @param {string} data.serviceContract.vendorName - The vendorName.
 * @param {string} serviceType - The service type.
 * @param {any} laborApp - The labor app.
 * @param {any} currentEditingService - The currently editing service.
 * @param {object} options - Additional options object.
 * @param {string} options.appType - The application type.
 * @returns {boolean} - True if the save button should be disabled, otherwise false.
 */
const isSaveButtonDisabled = (
  {
    payTypeCode,
    subTypeId,
    allocationSubTypeId,
    internalAccount,
    technicians,
    cause,
    correction,
    complaint,
    serviceTypeCode,
    serviceContract
  },
  serviceType,
  laborApp,
  currentEditingService,
  isDMSPlusDealer,
  isDMSPInternalAccountsAvailable,
  { appType, serviceTypes }
) => {
  return (
    (isDMSPlusDealer &&
      serviceType !== serviceTypesConstant.MENU &&
      (isEmpty(serviceTypeCode) || serviceTypes?.length == 0)) ||
    isInternalPaymentType(
      payTypeCode,
      subTypeId,
      allocationSubTypeId,
      internalAccount,
      isDMSPInternalAccountsAvailable
    ) ||
    isGlobalRepairService(serviceType, laborApp) ||
    isCSRWithWarranty(
      appType,
      payTypeCode,
      currentEditingService,
      cause,
      correction,
      complaint
    ) ||
    hasCorrectionCode(appType, payTypeCode, technicians) ||
    isTechAssigned(appType, technicians) ||
    (isServiceContract(payTypeCode) &&
      isEmpty(serviceContract?.vendorName) &&
      serviceType !== serviceTypesConstant.MENU &&
      appType === appTypes.CSR)
  );
};

/**
 * Checks if the application is a CSR app and not associated with an advisor.
 * @param {Object} props - The properties object containing appType and quoteSummary.
 * @returns {boolean} - True if it's a CSR app and not with an advisor, false otherwise.
 */
const isCSRAppAndNotWithAdvisor = ({ appType, quoteSummary }) => {
  return (
    appType === appTypes.CSR &&
    !isEmpty(quoteSummary?.quoteStatus) &&
    quoteSummary?.quoteStatus !== roStatusOptions.WITH_ADVISOR
  );
};

/**
 * Checks if the application is currently editing a service or menu.
 * @param {string} serviceType - The type of service.
 * @param {Object} currentEditingService - The currently editing service object.
 * @param {Object} propService - The service object from the properties.
 * @returns {boolean} - True if editing a service or menu, false otherwise.
 */
const isEditingServiceOrMenu = (
  serviceType,
  currentEditingService,
  propService
) => {
  return (
    !isEmpty(currentEditingService) ||
    (serviceType === serviceTypes.MENU && !isEmpty(propService))
  );
};

/**
 * Checks the validity of a specific attribute.
 *
 * @param {any} attributeValue - The value of the attribute to check.
 * @returns {boolean} - True if the attribute value is considered valid, false otherwise.
 */
const isValidAttr = attributeValue => {
  return !isEmpty(attributeValue);
};

const isDMSPlus = dmsType => dmsType?.toUpperCase() === "DMS+";

const isServiceContract = payTypeCode =>
  payTypeCode === payTypeCodes.SERVICE_CONTRACT;

const isMenuServiceSelected = (
  currentServiceType,
  services,
  currentServiceId
) => {
  if (currentServiceType === serviceTypes.MENU) {
    const menuPackage = services?.find(
      service => service.quoteServiceType.toLowerCase() === serviceTypes.MENU
    );
    return menuPackage?.menuServices?.some(
      service => +service.extServiceId === currentServiceId
    );
  } else {
    return false;
  }
};

export {
  getWarningMessages,
  hasErrorStrings,
  hasTouched,
  freezeObject,
  extractAllPartsFromRawOperation,
  stripPropertiesFromObjectArray,
  isDealerTireService,
  checkTechAssigned,
  techHasCorrectionCode,
  isSaveButtonDisabled,
  isCSRAppAndNotWithAdvisor,
  isEditingServiceOrMenu,
  isValidAttr,
  isDMSPlus,
  isMenuServiceSelected,
  isServiceContract
};
