// EXTERNAL LIBRARIES
import SimpleTable from "@cx/ui/SimpleTable";
import React from "react";
import PropTypes from "prop-types";
import format from "../../../utils/format";

const RoServices = ({ services }) => {
  if (services && services.length > 0) {
    return (
      <div className="ro-service-history services">
        <SimpleTable htmlId="Service-table" comfortable hover={false}>
          <thead>
            <tr>
              <th className="tr-heading-text width-80" scope="col">
                Service
              </th>
              <th className="tr-heading-text width-10" scope="col">
                Op code
              </th>
              {/* 
              // ! NOTE commenting this code as data is not coming from APIs for these fields
              <th className="tr-heading-text width-10" scope="col">
                Tech
              </th>
              <th className="tr-heading-text width-10" scope="col">
                Type
              </th>
               */}
              <th
                className="tr-heading-text width-10 text-align-right"
                scope="col"
              >
                Price
              </th>
            </tr>
          </thead>
          <tbody className="services-body">
            {services.map(service => {
              return (
                <tr key={`service${service?.roId}${service?.roItemNum}`}>
                  <th scope="row">
                    <div className="flex-d">
                      <span className="service-desc">{service.opDesc}</span>
                      <span className="service-concern">
                        <span className="service-desc-heading mr-26">
                          Concern
                        </span>
                        {service.complaint || "--"}
                      </span>
                      <span className="service-concern">
                        <span className="service-desc-heading mr-36">
                          Cause
                        </span>
                        {service.cause || "--"}
                      </span>
                      <span className="service-concern">
                        <span className="service-desc-heading m-r">
                          Correction
                        </span>
                        {service.correction || "--"}
                      </span>
                    </div>
                  </th>
                  <td className="service-desc">{service.opCode || "---"}</td>
                  {/* 
                  // ! NOTE commenting this code as data is not coming from APIs for these fields
                  <td className="service-desc">{service.techId || "---"}</td>
                  <td className="service-desc">{service.opType || "---"}</td> */}
                  <td className="service-desc text-align-right">
                    {format.currency(service.opCost)}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </SimpleTable>
      </div>
    );
  } else return false;
};

export default RoServices;

RoServices.prototype = {
  // data
  services: PropTypes.array
};
