/* eslint-disable react/no-multi-comp */
/* eslint-disable react/prop-types */
import React from "react";

// const NaNssNrParts = ["NA", "NR", "NSS"];
// This Speed up calls to hasOwnProperty
const doesOwnProperty = Object.prototype.hasOwnProperty;

export const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));
// Example:
// sleep(300).then(() => {
// console.log("Timeout started");
// });

export function toEmptyListIfUndefined(list) {
  if (list && Array.isArray(list)) {
    return list;
  }
  return [];
}

export function toEmptyStringIfUndefined(value) {
  return value === undefined || value === null ? "" : value.toString();
}

export const DisplayState = props => (
  <div style={{ margin: "1rem 0" }}>
    <pre className="pre-style">
      <strong>props</strong> = {JSON.stringify(props, null, 2)}
    </pre>
  </div>
);

export function isDifferentValue(newVal, oldVal) {
  const newValue = toEmptyStringIfUndefined(newVal);
  const oldValue = toEmptyStringIfUndefined(oldVal);
  return newValue !== oldValue;
}

function internalFormatPrice(value) {
  const price = Math.round(value * 100) / 100;
  if (!isNaN(price) && price >= 0) {
    return "$".concat(price.toFixed(2));
  }
  return "-";
}

export const priceFormatter = value => {
  if (isNaN(value) || !value) {
    return "-";
  }
  return internalFormatPrice(value);
};

export const isEmpty = obj => {
  // null and undefined are "empty"
  if (obj == null) return true;

  // Assume if it has a length property with a non-zero value
  // that that property is correct.
  if (obj.length > 0) return false;
  if (obj.length === 0) return true;

  // If it isn't an object at this point
  // it is empty, but it can't be anything *but* empty
  // Is it empty?  Depends on your application.
  if (typeof obj !== "object") return true;

  // Otherwise, does it have any properties of its own?
  // Note that this doesn't handle
  // toString and valueOf enumeration bugs in IE < 9
  for (const key in obj) {
    if (doesOwnProperty.call(obj, key)) return false;
  }
  return true;
};

/**
 * Returns true if the passed value is empty, false otherwise. The value is deemed to be empty if it is either:
 *
 * - `null`
 * - `undefined`
 * - a zero-length array
 * - a zero-length string (Unless the `allowEmptyString` parameter is set to `true`)
 *
 * @param {Object} value The value to test
 * @param {Boolean} allowEmptyString (optional) true to allow empty strings (defaults to false)
 * @return {Boolean}
 * @markdown
 */
export const doesEmpty = (value, allowEmptyString) => {
  return (
    value === null ||
    value === undefined ||
    (!allowEmptyString ? value === "" : false) ||
    (isArrayExist(value) && value.length === 0)
  );
};

/**
 * Returns true if the passed value is a JavaScript Array, false otherwise.
 *
 * @param {Object} target The target to test
 * @return {Boolean}
 * @method
 */
export const isArrayExist = value => {
  return Object.prototype.toString.call(value) === "[object Array]";
};

export const isArrayList = val => {
  return Array.isArray(val);
};

export const defaultToZeroIfNullOrEmpty = value => {
  return !value ? 0 : Number(value);
};

export function convertMinutesToHours(timeParam, decimals) {
  /*
   * example:
   * 8.4/60 = 0.14
   * 0.14 * 100 = 14.000000000000002
   * 14.000000000000002 toFixed(1) becomes 14.0
   * Math.ceil(14.0) becomes 14
   * 14/100 = 0.14
   * 0.14.toFixed(2) becomes 0.14
   * 0.14 is returned
   *
   * */
  let time = 0;
  if (timeParam) {
    time = timeParam;
    time /= 60;
    time *= 100;
    /* just one decimal is enough to take ceiling */
    time = time.toFixed(1);
    time = Math.ceil(time);
    time /= 100;
    time = time.toFixed(!decimals ? 2 : decimals);
    return time;
  }
  return 0;
}

export function convertToHours(timeParam, decimals) {
  let time = 0;
  if (timeParam) {
    time = timeParam;
    time *= 100;
    time = time.toFixed(1);
    time = Math.ceil(time);
    time /= 100;
    time = time.toFixed(!decimals ? 2 : decimals);
    return time;
  }
  return 0;
}

export function getVehicleAttributeColmns(selectableVehicleAttributes) {
  if (!selectableVehicleAttributes) {
    return [];
  }
  return selectableVehicleAttributes.map(attribute => {
    const field = attribute; // convertNameToField(attribute);
    const headerName = attribute;
    return { field, headerName };
  });
}

/**
 * @param {object} operation
 * @param {object} response
 * @param {number} laborRateValue
 * @returns { partsAndLabor, laborPartsTotal }
 */
export function getPartsAndLaborAndTotal(operation, response, laborRateValue) {
  let laborRate = laborRateValue ? laborRateValue : 100;

  const partsAndLabor = {
    operation,
    labors: [],
    laborsColumns: [],
    parts: [],
    partsColumns: [],
    selectedVehicleAttributeMap: {}
  };
  const {
    catalogSource,
    defaultLaborRate,
    laborApps,
    notApplicableParts,
    vehicleAttributes,
    selectableVehicleAttributes
  } = response;

  if (notApplicableParts) {
    notApplicableParts.forEach(p => {
      p.oePartNumber = p.oemPartNumber;
    });
  }

  const dealerPublishedCatalog = catalogSource === "DealerPublishedCatalog";

  if (defaultLaborRate) {
    laborRate = defaultLaborRate;
  }

  partsAndLabor.operation = operation;
  partsAndLabor.dealerPublishedCatalog = dealerPublishedCatalog;
  partsAndLabor.labors = laborApps ? laborApps : [];
  let fluidsAndParts = [];
  if (partsAndLabor.labors.length !== 0) {
    partsAndLabor.labors.forEach(l => {
      const { parts } = l;
      fluidsAndParts = fluidsAndParts.concat(parts);
    });
  }
  partsAndLabor.laborsColumns = getAdditionalColumns(partsAndLabor.labors);
  laborApps.forEach(row => {
    row.description = row.displayName;
    if (!row.quantity) {
      row.quantity = 1;
    }
    row.dealerPublishedCatalog = dealerPublishedCatalog;
  });
  const naNrNssParts = notApplicableParts ? notApplicableParts : [];
  const fluidHasQualifiers = false;
  fluidsAndParts.forEach(row => {
    row.description = toEmptyStringIfUndefined(row.partName);
    row.manuallySelected = row.selected;
    row.oePartNumber = row.oemPartNumber;
    if (row.partType === "part") {
      row.supersededParts = row.alternateParts;
    } else {
      row.supersededFluids = row.alternateParts;
    }
    row.price = row.unitPrice;
    row.units = row.unitOfMeasure;
    if (!row.quantity) {
      row.quantity = 1;
    }
    row.dealerPublishedCatalog = dealerPublishedCatalog;
  });

  partsAndLabor.apiVersion = 2;
  partsAndLabor.fluidHasQualifiers = fluidHasQualifiers;
  partsAndLabor.parts = fluidsAndParts;
  partsAndLabor.naNrNssParts = naNrNssParts;
  partsAndLabor.partsColumns = getAdditionalColumns(fluidsAndParts);
  // set up the selectableVehicleAttributes, selectableVehAttrLabors, and selectableVehAttrParts
  partsAndLabor.selectableVehicleAttributes = selectableVehicleAttributes;

  if (
    hasSelectableVehicleAttributes(partsAndLabor.selectableVehicleAttributes)
  ) {
    partsAndLabor.selectableVehAttrLabors = [...partsAndLabor.labors];
    partsAndLabor.selectableVehAttrParts = [...partsAndLabor.parts];
    partsAndLabor.vehicleAttributes = vehicleAttributes;
  }
  const laborPartsTotal = {
    isPublishedCatalog: false,
    laborRate,
    laborTime: 0,
    partsTotal: 0,
    laborTotal: 0,
    total: 0
  };

  const priceOverrides = {};

  if (dealerPublishedCatalog) {
    updateLaborPartsTotal(response, laborPartsTotal, priceOverrides);
  }

  return { partsAndLabor, laborPartsTotal, priceOverrides };
}

export function hasSelectableVehicleAttributes(selectableVehicleAttributes) {
  return (
    selectableVehicleAttributes && selectableVehicleAttributes.length !== 0
  );
}

export function hasAllVehicleAttributesSelected(
  selectedVehicleAttributeMap,
  vehicleAttributeListLength
) {
  return (
    selectedVehicleAttributeMap &&
    Object.keys(selectedVehicleAttributeMap).length ===
      vehicleAttributeListLength
  );
}

function updateLaborPartsTotal(response, laborPartsTotal, priceOverrides) {
  const { laborApps } = response;
  if (laborApps && Array.isArray(laborApps) && laborApps.length !== 0) {
    const laborApp = laborApps[0];
    const {
      laborHours,
      laborPrice,
      partsPrice,
      totalPrice,
      laborPriceOverridden,
      partsPriceOverridden,
      totalPriceOverridden
    } = laborApp;
    if (laborHours) {
      laborPartsTotal.isPublishedCatalog = true;
      laborPartsTotal.laborTime = laborHours ? laborHours : 0;
      laborPartsTotal.laborTotal = laborPrice ? laborPrice : 0;
      laborPartsTotal.partsTotal = partsPrice ? partsPrice : 0;
      laborPartsTotal.total = totalPrice ? totalPrice : 0;
    }
    priceOverrides.laborPrice = laborPrice;
    priceOverrides.partsPrice = partsPrice;
    priceOverrides.totalPrice = totalPrice;
    priceOverrides.laborPriceOverridden = laborPriceOverridden;
    priceOverrides.partsPriceOverridden = partsPriceOverridden;
    priceOverrides.totalPriceOverridden = totalPriceOverridden;
  }
}

function getAdditionalColumns(list) {
  const additionalColumnNamesMap = {};
  let id = 1;
  list.forEach(item => {
    if (item.quantity === 0) {
      item.quantity = 1;
    }
    item.id = id++;
    const { qualifiers, vehicleAttributes } = item;
    if (item.partType === "fluid" && qualifiers) {
      const columnNames = Object.keys(qualifiers);
      columnNames.forEach(key => {
        additionalColumnNamesMap[key] = key;
        const field = convertNameToField(key);
        item[field] = qualifiers[key];
      });
    }
    if (vehicleAttributes) {
      const columnNames = Object.keys(vehicleAttributes);
      columnNames.forEach(key => {
        additionalColumnNamesMap[key] = key;
        const field = convertNameToField(key);
        item[field] = vehicleAttributes[key];
      });
    }
  });
  const additionalColumnNames = Object.keys(additionalColumnNamesMap);
  const sortedAdditionalColumnNames = additionalColumnNames.sort((a, b) => {
    return a > b ? 1 : -1;
  });
  return sortedAdditionalColumnNames.map(headerName => {
    const field = convertNameToField(headerName);
    return { field, headerName };
  });
}

export function convertNameToField(name) {
  return name.replace(/\s+/g, "");
}
