import * as Yup from "yup";
import isEmpty from "lodash/isEmpty";
import { formatDateTimezone } from "../../../utils/date.util";

const createVehicle = Yup.object().shape({
  avgMileage: Yup.number().required().default(0),
  color: Yup.string().required().default(""),
  default: Yup.boolean().required().default(true),
  description: Yup.string().required().default(""),
  driveType: Yup.string().required().default(""),
  engineSize: Yup.string()
    .required()
    .default("")
    .transform(value => (value === null ? "" : value)),
  engineType: Yup.string()
    .required()
    .default("")
    .transform(value => (value === null ? "" : value)),
  licenseNumber: Yup.string().required().default(""),
  lifecycleState: Yup.string().required().default(""),
  make: Yup.string().required().default(""),
  metaVehicleId: Yup.number().required().default(0),
  metaVehicleDetailId: Yup.string().required().default(""),
  mileage: Yup.string().required().default(""),
  mileageDate: Yup.string().required().default(""),
  model: Yup.string().required().default(""),
  name: Yup.string().required().default(""),
  orgUnitId: Yup.number().required().default(0),
  personId: Yup.number().required().default(0),
  roHistoryCnt: Yup.number().required().default(0),
  transmissionType: Yup.string()
    .required()
    .default("")
    .transform(value => (value === null ? "" : value)),
  trim: Yup.string()
    .required()
    .default("")
    .transform(value => (value === null ? "" : value)),
  variant: Yup.string().required().default(""),
  vin: Yup.string().required().default(""),
  year: Yup.number().required().default(0),
  userRegDate: Yup.string().required()
});

const editVehicle = createVehicle.shape({
  vehicleId: Yup.mixed().required()
});

const vehicleDirtyCheck = Yup.object().shape({
  driveType: Yup.string(),
  engineSize: Yup.string(),
  engineSizeType: Yup.string(),
  engineType: Yup.string(),
  make: Yup.string(),
  metaVehicleId: Yup.number().transform(value => (isEmpty(value) ? 0 : value)),
  mileage: Yup.mixed(),
  model: Yup.string(),
  transmissionType: Yup.string(),
  trim: Yup.string(),
  variant: Yup.string(),
  vehicleId: Yup.mixed(),
  vin: Yup.string(),
  year: Yup.string()
});

const editAddVehicleRequest = Yup.object().shape({
  year: Yup.number().required(),
  make: Yup.string().required(),
  model: Yup.string().required(),
  color: Yup.string(),
  licenseNumber: Yup.string(),
  avgMileage: Yup.number(),
  trim: Yup.string(),
  engineType: Yup.string(),
  engineSize: Yup.string(),
  driveType: Yup.string(),
  transmissionType: Yup.string(),
  mileage: Yup.number().transform(value => (isNaN(value) ? 0 : value)),
  mileageDate: Yup.string()
    .required()
    .default(formatDateTimezone(new Date(), false, "vehicle")),
  isDefault: Yup.boolean().default(false),
  isActive: Yup.boolean().required().default(true),
  webkey: Yup.string().required(),
  dealerCode: Yup.string().required(),
  vin: Yup.string()
});
// @note: This schema used when all attributes missing in vehicle form including YMM fields
const vehicleFormAttributeSchema = Yup.object().shape({
  year: Yup.string().required(),
  make: Yup.string().required(),
  model: Yup.string().required(),
  trim: Yup.string().required(),
  engineSize: Yup.string().required(),
  engineType: Yup.string().required(),
  driveType: Yup.string().required(),
  engineSizeType: Yup.string(),
  transmissionType: Yup.string().required(),
  metaVehicleId: Yup.number().transform(value => (isEmpty(value) ? 0 : value)),
  vehicleId: Yup.mixed(),
  variant: Yup.string(),
  vin: Yup.string(),
  mileage: Yup.mixed()
});
// @note: This schema used when all vehicle attr missing from vehicle results page
const vehicleCardSchema = Yup.object().shape({
  vehicleId: Yup.mixed().required(),
  year: Yup.string().required(),
  make: Yup.string().required(),
  model: Yup.string().required(),
  trim: Yup.string().required(),
  engineSize: Yup.string().required(),
  engineType: Yup.string().required(),
  driveType: Yup.string().required(),
  transmissionType: Yup.string().required(),
  vin: Yup.string(),
  mileage: Yup.mixed()
});

export default {
  createVehicle,
  editVehicle,
  vehicleDirtyCheck,
  vehicleCardSchema,
  vehicleFormAttributeSchema,
  editAddVehicleRequest
};
