import React, { useState, useEffect } from "react";
import Alert from "@cx/ui/Alert";
import PriceInput from "@cx/ui/PriceInput";
import format from "../../../utils/format";
import util from "../../../utils/util";
import cloneDeep from "lodash/cloneDeep";
import SelectInput from "@cx/ui/SelectInput";
import isArray from "lodash/isArray";
import {
  DISCOUNT_CANNOT_BE_APPLIED,
  DISCOUNT_CANNOT_BE_APPLIED_WITH_EXISTING,
  DISCOUNT_TYPES,
  MAXIMUM_DISCOUNT_APPLIED,
  MAXIMUM_DOLLAR_DISCOUNT_APPLIED
} from "../catalog-discount.constants";
import {
  ADD_CATALOG_RO_LEVEL_DISCOUNT,
  MODIFY_CATALOG_RO_LEVEL_DISCOUNT
} from "../../../constants/adjustment.constant";
import {
  didROHasAnyROLevelDiscount,
  didROHasServicesWithDiscount,
  getAdjustedPriceLineLevelDiscount,
  getAdjustedPriceROLevelDiscount,
  getDiscountLabelValue,
  getPayer,
  getTotalServicePrice,
  getTotalServicePriceForRO,
  hasNonCombiningDiscount,
  sumOfAllLineAppliedLaborPartsDiscounts,
  sumOfAlreadyAppliedLaborPartDiscounts
} from "./catalog-discounts.utils";
import RODiscountPayerSelectComponent from "./ro-discount-payer-select.component";
import PriceRenderer from "./price-renderer.component";

const DiscountRenderContentComponent = props => {
  const {
    availableDiscountOptions,
    service,
    onUpdatePayload,
    selectedDiscountModify,
    priceAdjustmentType,
    quoteSummary
  } = props;

  if (selectedDiscountModify !== null) {
    selectedDiscountModify.laborDepartmentAllocations =
      selectedDiscountModify?.allocation?.laborDepartmentAllocations;
    selectedDiscountModify.partsDepartmentAllocations =
      selectedDiscountModify?.allocation?.partsDepartmentAllocations;
  }

  const [discountList, setDiscountList] = useState([]);
  const [selectedDiscount, setSelectedDiscount] = useState(
    selectedDiscountModify || null
  );
  const [discountLabel, setDiscountLabel] = useState(() => {
    if (selectedDiscount?.discountType === DISCOUNT_TYPES.PERCENT)
      return `Percentage discount `;
    if (selectedDiscount?.discountType === DISCOUNT_TYPES.DOLLAR)
      return `Dollar discount `;
    if (selectedDiscount?.discountType === DISCOUNT_TYPES.ARV)
      return `Retail value discount`;
    if (selectedDiscount?.discountType === DISCOUNT_TYPES.VARIABLE)
      return `Variable discount `;
  });
  const [discountMax, setDiscountMax] = useState(
    (selectedDiscountModify && selectedDiscountModify?.discountMax) || 0
  );

  const [actualLaborDiscountValue, setActualLaborDiscountValue] = useState(
    (selectedDiscountModify && selectedDiscountModify?.appliedLaborDiscount) ||
      0
  );
  const [actualPartDiscountValue, setActualPartDiscountValue] = useState(
    (selectedDiscountModify && selectedDiscountModify?.appliedPartsDiscount) ||
      0
  );
  const [adjustedServicePriceValue, setAdjustedServicePriceValue] = useState(
    service?.lineServicePrice ?? service?.servicePrice ?? 0
  );
  //* used in priceRenderer
  const hideLaborRow = !(
    selectedDiscount?.laborDepartmentAllocations?.allocationPercentage === 0
  );
  const hidePartsRow = !(
    selectedDiscount?.partsDepartmentAllocations?.allocationPercentage === 0
  );

  const [labelError, setLabelError] = useState(false);

  const onVariablePriceChange = e => {
    const clonedDiscount = cloneDeep(selectedDiscount);

    clonedDiscount.dollarAmount = e.target.value;

    [ADD_CATALOG_RO_LEVEL_DISCOUNT, MODIFY_CATALOG_RO_LEVEL_DISCOUNT].includes(
      priceAdjustmentType
    ) === true
      ? calculateDiscountRoLevel(clonedDiscount)
      : calculateDiscount(clonedDiscount);

    setSelectedDiscount(clonedDiscount);
  };

  useEffect(() => {
    //* case RO_level radio btn update
    onUpdatePayload(selectedDiscount);
  }, [onUpdatePayload, selectedDiscount]);

  useEffect(() => {
    if (priceAdjustmentType === MODIFY_CATALOG_RO_LEVEL_DISCOUNT) {
      const updatedSelectedModifyDiscount = cloneDeep(selectedDiscountModify);
      const payer = quoteSummary?.payers?.find(
        payer => payer?.payType === selectedDiscount?.feeDiscountPayerPayCode
      );
      updatedSelectedModifyDiscount.payerObject = payer;
      setSelectedDiscount(updatedSelectedModifyDiscount);
      setAdjustedServicePriceValue(
        updatedSelectedModifyDiscount?.payerObject?.subTotalBeforeTaxes
      );
    }
  }, [selectedDiscountModify]);

  useEffect(() => {
    //* creating discount dropdown options for both add and modify flow
    if (
      [
        ADD_CATALOG_RO_LEVEL_DISCOUNT,
        MODIFY_CATALOG_RO_LEVEL_DISCOUNT
      ].includes(priceAdjustmentType) === true
    )
      setRoLevelDiscountOptions();
    else setLineLevelDiscountOptions();
  }, [availableDiscountOptions]);

  const setLineLevelDiscountOptions = () => {
    if (availableDiscountOptions) {
      const options = [];
      availableDiscountOptions?.map(discount => {
        const payTypesSupported =
          isArray(discount?.payTypes) === false
            ? discount?.payTypes?.split(",")
            : discount?.payTypes;
        const isServicePayTypeSupported = payTypesSupported.findIndex(
          type => type === service?.payTypeCode
        );
        const isDiscountAlreadyAdded = service?.catalogDiscounts.findIndex(
          serviceDiscount =>
            serviceDiscount?.dealerDiscountId == discount?.dealerDiscountId
        );
        if (isServicePayTypeSupported !== -1 && isDiscountAlreadyAdded === -1) {
          const option = {};
          option.label = discount.description;
          option.value = discount.dealerDiscountId + "";
          options.push(option);
        }
      });
      //* for adding selected discount in dropdown when modifing discount
      if (selectedDiscountModify !== null) {
        const discountOption = {};
        discountOption.label = selectedDiscountModify?.description;
        discountOption.value = selectedDiscountModify?.dealerDiscountId;
        options.push(discountOption);
      }
      setDiscountList(options);
    }
  };

  const setRoLevelDiscountOptions = () => {
    const isRODiscountAlreadyAdded = disc => {
      return quoteSummary?.payers?.some(
        payer =>
          payer?.catalogDiscounts &&
          payer?.catalogDiscounts?.some(
            discount => discount?.dealerDiscountId == disc?.dealerDiscountId
          )
      );
    };

    const isRoLevelPayTypePresent = (payTypes, payerObject) => {
      return payerObject?.some(payer => payTypes?.includes(payer?.payType));
    };

    if (availableDiscountOptions) {
      const options = [];
      availableDiscountOptions?.map(discount => {
        const payTypesSupported =
          isArray(discount?.payTypes) === false
            ? discount?.payTypes?.split(",")
            : discount?.payTypes;
        const isServicePayTypeSupported = isRoLevelPayTypePresent(
          payTypesSupported,
          quoteSummary?.payers
        );
        const isDiscountAlreadyAdded = isRODiscountAlreadyAdded(discount);

        if (isServicePayTypeSupported && !isDiscountAlreadyAdded) {
          const option = {};
          option.label = discount?.description;
          option.value = discount?.dealerDiscountId + "";
          options.push(option);
        }
      });
      //* for adding selected discount in dropdown when modifing discount
      if (
        selectedDiscountModify !== null &&
        priceAdjustmentType === MODIFY_CATALOG_RO_LEVEL_DISCOUNT
      ) {
        const discountOption = {};
        discountOption.label = selectedDiscountModify?.description;
        discountOption.value = selectedDiscountModify?.dealerDiscountId;
        options.push(discountOption);
      }

      setDiscountList(options);
    }
  };

  useEffect(() => {
    //* this useEffect is for updating the error labels
    switch (selectedDiscount?.discountType) {
      case DISCOUNT_TYPES.DOLLAR:
        parseFloat(actualLaborDiscountValue + actualPartDiscountValue) >=
        selectedDiscount?.dollarAmount
          ? setLabelError(true)
          : setLabelError(false);
        break;
      case DISCOUNT_TYPES.VARIABLE:
        parseInt(selectedDiscount?.dollarAmount, 10) > discountMax
          ? setLabelError(true)
          : setLabelError(false);
        break;
      case DISCOUNT_TYPES.PERCENT:
        parseFloat(actualLaborDiscountValue + actualPartDiscountValue) >=
        discountMax
          ? setLabelError(true)
          : setLabelError(false);
        break;
      case DISCOUNT_TYPES.ARV:
        parseFloat(actualLaborDiscountValue + actualPartDiscountValue) >
        discountMax
          ? setLabelError(true)
          : setLabelError(false);
        break;
      default:
        setLabelError(false);
    }
  }, [
    selectedDiscount?.dollarAmount,
    actualLaborDiscountValue,
    actualPartDiscountValue,
    adjustedServicePriceValue
  ]);

  const calculateDiscount = selectedDiscount => {
    let actualLaborDiscount;
    let actualPartDiscount;
    let adjustedPrice;

    let adjustedLaborDiscount = 0;
    let adjustedPartsDiscount = 0;

    const {
      discountType,
      dollarAmount,
      discountMax,
      laborDepartmentAllocations,
      partsDepartmentAllocations,
      appliedLaborDiscount,
      appliedPartsDiscount,
      appliedDiscount
    } = selectedDiscount;

    selectedDiscount.payTypes =
      isArray(selectedDiscount?.payTypes) === false
        ? selectedDiscount.payTypes.split(",")
        : selectedDiscount.payTypes;
    selectedDiscount.maxLaborDiscount = 0;
    selectedDiscount.maxPartsDiscount = 0;

    selectedDiscount.allocation = {
      laborDepartmentAllocations,
      partsDepartmentAllocations
    };

    const isSameDiscount =
      selectedDiscount?.dealerDiscountId ==
      selectedDiscountModify?.dealerDiscountId;

    const isModify = priceAdjustmentType.includes("MODIFY");

    const laborAllocationPercentage =
      laborDepartmentAllocations?.allocationPercentage;

    const partsAllocationPercentage =
      partsDepartmentAllocations?.allocationPercentage;

    const alreadyAppliedDiscounts = sumOfAlreadyAppliedLaborPartDiscounts(
      service?.catalogDiscounts
    );

    const adjustedAppliedLaborDiscount =
      alreadyAppliedDiscounts?.appliedLaborDiscount;
    const adjustedAppliedPartDiscount =
      alreadyAppliedDiscounts?.appliedPartsDiscount;

    //* setting up final parts and labor price after adusting it with already applied Discounts
    adjustedLaborDiscount = isModify
      ? service?.finalLaborPrice -
        adjustedAppliedLaborDiscount +
        selectedDiscountModify?.appliedLaborDiscount
      : service?.finalLaborPrice - adjustedAppliedLaborDiscount;
    adjustedPartsDiscount = isModify
      ? service?.finalPartsPrice -
        adjustedAppliedPartDiscount +
        selectedDiscountModify?.appliedPartsDiscount
      : service?.finalPartsPrice - adjustedAppliedPartDiscount;

    switch (discountType) {
      case DISCOUNT_TYPES.DOLLAR: // ! DOLLAR
        actualLaborDiscount = appliedLaborDiscount
          ? appliedLaborDiscount
          : (laborAllocationPercentage / 100) * dollarAmount;
        actualPartDiscount = appliedPartsDiscount
          ? appliedPartsDiscount
          : (partsAllocationPercentage / 100) * dollarAmount;

        if (actualLaborDiscount + actualPartDiscount >= dollarAmount) {
          checkForMaxDiscount();
        }

        break;
      case DISCOUNT_TYPES.PERCENT: // ! PERCENT
        {
          let actualDiscount = appliedDiscount
            ? appliedDiscount
            : util.roundCurrencyValue(
                (selectedDiscount?.percentage / 100) *
                  getTotalServicePrice(service)
              );

          if (discountMax) {
            actualDiscount = Math.min(actualDiscount, discountMax);
          }

          actualPartDiscount = appliedPartsDiscount
            ? appliedPartsDiscount
            : util.roundCurrencyValue(
                (partsAllocationPercentage / 100) *
                  Math.min(actualDiscount, discountMax)
              );

          actualLaborDiscount = appliedLaborDiscount
            ? appliedLaborDiscount
            : actualDiscount - actualPartDiscount;

          if (actualLaborDiscount + actualPartDiscount >= discountMax) {
            checkForMaxDiscount();
          }
        }
        break;
      case DISCOUNT_TYPES.VARIABLE: // ! VARIABLE
        actualLaborDiscount = (laborAllocationPercentage / 100) * dollarAmount;
        actualPartDiscount = (partsAllocationPercentage / 100) * dollarAmount;

        break;
      case DISCOUNT_TYPES.ARV: // ! ARV
        actualLaborDiscount = appliedLaborDiscount
          ? appliedLaborDiscount
          : (laborAllocationPercentage / 100) * dollarAmount;
        actualPartDiscount = appliedPartsDiscount
          ? appliedPartsDiscount
          : (partsAllocationPercentage / 100) * dollarAmount;

        break;
    }

    setActualLaborDiscountValue(
      Math.min(actualLaborDiscount, adjustedLaborDiscount)
    );
    setActualPartDiscountValue(
      Math.min(actualPartDiscount, adjustedPartsDiscount)
    );
    // eslint-disable-next-line
    adjustedPrice = getAdjustedPriceLineLevelDiscount(
      adjustedPrice,
      isSameDiscount,
      service,
      actualLaborDiscount,
      adjustedLaborDiscount,
      actualPartDiscount,
      adjustedPartsDiscount,
      selectedDiscountModify
    );

    setAdjustedServicePriceValue(adjustedPrice);

    const clonedDiscount = cloneDeep(selectedDiscount);
    onUpdatePayload(clonedDiscount);
  };

  const calculateDiscountRoLevel = selectedDiscount => {
    let actualLaborDiscount;
    let actualPartDiscount;
    let adjustedPrice;

    let adjustedLaborDiscount = 0;
    let adjustedPartsDiscount = 0;

    const discountType = selectedDiscount?.discountType;
    const dollarAmount = selectedDiscount?.dollarAmount;
    const discountMax = selectedDiscount?.discountMax;
    const laborDepartmentAllocations =
      selectedDiscount?.laborDepartmentAllocations;
    const partsDepartmentAllocations =
      selectedDiscount?.partsDepartmentAllocations;
    const appliedLaborDiscount = selectedDiscount?.appliedLaborDiscount;
    const appliedPartsDiscount = selectedDiscount?.appliedPartsDiscount;
    const appliedDiscount = selectedDiscount?.appliedDiscount;
    const payerObject = selectedDiscount?.payerObject;

    const isSameDiscount =
      selectedDiscount?.dealerDiscountId ==
      selectedDiscountModify?.dealerDiscountId;

    const isModify = priceAdjustmentType.includes("MODIFY");

    const subTotalBeforeTaxes = payerObject?.subTotalBeforeTaxes;
    const subTotalLineLaborBasePrice = payerObject?.subTotalLineLaborBasePrice;
    const subTotalLinePartsBasePrice = payerObject?.subTotalLinePartsBasePrice;

    const subTotalLineLaborDisccount = payerObject?.subTotalLineLaborDisccount;
    const subTotalLinePartsDisccount = payerObject?.subTotalLinePartsDisccount;

    selectedDiscount.payTypes =
      isArray(selectedDiscount?.payTypes) === false
        ? selectedDiscount.payTypes.split(",")
        : selectedDiscount.payTypes;
    selectedDiscount.maxLaborDiscount = 0;
    selectedDiscount.maxPartsDiscount = 0;

    selectedDiscount.allocation = {
      laborDepartmentAllocations,
      partsDepartmentAllocations
    };

    const laborAllocationPercentage =
      laborDepartmentAllocations?.allocationPercentage;

    const partsAllocationPercentage =
      partsDepartmentAllocations?.allocationPercentage;

    const alreadyAppliedDiscounts = sumOfAllLineAppliedLaborPartsDiscounts(
      quoteSummary?.payers,
      payerObject?.payType
    );

    const adjustedAppliedLaborDiscount =
      alreadyAppliedDiscounts?.appliedLaborDiscount;
    const adjustedAppliedPartDiscount =
      alreadyAppliedDiscounts?.appliedPartsDiscount;

    //* setting up final parts and labor price after adusting it with already applied Discounts
    adjustedLaborDiscount = isModify
      ? subTotalLineLaborBasePrice -
        adjustedAppliedLaborDiscount -
        subTotalLineLaborDisccount +
        (isSameDiscount &&
        payerObject?.payType === selectedDiscountModify?.feeDiscountPayerPayCode
          ? selectedDiscountModify?.appliedLaborDiscount
          : 0)
      : subTotalLineLaborBasePrice -
        adjustedAppliedLaborDiscount -
        subTotalLineLaborDisccount;
    adjustedPartsDiscount = isModify
      ? subTotalLinePartsBasePrice -
        adjustedAppliedPartDiscount -
        subTotalLinePartsDisccount +
        (isSameDiscount &&
        payerObject?.payType === selectedDiscountModify?.feeDiscountPayerPayCode
          ? selectedDiscountModify?.appliedPartsDiscount
          : 0)
      : subTotalLinePartsBasePrice -
        adjustedAppliedPartDiscount -
        subTotalLinePartsDisccount;

    switch (discountType) {
      case DISCOUNT_TYPES.DOLLAR: // ! DOLLAR
        actualLaborDiscount = appliedLaborDiscount
          ? appliedLaborDiscount
          : (laborAllocationPercentage / 100) * dollarAmount;
        actualPartDiscount = appliedPartsDiscount
          ? appliedPartsDiscount
          : (partsAllocationPercentage / 100) * dollarAmount;

        if (actualLaborDiscount + actualPartDiscount >= dollarAmount) {
          checkForMaxDiscount();
        }
        break;
      case DISCOUNT_TYPES.PERCENT: // ! PERCENT
        {
          const actualDiscount = appliedDiscount
            ? appliedDiscount
            : (selectedDiscount?.percentage / 100) *
              getTotalServicePriceForRO(
                subTotalLineLaborBasePrice,
                subTotalLinePartsBasePrice
              );

          actualLaborDiscount = appliedLaborDiscount
            ? appliedLaborDiscount
            : (laborAllocationPercentage / 100) *
              Math.min(actualDiscount, discountMax);
          actualPartDiscount = appliedPartsDiscount
            ? appliedPartsDiscount
            : (partsAllocationPercentage / 100) *
              Math.min(actualDiscount, discountMax);

          if (actualLaborDiscount + actualPartDiscount >= discountMax) {
            checkForMaxDiscount();
          }
        }
        break;
      case DISCOUNT_TYPES.VARIABLE: // ! VARIABLE
        actualLaborDiscount = (laborAllocationPercentage / 100) * dollarAmount;
        actualPartDiscount = (partsAllocationPercentage / 100) * dollarAmount;
        break;
      case DISCOUNT_TYPES.ARV: // ! ARV
        actualLaborDiscount = appliedLaborDiscount
          ? appliedLaborDiscount
          : (laborAllocationPercentage / 100) * dollarAmount;
        actualPartDiscount = appliedPartsDiscount
          ? appliedPartsDiscount
          : (partsAllocationPercentage / 100) * dollarAmount;
        break;
    }

    setActualLaborDiscountValue(
      Math.min(actualLaborDiscount, adjustedLaborDiscount)
    );
    setActualPartDiscountValue(
      Math.min(actualPartDiscount, adjustedPartsDiscount)
    );
    // eslint-disable-next-line
    adjustedPrice = getAdjustedPriceROLevelDiscount(
      adjustedPrice,
      isSameDiscount,
      subTotalBeforeTaxes,
      actualLaborDiscount,
      adjustedLaborDiscount,
      subTotalLineLaborBasePrice,
      actualPartDiscount,
      adjustedPartsDiscount,
      subTotalLinePartsBasePrice,
      selectedDiscountModify
    );

    setAdjustedServicePriceValue(adjustedPrice);

    const clonedDiscount = cloneDeep(selectedDiscount);
    onUpdatePayload(clonedDiscount);
  };

  const onInputChange = value => {
    //* Find current discount
    const currentDiscount = availableDiscountOptions?.filter(
      c => c.dealerDiscountId == value
    );

    if (
      selectedDiscountModify &&
      selectedDiscountModify?.dealerDiscountId == value
    ) {
      //* modified
      currentDiscount[0] = selectedDiscountModify;
    }
    selectedDiscountModify && checkIfDiscountCanBeApplied();

    // * Get payer's Object based on RO PayType RO_LEVEL
    if (
      [
        ADD_CATALOG_RO_LEVEL_DISCOUNT,
        MODIFY_CATALOG_RO_LEVEL_DISCOUNT
      ].includes(priceAdjustmentType) === true
    ) {
      const payerForDiscount = getPayer(currentDiscount[0], quoteSummary);
      currentDiscount[0].payerObject = payerForDiscount;
      currentDiscount[0].feeDiscountPayerPayCode = payerForDiscount?.payType;
      //* For displying live value of parts/labor discount
      setSelectedDiscount(currentDiscount[0]);
      getDiscountLabelValue(currentDiscount[0], setDiscountLabel);
      setDiscountMax(currentDiscount[0].discountMax);
      //* calculating and updating payload RO LVL
      calculateDiscountRoLevel(currentDiscount[0]);
    } else {
      //* For displying live value of parts/labor discount
      setSelectedDiscount(currentDiscount[0]);
      getDiscountLabelValue(currentDiscount[0], setDiscountLabel);
      setDiscountMax(currentDiscount[0].discountMax);
      //* calculating and updating payload
      calculateDiscount(currentDiscount[0]);
    }
  };

  const checkIfDiscountCanBeApplied = () => {
    //* isCurrentDiscountAllowingOthers = true means cannot add a discount show err
    const isCurrentDiscountAllowingOthers =
      selectedDiscount?.applyWithOtherDiscount === 0;
    const isROHaveNotAllowedDiscount = hasNonCombiningDiscount(
      quoteSummary?.payers
    );

    const isServicesHaveNotAllowedDiscount = hasNonCombiningDiscount(
      quoteSummary?.quoteServices
    );

    const isROLevelDiscountAvailable = didROHasAnyROLevelDiscount();
    const isServiceLevelDiscountAvailable = didROHasServicesWithDiscount();

    if (priceAdjustmentType.includes("RO_LEVEL") === true) {
      //* For RO LEVEL Discounts logic

      const catalogDiscounts =
        selectedDiscount?.payerObject?.catalogDiscounts ?? [];

      if (
        (catalogDiscounts && catalogDiscounts?.length === 0) ||
        (selectedDiscountModify !== null && catalogDiscounts.length === 1)
      ) {
        if (isServiceLevelDiscountAvailable === true) {
          if (isServicesHaveNotAllowedDiscount === false) {
            if (isCurrentDiscountAllowingOthers === true) {
              return DISCOUNT_CANNOT_BE_APPLIED;
            } else return null;
          } else return DISCOUNT_CANNOT_BE_APPLIED;
        } else if (isROLevelDiscountAvailable === true) {
          if (isROHaveNotAllowedDiscount === false) {
            if (isCurrentDiscountAllowingOthers === true) {
              if (
                priceAdjustmentType.includes("MODIFY") &&
                catalogDiscounts.length === 1
              )
                return null;
              else return DISCOUNT_CANNOT_BE_APPLIED;
            } else return null;
          } else if (isROHaveNotAllowedDiscount === true) {
            if (
              priceAdjustmentType.includes("MODIFY") &&
              catalogDiscounts.length === 1
            ) {
              return null;
            } else return DISCOUNT_CANNOT_BE_APPLIED;
          }
        } else return null;
      } else {
        //* alreadyAddedDiscountAllowed = true means
        //* a discount is already added which do not allow other discounts
        const alreadyAddedDiscountAllowed = catalogDiscounts?.some(c =>
          selectedDiscountModify
            ? selectedDiscountModify.dealerDiscountId != c.dealerDiscountId &&
              c.applyWithOtherDiscount === 0
            : c.applyWithOtherDiscount === 0
        );
        if (
          isROHaveNotAllowedDiscount === true ||
          isServicesHaveNotAllowedDiscount === true
        ) {
          return DISCOUNT_CANNOT_BE_APPLIED;
        }
        if (
          isROHaveNotAllowedDiscount === true ||
          isServicesHaveNotAllowedDiscount === true
        ) {
          return DISCOUNT_CANNOT_BE_APPLIED;
        }

        if (
          isCurrentDiscountAllowingOthers === false &&
          alreadyAddedDiscountAllowed === false
        ) {
          return null;
        } else if (
          isCurrentDiscountAllowingOthers === false &&
          alreadyAddedDiscountAllowed === true
        ) {
          if (selectedDiscount === null) return null;
          else return DISCOUNT_CANNOT_BE_APPLIED_WITH_EXISTING;
        } else if (
          isCurrentDiscountAllowingOthers === true &&
          alreadyAddedDiscountAllowed === false
        ) {
          return DISCOUNT_CANNOT_BE_APPLIED;
        } else if (
          isCurrentDiscountAllowingOthers === true &&
          alreadyAddedDiscountAllowed === true
        ) {
          return DISCOUNT_CANNOT_BE_APPLIED;
        }
      }
    } else {
      //* Fresh case no discount added
      if (
        (service?.catalogDiscounts && service.catalogDiscounts.length === 0) ||
        (selectedDiscountModify !== null &&
          service.catalogDiscounts.length === 1)
      ) {
        if (isROLevelDiscountAvailable === true) {
          if (isROHaveNotAllowedDiscount === false) {
            if (isCurrentDiscountAllowingOthers === true) {
              return DISCOUNT_CANNOT_BE_APPLIED;
            } else return null;
          } else return DISCOUNT_CANNOT_BE_APPLIED;
        } else return null;
      } else {
        //* alreadyAddedDiscountAllowed = true means
        //* a discount is already added which do not allow other discounts
        const alreadyAddedDiscountAllowed = service?.catalogDiscounts?.some(c =>
          selectedDiscountModify
            ? selectedDiscountModify.dealerDiscountId != c.dealerDiscountId &&
              c.applyWithOtherDiscount === 0
            : c.applyWithOtherDiscount === 0
        );
        if (isROHaveNotAllowedDiscount === true) {
          return DISCOUNT_CANNOT_BE_APPLIED;
        }
        if (
          isCurrentDiscountAllowingOthers === false &&
          alreadyAddedDiscountAllowed === false
        ) {
          return null;
        } else if (
          isCurrentDiscountAllowingOthers === false &&
          alreadyAddedDiscountAllowed === true
        ) {
          if (selectedDiscount === null) return null;
          else return DISCOUNT_CANNOT_BE_APPLIED_WITH_EXISTING;
        } else if (
          isCurrentDiscountAllowingOthers === true &&
          alreadyAddedDiscountAllowed === false
        ) {
          return DISCOUNT_CANNOT_BE_APPLIED;
        } else if (
          isCurrentDiscountAllowingOthers === true &&
          alreadyAddedDiscountAllowed === true
        ) {
          return DISCOUNT_CANNOT_BE_APPLIED;
        }
      }
    }
  };

  const checkForMaxDiscount = () => {
    if (
      selectedDiscount?.discountType === DISCOUNT_TYPES.PERCENT &&
      actualLaborDiscountValue + actualPartDiscountValue >= discountMax
    ) {
      return MAXIMUM_DISCOUNT_APPLIED;
    } else if (
      selectedDiscount?.discountType === DISCOUNT_TYPES.DOLLAR &&
      actualLaborDiscountValue + actualPartDiscountValue >=
        selectedDiscount?.dollarAmount
    ) {
      return MAXIMUM_DOLLAR_DISCOUNT_APPLIED;
    }
  };

  return (
    <div className="catalog-discount">
      <SelectInput
        htmlId="catalogDiscount"
        name="catalog-discount"
        enableMultiSelect={false}
        disabled={discountList.length === 0}
        onChange={cxEvent => {
          onInputChange(cxEvent.target.value);
        }}
        placeholder="Select Discount"
        options={discountList}
        value={selectedDiscount?.description || ""}
        displayDeselectOption={false}
      />
      <label>
        <span>{discountLabel}</span>
        {selectedDiscount?.discountType === DISCOUNT_TYPES.PERCENT ||
        selectedDiscount?.discountType === DISCOUNT_TYPES.VARIABLE ||
        selectedDiscount?.discountType === DISCOUNT_TYPES.ARV ? (
          <span className={`${labelError ? "Ferrari500" : "Titanium700"}`}>
            {format.currency(discountMax)} Max
          </span>
        ) : null}
        {selectedDiscount?.discountType === DISCOUNT_TYPES.DOLLAR ? (
          <span className={`${labelError ? "Ferrari500" : "Titanium700"}`}>
            {format.currency(selectedDiscount?.dollarAmount)} Max
          </span>
        ) : null}
      </label>

      {selectedDiscount &&
      checkIfDiscountCanBeApplied() !== DISCOUNT_CANNOT_BE_APPLIED &&
      checkIfDiscountCanBeApplied() !==
        DISCOUNT_CANNOT_BE_APPLIED_WITH_EXISTING &&
      [
        ADD_CATALOG_RO_LEVEL_DISCOUNT,
        MODIFY_CATALOG_RO_LEVEL_DISCOUNT
      ].includes(priceAdjustmentType) ? (
        <RODiscountPayerSelectComponent
          selectedDiscount={selectedDiscount}
          quoteSummary={quoteSummary}
          setSelectedDiscount={setSelectedDiscount}
          calculate={discount => {
            calculateDiscountRoLevel(discount);
          }}
        />
      ) : null}

      {checkIfDiscountCanBeApplied() !== DISCOUNT_CANNOT_BE_APPLIED &&
      checkIfDiscountCanBeApplied() !==
        DISCOUNT_CANNOT_BE_APPLIED_WITH_EXISTING &&
      selectedDiscount?.discountType === DISCOUNT_TYPES.VARIABLE ? (
        <PriceInput
          htmlId="variableDiscount"
          label="Discount"
          name="variableDiscount"
          onChange={e => onVariablePriceChange(e)}
          value={
            selectedDiscount?.dollarAmount === 0
              ? null
              : selectedDiscount?.dollarAmount
          }
          error={
            selectedDiscount?.dollarAmount > discountMax ? "Over limit" : ""
          }
          maxValue={discountMax}
        />
      ) : null}

      {
        // ! show no discount selected when selctedDiscount is null
        !selectedDiscount ? (
          <div className="no-discount">Select discount to see details here</div>
        ) : null
      }

      {
        // ! show price renderer
        (selectedDiscount &&
          selectedDiscountModify?.dealerDiscountId ==
            selectedDiscount?.dealerDiscountId) ||
        (selectedDiscount &&
          checkIfDiscountCanBeApplied() !== DISCOUNT_CANNOT_BE_APPLIED &&
          checkIfDiscountCanBeApplied() !==
            DISCOUNT_CANNOT_BE_APPLIED_WITH_EXISTING) ? (
          <>
            <PriceRenderer
              selectedDiscount={selectedDiscount}
              priceAdjustmentType={priceAdjustmentType}
              hideLaborRow={hideLaborRow}
              hidePartsRow={hidePartsRow}
              labelError={labelError}
              actualLaborDiscountValue={actualLaborDiscountValue}
              actualPartDiscountValue={actualPartDiscountValue}
              adjustedServicePriceValue={adjustedServicePriceValue}
            />
            {checkForMaxDiscount() === MAXIMUM_DISCOUNT_APPLIED ? (
              <Alert htmlId="warningAlert" type="warning">
                Maximum discount applied
              </Alert>
            ) : null}
            {checkForMaxDiscount() === MAXIMUM_DOLLAR_DISCOUNT_APPLIED ? (
              <Alert htmlId="warningAlert" type="warning">
                Discount price exceeded. Maximum discount applied
              </Alert>
            ) : null}
          </>
        ) : null
      }
      {selectedDiscount &&
      checkIfDiscountCanBeApplied() === DISCOUNT_CANNOT_BE_APPLIED ? (
        <Alert htmlId="dangerAlert" type="danger">
          A discount that cannot be combined with other discounts has already
          been applied.
        </Alert>
      ) : null}
      {selectedDiscount &&
      selectedDiscountModify?.dealerDiscountId !=
        selectedDiscount?.dealerDiscountId &&
      checkIfDiscountCanBeApplied() ===
        DISCOUNT_CANNOT_BE_APPLIED_WITH_EXISTING ? (
        <Alert htmlId="dangerAlert" type="danger">
          A discount that cannot be combined with other discounts has already
          been applied to this service line.
        </Alert>
      ) : null}
    </div>
  );
};
export default DiscountRenderContentComponent;
