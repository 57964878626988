// @ts-check
import React from "react";
import QuotesOdometerChart from "./charts/quotes-odometer-chart.component";
import QuotesBreakdownBoard from "./boards/quotes-breakdown-board.component";
import QuotesAndTrendsHeader from "./headers/quotes-and-trends-header.component";
import QuotesAreaChart from "./charts/quotes-area-chart.component";
// eslint-disable-next-line unused-imports/no-unused-imports
import * as CommonTypes from "../../../../../@types/common.types";
// eslint-disable-next-line unused-imports/no-unused-imports
import * as ModelsTypes from "../../../../../@types/models.types";

/**
 * @description "Quotes and trends" section. Renders section header, quotes odometer, numeric breakdown board and quotes stacked area chart.
 * It should be kept as a dummy component. Any logic or fetching from context should be done in the section container.
 * @param {{numericBreakdownData: ModelsTypes.ChartData, areaTimelineData: Array<ModelsTypes.AreaChartData> ,localeStrings: CommonTypes.LocaleStrings, dateRange: string}} props
 * @returns {CommonTypes.Component}
 */
const QuotesSectionComponent = props => {
  const { numericBreakdownData, areaTimelineData, localeStrings, dateRange } =
    props;
  const {
    quotesTotalLabel,
    breakdownBoardLabels,
    quotesAndTrendsHeader,
    quotesAndTrendsTooltipMessage
  } = localeStrings;
  return (
    <div className="section__wrapper">
      <div className="header">
        <QuotesAndTrendsHeader
          header={quotesAndTrendsHeader}
          tooltipMessage={quotesAndTrendsTooltipMessage}
        />
      </div>
      <div className="quotes-and-trends quotes-and-trends__wrapper">
        <QuotesOdometerChart
          width="100%"
          height={130}
          data={numericBreakdownData}
          totalLabel={quotesTotalLabel}
        />
        <QuotesAreaChart
          width="100%"
          height={130}
          data={areaTimelineData}
          dateRange={dateRange}
        />
        <QuotesBreakdownBoard
          data={numericBreakdownData}
          labels={/** @type {string[]} */ (breakdownBoardLabels)}
        />
      </div>
    </div>
  );
};

export default QuotesSectionComponent;
