import { makeSecureRestApi } from "../../../api/xmmAxios";

const saveQuoteDealerSettingSvc = (dealerCode, payload) => {
  const restEndPoint = `/settings/${dealerCode}`;

  return new Promise((resolve, reject) => {
    makeSecureRestApi(
      {
        url: restEndPoint,
        method: "post",
        data: payload,
        params: {}
      },
      response => {
        resolve(response);
      },
      error => {
        reject(error);
      },
      "Unable to save dealer settings."
    );
  });
};

export { saveQuoteDealerSettingSvc };
