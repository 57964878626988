/* This objects are freezed, used for cell renderer  */
export const UnitOfMeasureMap = {
  Gallons: "gal",
  Grams: "g",
  Inches: "in",
  Liters: "l",
  Milliliters: "ml",
  Millimeters: "mm",
  Ounces: "oz",
  Pints: "pt",
  Quarts: "qt"
};

export const IncludedInspectionTags = [
  "inspect",
  "disclaimer",
  "lubricate",
  "reset",
  "tighten",
  "test"
];

export const GlobalOpsServiceType = {
  GLOBALCATALOG: "GlobalCatalog",
  DEALERCATALOG: "DealerPublishedCatalog",
  DIAGNOSIS: "Diagnose",
  DECLINED: "declined",
  AUTORECALL: "autorecall"
};

export const DealerPublishedCategory = {
  REPAIR: "repair",
  MAINTENANCE: "maintenance"
};

export const saveActionTypes = {
  NA: null,
  SAVE: "SAVE",
  SAVEANOTHER: "SAVEANOTHER"
};

export const OperationSources = {
  GLOBALCATALOG: "GlobalCatalog",
  DEALERCATALOG: "DealerPublishedCatalog",
  DECLINED: "DECLINED",
  MENU: "MENU",
  RECALL: "RECALL"
};

export const CatalogSources = {
  GLOBALCATALOG: "GlobalCatalog",
  DEALERCATALOG: "DealerPublishedCatalog",
  MENU: "MENU"
};

export const ServiceTypes = {
  GLOBALREPAIR: "globalRepair",
  DEALERPUBLISHED: "dealerPublished",
  DIAGNOSIS: "diagnosis",
  DECLINED: "declined",
  RECALL: "recall",
  MENU: "menu",
  TELLUSMORE: "tellusMore"
};
// These constants should be imported only in main project files but not in sub-module files
export const QuoteServiceTypes = {
  DEALER_PUB_MAINT_OPS: "DEALER_PUB_MAINT_OPS",
  DEALER_PUB_REPAIR_OPS: "DEALER_PUB_REPAIR_OPS",
  GLOBAL_REPAIR_OPS: "GLOBAL_REPAIR_OPS",
  MENU: "MENU",
  DECLINED: "DECLINED",
  RECALL: "RECALL"
};
