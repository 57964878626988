// @todo - Add util methods for dealerTire handlers
import dealerTireService from "../services/dealer-tire.service";
const getDealerTireParams = appContext => {
  let config = {};
  try {
    const {
      isDealerTireEnabled,
      dealer,
      localeStrings,
      webKey,
      appEnv,
      appSource,
      locale
    } = appContext;
    config = {
      isDealerTireEnabled,
      dealer,
      localeStrings,
      webKey,
      appEnv,
      appSource,
      locale
    };
    config.apiUrl = dealerTireService.getServiceQuotingApiUrl();
    config.styleUrl = getStyleUrl();
  } catch (err) {
    console.error(err);
    config = {};
  }
  return config;
};

function getAppEnvironment() {
  const { hostname } = window.location;
  const host = hostname.includes("localhost")
    ? "servicequoting.dev6.xtime.app.coxautoinc.com"
    : hostname;
  const hostArr = host.split(".");
  let quoteEnv = hostArr[1];
  quoteEnv = quoteEnv.includes("xtime") ? "prod" : quoteEnv;
  let returnedEnv = "qa5";
  switch (quoteEnv) {
    case "dev6":
      returnedEnv = "dev6";
      break;
    case "qa6":
      returnedEnv = "qa6";
      break;
    case "demo":
    case "pt3":
      returnedEnv = "pt3";
      break;
    case "stg4":
      returnedEnv = "stg4";
      break;
    case "prod":
      returnedEnv = "prod";
      break;
    default:
      returnedEnv = "qa5";
  }
  return returnedEnv;
}

// @dealer-tire - return with custom css file path
const getStyleUrl = () => {
  // let styleUrl = window.location.origin + "/resource/dealerTire.css";
  // if (window.location.host.includes("localhost")) {
  //   styleUrl = "https://servicequoting.dev6.xtime.app.coxautoinc.com/resource/dealerTire.css";
  // }
  const styleUrl = "";
  return styleUrl;
};

export { getDealerTireParams, getAppEnvironment, getStyleUrl };
