/* eslint-disable no-async-promise-executor */
import axiosService, { showApiFailureMessage } from "../../../api/xmmAxios";
import isEmpty from "lodash/isEmpty";
import { processMenusAPIData } from "../utils/data-util";
const getServicePoints = async (
  dealerCode,
  vehicle,
  mileage,
  drivingCondition,
  locale,
  useExactMileage
) => {
  return new Promise(async (resolve, reject) => {
    const {
      make,
      model,
      year,
      variant,
      trim,
      engineType,
      engineSize,
      driveType,
      transmissionType,
      vin,
      metaVehicleId
    } = vehicle;
    const axios = axiosService.getInstance();

    try {
      const { data } = await axios.get(
        `/quote/services/menus/${dealerCode}/make/${make}/model/${model}/year/${year}`,
        {
          params: {
            mileage,
            drivingCondition,
            variant,
            trim,
            engineType,
            engineSize,
            driveType,
            transmissionType,
            vin,
            metaVehicleId,
            locale,
            useExactMileage: useExactMileage ? useExactMileage : 0
          }
        }
      );

      if (data.hasOwnProperty("statusCode") && data.statusCode === 0) {
        let servicePoints = data.servicePoints || [];
        if (!isEmpty(data.servicePoints)) {
          const noMileages =
            data.nextMileage === 0 && data.previousMileage === 0;
          const noServicePoints =
            servicePoints.length === 1 && servicePoints[0].mileage === 0;
          servicePoints =
            noMileages || noServicePoints ? [] : data.servicePoints;

          processMenusAPIData(servicePoints, make);
          // console.log("getServicePoints API refined", servicePoints);
        }
        resolve(servicePoints || []);
      }
      reject();
    } catch (error) {
      showApiFailureMessage("Unable to retrieve service points.", error);
      reject(error);
    }
  });
};

export default {
  getServicePoints
};
