import React, { useEffect, useState } from "react";
import Grid from "@cx/ui/Grid";
import Row from "@cx/ui/Row";
import Col from "@cx/ui/Col";
import SearchableSelect from "@cx/ui/SearchableSelect";
import Button from "@cx/ui/Button";
import IconDelete from "@cx/ui/Icons/IconDelete";
import WarrantyExpenseCommonFieldComponent from "./warranty-expense-common-fields.component";
import WarrantyExpenseVORComponent from "./warranty-expsense-vor-field.component";
import { useEditServiceContext } from "../../state/edit-service.context";
import isEmpty from "lodash/isEmpty";
import WarrantyExpenseRentalCarFieldComponent from "./warranty-expense-rental-car-fields.component";
import "./csr.scss";
import ConfirmPopup from "../../../features/ui/modals/ConfirmPopup";

const WarrantyExpenseComponent = ({
  warrantyPayload,
  updateWarrantyPayload
}) => {
  const [selectedExpenseCode, setSelectedExpenseCode] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [techRowToDelete, setTechRowToDelete] = useState(null);
  const [selectedExpenseCodeList, setSelectedExpenseCodeList] = useState(
    warrantyPayload?.expenseCodes || []
  );
  const [expenseCodeOptions, setExpenseCodeOptions] = useState([]);
  const { state } = useEditServiceContext();
  const { expenseCodeList } = state;

  useEffect(() => {
    if (!isEmpty(expenseCodeList)) {
      if (expenseCodeList?.expensecode?.length > 0) {
        const formattedExpenseCodeList = formatExpenseCodeListOptions(
          expenseCodeList?.expensecode
        );
        setExpenseCodeOptions(formattedExpenseCodeList);
      }
    }
  }, [expenseCodeList]);

  const formatExpenseCodeListOptions = expenseCodes => {
    const options = [];
    expenseCodes.map(code => {
      const option = {};
      option.value = code?.expenseName;
      option.label =
        code?.expenseName + " - " + code?.expenseTypeDescription?.trim();
      options.push(option);
    });
    return options;
  };

  const onSelectExpense = cxEvent => {
    setSelectedExpenseCode(cxEvent?.target?.value[0]?.value);
  };

  const addExpenseCodeToService = () => {
    if (selectedExpenseCode !== null) {
      const isExpenseCodeExist = selectedExpenseCodeList?.findIndex(code =>
        code?.name?.includes(selectedExpenseCode)
      );
      if (isExpenseCodeExist === -1) {
        const expenseCode = expenseCodeList?.expensecode?.find(
          code => code?.expenseName === selectedExpenseCode
        );
        const expenseObject = {
          name:
            expenseCode?.expenseName +
            " - " +
            expenseCode?.expenseTypeDescription?.trim(),
          lineType: "",
          coverageAmount: "",
          coverageOne: {},
          coverageTwo: {},
          coverageThree: {}
        };

        setSelectedExpenseCodeList([...selectedExpenseCodeList, expenseObject]);
        updateWarrantyPayload({
          ...warrantyPayload,
          expenseCodes: [...selectedExpenseCodeList, expenseObject]
        });
      }
    }
  };

  const deleteExpenseCode = expenseCode => {
    if (expenseCode !== null) {
      const updatedExpenseCodeList = selectedExpenseCodeList?.filter(
        code => code?.name !== expenseCode?.name
      );
      setSelectedExpenseCodeList(updatedExpenseCodeList);
      updateWarrantyPayload({
        ...warrantyPayload,
        expenseCodes: updatedExpenseCodeList
      });
    }
  };

  const updateExpenseCommonFieldData = expensePayload => {
    const updatedExpenseCodeList = selectedExpenseCodeList?.map(code => {
      let selectedCode = "";
      if (code.name === expensePayload.name) {
        selectedCode = expensePayload;
      } else {
        selectedCode = code;
      }
      return selectedCode;
    });
    setSelectedExpenseCodeList(updatedExpenseCodeList);
    updateWarrantyPayload({
      ...warrantyPayload,
      expenseCodes: updatedExpenseCodeList
    });
  };

  const removeTechnicianPopup = (
    <ConfirmPopup
      title="Alert!"
      message="Are you sure you want to remove this expense code from the service?"
      show={showPopup}
      okText="Remove"
      cancelText="Cancel"
      okAction={() => {
        setShowPopup(false);
        deleteExpenseCode(techRowToDelete);
      }}
      cancelAction={() => {
        setShowPopup(false);
        setTechRowToDelete(null);
      }}
      hideCancel={false}
      hideOk={false}
      buttonStyle="danger"
    />
  );
  return (
    <>
      <Grid>
        <Row style={{ display: "flex" }}>
          <Col style={{ width: "300px" }}>
            <SearchableSelect
              htmlId="expenseCodeSelect"
              label="Add expense code"
              name="expenseCode"
              onChange={onSelectExpense}
              options={expenseCodeOptions}
              enableMultiSelect={false}
              value={selectedExpenseCode}
            />
          </Col>
          <Col className="m-t-md m-l">
            <Button
              aria-label="Add"
              htmlId="AddExpenseButton"
              buttonStyle="primary"
              disabled={isEmpty(selectedExpenseCode)}
              onClick={() => {
                setSelectedExpenseCode(null);
                addExpenseCodeToService();
              }}
            >
              Add
            </Button>
          </Col>
        </Row>
        {selectedExpenseCodeList?.length > 0
          ? selectedExpenseCodeList?.map(expenseCode => {
              return (
                <Row key={expenseCode?.name}>
                  <Row className="warranty-expense-code-row">
                    <Col className="m-l m-t">
                      <span className="warranty-selected-expense-code-text">
                        {expenseCode?.name}
                      </span>
                    </Col>
                    <Col className="m-r m-t" style={{ marginLeft: "auto" }}>
                      <IconDelete
                        onClick={() => {
                          setTechRowToDelete(expenseCode);
                          setShowPopup(true);
                        }}
                      />
                    </Col>
                  </Row>
                  <WarrantyExpenseCommonFieldComponent
                    expense={expenseCode}
                    warrantyPayload={warrantyPayload}
                    updateExpenseCommonFieldData={updateExpenseCommonFieldData}
                  />
                  {expenseCode?.name?.includes("502") ? (
                    <WarrantyExpenseRentalCarFieldComponent
                      expense={expenseCode}
                      updateExpenseRentalCarFieldData={
                        updateExpenseCommonFieldData
                      }
                    />
                  ) : null}
                  {expenseCode?.name?.includes("601") ? (
                    <WarrantyExpenseVORComponent expense={expenseCode} />
                  ) : null}
                </Row>
              );
            })
          : null}
      </Grid>
      {removeTechnicianPopup}
    </>
  );
};

export default WarrantyExpenseComponent;
