import React from "react";
import ChargeAccountPaymentComponent from "./components/charge-account-payment.component";
import CashPayment from "./components/cash-payment.component";
import CheckPayment from "./components/check-payment.component";
import { PAYMENT_TYPES } from "../../constants/csr.constants";
import DealerPolicyPaymentComponent from "./components/dealer-policy.component";
import CreditCardPaymentComponent from "./components/credit-card.component";

const CustomerPayPaymentLinesComponent = ({
  selectedPaymentLines,
  chargeAccountDetails,
  removePaymentLine,
  updateSelectedPaymentLines,
  payerConfig
}) => {
  return (
    <div
      data-testid="customer-payline-class-container"
      className={`customer-payline-class-container${
        selectedPaymentLines?.length > 2 ? " customer-overflow-class" : ""
      }`}
    >
      {selectedPaymentLines?.map(line => {
        return (
          <div
            key={`${line?.paymentMethod} ${
              line?.paymentId !== null ? line?.paymentId : line?.localPaymentId
            } `}
          >
            {line?.paymentMethod === PAYMENT_TYPES.CHARGE ? (
              <ChargeAccountPaymentComponent
                payerConfig={payerConfig}
                paymentLine={line}
                chargeAccountDetails={chargeAccountDetails}
                removePaymentLine={removePaymentLine}
                updateSelectedPaymentLines={updateSelectedPaymentLines}
              />
            ) : null}
            {line?.paymentMethod === PAYMENT_TYPES.CASH ? (
              <CashPayment
                paymentLine={line}
                removePaymentLine={removePaymentLine}
                updateSelectedPaymentLines={updateSelectedPaymentLines}
              />
            ) : null}
            {line?.paymentMethod === PAYMENT_TYPES.CHECK ? (
              <CheckPayment
                paymentLine={line}
                removePaymentLine={removePaymentLine}
                updateSelectedPaymentLines={updateSelectedPaymentLines}
                selectedPaymentLines={selectedPaymentLines}
              />
            ) : null}
            {line?.paymentMethod === PAYMENT_TYPES.DEALER_POLICY ? (
              <DealerPolicyPaymentComponent
                paymentLine={line}
                removePaymentLine={removePaymentLine}
                updateSelectedPaymentLines={updateSelectedPaymentLines}
              />
            ) : null}
            {line?.paymentMethod === PAYMENT_TYPES.CREDIT_CARD ? (
              <CreditCardPaymentComponent
                paymentLine={line}
                removePaymentLine={removePaymentLine}
                updateSelectedPaymentLines={updateSelectedPaymentLines}
              />
            ) : null}
          </div>
        );
      })}
    </div>
  );
};

export default CustomerPayPaymentLinesComponent;
