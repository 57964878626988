// SERVICES
import repairOrdersService from "../../../services/repair-orders.service";
import { logError } from "../../../../../services/log.service";

const createSearchTokens = searchTerm =>
  searchTerm.split(" ").filter(term => !!term);

/**
 * Escapes the `RegExp` special characters "^", "$", "\", ".", "*", "+",
 * "?", "(", ")", "[", "]", "{", "}", and "|" in `string`.
 * @param {string} [string=''] The string to escape.
 * @returns {string} Returns the escaped string.
 */
const escapeRegExp = string => {
  const reRegExpChar = /[\\^$.*+?()[\]{}|]/g;
  const reHasRegExpChar = RegExp(reRegExpChar.source);
  return string && reHasRegExpChar.test(string)
    ? string.replace(reRegExpChar, "\\$&")
    : string;
};

const isTokenFoundInRoId = (roId, regexObj) => {
  return regexObj.test(roId.toString().toLowerCase());
};

const isTokenFoundInServiceName = (serviceName, regexObj) => {
  // If there is no serviceName, skip matching
  if (!serviceName) return false;
  return serviceName
    .toString()
    .replace(/[()]/g, "")
    .toLowerCase()
    .split(" ")
    .some(n => regexObj.test(n));
};
const isTokenFoundInOpcode = (opCode, regexObj) => {
  // If there is no opCode, skip matching
  if (!opCode) return false;
  return regexObj.test(opCode.toString().toLowerCase());
};

const isTokenFoundInServices = (services, regexObj) => {
  return services.some(
    service =>
      isTokenFoundInServiceName(service?.opDesc, regexObj) ||
      isTokenFoundInOpcode(service?.opCode, regexObj)
  );
};
/**
 * Filters repairOrder list based on a filter string.
 * @param {string} filterString
 * @param {repairOrder[]} repairOrders
 * @return {repairOrder[]}
 */
export const searchByRoIdServiceNameOrOpcode = (filterString, repairOrders) => {
  const searchTokens = createSearchTokens(filterString);

  return searchTokens.reduce((previousValue, currentValue) => {
    return previousValue.filter(({ roId, services }) => {
      const keyword = currentValue.toLowerCase();
      const regexObj = new RegExp(`^${escapeRegExp(keyword)}`, "i");

      const roIdMatches = isTokenFoundInRoId(roId, regexObj);
      const servicesMatches = isTokenFoundInServices(services, regexObj);

      return roIdMatches || servicesMatches;
    });
  }, repairOrders);
};
/**
 *
 * @param {Object} dealer
 * @param {string} vin
 * @return {Promise<object|null> }
 */
export const getVehicleInfo$ = async (dealer, vin) => {
  try {
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop)
    });
    const vehicleVin = params.vin || vin;
    // To test this scenario, add this to the URL query: &TEST_HOOK_NO_DATA_FOUND=1
    // Or alternatively, you can just delete the VIN query parameter, or change it to be invalid.
    if (params.TEST_HOOK_NO_DATA_FOUND === "1") {
      throw new Error(
        `Failed to retrieve data for ${vehicleVin ?? "this vehicle"}`
      );
    }
    const response = await repairOrdersService.getServiceHistory(
      dealer,
      vehicleVin
    );
    if (response.vehicles?.length) {
      // To test this scenario, add this to the URL query: &TEST_HOOK_NO_SERVICE_RECORDS_FOUND=1
      if (params.TEST_HOOK_NO_SERVICE_RECORDS_FOUND === "1") {
        response.vehicles[0].repairOrders = [];
      }
    } else {
      return null;
    }
    return response.vehicles[0];
  } catch (err) {
    console.error(err);
    logError(err);
    return null;
  }
};
