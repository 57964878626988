const getLaborPriceService = async (params, axiosInstance) => {
  const {
    dealerCode,
    laborHours,
    vehicle,
    payTypeCode,
    serviceTypeCode,
    skillLevel,
    isMenuPackage
  } = params;
  const { make } = vehicle;
  const restUrl = `quote/services/labor/${dealerCode}/price`;
  // @note: Usage of spread operator below.
  // If params.dealerLaborRateId doesn't arrive, it won't be included in the request params; an empty object gets spread.
  // If it arrives, it does get sent; { dealerLaborRateId: params.dealerLaborRateId } gets spread into the request params object.
  const reqParams = {
    make,
    laborHours,
    payTypeCode,
    serviceTypeCode,
    skillLevel,
    isMenuPackage,
    ...(params.dealerLaborRateId
      ? { dealerLaborRateId: params.dealerLaborRateId }
      : {})
  };
  try {
    const response = await axiosInstance.get(restUrl, {
      params: reqParams
    });

    return response.data;
  } catch (error) {
    console.error(error);
    return {};
  }
};

/**
 * Fetches labor pricing information for a specific vehicle make from a dealer's service quote API.
 *
 * @param {Object} params - The parameters for the API call.
 * @param {string} params.dealerCode - The code of the dealer to query.
 * @param {Object} params.vehicle - The vehicle information.
 * @param {string} params.vehicle.make - The make of the vehicle.
 * @param {Object} axiosInstance - The Axios instance for making HTTP requests.
 *
 * @returns {Promise<Object>} The labor pricing information for the specified vehicle make.
 */
const getLaborPriceByMake = async (params, axiosInstance) => {
  const { dealerCode, vehicle } = params;
  const { make } = vehicle;
  const restUrl = `quote/services/${dealerCode}/laborPricing`;
  const reqParams = { make };

  try {
    const response = await axiosInstance.get(restUrl, {
      params: reqParams
    });

    return response.data;
  } catch (error) {
    console.error(error);
    return {};
  }
};

/**
 * Fetches the special labor price for a given dealer, vehicle make, and pay type.
 *
 * @param {Object} params - The parameters for the request.
 * @param {string} params.dealerCode - The unique code identifying the dealer.
 * @param {Object} params.vehicle - The vehicle details.
 * @param {string} params.vehicle.make - The make of the vehicle (e.g., Toyota, Ford).
 * @param {string} params.payTypeCode - The payment type code (e.g., cash, credit).
 * @param {Object} axiosInstance - An instance of Axios for making HTTP requests.
 *
 * @returns {Promise<Object>} The data from the response, or an empty object if an error occurs.
 *
 * @throws Will log an error to the console if the request fails.
 */
const getSpecialLaborPrice = async (params, axiosInstance) => {
  const { dealerCode, vehicle, payTypeCode } = params;
  const { make } = vehicle;
  const restUrl = `quote/services/${dealerCode}/specialLaborPrice/${payTypeCode}`;
  const reqParams = { make };

  try {
    const response = await axiosInstance.get(restUrl, {
      params: reqParams
    });

    return response.data;
  } catch (error) {
    console.error(error);
    return {};
  }
};

/**
 * Builds the label for a warranty plan option.
 * @param {object} warrantyPlan The plan option to build the label for.
 * @param {Array<object>} availableWarrantyPlans All available warranty plans.
 * @returns {string} The label to display for the specified warranty plan.
 */
const buildWarrantyPlanOptionLabel = (warrantyPlan, availableWarrantyPlans) => {
  if (!warrantyPlan) {
    return "";
  }

  const { warrantyType, planCode } = warrantyPlan;
  const isUniqueWarrantyType =
    availableWarrantyPlans?.filter(p => p.warrantyType === warrantyType)
      .length === 1;

  return isUniqueWarrantyType ? warrantyType : `${warrantyType} (${planCode})`;
};

/**
 * Retrieves available warranty plans.
 * @param {Object} params - The parameters for the API call.
 * @param {string} params.dealerCode - The code of the dealer to query.
 * @param {string} params.make - The make of the vehicle.
 * @param {Object} axiosInstance - The Axios instance for making HTTP requests.
 * @returns {Promise<Array>} Array of warranty plans available for the specified dealer & make.
 */
const getWarrantyPlans = async (params, axiosInstance) => {
  const { dealerCode, make } = params;
  const restUrl = `/quote/services/${dealerCode}/specialLaborPrice/W`;
  const reqParams = { make: make?.toUpperCase() };

  try {
    const response = await axiosInstance.get(restUrl, {
      params: reqParams
    });

    const warrantyPlans = response.data?.length
      ? response.data.map(plan => {
          return {
            ...plan,
            uniqueLabel: buildWarrantyPlanOptionLabel(plan, response.data)
          };
        })
      : [];

    return warrantyPlans;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export {
  getLaborPriceService,
  getLaborPriceByMake,
  getSpecialLaborPrice,
  getWarrantyPlans,
  buildWarrantyPlanOptionLabel
};
