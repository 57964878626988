// @ts-check
import React, { useEffect } from "react";
import Dashboard from "./dashboard.component";
import { useDashboardContext, Actions } from "./state/dashboard.context";
// eslint-disable-next-line unused-imports/no-unused-imports
import * as CommonTypes from "../../../@types/common.types";
// eslint-disable-next-line unused-imports/no-unused-imports
import * as ModelsTypes from "../../../@types/models.types";
// eslint-disable-next-line unused-imports/no-unused-imports
import * as ContextTypes from "../../../@types/context.types";

/**
 * @description Container component for Dashboard. Use it to set localeStrings and quotes in context
 * and to configure the arrangement of the layout and sections sub-components as children of Dashboard.
 * @param {{children: any, quotes: ModelsTypes.QuoteDto, localeStrings: Object, dateRange: string, serviceTypes:ModelsTypes.ServiceTypes}} props
 * @returns {CommonTypes.Component}
 */
const DashboardContainer = props => {
  // @tbd: config prop will be added and destructured here
  const { quotes, localeStrings, dateRange, serviceTypes } = props;
  /** @type {ContextTypes.ContextHook<ContextTypes.DashboardContext>} */
  const { dispatch } = useDashboardContext();

  useEffect(() => {
    dispatch({
      type: Actions.SET_LOCALE_STRINGS,
      payload: localeStrings
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch({
      type: Actions.SET_QUOTES,
      payload: { quotes, dateRange }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quotes, dateRange]);

  useEffect(() => {
    dispatch({
      type: Actions.SET_SERVICE_TYPES,
      payload: serviceTypes
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceTypes]);

  return (
    <Dashboard>
      <Dashboard.Wrapper>
        <Dashboard.Column>
          <Dashboard.QuotesSection />
        </Dashboard.Column>
        <Dashboard.Column>
          <Dashboard.PotentialValue />
        </Dashboard.Column>
        <Dashboard.Column>
          <Dashboard.ServiceTypes />
        </Dashboard.Column>
      </Dashboard.Wrapper>
    </Dashboard>
  );
};

export default DashboardContainer;
