import React, { useContext } from "react";
import isEmpty from "lodash/isEmpty";
import { useNewQuoteContext } from "../../../state/NewQuoteContext";
import { AppContext } from "../../../state/app-context";
import StatusBadge from "../../ui/reusable/status-badge/status-badge.component";
import QuoteStatusConstant from "../../../constants/quote-status.constants";
import { extractAndFormatAttentionTags } from "../../utils/data-transformer";
import { formatTwelveHourDate } from "../../page-wrapper/utils/quote-util";
import { getNodeJsPrintUrl } from "../../../api/xmmAxios";
import * as gtmEvent from "../../utils/gtag/gtag-event.util";
import { updateOpenerWindow } from "../../../utils/window.util";

const QuoteStatusHeader = props => {
  const { localeStrings } = props;
  const { state } = useNewQuoteContext();
  const { quoteSummary } = state;
  const confirmationId = state?.quoteSummary?.confirmationId;
  const quoteStatus = quoteSummary?.quoteStatus;
  const attentionTags = extractAndFormatAttentionTags(
    state?.quoteSummary?.workflowAttentionTag
  );
  const serviceTag = attentionTags.filter(tag => tag === "Service");
  const partsTag = attentionTags.filter(tag => tag === "Parts");
  const appContext = useContext(AppContext);
  const nodeJsPrintUrl = getNodeJsPrintUrl();
  const scheduledDate = formatTwelveHourDate(
    state?.quoteSummary?.convertedToApptDate
  );
  const sentDate = formatTwelveHourDate(quoteSummary?.lastModDateTime || "");
  const viewQuotePdf = () => {
    const { dealer } = appContext;
    gtmEvent.trackGAEventWithParam("ga.newquote.view_pdf_click", {
      result: dealer.dealerCode
    });
    const pdfUrl = new URL("/svcQuote/pdf", nodeJsPrintUrl);
    pdfUrl.searchParams.append("quoteConfirmationId", confirmationId);
    pdfUrl.searchParams.append("dealerCode", dealer.dealerCode);
    window.open(pdfUrl.toString(), "_PreviewQuote");
  };
  const { dealer, loginURL } = appContext;
  const { webKey } = dealer;
  const { appointmentCode } = quoteSummary;
  const urlToOpenExistingAppt = `${loginURL}/auth/businesslogin?w=${webKey}&act=ea&ck=${appointmentCode}`;
  const viewExistingAppointment = () => {
    updateOpenerWindow(urlToOpenExistingAppt, "_Scheduling");
  };

  const isRequestAssistance =
    quoteStatus === QuoteStatusConstant.REQUEST_ASSISTANCE;
  const isScheduled =
    quoteStatus === QuoteStatusConstant.CONVERTED_TO_APPOINTMENT;
  const isSent = quoteStatus === QuoteStatusConstant.SENT;
  return (
    <div className="quote-status-container">
      <div className="quote-status-container__confirmation-id">
        <h3>
          {localeStrings["sq.pastquotes.quote_id_lbl"]}: {confirmationId}
        </h3>
      </div>
      <span className="quote-status-container__status-label">Status:</span>
      <StatusBadge
        status={
          !isEmpty(quoteStatus) ? quoteStatus : QuoteStatusConstant.PROGRESS
        }
      />
      {isRequestAssistance && (!isEmpty(serviceTag) || !isEmpty(partsTag)) ? (
        <div className="quote-status-container__attention-wrapper">
          <span className="quote-status-container__attention-label">
            Attention:
          </span>
          {!isEmpty(serviceTag) ? (
            <StatusBadge
              className="quote-status-container__attention-badge"
              status={serviceTag}
              color="red"
            />
          ) : null}
          {!isEmpty(partsTag) ? (
            <StatusBadge
              className="quote-status-container__attention-badge"
              status={partsTag}
              color="red"
            />
          ) : null}
        </div>
      ) : null}
      {isScheduled && !isEmpty(scheduledDate) ? (
        <span className="quote-status-container__date">{scheduledDate}</span>
      ) : null}
      {isSent && !isEmpty(sentDate) ? (
        <span className="quote-status-container__date">{sentDate}</span>
      ) : null}
      {isScheduled || isSent ? (
        <a
          onClick={() => viewQuotePdf()}
          className="quote-status-container__pdf-btn"
        >
          {localeStrings["sq.newquote.summary.see-quote-pdf_button"]}
        </a>
      ) : null}
      {isScheduled ? (
        <>
          <span>|</span>
          <a
            onClick={() => viewExistingAppointment()}
            className="quote-status-container__appt-btn"
          >
            {localeStrings["sq.newquote.summary.see-appointment_button"]}
          </a>
        </>
      ) : null}
    </div>
  );
};

export default QuoteStatusHeader;
