/* eslint-disable react/no-array-index-key */
import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import SelectInput from "@cx/ui/SelectInput";
import PhoneInput from "@cx/ui/PhoneInput";
import EmailInput from "@cx/ui/EmailInput";
import TextArea from "@cx/ui/TextArea";
import Button from "@cx/ui/Button";
import IconAdd from "@cx/ui/Icons/IconAdd";
import SimpleTable from "@cx/ui/SimpleTable";
import IconArrowUpward from "@cx/ui/Icons/IconArrowUpward";
import sendEmailTemplate from "./sendEmailBody.tpl";
import { getNodeJsPrintUrl } from "../../../api/xmmAxios";

import "./send-email-text-style.scss";
import { AppContext } from "../../../state/app-context";
import {
  toEmptyStringIfUndefined,
  extractMobileNumber
} from "../../../utils/string.util";
import useComponentDidMount from "../../../hooks/useComponentDidMount";
import emailService from "./services/email.service";
import { toast } from "@cx/ui/Toast";
import { emailValidate } from "../../../utils/field.validator";
import { hideBodyMask, showBodyMask } from "../../../utils/page-mask.util";
import LoadingIndicator from "@cx/ui/LoadingIndicator";

const SendEmailTextToCustomer = props => {
  const { appSource, appEnv, localeStrings, dealer, text2way } =
    useContext(AppContext);
  const { navigationControls, onCancel, onSend, data } = props;
  const [sendOption, setSendOption] = useState("EMAILTEXT");
  const [dataProperties, setDataProperties] = useState([]);
  const [propEnable, setPropEnable] = useState({ email: true, phone: true });
  const [emailState, setEmailState] = useState(0);
  const [phoneState, setPhoneState] = useState(0);
  const [emailBody, setEmailBody] = useState("");
  const [loadmask, setLoadmask] = useState(false);
  const nodeJsPrintUrl = getNodeJsPrintUrl();

  useComponentDidMount(() => {
    const initialData = {
      email: toEmptyStringIfUndefined(data?.email),
      phone: toEmptyStringIfUndefined(extractMobileNumber(data?.mobile))
    };
    setDataProperties(oldArray => [...oldArray, initialData]);
    const newEmailBody = sendEmailTemplate
      .replace("{{Quote_ID}}", data?.confirmationId)
      .replace("{{Dealership_Name}}", dealer?.dealerName)
      .replace("{{Quote_PDF_Url}}", getQuotePdfUrl());
    setEmailBody(newEmailBody);
  });

  const onHandleOptionChange = e => {
    const value = e.target.value;

    if (value === "EMAIL") {
      setPropEnable(prevState => ({
        ...prevState,
        email: true,
        phone: false
      }));
    }
    if (value === "TEXT") {
      setPropEnable(prevState => ({
        ...prevState,
        email: false,
        phone: true
      }));
    }
    if (value === "EMAILTEXT") {
      setPropEnable(prevState => ({
        ...prevState,
        email: true,
        phone: true
      }));
    }

    setSendOption(value);
  };

  const addEmail = () => {
    const nextEmail = emailState + 1;
    const data = [...dataProperties];
    if (nextEmail <= 2) {
      if (data[nextEmail]) {
        data[nextEmail].email = "";
      } else {
        data.push({ email: "" });
      }
      setDataProperties(data);
      setEmailState(nextEmail);
    }
  };
  const addPhone = () => {
    const nextPhone = phoneState + 1;
    const data = [...dataProperties];
    if (nextPhone <= 2) {
      if (data[nextPhone]) {
        data[nextPhone].phone = "";
      } else {
        data.push({ phone: "" });
      }
      setDataProperties(data);
      setPhoneState(nextPhone);
    }
  };

  const handleBackToConfirmation = () => {
    setLoadmask(false);
    onCancel();
  };

  const onEmailBlur = (index, updateEmailValue) => (cxEvent, isValid) => {
    if (isValid) {
      updateEmailValue(index, cxEvent.target.value);
    }
  };

  const updateEmailValue = (index, value) => {
    const data = [...dataProperties];
    data[index].email = value;
    setDataProperties(data);
  };

  const onPhoneBlur = (index, updatePhoneValue) => (cxEvent, isValid) => {
    if (isValid) {
      updatePhoneValue(index, cxEvent.target.value);
    }
  };

  const updatePhoneValue = (index, value) => {
    const data = [...dataProperties];
    data[index].phone = value;
    setDataProperties(data);
  };

  const onEmailChange = (index, updateEmailValue) => cxEvent => {
    updateEmailValue(index, cxEvent.target.value);
  };

  const onPhoneChange = (index, updatePhoneValue) => cxEvent => {
    updatePhoneValue(index, cxEvent.target.value);
  };

  const onMessageChange = event => {
    const value = event.target.value;
    setEmailBody(value);
  };

  const sendSMSText = async () => {
    updateLoadMask(true);
    const smsConfig =
      appEnv === "prod" || appEnv === "ua9" || text2way
        ? await getSMSConfig()
        : null;

    const phoneNumbers = dataProperties
      .filter(d => {
        if (d.phone) {
          return d.phone;
        }
      })
      .map(item => {
        return { phoneNumber: item.phone, countryCode: "US" };
      });

    let count = phoneNumbers.length;
    for (const phone of phoneNumbers) {
      const quoteSMS = {
        to: phone,
        content: emailBody
      };
      if (smsConfig) {
        const { phoneNumber, countryCode } = smsConfig;
        if (phoneNumber && countryCode) {
          quoteSMS.from = { phoneNumber, countryCode };
        }
      }
      const { dealerCode, schemaName } = dealer;
      await emailService
        .sendSMS(
          appEnv,
          appSource,
          schemaName,
          dealerCode,
          quoteSMS,
          data?.confirmationId
        )
        .then(response => {
          updateLoadMask(false);
          if (response) {
            const { statusCode } = response;
            switch (statusCode) {
              case "DELIVERED_TO_PROVIDER":
                count--;
                break;
              case "REJECTED_DUPLICATE_MESSAGE_NO_ERROR":
                toast.warning(
                  `This exact message has already been sent to ${phone.phoneNumber}. To re-send the quote, please update the message.`,
                  {
                    closeOnClick: true
                  }
                );
                break;
              default:
                toast.error("A server error occurred.  Please try again.", {
                  closeOnClick: true
                });
                break;
            }
          }
        })
        .catch(error => {
          if (
            error &&
            error.response &&
            error.response.data &&
            error.response.data.message &&
            error.response.data.message.indexOf("WHITELIST_EXCLUDED") !== -1
          ) {
            toast.warning(
              "Cannot send quote to " +
                phone.phoneNumber +
                " since it is not Whitelisted",
              {
                closeOnClick: true
              }
            );
          } else {
            toast.error("A server error occurred. Please try again.", {
              closeOnClick: true
            });
          }
          updateLoadMask(false);
        });
    }

    return count === 0;
  };

  const sendEmail = async () => {
    updateLoadMask(true);
    const emailConfig = getEmailConfig();
    const { from, replyTo } = emailConfig;
    const subject = `Personalized Service Quote ${data?.confirmationId} from ${dealer?.dealerName}`;
    const content = emailBody;
    const address = dataProperties
      .filter(d => {
        if (d.email) {
          return d.email;
        }
      })
      .map(item => item.email);

    const quoteEmail = {
      address,
      from,
      replyTo,
      subject,
      content
    };
    const { dealerCode } = dealer;
    let rc = false;

    await emailService
      .sendEmail(
        appEnv,
        appSource,
        quoteEmail,
        dealerCode,
        data?.confirmationId
      )
      .then(response => {
        updateLoadMask(false);
        if (response) {
          const { statusCode } = response;
          switch (statusCode) {
            case "DELIVERED_TO_PROVIDER":
              rc = true;
              break;
            case "REJECTED_DUPLICATE_MESSAGE_NO_ERROR":
              toast.warning(
                "This exact message has already been sent to this Email Address(es). To re-send the quote, please update the message..",
                {
                  closeOnClick: true
                }
              );
              break;
            default:
              toast.error("A server error occurred.  Please try again.", {
                closeOnClick: true
              });
              break;
          }
        }
      })
      .catch(error => {
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message &&
          error.response.data.message.indexOf("WHITELIST_EXCLUDED") !== -1
        ) {
          toast.warning(
            "Cannot send quote to this Email Address since it is not Whitelisted",
            {
              closeOnClick: true
            }
          );
        } else {
          toast.error("A server error occurred.  Please try again.", {
            closeOnClick: true
          });
        }
        updateLoadMask(false);
      });
    return rc;
  };

  const getEmailConfig = async () => {
    const { dealerCode, schemaName, emailConfig } = dealer;
    if (!emailConfig) {
      const emailConfigResponse = await emailService.getEmailConfig(
        appEnv,
        appSource,
        schemaName,
        dealerCode,
        data?.confirmationId
      );
      if (
        !emailConfigResponse ||
        !emailConfigResponse.success ||
        !emailConfigResponse.data
      ) {
        toast.error(
          "There is an error in getting dealer configured email addresses.",
          {
            closeOnClick: true
          }
        );
      }
      dealer.emailConfig = {};
      dealer.emailConfig.from = emailConfigResponse.data.fromAddress;
      dealer.emailConfig.replyTo = emailConfigResponse.data.replyToAddress;
    }
    return emailConfig;
  };

  const getSMSConfig = async () => {
    const { dealerCode, schemaName } = dealer;
    if (!dealer.smsConfig) {
      await emailService
        .getSMSConfig(
          appEnv,
          appSource,
          schemaName,
          dealerCode,
          data?.confirmationId
        )
        .then(smsConfigResponse => {
          if (
            smsConfigResponse &&
            smsConfigResponse.success &&
            smsConfigResponse.data
          ) {
            const { address, countryCode } = smsConfigResponse.data.address;
            if (address && countryCode) {
              dealer.smsConfig = {
                phoneNumber: address,
                countryCode
              };
            }
          }
        })
        .catch(e => {
          console.error(e);
        });
    }
    return dealer.smsConfig;
  };
  const updateLoadMask = showmask => {
    setLoadmask(showmask);
    if (showmask) {
      showBodyMask();
    } else {
      hideBodyMask();
    }
  };
  const onHandleFormSubmit = async event => {
    event.preventDefault();
    updateLoadMask(true);
    let emailOk = true;
    let smsOk = true;
    if (sendOption === "EMAILTEXT" || sendOption === "EMAIL") {
      emailOk = await sendEmail();
    }

    if (sendOption === "EMAILTEXT" || sendOption === "TEXT") {
      smsOk = await sendSMSText();
    }

    if (emailOk && smsOk) {
      updateLoadMask(false);
      onSend(); // callback parent handler
      handleBackToConfirmation();
    }
  };

  const getQuotePdfUrl = () => {
    const { dealerCode } = dealer;
    const pdfUrl = new URL("/svcQuote/pdf", nodeJsPrintUrl);
    pdfUrl.searchParams.append("quoteConfirmationId", data?.confirmationId);
    pdfUrl.searchParams.append("dealerCode", dealerCode);
    return pdfUrl.toString();
  };

  const validEmailList = () => {
    const emails = dataProperties
      .filter(d => {
        if (d.email) {
          return d.email;
        }
      })
      .map(e => {
        const isValidEmail = emailValidate(e.email);
        if (isValidEmail) {
          return true;
        }
        return false;
      });
    const checkEmail = emails.find(item => item === true);
    if (checkEmail) {
      return true;
    }
    return false;
  };
  const sendBtnDisabed = () => {
    const emails = dataProperties.filter(d => {
      if (d.email) {
        return d.email;
      }
    });
    if (sendOption === "EMAILTEXT") {
      if (!emails.length || !emailBody) {
        return true;
      }
      return validEmailList();
    } else if (sendOption === "EMAIL") {
      if (!emails.length) {
        return true;
      }
      return validEmailList();
    }
    return false;
  };
  const maskContent = loadmask ? (
    <LoadingIndicator htmlId="loadingSendQuote" />
  ) : null;
  const clsMaskBody = loadmask ? "send-quote-mask-container" : "empty-cls";
  return (
    <>
      {navigationControls ? (
        <div>
          <span
            className="back-button-nq back-link-label"
            onClick={handleBackToConfirmation}
          >
            <IconArrowUpward
              htmlId="backButtonQuote"
              isActive={false}
              className="back-arrow"
            />
            {localeStrings["sq.newquote.send.email.text.back_to_confirmation"]}
          </span>
          <div className="quote-page-header">
            <div className="left-block">
              <h3 className="sq-h3">
                {localeStrings["sq.newquote.send.email.text.title.lbl"]}
              </h3>
              <span className="quote-label" />
            </div>
          </div>
        </div>
      ) : null}
      {maskContent}
      <div className={clsMaskBody}>
        <div className="send-quote-properties-container">
          <form autoComplete="off" onSubmit={onHandleFormSubmit}>
            <div className="send-quote-options email">
              <SelectInput
                htmlId="sendQuoteOption"
                name="sendQuoteOption"
                onChange={onHandleOptionChange}
                displayDeselectOption={false}
                value={sendOption}
                label="Send to"
                required
                className="send-quote-option-email-text"
                options={[
                  {
                    value: "EMAILTEXT",
                    label: "Email And Text"
                  },
                  {
                    value: "EMAIL",
                    label: "Email"
                  },
                  {
                    value: "TEXT",
                    label: "Text"
                  }
                ]}
              />
            </div>
            <div className="send-quote-properties">
              <SimpleTable
                htmlId="SimpleTable"
                hover={false}
                className="send-quote-table"
              >
                <tbody>
                  {dataProperties.map((item, index) => {
                    const hasEmail =
                      item.email !== undefined && item.email !== null;
                    const hasPhone =
                      item.phone !== undefined && item.phone !== null;
                    return (
                      <tr
                        id={"send-quote-property-" + index}
                        key={"key-" + index}
                      >
                        {hasEmail ? (
                          <th>
                            <div
                              className="send-quote-properties-item"
                              id={"send-quote-properties-item-email-" + index}
                            >
                              <EmailInput
                                appendChild={
                                  index === 0 ? (
                                    <Button
                                      aria-label="Add"
                                      htmlId="emailAdd"
                                      onClick={addEmail}
                                      buttonStyle="secondary"
                                      disabled={!propEnable.email}
                                      icon={
                                        <IconAdd htmlId="emailAddButon-iconAdd" />
                                      }
                                    />
                                  ) : null
                                }
                                htmlId="sendEmailOption"
                                name="quoteEmailOption"
                                label="Email Address"
                                value={item.email}
                                required={index === 0}
                                disabled={!propEnable.email}
                                onBlur={onEmailBlur(index, updateEmailValue)}
                                onChange={onEmailChange(
                                  index,
                                  updateEmailValue
                                )}
                                autoComplete="none"
                              />
                            </div>
                          </th>
                        ) : (
                          <th />
                        )}
                        {hasPhone ? (
                          <th>
                            <div
                              className="send-quote-properties-item"
                              id={"send-quote-properties-item-phone-" + index}
                            >
                              <PhoneInput
                                appendChild={
                                  index === 0 ? (
                                    <Button
                                      aria-label="Add"
                                      htmlId="phoneAdd"
                                      onClick={addPhone}
                                      buttonStyle="secondary"
                                      disabled={!propEnable.phone}
                                      icon={
                                        <IconAdd htmlId="phoneAddButton-iconAdd" />
                                      }
                                    />
                                  ) : null
                                }
                                htmlId="phoneOption"
                                name="quotePhoneOption"
                                autoComplete="none"
                                label="Mobile Number"
                                value={propEnable.phone ? item.phone : ""}
                                required={
                                  // eslint-disable-next-line  react/jsx-no-leaked-render
                                  propEnable.phone && index === 0
                                }
                                disabled={!propEnable.phone}
                                onBlur={onPhoneBlur(index, updatePhoneValue)}
                                onChange={onPhoneChange(
                                  index,
                                  updatePhoneValue
                                )}
                              />
                            </div>
                          </th>
                        ) : null}
                      </tr>
                    );
                  })}
                </tbody>
              </SimpleTable>
            </div>
            <div className="send-quote-options">
              <TextArea
                htmlId="textAreaDefault"
                className="email-textarea"
                label="Message"
                name="message"
                value={emailBody}
                onChange={onMessageChange}
              />
            </div>
            <div className="send-quote-flex-bgroup">
              <span
                id="cancelButton"
                className="hand-cursor"
                onClick={handleBackToConfirmation}
              >
                {localeStrings["sq.common.cancel_button"]}
              </span>

              <Button
                htmlId="sendButton"
                buttonStyle="primary"
                type="submit"
                disabled={sendBtnDisabed()}
              >
                Send
              </Button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

SendEmailTextToCustomer.propTypes = {
  navigationControls: PropTypes.bool,
  data: PropTypes.shape({
    email: PropTypes.string,
    confirmationId: PropTypes.string,
    mobile: PropTypes.string
  }),
  onCancel: PropTypes.func,
  onSend: PropTypes.func
};

SendEmailTextToCustomer.defaultProps = {
  navigationControls: false,
  onCancel: () => {},
  onSend: () => {},
  data: {
    email: "",
    confirmationId: "",
    mobile: ""
  }
};

export default SendEmailTextToCustomer;
