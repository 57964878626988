import React, { useEffect, useMemo, useState } from "react";

import RadioButtonList from "@cx/ui/RadioButtonList";
import cloneDeep from "lodash/cloneDeep";
import isArray from "lodash/isArray";

import { PAYER_TYPES } from "../../../constants/csr.constants";

const RODiscountPayerSelectComponent = ({
  selectedDiscount,
  quoteSummary,
  setSelectedDiscount,
  calculate
}) => {
  const [selectedPayer, setSelectedPayer] = useState("");

  useEffect(() => {
    setSelectedPayer(selectedDiscount?.payerObject);
  }, [selectedDiscount]);

  const onPayerChange = cxEvent => {
    const payerObject = quoteSummary?.payers?.find(
      payer => payer.payType === cxEvent.target.value
    );
    const clonedSelectedDiscount = cloneDeep(selectedDiscount);
    clonedSelectedDiscount.payerObject = payerObject;
    clonedSelectedDiscount.feeDiscountPayerPayCode = payerObject?.payType;

    setSelectedDiscount(clonedSelectedDiscount);
    setSelectedPayer(payerObject);
    calculate(clonedSelectedDiscount);
  };

  const enabledPayers = useMemo(() => {
    const selectedDiscountPayTypes = isArray(selectedDiscount?.payTypes)
      ? selectedDiscount?.payTypes
      : selectedDiscount?.payTypes?.split(",");
    const propertyValues = quoteSummary?.payers?.map(item => item["payType"]);
    const commonPayers = selectedDiscountPayTypes?.filter(item =>
      propertyValues?.includes(item)
    );
    return commonPayers;
  }, [selectedDiscount, quoteSummary]);

  return (
    <RadioButtonList
      htmlId="radioButtonListDefault"
      name="radioButtonListDefault"
      className="payer-selection-radio-group"
      displayLabel={false}
      label="Payer"
      onChange={onPayerChange}
      layout="horizontal"
      value={selectedPayer?.payType}
      options={[
        {
          value: PAYER_TYPES.C,
          label: "Customer pay",
          disabled: !enabledPayers?.includes(PAYER_TYPES.C)
        },
        {
          value: PAYER_TYPES.W,
          label: "Warranty",
          disabled: !enabledPayers?.includes(PAYER_TYPES.W)
        },
        {
          value: PAYER_TYPES.I,
          label: "Internal",
          disabled: !enabledPayers?.includes(PAYER_TYPES.I)
        },
        {
          value: PAYER_TYPES.S,
          label: "Service contract",
          disabled: !enabledPayers?.includes(PAYER_TYPES.S)
        }
      ]}
    />
  );
};

export default RODiscountPayerSelectComponent;
