/**
 * Custom Hook for Labor fields
 */
import { useState, useEffect, useContext } from "react";
import cloneDeep from "lodash/cloneDeep";
import isEmpty from "lodash/isEmpty";
import isNull from "lodash/isNull";
import isNil from "lodash/isNil";
import isNumber from "lodash/isNumber";
import priceCalculationUtil from "../utils/service-calculation.util";
import * as gtmEvent from "../../features/utils/gtag/gtag-event.util";
import { formatLaborTime } from "../utils/time";
import { Actions, useEditServiceContext } from "../state/edit-service.context";
import { AppContext } from "../../state/app-context";
import { isDMSPlus, isServiceContract } from "../utils/service.util";
import {
  getWarrantyPlans,
  buildWarrantyPlanOptionLabel,
  getLaborPriceService,
  getSpecialLaborPrice
} from "../service/labor-calculation.service";
import { getOperationDetailsParam } from "../utils/labor-calculation.util";
import {
  actionTypes,
  catalogSources,
  operationSources,
  payTypeCodes
} from "../utils/edit-service.constants";
import { toPriceString, toPriceStringNew } from "../utils/format.util";
import { FEES_TYPES } from "../../features/repair-order/constants/adjustment.constant";
import isArray from "lodash/isArray";
import {
  checkExpiredFeeOrDiscount,
  checkStartDateValidFeeOrDiscount
} from "../../features/page-wrapper/utils/quote-util";
import {
  formatVendorProduct,
  isServiceContractInsuranceSelected
} from "../../features/service-contract/utils/service-contracts.util";

export default function useLaborCalculation(props) {
  const {
    defaultPayTypeCode,
    dealerCode,
    onChangePaytype,
    axiosInstance,
    defaultServiceTypeCode
  } = props;

  const { dispatch, state } = useEditServiceContext();
  const {
    payTypes,
    serviceTypes: serviceTypesList,
    vendorList,
    serviceContracts,
    warrantyPlans,
    quoteSummary,
    currentEditingService,
    originalService,
    service: stateService,
    vehicle,
    filteredParts,
    discountsAndFees
  } = state;

  const {
    opCode: dmsOpcode,
    defaultLaborRate: laborRate,
    dealerLaborRateId
  } = stateService;

  const {
    labor,
    finalLaborPrice,
    payTypeCode,
    serviceTypeCode,
    operationSource
  } = stateService;

  const appContext = useContext(AppContext);
  const {
    dealer: { dmsType },
    appType,
    dealerProperties
  } = appContext;
  const isDMSPlusDealer = isDMSPlus(dmsType);
  const isServiceContractPayType = isServiceContract(payTypeCode);
  const [payTypeConfirmationModal, setPayTypeConfirmationModal] =
    useState(false);

  const [
    selectedPayTypeBeforeConfirmation,
    setSelectedPayTypeBeforeConfirmation
  ] = useState(null);

  const BODY_SHOP = "BS"; // to render or not laborRate input
  const isServiceRO = appType === "CSR";
  const isAlwaysShowLaborRateEnabled =
    dealerProperties?.ALWAYS_SHOW_LABOR_RATE === "Y" &&
    (isServiceRO || isDMSPlusDealer);

  const { time: laborTime } = labor;

  let laborRateState;
  if (isAlwaysShowLaborRateEnabled) {
    ({ laborRate: laborRateState } = labor);
  } else {
    ({ laborRate: laborRateState } = stateService);
  }
  // eslint-disable-next-line unused-imports/no-unused-vars
  const { labor: originalLabor } = originalService;
  const { time: originalLaborTime, price: originalLaborPrice } = originalLabor;

  let originalLaborRate;
  if (isAlwaysShowLaborRateEnabled) {
    ({ laborRate: originalLaborRate } = originalLabor);
  } else {
    ({ laborRate: originalLaborRate } = originalService);
  }

  // The utility formatLaborTime is used here to avoid issues caused by inconsistent type and format of originalLaborTime
  const [laborHours, setLaborHours] = useState(
    formatLaborTime(originalLaborTime, 0).toString()
  );
  // @note: read finalLaborPrice from state; And when user modify labor price field
  const [localLaborPrice, setLocalLaborPrice] = useState(
    toPriceString(finalLaborPrice)
  );
  // TODO: ADD: The Dropdown will always display a default service type.
  // TODO: EDIT: The Dropdown will always display the previously selected service type.
  const [serviceTypeTemp, setServiceTypeTemp] = useState(serviceTypeCode);
  const [localLaborRate, setLocalLaborRate] = useState(
    isAlwaysShowLaborRateEnabled
      ? toPriceStringNew(laborRateState ?? null)
      : toPriceString(laborRateState)
  );

  const [localSubTypeId, setLocalSubTypeId] = useState(
    currentEditingService?.subTypeId || stateService?.subTypeId
  );
  const [localCostAllocationSubTypeId, setLocalCostAllocationSubTypeId] =
    useState(
      currentEditingService?.allocationSubTypeId ||
        stateService?.allocationSubTypeId
    );
  const [newLocalCostAllocationName, setNewLocalCostAllocationName] = useState(
    currentEditingService?.internalAccount || stateService?.internalAccount
  );
  const [localOpCode, setLocalOpCode] = useState("");
  const [disablePaytype, setDisablePayType] = useState(false);
  const [defaultpayCodeID, setDefaultPayCodeID] = useState(
    defaultPayTypeCode || ""
  );
  const [defaultserviceCodeID, setDefaultServiceCodeID] = useState(
    !defaultServiceTypeCode ? "" : defaultServiceTypeCode
  );
  // @note: This field made true - when labor price touched
  const [priceModified, setPriceModified] = useState(false);
  // @note: update this state either getLaborPrice API has price or fallback calculated price
  const [backupLaborPrice, setBackupLaborPrice] = useState("");

  // The selected value for this dropdown should be in the format "vendorName - productName". Concatenation is handled in the UI.
  const currentVendorName = formatVendorProduct(
    currentEditingService?.serviceContract?.vendorName,
    currentEditingService?.serviceContract?.productName
  );

  const [localVendorName, setLocalVendorName] = useState(currentVendorName);
  const [isInsuranceSelected, setIsInsuranceSelected] = useState(
    isServiceContractInsuranceSelected(currentEditingService?.serviceContract)
  );
  const [localServiceContractSelected, setLocalServiceContractSelected] =
    useState(currentEditingService?.serviceContract);
  const [localContractNumber, setLocalContractNumber] = useState(
    currentEditingService?.serviceContract?.contractNumber
  );

  const [localWarrantyPlanLabel, setLocalWarrantyPlanLabel] = useState(
    currentEditingService?.labor?.catalogLabor?.uniqueLabel ??
      buildWarrantyPlanOptionLabel(
        currentEditingService?.labor?.catalogLabor,
        warrantyPlans
      )
  );

  const quoteService = quoteSummary?.quoteServices?.find(
    s => s.quoteServiceId === stateService?.quoteServiceId
  );
  const rawService = quoteService?.quoteRawService?.rawService
    ? JSON.parse(quoteService.quoteRawService.rawService)
    : null;

  // State for storing special labor prices locally
  const [localSpecialLaborPrice, setLocalSpecialLaborPrice] = useState([]);

  // Effect to update labor price calculation when special labor prices or selected service contract changes
  useEffect(() => {
    // Ensure both special labor prices and a selected service contract are available
    if (!localSpecialLaborPrice || !localServiceContractSelected) return;

    // Treat localSpecialLaborPrice as an empty array if it’s not an array
    const specialLaborPrices = Array.isArray(localSpecialLaborPrice)
      ? localSpecialLaborPrice
      : [];

    // Helper function to find the matching special price
    const findSpecialPrice = () => {
      const { internalProductId, vendorId } = localServiceContractSelected;

      // Check by internalProductId first, fallback to vendorId if no match found
      return internalProductId
        ? specialLaborPrices.find(
            price => price?.serviceContractPolicyId === internalProductId
          ) ||
            specialLaborPrices.find(
              price => price?.serviceContractSupplierId === vendorId
            )
        : specialLaborPrices.find(
            price => price?.serviceContractSupplierId === vendorId
          );
    };

    // Find the special price and set the labor rate, defaulting to 0 if not found
    const specialPrice = findSpecialPrice();
    const rate = specialPrice?.laborRate || 0;

    // Update labor price calculation with the found hourly rate
    updateLaborPriceCalculation({
      hourlyRate: Number(rate),
      isAPILaborPrice: true
    });
  }, [localServiceContractSelected, localSpecialLaborPrice]); // Dependencies trigger the effect when either value changes

  useEffect(() => {
    setDefaultPayCodeID(!defaultPayTypeCode ? "" : defaultPayTypeCode);
  }, [defaultPayTypeCode]);

  useEffect(() => {
    setDefaultServiceCodeID(
      !defaultServiceTypeCode ? "" : defaultServiceTypeCode
    );
  }, [defaultServiceTypeCode]);

  useEffect(() => {
    if (
      stateService.operationSource === catalogSources.MENU ||
      quoteSummary?.payers?.some(p => !!p.closedDate)
    ) {
      setDisablePayType(true);
    }
  }, [stateService.operationSource, quoteSummary]);

  const fetchWarrantyPlans = async () => {
    return payTypeCode === payTypeCodes.WARRANTY
      ? getWarrantyPlans(
          { dealerCode, make: vehicle?.make },
          axiosInstance.getInstance()
        )
      : [];
  };

  useEffect(() => {
    if (dealerProperties?.ENGG_USE_OEM_WARRANTY === "Y") {
      fetchWarrantyPlans()
        .then(result => {
          dispatch({
            type: Actions.SET_WARRANTY_PLANS,
            payload: result
          });
        })
        .catch(err => {
          console.log(err);
          dispatch({
            type: Actions.SET_WARRANTY_PLANS,
            payload: []
          });
        });
    }
  }, [payTypeCode]);

  // @note - we no longer need to restrict call to getLabor price even if the prices are comming as overriden
  useEffect(() => {
    if (payTypeCode || serviceTypeCode) {
      getLaborPrice();
    } else {
      updateLaborPriceCalculation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payTypeCode, serviceTypeCode, laborTime]);

  // @csr-logic

  // @note: updating laborRate by dividing laborPrice and laborHours only if laborRateState not available
  useEffect(() => {
    if (isAlwaysShowLaborRateEnabled) {
      if (laborRateState == null && finalLaborPrice) {
        const laborRateCalculated =
          finalLaborPrice != 0 && laborHours != 0
            ? finalLaborPrice / laborHours
            : 0;
        setLocalLaborRate(toPriceString(laborRateCalculated));

        const changed =
          parseFloat(laborRateCalculated.toFixed(2)) !== originalLaborRate;
        dispatch({
          type: Actions.SET_CHANGED,
          payload: {
            field: "laborRate",
            value: changed
          }
        });
        dispatch({
          type: Actions.SET_LABOR_RATE,
          payload: {
            laborRate: parseFloat(laborRateCalculated.toFixed(2)),
            flag: isAlwaysShowLaborRateEnabled
          }
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [finalLaborPrice]);

  useEffect(() => {
    if (!isAlwaysShowLaborRateEnabled) {
      if (!priceModified) {
        let laborRateCalculated =
          localLaborPrice != 0 ? localLaborPrice / laborHours : 0;
        if (laborTime == 0) {
          laborRateCalculated = 0;
        }
        setLocalLaborRate(toPriceString(laborRateCalculated));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localLaborPrice]);

  const getLaborPrice = async () => {
    // When the ALWAYS_SHOW_LABOR_RATE flag is on, and the labor rate has been overridden,
    // we want to keep the calculated labor price instead of retrieving it from catalog.
    if (
      isAlwaysShowLaborRateEnabled &&
      stateService?.labor?.laborRate !== rawService?.defaultLaborRate
    ) {
      return;
    }

    // fix - allow API call when global repair/non-global service has empty laborApps
    const isMenuPackage = operationSources.MENU === operationSource ? 1 : 0;
    // @note: Usage of spread operator below.
    // If dealerLaborRateId arrives as null, it won't be included in the payload; an empty object gets spread.
    // If it arrives with a value, it does get sent; { dealerLaborRateId } gets spread into the payload object.
    // TODO: WA - sending fallbackServiceTypeCode = MR for MENU services because serviceTypeCode is coming as undefined
    //  get the serviceTypeCode correctly when MENU is implemented
    // const fallbackServiceTypeCode = !!isMenuPackage ? "MR" : null;

    const isDealerPublishedOrGlobal =
      stateService.operationSource === catalogSources.DEALERCATALOG ||
      stateService.operationSource === catalogSources.GLOBALCATALOG;
    const isServiceTypeFeatureEnabled =
      isDMSPlusDealer &&
      serviceTypesList?.length > 0 &&
      isDealerPublishedOrGlobal;

    const skillLevel = getOperationDetailsParam(
      "dispatchSkillLevel",
      stateService,
      "skillLevel",
      currentEditingService
    );
    const make = isDealerPublishedOrGlobal
      ? vehicle?.make ??
        getOperationDetailsParam(
          "make",
          stateService ?? {},
          "make",
          currentEditingService ?? {}
        )
      : vehicle?.make ?? "";

    const getLaborPayload = () => {
      if (payTypeCode === payTypeCodes.SERVICE_CONTRACT) {
        return {
          dealerCode,
          vehicle: {
            ...vehicle,
            make
          },
          payTypeCode
        };
      }

      return {
        dealerCode,
        laborHours: laborTime,
        vehicle: {
          ...vehicle,
          make
        },
        payTypeCode,
        // Note: set default serviceTypeCode instead of sending null to the API
        serviceTypeCode: serviceTypeCode || "MR",
        ...(isServiceTypeFeatureEnabled && { skillLevel }),
        isMenuPackage,
        ...(!isNull(dealerLaborRateId) ? { dealerLaborRateId } : {})
      };
    };

    try {
      const responseLaborPrice = await (payTypeCode ===
      payTypeCodes.SERVICE_CONTRACT
        ? getSpecialLaborPrice(getLaborPayload(), axiosInstance.getInstance())
        : getLaborPriceService(getLaborPayload(), axiosInstance.getInstance()));

      // Check if the payment type is a service contract and update local special labor price if true
      if (payTypeCode === payTypeCodes.SERVICE_CONTRACT) {
        setLocalSpecialLaborPrice(responseLaborPrice);
        return;
      }

      const laborPrice = responseLaborPrice?.laborPrice;
      const hourlyRate = responseLaborPrice?.hourlyRate;

      if (!isNil(laborPrice) || !isNil(hourlyRate)) {
        updateLaborPriceCalculation({
          laborPrice,
          hourlyRate,
          isAPILaborPrice: true
        });
      } else {
        updateLaborPriceCalculation({ isAPILaborPrice: true });
      }
    } catch (error) {
      updateLaborPriceCalculation({ isAPILaborPrice: true });
    }
  };

  // @todo-edit: fallback logic trigger when API failed or first place when edit page rendered
  const updateLaborPriceCalculation = ({
    laborPrice = null,
    hourlyRate = null,
    laborRateNumber,
    type,
    isAPILaborPrice = false
  } = {}) => {
    const fallbackPrice = (laborRateState || laborRate) * laborTime;
    const serviceContractPrice = isNumber(hourlyRate)
      ? Number(hourlyRate) * laborTime
      : null;
    const price = isNumber(laborPrice) ? Number(laborPrice) : null;
    const calculatedPrice = isNumber(serviceContractPrice)
      ? serviceContractPrice
      : isNumber(price)
      ? price
      : fallbackPrice;
    // @todo-task: replace this dispatch by call util to re-calculate prices later
    const updatedService = priceCalculationUtil.recalculatePrices(
      stateService,
      type || actionTypes.LABOR,
      calculatedPrice
    );

    if (isNumber(hourlyRate)) {
      updatedService.laborRate = hourlyRate;
    }
    if (isAlwaysShowLaborRateEnabled) {
      if (laborRateNumber) {
        updatedService.labor = {
          ...updatedService.labor,
          laborRate: laborRateNumber
        };
      }
    } else {
      if (updatedService?.labor?.laborRate) {
        updatedService.labor = {
          ...updatedService.labor,
          laborRate: null
        };
      }
    }

    if (isAPILaborPrice) {
      setBackupLaborPrice(toPriceString(calculatedPrice));
    }
    dispatch({
      type: Actions.SET_SERVICE,
      payload: updatedService
    });
  };

  const showConfirmationModalForPayType = (cxEvent, action) => {
    setSelectedPayTypeBeforeConfirmation(cxEvent);
    setPayTypeConfirmationModal(action);
  };

  const confirmPayTypeChange = () => {
    handlePayTypeChange(selectedPayTypeBeforeConfirmation);
    showConfirmationModalForPayType(null, false);
  };

  const handlePayTypeChange = e => {
    setDefaultPayCodeID(e.target.value);
    if (e.target.value !== "I") {
      setPayTypeSubType(null);
      setPayTypeCostAllocation(null);
    }
    const editedService = cloneDeep(stateService);
    const selection = payTypes.find(
      pt => pt.dealerPayTypeId.toString() === e.target.value
    );
    const changed = selection.payCode !== defaultpayCodeID;
    gtmEvent.trackGAEventWithParam("ga.newquote.edit_service_pay_type_click", {
      result: `${stateService.operationSource} - ${selection.description}`,
      location: "edit-service"
    });
    const {
      payCode: selectedPayCode,
      description: selectedDescription,
      payTypeGroup: selectedPayTypeGroup
    } = selection;

    // @note: Update all paytype values with one action
    dispatch({
      type: Actions.SET_PAYTYPE_DETAILS,
      payload: {
        payTypeCode: selectedPayCode,
        payTypeDescription: selectedDescription,
        payTypeGroup: selectedPayTypeGroup
      }
    });

    dispatch({
      type: Actions.SET_CHANGED,
      payload: {
        field: "payType",
        value: changed
      }
    });

    if (stateService.catalogSource === "GlobalCatalog") {
      editedService.parts = filteredParts;
    }
    const updatedCatalogFees =
      getUpdatedCatalogFeesAfterPayTypeChange(selectedPayCode);
    const updatedCatalogDiscounts =
      getUpdatedDiscountAfterPayTypeChange(selectedPayCode);
    dispatch({
      type: Actions.SET_CATALOG_FEES,
      payload: updatedCatalogFees
    });
    dispatch({
      type: Actions.SET_CATALOG_DISCOUNTS,
      payload: updatedCatalogDiscounts
    });
    onChangePaytype(selection.payCode, editedService);
  };

  const getUpdatedCatalogFeesAfterPayTypeChange = selectedPayCode => {
    console.log("selectedPayCode", selectedPayCode, stateService);
    let catalogFees = cloneDeep(stateService?.catalogFees);
    //* For filtering fees that are not applicable to changed payType
    catalogFees = catalogFees?.filter(
      fee =>
        fee?.payTypes?.findIndex(f => f === selectedPayCode) !== -1 &&
        fee.applyFeeSvcLine === 1
    );
    //* for adding mandatory fee that is applicable to changed payType
    discountsAndFees?.fees?.map(fees => {
      const feesPayTypesSupported = !isArray(fees?.payTypes)
        ? fees?.payTypes?.split(",")
        : fees?.payTypes;
      const isFeeAlreadyAdded = catalogFees.findIndex(
        serviceFee => serviceFee?.dealerFeesId + "" === fees?.dealerFeesId + ""
      );
      if (
        fees?.applyFeeSvcLine === 1 &&
        feesPayTypesSupported.findIndex(f => f === selectedPayCode) !== -1 &&
        isFeeAlreadyAdded === -1 &&
        fees?.applyToOverallTotal !== 1 &&
        fees?.feesType !== "Variable" &&
        checkExpiredFeeOrDiscount(fees) &&
        checkStartDateValidFeeOrDiscount(fees)
      ) {
        fees.feeMax =
          fees?.feesType === FEES_TYPES.DOLLAR ? null : fees?.feeMax;
        fees.payTypes = !isArray(fees?.payTypes)
          ? fees?.payTypes?.split(",")
          : fees?.payTypes;
        catalogFees?.push(fees);
      }
    });
    return catalogFees;
  };

  const getUpdatedDiscountAfterPayTypeChange = selectedPayCode => {
    let catalogDiscounts = cloneDeep(stateService?.catalogDiscounts);

    //* For filtering discounts that are not applicable to changed payType
    catalogDiscounts = catalogDiscounts?.filter(
      disc => disc?.payTypes?.findIndex(f => f === selectedPayCode) !== -1
    );
    console.debug(catalogDiscounts);
    //* returning blank, as we need to drop all the disc on payType change
    return [];
  };

  const handleServiceTypeChange = e => {
    const { value } = e.target;
    setDefaultServiceCodeID(value);
    const editedService = cloneDeep(stateService);
    const selection = serviceTypesList.find(
      st => st.dealerServiceTypeId.toString() === value
    );
    setServiceTypeTemp(selection?.serviceTypeCode); // BS, MR, QL
    const changed = selection.serviceTypeCode !== defaultpayCodeID;
    // TODO: GA
    const {
      serviceTypeCode: selectedServiceTypeCode,
      description: selectedServiceTypeDescription
    } = selection;
    // update all serviceTypes values with one action
    dispatch({
      type: Actions.SET_SERVICETYPE_DETAILS,
      payload: {
        serviceTypeCode: selectedServiceTypeCode, // MR, BS, QL
        serviceTypeDescription: selectedServiceTypeDescription // "Mechanical Repair"
      }
    });
    dispatch({
      type: Actions.SET_CHANGED,
      payload: {
        field: "serviceType",
        value: changed
      }
    });
    if (stateService.catalogSource === "GlobalCatalog") {
      editedService.parts = filteredParts;
    }
    props.onChangeServiceType(selection.serviceTypeCode, editedService);
  };

  const handleServiceContractChange = e => {
    const selectedServiceContract = e.target.value?.[0];
    const editedService = cloneDeep(stateService);
    const selection = vendorList.find(
      sc => sc.vendor.toString() === selectedServiceContract?.value
    );
    const isInsurance = isServiceContractInsuranceSelected(selection);

    let serviceContractDetails = null;
    if (isInsurance) {
      serviceContractDetails = vendorList?.find(
        sc => sc.vendorId === selection?.vendorId
      );
    } else {
      serviceContractDetails = serviceContracts?.find(
        sc => sc.internalProductId === selection?.internalProductId
      );
    }

    const changed = selection !== localVendorName;
    setLocalVendorName(selection?.vendor);
    setIsInsuranceSelected(isInsurance);
    setLocalServiceContractSelected(serviceContractDetails);
    setLocalContractNumber(null);
    // TODO: GA
    dispatch({
      type: Actions.SET_SERVICE_CONTRACT_DETAILS,
      payload: serviceContractDetails
    });
    dispatch({
      type: Actions.SET_CHANGED,
      payload: {
        field: "serviceContract",
        value: changed
      }
    });

    props.onChangeServiceContract(selection, editedService);
  };

  const handleWarrantyPlanChange = event => {
    const selectedWarrantyPlan = event.target.value;
    setLocalWarrantyPlanLabel(selectedWarrantyPlan?.uniqueLabel);

    dispatch({
      type: Actions.SET_SELECTED_WARRANTY_PLAN,
      payload: selectedWarrantyPlan
    });
  };

  const handleContractNumberChange = event => {
    const { value: contractNumber } = event.target;
    if (contractNumber) {
      setLocalContractNumber(contractNumber);
      dispatch({
        type: Actions.SET_SERVICE_CONTRACT_DETAILS,
        payload: { ...localServiceContractSelected, contractNumber }
      });
    } else {
      setLocalContractNumber(null);
    }
  };

  const setPayTypeSubType = subTypeId => {
    setLocalSubTypeId(subTypeId);
    dispatch({
      type: Actions.SET_PAY_TYPE_SUB_TYPE,
      payload: subTypeId
    });
    const changed = subTypeId !== originalService.subTypeId;
    dispatch({
      type: Actions.SET_CHANGED,
      payload: {
        field: "subTypeId",
        value: changed
      }
    });
  };

  const setPayTypeCostAllocation = allocationSubTypeId => {
    console.log(allocationSubTypeId);
    setLocalCostAllocationSubTypeId(allocationSubTypeId?.value);
    dispatch({
      type: Actions.SET_PAY_TYPE_COST_ALLOCATION,
      payload: allocationSubTypeId?.value
    });
    const changed =
      allocationSubTypeId?.value !== originalService.allocationSubTypeId;
    dispatch({
      type: Actions.SET_CHANGED,
      payload: {
        field: "allocationSubTypeId",
        value: changed
      }
    });
  };

  const handlePayTypeSubTypeChange = cxEvent => {
    setPayTypeSubType(cxEvent.target.value);
  };

  const handlePayTypeCostAllocationChange = cxEvent => {
    setPayTypeCostAllocation(cxEvent.target.value[0]);
  };

  // new cost allocation
  const setNewPayTypeCostAllocation = costAllocObject => {
    console.log(costAllocObject);
    setNewLocalCostAllocationName(costAllocObject?.value);
    dispatch({
      type: Actions.SET_PAY_TYPE_NEW_COST_ALLOCATION_TYPE,
      payload: costAllocObject?.value
    });
    const changed = costAllocObject?.value !== originalService.internalAccount;
    dispatch({
      type: Actions.SET_CHANGED,
      payload: {
        field: "internalAccount",
        value: changed
      }
    });
  };

  const handleNewPayTypeCostAllocationChange = cxEvent => {
    setNewPayTypeCostAllocation(cxEvent.target.value[0]);
  };

  // @note: Global Repair - FIX side-effect where labor.time and labor.price are null and
  // And updated after clicking "DONE", and therefore change from  0 default to a value.
  // @DEBUG with GLOBAL OPS
  useEffect(() => {
    if (!isNull(labor.time) && labor.time !== originalLaborTime) {
      const time = formatLaborTime(labor.time, 0);
      setLaborHours(time.toString());
      console.log("GLOBAL LABOR TIME", time);
    }
  }, [labor, originalLaborTime, originalLaborPrice]);
  // @note: This useEffect required, to update localLaborPrice {binded to UI} whenever finalLaborPrice updated in context
  useEffect(() => {
    const strFinalLaborPrice = toPriceString(finalLaborPrice);
    setLocalLaborPrice(strFinalLaborPrice);
  }, [finalLaborPrice]);

  // monitor for opCode changes from context.service
  useEffect(() => {
    if (stateService.opCode) {
      const opCodes = !isEmpty(stateService.opCode)
        ? stateService.opCode.split(";")
        : [];
      const opCodeSelected = !isEmpty(opCodes)
        ? opCodes[0]
        : stateService.opCode;
      setLocalOpCode(opCodeSelected);
    }
  }, [stateService.opCode]);

  const handleLaborPriceChange = event => {
    const { value } = event.target;
    if (value !== localLaborPrice) {
      setPriceModified(true);
    }
    if (!isEmpty(value)) {
      const priceValue = Number(value);
      setLocalLaborPrice(toPriceString(priceValue));
      setPriceModified(true);
    } else {
      setLocalLaborPrice("");
    }
  };

  const handleLaborRateChange = event => {
    const { value } = event.target;
    setLocalLaborRate(toPriceString(value));
  };

  const onBlurLaborRate = () => {
    if (!isEmpty(localLaborRate)) {
      const laborRateNumber = parseFloat(localLaborRate);
      const error = laborRateNumber > 999.99;
      const changed = laborRateNumber != originalLaborRate;
      dispatch({
        type: Actions.SET_LABOR_RATE,
        payload: {
          laborRate: parseFloat(laborRateNumber.toFixed(2)),
          flag: isAlwaysShowLaborRateEnabled
        }
      });
      dispatch({
        type: Actions.SET_CHANGED,
        payload: {
          field: "laborRate",
          value: changed
        }
      });
      dispatch({
        type: Actions.SET_ERRORS,
        payload: {
          field: "laborRate",
          value: error
        }
      });
      if (isAlwaysShowLaborRateEnabled && stateService?.laborPriceOverridden) {
        // Per logic for US1491994, do not recalculate the total labor price if it was overridden.
      } else {
        // For ServiceTypes as BodyShop the only way to update/override total price is thru labor rate
        // cause Total is disabled for this serviceTypes
        const totalLabor = parseFloat(Number(laborHours) * localLaborRate);
        processPriceChanges(totalLabor, laborRateNumber, false);
        setPriceModified(true);
      }
    }
  };

  const handleLaborTimeChange = event => {
    const { value } = event.target;
    let newLaborHours = "";
    if (value) {
      if (value.includes("_")) {
        newLaborHours = "0.";
      } else {
        newLaborHours = value;
      }
    } else {
      newLaborHours = "";
    }
    setLaborHours(newLaborHours);
    if (!isEmpty(newLaborHours)) {
      const newLaborHourNumber = parseFloat(newLaborHours);
      const changed = newLaborHourNumber !== originalLaborTime;
      console.log("DEBUG labor time change event", changed);
    }
  };

  const handleOpCodeChange = event => {
    const { value: code } = event.target;
    if (code) {
      setLocalOpCode(code);
    } else {
      setLocalOpCode("");
    }
  };

  const onBlurLaborTime = () => {
    if (!isEmpty(laborHours)) {
      const laborHourNumber = parseFloat(laborHours);
      const changed = laborHourNumber !== originalLaborTime;
      const error = laborHourNumber > 999.99;

      if (isAlwaysShowLaborRateEnabled) {
        if (stateService?.totalLaborPriceOverride) {
          // Per logic for US1491994, if the total labor price was previously overridden, don't recalculate it,
          // and also don't re-calculate the labor rate if the hours change.
        } else {
          const totalLabor = parseFloat(Number(laborHours) * localLaborRate);
          processPriceChanges(totalLabor, parseFloat(localLaborRate), false);
          setPriceModified(true);
          dispatch({
            type: Actions.SET_LABOR_RATE,
            payload: {
              laborRate: parseFloat(localLaborRate),
              flag: isAlwaysShowLaborRateEnabled
            }
          });
          const changed = parseFloat(localLaborRate) !== originalLaborRate;
          dispatch({
            type: Actions.SET_CHANGED,
            payload: {
              field: "laborRate",
              value: changed
            }
          });
        }
      } else {
        if (priceModified) {
          let laborRate = 0;
          if (+laborHours) {
            laborRate = localLaborPrice / laborHours;
            laborRate = parseFloat(laborRate.toFixed(2));
          }
          setLocalLaborRate(toPriceString(laborRate));
          dispatch({
            type: Actions.SET_LABOR_RATE,
            payload: {
              laborRate,
              flag: isAlwaysShowLaborRateEnabled
            }
          });
        }
      }

      dispatch({
        type: Actions.SET_CHANGED,
        payload: {
          field: "laborTime",
          value: changed
        }
      });

      dispatch({
        type: Actions.SET_LABOR_TIME,
        payload: Number(laborHours)
      });

      dispatch({
        type: Actions.SET_ERRORS,
        payload: {
          field: "laborTime",
          value: error
        }
      });
    }
  };

  const onBlurLaborPrice = () => {
    let dirty = false;
    const changed = parseFloat(localLaborRate) !== originalLaborRate;
    if (!isEmpty(String(localLaborPrice))) {
      // eslint-disable-next-line unused-imports/no-unused-vars
      dirty = true;
      const laborPriceNumber = parseFloat(localLaborPrice);
      if (isAlwaysShowLaborRateEnabled) {
        processPriceChanges(laborPriceNumber, undefined, changed);
      } else {
        processPriceChanges(laborPriceNumber);
      }

      if (!laborHours) {
        if (!isAlwaysShowLaborRateEnabled) {
          setLocalLaborRate(toPriceString(0));
          dispatch({
            type: Actions.SET_LABOR_RATE,
            payload: {
              laborRate: 0,
              flag: isAlwaysShowLaborRateEnabled
            }
          });
        }
      } else {
        if (!isAlwaysShowLaborRateEnabled) {
          let totalLaborRate = laborPriceNumber / Number(laborHours);
          totalLaborRate = totalLaborRate.toFixed(2);
          setLocalLaborRate(toPriceString(totalLaborRate));
          dispatch({
            type: Actions.SET_LABOR_RATE,
            payload: {
              laborRate: totalLaborRate,
              flag: isAlwaysShowLaborRateEnabled
            }
          });
        } else {
          // when overriding laborPrice value, change laborrate in state
          dispatch({
            type: Actions.SET_CHANGED,
            payload: {
              field: "laborRate",
              value: changed
            }
          });
          dispatch({
            type: Actions.SET_LABOR_RATE,
            payload: {
              laborRate: parseFloat(localLaborRate),
              flag: isAlwaysShowLaborRateEnabled
            }
          });
        }
      }

      if (isAlwaysShowLaborRateEnabled && changed) {
        updateLaborPriceCalculation({
          laborPrice: laborPriceNumber,
          type: actionTypes.LABOR_OVERRIDE
        });
      }
    } else {
      // @note: When Labor Price modified as blank, retain field with {API retuned price value}
      setLocalLaborPrice(backupLaborPrice);
      dispatch({
        type: Actions.SET_CHANGED,
        payload: {
          field: "laborPrice",
          value: changed
        }
      });
      updateLaborPriceCalculation({
        laborPrice: backupLaborPrice,
        type: actionTypes.LABOR_OVERRIDE
      });
    }
  };
  /**
   * Purpose: Util called to update context sevice fields  with recalculation logic
   * @param {number} priceParam - labor price field value convert in to float before comparing with original prices
   * @param {number} laborRateNumber - labor rate
   * @param {boolean} isNewManualTotalLaborPriceOverride - flag indicating whether this call is the result of
   *                  the total labor price having just been manually overridden
   */
  const processPriceChanges = (
    priceParam = 0,
    laborRateNumber,
    isNewManualTotalLaborPriceOverride
  ) => {
    const price = parseFloat(priceParam.toFixed(2));
    const changed = price !== parseFloat(finalLaborPrice.toFixed(2));
    const error = price > 99999.99;
    if (changed) {
      updateLaborPriceCalculation({
        laborPrice: price,
        type:
          isAlwaysShowLaborRateEnabled && !isNewManualTotalLaborPriceOverride
            ? null
            : actionTypes.LABOR_OVERRIDE,
        laborRateNumber
      });

      dispatch({
        type: Actions.SET_CHANGED,
        payload: {
          field: "laborPrice",
          value: changed
        }
      });
    }
    dispatch({
      type: Actions.SET_ERRORS,
      payload: {
        field: "laborPrice",
        value: error
      }
    });
  };

  const onBlurOpCode = () => {
    const changed = localOpCode !== dmsOpcode;
    const error = !isEmpty(localOpCode) && localOpCode.length > 256;
    dispatch({
      type: Actions.SET_CHANGED,
      payload: {
        field: "opCode",
        value: changed
      }
    });

    dispatch({
      type: Actions.SET_ERRORS,
      payload: {
        field: "opCode",
        value: error
      }
    });

    if (changed) {
      dispatch({
        type: Actions.SET_OPCODE,
        payload: localOpCode
      });
    }
  };

  return {
    BODY_SHOP,
    state,
    defaultserviceCodeID,
    laborHours,
    setLaborHours,
    originalLaborPrice,
    localLaborPrice,
    setLocalLaborPrice,
    localSubTypeId,
    setLocalSubTypeId,
    localCostAllocationSubTypeId,
    setLocalCostAllocationSubTypeId,
    newLocalCostAllocationName,
    setNewLocalCostAllocationName,
    localOpCode,
    setLocalOpCode,
    localLaborRate,
    disablePaytype,
    defaultpayCodeID,
    serviceTypeTemp,
    isDMSPlusDealer,
    localVendorName,
    getLaborPrice,
    handlePayTypeChange,
    handlePayTypeSubTypeChange,
    handlePayTypeCostAllocationChange,
    handleNewPayTypeCostAllocationChange,
    handleLaborPriceChange,
    handleLaborTimeChange,
    handleOpCodeChange,
    handleServiceTypeChange,
    handleServiceContractChange,
    handleWarrantyPlanChange,
    localWarrantyPlanLabel,
    handleLaborRateChange,
    onBlurLaborRate,
    onBlurLaborTime,
    onBlurLaborPrice,
    onBlurOpCode,
    payTypeConfirmationModal,
    showConfirmationModalForPayType,
    confirmPayTypeChange,
    dealerProperties,
    isServiceContractPayType,
    isInsuranceSelected,
    handleContractNumberChange,
    localContractNumber,
    isAlwaysShowLaborRateEnabled,
    localSpecialLaborPrice
  };
}
