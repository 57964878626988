import React from "react";
import PropTypes from "prop-types";
import format from "../../../utils/format";
import { appTypes } from "../../../../../constants/app.constants";
import Button from "@cx/ui/Button";

const AdjustmentInformationRow = ({
  label,
  description,
  total,
  appType,
  onModify,
  canModify
}) => {
  const renderModify = () => {
    // @sq-logic - hidden when service can no longer be modified - decision by Trance to keep consistency with existing Modify behavior on SQ
    if (appType === appTypes.SQ && !canModify) {
      return null;
    }

    // @sq-logic - only displayed when service can still be modified
    // @csr-logic - always displayed but become disabled when pay type is closed
    return (
      <>
        <span> | </span>
        <Button
          htmlId="modifyButton"
          buttonStyle="link"
          disabled={!canModify}
          className="modify"
          onClick={onModify}
          title={!canModify ? "Not available for completed services" : ""}
        >
          Modify
        </Button>
      </>
    );
  };

  return (
    <>
      <div className="adjustment-information-row">
        <div className="adjustment-information-row-description">
          <span className="adjustment-information-row-label">
            {`${label} - `}
          </span>
          <span>{description}</span>
          {renderModify()}
        </div>
        <span className="price">{format.currency(total)}</span>
      </div>
      <div className="separator" />
    </>
  );
};

AdjustmentInformationRow.defaultProps = {
  canModify: true
};

AdjustmentInformationRow.propTypes = {
  label: PropTypes.string,
  description: PropTypes.string,
  total: PropTypes.number,
  appType: PropTypes.oneOf([appTypes.SQ, appTypes.CSR]),
  onModify: PropTypes.func,
  canModify: PropTypes.bool
};

export default AdjustmentInformationRow;
