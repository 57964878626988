import { makeSecureRestApi } from "../../../api/xmmAxios";
/**
 * Fetches the historical ROs from the api.
 * @param {string} searchCriteria
 * @param {string} dealerCode
 * @returns {Promise<object>}
 */

export const getRoHistory = (searchCriteria, dealerCode) => {
  const headers = {
    Accept: "application/json"
  };
  const restEndPoint = `/rohistory/csr/search/${dealerCode}`;

  return new Promise((resolve, reject) => {
    makeSecureRestApi(
      {
        url: restEndPoint,
        method: "get",
        data: {},
        params: {
          searchKey: searchCriteria
        },
        headers
      },
      response => {
        resolve(response);
      },
      error => {
        reject(error);
      },
      "Search failed, please try again in a few minutes."
    );
  });
};
