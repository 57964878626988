/* eslint-disable no-console */
import React, { useState } from "react";
import Alert from "@cx/ui/Alert";
import { useErrorBoundary } from "./error.provider";
import Card from "@cx/ui/Card";
import omit from "lodash/omit";
import isEmpty from "lodash/isEmpty";

function ErrorNotification() {
  const errors = useErrorBoundary();
  const [showDetails, setShowDetails] = useState(false);
  const stackMessage =
    errors?.error?.stack || errors?.errorInfo?.componentStack;

  const onShowMoreDetails = () => setShowDetails(!showDetails);

  return (
    <div>
      {errors?.hasError ? (
        <Alert
          htmlId="errorAlert"
          type=""
          displayCloseButton={true}
          onCloseClick={errors?.removeError}
        >
          <div className="sq-flex-grid-container">
            {errors?.error?.message ? (
              <h6 className="sq-error-msg">
                <span
                  style={{
                    display: "inline-block",
                    padding: "4px 0",
                    fontSize: 14
                  }}
                >
                  JS Error: {errors?.error.message}
                </span>
                <span
                  className="sq-link"
                  style={{ float: "right" }}
                  onClick={onShowMoreDetails}
                >
                  {!showDetails ? "Show details" : "Hide details"}
                </span>
              </h6>
            ) : null}
            {!isEmpty(stackMessage) && showDetails ? (
              <div>
                <p className="sq-msg">{stackMessage}</p>
                <Card htmlId="error-card-boundary">
                  <pre
                    style={{
                      background: "#FAFAFA",
                      color: "#000",
                      fontSize: ".75rem",
                      padding: ".5rem",
                      display: "flex",
                      width: "100%"
                    }}
                  >
                    {JSON.stringify(
                      omit(errors, ["error.stack", "errorInfo"]),
                      null,
                      2
                    )}
                  </pre>
                </Card>
              </div>
            ) : null}
          </div>
        </Alert>
      ) : null}
    </div>
  );
}

export default ErrorNotification;
