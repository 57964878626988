import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import ModalDialog from "@cx/ui/ModalDialog";
import Button from "@cx/ui/Button";
import ErrorBoundary from "./error-boundary.component";
import Sublet from "./sublet/sublet.component";
import csrUtil from "../utils/csr-util";
import {
  LINE_SUBLET,
  SUBLET_DEFAULT,
  SPLIT_SUBLET_DEFAULT
} from "../constants/adjustment.constant";
import { AppContext } from "../../../state/app-context";
import SplitCostSublet from "./sublet/split-cost-sublet.component";
import {
  discountPropType,
  feePropType,
  subletPropType
} from "./adjustment-information/types/propTypes";
import { canSaveSublet } from "./sublet/utils/sublet.util";

const PriceAdjustmentModal = props => {
  const { currentModalType, service, currentModalItem } = props;
  const [showModal, setShowModal] = useState(true);
  const [payload, setPayload] = useState(null);
  const appContext = useContext(AppContext);
  const { dealerProperties } = appContext;
  const isSplitCostSubletEnabled =
    dealerProperties?.CSR_SUBLET_FORM_ENABLED === "Y";
  const isAddMode = currentModalItem === null;

  const getModalHeader = () => {
    const serviceLineNumber = service?.serviceLineNumber;
    let header = {};
    switch (currentModalType) {
      case LINE_SUBLET:
        header = {
          header: `Sublet for line ${serviceLineNumber}`,
          width: "quarter-width"
        };
        break;
      default:
        header = {
          header: "",
          width: ""
        };
    }
    return header;
  };

  const handleOnUpdate = payload => {
    setPayload(payload);
  };

  const handleOnEnter = () => {
    if (checkSaveButtonEnabled()) {
      saveAction();
    }
  };

  // ok/save handler
  const saveAction = () => {
    setShowModal(false);
    props.callbackSaveAction(payload);
  };

  // close icon click handler
  const hideModalHandler = () => {
    // @note: special case: to hide modal when we have two action buttons instead of cancel link
    if (props.specialCase) {
      const { callbackCloseAction } = props;
      if (typeof callbackCloseAction === "function") {
        callbackCloseAction();
      }
      return;
    }
    setShowModal(false);
    props.callbackCloseAction();
  };

  // remove handler on demand
  const customAction = () => {
    setShowModal(false);
    props.callbackRemoveAction();
  };

  const checkIsNewRecord = () => {
    switch (currentModalType) {
      case LINE_SUBLET:
        if (isSplitCostSubletEnabled) {
          return isAddMode;
        }
        return service.sublets.length === 0;
      default:
        console.error(`Modal type not accounted for: ${currentModalType}`);
        return false;
    }
  };

  const checkSaveButtonEnabled = () => {
    if (!payload) {
      return false;
    }

    switch (currentModalType) {
      case LINE_SUBLET:
        if (isSplitCostSubletEnabled) {
          return canSaveSublet(payload, currentModalItem);
        }
        return csrUtil.isValidSublet(payload);
      default:
        console.error(`Modal type not accounted for: ${currentModalType}`);
        return false;
    }
  };

  const renderContent = () => {
    let content = null;
    if (currentModalType === LINE_SUBLET) {
      if (isSplitCostSubletEnabled) {
        content = (
          <SplitCostSublet
            initialSublet={currentModalItem ?? SPLIT_SUBLET_DEFAULT}
            onUpdate={handleOnUpdate}
          />
        );
      } else {
        content = (
          <Sublet
            sublet={
              service.sublets.length > 0 ? service.sublets[0] : SUBLET_DEFAULT
            }
            onUpdate={handleOnUpdate}
            onEnter={handleOnEnter}
          />
        );
      }
    }
    return content;
  };

  return (
    <ModalDialog
      htmlId="PriceAdjustmentModalDialog"
      backdrop="static"
      className={`sample-modal-dialog  ${getModalHeader().width}`}
      show={showModal}
      header={<h4 className="modal-title">{getModalHeader().header}</h4>}
      footer={
        <div>
          {!checkIsNewRecord() ? (
            <Button
              htmlId="removebtnModal"
              onClick={customAction}
              className={`float-left ${props.hideRemove ? "hide" : ""}`}
              buttonStyle={props.removeBtnStyle}
              tabIndex="-1"
            >
              {props.removeText}
            </Button>
          ) : null}
          {isSplitCostSubletEnabled ? (
            <Button
              htmlId="cancelbtnModal"
              buttonStyle="secondary"
              onClick={hideModalHandler}
            >
              Cancel
            </Button>
          ) : null}
          <Button
            htmlId="okbtnModal"
            onClick={saveAction}
            disabled={!checkSaveButtonEnabled()}
            buttonStyle={props.okBtnStyle}
            className={props.hideOk ? "hide" : ""}
            tabIndex="0"
          >
            {props.okText}
          </Button>
        </div>
      }
      onHide={hideModalHandler}
    >
      <div className="sq-flex-container">
        <ErrorBoundary>{renderContent()}</ErrorBoundary>
      </div>
    </ModalDialog>
  );
};

export default PriceAdjustmentModal;

PriceAdjustmentModal.propTypes = {
  specialCase: PropTypes.bool,
  callbackSaveAction: PropTypes.func,
  callbackCloseAction: PropTypes.func,
  callbackRemoveAction: PropTypes.func,
  okBtnStyle: PropTypes.string,
  removeBtnStyle: PropTypes.string,
  okText: PropTypes.string,
  removeText: PropTypes.string,
  hideOk: PropTypes.bool,
  hideRemove: PropTypes.bool,
  currentModalItem: PropTypes.oneOfType([
    subletPropType,
    feePropType,
    discountPropType
  ])
};

PriceAdjustmentModal.defaultProps = {
  // events
  callbackCloseAction: () => {},
  // data
  message: "Test message",
  okBtnStyle: "primary",
  removeBtnStyle: "danger",
  okText: "Save",
  removeText: "Remove",
  hideRemove: false,
  hideOk: false,
  specialCase: false
};
