import { appTypes } from "../../constants/app.constants";

const CONFIG = {
  diagnosis: {
    searchHeader: true,
    vehicleAttr: false,
    serviceOptions: false,
    description: true,
    asrNotes: false,
    parts: true,
    laborComponent: true,
    partsLookup: true,
    partsGrid: true,
    csrDetails: false, 
    notes: true,
    dealershipNotes: true
  },
  dealerPublished: {
    searchHeader: true,
    vehicleAttr: false,
    serviceOptions: false,
    description: true,
    asrNotes: false,
    laborComponent: true,
    partsLookup: true,
    partsGrid: true,
    notes: true,
    dealershipNotes: true,
    csrDetails: false, 
    parts: true
  },
  declined: {
    searchHeader: true,
    vehicleAttr: false,
    serviceOptions: false,
    description: true,
    asrNotes: true,
    laborComponent: true,
    partsLookup: true,
    partsGrid: true,
    csrDetails: false, 
    notes: true,
    dealershipNotes: true
  },
  recall: {
    searchHeader: true,
    vehicleAttr: false,
    serviceOptions: false,
    description: true,
    asrNotes: false,
    laborComponent: true,
    partsLookup: true,
    partsGrid: true,
    csrDetails: false, 
    notes: false,
    dealershipNotes: true
  },
  globalRepair: {
    searchHeader: true,
    vehicleAttr: true,
    serviceOptions: true,
    description: true,
    asrNotes: false,
    laborComponent: true,
    partsLookup: true,
    partsGrid: true,
    csrDetails: false, 
    notes: true,
    dealershipNotes: true
  },
  menu: {
    searchHeader: true,
    vehicleAttr: false,
    serviceOptions: false,
    description: true,
    asrNotes: false,
    laborComponent: true,
    partsLookup: true,
    partsGrid: true,
    csrDetails: false, 
    notes: true,
    dealershipNotes: true
  },
  tellusMore: {
    searchHeader: true,
    vehicleAttr: false,
    serviceOptions: false,
    description: false,
    asrNotes: false,
    laborComponent: true,
    partsLookup: true,
    partsGrid: true,
    csrDetails: false, 
    notes: true,
    dealershipNotes: false
  }
};

/**
 * Updates configuration settings based on the application type.
 * 
 * This function modifies the global settings object based on the provided 
 * application type (`appType`). If the application type is CSR, it will 
 * enable the `csrDetails` flag for specific sections in the settings.
 * 
 * @param {string} appType - The type of the application, e.g., CSR.
 * @returns {Object} - The updated configuration settings.
 */
function updateEditSettings(appType) {
  // Clone CONFIG to avoid modifying the original reference
  const settings = { ...CONFIG };

  // Update settings if the application type is CSR
  if (appType === appTypes.CSR) {
    const sectionsToUpdate = [
      'diagnosis',
      'dealerPublished',
      'globalRepair',
      'menu',
      'declined',
      'recall',
    ];

    sectionsToUpdate.forEach(section => {
      if (settings[section]) {
        settings[section].csrDetails = true;
      }
    });
  }

  return settings;
}

export {
  CONFIG,
  updateEditSettings
};
