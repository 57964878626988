import TextArea from "@cx/ui/TextArea";
import TextInput from "@cx/ui/TextInput";
import PropTypes from "prop-types";
import React, { useState } from "react";
import {
  convertDateTimeToTimeFormat,
  convertMinutesToHours,
  parseTimeWithDate,
  timePattern,
  allowedKeys,
  isWorkStartedBeforeWorkEnded,
  getLocalTimezone
} from "../../../../utils/time";
import OverrideTimeSwitchButton from "./override-time-switch-button.component";
import cloneDeep from "lodash/cloneDeep";

const ManagerOverridesContentComponent = ({
  data,
  updateOverridePayload,
  originalData
}) => {
  const [isValid, setIsValid] = useState(true);
  const [errorMessage, setErrorMessage] = useState(true);
  const timeZone = getLocalTimezone();
  // Handle key down event
  const handleKeyDown = event => {
    const { key } = event;
    // Prevent any key that is not in the allowedKeys array
    if (!allowedKeys.includes(key)) {
      event.preventDefault();
    }

    // Prevent entering values before the colon greater than 12
    if (key >= "0" && key <= "9") {
      const newTime = data[event.target.name] + key;
      const [hours] = newTime.split(":");
      if (hours.length > 0 && parseInt(hours, 10) > 12) {
        event.preventDefault();
      }
    }
  };

  const startEndTimeOverrideChange = e => {
    const payload = cloneDeep(data);
    payload[e.target.name] = e.target.value;
    if (e.target.name !== "overrideReason") {
      const { timeDifferenceMinutes, startDate, endDate } =
        calculateOverrideWorkingDuration(payload);
      payload["workingDuration"] = timeDifferenceMinutes;
      payload["workStarted"] = startDate;
      payload["workEnded"] = endDate;
      updateOverridePayload(payload);
      // Validate the input value against the regex
      if (timePattern.test(e.target.value)) {
        if (
          isWorkStartedBeforeWorkEnded(payload.workStarted, payload.workEnded)
        ) {
          setIsValid(true);
        } else {
          setErrorMessage("The end time must be after the start time.");
          setIsValid(false);
        }
      } else {
        setErrorMessage(
          "Invalid time format. Please use HH:MM (1 to 12 : 00 to 59)."
        );
        setIsValid(false);
      }
    } else updateOverridePayload(payload);
  };

  const updateTimeModifiers = (value, key) => {
    const payload = cloneDeep(data);
    payload[key] = value;
    const { timeDifferenceMinutes, startDate, endDate } =
      calculateOverrideWorkingDuration(payload);
    payload["workingDuration"] = timeDifferenceMinutes;
    payload["workStarted"] = startDate;
    payload["workEnded"] = endDate;
    if (isWorkStartedBeforeWorkEnded(payload.workStarted, payload.workEnded)) {
      setIsValid(true);
    } else {
      setErrorMessage("The end time must be after the start time.");
      setIsValid(false);
    }
    updateOverridePayload(payload);
  };

  const calculateOverrideWorkingDuration = payload => {
    if (payload?.startTime && payload.endTime) {
      // Step 1: Parse the start time with workStarted's date in UTC
      const startDate = parseTimeWithDate(
        originalData?.workStarted,
        payload?.startTime,
        payload?.startTimeModifier
      );
      // Step 2: Parse the end time with workEnded's date in UTC
      const endDate = parseTimeWithDate(
        originalData?.workEnded,
        payload?.endTime,
        payload?.endTimeModifier
      );
      // Step 3: Calculate the time difference in milliseconds
      const timeDifferenceMs = new Date(endDate) - new Date(startDate);
      // Step 4: Convert milliseconds to minutes
      const timeDifferenceMinutes = Math.floor(timeDifferenceMs / 60000);
      return {
        timeDifferenceMinutes,
        startDate,
        endDate
      };
    }
  };

  return (
    <div className="manager-overrides-content-container-box">
      <span className="manager-overrides-content-recorded-time-box">
        Recorded time:
      </span>
      <div className="manager-overrides-content-actual-start-end-time-box">
        <span className="manager-overrides-content-actual-start-end-time-text">
          Start time:
        </span>
        <span className="manager-overrides-content-actual-start-end-time">
          {convertDateTimeToTimeFormat(originalData?.workStarted, timeZone)}
        </span>
        <span className="manager-overrides-vertical-bar">|</span>
        <span className="manager-overrides-content-actual-start-end-time-text">
          End time:
        </span>
        <span className="manager-overrides-content-actual-start-end-time">
          {convertDateTimeToTimeFormat(originalData?.workEnded, timeZone)}
        </span>
      </div>
      <div className="manager-overrides-content-actual-time-box">
        <span className="manager-overrides-content-actual-time-text">
          Actual time:
        </span>
        <span className="manager-overrides-content-actual-time">
          {convertMinutesToHours(originalData?.workingDuration)}
        </span>
      </div>
      <div className="manager-overrides-horizontal-bar" />
      <span className="manager-overrides-content-override-time-box">
        Override time to:
      </span>
      <div className="manager-overrides-content-override-time-container">
        <TextInput
          htmlId="startTimeTextInputRequired"
          className="start-time-text-input-field"
          label="Start time"
          name="startTime"
          onChange={startEndTimeOverrideChange}
          required
          value={data?.startTime || ""}
          maxLength={5}
          onKeyDown={handleKeyDown}
        />
        <OverrideTimeSwitchButton
          data={data?.startTimeModifier}
          modifierKey="startTimeModifier"
          updateTimeModifiers={updateTimeModifiers}
        />
        <TextInput
          htmlId="endTimeTextInputRequired"
          className="end-time-text-input-field"
          label="End time"
          name="endTime"
          onChange={startEndTimeOverrideChange}
          required
          value={data?.endTime || ""}
          maxLength={5}
          onKeyDown={handleKeyDown}
        />
        <OverrideTimeSwitchButton
          data={data?.endTimeModifier}
          modifierKey="endTimeModifier"
          updateTimeModifiers={updateTimeModifiers}
        />
      </div>
      {!isValid ? (
        <p className="manager-override-error">{errorMessage}</p>
      ) : null}

      <div className="manager-overrides-content-adjusted-actual-time-box">
        <span className="manager-overrides-content-adjusted-actual-time-text">
          Adjusted actual time:
        </span>
        <span className="manager-overrides-content-adjusted-actual-time">
          {data?.workingDuration
            ? convertMinutesToHours(data?.workingDuration)
            : 0}
        </span>
      </div>
      <TextArea
        htmlId="textAreaRequired"
        label="Reason"
        name="overrideReason"
        required
        onChange={e => startEndTimeOverrideChange(e)}
        value={data?.overrideReason || ""}
        maxLength={40}
      />
    </div>
  );
};

export default ManagerOverridesContentComponent;

ManagerOverridesContentComponent.propTypes = {
  data: PropTypes.object,
  originalData: PropTypes.object,
  updateOverridePayload: PropTypes.func.isRequired
};
