import { StatusMappings } from "./status-badge.constants";

/**
 * @typedef {Object} StatusObject
 * @property {string} status
 * @property {string} color
 */
/**
 * @typedef {Object} Params
 * @property {string} status
 * @property {string} [color]
 */
/**
 * As argument, this utility expects an object with at least one property which has to be named status ({ status: "" }), which is the quote status ("IN_PROGRESS", "SENT", etc).
 * It also accepts an optional second parameter "color" in case a specific color mapping is desired.
 * @param {Params} params
 * @return {StatusObject} statusObject
 */
function getStatusProperties(params) {
  const { status, color = "gray" } = params;
  // The default color is grey and the default status is the original string param,
  // so that if there are no matches, a usable status object is returned. Ex: getStatusProperties({ status: "New status" }) -> { color: "gray", status: "New status" }
  // If the optional color param is provided, then that is used. Ex: Ex: getStatusProperties({ status: "New status", color: "Yellow" }) -> { color: "yellow", status: "New status" }
  const statusObj = { color, label: status };

  for (const mapping of StatusMappings) {
    if (mapping.status === status) {
      statusObj.color = mapping.color;
      statusObj.label = mapping.label;
    }
  }
  return statusObj;
}

export { getStatusProperties };
