import React from "react";
import { array, bool, func, number, object, string } from "prop-types";
import clsx from "clsx";
import Dropdown from "@cx/ui/Dropdown";
import IconMore from "@cx/ui/Icons/IconMore";

function SummaryButton(props) {
  const {
    isEnabled,
    isXsmall,
    dropDownOptions,
    localeStrings,
    onClick,
    quoteTotalAmountText,
    totalServices
  } = props;
  return (
    <div id="quoteTotalButton" className="bar-quote-btn">
      {!isXsmall ? (
        <div
          id="quoteTotalServicesBadge"
          className={clsx("quote-badge", isEnabled ? "live" : "disable")}
          onClick={() => onClick()}
        >
          {totalServices}
        </div>
      ) : null}
      <div
        className={clsx("bar-middle-content", isEnabled ? "live" : "disable")}
        onClick={() => onClick()}
      >
        {!isXsmall ? (
          <div className="quote-lbl">
            {localeStrings["sq.newquote.quote_stepper.quote_summary_lbl"]}:{" "}
          </div>
        ) : null}
        <div id="quoteTotalAmountText">{quoteTotalAmountText}</div>
      </div>
      {!isXsmall ? (
        <Dropdown
          buttonStyle="link"
          icon={<IconMore />}
          htmlId="customerMenu"
          name="customerMenuIcon"
          className="xmm-dotted-dropdown btn--icon quote-header-menu"
          pullRight
          options={[...dropDownOptions]}
          disabled={Boolean(!dropDownOptions.length)}
        />
      ) : null}
    </div>
  );
}

SummaryButton.propTypes = {
  isEnabled: bool,
  isXsmall: bool,
  dropDownOptions: array,
  localeStrings: object.isRequired,
  onClick: func,
  quoteTotalAmountText: string,
  totalServices: number
};

SummaryButton.defaultProps = {
  isEnabled: true,
  isXsmall: false,
  dropDownOptions: [],
  localeStrings: {},
  onClick: () => {},
  quoteTotalAmountText: "",
  totalServices: 0
};

export default SummaryButton;
