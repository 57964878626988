import { getPayloadForPartsInventoryUsingQuoteService } from "../../page-wrapper/utils/data-util";
import globalOperationsService from "../../page-wrapper/services/global-operations.service";

const asyncHandleGetPartsInventoryCommon = async (
  currentEditingService,
  payType,
  vehicle,
  serviceType
) => {
  let partsPricingAndInventory = [];
  try {
    const payTypeCode = payType || "";
    const serviceTypeCode = serviceType || "";
    const postParams = getPayloadForPartsInventoryUsingQuoteService(
      currentEditingService,
      vehicle,
      payTypeCode,
      currentEditingService.operationSource,
      "",
      serviceTypeCode
    );
    partsPricingAndInventory =
      await globalOperationsService.getPartsPricingAndInventory(
        null,
        postParams
      );
  } catch (error) {
    partsPricingAndInventory = [];
    console.log(
      "EditServiceHOC Call to opentrack API failed with error",
      error
    );
  }

  return partsPricingAndInventory;
};

export { asyncHandleGetPartsInventoryCommon };
