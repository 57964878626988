const getPreselectedServices = services => {
  const selectedOps = [];
  services.forEach(service => {
    let isDefault = false;
    if (!service.selectable && !service.selectByDefault) {
      isDefault = true;
    }
    // selected[service.id] = selectByDefault;
    if (service.selectByDefault || isDefault) selectedOps.push(service);
  });
  return selectedOps;
};

export { getPreselectedServices };
