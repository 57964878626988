import { makeSecureRestApi } from "../../../../api/xmmAxios";
import { LogLevel, writeLogEntry } from "../../../../services/log.service";

const saveSublet = async (
  appContext,
  quoteSummary,
  currentEditingService,
  sublet
) => {
  const { confirmationId } = quoteSummary;
  const { dealer, user } = appContext;
  let { dealerCode } = dealer;
  const { quoteServiceId } = currentEditingService;
  const requestPayload = {
    ...sublet,
    lastModBy: user.quoteUserId,
    lastModTime: null
  };

  if (sublet.vendorNotes.trim() === "TEST_HOOK_INVALID_DEALER_CODE") {
    dealerCode = "INVALID_DEALER_CODE";
  } else if (
    sublet.vendorNotes.trim() === "TEST_HOOK_ERROR_DETAIL_DATA_TYPES"
  ) {
    // This should result in a log message like the following:
    // a:54.879|b:true|c:2023-09-21T14:32:38-0500|d:[1,2,3,"2024-02-01T23:00:00.000Z"]|e:null|f:undefined|g:function someFunction() {}|h:Symbol(x)|i:Math|j:NaN|k:{"a":1}
    writeLogEntry(LogLevel.WARN, {
      a: 54.879,
      b: true,
      c: new Date(),
      d: [1, 2, 3, new Date(2024, 1, 1, 17, 0, 0)],
      e: null,
      f: undefined,
      g: function someFunction() {},
      h: Symbol("x"),
      i: Math,
      j: NaN,
      k: { a: 1 }
    });
  }

  return new Promise((resolve, reject) => {
    makeSecureRestApi(
      {
        url: `service/${quoteServiceId}/dealercode/${dealerCode}/quote/${confirmationId}/sublet`,
        method: "put",
        data: requestPayload
      },
      response => {
        resolve(response);
      },
      error => {
        reject(error);
      },
      "Unable to save sublet."
    );
  });
};

const removeSublet = async (
  appContext,
  quoteSummary,
  currentEditingService,
  subletId
) => {
  const { confirmationId } = quoteSummary;
  const { dealer } = appContext;
  const { dealerCode } = dealer;
  const { quoteServiceId } = currentEditingService;

  return new Promise((resolve, reject) => {
    makeSecureRestApi(
      {
        url: `service/${quoteServiceId}/dealercode/${dealerCode}/quote/${confirmationId}/sublet/${subletId}`,
        method: "delete"
      },
      response => {
        resolve(response);
      },
      error => {
        reject(error);
      },
      "Unable to remove sublet."
    );
  });
};

export { saveSublet, removeSublet };
