import React from "react";
import isEmpty from "lodash/isEmpty";
import { useDashboardContext } from "../../state/dashboard.context";
import ServiceTypesComponent from "./service-types-section.component";
// eslint-disable-next-line unused-imports/no-unused-imports
import * as CommonTypes from "../../../../../@types/common.types";
// eslint-disable-next-line unused-imports/no-unused-imports
import * as ContextTypes from "../../../../../@types/context.types";

/**
 * @description Container for the "Service Types" section.
 * Labels and services breakdown validations and fetching from dashboard context should be done here before passing to ServiceTypes.
 * @returns {CommonTypes.Component | null}
 */
const ServiceTypesContainer = () => {
  /** @type {ContextTypes.ContextHook<ContextTypes.DashboardContext>} */
  const { state } = useDashboardContext();
  const { localeStrings, servicesTotals } = state;

  /** @type {Array<string>} */

  const serviceTypesLabels = !isEmpty(localeStrings)
    ? [
        localeStrings["sq.dashboard.service_types_maintenance"],
        localeStrings["sq.dashboard.service_types_diagnosis"],
        localeStrings["sq.dashboard.service_types_declined"],
        localeStrings["sq.dashboard.service_types_repairs"],
        localeStrings["sq.dashboard.service_types_packages"],
        localeStrings["sq.dashboard.service_types_recalls"]
      ]
    : [];
  const serviceTypesHeader = !isEmpty(localeStrings)
    ? localeStrings["sq.dashboard.service_types_header"]
    : "";
  const serviceTypesTooltipMessage = !isEmpty(localeStrings)
    ? localeStrings["sq.dashboard.service_types_tooltip"]
    : "";
  /** @type {Object<string, string|string[]>} */
  const localeStringsObject = {
    serviceTypesLabels,
    serviceTypesHeader,
    serviceTypesTooltipMessage
  };

  return (
    <ServiceTypesComponent
      localeStrings={localeStringsObject}
      servicesTotals={servicesTotals}
    />
  );
};

export default ServiceTypesContainer;
