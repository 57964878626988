// @ts-check
import React from "react";
import isEmpty from "lodash/isEmpty";
import PotentialValueSectionComponent from "./potential-value-section.component";
import { useDashboardContext } from "../../state/dashboard.context";
// eslint-disable-next-line unused-imports/no-unused-imports
import * as CommonTypes from "../../../../../@types/common.types";
// eslint-disable-next-line unused-imports/no-unused-imports
import * as ContextTypes from "../../../../../@types/context.types";

/**
 * @description Container for the potential quote value section.
 * Label validations and fetching from dashboard context should be done here before passing to QuotesSectionCompoennt.
 * @returns {CommonTypes.Component | null}
 */
const PotentialValueSectionContainer = () => {
  /** @type {ContextTypes.ContextHook<ContextTypes.DashboardContext>} */
  const { state } = useDashboardContext();
  const { potentialValueChartData, localeStrings } = state;
  const potentialValueTotalLabel = !isEmpty(localeStrings)
    ? localeStrings["sq.dashboard.potential_value_odometer_total"]
    : "";
  const potentialValueHeader = !isEmpty(localeStrings)
    ? localeStrings["sq.dashboard.potential_value_header"]
    : "";
  const potentialValueTooltipMessage = !isEmpty(localeStrings)
    ? localeStrings["sq.dashboard.potential_value_tooltip"]
    : "";
  /** @type {Array<string>} */
  const potentialValueBoardLabels = !isEmpty(localeStrings)
    ? [
        localeStrings["sq.dashboard.quotes_potential_value_sent"],
        localeStrings["sq.dashboard.quotes_breakdown_scheduled"]
      ]
    : [];
  /** @type {Object<string, string|string[]>} */
  const localeStringsObject = {
    potentialValueTotalLabel,
    potentialValueHeader,
    potentialValueTooltipMessage,
    potentialValueBoardLabels
  };
  return !isEmpty(potentialValueChartData) ? (
    <PotentialValueSectionComponent
      data={potentialValueChartData}
      localeStrings={localeStringsObject}
    />
  ) : null;
};

export default PotentialValueSectionContainer;
