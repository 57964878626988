/**
 * @param  {any} value
 * @returns {boolean} always returns true | false
 */
export const isTrue = (value = "false") => {
  if (value === true) {
    return true;
  }
  if (value === null || value === undefined || value === false) {
    return false;
  }
  const valueStr = typeof value === "number" ? value.toString() : value;
  switch (valueStr.toLowerCase()) {
    case "true":
    case "yes":
    case "y":
    case "1":
      return true;
    default:
      return false;
  }
};
/**
 *
 * @param {string} "true" / "false"
 * @returns {boolean}  true / fasle
 */
export const parseBool = strBoolean => {
  return JSON.parse(strBoolean);
};

export const defaultToZeroIfNullOrEmpty = value => {
  return !value ? 0 : value;
};

/**
 * Corrects floating point numbers that overflow to a non-precise
 * value because of their floating nature, for example `0.1 + 0.2` usually go to 15-16 decimals
 * @function correctFloat
 * @param {number} n
 * @return {number} returns correctly rounded number
 */
// This is to correct the type of errors where 2 floats end with
// a long string of decimals, eg 0.1 + 0.2. When they overflow in this
// manner, they usually go to 15-16 decimals, so we cut it off at 14.
export function correctFloat(n) {
  return parseFloat(n.toPrecision(14));
}

/**
 * @function toFixed
 *  This function formats a number using fixed-point notation
 * @param {number} value The number to format
 * @param {number} precision The number of digits to show after the decimal point
 */
export function toFixed(value, precision) {
  let localPrecision = 2;
  if (!precision) {
    return value.toFixed(localPrecision);
  } else {
    localPrecision = precision || 0;
  }
  const pow = Math.pow(10, localPrecision);
  return (Math.round(value * pow) / pow).toFixed(localPrecision);
}
