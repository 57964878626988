// Internal imports
import { detectBrowser } from "../utils/browser.util";
import { getAppType, makeSecureRestApi } from "../api/xmmAxios";
import { getMetaTagContentByName } from "../utils/dom.util";
import { getUrlParameter } from "../api/app.util";
import util from "../features/repair-order/utils/util";
// 3rd-party components
import moment from "moment";
import platform from "platform";
import { v4 as uuidv4, parse as uuidParse } from "uuid";

const appType = getAppType(getUrlParameter("appType"));

/**
 * Pseudo-enum representing the available log levels.
 * @note These strings must match the server-side log levels, so don't modify them here.
 */
const LogLevel = {
  TRACE: "trace",
  DEBUG: "debug",
  INFO: "info",
  WARN: "warn",
  ERROR: "error"
};

/**
 * Retrieves the client key for the current machine and browser.
 * @returns {string | null} Client key.
 */
function getClientKey() {
  let clientKey = localStorage.getItem("clientKey");
  if (!clientKey) {
    clientKey = uuidv4();
    try {
      clientKey = Buffer.from(uuidParse(clientKey))
        .toString("base64")
        .replace(/=/g, "")
        .replace(/\//g, "_")
        .replace(/\+/g, "-");
    } catch (err) {
      console.error(err);
      return null;
    }
    localStorage.setItem("clientKey", clientKey);
  }
  return clientKey;
}

/**
 * Converts any value to a loggable string.
 * @param {*} val Value you want to log.
 * @returns {string} Resulting string to embed in the log message.
 */
function stringify(val) {
  const type = util.getType(val);

  if (["Null", "Object", "Array"].includes(type)) {
    return JSON.stringify(val);
  } else if (["Math", "Promise"].includes(type)) {
    return type;
  } else if (type === "Date") {
    return moment(val).format("YYYY-MM-DDTHH:mm:ssZZ");
  } else if (type === "Undefined") {
    return "undefined";
  } else {
    return val.toString();
  }
}

/**
 * Serializes an object into key:value pairs delimited by a pipe character.
 * If the value itself contains any pipes, they will be replaced with backticks.
 * @param {object} obj Any object.
 * @returns {string} Serialized string ready for passing to the logging API.
 */
function toDelimitedKVP(obj, propertiesToIgnore) {
  return Object.getOwnPropertyNames(obj)
    .filter(key => !propertiesToIgnore || !propertiesToIgnore.includes(key))
    .map(key => {
      const value = stringify(obj[key]);
      return `${key}:${value.replace(/\|/g, "`")}`;
    })
    .join("|");
}

/**
 * Gets the current application build version.
 * @returns {string}
 */
function getAppVersion() {
  try {
    return getMetaTagContentByName("xtime:buildNumber").replace(
      "%REACT_APP_BUILD_NUMBER%",
      "(local build)"
    );
  } catch (err) {
    console.error(err);
    return "unknown";
  }
}

/**
 * Sends a log entry to the server.
 * @param {string} level
 * @param {object} details Ad-hoc object containing details to log.
 *                         At the very least, it should have an "event" property.
 *                         Example: { event: "Received empty subtype array", payType: "I" }
 * @description Splunk KVP parsing example: | rex field=message "event:(?<event>[^|]*)"
 */
function writeLogEntry(level, details) {
  try {
    const detailsJSON = JSON.stringify(details);
    const last5Logs = JSON.parse(sessionStorage.getItem("last5Logs") ?? "[]");
    if (last5Logs.includes(detailsJSON)) {
      console.log("Suppressing repeat logging", details);
      return;
    }
    makeSecureRestApi(
      {
        url: "/log/writeLogEntry",
        method: "post",
        data: {
          level,
          appName: appType === "CSR" ? "Service RO" : "Service Quoting",
          appVersion: getAppVersion(),
          clientKey: getClientKey(),
          event: details.event,
          dealerCode: details.dealerCode ?? localStorage.getItem("dealerCode"),
          username: details.username ?? localStorage.getItem("username"),
          message: toDelimitedKVP(details, ["event", "dealerCode", "username"])
        }
      },
      data => {
        console.log("writeLogEntry response: " + data);
        if (last5Logs.length === 5) {
          last5Logs.shift();
        }
        last5Logs.push(detailsJSON);
        sessionStorage.setItem("last5Logs", JSON.stringify(last5Logs));
      },
      err => {
        console.error("writeLogEntry error: ", err);
      }
    );
  } catch (err) {
    console.log(details);
    console.error(err);
  }
}

/**
 * Determines whether the error was from the writeLogEntry call itself.
 * @param {object} errorObject Error object.
 * @param {object} event Error event.
 * @returns {boolean} True if the error happened during the writeLogEntry call,
 *                    otherwise false.
 */
function isWriteLogEntryError(errorObject, event) {
  return (
    errorObject?.toString().includes("writeLogEntry") ||
    event?.toString().includes("writeLogEntry") ||
    event?.reason?.config?.url?.includes("writeLogEntry")
  );
}

/**
 * Logs an unhandled Promise rejection error.
 * @param {object} event Error event.
 */
function logUnhandledRejection(event) {
  const details = {
    event: "Unhandled Promise rejection",
    ...util.parseErrorObject(event.reason)
  };
  if (isWriteLogEntryError(undefined, event)) {
    console.error(details);
  } else {
    writeLogEntry(LogLevel.WARN, details);
  }
}

/**
 * Logs an error.
 * @param {object} errorObject Error object.
 * @param {object?} event Error event.
 */
function logError(errorObject, event) {
  const details = {
    event: event ?? "Error",
    ...util.parseErrorObject(errorObject)
  };
  if (isWriteLogEntryError(undefined, event)) {
    console.error(details);
  } else {
    writeLogEntry(LogLevel.ERROR, details);
  }
}

/**
 * Logs application, user, and client details when the app is loaded.
 * @param {object} appState Application state.
 */
function logApplicationStartup(appState) {
  try {
    const { appEnv, appSource, appType, user } = appState;
    const extUserId = user?.id;
    const details = {
      event: "Application startup",
      appEnv,
      appSource,
      appType,
      extUserId,
      dealerCode: localStorage.getItem("dealerCode"),
      url: window.location.href,
      userAgent: window.navigator?.userAgent,
      browser: detectBrowser(),
      operatingSystem: platform?.os?.toString(),
      languages: window.navigator?.languages,
      localTime: moment().format("YYYY-MM-DDTHH:mm:ssZZ"),
      screenWidth: window.screen?.availWidth,
      screenHeight: window.screen?.availHeight,
      screenAvailLeft: window.screen?.availLeft,
      screenAvailTop: window.screen?.availTop,
      windowOuterWidth: window.outerWidth,
      windowOuterHeight: window.outerHeight,
      windowInnerWidth: window.innerWidth,
      windowInnerHeight: window.innerHeight,
      windowX: window.screenX ?? window.screenLeft ?? window.screen?.left,
      windowY: window.screenY ?? window.screenTop ?? window.screen?.top,
      viewportWidth:
        window.visualViewport?.width ??
        window.innerWidth ??
        document.documentElement?.clientWidth,
      viewportHeight:
        window.visualViewport?.height ??
        window.innerHeight ??
        document.documentElement?.clientHeight
    };

    // Getting this detail separately in case some browsers don't support it.
    try {
      details.navigationType =
        performance.getEntriesByType("navigation")[0].type; // navigate, reload
    } catch {
      details.navigationType = "unknown";
    }

    writeLogEntry(LogLevel.INFO, details);
  } catch (err) {
    console.error(err);
  }
}

export {
  LogLevel,
  writeLogEntry,
  logError,
  logUnhandledRejection,
  logApplicationStartup
};
