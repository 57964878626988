import { useEffect } from "react";
import isEmpty from "lodash/isEmpty";

/**
 * Inserts a script tag with a specified URL.
 * If a strigified config object is passed to it, it will be append it before the script tag that contains the URL.
 * Note: This custom hook was originally made to be used with Beamer, but should be usable for similar scenarios.
 * @param {string} url
 * @param {string} config
 */
const useExternalScriptTags = (url, config = "") => {
  useEffect(() => {
    const body = document.querySelector("body");
    const mainScriptTag = document.createElement("script");
    mainScriptTag.setAttribute("src", url);
    mainScriptTag.setAttribute("defer", true);
    let configScriptTag;
    if (!isEmpty(config)) {
      configScriptTag = document.createElement("script");
      configScriptTag.text = config;
      body.appendChild(configScriptTag);
    }
    body.appendChild(mainScriptTag);
    return () => {
      body.removeChild(mainScriptTag);
      if (!isEmpty(config)) {
        body.removeChild(configScriptTag);
      }
    };
  }, [url, config]);
};

export default useExternalScriptTags;
