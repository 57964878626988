/* eslint-disable no-console */
/* This class used to get/set state to localStorage */
export const getCacheItem = cacheKey => {
  try {
    const serializedState = localStorage.getItem(cacheKey);
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

export const setCacheItem = (cacheKey, stateObj) => {
  try {
    const serializedState = JSON.stringify(stateObj);
    localStorage.setItem(cacheKey, serializedState);
  } catch (err) {
    console.error(err);
  }
};

export const saveStateToLocalStorage = state => {
  // for every item in React state
  for (const key in state) {
    // save to localStorage
    localStorage.setItem(key, JSON.stringify(state[key]));
  }
};

function gen(num) {
  return new Array(num * 1024 + 1).join("a");
}

export function localStorageLimit() {
  // Determine size of localStorage if it's not set
  if (localStorage && !localStorage.getItem("size")) {
    let i = 0;
    try {
      // Test up to 10 MB
      for (i = 0; i <= 10000; i += 250) {
        localStorage.setItem("test", gen(i));
      }
    } catch (e) {
      localStorage.removeItem("test");
      localStorage.setItem("size", i ? i - 250 : 0);
    }
    console.log(
      "LocaleStorage Limit in Browser",
      localStorage.getItem("size"),
      "KBs."
    );
  }
}
