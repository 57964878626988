/**
 * Updates Opener Window (if detected), otherwise, opens a new tab with a custom URL
 *
 * @param {string} URL
 * @param {boolean} closeWindow
 * @return void
 */
const updateOpenerWindow = (URL, target) => {
  if (window.opener) {
    window.opener.location.href = URL;
    window.self.close();
  } else {
    window.open(URL, target);
  }
};

export { updateOpenerWindow };
