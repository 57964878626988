import moment from "moment";

/**
 * Formats Historical Repair Orders from API to include at top level for AG Grid:
 *  customerName
 *  serviceTypeCodes
 *  payTypeCodes
 *  services
 * @param historicalRepairOrders {Array}
 * @returns {Array}
 */
export const formatHistoricalRepairOrders = historicalRepairOrders => {
  return historicalRepairOrders.map(historicalRepairOrder => {
    if (historicalRepairOrder.repairOrder) {
      const { serviceTypeCodes, payTypeCodes } = getServiceTypeAndPayTypeCodes(
        historicalRepairOrder.repairOrder.services
      );
      return {
        ...historicalRepairOrder,
        customerName: getCustomerName(
          historicalRepairOrder.repairOrder.customer
        ),
        serviceTypeCodes,
        payTypeCodes,
        services: historicalRepairOrder.repairOrder.services?.length ?? "- -"
      };
    }
    return historicalRepairOrder;
  });
};

/**
 * Individual customers have name.firstName and name.lastName as string.
 * Business customers have name as string.
 * @param customer {object}
 * @returns string
 */
export const getCustomerName = customer => {
  if (customer?.name) {
    const { name } = customer;

    // Business customer?
    if (typeof name === "string") {
      if (name) {
        return name;
      }
    }

    // Individual customer?
    if (name?.lastName && name?.firstName) {
      return `${name.lastName}, ${name.firstName}`;
    } else if (name?.lastName) {
      return name.lastName;
    } else if (name?.firstName) {
      return name.firstName;
    }
  }

  return "- -";
};

/**
 * Iterate over services array and construct service types and pay types string
 * @param services {Array}
 * @returns {object}
 */
export const getServiceTypeAndPayTypeCodes = services => {
  if (!services) {
    return {
      serviceTypeCodes: "- -",
      payTypeCodes: "- -"
    };
  }

  const serviceTypeCodes = new Set();
  const payTypeCodes = new Set();
  for (const service of services) {
    if (service.serviceType?.code) {
      serviceTypeCodes.add(service.serviceType.code);
    }
    if (service.payTypeCode) {
      payTypeCodes.add(service.payTypeCode);
    }
  }

  const serviceTypeCodesAsArray = Array.from(serviceTypeCodes);
  const payTypeCodesAsArray = Array.from(payTypeCodes);
  return {
    serviceTypeCodes:
      serviceTypeCodesAsArray.length > 0
        ? serviceTypeCodesAsArray.sort().join("/")
        : "- -",
    payTypeCodes:
      payTypeCodesAsArray.length > 0
        ? payTypeCodesAsArray.sort().join("/")
        : "- -"
  };
};

export const formatDate = val => {
  if (!val) {
    return "- -";
  }

  const date = moment(val);
  return date.format("M/D/YY");
};
