// @ts-check
import React from "react";
import isEmpty from "lodash/isEmpty";
import { useDashboardContext } from "../../state/dashboard.context";
import QuotesSectionComponent from "./quotes-section.component";
// eslint-disable-next-line unused-imports/no-unused-imports
import * as CommonTypes from "../../../../../@types/common.types";
// eslint-disable-next-line unused-imports/no-unused-imports
import * as ContextTypes from "../../../../../@types/context.types";

/**
 * @description Container for the quotes section.
 * Label validations and fetching from dashboard context should be done here before passing to QuotesSectionCompoennt.
 * @returns {CommonTypes.Component | null}
 */
const QuotesSectionContainer = () => {
  /** @type {ContextTypes.ContextHook<ContextTypes.DashboardContext>} */
  const { state } = useDashboardContext();
  const {
    quotesNumericBreakdownDataSet,
    quotesAreaTimelineChartData,
    localeStrings,
    dateRange
  } = state;
  const quotesTotalLabel = !isEmpty(localeStrings)
    ? localeStrings["sq.dashboard.quotes_odometer_total"]
    : "";
  /** @type {Array<string>} */
  const breakdownBoardLabels = !isEmpty(localeStrings)
    ? [
        localeStrings["sq.dashboard.quotes_breakdown_in_progress"],
        localeStrings["sq.dashboard.quotes_breakdown_sent"],
        localeStrings["sq.dashboard.quotes_breakdown_scheduled"],
        localeStrings["sq.dashboard.quotes_breakdown_expired"]
      ]
    : [];
  const quotesAndTrendsHeader = !isEmpty(localeStrings)
    ? localeStrings["sq.dashboard.quotes_and_trends_header"]
    : "";
  const quotesAndTrendsTooltipMessage = !isEmpty(localeStrings)
    ? localeStrings["sq.dashboard.quotes_and_trends_tooltip"]
    : "";
  /** @type {Object<string, string|string[]>} */
  const localeStringsObject = {
    quotesTotalLabel,
    breakdownBoardLabels,
    quotesAndTrendsHeader,
    quotesAndTrendsTooltipMessage
  };
  return !isEmpty(quotesNumericBreakdownDataSet) &&
    !isEmpty(quotesNumericBreakdownDataSet) ? (
    <QuotesSectionComponent
      numericBreakdownData={quotesNumericBreakdownDataSet}
      areaTimelineData={quotesAreaTimelineChartData}
      localeStrings={localeStringsObject}
      dateRange={dateRange}
    />
  ) : null;
};

export default QuotesSectionContainer;
