import React from "react";
import PropTypes from "prop-types";
import format from "../../../utils/format";
import Badge from "@cx/ui/Badge";
import Button from "@cx/ui/Button";

const ServiceCatalogFeeDetailComponent = ({
  service,
  onModify,
  showDetails,
  modifyDisabled
}) => {
  let totalCatalogFeeApplied = 0;
  service?.catalogFees?.map(
    fee =>
      (totalCatalogFeeApplied =
        totalCatalogFeeApplied + (fee?.appliedFee ? fee?.appliedFee : 0))
  );
  return (
    <>
      <div
        className={["catalog-fee-details", "margin-left-12"].join(" ")}
        key={"catalog-fee-details - " + service?.extServiceId}
      >
        <div className="catalog-fee-details-title">
          <span>
            <strong>Fees </strong>
          </span>
          {service?.catalogFees?.length > 0 ? (
            <Badge htmlId="adjustmentInformationBadge">
              {service?.catalogFees?.length}
            </Badge>
          ) : null}
        </div>
        <div className="catalog-fee-details-total-price">
          {format.currency(totalCatalogFeeApplied)}
        </div>
      </div>
      {showDetails
        ? service?.catalogFees?.map((fee, index) => {
            const elementKey =
              "catalog-fee-details-" + fee?.dealerFeesId || index;
            return (
              <div key={`${elementKey}-fees`}>
                <div
                  key={elementKey}
                  className={["catalog-fee-details", "margin-left-12"].join(
                    " "
                  )}
                >
                  <div className="catalog-fee-details-title">
                    <span>Fee - </span>
                    <span className="catalog-fee-details-description">
                      {fee?.description}
                    </span>

                    {fee?.applyFeeSvcLine === 0 ? (
                      <>
                        <span> | </span>
                        <Button
                          className="catalog-fee-details-modify"
                          onClick={() => onModify(fee)}
                          buttonStyle="link"
                          disabled={modifyDisabled}
                          title={
                            modifyDisabled
                              ? "Cannot be modified for closed services"
                              : ""
                          }
                        >
                          Modify
                        </Button>
                      </>
                    ) : null}
                  </div>
                  <div className="catalog-fee-details-price">
                    {format.currency(fee?.appliedFee)}
                  </div>
                </div>
                <div className="catalog-fee-separator margin-left-10" />
              </div>
            );
          })
        : null}
    </>
  );
};

ServiceCatalogFeeDetailComponent.propTypes = {
  showDetails: PropTypes?.bool
};

export default ServiceCatalogFeeDetailComponent;
