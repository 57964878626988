/**
 * Checks if any part in the array requires approval.
 *
 * @param {Array} parts - An array of part objects.
 * @returns {boolean} - Returns true if any part has a typeOfPurchase of 'EP' and an approver that is null or undefined.
 */
const checkPartsForApproval = parts => {
  if (!Array.isArray(parts)) {
    return false;
  }

  return parts.some(
    part => part && part.typeOfPurchase === "EP" && !part.approver
  );
};

export { checkPartsForApproval };
