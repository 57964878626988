/* eslint-disable no-console */
/* eslint-disable react/prop-types */
import React, { Component } from "react";
import axios from "axios";
import TextArea from "@cx/ui/TextArea";
import SelectInput from "@cx/ui/SelectInput";
import Button from "@cx/ui/Button";
import Alert from "@cx/ui/Alert";
// import { FeedbackTemplate } from "./constants";
import platform from "platform";
import { detectBrowser } from "./util/browser";
import { getMetaTagContentByName } from "./util/dom";
import { AppContext } from "../../state/app-context";
import { showApiFailureMessage } from "../../api/xmmAxios";

class FeedbackForm extends Component {
  static contextType = AppContext;
  constructor(props, context) {
    super(props, context);
    this.saveFeedback = this.saveFeedback.bind(this);
    this.onCancelForm = this.onCancelForm.bind(this);

    this.state = {
      alertMsg: "",
      feedBackType: "",
      feedbackComment: "",
      rating: "",
      contactMe: 0,
      rateSelected: 0
    };
  }
  componentDidMount() {}

  resetForm() {
    this.setState({
      alertMsg: "",
      feedBackType: "",
      feedbackComment: "",
      rating: "",
      contactMe: 0
    });
  }
  /* checkbox change handler */
  onChangeFollow = event => {
    const target = event.target;
    // const { name } = target;
    const newValue = target.checked ? 1 : 0;
    this.setState({ contactMe: newValue });
  };

  /* Text event */
  onChangeInput = cxEvent => {
    const { name, value } = cxEvent.target;
    const newValue = !value || value === "" ? "" : value;

    // Note: skip change event for initial form load
    if (this.state[name] === value) {
      return;
    }
    this.setState({ [name]: newValue });
  };
  // select handler
  onChangeType = event => {
    const { name, value } = event.target;
    const newValue = !value || value === "" ? "" : value;
    // Note: skip change event when form loaded
    if (this.state.feedBackType === value) {
      return;
    }
    this.setState({ [name]: newValue });
  };

  onChangeRating = event => {
    const { value } = event.target;
    const newValue = !value || value === "" ? "" : value;
    // Note: skip change event when form loaded
    if (this.state.rating === value) {
      return;
    }
    this.setState({ rating: newValue, rateSelected: value });
  };

  onCancelForm = () => {
    this.props.close();
  };
  saveFeedback = () => {
    // call rest api to save
    this.saveForm();
    // this.props.saveFeedbackState();
    // this.props.close();
  };
  getSavePayload() {
    const { webKey, user, dealer } = this.context;
    const { feedBackType, feedbackComment, rating, contactMe } = this.state;
    const dealerName = !dealer.dealerName ? "" : dealer.dealerName;
    const { screenName } = this.props;
    const payload = {
      company: "Xtime",
      dealerName,
      dealerCode: !dealer.dealerCode ? webKey.toUpperCase() : dealer.dealerCode,
      applicationName: "Service Quoting",
      applicationURL: window.location.href,
      // applicationVersion: getMetaTagContentByName("xtime:gitBranch").concat(
      //   " - ",
      //   getMetaTagContentByName("xtime:buildNumber")
      // ),
      applicationVersion: getMetaTagContentByName("xtime:buildNumber"),
      userName: user.userName,
      language: "en_US",
      browser: detectBrowser(),
      operationSystem: platform.os.toString(),
      userRole: user.trainingRole,
      userEmail: user.email,
      feedbackComponent: !screenName ? "Service Quoting" : screenName,
      creationDate: new Date(),
      feedbackComment,
      feedBackType,
      rating,
      contactMe
    };
    return payload;
  }

  saveForm() {
    const th = this;
    const payload = this.getSavePayload();
    console.log("save payload", payload);
    const { feedBackType, feedbackComment, rating } = this.state;
    if (!feedBackType && !feedbackComment && !rating) {
      this.setState({
        alertMsg: "Please enter in feedback fields"
      });
    } else {
      const postHeaders = {
        Accept: "application/json",
        "Content-Type": "application/json"
      };
      const restApi =
        "https://jfzxb341bi.execute-api.us-west-2.amazonaws.com/hack/feedbacks";
      axios
        .post(restApi, payload, { headers: postHeaders })
        .then(function () {
          th.resetForm();
          th.props.saveFeedbackState(payload);
          th.props.close();
        })
        .catch(error => {
          showApiFailureMessage("Unable to save feedback.", error);
        });
    }
  }
  render() {
    const clsHappy =
      this.state.rateSelected === "1"
        ? "happy-face selected happy"
        : "happy-face happy";
    const clsNeutral =
      this.state.rateSelected === "2"
        ? "happy-face selected neutral"
        : "happy-face neutral";
    const clsSad =
      this.state.rateSelected === "3"
        ? "happy-face selected sad"
        : "happy-face sad";
    const helpText =
      "We would like your feedback and suggestions to help improve our tools and make your job easier.";
    const { feedBackType, feedbackComment, rating, contactMe } = this.state;
    return (
      <div className="feed-form">
        <div className="feed-msg">{helpText}</div>
        <SelectInput
          htmlId="feedbackType"
          label="Feedback type"
          name="feedBackType"
          onChange={this.onChangeType}
          value={feedBackType}
          placeholder="Please select one"
          displayDeselectOption={false}
          required
          options={[
            {
              value: "Compliment",
              label: "Compliment"
            },
            {
              value: "Suggestion",
              label: "Suggestion"
            },
            {
              value: "General feedback",
              label: "General feedback"
            }
          ]}
        />

        <TextArea
          htmlId="comment"
          label="Comment"
          maxLength={500}
          name="feedbackComment"
          value={feedbackComment}
          onChange={this.onChangeInput}
          required
          layout="vertical"
        />
        <div className="feed-msg">
          {" "}
          Rate your experience or sentiment{" "}
          <span className="feed-red-label">*</span>
        </div>
        <span>
          <label className={clsHappy} htmlFor="happy-button">
            <input
              type="radio"
              id="happy-button"
              name="happy"
              className="smilies"
              value="1"
              checked={rating || 0}
              onChange={this.onChangeRating}
            />
          </label>
          <label className={clsNeutral} htmlFor="neutral-button">
            <input
              type="radio"
              id="neutral-button"
              name="neutral"
              className="smilies"
              value="2"
              checked={rating || 0}
              onChange={this.onChangeRating}
            />
          </label>
          <label className={clsSad} htmlFor="sad-button">
            <input
              type="radio"
              id="sad-button"
              name="sad"
              className="smilies"
              value="3"
              checked={rating || 0}
              onChange={this.onChangeRating}
            />
          </label>
        </span>
        <div className="xmm-checkbox-container" id="followup">
          <input
            className="form-checkbox"
            name="contactMe"
            id="followChk"
            type="checkbox"
            checked={contactMe || 0}
            onChange={this.onChangeFollow}
          />
          <span className="xmm-checkmark" />
          <span className="label-checkbox full-label">
            {" It's okay to follow up with me if needed"}
          </span>
        </div>
        <Alert htmlId="infoAlert" type="info">
          This is not a support form. If you are having an issue please use the
          customer support form.
        </Alert>
        <div className="feed-flex-bgroup">
          {/* <span className="hand-cursor" onClick={this.onCancelForm}>
            Cancel
          </span> */}
          <span>{this.state.alertMsg}</span>
          <Button
            htmlId="saveBtn"
            size="small"
            buttonStyle="primary"
            onClick={this.saveFeedback}
          >
            Submit
          </Button>
        </div>
      </div>
    );
  }
}

export default FeedbackForm;
