import { useEffect, useRef, useState } from "react";

function throttle(func, limit) {
  let flag = true;
  return function () {
    const context = this;
    const args = arguments;
    if (flag) {
      func.apply(context, args);
      flag = false;
      setTimeout(() => {
        flag = true;
      }, limit);
    }
  };
}

export default function useElementHeight(className) {
  const [height, setHeight] = useState(0);
  const elementRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      if (elementRef.current) {
        setHeight(elementRef.current.offsetHeight);
      }
    };

    const throttledHandleResize = throttle(handleResize, 200);
    throttledHandleResize();
    window.addEventListener("resize", throttledHandleResize);

    return () => {
      window.removeEventListener("resize", throttledHandleResize);
    };
  }, [className]);

  return { height, ref: elementRef };
}
