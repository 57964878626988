/**
 * Provides utility functions for parsing values.
 */
export default class parse {
  static #cleanCurrencyString(str) {
    return typeof str === "string"
      ? str.replace(/^(US|USD|US\$|\$)/, "").replace(/,/g, "")
      : str;
  }

  /**
   * Parses a currency value.
   * If the value is not parseable, returns the provided fallback value instead.
   * @param val Value to parse.
   * @param fallback Amount to return if the value is not parseable.
   * @returns {*|number}
   */
  static currency(val, fallback) {
    const valueType = typeof val;
    if (valueType === "string") {
      // Allow passing in formatted USD currency strings by removing prefixes & commas.
      const cleanVal = this.#cleanCurrencyString(val);
      const parsedVal = parseFloat(cleanVal);
      return isNaN(parsedVal)
        ? fallback
        : Math.round((parsedVal + Number.EPSILON) * 100) / 100;
    } else if (valueType === "number" && !isNaN(val)) {
      return Math.round((val + Number.EPSILON) * 100) / 100;
    } else {
      return fallback;
    }
  }

  /**
   * Parses an integer value.
   * @param {any} val Value to parse.
   * @param {number|*} fallback Value to return if the supplied value could not be parsed as an integer.
   * @returns {number|*} Parsed value, or the fallback value if it couldn't be parsed.
   */
  static int(val, fallback) {
    try {
      const parsedValue = parseInt(val, 10);
      return isNaN(parsedValue) ? fallback : parsedValue;
    } catch (err) {
      return fallback;
    }
  }

  /**
   * Parses a decimal value to the specified precision.
   * If the value is not parseable, returns the provided fallback value instead.
   * @param val Value to parse.
   * @param precision Number of digits to keep after the decimal. Defaults to 3 if not specified.
   * @param fallback Number to return if the value is not parseable.
   * @returns {*|number}
   */
  static decimal(val, precision, fallback) {
    const valueType = typeof val;
    const multiplier = Math.pow(10, precision ?? 3);
    if (valueType === "string") {
      // Allow passing in formatted USD currency strings by removing prefixes & commas.
      const cleanVal = this.#cleanCurrencyString(val);
      const parsedVal = parseFloat(cleanVal);
      return isNaN(parsedVal)
        ? fallback
        : Math.round((parsedVal + Number.EPSILON) * multiplier) / multiplier;
    } else if (valueType === "number" && !isNaN(val)) {
      return Math.round((val + Number.EPSILON) * multiplier) / multiplier;
    } else {
      return fallback;
    }
  }
}
