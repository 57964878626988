import React from "react";
import PropTypes from "prop-types";
import isEmpty from "lodash/isEmpty";
import Button from "@cx/ui/Button";
import { EditServiceProvider } from "./state/edit-service.context";
import { serviceTypes } from "./utils/edit-service.constants";
import { getWarningMessages } from "./utils/service.util";
import "./edit-service-module.scss";
import { appTypes } from "../constants/app.constants";
// poc-edit
import Details from "./containers/Details";
import { useEditServiceModule } from "./hooks";
/**
 * @param {object} currentEditingService
 * @param {object} service
 * @param {object} localeStrings
 * @param {string} username
 * @param {function} onCancelHandler
 * @param {function} onSaveHandler
 * @param {function} onSaveAnotherHandler
 * @param {function} onServiceChange
 * @returns {Element}
 */
const EditServiceComponent = props => {
  const {
    appContext,
    localeStrings,
    currentEditingService,
    stateService,
    serviceType,
    rawOperationDetails,
    settings,
    partsPricingAndInventory,
    payCodeProp,
    showNotesWarning,
    errorExists,
    isEngageRO,
    isSaveDisabled,
    serviceLineWidget,
    serviceCodeProp,
    getComponentStack,
    handleSave,
    handleCancel,
    errors,
    confirmationId
  } = useEditServiceModule(props);
  const { quoteSummary } = props;

  const shouldRenderDetailsComponent =
    !isEmpty(stateService) &&
    !isEmpty(serviceType) &&
    getComponentStack(serviceType);

  const shouldRenderDetails =
    !isEmpty(stateService) && !isEmpty(rawOperationDetails);

  const getTitle = () => {
    let title = "";
    if (serviceType == serviceTypes.MENU) {
      title =
        props.appType === "CSR"
          ? !isEmpty(quoteSummary)
            ? "Update"
            : "Add"
          : localeStrings["sq.search.common.save_button"];
    } else {
      title =
        props.appType === "CSR"
          ? !isEmpty(currentEditingService)
            ? "Update"
            : "Add"
          : localeStrings["sq.search.common.save_button"];
    }
    return title;
  };

  return (
    <>
      {shouldRenderDetailsComponent ? shouldRenderDetailsComponent : null}
      {shouldRenderDetails ? (
        <div>
          <Details
            sections={settings[serviceType]}
            partsPricingAndInventory={partsPricingAndInventory}
            onChangePaytype={props.onChangePaytype}
            onChangeServiceType={props.onChangeServiceType}
            onChangeServiceContract={props.onChangeServiceContract}
            wrapOnAccordion={
              // * disabled it, to avoid any logical error keeping the condition as-it-is
              // eslint-disable-next-line react/jsx-no-leaked-render
              serviceType === serviceTypes.GLOBALREPAIR &&
              !isEmpty(rawOperationDetails.laborApps)
            }
            rawOperationDetails={rawOperationDetails}
            axiosInstance={props.axiosInstance}
            dealerCode={props.dealerCode}
            defaultPayTypeOption={payCodeProp}
            defaultServiceTypeOption={serviceCodeProp}
            localeStrings={localeStrings}
            appContext={appContext}
          />
        </div>
      ) : null}

      <div
        className={`edit-service-flex-bgroup ${
          !isEngageRO && "sticky-footer p-a"
        }`}
      >
        {serviceLineWidget}
        {serviceType !== serviceTypes.MENU &&
        isEmpty(currentEditingService) &&
        props.appType !== appTypes.CSR ? (
          <Button
            htmlId="save-and-add-another-serviceBtn"
            buttonStyle="secondary"
            type="button"
            disabled={isSaveDisabled}
            onClick={() => handleSave(true)}
          >
            Save and add another
          </Button>
        ) : (
          // eslint-disable-next-line react/jsx-curly-brace-presence
          <div>{""}</div> // ! do not delete this div
        )}
        <div className="edit-service-save-cancel">
          <Button
            htmlId="cancelServiceBtn"
            buttonStyle="secondary"
            type="button"
            disabled={false}
            onClick={handleCancel}
          >
            {localeStrings["sq.search.common.cancel_button"]}
          </Button>
          <Button
            htmlId="edit-save-serviceBtn"
            buttonStyle="primary"
            type="button"
            disabled={isSaveDisabled}
            onClick={() => handleSave(false)}
          >
            {getTitle()}
          </Button>
        </div>
      </div>
      {showNotesWarning && errorExists ? (
        <span
          className={`missing-notes-message ${
            appContext.appType === appTypes.CSR ? "csr-msg-margin" : ""
          }`}
        >
          {localeStrings["sq.editservice.save_error"]}(
          {getWarningMessages(errors, {
            dealer: props.dealerCode,
            name:
              props?.service?.name || props?.currentEditingService?.serviceName,
            confirmationId
          })}
          )
        </span>
      ) : null}
    </>
  );
};
// poc-edit
export const EditServiceModule = props => {
  const widget = (
    <EditServiceProvider>
      <EditServiceComponent {...props} />
    </EditServiceProvider>
  );
  return widget;
};

EditServiceModule.defaultProps = {
  debugMode: false,
  appType: null,
  userPermissions: {},
  axiosInstance: null,
  rawOperationDetails: null,
  quoteSummary: null,
  // search flow - this prop will be null for all services, menu
  currentEditingService: null,
  // summary flow - this prop will be null for all services except for menu
  service: null,
  localeStrings: null,
  username: "",
  vehicle: null,
  payTypes: null,
  serviceTypes: null,
  vendorList: null,
  payTypeSubTypes: null,
  costAllocationTypes: null,
  partsPricingAndInventory: null,
  dealerCode: null,
  confirmationId: null,
  writeLogEntry: () => {},
  onChangePaytype: () => {},
  onChangeServiceType: () => {},
  onChangeServiceContract: () => {},
  onCancelHandler: () => {},
  onSaveHandler: () => {},
  onSaveAnotherHandler: () => {},
  onServiceChange: () => {}
};

EditServiceModule.propTypes = {
  debugMode: PropTypes.bool,
  appType: PropTypes.string, // @csr-logic
  userPermissions: PropTypes.object,
  // @prop axios instance passed to call rest api's inside partsLookup or EditService modules for dynamic data callbacks
  axiosInstance: PropTypes.object,
  rawOperationDetails: PropTypes.object,
  quoteSummary: PropTypes.object,
  currentEditingService: PropTypes.object,
  service: PropTypes.object,
  localeStrings: PropTypes.object,
  username: PropTypes.string,
  dealerCode: PropTypes.string,
  confirmationId: PropTypes.string,
  vehicle: PropTypes.object,
  payTypes: PropTypes.array,
  serviceTypes: PropTypes.array,
  vendorList: PropTypes.array,
  payTypeSubTypes: PropTypes.array,
  costAllocationTypes: PropTypes.array,
  partsPricingAndInventory: PropTypes.array,
  writeLogEntry: PropTypes.func,
  onChangePaytype: PropTypes.func,
  onChangeServiceType: PropTypes.func,
  onChangeServiceContract: PropTypes.func,
  onCancelHandler: PropTypes.func,
  onSaveHandler: PropTypes.func,
  onSaveAnotherHandler: PropTypes.func,
  onServiceChange: PropTypes.func
};
