// Purpose of this comp - to view dynamic state fields
import React from "react";
import { useEditServiceContext } from "../state/edit-service.context";
import isEmpty from "lodash/isEmpty";
import { priceValueFormatter } from "../utils/format.util";
import "./object-preview-style.scss";
export const ObjectPreview = () => {
  const { state } = useEditServiceContext();
  const { service } = state;
  const {
    labor,
    partsPrice,
    totalPrice,
    finalLaborPrice,
    finalPartsPrice,
    totalPriceOverride,
    totalLaborPriceOverride,
    totalPartsPriceOverride,
    calculatedLaborPrice,
    calculatedTotalPartsPrice,
    calculatedServicePrice,
    laborPriceOverridden,
    partsPriceOverridden,
    totalPriceOverridden
  } = service;
  const widget = !isEmpty(service) ? (
    <div className="cust-box-preview">
      <div className="cust-info-group">
        <div className="cust-part1">
          <div className="cust-title">Calculated</div>
          <div>
            <b>Labor</b> {priceValueFormatter(calculatedLaborPrice)}
          </div>
          <div>
            <b>Parts</b> {priceValueFormatter(calculatedTotalPartsPrice)}
          </div>
          <div>
            <b>Total</b> {priceValueFormatter(calculatedServicePrice)}
          </div>
        </div>

        <div className="cust-part2">
          <div className="cust-title">Overrides</div>
          <span>
            {Boolean(laborPriceOverridden).toString()} &nbsp;
            {priceValueFormatter(totalLaborPriceOverride)}
          </span>
          <span>
            {Boolean(partsPriceOverridden).toString()} &nbsp;
            {priceValueFormatter(totalPartsPriceOverride)}
          </span>
          <span>
            {Boolean(totalPriceOverridden).toString()} &nbsp;
            {priceValueFormatter(totalPriceOverride)}
          </span>
        </div>

        <div className="cust-part2">
          <div className="cust-title">Final</div>
          <span>
            <b>Labor</b> {priceValueFormatter(finalLaborPrice)}
          </span>
          <span>
            <b>Parts</b> {priceValueFormatter(finalPartsPrice)}
          </span>
          <span>
            <b>Total</b> {priceValueFormatter(totalPrice)}
          </span>
        </div>
        <div className="cust-part2">
          <div className="cust-title">Original(API)</div>
          <span>
            <b>Labor</b> {priceValueFormatter(labor?.price)}
          </span>
          <span>
            <b>Parts</b> {priceValueFormatter(partsPrice)}
          </span>
          <span>
            <b>Time</b> {labor?.time}
          </span>
        </div>
      </div>
    </div>
  ) : null;
  return widget;
};
