// This wrapper will use NewQuote.js conditional pages
import React, { useEffect, useContext } from "react";
import isEmpty from "lodash/isEmpty";
import IconArrowUpward from "@cx/ui/Icons/IconArrowUpward";

import { AppContext } from "../../../state/app-context";
// shared component used in - summary, customer search wrappers
// below pages part of customer search wrapper
import SearchCustomer from "../../customer/search-customer.component";
import CustomerForm from "../../customer/CustomerForm";
import VehicleResults from "../../vehicle/vehicle-results.container";
import VehicleFormClassWrapper from "../../vehicle/vehicle-form.component";
import {
  CUSTOMER_SEARCH,
  SEARCH_SERVICE,
  ADD_CUSTOMER,
  ADD_CUSTOMER_ADD_VEHICLE,
  EDIT_CUSTOMER,
  VEHICLE_RESULTS,
  ADD_VEHICLE,
  SKIP_CUSTOMER,
  EDIT_VEHICLE
} from "../../../constants/pages.constants";
import { useNewQuoteContext, Actions } from "../../../state/NewQuoteContext";

const CustomerSearchWrapper = () => {
  const appContext = useContext(AppContext);
  const { state, dispatch } = useNewQuoteContext();
  const { localeStrings, quote } = appContext;
  const { currentPage, /* showCustomerVehicleCard, */ quoteSummary } = state;
  const { confirmationId, quoteId } = quoteSummary;
  const hasQuote = confirmationId && quoteId;
  const { customerId, vehicleId, quoteId: qId } = quote;
  const isDeeplink =
    !isEmpty(qId) || !isEmpty(customerId) || !isEmpty(vehicleId);
  const pageMap = {
    // customer search wrapper
    [CUSTOMER_SEARCH]: <SearchCustomer />,
    [ADD_CUSTOMER]: <CustomerForm />,
    [ADD_CUSTOMER_ADD_VEHICLE]: <CustomerForm />,
    [EDIT_CUSTOMER]: <CustomerForm />,
    [ADD_VEHICLE]: <VehicleFormClassWrapper />,
    [EDIT_VEHICLE]: <VehicleFormClassWrapper />,
    [SKIP_CUSTOMER]: <VehicleFormClassWrapper />,
    [VEHICLE_RESULTS]: <VehicleResults />
  };
  const handleBackToSearchForCustomer = () => {
    dispatch({
      type: Actions.SET_CURRENT_PAGE,
      payload: CUSTOMER_SEARCH
    });
    dispatch({
      type: Actions.SET_CUSTOMER,
      payload: {}
    });
  };
  useEffect(() => {
    const pagesToHideCustomerVehicleCard = [CUSTOMER_SEARCH, SEARCH_SERVICE];
    const showCards = pagesToHideCustomerVehicleCard.includes(currentPage)
      ? false
      : true;
    dispatch({
      type: Actions.SET_SHOW_CUSTOMER_VEHICLE_CARDS,
      payload: showCards
    });
  }, [currentPage, dispatch]);

  return (
    <>
      {[VEHICLE_RESULTS].includes(currentPage) && !hasQuote && !isDeeplink ? (
        <span
          className="back-button-nq back-link-label"
          onClick={handleBackToSearchForCustomer}
        >
          <IconArrowUpward
            htmlId="backButtonQuote"
            isActive={false}
            className="back-arrow"
          />
          {localeStrings["sq.newquote.new_quote.back_to_search_for_customer"]}
        </span>
      ) : null}
      {pageMap[currentPage]}
    </>
  );
};

export default CustomerSearchWrapper;
