import React from "react";
import format from "../../../utils/format";
import PropTypes from "prop-types";

const TotalRow = ({ label, value, priceClassName }) => {
  return (
    <div className="total-row">
      <span>{label}</span>
      <span className={priceClassName}>{format.currency(value)}</span>
    </div>
  );
};

TotalRow.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.number,
  priceClassName: PropTypes.string
};

export default TotalRow;
