/* eslint-disable no-console */
import React from "react";
import PropTypes from "prop-types";
import OperationRow from "./OperationRow";
import { isEmpty } from "../utils/helper";

class DealerOperationsTable extends React.Component {
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.result !== prevState.result) {
      return prevState.getNewStateInfo(nextProps);
    }
    return null;
  }
  constructor(props) {
    super(props);
    this.getNewStateInfo = this.getNewStateInfo.bind(this);
    const { result, services, filteredData, isLoaded, statusMsg } =
      this.getNewStateInfo(props);

    this.state = {
      result,
      services,
      filteredData,
      isLoaded,
      statusMsg,
      getNewStateInfo: this.getNewStateInfo
    };
    console.log("Dealer level service", result);
  }
  componentDidMount() {
    // Do nothing
  }

  getNewStateInfo(props) {
    this.serviceRows = [];
    let isLoaded = false;
    let statusMsg = null;
    const result = isEmpty(props.result) ? null : props.result;
    let services = [];
    if (result && result.hasOwnProperty("services")) {
      services = result.services;
      isLoaded = true;
    } else if (result && result.hasOwnProperty("message")) {
      statusMsg = result.message;
      isLoaded = false;
    }

    return {
      result,
      services,
      filteredData: services,
      isLoaded,
      statusMsg
    };
  }
  render() {
    let resultTable = null;
    let externalFilters = null;
    const { filteredData, isLoaded, statusMsg } = this.state;
    if (this.state.isLoaded) {
      if (filteredData && filteredData.length !== 0) {
        const serviceList = filteredData.map((s, index) => {
          return (
            <div key={"service-" + s.id}>
              <OperationRow
                ref={ref => (this.serviceRows[index + 1] = ref)}
                service={s}
              />
            </div>
          );
        });
        resultTable = <div className="ops-table">{serviceList}</div>;
      } else {
        resultTable = (
          <div className="ops-center-content"> No records matched </div>
        );
      }
    } else if (!isLoaded && statusMsg) {
      externalFilters = (
        <div className="ops-center-content">
          <b> {statusMsg}</b>
        </div>
      );
    }
    return (
      <React.Fragment>
        {externalFilters}
        {resultTable}
      </React.Fragment>
    );
  }
}

export default DealerOperationsTable;

DealerOperationsTable.propTypes = {
  selectedIndex: PropTypes.number,
  result: PropTypes.object
};
