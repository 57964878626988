import moment from "moment";

/**
 * Formats a number like in thousand or million (K or M).
 * If the value is >1000 it will be taken as 1K.
 * If the value is >1000000 it will be taken as 1M.
 * If the value is < 0, it will be taken as is.
 * @param number Value to format.
 * @returns {string} Formatted value.
 */
// Add formatters
const formatNumberWithLetter = number => {
  let formattedNumber = `${number}`;

  if (number >= 1000000) {
    formattedNumber = `${Number((number / 1000000).toFixed(2))}M`;
  } else if (number >= 1000) {
    formattedNumber = `${Number((number / 1000).toFixed(2))}K`;
  }

  return formattedNumber;
};

/**
 * Formats price like prepends with '$' and appends with 2 decimal digits.
 * @param price Value to format.
 * @returns {string} Formatted value.
 */
const formatPrice = price => {
  const priceVal = price === undefined ? 0 : price;
  const formattedPrice = Number(priceVal).toFixed(2);
  return `$${formattedPrice}`;
};

// Advance price formatter
// input value can be 654321, null, 0, "", "65.34"
function priceValueFormatter(amount) {
  const options = { style: "currency", currency: "USD" };
  const priceFormat = new Intl.NumberFormat("en-US", options).format(
    amount || 0
  );
  return priceFormat;
}

/**
 *
 * @param {number} number
 * @returns {string}
 */
function formatNumberWithThousands(number) {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

/**
 * Util return patterns for Date & Time based on country locale
 * @param {string} locale
 * @return {string}
 */
function getDatePattern(locale) {
  let pattern = "DD-MM-YYYY";
  if (locale === "en_US") {
    pattern = "MM-DD-YYYY";
  } else if (locale === "en_US_short") {
    pattern = "M-D-YY";
  } else if (locale === "en_CA" || locale === "fr_CA") {
    pattern = "YYYY/MM/DD";
  } else if (locale === "en_CA_short" || locale === "fr_CA_short") {
    pattern = "YY-M-D";
  } else if (locale === "en_AU") {
    pattern = "DD/MM/YYYY";
  } else if (locale === "en_AU_short") {
    pattern = "D-M-YY";
  } else if (locale === "quotes") {
    pattern = "YYYY-MM-DDTHH:mm:ssZZ";
  } else if (locale === "vehicle") {
    pattern = "YYYY-MM-DD";
  }
  return pattern;
}

/**
 *
 * @param {number} rawDate
 * @param {string} locale
 * @param {string} [version]
 * @return {string}
 */
const formatDate = (rawDate, locale, version) => {
  let pattern;
  if (version) {
    pattern = getDatePattern(locale + "_" + version);
  } else {
    pattern = getDatePattern(locale);
  }
  const formattedDate = moment(rawDate).format(pattern).replace(/-/g, "/");
  return formattedDate;
};

/**
 * Is Numeric Implementation
 * Reference: https://api.jquery.com/jquery.isnumeric/
 *
 * @param {string|number} n
 * @returns boolean
 */
function isNumeric(n) {
  return !isNaN(parseFloat(n)) && isFinite(n);
}

/**
 *
 * @param {string} str
 * @param {number} limit
 * @returns string
 */
function limitStringCharacters(str = "", limit = 90) {
  if (str.length > limit) {
    return str.slice(0, limit);
  }

  return str;
}

/**
 *
 * @param {*} errorInfo
 * @returns
 */
function formatErrorLocation(errorInfo) {
  const componentStackLines = errorInfo?.stack.split("\n");
  const componentStackFirstLine = componentStackLines && componentStackLines[1];
  // Extract the component name and location from the component stack string
  const componentParts = componentStackFirstLine?.match(/at\s+(\S+)/);
  const location = (componentParts && componentParts[1]) || null;

  return location;
}

//* for formatting date into particular format
const getOffsetIsoString = date => {
  const offsetString = moment(date, "YYYY-MM-DD").format(
    "YYYY-MM-DDTHH:mm:ssZ"
  );
  return offsetString?.slice(0, -3) + offsetString?.slice(-2);
};

/**
 * Formats a Date object as "MM/DD/YYYY".
 *
 * @param {Date} date - The date to format.
 * @returns {string|null} - The formatted date string.
 */
function formatDateString(date) {
  if (!date?.getMonth) {
    return null;
  }
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const year = date.getFullYear();
  return `${month}/${day}/${year}`;
}

/**
 * Adds a specified number of months to a given date.
 *
 * @param {Date|string} date - The starting date. Can be a Date object or a date string that can be parsed by the Date constructor.
 * @param {number} months - The number of months to add to the date.
 * @returns {string} - The formatted date string.
 */
function addMonthsToDate(date, months = 0) {
  const result = new Date(date);
  result.setMonth(result.getMonth() + months);
  return formatDateString(result);
}

/**
 * Calculates the number of months between two dates.
 *
 * @param {string} startingDate - The start date in ISO 8601 format (e.g., "2024-07-01T00:00:00-05:00").
 * @param {string} expirationDate - The end date in ISO 8601 format (e.g., "2027-07-01T00:00:00-05:00").
 * @returns {number} The number of months between the two dates.
 */
function calculateMonthsBetweenDates(startingDate, expirationDate) {
  // Convert the string dates to Date objects
  const start = new Date(startingDate);
  const end = new Date(expirationDate);

  // Calculate the difference in months
  const numberOfMonths =
    (end.getFullYear() - start.getFullYear()) * 12 +
    (end.getMonth() - start.getMonth());

  return numberOfMonths;
}

export {
  isNumeric,
  formatNumberWithLetter,
  formatPrice,
  priceValueFormatter,
  formatNumberWithThousands,
  formatDate,
  limitStringCharacters,
  formatErrorLocation,
  getOffsetIsoString,
  formatDateString,
  addMonthsToDate,
  getDatePattern,
  calculateMonthsBetweenDates
};
