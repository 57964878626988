/* eslint-disable no-console */
import React from "react";
import PropTypes from "prop-types";
import LoadingIndicator from "@cx/ui/LoadingIndicator";
const BodyMask = ({ loadingText, size }) => {
  return (
    <div className="xmm-loading-mask">
      <div className="xmm-loading-label">{loadingText}</div>
      <LoadingIndicator htmlId="quoteBodyMasking" size={size} />
    </div>
  );
};

export default BodyMask;

BodyMask.propTypes = {
  loadingText: PropTypes.string,
  size: PropTypes.string
};
BodyMask.defaultProps = {
  loadingText: "",
  size: "large"
};
