import isNull from "lodash/isNull";
import isArray from "lodash/isArray";

import { DISCOUNT_TYPES } from "../catalog-discount.constants";

export const getDiscountLabelValue = (currentDiscount, setDiscountLabel) => {
  if (currentDiscount.discountType === DISCOUNT_TYPES.PERCENT)
    setDiscountLabel(`Percentage discount `);
  if (currentDiscount.discountType === DISCOUNT_TYPES.DOLLAR)
    setDiscountLabel(`Dollar discount `);
  if (currentDiscount.discountType === DISCOUNT_TYPES.ARV)
    setDiscountLabel(`Retail value discount `);
  if (currentDiscount.discountType === DISCOUNT_TYPES.VARIABLE)
    setDiscountLabel(`Variable discount `);
};

export const sumOfAlreadyAppliedLaborPartDiscounts = data => {
  return data?.reduce(
    (acc, { appliedLaborDiscount, appliedPartsDiscount }) => ({
      appliedLaborDiscount: acc.appliedLaborDiscount + appliedLaborDiscount,
      appliedPartsDiscount: acc.appliedPartsDiscount + appliedPartsDiscount
    }),
    { appliedLaborDiscount: 0, appliedPartsDiscount: 0 }
  );
};

export const sumOfAllLineAppliedLaborPartsDiscounts = (
  quoteServices,
  selectedPayerType
) => {
  return quoteServices
    .filter(c => c?.payType === selectedPayerType)
    .flatMap(d => (isNull(d?.catalogDiscounts) ? [] : d?.catalogDiscounts))
    .reduce(
      (acc, { appliedLaborDiscount, appliedPartsDiscount }) => ({
        appliedLaborDiscount: acc.appliedLaborDiscount + appliedLaborDiscount,
        appliedPartsDiscount: acc.appliedPartsDiscount + appliedPartsDiscount
      }),
      { appliedLaborDiscount: 0, appliedPartsDiscount: 0 }
    );
};

export const getAdjustedPriceLineLevelDiscount = (
  adjustedPrice,
  isSameDiscount,
  service,
  actualLaborDiscount,
  adjustedLaborDiscount,
  actualPartDiscount,
  adjustedPartsDiscount,
  selectedDiscountModify
) => {
  // eslint-disable-next-line
  adjustedPrice = isSameDiscount
    ? Math.max(
        (service?.lineServicePrice ?? service?.servicePrice ?? 0) -
          (Math.min(
            Math.min(actualLaborDiscount, adjustedLaborDiscount),
            service?.finalLaborPrice
          ) +
            Math.min(
              Math.min(actualPartDiscount, adjustedPartsDiscount),
              service?.finalPartsPrice
            )),
        0
      )
    : Math.max(
        (service?.lineServicePrice ?? service?.servicePrice ?? 0) -
          (Math.min(
            Math.min(actualLaborDiscount, adjustedLaborDiscount),
            service?.finalLaborPrice
          ) +
            Math.min(
              Math.min(actualPartDiscount, adjustedPartsDiscount),
              service?.finalPartsPrice
            )) +
          (selectedDiscountModify?.appliedDiscount ?? 0),
        0
      );

  return adjustedPrice;
};

export const getAdjustedPriceROLevelDiscount = (
  adjustedPrice,
  isSameDiscount,
  subTotalBeforeTaxes,
  actualLaborDiscount,
  adjustedLaborDiscount,
  subTotalLineLaborBasePrice,
  actualPartDiscount,
  adjustedPartsDiscount,
  subTotalLinePartsBasePrice,
  selectedDiscountModify
) => {
  // eslint-disable-next-line
  adjustedPrice = isSameDiscount
    ? subTotalBeforeTaxes -
      (Math.min(
        Math.min(actualLaborDiscount, adjustedLaborDiscount),
        subTotalLineLaborBasePrice
      ) +
        Math.min(
          Math.min(actualPartDiscount, adjustedPartsDiscount),
          subTotalLinePartsBasePrice
        ))
    : subTotalBeforeTaxes -
      (Math.min(
        Math.min(actualLaborDiscount, adjustedLaborDiscount),
        subTotalLineLaborBasePrice
      ) +
        Math.min(
          Math.min(actualPartDiscount, adjustedPartsDiscount),
          subTotalLinePartsBasePrice
        )) +
      (selectedDiscountModify?.appliedDiscount ?? 0);
  return adjustedPrice;
};

export const getTotalServicePrice = service => {
  const totalPrice =
    (service?.finalLaborPrice ?? 0) + (service?.finalPartsPrice ?? 0);
  return totalPrice;
};

export const getTotalServicePriceForRO = (
  subTotalLineLaborBasePrice,
  subTotalLinePartsBasePrice
) => subTotalLineLaborBasePrice + subTotalLinePartsBasePrice;

export const hasNonCombiningDiscount = services => {
  return services?.some(serviceOrPayer =>
    serviceOrPayer?.catalogDiscounts?.some(
      discount => discount?.applyWithOtherDiscount === 0
    )
  );
};

export const didROHasAnyROLevelDiscount = quoteSummary => {
  return quoteSummary?.payers?.some(
    serviceOrPayer => serviceOrPayer?.catalogDiscounts?.length > 0
  );
};

export const didROHasServicesWithDiscount = quoteSummary => {
  return quoteSummary?.quoteServices?.some(
    serviceOrPayer => serviceOrPayer?.catalogDiscounts?.length > 0
  );
};

export const getPayer = (currentDiscount, quoteSummary) => {
  const payersInQuote = quoteSummary?.payers;
  const payTypes = isArray(currentDiscount?.payTypes)
    ? currentDiscount?.payTypes
    : currentDiscount?.payTypes?.split(",");

  const isPayTypePresent = payType =>
    payersInQuote?.some(payer => payer?.payType === payType) &&
    payTypes?.some(pType => pType === payType);

  const types = ["C", "W", "I"];
  const payerPayType = types.find(type => isPayTypePresent(type)) || null;

  return payersInQuote?.find(payer => payer?.payType === payerPayType);
};
