import React from "react";
import { Tooltip } from "react-tippy";
import "react-tippy/dist/tippy.css";
import "./rich-tooltip.scss";
import PropTypes from "prop-types";

/*
Reference:
* https://github.com/atomiks/tippyjs-react#readme
* https://atomiks.github.io/tippyjs/v6/all-props/
*/

const RichTooltip = props => {
  const tooltipContainer = (
    <div className="tooltip-container">{props.tooltipContent}</div>
  );
  return (
    <Tooltip
      theme="light"
      duration={props.duration}
      placement={props.placement}
      html={tooltipContainer}
      trigger={props.trigger}
      interactive={props.interactive}
    >
      {props.elementContent}
    </Tooltip>
  );
};

RichTooltip.propTypes = {
  elementContent: PropTypes.object.isRequired,
  tooltipContent: PropTypes.object.isRequired,
  duration: PropTypes.arrayOf(PropTypes.number),
  placement: PropTypes.string,
  interactive: PropTypes.bool
};

RichTooltip.defaultProps = {
  duration: [0, 0],
  placement: "bottom",
  interactive: false
};

export default RichTooltip;
