import React from "react";

import "./charge-account-details.scss";
import Popover from "@cx/ui/Popover";
import Button from "@cx/ui/Button";
import format from "../../utils/format";
import { GOOD, ON_HOLD } from "../../constants/csr.constants";

const ChargeAccountDetails = ({ chargeAccountInfo }) => {
  const popOverContent = (
    <div>
      <b>Available credit : </b>
      <span
        className={
          chargeAccountInfo?.availableCredit?.amount < 0
            ? "class-balance-red"
            : ""
        }
      >
        {format.currency(chargeAccountInfo?.availableCredit?.amount)}
      </span>
    </div>
  );
  return (
    <div className="charge-account-details-container">
      <Popover
        htmlId="popoverFocus"
        popoverContent={popOverContent}
        trigger={["hover"]}
      >
        <Button
          className={`charge-account-badge-button ${
            chargeAccountInfo?.isInGoodStanding ? "class-green" : "class-red"
          } `}
          buttonStyle="secondary"
          htmlId="btnLink"
        >
          Charge account: {chargeAccountInfo?.isInGoodStanding ? GOOD : ON_HOLD}
        </Button>
      </Popover>
    </div>
  );
};
export default ChargeAccountDetails;
