// Listout all events { btn clicks, links etc } under this single Object to access within Application
export const gtmEventData = Object.freeze({
  "ga.dashboard.create_quote_click": {
    eventName: "Create New Quote Click",
    eventProperties: {
      location: "dashboard-pastquotes"
    }
  },
  "ga.dashboard.edit_quote_click": {
    eventName: "Edit Quote Click",
    eventProperties: {
      location: "dashboard-pastquotes"
    }
  },
  "ga.dashboard.copy_quote_click": {
    eventName: "Copy Quote Click",
    eventProperties: {
      location: "dashboard-pastquotes"
    }
  },
  "ga.dashboard.delete_quote_click": {
    eventName: "Delete Quote Click",
    eventProperties: {
      location: "dashboard-pastquotes"
    }
  },
  "ga.dashboard.refresh_quotes": {
    eventName: "Refresh Quotes Click",
    eventProperties: {
      location: "dashboard-pastquotes"
    }
  },
  "ga.dashboard.toggle_statistics": {
    eventName: "Toggle Quote Statistics",
    eventProperties: {
      location: "dashboard-pastquotes"
    }
  },
  "ga.dashboard.grid_cellclick_quote_click": {
    eventName: "Select Quote from Grid row",
    eventProperties: {
      result: "Select Quote from Grid row",
      location: "dashboard-pastquotes"
    }
  },
  "ga.dashboard.select_day_filter": {
    eventName: "Select Timeframe Filter",
    eventProperties: {
      location: "dashboard-pastquotes"
    }
  },
  "ga.dashboard.select_quote_status_filter": {
    eventName: "Select Quote Status Filter",
    eventProperties: {
      location: "dashboard-pastquotes"
    }
  },
  "ga.dashboard.change_dealership_click": {
    eventName: "Change Dealership Click",
    eventProperties: {
      location: "dealer-selector"
    }
  },
  "ga.newquote.select_customer_click": {
    eventName: "Select Customer Click",
    eventProperties: {
      location: "newquote"
    }
  },
  "ga.newquote.select_customer_vehicle_click": {
    eventName: "Select Customer Vehicle Click",
    eventProperties: {
      location: "newquote"
    }
  },
  "ga.newquote.new_customer_click": {
    eventName: "New Customer Click",
    eventProperties: {
      location: "newquote"
    }
  },
  "ga.newquote.skip_customer_click": {
    eventName: "Skip Customer Click",
    eventProperties: {
      location: "newquote"
    }
  },
  "ga.newquote.select_service_click": {
    eventName: "Select Service Click",
    eventProperties: {
      location: "search-service-module"
    }
  },
  "ga.newquote.edit_service_pay_type_click": {
    eventName: "Select Service Pay Type Click",
    eventProperties: {
      location: "edit-service"
    }
  },
  "ga.newquote.reset_quote_click": {
    eventName: "Reset Quote Click",
    eventProperties: {
      location: "newquote-summarypage"
    }
  },
  "ga.newquote.modify_parts_click": {
    eventName: "Modify Parts Click",
    eventProperties: {
      location: "newquote-summarypage"
    }
  },
  "ga.newquote.save_service_click": {
    eventName: "Save Service Click",
    eventProperties: {
      location: "edit-service"
    }
  },
  "ga.newquote.save_another_service_click": {
    eventName: "Save Another Service Click",
    eventProperties: {
      location: "edit-service"
    }
  },
  "ga.newquote.add_individual_part_click": {
    eventName: "Add Individual Part Click",
    eventProperties: {
      location: "parts-lookup"
    }
  },
  "ga.dashboard.add_recommended_parts_click": {
    eventName: "Add Recommended Parts Click",
    eventProperties: {
      location: "parts-lookup"
    }
  },
  "ga.newquote.remove_service_click": {
    eventName: "Remove Service Click",
    eventProperties: {
      location: "newquote-summarypage"
    }
  },
  "ga.newquote.save_quote_click": {
    eventName: "Save Quote Click",
    eventProperties: {
      location: "newquote-confirmation"
    }
  },
  "ga.newquote.send_quote_to_customer_click": {
    eventName: "Send Quote To Customer Click",
    eventProperties: {
      location: "newquote-confirmation"
    }
  },
  "ga.newquote.schedule_service_appointment_click": {
    eventName: "Schedule Service Appointment Click",
    eventProperties: {
      location: "newquote-confirmation"
    }
  },
  "ga.newquote.view_pdf_click": {
    eventName: "View PDF Click",
    eventProperties: {
      location: "newquote-confirmation"
    }
  },
  "ga.dashboard.select_new_timeframe_click": {
    eventName: "Select New Timeframe Click",
    eventProperties: {
      location: "dashboard-pastquotes"
    }
  },
  "ga.dashboard.select_quote_status_click": {
    eventName: "Select Quote Status Click",
    eventProperties: {
      location: "dashboard-pastquotes"
    }
  },
  "ga.dashboard.customer_notes_click": {
    eventName: "Customer Notes Click",
    eventProperties: {
      location: "edit-service"
    }
  },
  "ga.dashboard.dealership_notes_click": {
    eventName: "Dealership Notes Click",
    eventProperties: {
      location: "edit-service"
    }
  },
  "ga.dashboard.request_and_complete_service_assistance_click": {
    eventName: "Request & Complete Service Assistance Click",
    eventProperties: {
      location: "dashboard-pastquotes"
    }
  },
  "ga.dashboard.request_and_complete_parts_assistance_click": {
    eventName: "Request & Complete Parts Assistance Click",
    eventProperties: {
      location: "dashboard-pastquotes"
    }
  }
});
