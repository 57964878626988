import React from "react";
import PropTypes from "prop-types";
import Tooltip from "@cx/ui/Tooltip";
import { getCustomerName } from "../archived-repair-orders-grid.util";

const CustomerColumn = ({ customer }) => {
  const { emails, legacyDMSId } = customer;

  const displayName = getCustomerName(customer);
  const email = emails?.[0].email ?? "- -";

  const contentHTML = (
    <div className="sq-tooltip-card">
      <div className="sq-tooltip-card__header">{displayName}</div>
      <ul className="tip-row">
        <li>Email: {email}</li>
      </ul>
    </div>
  );
  const renderHTML = (
    <span className="sq-flex-container">
      <Tooltip
        htmlId={`id_${legacyDMSId}`}
        tooltipContent={contentHTML}
        position="left"
        className="sq-tooltip-list"
      >
        {displayName}
      </Tooltip>
    </span>
  );

  return renderHTML;
};

CustomerColumn.propTypes = {
  customer: PropTypes.shape({
    name: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        lastName: PropTypes.string,
        firstName: PropTypes.string
      })
    ]),
    emails: PropTypes.arrayOf(
      PropTypes.shape({
        email: PropTypes.string
      })
    ),
    legacyDMSId: PropTypes.string
  })
};

export default CustomerColumn;
