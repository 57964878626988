import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import "./change-advisor.scss";
import SelectInput from "@cx/ui/SelectInput";
import { AppContext } from "../../../../state/app-context";
const Advisor = props => {
  const [advisorList] = useState(props?.quote?.advisors || null);
  const {
    quote: {
      quoteSummary: { advisor: assignedAdvisor }
    },
    required
  } = props;

  const appContext = useContext(AppContext);
  const { user } = appContext;

  const [advisorUserName, setAdvisorUserName] = useState(
    advisorList?.some(a => a.id == assignedAdvisor?.extUserId)
      ? assignedAdvisor?.extUsername || ""
      : ""
  );

  useEffect(() => {
    if (props?.quote?.quoteSummary?.quoteStatus === "WITH_ADVISOR") {
      const isLoggedInUserAdvisor = advisorList?.some(
        advisor => advisor?.id === user?.userId + ""
      );
      if (isLoggedInUserAdvisor) {
        setAdvisorUserName(user?.userName);
        props.onUpdate({
          serviceWriter: user?.userId
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.quoteSummary]);

  const advisorListOptions = persons => {
    const advisorsList = persons?.map(p => {
      return { label: `${p.firstName} ${p.lastName}`, value: p.userName };
    });
    advisorsList?.sort((a, b) => a.label.localeCompare(b.label));
    return advisorsList;
  };

  const onInputChange = userName => {
    if (userName) {
      const selectedAdvisor = advisorList.find(x => x.userName === userName);
      setAdvisorUserName(selectedAdvisor.userName);
      props.onUpdate({
        serviceWriter: selectedAdvisor.id
      });
    } else {
      setAdvisorUserName("");
      props.onUpdate({
        serviceWriter: null
      });
    }
  };

  const handleEnterPress = event => {
    if (event.key === "Enter") {
      // Call the callback function passed from parent on press of enter
      props.onEnterKeyPressOnUpdate();
    }
  };
  return (
    <div className="change-advisor-container">
      <SelectInput
        htmlId="advisorSelectInput"
        name="advisorSelectInput"
        label="Advisor"
        onChange={cxEvent => {
          onInputChange(cxEvent.target.value);
        }}
        options={advisorListOptions(advisorList)}
        value={advisorUserName}
        placeholder="Select advisor"
        onKeyPress={handleEnterPress}
        required={required}
        displayDeselectOption={false}
      />
    </div>
  );
};

export default Advisor;

Advisor.propTypes = {
  quote: PropTypes.object,
  onUpdate: PropTypes.func,
  onEnterKeyPressOnUpdate: PropTypes.func
};

Advisor.defaultProps = {
  quote: {},
  onUpdate: () => {},
  onEnterKeyPressOnUpdate: () => {}
};
