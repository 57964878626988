import React, { useContext } from "react";
import PastQuotesGrid from "./components/past-quotes-grid.component";
import CardView from "./components/card.component";
import { AppContext } from "../../state/app-context";
import { appTypes } from "../../constants/app.constants";
import { YES } from "../repair-order/constants/adjustment.constant";
import "./past-quotes.scss";
import RepairOrdersTabs from "./components/repair-orders-tabs/repair-orders-tabs.component";

const LayoutPage = () => {
  const { windowSize, dealerProperties, appType, userPermissions } =
    useContext(AppContext);

  return (
    <div className="past-quotes-flex-container">
      {windowSize.width <= 350 ? (
        <CardView />
      ) : appType === appTypes.CSR &&
        dealerProperties?.ENABLE_CSR_SEARCH_HISTORIC_RO === YES &&
        userPermissions.canSearchArchivedROs ? (
        <RepairOrdersTabs />
      ) : (
        <PastQuotesGrid />
      )}
    </div>
  );
};

export default LayoutPage;
