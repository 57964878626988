/* eslint-disable */

const isDev = () => {
  return process.env.NODE_ENV === "development";
};

const isDev6 = () => {
  return window.location.host.includes("dev6") || isDev();
};

const isQa6 = () => {
  return window.location.host.includes("qa6") || isDev();
};

const isStage4 = () => {
  return window.location.host.includes("stg4") || isDev();
};

const isTesting = () => {
  return process.env.NODE_ENV === "test";
};

const isProduction = () => {
  return process.env.NODE_ENV === "production";
};
export { isDev, isDev6, isQa6, isTesting, isProduction, isStage4 };
