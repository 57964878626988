import React, { Component } from "react";
import { bool, PropTypes } from "prop-types";
import SlidingDrawer from "@cx/ui/SlidingDrawer";
import IconMaximize from "@cx/ui/Icons/IconMaximize";
import IconMinimize from "@cx/ui/Icons/IconMinimize";
import IconClose from "@cx/ui/Icons/IconClose";
import clsx from "clsx";
/**
 * @class GenericSlider
 * This Component used for render content within slider in any pages
 */
export default class GenericSlider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      panelWidth: props.slideWidth,
      title: props.title,
      isExpand: props.isExpand
      // isScrolling: false
    };
    // this.scrollingDetection = this.scrollingDetection.bind(this);
    // this.scrollTopAction = this.scrollTopAction.bind(this);
  }
  /**
   * Add event listener
   */
  componentDidMount() {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions.bind(this));
  }

  /**
   * Remove event listener
   */
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions.bind(this));
  }

  // scrollingDetection = event => {
  //   const lastScrollTop = 0;
  //   const currentScroll = event.target.scrollTop;
  //   if (currentScroll > lastScrollTop) {
  //     this.setState({ isScrolling: true });
  //   } else {
  //     this.setState({ isScrolling: false });
  //   }
  //   this.lastScrollTop = currentScroll <= 0 ? 0 : currentScroll;
  // };

  // @note: scrollTop event works when placeholder <div> element within slider container
  // @note - scrolltop event feature disabled
  // scrollTopAction = event => {
  //   const topElement = document.querySelector(".slider-content");
  //   topElement.scrollTop = 0;
  //   this.setState({ isScrolling: false });
  // };
  /**
   * This Calculate & Update state of new dimensions
   */
  updateDimensions() {
    const fixedWidth = 500; // in px
    const { sliderWidth, flexWidth } = this.props;
    if (flexWidth) {
      this.setState({ panelWidth: sliderWidth });
    } else {
      this.setState({ panelWidth: fixedWidth });
    }
  }
  onIconClick = () => {
    this.setState(
      prevState => {
        return { isExpand: !prevState.isExpand };
      },
      () => {
        const { isExpand } = this.state;
        window.dispatchEvent(
          new CustomEvent("expandSlider", {
            detail: { isExpand },
            bubbles: true,
            cancelable: true
          })
        );
      }
    );
  };
  // @workaround - Empty arrow function return 'undefined' used to stop closing slider upon ESC, when clicking outside the drawer
  onEmptyFn = () => {};
  render() {
    const { closeButton, flexWidth, showSlide, title } = this.props;
    // const isScrollButton = this.state.isScrolling;
    const clsBox = flexWidth ? "xmm-flex-slider" : "";
    const clsSlider = clsx(
      clsBox,
      this.state.isExpand && "xmm-expanded-slider"
    );
    return (
      <div id="sq-slider-container">
        <span id="sq-slider-container-top" />
        <SlidingDrawer
          htmlId="GenericSlider"
          show={showSlide}
          panelWidth={this.state.panelWidth}
          onHide={this.onEmptyFn}
          closeButton={closeButton}
          position="right"
          className={clsSlider}
        >
          <h3 className="slider-title-fixed">{title}</h3>
          <div
            className={
              this.props.expandSlider ? "xmm-slider-expand-button" : "hide"
            }
          >
            <IconMaximize
              htmlId="IconMaxExample"
              onClick={this.onIconClick}
              hidden={this.state.isExpand}
            />
            <IconMinimize
              htmlId="IconMinExample"
              onClick={this.onIconClick}
              hidden={!this.state.isExpand}
            />
            <IconClose
              htmlId="IconCloseExample"
              onClick={this.props.toggleSlider}
            />
          </div>

          {this.props.children}

          <footer className="scroll-footer">
            {/* {isScrollButton && (
              <button
                className={hideElement}
                // onClick={this.scrollTopAction}
              >
                <i className="fa fa-arrow-up" aria-hidden="true" />
              </button>
            )} */}
          </footer>
        </SlidingDrawer>
      </div>
    );
  }
}

GenericSlider.defaultProps = {
  expandSlider: false,
  flexWidth: true,
  showSlide: false,
  sliderWidth: "85vw",
  isExpand: false,
  toggleSlider: () => {},
  closeButton: false
};
GenericSlider.propTypes = {
  children: PropTypes.object,
  expandSlider: bool,
  flexWidth: bool,
  showSlide: bool,
  sliderWidth: PropTypes.string,
  title: PropTypes.object,
  closeButton: bool,
  isExpand: bool,
  toggleSlider: PropTypes.func
};
