/* eslint-disable react/prop-types */
import React from "react";
// import IconKeyboardArrowRight from "@cx/ui/Icons/IconKeyboardArrowRight";
const QuoteDetails = props => {
  const { quote } = props;
  return (
    <div className="past-box">
      <div className="past-info">
        <b>{quote.confirmationId}</b>
        <div>{quote.createdBy}</div>
        <div>{quote.quoteStatus}</div>
      </div>

      <span className="past-address">
        {quote.customerId} <br />
        {quote.creationDateTime} <br />
        {quote.totalPrice}
      </span>

      <div className="past-action">
        {/* <IconKeyboardArrowRight htmlId="IconNoteExample" /> */}
      </div>
    </div>
  );
};

export default QuoteDetails;
