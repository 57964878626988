/* This Component reused for loading and No rows found cases.
 */
import React from "react";
import LoadingIndicator from "@cx/ui/LoadingIndicator";
import PropTypes from "prop-types";

const CustomLoadingOverlay = props => {
  const { isLoading } = props;
  let content = null;
  // case1: Loading...
  if (isLoading) {
    content = (
      <div>
        <LoadingIndicator htmlId="maskSpinner" size="large" color="gray" />
      </div>
    );
  }
  return content;
};

export default CustomLoadingOverlay;

CustomLoadingOverlay.defaultProps = {
  isLoading: false
};
CustomLoadingOverlay.propTypes = {
  isLoading: PropTypes.bool
};
