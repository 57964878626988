// EXTERNAL LIBRARIES
import React from "react";
import AccordionGroup from "@cx/ui/AccordionGroup";
import PropTypes from "prop-types";
// COMPONENTS
import RoLineHeading from "./service-history-ro-line-heading.component";
import RoParts from "./service-history-ro-parts.component";
import RoServices from "./service-history-ro-services.component";

const ServiceHistoryRepairOrders = ({
  repairOrders,
  roIdSelected,
  isRoSelectable,
  handleCheckboxChange
}) => {
  /**
   * Formats a given date object into a string with the specified format.
   * @param {Date} date - The date object to be formatted.
   * @return {string} The formatted date string.
   */
  const formatDate = date => {
    const options = {
      timeZone: "UTC",
      month: "short",
      day: "numeric",
      year: "numeric"
    };
    return new Date(date).toLocaleDateString("en-US", options).replace(",", "");
  };
  /**
   * Returns the sum of all RO prices
   * @param {Object} repairOrder
   * @return {string}
   */
  const getRoTotal = repairOrder => {
    let total = 0;
    total =
      total +
      parseFloat(repairOrder.warrTotal || 0) +
      parseFloat(repairOrder.intTotal || 0) +
      parseFloat(repairOrder.custTotal || 0) +
      parseFloat(repairOrder.mistAmt || 0);

    return total.toFixed(2);
  };
  /**
   * Parses a string to a float with two decimals and formats it as currency.
   * @param {string} price
   * @return {string}
   */
  const getParsedPrice = price => {
    const parsedPrice = price ? parseFloat(price).toFixed(2) : "0.00";
    return "$" + parsedPrice;
  };

  return (
    <AccordionGroup.Container
      htmlId="accordionGroupOnlyOneOpen"
      independent
      className="repair-order-accordion"
    >
      {repairOrders.map((repairOrder, index) => {
        return (
          <AccordionGroup.Item
            key={`accordion${repairOrder?.roId}`}
            header={
              <div className="history-header-accordion">
                <span className="header-date">
                  <b>{formatDate(repairOrder.roDate)}</b>
                </span>
                <div className="ro-Info-Lines">
                  <RoLineHeading label="Mileage" value={repairOrder.mileage} />
                  <RoLineHeading
                    label="Customer"
                    value={getParsedPrice(repairOrder.custTotal)}
                  />
                  <RoLineHeading
                    label="Warranty"
                    value={getParsedPrice(repairOrder.warrTotal)}
                  />
                  <RoLineHeading
                    label="Internal"
                    value={getParsedPrice(repairOrder.intTotal)}
                  />
                  <RoLineHeading
                    label="Misc"
                    value={getParsedPrice(repairOrder.mistAmt)}
                  />
                  <RoLineHeading
                    label="Total"
                    value={getParsedPrice(getRoTotal(repairOrder))}
                  />
                </div>
              </div>
            }
            eventKey={repairOrder?.roId}
            htmlId={repairOrder.roId}
            defaultExpanded={index === 0}
          >
            <div>
              <hr className="horizontalDivider" />
              <div className="service-accordion-container">
                {isRoSelectable ? (
                  <div>
                    <label className="checkbox-label">
                      <input
                        type="checkbox"
                        checked={
                          repairOrder.roId?.toString() ===
                          roIdSelected?.toString()
                        }
                        onChange={() => handleCheckboxChange(repairOrder.roId)}
                      />
                      <div className="checkbox-checkmark" />
                    </label>
                  </div>
                ) : null}
                <div className="service-accordion-data">
                  <div className="ro-Info">
                    <span>RO# {repairOrder?.roId || "---"}</span>
                    <span>
                      Advisor: {repairOrder?.advisorId || "---"}{" "}
                      {repairOrder?.advisorName}
                    </span>
                  </div>
                  <RoServices services={repairOrder.services} />
                  {repairOrder?.roComments ? (
                    <div className="ro-service-history comments m-t-md">
                      <span className="m-l tr-heading-text">Comments</span>
                      <div className="comments-box">
                        <span className="service-desc ">
                          {repairOrder.roComments}
                        </span>
                      </div>
                    </div>
                  ) : null}
                  <RoParts parts={repairOrder.parts} />
                </div>
              </div>
            </div>
          </AccordionGroup.Item>
        );
      })}
    </AccordionGroup.Container>
  );
};

export default ServiceHistoryRepairOrders;

ServiceHistoryRepairOrders.propTypes = {
  // handlers
  handleCheckboxChange: PropTypes.func,
  // data
  repairOrders: PropTypes.array.isRequired,
  roIdSelected: PropTypes.string,
  isRoSelectable: PropTypes.bool
};
