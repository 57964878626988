import React, { useState, useEffect } from "react";
import TextArea from "@cx/ui/TextArea";
import isEmpty from "lodash/isEmpty";
import { useEditServiceContext, Actions } from "../state/edit-service.context";
import "./ServiceNotes.scss";

const ConcernNotes = ({ localeStrings }) => {
  const {
    dispatch,
    state: {
      service: { commentsRequired, complaint }
    }
  } = useEditServiceContext();
  const [concern, setConcern] = useState(complaint || "");
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    const hasError = commentsRequired && isEmpty(concern);
    dispatch({
      type: Actions.SET_ERRORS,
      payload: { field: "complaint", value: hasError }
    });
  }, [concern, commentsRequired, dispatch]);

  const handleConcernChange = event => {
    setConcern(event.target.value.trim());
  };

  const handleBlur = () => {
    const concernExists = !isEmpty(concern);
    dispatch({ type: Actions.SET_COMPLAINT, payload: concern });
    dispatch({
      type: Actions.SET_CHANGED,
      payload: { field: "complaint", value: concernExists }
    });
    const hasError = commentsRequired && !concernExists;
    dispatch({
      type: Actions.SET_ERRORS,
      payload: { field: "complaint", value: hasError }
    });
    setShowError(hasError);
  };

  return (
    <div id="editDiagnoseService" className="search-flex-grid-container">
      <TextArea
        htmlId="complaint"
        label={localeStrings["sq.common.concern"]}
        maxLength={512}
        name="complaint"
        onChange={handleConcernChange}
        value={concern}
        autoComplete="none"
        className={showError ? "has-error" : ""}
        required={commentsRequired}
        onBlur={handleBlur}
      />
    </div>
  );
};

export default ConcernNotes;
