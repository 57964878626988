import * as Yup from "yup";

import customerSchema from "./customer.schema";

// this schema is only to validate `searchCustomer` payload in our state
const searchCustomer = Yup.object().shape({
  query: Yup.string().default(""),
  customers: Yup.array(customerSchema.customerInSearchState)
});

export default {
  searchCustomer
};
