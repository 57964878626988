const LINE_SUBLET = "LINE_SUBLET";
const ADD_CATALOG_LINE_DISCOUNT = "ADD_CATALOG_LINE_DISCOUNT";
const ADD_CATALOG_LINE_FEE = "ADD_CATALOG_LINE_FEE";
const MODIFY_CATALOG_LINE_FEE = "MODIFY_CATALOG_LINE_FEE";
const ADD_CATALOG_RO_FEE = "ADD_CATALOG_RO_FEE";
const MODIFY_CATALOG_RO_FEE = "MODIFY_CATALOG_RO_FEE";
const MODIFY_CATALOG_LINE_DISCOUNT = "MODIFY_CATALOG_LINE_DISCOUNT";
const ADD_CATALOG_RO_LEVEL_DISCOUNT = "ADD_CATALOG_RO_LEVEL_DISCOUNT";
const MODIFY_CATALOG_RO_LEVEL_DISCOUNT = "MODIFY_CATALOG_RO_LEVEL_DISCOUNT";
const HANGTAG = "HANG TAG";
const CHANGE_ADVISOR = "CHANGE ADVISOR";
const CHANGE_PROMISED_TIME = "CHANGE PROMISED TIME";
const CHANGE_MILEAGE_IN = "CHANGE MILEAGE_IN";
const CHANGE_MILEAGE_OUT = "CHANGE MILEAGE_OUT";
const CHANGE_TRANSPORTATION = "CHANGE_TRANSPORTATION";
const SUBLET_DEFAULT = {
  vendorNotes: "",
  poNumber: "",
  invoiceNumber: "",
  vendor: "",
  poPricing: {
    baseCost: null,
    markupType: "$",
    markupDollars: null,
    markupPercent: null,
    subletTotalCost: 0
  }
};

const SPLIT_SUBLET_DEFAULT = {
  vendorNotes: "",
  poNumber: "",
  invoiceNumber: "",
  vendor: "",
  poPricing: {
    cost: null,
    price: null,
    laborCost: null,
    laborPrice: null,
    partsCost: null,
    partsPrice: null,
    subletTotalCost: 0
  }
};

const FEES_TYPES = {
  DOLLAR: "Dollar",
  PERCENT: "Percent",
  VARIABLE: "Variable"
};

const YES = "Y";
const NO = "N";

const SUBLET_MAX_BASE_COST = 999999.99;
const SUBLET_MAX_MARKUP_DOLLARS = 100000;
const SUBLET_MAX_MARKUP_PERCENT = 1000;

const CATALOG_DISCOUNT_ACTIONS = [
  ADD_CATALOG_LINE_DISCOUNT,
  ADD_CATALOG_RO_LEVEL_DISCOUNT,
  MODIFY_CATALOG_LINE_DISCOUNT,
  MODIFY_CATALOG_RO_LEVEL_DISCOUNT
];

const CATALOG_FEE_ACTIONS = [
  ADD_CATALOG_LINE_FEE,
  ADD_CATALOG_RO_FEE,
  MODIFY_CATALOG_LINE_FEE,
  MODIFY_CATALOG_RO_FEE
];

export {
  LINE_SUBLET,
  SUBLET_DEFAULT,
  SPLIT_SUBLET_DEFAULT,
  SUBLET_MAX_BASE_COST,
  SUBLET_MAX_MARKUP_DOLLARS,
  SUBLET_MAX_MARKUP_PERCENT,
  HANGTAG,
  CHANGE_ADVISOR,
  CHANGE_PROMISED_TIME,
  CHANGE_MILEAGE_IN,
  CHANGE_MILEAGE_OUT,
  CHANGE_TRANSPORTATION,
  ADD_CATALOG_LINE_DISCOUNT,
  ADD_CATALOG_LINE_FEE,
  MODIFY_CATALOG_LINE_FEE,
  ADD_CATALOG_RO_FEE,
  MODIFY_CATALOG_RO_FEE,
  MODIFY_CATALOG_LINE_DISCOUNT,
  ADD_CATALOG_RO_LEVEL_DISCOUNT,
  MODIFY_CATALOG_RO_LEVEL_DISCOUNT,
  FEES_TYPES,
  YES,
  NO,
  CATALOG_DISCOUNT_ACTIONS,
  CATALOG_FEE_ACTIONS
};
