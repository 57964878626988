import React from "react";
import PropTypes from "prop-types";
import IconKeyboardArrowRight from "@cx/ui/Icons/IconKeyboardArrowRight";

import { formatPhoneNumberWithTypeLabel } from "../../utils/formatter.util";

const CustomerCard = props => {
  const { rowRecord } = props;
  const { address, phoneNumbers, personId } = rowRecord && rowRecord;
  const formattedPhone = formatPhoneNumberWithTypeLabel(phoneNumbers);

  return (
    <div className="cust-box" id={personId} onClick={props.clicked}>
      <div className="cust-details">
        <div className="cust-name">
          {rowRecord.firstName} {rowRecord.lastName}
        </div>
        <div className="cust-info-group">
          <div className="cust-info">
            <div>{rowRecord.email}</div>
            <div>{formattedPhone}</div>
          </div>
          <div className="cust-address">
            {address.streetAddressOne ? (
              <span>{address.streetAddressOne}</span>
            ) : null}
            {address.city && address.state ? (
              <span>{`${address.city}, ${address.state} ${
                address.postalCode || address.postalCodeString || ""
              }`}</span>
            ) : null}
          </div>
        </div>
      </div>
      <div className="cust-action">
        <IconKeyboardArrowRight htmlId="IconNoteExample" />
      </div>
    </div>
  );
};

export default CustomerCard;
CustomerCard.propTypes = {
  clicked: PropTypes.func,
  rowRecord: PropTypes.object
};
