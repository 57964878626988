import React, { useEffect, useState } from "react";
import isEmpty from "lodash/isEmpty";
import { useEditServiceContext, Actions } from "../state/edit-service.context";
import CompleteIcon from "@cx/ui/Icons/IconComplete";
import AccordionGroup from "@cx/ui/AccordionGroup";
import SelectInput from "@cx/ui/SelectInput";
import Button from "@cx/ui/Button";
import Badge from "@cx/ui/Badge";
import "./VehicleAttributes.scss";

export const VehicleAttributes = () => {
  const { dispatch, state } = useEditServiceContext();
  const {
    currentEditingService,
    completedSteps,
    rawOperationDetails,
    vehicleAttributes: vehicleAttrs
  } = state;
  const { selectableVehicleAttributes, vehicleAttributes } =
    rawOperationDetails;
  const defaultValues = [{ value: "idk", label: "I don't know" }];
  const disabledNext = isEmpty(Object.keys(vehicleAttrs))
    ? true
    : Object.keys(vehicleAttrs).some(key => isEmpty(vehicleAttrs[key]));
  const [expandedStatus, setExpandedStatus] = useState({
    defaultExpanded: isEmpty(currentEditingService)
  });
  const handleDropdownChange = (type, value) => {
    dispatch({
      type: Actions.SET_VEHICLE_ATTRIBUTES,
      payload: { type, value }
    });
    dispatch({
      type: Actions.SET_STEP_COMPLETED,
      payload: { step: "serviceOptions", completed: false }
    });
    dispatch({
      type: Actions.SET_STEP_COMPLETED,
      payload: { step: "vehicleAttr", completed: false }
    });
    dispatch({
      type: Actions.SET_STEP_COMPLETED,
      payload: { step: "details", completed: false }
    });
    dispatch({
      type: Actions.SET_CHANGED,
      payload: {
        field: "vehicleAttributes",
        value: true
      }
    });
  };
  const buildOptions = options => {
    return Array.isArray(options) && !isEmpty(options)
      ? options.map(option => {
          return { value: option, label: option };
        })
      : [];
  };
  const handleDoneClick = () => {
    setExpandedStatus({ expanded: false });
    dispatch({
      type: Actions.SET_STEP_COMPLETED,
      payload: { step: "vehicleAttr", completed: true }
    });
    dispatch({
      type: Actions.SET_ERRORS,
      payload: { field: "vehicleAttributes", value: false }
    });
  };
  const mapVehicleAttr = value => {
    switch (value) {
      case "idk":
        return "I don't know";
      default:
        return value;
    }
  };
  useEffect(() => {
    if (!expandedStatus.expanded) {
      setExpandedStatus({ defaultExpanded: false });
    }
  }, [expandedStatus.expanded, setExpandedStatus]);
  return (
    <AccordionGroup.Container htmlId="vehicleAttributesComponent" independent>
      <AccordionGroup.Item
        key="1"
        htmlId="vehicleAttributes"
        header={
          <div className="vehicle-attributes-header">
            <div className="vehicle-attributes-wrapper">
              <span>Vehicle attributes</span>
              {!disabledNext && completedSteps.vehicleAttr ? (
                <div className="vehicle-attributes-badges">
                  {Object.keys(vehicleAttrs).map((vehicleAttribute, index) => {
                    return (
                      <Badge
                        key={`vehicle-attr-${index + 1}`}
                        htmlId={`vehicleAttr${index + 1}`}
                        className="vehicle-attributes-badge"
                      >
                        {vehicleAttribute} :{" "}
                        {mapVehicleAttr(vehicleAttrs[vehicleAttribute])}{" "}
                      </Badge>
                    );
                  })}
                </div>
              ) : null}
            </div>
            {!disabledNext && completedSteps.vehicleAttr ? (
              <div id="submoduleCompleted" className="step-completed">
                <CompleteIcon id="vehicleAttributesCompleted" />
              </div>
            ) : null}
          </div>
        }
        onToggle={() => {}}
        {...expandedStatus}
        disabled={!isEmpty(currentEditingService)}
      >
        <div className="vehicle-attributes">
          <div className="vehicle-attributes-dropdowns">
            {selectableVehicleAttributes.map((vehicleAttribute, index) => {
              return (
                <SelectInput
                  key={`vehicle-attr-select-${index + 1}`}
                  htmlId={`vehicleAttrSelect${index + 1}`}
                  label={vehicleAttribute}
                  name={vehicleAttribute}
                  onChange={cxEvent =>
                    handleDropdownChange(vehicleAttribute, cxEvent.target.value)
                  }
                  options={[
                    ...defaultValues,
                    ...buildOptions(vehicleAttributes[vehicleAttribute])
                  ]}
                />
              );
            })}
          </div>
          <div className="vehicle-attributes-controls">
            <Button
              htmlId="doneVehicleAttributes"
              buttonStyle="secondary"
              disabled={disabledNext}
              onClick={handleDoneClick}
            >
              Done
            </Button>
          </div>
        </div>
      </AccordionGroup.Item>
    </AccordionGroup.Container>
  );
};

VehicleAttributes.defaultProps = {};
VehicleAttributes.propTypes = {};
