// @ts-check
import React from "react";
import PotentialValueOdometerChart from "./charts/potential-value-chart.component";
import PotentialValueHeader from "./headers/potential-value-header.component";
import PotentialValueBoard from "./boards/potential-value-board.component";
// eslint-disable-next-line unused-imports/no-unused-imports
import * as CommonTypes from "../../../../../@types/common.types";
// eslint-disable-next-line unused-imports/no-unused-imports
import * as ModelsTypes from "../../../../../@types/models.types";

/**
 * @description "Potential quote value" section. Renders section header and odometer chart for potential quote dollar.
 * It should be kept as a dummy component. Any logic or fetching from context should be done in the section container.
 * @param {{data: ModelsTypes.ChartData, localeStrings: CommonTypes.LocaleStrings}} props
 * @returns {CommonTypes.Component}
 */
const PotentialValueSectionComponent = props => {
  const { data, localeStrings } = props;
  const {
    potentialValueTotalLabel,
    potentialValueHeader,
    potentialValueTooltipMessage,
    potentialValueBoardLabels
  } = localeStrings;
  return (
    <div className="section__wrapper">
      <div className="header">
        <PotentialValueHeader
          header={potentialValueHeader}
          tooltipMessage={potentialValueTooltipMessage}
        />
      </div>
      <div className="potential-value potential-value__wrapper">
        <PotentialValueBoard
          formattedValue={data.chartData[0].formattedValue}
          label={potentialValueBoardLabels[0]}
          position="left"
        />
        <PotentialValueOdometerChart
          width="100%"
          height={130}
          data={data}
          totalLabel={potentialValueTotalLabel}
        />
        <PotentialValueBoard
          formattedValue={data.chartData[1].formattedValue}
          label={potentialValueBoardLabels[1]}
          position="right"
        />
      </div>
    </div>
  );
};

export default PotentialValueSectionComponent;
