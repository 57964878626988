// @ts-check
import React from "react";
import { PieChart, Pie, Cell, ResponsiveContainer } from "recharts";
import { quotesBreakdownColorsArray } from "../../../constants/dashboard.constants";
// eslint-disable-next-line unused-imports/no-unused-imports
import * as ModelsTypes from "../../../../../../@types/models.types";
// eslint-disable-next-line unused-imports/no-unused-imports
import * as CommonTypes from "../../../../../../@types/common.types";

/**
 * @description Quotes odometer component.
 * @param {{width: string, height: number, data: ModelsTypes.ChartData, totalLabel: string}} props
 * @returns {CommonTypes.Component}
 */
const QuotesOdometerChart = props => {
  const { width, height, data, totalLabel } = props;
  return (
    <div className="odometer-chart">
      <ResponsiveContainer width={width} height={height}>
        <PieChart>
          <Pie
            data={data.chartData}
            cx={100}
            cy={100}
            startAngle={200}
            endAngle={-20}
            innerRadius={60}
            outerRadius={80}
            dataKey="value"
            isAnimationActive={false}
          >
            {data.chartData.map((entry, index) => (
              <Cell
                // eslint-disable-next-line react/no-array-index-key
                key={`cell-${index}`}
                fill={
                  quotesBreakdownColorsArray[
                    index % quotesBreakdownColorsArray.length
                  ]
                }
              />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
      <div className="odometer-chart__total">
        <div className="amount">{data.totalQuotes}</div>
        <div className="text">{totalLabel}</div>
      </div>
    </div>
  );
};

export default QuotesOdometerChart;
