const addAddedRecallServices = (quoteConfirmationId, service) => {
  const storageKey = `added-quote-${quoteConfirmationId}`;
  const sessionState = JSON.parse(sessionStorage.getItem(storageKey));
  const recallServices =
    sessionState !== null && sessionState.recallServices
      ? sessionState.recallServices
      : [];

  const foundService = recallServices.find(
    recallService => recallService.id === service.id
  );
  if (!foundService) {
    recallServices.push({ id: service.id });
  }

  sessionStorage.setItem(
    storageKey,
    JSON.stringify({
      ...sessionState,
      recallServices
    })
  );
};

const removeAddedService = (quoteConfirmationId, removedServiceId) => {
  const storageKey = `added-quote-${quoteConfirmationId}`;
  const sessionState = JSON.parse(sessionStorage.getItem(storageKey));

  if (sessionState !== null) {
    const sessionKeys = Object.keys(sessionState);
    const newCacheState = {};
    for (const sessionKey of sessionKeys) {
      let services =
        sessionState !== null && sessionState[sessionKey]
          ? sessionState[sessionKey]
          : [];
      services = services.filter(
        service => service.id !== Number(removedServiceId)
      );
      newCacheState[sessionKey] = services;
    }

    sessionStorage.setItem(
      storageKey,
      JSON.stringify({
        ...newCacheState
      })
    );
  }
};

const wipeAddedServices = quoteConfirmationId => {
  const storageKey = `added-quote-${quoteConfirmationId}`;
  sessionStorage.setItem(storageKey, JSON.stringify({}));
};

export default {
  addAddedRecallServices,
  removeAddedService,
  wipeAddedServices
};
