/* eslint-disable no-console */
import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import isEmpty from "lodash/isEmpty";
import isNull from "lodash/isNull";
import get from "lodash/get";
import AccordionGroup from "@cx/ui/AccordionGroup";
import SummaryButton from "./summary-button.component";
import { getNodeJsPrintUrl, getVehicleImage } from "../../../api/xmmAxios";
import {
  ADD_CUSTOMER,
  ADD_CUSTOMER_ADD_VEHICLE,
  EDIT_CUSTOMER,
  EDIT_VEHICLE,
  SERVICE_SUMMARY,
  SKIP_CUSTOMER
} from "../../../constants/pages.constants";
import { useNewQuoteContext, Actions } from "../../../state/NewQuoteContext";
import ActiveCustomerVehicle from "../selected-customer-vehicle/selected-customer-vehicle.card.js";
import { AppContext } from "../../../state/app-context";
import { formatPrice } from "../../../utils/formatter.util";
import * as gtmEvent from "../../utils/gtag/gtag-event.util";
import NotesTextAreaModal from "../../ui/modals/notes-text-modal.component";
import pastQuotesService from "../../past-quotes/services/past-quotes.service";
import {
  hasNotesAccess,
  hasSeeQuotePDFAccess
} from "../../page-wrapper/utils/quote-util";
import "./quote-header.scss";
function CustomerVehicleHeader() {
  const { state, dispatch } = useNewQuoteContext();
  const {
    customer,
    isEditingService,
    quoteSummary,
    vehicle,
    currentPage,
    serviceContracts
  } = state;
  const { quoteStatus } = quoteSummary;
  const appContext = useContext(AppContext);
  const { windowSize, localeStrings, locale, user, dealer, appType } =
    appContext;
  const { confirmationId, quoteServices } = quoteSummary;
  const [isOpen, setIsOpen] = useState(false);

  const totalServices =
    !confirmationId || isEmpty(quoteServices) ? 0 : quoteServices.length;
  const totalPrice = get(quoteSummary, "totalPrice", "0");
  const xsmall = windowSize.width < 570 ? true : false;
  const showMenuItems =
    (!isEmpty(customer) || !isEmpty(vehicle)) &&
    ![ADD_CUSTOMER, SKIP_CUSTOMER, EDIT_CUSTOMER, EDIT_VEHICLE].includes(
      currentPage
    );
  const [showNotesModal, setShowNotesModal] = useState(false);

  // This handler is triggered by dropdown menu option add/review notes, and opens quote notes modal.
  const openNotesModal = () => {
    setShowNotesModal(true);
  };
  // This handler is triggered by close or cancel buttons from quote notes modal.
  const closeQuoteNotesModal = () => {
    setShowNotesModal(false);
  };
  // This handler is triggered by update button from quote notes modal.
  const updateNotesInQuote = async notesData => {
    showPageLoading(true);
    closeQuoteNotesModal();
    try {
      const { dealerCode } = dealer;
      const { confirmationId } = quoteSummary;
      const notesObj = { note: !notesData ? "" : notesData };
      const notesArray = !notesData ? null : [notesObj];
      const response = await pastQuotesService.updatePastQuoteNotes({
        dealerCode,
        confirmationId,
        lastModByUserId: user.quoteUserId,
        notes: notesArray
      });
      if (!isEmpty(response)) {
        const { notes } = response;
        dispatch({
          type: Actions.SET_QUOTE_NOTES,
          payload: notes
        });
        showPageLoading(false);
      }
    } catch (error) {
      showPageLoading(false);
      console.error(error);
    }
  };

  const showPageLoading = showPageMask => {
    dispatch({
      type: Actions.SET_PAGE_MASK,
      payload: showPageMask
    });
  };

  const handleQuoteTotal = () => {
    if (
      !isEditingService &&
      !isEmpty(confirmationId) &&
      ![ADD_CUSTOMER, SKIP_CUSTOMER, EDIT_CUSTOMER, EDIT_VEHICLE].includes(
        currentPage
      )
    ) {
      dispatch({
        type: Actions.SET_CURRENT_PAGE,
        payload: SERVICE_SUMMARY
      });
    }
  };

  const handleToggle = (open, event) => {
    // This is a hacky approach to get Accordion expanded only when arrow is clicked
    if (
      ["svg", "polygon"].includes(event.target.tagName) &&
      [
        ...event.target.parentElement.classList.value.split(" "),
        ...event.target.classList.value.split(" ")
      ].includes("icon-keyboard-arrow-right")
    ) {
      setIsOpen(open);
    }
  };
  const handleSelectCustomerLabelClick = () => {
    if (
      (!customer || !customer.personId) &&
      (state.currentPage === ADD_CUSTOMER ||
        state.currentPage === ADD_CUSTOMER_ADD_VEHICLE)
    ) {
      return;
    }
    // @note: skip customer flow, add new customer for vehicle added
    if (!isEmpty(vehicle) && isEmpty(customer)) {
      dispatch({
        type: Actions.SET_CURRENT_PAGE,
        payload: ADD_CUSTOMER_ADD_VEHICLE
      });
      dispatch({
        type: Actions.SET_IS_SKIP_CUSTOMER_FLOW,
        payload: true
      });
    } else {
      if (isEmpty(vehicle)) {
        dispatch({
          type: Actions.SET_CURRENT_PAGE,
          payload: ADD_CUSTOMER
        });
      }
    }
  };
  const headerRibbon = (customer, vehicle) => {
    if (!isEmpty(customer) || !isEmpty(vehicle)) {
      return (
        <span className="quote-header__ribbon">
          {customer
            ? ` ${customer.firstName || ""} ${customer.lastName || ""}`
            : null}
          {isEmpty(customer) ? (
            <span
              className={clsx(
                "quote-header__select-customer",
                currentPage === ADD_CUSTOMER_ADD_VEHICLE ? "disabled" : ""
              )}
              role="button"
              onClick={handleSelectCustomerLabelClick}
            >
              {
                localeStrings[
                  "sq.newquote.active_customer_vehicle.select_customer_lbl"
                ]
              }
            </span>
          ) : null}
          {!isEmpty(vehicle) ? <span>&nbsp; &bull; &nbsp;</span> : null}
          {vehicle
            ? `${vehicle.year || ""} ${vehicle.make || ""} ${
                vehicle.model || ""
              } ${vehicle.vin || ""}`
            : null}
        </span>
      );
    } else {
      return "";
    }
  };

  useEffect(() => {
    if (isEmpty(customer) && isEmpty(vehicle)) {
      setIsOpen(false);
    } else if (appType === "CSR") {
      setIsOpen(true);
    }
  }, [customer, setIsOpen, vehicle, appType]);

  // previouslly i added check for only skip customer flow, now i removed it
  // becuase if we open past quote we have customer but still vehicle image url was undefined.
  useEffect(() => {
    if (vehicle && isNull(vehicle.image)) {
      const { make, model, year } = vehicle;
      const countryCode = locale.split("_")[1];
      vehicle.image = getVehicleImage(make, model, year, countryCode);
    }
  }, [vehicle, locale]);

  // @note - create dropdown menu items as below
  const dropDownOptions = [];
  const notesAccess = hasNotesAccess(quoteSummary);
  const seeQuotePDFAccess = hasSeeQuotePDFAccess(quoteSummary);
  // const copyQuoteAccess = hasCopyQuoteAccess(quoteSummary, userPermissions);
  // const deleteQuoteAccess = hasDeleteQuoteAccess(quoteSummary);
  if (!isEmpty(confirmationId)) {
    dropDownOptions.push({
      label:
        !isEmpty(quoteSummary.notes) || !notesAccess
          ? localeStrings["sq.newquote.location_bar.review_notes"]
          : localeStrings["sq.newquote.location_bar.add_notes"],
      onSelect: () => openNotesModal(),
      value:
        !isEmpty(quoteSummary.notes) || !notesAccess
          ? "reviewNotes"
          : "addNotes"
    });
    // See Quote PDF
    dropDownOptions.push({
      label: "See quote (PDF)",
      onSelect: () => viewQuotePdf(),
      disabled: !seeQuotePDFAccess,
      value: "seeQuote"
    });
  }
  // @note: Hide copy/delete buttons and handlers until product team want to enable them using story
  // Copy quote
  /*
    dropDownOptions.push({
      label: "Duplicate quote",
      onSelect: () => copyQuoteHandler(),
      disabled: !copyQuoteAccess,
      value: "copyQuote"
    });
    dropDownOptions.push({
      label: "Delete quote",
      onSelect: () => deleteQuoteHandler(),
      disabled: !deleteQuoteAccess,
      value: "deleteQuote"
    });
    */

  /*
  const deleteQuoteHandler = async () => {
    const { dealer } = appContext;
    const { confirmationId } = quoteSummary;
    try {
      await deleteQuote(dealer.dealerCode, confirmationId);
    } catch (error) {
      console.error(error);
    }
    // set new quote context
    dispatch({
      type: Actions.WIPE_STATE
    });
    dispatch({
      type: Actions.SET_CURRENT_PAGE,
      payload: CUSTOMER_SEARCH
    });
  };
  const copyQuoteHandler = async () => {
    const { dealer, user } = appContext;
    const copiedQuote = await copyQuote(
      quoteSummary,
      user.quoteUserId,
      dealer.dealerCode
    );
    const { quoteStatus } = copiedQuote;
    dispatch({
      type: Actions.SET_QUOTE,
      payload: copiedQuote
    });
    dispatch({
      type: Actions.SET_QUOTE_STATUS,
      payload: quoteStatus
    });
    dispatch({
      type: Actions.SET_CURRENT_PAGE,
      payload: SERVICE_SUMMARY
    });
  };
  */
  const viewQuotePdf = () => {
    const { dealer } = appContext;
    gtmEvent.trackGAEventWithParam("ga.newquote.view_pdf_click", {
      result: dealer.dealerCode
    });
    const nodeJsPrintUrl = getNodeJsPrintUrl();
    const pdfUrl = new URL("/svcQuote/pdf", nodeJsPrintUrl);
    pdfUrl.searchParams.append(
      "quoteConfirmationId",
      quoteSummary.confirmationId
    );
    pdfUrl.searchParams.append("dealerCode", dealer.dealerCode);
    window.open(pdfUrl.toString(), "_PreviewQuote");
  };
  const quoteNotesModal = showNotesModal && (
    <NotesTextAreaModal
      quoteId={quoteSummary.confirmationId || null}
      notes={
        !isEmpty(quoteSummary.notes?.[0]) ? quoteSummary.notes[0].note : null
      }
      okAction={updateNotesInQuote}
      cancelAction={() => closeQuoteNotesModal()}
      showModal={showNotesModal}
      legend={localeStrings["sq.newquote.add_notes.legend"]}
      notesTitle={localeStrings["sq.newquote.add_notes.notes"]}
      quoteStatus={quoteStatus || null}
    />
  );

  const enableSummary = !isEmpty(confirmationId) || !isEditingService;
  return (
    <>
      <div id="CustomerVehicleHeader" className="quote-header-wrapper">
        <AccordionGroup.Container htmlId="accordionGroupSingle" independent>
          <AccordionGroup.Item
            className={clsx(
              "quote-header-wrapper",
              isEmpty(customer) && isEmpty(vehicle) ? "hide-arrow" : ""
            )}
            expanded={isOpen}
            onToggle={handleToggle}
            eventKey="1"
            htmlId="CustomerVehicleHeaderAccordion"
            header={
              <div className="quote-header">
                {isOpen ? (
                  <ActiveCustomerVehicle selectIfEmpty={false} />
                ) : (
                  headerRibbon(customer, vehicle)
                )}
                {appType !== "CSR" ? (
                  <div className={isOpen ? "summary-button" : ""}>
                    <SummaryButton
                      isEnabled={enableSummary}
                      isXsmall={xsmall}
                      localeStrings={localeStrings}
                      onClick={() => handleQuoteTotal()}
                      quoteTotalAmountText={formatPrice(totalPrice)}
                      totalServices={totalServices}
                      {...(showMenuItems ? { dropDownOptions } : {})}
                    />
                  </div>
                ) : null}
              </div>
            }
          />
        </AccordionGroup.Container>
      </div>
      {quoteNotesModal}
    </>
  );
}

CustomerVehicleHeader.propTypes = {
  onStartNewQuote: PropTypes.func
};

CustomerVehicleHeader.defaultProps = {
  onStartNewQuote: () => {}
};

export default CustomerVehicleHeader;
/* eslint-enable no-console */
