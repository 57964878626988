import React from "react";
import format from "../../../utils/format";
import PropTypes from "prop-types";

const FeeTotalRow = ({ label, value, priceClassName }) => {
  return (
    <div className="catalog-fee-total-row">
      <span>{label}</span>
      <span className={priceClassName}>{format.currency(value)}</span>
    </div>
  );
};

FeeTotalRow.propTypes = {
  label: PropTypes.string,
  value: PropTypes.number,
  priceClassName: PropTypes.string
};

export default FeeTotalRow;
