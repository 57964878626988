const localeEnUs = {
  "sq.newquote.edit_menu_service.no_service_selected_msg":
    "At least one service must be included before the package can be Saved",
  "sq.search.menu.edit_service_detail_lbl": "Edit service details",
  "sq.search.menu.edit_back_review_package_details_lbl":
    "Back to review package details",
  "sq.search.common.edit_button": "Edit",
  "sq.search.common.cancel_button": "Cancel",
  "sq.search.common.save_button": "Save",
  "sq.search.common.alert_lbl": "Alert!",
  "sq.search.common.leaving_menu_package_edit":
    "Are you sure you want to leave without saving? All unsaved changes will be lost.",
  "sq.search.common.leaving_edit_page":
    "Are you sure you want to leave without saving? All unsaved changes will be lost.",
  "sq.search.common.proceed_button": "Proceed",
  "sq.search.common.edit_service.summary.labor_lbl": "Labor",
  "sq.search.common.edit_service.summary.qty_lbl": "Qty",
  "sq.search.common.edit_service.summary.parts_lbl": "Parts",
  "sq.search.edit_service.summary.show_details_lbl": "Show details",
  "sq.search.edit_service.summary.included_inspection": "Included inspection",
  "sq.search.menu.package.customize": "This package can not be customized",
  "sq.search.menu.package.selectable":
    "This package includes optional services",
  "sq.search.menu.package.modifiable":
    "This package contains services that can be modified",
  "sq.search.menu.package.opcode":
    "By changing the op code the package will show as a single service line on the RO",
  "sq.search.errors.read_service_quoting_enabled_property":
    "There was an error while fetching Service Quoting Enabled property.",
  "sq.search.customer.add_to_quote_lbl": "Add to quote",
  "sq.search.diagnose.service.note.title":
    "Additional notes, customer concerns, etc.",
  "sq.search.declined.service.note.error.msg": "Additional notes are required",
  "sq.search.diagnose.service.note.error.msg": "additional notes are required",
  "sq.editservice.missing_notes":
    "Please complete the Notes section to save this item to the quote",
  "sq.editservice.save_error":
    "Please review the following information to save this item to the quote "
};

export default localeEnUs;
