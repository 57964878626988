import React from "react";
import {
  ADD_CATALOG_RO_LEVEL_DISCOUNT,
  MODIFY_CATALOG_RO_LEVEL_DISCOUNT
} from "../../../constants/adjustment.constant";
import Row from "@cx/ui/Row";
import Col from "@cx/ui/Col";
import format from "../../../utils/format";
import { DISCOUNT_TYPES } from "../catalog-discount.constants";

const PriceRenderer = ({
  selectedDiscount,
  priceAdjustmentType,
  hideLaborRow,
  hidePartsRow,
  labelError,
  actualLaborDiscountValue,
  actualPartDiscountValue,
  adjustedServicePriceValue
}) => {
  return (
    <>
      {[
        ADD_CATALOG_RO_LEVEL_DISCOUNT,
        MODIFY_CATALOG_RO_LEVEL_DISCOUNT
      ].includes(priceAdjustmentType) ? (
        <div className="hr-border" />
      ) : null}
      {hideLaborRow ? (
        <Row>
          <Col xs={8} md={8}>
            Labor discount
          </Col>
          <Col
            md={4}
            xs={4}
            className={`text-end ${labelError ? "Ferrari500" : "Titanium800"}`}
          >
            {format.currency(-actualLaborDiscountValue)}
          </Col>
        </Row>
      ) : null}
      {hidePartsRow ? (
        <Row>
          <Col xs={8} md={8}>
            Parts discount
          </Col>

          <Col
            md={4}
            xs={4}
            className={`text-end ${labelError ? "Ferrari500" : "Titanium800"}`}
          >
            {format.currency(-actualPartDiscountValue)}
          </Col>
        </Row>
      ) : null}
      {[
        ADD_CATALOG_RO_LEVEL_DISCOUNT,
        MODIFY_CATALOG_RO_LEVEL_DISCOUNT
      ].includes(priceAdjustmentType) ? (
        <div className="hr-border" />
      ) : (
        <div>
          <div className="hr-border" /> <div className="hr-border" />
          <Row className="m-b">
            <Col xs={8} md={8}>
              Adjusted service price
            </Col>
            <Col
              md={4}
              xs={4}
              className={`text-end bold  ${
                labelError &&
                selectedDiscount?.discountType === DISCOUNT_TYPES.VARIABLE
                  ? "Ferrari500"
                  : "Titanium800"
              }`}
            >
              {format.currency(adjustedServicePriceValue)}
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};

export default PriceRenderer;
