import React from "react";
import PropTypes from "prop-types";
import LaborGrid from "./LaborGrid";
import PartsGrid from "./PartsGrid";
import VehicleAttributeSelector from "./VehicleAttributeSelector";
import PositionExclusion from "./data/PositionExclusion.json";
import {
  hasAllVehicleAttributesSelected,
  hasSelectableVehicleAttributes
} from "./utils/helper";
import { LicenseManager } from "ag-grid-enterprise";

// Add Ag-grid License here at App level
LicenseManager.setLicenseKey(
  "CompanyName=Cox Automotive Corporate Services, LLC.,LicensedGroup=Multi,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=3,LicensedProductionInstancesCount=1,AssetReference=AG-032003,SupportServicesEnd=9_November_2023_[v2]_MTY5OTQ4ODAwMDAwMA==b5cca07001950ac04847c94c8d63c8e7"
);

class ResultsPane extends React.Component {
  static getDerivedStateFromProps(nextProps, prevState) {
    const { partsAndLabor } = nextProps;
    if (partsAndLabor && partsAndLabor !== prevState.partsAndLabor) {
      let hideLaborGrid = !showLaborAndPartsGrids(partsAndLabor);
      const { selectableVehicleAttributes } = partsAndLabor;
      const showVehAttrSelector = hasSelectableVehicleAttributes(
        selectableVehicleAttributes
      );
      if (showVehAttrSelector) {
        hideLaborGrid = true;
      }

      return {
        showVehAttrSelector,
        hideLaborGrid,
        partsAndLabor,
        filteredParts: partsAndLabor?.parts ? [...partsAndLabor.parts] : [],
        laborSelected: false
        // selectedParts
      };
    }
    return null;
  }
  constructor(props) {
    super(props);
    const { partsAndLabor } = props;
    this.laborGridRef = React.createRef();
    this.partsGridRef = React.createRef();
    this.selectLaborRow = this.selectLaborRow.bind(this);
    this.selectPartsRow = this.selectPartsRow.bind(this);
    this.getPartsGridRef = this.getPartsGridRef.bind(this);
    this.getSelectedVehicleAttributeMap =
      this.getSelectedVehicleAttributeMap.bind(this);
    this.refreshPartsGrid = this.refreshPartsGrid.bind(this);
    this.getPartsPricingAndInventory =
      this.getPartsPricingAndInventory.bind(this);
    this.onVehicleAttributeSelectorChange =
      this.onVehicleAttributeSelectorChange.bind(this);
    const filteredParts = partsAndLabor?.parts ? [...partsAndLabor.parts] : [];
    let hideLaborGrid = !showLaborAndPartsGrids(partsAndLabor);
    const showVehAttrSelector =
      partsAndLabor &&
      hasSelectableVehicleAttributes(partsAndLabor.selectableVehicleAttributes);
    if (showVehAttrSelector) {
      hideLaborGrid = !hasAllVehicleAttributesSelected(
        partsAndLabor.selectedVehicleAttributeMap,
        partsAndLabor.selectableVehicleAttributes.length
      );
    }
    this.state = {
      errors: {},
      showVehAttrSelector,
      hideLaborGrid,
      partsAndLabor,
      filteredParts,
      selectedLabors: props.selectedLabors || [],
      selectedParts: props.selectedParts || [],
      laborSelected: false,
      laborAppPositions: []
    };
  }

  getPartsGridRef() {
    return this.partsGridRef?.current ? this.partsGridRef.current : null;
  }
  refreshPartsGrid() {
    const partsGridRef = this.getPartsGridRef();
    partsGridRef?.refreshGrid();
  }

  selectLaborRow = selectedRows => {
    const laborSelected = selectedRows.length !== 0;
    const laborAppPositions = this.getPositions(selectedRows);
    const { partsAndLabor } = this.state;
    const { dealerPublishedCatalog } = partsAndLabor;
    let selectedParts = null;
    if (dealerPublishedCatalog) {
      this.setState({
        laborSelected,
        laborAppPositions,
        // filteredParts,
        selectedParts: partsAndLabor.parts
      });
    } else {
      let filteredParts = [];
      const { apiVersion } = partsAndLabor;
      if (apiVersion === 2) {
        if (selectedRows.length === 1) {
          partsAndLabor.selectableVehAttrParts = selectedRows[0].parts;
          const { selectedVehicleAttributeMap, selectableVehAttrParts } =
            partsAndLabor;
          const parts = selectableVehAttrParts.filter(part => {
            const { vehicleAttributes } = part;
            if (!vehicleAttributes) {
              return true;
            }
            const vehAttrKeys = Object.keys(vehicleAttributes);
            for (const key of vehAttrKeys) {
              if (
                selectedVehicleAttributeMap[key] !== "idk" &&
                selectedVehicleAttributeMap[key] !== vehicleAttributes[key]
              ) {
                return false;
              }
            }
            return true;
          });
          partsAndLabor.parts = parts;
          filteredParts = parts;
        }
      } else {
        filteredParts = this.getFilteredParts(
          laborAppPositions,
          partsAndLabor.parts
        );
      }
      selectedParts = filteredParts.filter(item => {
        return item.manuallySelected;
      });
      this.setState({
        laborSelected,
        laborAppPositions,
        filteredParts,
        selectedParts
      });
    }
    this.props.selectGridRow(selectedRows, "labor", selectedParts);
  };
  selectPartsRow = selectedParts => {
    this.props.selectGridRow(selectedParts, "parts");
    const { partsAndLabor } = this.state;
    partsAndLabor.partsSelected = true;
    this.setState({ selectedParts, partsAndLabor });
  };
  recalculateTotalPrice() {
    const { selectedParts, partsAndLabor } = this.state;
    const { partsSelected } = partsAndLabor;
    if (partsSelected) {
      this.props.selectGridRow(selectedParts, "parts");
    }
  }
  getPositions = laborApps => {
    const laborAppPositions = [];
    laborApps.forEach(l => {
      if (l.position) {
        laborAppPositions.push(l.position);
      }
    });
    return laborAppPositions;
  };
  isPositionExcluded = (excludedPositions, position) => {
    for (const s of excludedPositions) {
      if (s.toLowerCase() === position.toLowerCase()) {
        return true;
      }
    }
    return false;
  };
  getFilteredParts = (laborAppPositions, parts) => {
    if (laborAppPositions.length === 0) {
      return [...parts];
    } else {
      const filteredParts = parts.filter(p => {
        let included = false;
        if (p.position) {
          for (const laborAppPosition of laborAppPositions) {
            const excludedPositions = PositionExclusion[laborAppPosition];
            if (
              !excludedPositions ||
              // p.position === "N/A" ||
              !this.isPositionExcluded(excludedPositions, p.position)
            ) {
              included = true;
              break;
            }
          }
        } else {
          included = true;
        }
        return included;
      });
      return filteredParts;
    }
  };

  onVehicleAttributeSelectorChange = cxEvent => {
    const { name, value } = cxEvent.target;
    const { hideLaborGrid, partsAndLabor } = this.state;
    const {
      selectedVehicleAttributeMap,
      selectableVehicleAttributes,
      selectableVehAttrLabors
      // selectableVehAttrParts
    } = partsAndLabor;

    if (!value) {
      delete selectedVehicleAttributeMap[name];
      if (!hideLaborGrid) {
        this.setState({ hideLaborGrid: true });
      }
      this.props.selectGridRow([], "labor", []);
      return;
    }

    selectedVehicleAttributeMap[name] = value;
    // }
    if (
      hasAllVehicleAttributesSelected(
        selectedVehicleAttributeMap,
        selectableVehicleAttributes.length
      )
    ) {
      const labors = selectableVehAttrLabors.filter(labor => {
        const { vehicleAttributes } = labor;
        if (!vehicleAttributes) {
          return true;
        }
        const vehAttrKeys = Object.keys(vehicleAttributes);
        for (const key of vehAttrKeys) {
          if (
            selectedVehicleAttributeMap[key] !== "idk" &&
            selectedVehicleAttributeMap[key] !== vehicleAttributes[key]
          ) {
            return false;
          }
        }
        return true;
      });
      partsAndLabor.labors = labors;
      let laborSelected = true;

      if (labors.length === 1) {
        this.selectLaborRow(labors);
      } else {
        laborSelected = false;
        this.laborGridRef?.current &&
          this.laborGridRef.current.resetSelection();
        this.setState({ selectedLabors: [], laborSelected });
      }
      this.setState({ partsAndLabor, hideLaborGrid: false });
    } else {
      if (!hideLaborGrid) {
        this.setState({ hideLaborGrid: true });
      }
    }
  };

  resetDmsPending(parts) {
    parts.forEach(p => {
      if (p.oemPartNumber) {
        p.dmsPending = false;
      }
    });
  }

  getSelectedVehicleAttributeMap = () => {
    const { partsAndLabor } = this.state;
    if (partsAndLabor?.selectedVehicleAttributeMap) {
      return partsAndLabor.selectedVehicleAttributeMap;
    }
    return {};
  };
  getPartsPricingAndInventory(serviceApiCallback, params) {
    const {
      // appSource,
      dealerCode,
      // make,
      // vin,
      // operationId,
      // operationSource,
      // payType,
      // serviceType,
      partsAndLabor
    } = params;
    if (
      dealerCode &&
      partsAndLabor.parts &&
      partsAndLabor.parts.length !== 0 &&
      partsAndLabor.parts.filter(p => !!p.oemPartNumber).length !== 0
    ) {
      const { labors } = partsAndLabor;
      let opcode = null;
      if (labors && labors.length !== 0) {
        const { opCode, dmsOpcode } = labors[0];
        opcode = dmsOpcode ? dmsOpcode : opCode;
      }
      this.updatePartsPricingAndInventory(
        serviceApiCallback,
        params,
        partsAndLabor.parts,
        opcode
      );
    }
  }
  updatePartsPricingAndInventory(serviceApiCallback, params, oemParts) {
    const {
      appSource,
      // dealerCode,
      // make,
      vin,
      operationId,
      operationSource,
      payType,
      serviceType
    } = params;

    // get unique part numbers for getting the parts pricing and inventory
    const uniquePartNumberMap = {};
    const partNumbers = oemParts
      .filter(p => {
        if (p.oemPartNumber) {
          p.dmsPending = true;
          if (!uniquePartNumberMap[p.oemPartNumber]) {
            uniquePartNumberMap[p.oemPartNumber] = p;
            return true;
          }
        }
        return false;
      })
      .map(p => {
        return {
          partNumber: p.oemPartNumber,
          manufacturerCode: p.dtDmsPartCode
        };
      });

    const data = {
      appSource,
      operationId,
      operationSource,
      // opCode,
      vin,
      payType,
      serviceType,
      partNumbers
    };

    serviceApiCallback(
      data,
      response => {
        const { success, statusCode } = response;
        if (!success || statusCode !== 200) {
          const { errors } = this.state;
          errors.operations = "Internal error occurred.";
          this.setState({
            errors: { ...errors }
          });
          this.resetDmsPending(oemParts);
        } else {
          const dmsPartsMap = response.parts
            .filter(p => !p.code)
            .reduce((map, obj) => {
              map[obj.partNumber] = obj;
              return map;
            }, {});
          let dmsPriceSet = false;
          oemParts.forEach(p => {
            if (p.oemPartNumber) {
              p.dmsPending = false;
              const dmsPart = dmsPartsMap[p.oemPartNumber];
              if (dmsPart) {
                p.quantityAvailable = dmsPart.quantityAvailable;
                p.dmsPrice = dmsPart.partPrice;
                dmsPriceSet = true;
              }
            }
          });
          // recalculate total price if dmsPrice for parts is set
          if (dmsPriceSet) {
            this.recalculateTotalPrice();
          }
          // refresh parts grid
          this.refreshPartsGrid();
        }
      },
      error => {
        const { response } = error;
        if (!response) {
          const { errors } = this.state;
          errors.operations = error.message;
          this.setState({
            showFilter: true,
            errors: { ...errors }
          });
        } else {
          const { success, message } = response.data;
          if (!success && message) {
            const { errors } = this.state;
            errors.dmsPartsPricingAndInventory = message;
            this.setState({
              errors: { ...errors }
            });
          }
        }
        this.resetDmsPending(oemParts);
        this.refreshPartsGrid();
      }
    );
  }

  render() {
    let resultSection = null;
    const { disableAllParts } = this.props;
    const {
      showVehAttrSelector,
      hideLaborGrid,
      laborSelected,
      selectedLabors,
      selectedParts,
      partsAndLabor
    } = this.state;

    if (
      partsAndLabor
      // partsAndLabor.operation.operationSource === "GlobalCatalog"
    ) {
      const { filteredParts } = this.state;
      let partsGrid = "";
      if (laborSelected) {
        const { selectedVehicleAttributeMap } = partsAndLabor;
        const additionalColumns = partsAndLabor.partsColumns.filter(column => {
          const { headerName } = column;
          const value = selectedVehicleAttributeMap[headerName];
          return !value || value === "idk";
        });
        partsGrid = (
          <PartsGrid
            ref={this.partsGridRef}
            rowData={filteredParts}
            selectedParts={selectedParts}
            additionalColumns={additionalColumns}
            naNrNssParts={partsAndLabor.naNrNssParts}
            fluidHasQualifiers={partsAndLabor.fluidHasQualifiers}
            includeInspectColumns={this.props.includeInspectColumns}
            disableAllParts={disableAllParts}
            onSelectRow={this.selectPartsRow}
            // optionalPRForFluids={partsAndLabor.optionalPRforFluids}
            // optionalPRForParts={partsAndLabor.optionalPRForParts}
          />
        );
      }
      const vehAttrSelector = showVehAttrSelector ? (
        <VehicleAttributeSelector
          partsAndLabor={partsAndLabor}
          onChange={this.onVehicleAttributeSelectorChange}
        />
      ) : (
        ""
      );

      resultSection = (
        <div style={{ width: "100%" }}>
          {vehAttrSelector}
          {hideLaborGrid ? (
            <div />
          ) : (
            <div className="ops-results-grid">
              <LaborGrid
                ref={this.laborGridRef}
                partsAndLabor={partsAndLabor}
                showHeaderCheckbox={!partsAndLabor.dealerPublishedCatalog}
                selectedLabors={selectedLabors}
                rowData={partsAndLabor.labors}
                additionalColumns={partsAndLabor.laborsColumns}
                onSelectRow={this.selectLaborRow}
              />
              {partsGrid}
            </div>
          )}
        </div>
      );
    }
    return <React.Fragment>{resultSection}</React.Fragment>;
  }
}
export default ResultsPane;

ResultsPane.propTypes = {
  disableAllParts: PropTypes.bool,
  showHeaderCheckbox: PropTypes.bool,
  includeInspectColumns: PropTypes.bool,
  partsAndLabor: PropTypes.object,
  selectGridRow: PropTypes.func,
  selectedLabors: PropTypes.array,
  selectedParts: PropTypes.array
};

ResultsPane.defaultProps = {
  disableAllParts: false,
  showHeaderCheckbox: true,
  includeInspectColumns: true,
  selectedLabors: [],
  selectedParts: []
};

function showLaborAndPartsGrids(partsAndLabor) {
  if (partsAndLabor?.labors?.length) {
    for (const labor of partsAndLabor.labors) {
      if (labor && !isNaN(labor.laborHours)) {
        return true;
      }
    }
  }
  return false;
}
