/* @note: These make codes are extracted from Make Manufacturer codelist excel, in future this json will be converted to rest API */
const makeCodes = [
  {
    make: "ACURA",
    code: "AC",
    description: "Acura"
  },
  {
    make: "ALFA ROMEO",
    code: "AL",
    description: "Alfa Romeo"
  },
  // {
  //   make: "ASTON MARTIN",
  //   code: "",
  //   description: "Aston Martin"
  // },
  {
    make: "AUDI",
    code: "VW",
    description: "Audi"
  },
  // {
  //   make: "BENTLEY",
  //   code: "",
  //   description: "Bentley"
  // },
  {
    make: "BMW",
    code: "BM",
    description: "BMW"
  },
  {
    make: "BUICK",
    code: "GM",
    description: "General Motors"
  },
  {
    make: "CADILLAC",
    code: "GM",
    description: "General Motors"
  },
  {
    make: "CHEVROLET",
    code: "GM",
    description: "General Motors"
  },
  {
    make: "CHRYSLER",
    code: "CH",
    description: "Chrysler"
  },
  {
    make: "DODGE",
    code: "CH",
    description: "Chrysler"
  },
  {
    make: "FIAT",
    code: "FI",
    description: "Fiat"
  },
  {
    make: "FORD",
    code: "FD",
    description: "Ford"
  },
  {
    make: "GENESIS",
    code: "GN",
    description: "Genesis"
  },
  {
    make: "GMC",
    code: "GM",
    description: "General Motors"
  },
  {
    make: "HONDA",
    code: "HN",
    description: "Honda"
  },
  {
    make: "HUMMER",
    code: "GM",
    description: "General Motors"
  },
  {
    make: "HYUNDAI",
    code: "HY",
    description: "Hyundai"
  },
  {
    make: "INFINITI",
    code: "IN",
    description: "Infiniti"
  },
  {
    make: "ISUZU",
    code: "IZ",
    description: "Isuzu"
  },
  {
    make: "JAGUAR",
    code: "JG",
    description: "Jaguar"
  },
  {
    make: "JEEP",
    code: "CH",
    description: "Chrysler"
  },
  {
    make: "KIA",
    code: "KI",
    description: "Kia"
  },
  {
    make: "LAND ROVER",
    code: "LR",
    description: "Land Rover"
  },
  {
    make: "LEXUS",
    code: "LX",
    description: "Lexus"
  },
  {
    make: "LINCOLN",
    code: "FD",
    description: "Ford"
  },
  {
    make: "MASERATI",
    code: "MF",
    description: "Maserati"
  },
  {
    make: "MAZDA",
    code: "MZ",
    description: "Mazda"
  },
  {
    make: "MERCEDES-BENZ",
    code: "MB",
    description: "Mercedes-Benz"
  },
  {
    make: "MERCURY",
    code: "FD",
    description: "Ford"
  },
  {
    make: "MINI",
    code: "BM",
    description: "BMW"
  },
  {
    make: "MITSUBISHI",
    code: "MT",
    description: "Mitsubishi"
  },
  {
    make: "NISSAN",
    code: "NS",
    description: "Nissan"
  },
  {
    make: "OLDSMOBILE",
    code: "GM",
    description: "General Motors"
  },
  {
    make: "OTHER",
    code: "OT",
    description: "Other"
  },
  // {
  //   make: "POLESTAR",
  //   code: "",
  //   description: "POLESTAR"
  // },
  {
    make: "PONTIAC",
    code: "GM",
    description: "General Motors"
  },
  {
    make: "PORSCHE",
    code: "PO",
    description: "Porsche"
  },
  {
    make: "RAM",
    code: "CH",
    description: "Chrysler"
  },
  // {
  //   make: "ROLLS-ROYCE",
  //   code: "",
  //   description: "ROLLS-ROYCE"
  // },
  {
    make: "SAAB",
    code: "SB",
    description: "Saab"
  },
  {
    make: "SATURN",
    code: "GM",
    description: "General Motors"
  },
  {
    make: "SCION",
    code: "TY",
    description: "Toyota"
  },
  // {
  //   make: "SMART",
  //   code: "",
  //   description: "SMART"
  // },
  {
    make: "SPRINTER",
    code: "MB",
    description: "Mercedes-Benz"
  },
  {
    make: "SRT",
    code: "CH",
    description: "Chrysler"
  },
  {
    make: "SUBARU",
    code: "SU",
    description: "Subaru"
  },
  {
    make: "SUZUKI",
    code: "SZ",
    description: "Suzuki"
  },
  {
    make: "TOYOTA",
    code: "TY",
    description: "Toyota"
  },
  {
    make: "VOLKSWAGEN",
    code: "VW",
    description: "Volkswagen"
  },
  {
    make: "VOLVO",
    code: "VO",
    description: "Volvo"
  },
  {
    make: "WAGONEER",
    code: "CH",
    description: "Chrysler"
  }
];

export default makeCodes;
