// @ts-check
import React from "react";
import PropTypes from "prop-types";
import { freezeObject } from "../../../../utils/object";
import {
  createQuotesNumericBreakdownDataSet,
  refineQuotesDataSet,
  createDataSetForPotentialValueChart,
  createDailyDataSetForTimelineChart,
  createWeeklyDataSetForTimelineChart
} from "../utils/dashboard.util";
import servicesCount from "../utils/dashboard-service-types.util";
// eslint-disable-next-line unused-imports/no-unused-imports
import * as ModelsTypes from "../../../../@types/models.types";
// eslint-disable-next-line unused-imports/no-unused-imports
import * as ContextTypes from "../../../../@types/context.types";
// eslint-disable-next-line unused-imports/no-unused-imports
import * as CommonTypes from "../../../../@types/common.types";

/** @type {ContextTypes.DashboardContext} */
let defaultState = {
  quotes: null,
  localeStrings: null,
  refinedQuotes: null,
  serviceTypes: null,
  quoteOdometerChartData: null,
  potentialValueChartData: null,
  quotesNumericBreakdownDataSet: null,
  quotesAreaTimelineChartData: [],
  dateRange: "30"
};

defaultState = freezeObject(defaultState);

/** @type {React.Context<any>} */
const DashboardContext = React.createContext(defaultState);

/** @type {ContextTypes.ContextActionType} */
const Actions = {
  SET_LOCALE_STRINGS: "SET_LOCALE_STRINGS",
  SET_QUOTES: "SET_QUOTES",
  SET_SERVICE_TYPES: "SET_SERVICE_TYPES"
};

/** @type {ContextTypes.ReducerType} */
const dashboardReducer = (state, action) => {
  switch (action.type) {
    case Actions.SET_LOCALE_STRINGS: {
      const localeStrings = action.payload;
      return {
        ...state,
        localeStrings
      };
    }
    case Actions.SET_QUOTES: {
      const { quotes, dateRange } = action.payload;
      /** @type {Array<ModelsTypes.RefineQuote>} */
      const refinedQuotes = refineQuotesDataSet(quotes);
      const quotesNumericBreakdownDataSet =
        createQuotesNumericBreakdownDataSet(refinedQuotes);
      // tbd: uncomment const below when working in potential quote value section
      const potentialValueChartData =
        createDataSetForPotentialValueChart(refinedQuotes);
      const quotesAreaTimelineChartData =
        dateRange === "90"
          ? createWeeklyDataSetForTimelineChart(refinedQuotes)
          : createDailyDataSetForTimelineChart(refinedQuotes, dateRange);
      return {
        ...state,
        quotes: action.payload,
        refinedQuotes,
        quotesNumericBreakdownDataSet,
        potentialValueChartData,
        quotesAreaTimelineChartData,
        dateRange
      };
    }
    case Actions.SET_SERVICE_TYPES: {
      /** @type {ModelsTypes.AllServiceTypes} */
      const servicesTotals = servicesCount(action.payload);
      return {
        ...state,
        serviceTypes: action.payload,
        servicesTotals
      };
    }
  }
};

/**
 * @description Dashboard Provider
 * @param {{children: any}} props
 * @returns {CommonTypes.Component}
 */
const DashboardProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(dashboardReducer, defaultState);
  const value = { state, dispatch };
  return (
    <DashboardContext.Provider value={value}>
      {children}
    </DashboardContext.Provider>
  );
};

DashboardProvider.propTypes = {
  children: PropTypes.node.isRequired
};

/**
 * @description Custom Hook
 * @returns {ContextTypes.ContextHook<ContextTypes.DashboardContext>}
 */
const useDashboardContext = () => {
  /** @type {any} */
  const context = React.useContext(DashboardContext);
  if (context === undefined) {
    throw new Error(
      "useDashboardContext must be used within a DashboardProvider"
    );
  }
  return {
    state: context.state,
    dispatch: context.dispatch
  };
};

export { DashboardProvider, useDashboardContext, Actions, defaultState };
