// @ts-check
import React from "react";
// eslint-disable-next-line unused-imports/no-unused-imports
import * as CommonTypes from "../../../../../@types/common.types";

/**
 * @description Layout component only. Used to wrap each section in a column.
 * @param {{children: any}} props
 * @returns {CommonTypes.Component}
 */
const DashboardColumn = props => {
  /** @type {{children:JSX.Element}} */
  const { children } = props;
  return <div className="dashboard__column">{children}</div>;
};

export default DashboardColumn;
