import React, { useState } from "react";
import PropTypes from "prop-types";
import "./hang-tag.scss";
import TextInput from "@cx/ui/TextInput";
const HangTag = props => {
  const [hangTag, setHangTag] = useState(props?.quoteSummary?.hangTag || null);

  const onInputChange = cxEvent => {
    const hangTagValue = cxEvent.target.value;
    setHangTag(hangTagValue);
    props.onUpdate({ hangTag: hangTagValue });
  };

  const handleEnterPress = event => {
    /* istanbul ignore else*/
    if (event.key === "Enter") {
      // Call the callback function passed from parent on press of enter
      props.onEnterKeyPressOnUpdate();
    }
  };
  return (
    <div className="hang-tag-container">
      <TextInput
        autoFocus
        className="test"
        htmlId="hangTagDefault"
        label="Hang tag"
        name="hangTag"
        onChange={onInputChange}
        onKeyDown={handleEnterPress}
        value={hangTag !== null ? hangTag : ""}
      />
    </div>
  );
};

export default HangTag;

HangTag.propTypes = {
  quoteSummary: PropTypes.object,
  onUpdate: PropTypes.func,
  onEnterKeyPressOnUpdate: PropTypes.func
};

HangTag.defaultProps = {
  quoteSummary: {},
  onUpdate: () => {},
  onEnterKeyPressOnUpdate: () => {}
};
