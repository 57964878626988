// EXTERNAL LIBRARIES
import React from "react";
import PropTypes from "prop-types";

const RoLineHeading = ({ label, value }) => {
  return (
    <div className="ro-line" data-testid="ro-line">
      <span className="text-heading">{label}</span>
      <span className="text-color-grey">{value}</span>
    </div>
  );
};

export default RoLineHeading;

RoLineHeading.prototype = {
  // data
  label: PropTypes.string.isRequired,
  value: PropTypes.string
};
