/* eslint camelcase: 0 */

/**
 * NOTES for Fernando
 * - split the locales in multiple JS files, import them here and assign to respective keys
 * - Cleanup unused keys
 */

import locale_en_US from "./locales/locale_en_US";
import locale_en_AU from "./locales/locale_en_AU";
import locale_fr_CA from "./locales/locale_fr_CA";

const locales = {
  en_AU: locale_en_AU,
  en_US: locale_en_US,
  fr_CA: locale_fr_CA
};

const getLocales = (locale = "en_US") => locales[locale];

export { getLocales };
