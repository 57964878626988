// **  NOTE ! important ------------------------------
// added 1 in id for transportation list response values because in props.transportationList id is starting from 0 which is not usable by cx radio button
// while setting payload subtracted 1 from transport id due to above written cause
// ** -----------------------------------------------------
import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import "./change-transportation.scss";
import RadioButtonList from "@cx/ui/RadioButtonList";

const Transportation = props => {
  const [transportation, setTransportation] = useState(
    props?.selectedTransport?.transportTypeId + 1 + "" || null
  );

  const getFormattedListOfTransportation = transportationList => {
    const options = [];
    transportationList?.map(transport => {
      const option = {};
      option.value = parseInt(transport?.id, 10) + 1 + "";
      option.label = transport?.name;
      options.push(option);
    });
    return options;
  };

  const formattedTransportationListOptions = useMemo(() => {
    return getFormattedListOfTransportation(props?.transportationList);
  }, [props?.transportationList]);

  const onSelect = cxEvent => {
    props.onUpdate({
      transportation: { start: { transportType: cxEvent.target.value - 1 } }
    });
    setTransportation(cxEvent.target.value);
  };

  return (
    <div className="change-transportation-container">
      <RadioButtonList
        displayLabel={false}
        inline
        htmlId="radioButtonListHiddenLabel"
        name="radioButtonListHiddenLabel"
        label="Label"
        onChange={onSelect}
        value={transportation}
        options={formattedTransportationListOptions}
      />
    </div>
  );
};

export default Transportation;

Transportation.propTypes = {
  selectedTransport: PropTypes.object,
  onUpdate: PropTypes.func,
  transportationList: PropTypes.array
};

Transportation.defaultProps = {
  selectedTransport: {},
  onUpdate: () => {},
  transportationList: []
};
