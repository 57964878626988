import React from "react";
import ServiceTypesBoard from "./boards/service-types-board.component";
import ServiceTypesHeader from "./headers/service-types-header.component";

// eslint-disable-next-line unused-imports/no-unused-imports
import * as CommonTypes from "../../../../../@types/common.types";
// eslint-disable-next-line unused-imports/no-unused-imports
import * as ModelsTypes from "../../../../../@types/models.types";

/**
 * @description "Service Types" section. Renders section header and services breakdown.
 * It should be kept as a dummy component. Any logic or fetching from context should be done in the section container.
 * @param {{AllServicesTypes: ModelsTypes.AllServiceTypes, localeStrings: CommonTypes.LocaleStrings}} props
 * @returns {CommonTypes.Component}
 */

const ServiceTypesComponent = props => {
  const { localeStrings, servicesTotals } = props;
  const { serviceTypesLabels, serviceTypesHeader, serviceTypesTooltipMessage } =
    localeStrings;
  return (
    <div className="section__wrapper">
      <div className="header">
        <ServiceTypesHeader
          header={serviceTypesHeader}
          tooltipMessage={serviceTypesTooltipMessage}
        />
      </div>
      <div className="quotes-and-trends quotes-and-trends__wrapper">
        <ServiceTypesBoard
          labels={/** @type {string[]} */ (serviceTypesLabels)}
          servicesTotals={servicesTotals}
        />
      </div>
    </div>
  );
};

export default ServiceTypesComponent;
