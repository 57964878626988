import moment from "moment";

/* eslint-disable no-console */
export const getStoreHoursRefData = () => {
  const storeHourOptions = {};
  for (let minutes = 0; minutes < 1440; minutes += 5) {
    const ampm = minutes < 720 ? "AM" : "PM";
    let min = minutes % 60;
    const hour = (minutes - min) / 60;
    let hr = hour === 0 ? "12" : ampm === "PM" ? hour % 12 : hour;
    if (hr < 10) {
      hr = "0" + hr;
    }
    if (min < 10) {
      min = "0" + min;
    }
    storeHourOptions[minutes] = `${hr}:${min} ${ampm}`;
  }
  // const storeHourOptions = [{0: "12:00 AM"}, {5: "12:05 AM"}, {10: "12:10 AM"}];
  // console.log("getStoreHoursRefData", storeHourOptions);
  return storeHourOptions;
};
/* This compares only Dates when date strings passed in this format (mm/dd/yyyy)
 */
/**
 *
 *
 * @function dateComparator
 * @param {date} date1
 * @param {date} date2
 * @return {boolean}
 */
export function dateComparator(date1, date2) {
  const date1Number = monthToComparableNumber(date1);
  const date2Number = monthToComparableNumber(date2);
  if (date1Number === null && date2Number === null) {
    return 0;
  }
  if (date1Number === null) {
    return -1;
  }
  if (date2Number === null) {
    return 1;
  }
  return date1Number - date2Number;
}

function monthToComparableNumber(date) {
  if (date === undefined || date === null || date.length !== 10) {
    return null;
  }
  const monthNumber = date.substring(0, 2);
  const dayNumber = date.substring(3, 5);
  const yearNumber = date.substring(6, 10);
  const result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
  return result;
}

/* This compares datetime strings and used as sort comparators
 */
export function datetimeComparator(dateStr1, dateStr2) {
  const date1 = !dateStr1 ? null : new Date(dateStr1);
  const date2 = !dateStr2 ? null : new Date(dateStr2);

  if (!date1) {
    return -1;
  }
  if (!date2) {
    return 1;
  }
  if (date1.getTime() === date2.getTime()) {
    return 0;
  }
  if (date2 < date1) {
    return -1;
  }
  if (date2 > date1) {
    return 1;
  }
}
/* Util return patterns for Date & Time based on country locale  */
export function getDatePattern(locale) {
  let pattern = "DD-MM-YYYY";
  if (locale === "en_US") {
    pattern = "MM-DD-YYYY";
  } else if (locale === "en_US_short") {
    pattern = "M-D-YY";
  } else if (locale === "en_CA" || locale === "fr_CA") {
    pattern = "YYYY/MM/DD";
  } else if (locale === "en_CA_short" || locale === "fr_CA_short") {
    pattern = "YY-M-D";
  } else if (locale === "en_AU") {
    pattern = "DD/MM/YYYY";
  } else if (locale === "en_AU_short") {
    pattern = "D-M-YY";
  } else if (locale === "quotes") {
    pattern = "YYYY-MM-DDTHH:mm:ssZZ";
  } else if (locale === "vehicle") {
    pattern = "YYYY-MM-DD";
  }
  return pattern;
}
export function getTimePattern(locale) {
  let pattern = "h:mma";
  if (locale === "en_US" || locale === "en_AU") {
    pattern = "HH:mm";
  } else if (locale === "en_CA" || locale === "fr_CA") {
    pattern = "H:mm";
  }
  return pattern;
}
/* This method returns format date with shortcut timezone using momentjs
  @params dateStr {string}
  @params format {string}
  @params wantTimezone {boolean} true - append shortend timezone
  eg: Date format based on locale
*/
export function formatDateTimezone(dateStr, wantTimezone, locale) {
  if (!dateStr) {
    return "";
  }
  let convertedValue = "";
  if (dateStr) {
    const pattern = getDatePattern(locale);
    let dateVal = new Date().toISOString(); // default, today date
    if (wantTimezone) {
      dateVal = moment(dateStr).format(pattern);
      const timePattern = getTimePattern(locale);
      const time = moment(dateStr).format(timePattern);
      const timezone = formatTimezone(dateStr);
      convertedValue = dateVal + " " + time + " " + timezone;
    } else {
      const isoDateStr = new Date(dateStr).toISOString();
      dateVal = moment(isoDateStr).format(pattern);
      convertedValue = dateVal;
    }
  }
  return convertedValue;
}

/**
 * @description Formats date and time (without including the timezone) as a custom message as needed for the Date Created column cells in the past quote grid
 * @param {string} dateStr
 * @param {string} locale
 * @param {boolean} ampm Whether or not to use AM/PM format "on MM/DD/YYYY at hh:mm AM/PM". By default, it is false.
 * @returns {string} dateAndTime
 */
export function formatDateAndTime(dateStr, locale, ampm = false) {
  if (!dateStr) {
    return "- -";
  }
  if (ampm) {
    const dateVal = moment(dateStr).format("[on] MM/DD/YYYY [at] hh:mm A");
    return dateVal;
  } else {
    const pattern = getDatePattern(locale);
    // let dateVal = new Date().toISOString(); // default, today date
    const dateVal = moment(dateStr).format(pattern);
    const timePattern = getTimePattern(locale);
    const time = moment(dateStr).format(timePattern);
    const dateAndTime = dateVal + " at " + time;
    return dateAndTime;
  }
}

/* Method returns shortcut form of Timezone
eg: CST, EST, PDT
*/
export function formatTimezone(dateStr) {
  // const zone = "Central Daylight Time";
  let timezone = "";
  let localDate = new Date();
  if (dateStr) {
    localDate = new Date(dateStr);
    localDate = localDate.toString();
  }
  const startIndex = localDate.indexOf("(");
  const endIndex = localDate.indexOf(")");
  const zone = localDate.substring(startIndex + 1, endIndex);
  const words = zone.split(" ");
  words.forEach(function (word) {
    timezone = timezone + word.charAt(0);
  });
  return timezone;
}
/* This method returns Formatted date string with full timezone
  @params dateStr {string}
  @params format {string}
  eg: June 15, 2007 at 2:05am Central Daylight Time
*/
export function getFormattedDateTime(dateStr, format) {
  let convertedValue = "";
  let pattern = "MMMM D, YYYY";
  if (!dateStr) {
    return "";
  }
  if (dateStr) {
    // const dobj = moment(dateStr).toObject();
    if (format) {
      pattern = format;
    }
    let localDate = new Date(dateStr);
    localDate = localDate.toString();
    const startIndex = localDate.indexOf("(");
    const endIndex = localDate.indexOf(")");
    const zone = localDate.substr(startIndex, endIndex);
    const dateVal = moment(dateStr).format(pattern);
    const time = moment(dateStr).format("h:mma");
    convertedValue = dateVal + " at " + time + " " + zone;
  }
  return convertedValue;
}

export function formatSpecialDate(initialDate, locale) {
  const splitDate = initialDate.split(" ");
  const date = splitDate[0].split("-");
  const time = splitDate[1].split(":");

  // get all date parts
  const dd = date[0];
  const mm = date[1] - 1;
  const yyyy = date[2];
  const hh = time[0];
  const min = time[1];
  const ss = time[2];

  const pattern = getDatePattern(locale);
  // new date
  let convertedValue = new Date(yyyy, mm, dd, hh, min, ss).toISOString();
  convertedValue = moment(convertedValue).format(pattern);
  return convertedValue + " " + hh + ":" + min + ":" + ss;
}
