// @ts-check
import React from "react";
import DashboardWrapper from "./views/layout/dashboard-wrapper.component";
import DashboardColumn from "./views/layout/dashboard-column.component";
import QuotesSectionContainer from "./views/sections/quotes-section.container";
import PotentialValueSectionContainer from "./views/sections/potential-value-section.container";
import ServiceTypesContainer from "./views/sections/service-types-section.container";

// eslint-disable-next-line unused-imports/no-unused-imports
import * as CommonTypes from "../../../@types/common.types";
import "./dashboard.scss";

/**
 * @description Component where layout and section components are ultimately rendered.
 * All direct sub-components of Dashboard should be added here to Dashboard as properties.
 * @param {{children: any}} props
 * @returns {CommonTypes.Component}
 */
const Dashboard = props => {
  const { children } = props;
  return <div className="dashboard">{children}</div>;
};

// Include all sub-components to be used inside Dashboard so we only need to export Dashboard itself
Dashboard.Wrapper = DashboardWrapper;
Dashboard.Column = DashboardColumn;
Dashboard.QuotesSection = QuotesSectionContainer;
Dashboard.PotentialValue = PotentialValueSectionContainer;
Dashboard.ServiceTypes = ServiceTypesContainer;

export default Dashboard;
