let translations = null;
const DEFAULT_LOCALE = "en_US";
export function xlate(msgKey, defaultMsg) {
  if (isTranslationsSet()) {
    const msg = translations[msgKey];
    return msg ? msg : `*${msgKey}*`;
  }
  handleFatalApplicationAccess({
    messageKey: msgKey,
    defaultMessage: defaultMsg
  });
}
export let locale = DEFAULT_LOCALE;
export const setTranslations = (localeStrings, localeString) => {
  translations = localeStrings;
  locale = localeString;
};
function isTranslationsSet() {
  return translations !== null;
}
export function handleFatalApplicationAccess(detail) {
  window.dispatchEvent(
    new CustomEvent("fatalApplicationAccess", {
      detail,
      bubbles: true,
      cancelable: true
    })
  );
}

export const SupportedLocales = ["en_US", "en_CA", "fr_CA", "en_AU"];
