import React, { useEffect, useState, useContext } from "react";
import "./service-log.scss";
import repairOrdersService from "../../services/repair-orders.service";
import { AppContext } from "../../../../state/app-context";
import { loadQuote } from "../../../page-wrapper/services/quote-api.service";
import LoadingIndicator from "@cx/ui/LoadingIndicator";
import { Header, CustomerVehicleDetails, EventLog } from "./components";

const ServiceLog = () => {
  const appContext = useContext(AppContext);
  const { localeStrings, dealer } = appContext;
  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [csr, setCSR] = useState({});

  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop)
  });
  const roNumber = params.roNumber;

  useEffect(() => {
    getActivityLogList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function getActivityLogList() {
    const quoteId = params.quoteId;
    const dealerCode = dealer.dealerCode;
    try {
      const data = await loadQuote({
        confirmationId: quoteId,
        localeStrings,
        dealerCode,
        appContext
      });
      setCSR(data);
      const response = await repairOrdersService.getActivityLog(
        dealer,
        quoteId
      );

      response?.content?.sort((a, b) => {
        const timestampA = new Date(a.timestamp);
        const timestampB = new Date(b.timestamp);
        return timestampB - timestampA;
      });
      const formattedLogs = response?.content?.map(log => {
        return {
          ...log,
          user: log?.user
            ? `${log?.user?.lastName}, ${log?.user?.firstName}`
            : "--"
        };
      });
      setLogs(formattedLogs);
      setLoading(false);
    } catch {
      setCSR(null);
      setLogs(null);
      setLoading(false);
    }
  }

  const { customer, vehicle, mileageIn, mileageOut } = csr ? csr : {};

  return loading ? (
    <LoadingIndicator htmlId="maskSpinner" size="large" color="gray" />
  ) : csr ? (
    <div className="activity-log-container">
      <Header roNumber={roNumber} />
      <CustomerVehicleDetails
        customer={customer}
        vehicle={vehicle}
        dealerCountryCode={dealer?.dealerCountryCode}
        mileageIn={mileageIn}
        mileageOut={mileageOut}
      />
      <EventLog roNumber={roNumber} logs={logs} />
    </div>
  ) : null;
};

export default ServiceLog;
