import isEmpty from "lodash/isEmpty";
import { Actions } from "../state/edit-service.context";
import {
  getAdvisorTechnicianList,
  getExpenseCodeForWarranty
} from "./advisors.service";
import { operationSources } from "../utils/edit-service.constants";
import { isValidAttr } from "../utils/service.util";
import priceCalculationUtil from "../utils/service-calculation.util";
import globalOperationsService from "../../features/page-wrapper/services/global-operations.service";

/**
 * Load advisor technician list and update state.
 * @param {function} dispatch - The dispatch function from the context API.
 * @param {string} dealerCode - The dealer code.
 * @param {string} schemaName - The schema name.
 * @param {string} localeStrings - The locale strings.
 */
const loadAdvisorTechnicianList = async (
  dispatch,
  dealerCode,
  schemaName,
  localeStrings
) => {
  try {
    const advisorTechnicianList = await getAdvisorTechnicianList({
      dealerCode,
      schemaName,
      localeStrings
    });
    dispatch({
      type: Actions.SET_TECHNICIAN_LIST,
      payload: advisorTechnicianList.technicians
    });
  } catch (e) {
    console.error("Unable to fetch technician list", e);
    dispatch({
      type: Actions.SET_TECHNICIAN_LIST,
      payload: { technicians: [] }
    });
  }
};

/**
 * Load expense code for warranty and update state.
 * @param {function} dispatch - The dispatch function from the context API.
 * @param {string} dealerCode - The dealer code.
 * @param {string} make - The make of the vehicle.
 * @param {string} countryCode - The country code.
 */
const loadExpenseCodeForWarranty = async (
  dispatch,
  dealerCode,
  make,
  countryCode
) => {
  try {
    const expenseCodeListForWarranty = await getExpenseCodeForWarranty({
      dealerCode,
      make,
      countryCode
    });
    dispatch({
      type: Actions.SET_EXPENSE_CODE_LIST_FOR_WARRANTY_LIST,
      payload: expenseCodeListForWarranty
    });
  } catch (e) {
    console.error("Unable to fetch expense codes list", e);
    dispatch({
      type: Actions.SET_EXPENSE_CODE_LIST_FOR_WARRANTY_LIST,
      payload: {}
    });
  }
};

/**
 * Handle global catalog operation and log a warning if laborApp is missing.
 * @param {function} writeLogEntry
 * @param {object} rawOperationDetails - Raw details of the operation.
 * @param {string} laborApp - The labor application.
 * @param {string} make - make.
 */
const handleGlobalCatalogOperation = (
  writeLogEntry,
  rawOperationDetails,
  laborApp,
  make
) => {
  if (isEmpty(laborApp)) {
    writeLogEntry("warn", {
      event: "GlobalCatalog operation missing laborApp",
      make,
      operationSource: rawOperationDetails?.operationSource,
      operationId: rawOperationDetails?.operationId,
      operationName: rawOperationDetails?.name
    });
  }
};

/**
 * Handle prop service logic and update context.service after applying calculated logic - MENU, Dealer Publish, Recall, Declined
 * @param {function} dispatch - The dispatch function from the context API.
 * @param {object} rawOperationDetails - Raw details of the operation.
 * @param {object} propService - The prop service object.
 * @param {object} payTypes - The pay types object.
 */
const handlePropService = (
  dispatch,
  rawOperationDetails,
  propService,
  payTypes
) => {
  if (
    rawOperationDetails.operationSource.toUpperCase() === operationSources.MENU
  ) {
    console.log("Pre-apply PRICING for Menu>propService", propService);
    dispatch({ type: Actions.SET_SERVICE, payload: propService });
    dispatch({ type: Actions.SET_ORIGINAL_SERVICE, payload: propService });
  } else {
    const serviceWithPaytypeCode =
      priceCalculationUtil.updateServiceWithPayTypeValues(
        propService,
        payTypes,
        rawOperationDetails
      );
    const updatedService = priceCalculationUtil.initCalculationForService(
      serviceWithPaytypeCode
    );
    console.log("APPLY PRICING for Regular Service", updatedService);
    dispatch({ type: Actions.SET_SERVICE, payload: updatedService });
    // This state {originalService} used to compare total parts, total parts price with changes in parts component
    dispatch({ type: Actions.SET_ORIGINAL_SERVICE, payload: updatedService });
  }
};

/**
 * Validate attributes for global repair service and update state.
 * @param {function} dispatch - The dispatch function from the context API.
 * @param {object} stateService - The state service object.
 * @param {object} rawOperationDetails - Raw details of the operation.
 */
const validateGlobalCatalogAttributes = (
  dispatch,
  stateService,
  rawOperationDetails
) => {
  dispatch({
    type: Actions.SET_ERRORS,
    payload: {
      field: "vehicleAttributes",
      value: isValidAttr(rawOperationDetails?.selectableVehicleAttributes)
    }
  });
  dispatch({
    type: Actions.SET_ERRORS,
    payload: {
      field: "serviceOptions",
      value: isValidAttr(rawOperationDetails?.laborApps)
    }
  });
  const commentsRequiredExist = stateService?.commentsRequired || false;
  if (commentsRequiredExist) {
    dispatch({
      type: Actions.SET_ERRORS,
      payload: { field: "serviceNotes", value: true }
    });
  }
};

/**
 * Handle menu operation and update state.
 * @param {object} currentEditingService - The currentEditingService service object.
 * @param {object} stateService - The state service object.
 * @param {object} propService - The prop service object.
 * @param {function} onChangePaytype
 */
const handleMenuOperation = (
  currentEditingService,
  stateService,
  propService
  // onChangePaytype
) => {
  // eslint-disable-next-line unused-imports/no-unused-vars
  const payTypeCodeLocal = isEmpty(currentEditingService)
    ? propService.payTypeCode
    : stateService.payTypeCode;

  // TODO: Why we are not calling for MENU case?
  // Function call commented because is generating a duplicated API call when editing services from menus
  // onChangePaytype(payTypeCodeLocal);
};

/**
 * Validate attributes based on operation source and update state.
 * @param {function} dispatch - The dispatch function from the context API.
 * @param {object} currentEditingService - The currentEditingService service object
 * @param {object} stateService - The state service object.
 * @param {object} rawOperationDetails - Raw details of the operation.
 */
const validateAttributes = (
  dispatch,
  currentEditingService,
  stateService,
  rawOperationDetails
) => {
  if (
    isEmpty(currentEditingService) &&
    rawOperationDetails.operationSource === operationSources.GLOBALCATALOG &&
    !isEmpty(rawOperationDetails)
  ) {
    dispatch({
      type: Actions.SET_ERRORS,
      payload: { field: "vehicleAttributes", value: true }
    });
    dispatch({
      type: Actions.SET_ERRORS,
      payload: { field: "serviceOptions", value: true }
    });
  }
  const commentsRequiredExist = stateService?.commentsRequired || false;
  if (commentsRequiredExist) {
    dispatch({
      type: Actions.SET_ERRORS,
      payload: { field: "serviceNotes", value: true }
    });
  }
};

const loadCatalogFeesForService = async (
  dispatch,
  currentEditingService,
  vehicle
) => {
  const params = {
    operationSource: currentEditingService?.operationSource,
    operationId: currentEditingService?.extServiceId
  };
  try {
    const globalOperationDetails =
      await globalOperationsService.getGlobalOperationDetails(vehicle, params);
    console.log(
      "payTypeChange ",
      globalOperationDetails,
      currentEditingService
    );
    dispatch({
      type: Actions.SET_ALL_CATALOG_FEES_DISCOUNTS,
      payload: globalOperationDetails?.discountsAndFees
    });
  } catch (e) {
    console.error("Unable to fetch discounts and fees list", e);
    dispatch({
      type: Actions.SET_ALL_CATALOG_FEES_DISCOUNTS,
      payload: null
    });
  }
};

export {
  loadAdvisorTechnicianList,
  loadExpenseCodeForWarranty,
  handleGlobalCatalogOperation,
  handlePropService,
  validateGlobalCatalogAttributes,
  handleMenuOperation,
  validateAttributes,
  loadCatalogFeesForService
};
