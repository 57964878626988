import React from "react";
import IconArrowUpward from "@cx/ui/Icons/IconArrowUpward";
import {
  useServiceSearchContext,
  Actions
} from "../../state/service-search.context";
import modalPages from "../../constants/pages.constants";

import "./CustomerOperationPage.scss";
// @todo: use this file to add Tellus more in ServiceSearchModal/pages
const CustomOperationPage = props => {
  const { EditServiceModule } = props;
  const { dispatch } = useServiceSearchContext();
  const backToServiceSearch = () => {
    dispatch({
      type: Actions.SET_CURRENT_PAGE,
      payload: modalPages.SEARCH_SERVICES
    });
  };

  return (
    <div id="customerOperationPageContainer">
      <span
        className="back-nav-label search-back-sticky"
        onClick={backToServiceSearch}
      >
        <IconArrowUpward
          htmlId="backArrowIcon"
          isActive={false}
          className="back-arrow"
        />
        Back to search services
      </span>
      {/* <span>{state.currentPage}</span> */}

      {EditServiceModule}
    </div>
  );
};

export default CustomOperationPage;
