import React, { useState } from "react";
import TotalRow from "./total-row.component";
import CurrencyPriceInput from "./currency-price-input.component";
import util from "../../../utils/util";
import parse from "../../../utils/parse";
import { stripSubletPrefix } from "../utils/sublet.util";
import { splitPoPricingProps } from "../sublet.types";
import { SPLIT_SUBLET_DEFAULT } from "../../../constants/adjustment.constant";
import PropTypes from "prop-types";

const SplitCostOn = ({
  splitPricing = SPLIT_SUBLET_DEFAULT.poPricing,
  onUpdate = () => {}
}) => {
  const { laborCost, laborPrice, partsCost, partsPrice } = splitPricing;
  const [newLaborCost, setNewLaborCost] = useState(laborCost);
  const [newLaborPrice, setNewLaborPrice] = useState(laborPrice);
  const [newPartsCost, setNewPartsCost] = useState(partsCost);
  const [newPartsPrice, setNewPartsPrice] = useState(partsPrice);

  const totalSublet =
    (parseFloat(newLaborPrice) || 0) + (parseFloat(newPartsPrice) || 0);

  const handleUpdateLaborCost = event => {
    const numericValue = parse.currency(event.target.value, 0);
    setNewLaborCost(numericValue);
    onUpdate(getSubletPayload(event.target.name, numericValue));
  };

  const handleUpdatePartsCost = event => {
    const numericValue = parse.currency(event.target.value, 0);
    setNewPartsCost(numericValue);
    onUpdate(getSubletPayload(event.target.name, numericValue));
  };

  const getSubletPayload = (name, value) => {
    return { [stripSubletPrefix(name)]: value };
  };

  const handleUpdateLaborPrice = event => {
    const numericValue = parse.currency(event.target.value, 0);
    setNewLaborPrice(numericValue);
    onUpdate({
      ...getSubletPayload(event.target.name, numericValue),
      subletTotalCost: numericValue + partsPrice
    });
  };

  const handleUpdatePartsPrice = event => {
    const numericValue = parse.currency(event.target.value, 0);
    setNewPartsPrice(numericValue);
    onUpdate({
      ...getSubletPayload(event.target.name, numericValue),
      subletTotalCost: numericValue + laborPrice
    });
  };

  return (
    <>
      <div className="pricing-row">
        <CurrencyPriceInput
          value={newLaborCost}
          htmlId="subletLaborCost"
          name="subletLaborCost"
          label="Labor cost"
          onUpdate={handleUpdateLaborCost}
        />
        <CurrencyPriceInput
          value={newPartsCost}
          htmlId="subletPartsCost"
          name="subletPartsCost"
          label="Parts cost"
          onUpdate={handleUpdatePartsCost}
        />
      </div>
      <div className="pricing-row">
        <CurrencyPriceInput
          value={newLaborPrice}
          htmlId="subletLaborPrice"
          error={
            util.isNumber(laborPrice) && laborPrice < laborCost
              ? "Price is below cost"
              : undefined
          }
          name="subletLaborPrice"
          label="Labor price"
          onUpdate={handleUpdateLaborPrice}
        />
        <CurrencyPriceInput
          value={newPartsPrice}
          htmlId="subletPartsPrice"
          error={
            util.isNumber(partsPrice) && partsPrice < partsCost
              ? "Price is below cost"
              : undefined
          }
          name="subletPartsPrice"
          label="Parts price"
          onUpdate={handleUpdatePartsPrice}
        />
      </div>
      <TotalRow label="Labor price" value={laborPrice} />
      <TotalRow label="Parts price" value={partsPrice} />
      <div className="separator" />
      <div className="separator" />
      <TotalRow
        label="Total sublet"
        value={totalSublet}
        priceClassName="total-amount"
      />
    </>
  );
};

SplitCostOn.propTypes = {
  splitPricing: splitPoPricingProps,
  onUpdate: PropTypes.func.isRequired
};

export default SplitCostOn;
