import React from "react";
import PropTypes from "prop-types";
import AdjustmentInformationHeader from "./components/adjustment-information-header.component";
import AdjustmentInformationRow from "./components/adjustment-information-row.component";
import { calculateTotal } from "./utils";
import { subletPropType } from "./types/propTypes";
import { appTypes } from "../../../../constants/app.constants";

const SubletsInformation = ({
  data,
  showDetails,
  canModify,
  onModify,
  appType,
  containerClassName
}) => {
  return (
    <div className={["adjustment-information", containerClassName].join(" ")}>
      <AdjustmentInformationHeader
        title="Sublets"
        count={data.length}
        total={calculateTotal(data)}
      />
      {showDetails
        ? data.map(item => {
            const description =
              item.vendor + (item.poNumber ? ` - PO #${item.poNumber}` : "");

            return (
              <AdjustmentInformationRow
                key={item.subletId}
                label="Sublet"
                description={description}
                total={item.poPricing.subletTotalCost}
                appType={appType}
                canModify={canModify}
                onModify={() => onModify(item)}
              />
            );
          })
        : null}
    </div>
  );
};

export default SubletsInformation;

SubletsInformation.defaultProps = {
  data: [],
  showDetails: true,
  canModify: true
};

SubletsInformation.propTypes = {
  data: PropTypes.arrayOf(subletPropType),
  showDetails: PropTypes.bool,
  canModify: PropTypes.bool,
  onModify: PropTypes.func,
  containerClassName: PropTypes.string,
  appType: PropTypes.oneOf([appTypes.SQ, appTypes.CSR])
};
