import * as Yup from "yup";

import vehicleSchema from "./vehicle.schema";

// schema used to fill null values with empty strings so we will stop doing obj.value || "" in our UI
const customerPhoneNumbers = Yup.object().shape({
  work: Yup.string()
    .default("")
    .transform(value => (value === null ? "" : value)),
  mobile: Yup.string()
    .default("")
    .transform(value => (value === null ? "" : value)),
  home: Yup.string()
    .default("")
    .transform(value => (value === null ? "" : value))
});

// schema used to fill null values with empty strings so we will stop doing obj.value || "" in our UI
const customerAddress = Yup.object().shape({
  city: Yup.string()
    .default("")
    .transform(value => (value === null ? "" : value)),
  state: Yup.string()
    .default("")
    .transform(value => (value === null ? "" : value)),
  streetAddressOne: Yup.string()
    .default("")
    .transform(value => (value === null ? "" : value)),
  streetAddressTwo: Yup.string()
    .default("")
    .transform(value => (value === null ? "" : value))
});

const customerInForm = Yup.object().shape({
  firstName: Yup.string()
    .default("")
    .transform(value => (value === null ? "" : value)),
  lastName: Yup.string()
    .default("")
    .transform(value => (value === null ? "" : value)),
  email: Yup.string()
    .email()
    .default("")
    .transform(value => (value === null ? "" : value)),
  contactInfo: Yup.object({
    phoneNumbers: customerPhoneNumbers,
    address: customerAddress
  })
});

// we use this schema to validate and fill null values from API
const customerInState = customerInForm.shape({
  personId: Yup.number(),
  commonConsumerId: Yup.string().transform(value =>
    value === null ? "" : value
  ),
  lifecycleState: Yup.boolean(),
  vehicles: Yup.array(vehicleSchema.vehicle)
});

const customerInSearch = Yup.object().shape({
  firstName: Yup.string()
    .default("")
    .transform(value => (value === null ? "" : value)),
  lastName: Yup.string()
    .default("")
    .transform(value => (value === null ? "" : value)),
  email: Yup.string()
    .email()
    .default("")
    .transform(value => (value === null ? "" : value)),
  phoneNumbers: customerPhoneNumbers,
  address: customerAddress
});

// we use this schema to validate and fill null values from API
const customerInSearchState = customerInSearch.shape({
  personId: Yup.number(),
  lifecycleState: Yup.boolean(),
  vehicles: Yup.array(vehicleSchema.vehicle)
});

const customerInPayload = Yup.object().shape({
  personId: Yup.number(),
  firstName: Yup.string()
    .trim()
    .required()
    .length(64)
    .default("")
    .transform(value => (value === null ? "" : value)),
  ccId: Yup.string().nullable(),
  lastName: Yup.string()
    .trim()
    .required()
    .length(64)
    .default("")
    .transform(value => (value === null ? "" : value)),
  lifecycleState: Yup.boolean().default(true).required(),
  email: Yup.string()
    .email()
    .required()
    .default("")
    .transform(value => (value === null ? "" : value)),
  vehicles: Yup.array(),
  contactInfo: Yup.object({
    phoneNumbers: Yup.object({
      HOME: Yup.string()
        .default("")
        .transform(value => (value === null ? "" : value)),
      WORK: Yup.string()
        .default("")
        .transform(value => (value === null ? "" : value)),
      MOBILE: Yup.string()
        .default("")
        .transform(value => (value === null ? "" : value))
    }),
    address: Yup.object()
  })
});
// This customer schema used in create/update quote payload
const quoteCustomerSchema = Yup.object().shape({
  quoteCustomerId: Yup.number(),
  personId: Yup.number(),
  firstName: Yup.string()
    .trim()
    .required()
    .length(64)
    .default("")
    .transform(value => (value === null ? "" : value)),
  lastName: Yup.string()
    .trim()
    .required()
    .length(64)
    .default("")
    .transform(value => (value === null ? "" : value)),
  email: Yup.string()
    .email()
    .required()
    .default("")
    .transform(value => (value === null ? "" : value)),
  commonConsumerId: Yup.string().nullable(),
  schmea: Yup.string()
    .required()
    .default("")
    .transform(value => (value === null ? "" : value)),
  lifecycleState: Yup.boolean().default(true),
  contactInfo: Yup.object().nullable(),
  customerPrivacy: Yup.object().nullable()
});
export default {
  customer: customerInPayload,
  customerInState,
  customerInForm,
  customerAddress,
  customerInSearchState,
  customerInSearch,
  quoteCustomerSchema
};
