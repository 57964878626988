/* eslint-disable react/jsx-key */
/* eslint-disable no-console */
import React from "react";
import PropTypes from "prop-types";
import CustomLoadingOverlay from "./reusable/loadingmask/CustomLoadingOverlay";
import { priceFormatter, toEmptyStringIfUndefined } from "./utils/helper";
import NumericEditor from "./editors/NumericEditor";
import { AgGridReact } from "ag-grid-react";
import IconInfoOutline from "@cx/ui/Icons/IconInfoOutline";
import IconWarning from "@cx/ui/Icons/IconWarning";
import Badge from "@cx/ui/Badge";
import Popover from "@cx/ui/Popover";
import Tooltip from "@cx/ui/Tooltip";
import LoadingIndicator from "@cx/ui/LoadingIndicator";
import SimpleTable from "@cx/ui/SimpleTable";

class PartsGrid extends React.Component {
  // static contextType = PreviewContext;
  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps.rowData !== prevState.rowData ||
      nextProps.additionalColumns !== prevState.additionalColumns
    ) {
      const {
        disableAllParts,
        rowData,
        selectedParts,
        naNrNssParts,
        additionalColumns,
        includeInspectColumns,
        fluidHasQualifiers
      } = nextProps;
      const fluidsAndParts =
        prevState.parentHandle.getRowDataWithSupersessionHistory(rowData);
      const columnDefs = prevState.getColumnList(
        fluidsAndParts,
        disableAllParts,
        additionalColumns,
        prevState.parentHandle,
        fluidHasQualifiers,
        includeInspectColumns
      );
      setTimeout(() => {
        // prevState.adjustGridColumns();
        prevState.parentHandle.preselectRow();
      }, 200);
      return {
        disableAllParts,
        rowData: fluidsAndParts,
        selectedParts,
        selectionlist: selectedParts,
        naNrNssParts,
        additionalColumns,
        columnDefs
      };
    }
    return null;
  }
  constructor(props, context) {
    super(props, context);
    this.onGridReady = this.onGridReady.bind(this);
    this.onFilterChanged = this.onFilterChanged.bind(this);
    this.handleSelectionChanged = this.handleSelectionChanged.bind(this);
    this.adjustGridColumns = this.adjustGridColumns.bind(this);
    this.sizeToFit = this.sizeToFit.bind(this);
    this.setAutoHeight = this.setAutoHeight.bind(this);
    this.handleGridSizeChanged = this.handleGridSizeChanged.bind(this);

    const {
      disableAllParts,
      rowData,
      naNrNssParts,
      additionalColumns,
      selectedParts
    } = props;

    const fluidAndParts = this.getRowDataWithSupersessionHistory(rowData);
    const gridOptions = {
      disableAllParts,
      // other state props
      pageTitle: "Parts",
      // ag-grid props
      rowData: fluidAndParts,
      naNrNssParts,
      additionalColumns: additionalColumns ? additionalColumns : [],
      selectionlist: selectedParts,
      columnDefs: this.getColumnList(
        fluidAndParts,
        disableAllParts,
        additionalColumns,
        this,
        props.fluidHasQualifiers,
        props.includeInspectColumns
      ),
      defaultColDef: {
        // cellClass: "xmm-wrap-cell",
        sortable: true,
        resizable: true,
        editable: false, // default disable editor
        enableRowGroup: false,
        sortingOrder: ["asc", "desc", null],
        filter: "agTextColumnFilter",
        filterParams: {
          suppressMiniFilter: false,
          buttons: ["clear"]
        },
        menuTabs: ["filterMenuTab"],
        headerComponentParams: {
          template: `
          <div class="ag-cell-label-container" role="presentation">
            <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>
            <div ref="eLabel" class="ag-header-cell-label" role="presentation">
              <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
              <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>
              <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span>
              <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span>
              <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon" ></span>
            </div>
          </div>
          `
        },
        // suppressKeyboardEvent: applyCustomKeyNavigation,
        floatingFilter: false, // true - enable column header filters
        suppressMenu: true, // disabled menu filters
        rowGroup: false
      },
      multiSortKey: "ctrl",
      frameworkComponents: {
        customLoadingOverlay: CustomLoadingOverlay,
        numericEditor: NumericEditor
      },
      loadingOverlayComponent: "customLoadingOverlay",
      loadingOverlayComponentParams: {
        loadingMessage: "Loading",
        isLoading: true,
        noRows: false
      },

      noRowsOverlayComponent: "customLoadingOverlay",
      noRowsOverlayComponentParams: {
        loadingMessage: "No parts found.",
        isLoading: false,
        noRows: true
      },

      // true - use browser default tooltip instead of ag-grid tooltip
      enableBrowserTooltips: true,
      rowSelection: "multiple", // allows multiple row selections with check column
      // eslint-disable-next-line unused-imports/no-unused-vars
      isRowSelectable(rowNode) {
        return true; // to see checkbox
      },
      columnTypes: {
        nonEditableColumn: { editable: false },
        numberColumn: {
          width: 160,
          filter: "agNumberColumnFilter"
        },
        // priceColumn: {
        //   editable: false,
        //   valueFormatter: priceFormatter,
        //   filter: "agNumberColumnFilter",
        //   cellClass: "xmm-wrap-cell xmm-grid-price",
        //   cellStyle: {
        //     color: "black",
        //     textAlign: "left"
        //   }
        // },
        actionColumn: {
          filter: false,
          editable: false,
          sortable: false,
          suppressMenu: true,
          enableRowGroup: false
        }
      },
      onColumnMoved: this.refreshGrid,
      onColumnPinned: this.refreshGrid,
      domLayout: "autoHeight",
      // popupParent: document.body,
      sideBar: false,
      getColumnList: this.getColumnList,
      adjustGridColumns: this.adjustGridColumns,
      parentHandle: this
    };
    this.state = gridOptions;
  }

  getColumnList(
    fluidsAndParts,
    disableAllParts,
    additionalColumns,
    parentHandle,
    fluidHasQualifiers
  ) {
    const optionalParts = fluidsAndParts.filter(
      p => p.relationship === "Related/Optional"
    );
    const relationshipOrder = {
      Primary: "0",
      Recommended: "1",
      "Related/Optional": "2"
    };
    const optionalPartsFlag = optionalParts.length !== 0;
    const columns = [
      {
        headerName: "",
        headerCheckboxSelection: !disableAllParts,
        headerCheckboxSelectionFilteredOnly: !disableAllParts,
        checkboxSelection: params => {
          const { oemPartNumber } = params.data;
          return !ExcludedParts.includes(oemPartNumber);
        },
        pinned: "left",
        field: "checked",
        type: "actionColumn",
        cellStyle: () => {
          return disableAllParts
            ? { "pointer-events": "none", opacity: "0.4" }
            : {};
        },
        suppressSizeToFit: true,
        suppressColumnsToolPanel: true, // hide item in sidebar.columns
        maxWidth: 37,
        minWidth: 37,
        width: 37
      },
      {
        headerName: "Qty",
        headerClass: "ag-text-header",
        pinned: "left",
        field: "quantity",
        editable: !disableAllParts,
        cellClass: "d-block-cell",
        cellEditor: "numericEditor",
        cellEditorParams(params) {
          const { data } = params;
          let allowDecimals = false;
          let maxLength = -1;
          const maxValue = 100;
          if (data) {
            const { partType } = data;
            if (partType === "part") {
              maxLength = 3;
            } else {
              allowDecimals = true;
              maxLength = 4;
            }
          }
          return { parentHandle, allowDecimals, maxLength, maxValue };
        },
        cellStyle: {
          color: "black",
          textAlign: "left"
        },
        cellRendererFramework: quantityCellRenderer,
        suppressSizeToFit: true,
        type: "numberColumn",
        maxWidth: 140,
        minWidth: 120
        // width: 75
      },
      {
        headerName: "Description",
        field: "partName",
        tooltipField: "partName",
        headerClass: "ag-text-header",
        cellRendererFramework: descriptionCellRenderer,
        // maxWidth: 500,
        minWidth: 200
        // width: 280,
      },
      {
        headerName: "Part relationship",
        field: "relationship",
        hide: !optionalPartsFlag,
        valueFormatter: relFormatter,
        comparator: (value1, value2) => {
          if (!relationshipOrder[value1] || !relationshipOrder[value2]) {
            if (!relationshipOrder[value1]) {
              return -1;
            }
            return 1;
          } else {
            return relationshipOrder[value1].localeCompare(
              relationshipOrder[value2]
            );
          }
        },
        maxWidth: 150,
        minWidth: 120,
        width: 120
      },
      {
        headerName: "Part number",
        field: "oemPartNumber",
        headerClass: "ag-text-header",
        cellRendererFramework: partNumberCellRenderer,
        suppressSizeToFit: true,
        maxWidth: 200,
        minWidth: 100,
        width: 200
      },
      {
        headerName: "Price/item",
        field: "unitPrice",
        headerClass: "ag-numeric-header",
        cellClass: "d-block-cell",
        cellRendererFramework: priceCellRenderer,
        suppressSizeToFit: true,
        // type: "priceColumn",
        maxWidth: 120,
        minWidth: 120
        // width: 110
      }
    ];
    if (fluidHasQualifiers) {
      columns.push({
        headerName: "Qualifiers",
        field: "qualifiers",
        headerClass: "ag-text-header",
        tooltipField: "qualifiers",
        // suppressSizeToFit: true,
        // maxWidth: 300,
        // minWidth: 500,
        width: 350
      });
    }
    columns.push({
      headerName: "Notes",
      field: "notes",
      headerClass: "ag-text-header",
      cellRendererFramework: noteCellRenderer,
      maxWidth: 80,
      minWidth: 80,
      hide: true
    });
    if (additionalColumns && additionalColumns.length !== 0) {
      additionalColumns.forEach(column => {
        const { field, headerName } = column;
        columns.push({
          headerName,
          field,
          tooltipField: field,
          headerClass: "ag-text-header",
          filter: "agSetColumnFilter",
          // suppressSizeToFit: true
          maxWidth: 150
          // minWidth: 150
          // width: 120
        });
      });
    }
    return columns;
  }
  onGridReady = params => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    setTimeout(() => {
      this.adjustGridColumns();
      this.preselectRow();
    }, 0);
    this.gridApi.closeToolPanel();
    this.applySortConfig();
  };
  setAutoHeight = () => {
    const { domLayout } = this.state;
    const { rowData } = this.props;
    if (rowData) {
      const newDomLayout = rowData.length < 20 ? "autoHeight" : "";
      if (domLayout !== newDomLayout) {
        this.gridApi.setDomLayout(newDomLayout);
        this.setState({ domLayout: newDomLayout });
      }
    }
  };
  sizeToFit = () => {
    this.gridApi?.sizeColumnsToFit();
  };
  handleGridSizeChanged = event => {
    const { clientWidth, clientHeight } = event;
    if (clientWidth && clientHeight) {
      this.sizeToFit();
    }
  };
  /* This selection handler returns selected records from grid */
  handleSelectionChanged = () => {
    if (this.gridApi) {
      const selectedRows = this.gridApi.getSelectedRows();
      const { onSelectRow } = this.props;
      const { rowData } = this.state;
      rowData.forEach(d => {
        d.manuallySelected = selectedRows.includes(d);
      });
      onSelectRow(selectedRows);
      this.setState({ selectionlist: selectedRows });
    }
  };
  /* "filterChanged" - listen to the column filter events; can be used to  clear column filters */
  onFilterChanged = () => {
    if (this.gridApi) {
      this.clearGridSelections();
    }
  };
  /* Un-select all rows, regardless of filtering from grid */
  clearGridSelections = () => {
    if (this.gridApi) {
      this.gridApi.deselectAll();
      this.setState({ selectionlist: [] });
    }
  };
  getRowNodeId(data) {
    return data.id;
  }
  adjustGridColumns() {
    this.sizeToFit();
    this.setAutoHeight();
  }
  preselectRow() {
    const { disableAllParts, rowData, selectionlist } = this.state;

    if (rowData && !rowData.length) {
      return;
    }

    if (disableAllParts || !this.gridApi) {
      return;
    }
    if (rowData && rowData.length === 1) {
      if (rowData[0].manuallySelected) {
        this.gridApi.forEachNode(node => {
          node.setSelected(true);
        });
      }
    } else {
      if (selectionlist && selectionlist.length !== 0) {
        selectionlist.forEach(item => {
          if (item.manuallySelected) {
            const node = this.gridApi.getRowNode(item.id);
            if (node) {
              node.setSelected(true);
            }
          }
        });
      }
    }
  }
  getRowDataWithSupersessionHistory(rowData) {
    if (!rowData) {
      return null;
    }
    rowData.forEach(row => {
      const { oemPartNumber, status, supersededFluids, supersededParts } = row;
      if (!row.processedSupersededHistory) {
        row.processedSupersededHistory = true;
        const supersessionHistory = [];
        if (supersededFluids && supersededFluids.length !== 0) {
          supersessionHistory.push(...supersededFluids);
        } else if (supersededParts && supersededParts.length !== 0) {
          supersessionHistory.push(...supersededParts);
        }
        if (supersessionHistory.length !== 0) {
          supersessionHistory.splice(0, 0, { oemPartNumber, status });
        }
        row.supersessionHistory = supersessionHistory;
      }
    });
    return rowData;
  }
  applySortConfig() {
    const defaultSortModel = [
      {
        colId: "relationship",
        sort: "asc",
        sortIndex: 0
      },
      {
        colId: "partName",
        sort: "asc",
        sortIndex: 1
      }
    ];
    this.assignColumnState(defaultSortModel);
  }
  assignColumnState = defaultSortModel => {
    // this.gridColumnApi &&
    this.gridColumnApi?.applyColumnState({
      state: defaultSortModel,
      defaultState: {
        // important to say 'null' as undefined means 'do nothing'
        sort: null
      }
    });
  };
  /* This method can be called to refresh single or multi rows */
  refreshGrid(params) {
    if (params) {
      params.api.refreshCells({ force: true });
    } else {
      this.gridApi && this.gridApi.refreshCells({ force: true });
    }
  }
  renderPageTitle(rowData) {
    let pageTitle = "Parts";
    if (rowData && rowData.length > 0) {
      pageTitle = "Parts (" + rowData.length + ")";
    }
    return pageTitle;
  }
  rendreNaNrNssPopover(naNrNssParts) {
    const posts = naNrNssParts.map((post, index) => {
      let notes = [];
      if (post.notes && post.notes.length > 0) {
        notes = post.notes[0];
      }
      return (
        // eslint-disable-next-line react/no-array-index-key
        <tr className="ops-ant-table-row" key={`key${index}`}>
          <td>
            <span>{post.partName}</span>
          </td>
          <td>
            <span>{post.oemPartNumber}</span>
          </td>
          <td>
            <span>{notes}</span>
          </td>
        </tr>
      );
    });
    const main = (
      <div className="ops-na-table-container">
        <table>
          <thead className="ant-table-thead">
            <tr>
              <th>Parts Not Applicable</th>
            </tr>
          </thead>
          <tbody>{posts}</tbody>
        </table>
      </div>
    );
    return main;
  }
  renderNaNrNssIconWithPopover(naNrNssParts) {
    if (!naNrNssParts || naNrNssParts.length === 0) {
      return "";
    }
    const popoverContent = this.rendreNaNrNssPopover(naNrNssParts);
    return (
      <span className="ops-na-parts">
        <Popover
          htmlId="naNrNssLink"
          trigger={["click", "outsideClick"]}
          className="ops-na-parts-popover"
          popoverContent={popoverContent}
          position="right"
        >
          <IconInfoOutline className="info" />
        </Popover>
      </span>
    );
  }
  render() {
    const { rowData, naNrNssParts } = this.state;
    const gridClassname =
      rowData && rowData.length < 20
        ? "ag-grid-container ops-auto-height no-striped-grid ops-scroll-x-hidden ag-theme-balham"
        : "ag-grid-container ops-parts-grid no-striped-grid ops-scroll-x-hidden ag-theme-balham";
    const pageTitle = this.renderPageTitle(rowData);
    const naNrNssPartsIcon = this.renderNaNrNssIconWithPopover(naNrNssParts);
    return (
      <React.Fragment>
        <h4>
          {pageTitle}&nbsp;{naNrNssPartsIcon}
        </h4>
        <div id="partsGrid" className={gridClassname}>
          <AgGridReact
            columnDefs={this.state.columnDefs}
            defaultColDef={this.state.defaultColDef}
            suppressRowClickSelection={true}
            suppressMenuHide={false}
            suppressContextMenu={true}
            getRowNodeId={this.getRowNodeId}
            rowData={rowData}
            rowSelection={this.state.rowSelection}
            rowDeselection={true}
            singleClickEdit={false}
            stopEditingWhenCellsLoseFocus={true}
            animateRows={true}
            statusBar={this.state.statusBar}
            components={this.state.components}
            multiSortKey={this.state.multiSortKey}
            enableRangeSelection={false}
            enableCellTextSelection={true}
            enableBrowserTooltips={true}
            // rowHeight={30}
            onFilterChanged={this.onFilterChanged}
            onGridReady={this.onGridReady}
            onGridSizeChanged={this.handleGridSizeChanged}
            onSelectionChanged={this.handleSelectionChanged}
            // onCellValueChanged={this.onCellValueChanged}
            frameworkComponents={this.state.frameworkComponents}
            loadingOverlayComponent={this.state.loadingOverlayComponent}
            loadingOverlayComponentParams={
              this.state.loadingOverlayComponentParams
            }
            noRowsOverlayComponent={this.state.noRowsOverlayComponent}
            noRowsOverlayComponentParams={
              this.state.noRowsOverlayComponentParams
            }
            isRowSelectable={this.state.isRowSelectable}
            sideBar={this.state.sideBar}
            columnTypes={this.state.columnTypes}
            domLayout={this.state.domLayout}
            // popupParent={this.state.popupParent}
          />
        </div>
      </React.Fragment>
    );
  }
}
export default PartsGrid;

PartsGrid.propTypes = {
  disableAllParts: PropTypes.bool,
  selectedParts: PropTypes.array,
  rowData: PropTypes.array,
  naNrNssParts: PropTypes.array,
  additionalColumns: PropTypes.array,
  fluidHasQualifiers: PropTypes.bool,
  includeInspectColumns: PropTypes.bool,
  onSelectRow: PropTypes.func
};
PartsGrid.defaultProps = {
  disableAllParts: false,
  selectedParts: [],
  rowData: [],
  naNrNssParts: [],
  additionalColumns: [],
  fluidHasQualifiers: false,
  includeInspectColumns: true,
  onSelectRow: () => {
    // default empty function
  }
};

const ExcludedParts = ["NA", "NR", "NSS"];
const SpecialPartStatus = ["Discontinued", "Superseded"];

function qtyFormatter(params) {
  if (!params?.data) {
    return "";
  }
  const { quantity, units, partType } = params.data;
  let label = "";
  if (partType === "part") {
    label += toEmptyStringIfUndefined(quantity);
  } else if (partType === "fluid") {
    if (toEmptyStringIfUndefined(quantity) !== "" && quantity !== "NS") {
      label += quantity + " " + toEmptyStringIfUndefined(units);
    }
  }
  return label;
}
function relFormatter(params) {
  if (!params?.data) {
    return "";
  }
  if (params.data.relationship === "Related/Optional") {
    return "Optional";
  }
  return params.data.relationship;
}
function renderNotes(notes) {
  if (notes && Array.isArray(notes) && notes.length !== 0) {
    if (notes.length === 1) {
      return <div>{notes[0]}</div>;
    } else {
      const list = notes.map((note, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <li key={`key${i}`}>{note}</li>
      ));
      return <ul>{list}</ul>;
    }
  }
  return "";
}
function noteCellRenderer(params) {
  const { id, notes } = params.data;
  const label = renderNotes(notes);
  if (label === "" || notes.length === 1) {
    return "";
  } else {
    return (
      <div className="ops-notes">
        <Tooltip
          htmlId={`id_${id}`}
          tooltipContent={label}
          className="list-tooltip"
        >
          <IconInfoOutline className="info" />
        </Tooltip>
      </div>
    );
  }
}
function descriptionCellRenderer(params) {
  const { partName, partType, notes, id } = params.data;
  if (partType === "part") {
    const noteList = notes ? notes : [];
    const badges = noteList.slice(0, 2).map((note, i) => (
      // eslint-disable-next-line react/no-array-index-key
      <Badge htmlId={"noteBadge_" + i} color="gray" key={"key_" + i}>
        {note}
      </Badge>
    ));
    const tooltipList = noteList.slice(2);
    const label = renderNotes(tooltipList);
    if (noteList.length > 2) {
      return (
        <div className="ops-part-description">
          <span className="ops-description">{[partName]}</span>
          {badges}
          <Tooltip
            htmlId={`id_${id}`}
            tooltipContent={label}
            className="list-tooltip"
          >
            <IconInfoOutline className="info" />
          </Tooltip>
        </div>
      );
    } else {
      return (
        <div className="ops-part-description">
          <span className="ops-description">{partName}</span>
          {badges}
        </div>
      );
    }
  }
  return partName;
}

function quantityCellRenderer(params) {
  if (!params?.data) {
    return "";
  }
  if (params.data.dmsPending) {
    return (
      <LoadingIndicator
        htmlId="priceLoadingIndicator"
        size="small"
        color="gray"
      />
    );
  }
  const label = qtyFormatter(params);
  const { id, quantityAvailable } = params.data;
  const keyId = "qty_" + id;
  const qtySpan = !isNaN(quantityAvailable) ? (
    <span className="badge-dms">{quantityAvailable}</span>
  ) : (
    <span className="badge-msrp">0</span>
  );

  const tooltipText = "Quantity Available";
  return (
    <div>
      {toEmptyStringIfUndefined(label)}{" "}
      <span className="qty-separator">&nbsp;/&nbsp;</span>
      <Tooltip htmlId={keyId} tooltipContent={tooltipText}>
        {qtySpan}
      </Tooltip>
    </div>
  );
}

function partNumberCellRenderer(params) {
  const { id, oemPartNumber, status, supersessionHistory } = params.data;
  let lookupIcon = "";

  if (supersessionHistory && supersessionHistory.length !== 0) {
    const popoverContent = renderSupersededParts(params, supersessionHistory);
    const keyId = `supersession_${id}`;
    lookupIcon = (
      <div id={`id_${id}`}>
        <Tooltip
          htmlId={keyId}
          tooltipContent="Click to show previous superseded part numbers."
        >
          <span className="parts-instock">
            <Popover
              htmlId="supersededPartsId"
              trigger={["click", "outsideClick"]}
              className="ops-superseded-parts-popover"
              popoverContent={popoverContent}
              position="top"
            >
              <i
                style={{ width: "24px", textAlign: "center" }}
                className="fa fa-history"
              />
            </Popover>
          </span>
        </Tooltip>
      </div>
    );
  }
  if (status && SpecialPartStatus.includes(status)) {
    const keyId = `status_alert_${id}`;
    const specialPartNumber = (
      <div className="ops-special-part-number">
        {" "}
        {oemPartNumber}{" "}
        <Tooltip htmlId={keyId} tooltipContent={status}>
          <IconWarning className="ops-part-number-warn" />
        </Tooltip>
        {lookupIcon}
      </div>
    );
    return specialPartNumber;
  } else {
    const specialPartNumber = (
      <div className="ops-special-part-number">
        {" "}
        {oemPartNumber}
        <span />
        {lookupIcon}
      </div>
    );
    return specialPartNumber;
  }
}

function priceCellRenderer(params) {
  const { dmsPending, dmsPrice, unitPrice } = params.data;
  if (dmsPending) {
    return (
      <LoadingIndicator
        htmlId="priceLoadingIndicator"
        size="small"
        color="gray"
      />
    );
  }
  const priceType = dmsPrice ? "DMS" : "MSRP";
  const pricePerItem = dmsPrice ? dmsPrice : unitPrice;
  const priceTypeSpan =
    priceType === "DMS" ? (
      <span className="badge-dms">{priceType}</span>
    ) : (
      <span className="badge-msrp">{priceType}</span>
    );
  return (
    <div className="cx-badge-flex">
      {priceFormatter(pricePerItem)}
      {priceTypeSpan}
    </div>
  );
}

function renderSupersededParts(params, parts) {
  const { api, data } = params;
  const { id, unitPrice } = data;
  const htmlId = `supersededPartsTable_${id}`;
  return (
    <SimpleTable htmlId={htmlId} striped={false}>
      <thead>
        <tr>
          <th>Supersession History</th>
        </tr>
        <tr>
          <th>Select a previous part number to use it instead</th>
        </tr>
        <tr>
          <th scope="col">Part number</th>
          <th scope="col">Status</th>
          <th scope="col">MSRP/Item</th>
          <th scope="col">DMS Price</th>
          <th scope="col">Qty on Hand</th>
        </tr>
      </thead>
      <tbody>
        {parts.map((d, index) => {
          const { oemPartNumber, status } = d;
          const key = `key_${index}`;
          return (
            <tr
              className="parts-supersession-row"
              key={key}
              onClick={() => {
                data.oePartNumber = parts[index].oemPartNumber;
                data.oemPartNumber = parts[index].oemPartNumber;
                data.status = parts[index].status;
                api.refreshCells({ force: true });
                const divEl = document.querySelector(`#id_${id}`);
                if (divEl) {
                  divEl.click();
                }
              }}
            >
              <th scope="row">{oemPartNumber}</th>
              <td>{status}</td>
              <td>{unitPrice}</td>
              <td>-</td>
              <td>0</td>
            </tr>
          );
        })}
      </tbody>
    </SimpleTable>
  );
}

/* eslint-enable no-console */
