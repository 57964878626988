/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import { freezeObject } from "../utils/object";

// this context deals with service level
const defaultService = {
  quoteServiceId: null,
  quoteId: null
};

// default state applied to this context
let defaultState = {
  debugMode: false,
  appType: null,
  service: defaultService,
  quoteSummary: null,
  initialized: false
};

defaultState = freezeObject(defaultState);

const PriceAdjustmentContext = React.createContext(defaultState);

const Actions = {
  INITIALIZE: "INITIALIZE",
  WIPE_STATE: "WIPE_STATE",
  SET_DEBUG_MODE: "SET_DEBUG_MODE",
  SET_USER_PERMISSIONS: "SET_USER_PERMISSIONS"
};

const PriceAdjustmentReducer = (state, action) => {
  switch (action.type) {
    // @note: this action is called to pre-load any objects within scope of this context
    case Actions.INITIALIZE: {
      const { quoteSummary } = action.payload;
      return {
        ...state,
        initialized: true,
        quoteSummary
      };
    }
    // @note: reset state excluding app level objects
    case Actions.WIPE_STATE: {
      return {
        ...defaultState,
        debugMode: state.debugMode,
        userPermissions: state.userPermissions
      };
    }
    case Actions.SET_USER_PERMISSIONS: {
      return {
        ...state,
        userPermissions: action.payload
      };
    }
    case Actions.SET_DEBUG_MODE: {
      return {
        ...state,
        debugMode: action.payload
      };
    }

    default: {
      console.log(`Unhandled action type: ${action.type}`);
    }
  }
};

const PriceAdjustmentProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(
    PriceAdjustmentReducer,
    defaultState
  );
  const value = { state, dispatch };
  return (
    <PriceAdjustmentContext.Provider value={value}>
      {children}
    </PriceAdjustmentContext.Provider>
  );
};

PriceAdjustmentProvider.propTypes = {
  children: PropTypes.node.isRequired
};

const usePriceAdjustmentContext = () => {
  const context = React.useContext(PriceAdjustmentContext);
  if (context === undefined) {
    throw new Error(
      "usePriceAdjustmentContext must be used within a PriceAdjustmentProvider"
    );
  }
  return {
    state: context.state,
    dispatch: context.dispatch
  };
};

export {
  PriceAdjustmentProvider,
  usePriceAdjustmentContext,
  Actions,
  PriceAdjustmentContext
};
