import React from "react";
import PropTypes from "prop-types";
import ModalDialog from "@cx/ui/ModalDialog";
import Button from "@cx/ui/Button";
import "./approve-estimate-modal.scss";

const ApproveEstimateModal = props => {
  return (
    <ModalDialog
      htmlId="approveEstimateModal"
      className="approve-estimate-modal"
      show={props.show}
      onHide={props.cancelHandler}
      header="Approve estimate"
      footer={
        <div>
          <Button
            htmlId="cancelButton"
            onClick={props.cancelHandler}
            buttonStyle="link"
          >
            Cancel
          </Button>
          <Button
            htmlId="continueButton"
            onClick={props.continueHandler}
            buttonStyle="primary"
          >
            Continue
          </Button>
        </div>
      }
    >
      <div className="subheader">
        The Estimate and Hard Card will be printed.
      </div>
      <div className="content">
        <ul>
          <li>
            The customer must approve the service by signing the estimate.
          </li>
        </ul>
      </div>
    </ModalDialog>
  );
};

export default ApproveEstimateModal;

ApproveEstimateModal.propTypes = {
  show: PropTypes.bool.isRequired,
  cancelHandler: PropTypes.func.isRequired,
  continueHandler: PropTypes.func.isRequired
};
