import React, { useEffect, useState } from "react";
import "./csr.scss";
import {
  useEditServiceContext,
  Actions
} from "../../state/edit-service.context";
import CheckBoxList from "@cx/ui/CheckBoxList";
import Badge from "@cx/ui/Badge";
import isEqual from "lodash/isEqual";
import { serviceTypes, payTypeCodes } from "../../utils/edit-service.constants";
import {
  warrantyBadgeLabelsSubmit,
  warrantySubmissionStates
} from "../../../constants/quote-status.constants";

const ServiceLineCompleteComponent = ({ isMenuServiceSelected }) => {
  const { dispatch, state } = useEditServiceContext();
  const [initialSelectedValues, setInitialSelectedValues] = useState([]);
  const [selectedValues, setSelectedValues] = useState([]);

  const { dealerProperties } = state;
  const isTechTimeTrackingEnabled =
    dealerProperties?.ENABLE_DMSPLUS_TECH_TIME_CAPTURE === "Y";
  const isUseOEMWarrantyEnabled =
    dealerProperties?.ENGG_USE_OEM_WARRANTY === "Y" ? true : false;

  // @note: hasPartsPending will be true until all parts are approved or if the service has no parts.
  const hasPartsPending =
    state?.service?.parts?.some(p => !p.approver) ?? false;

  // @note: areTechniciansNotWorking will give true if no technicians exist for a line, have not started their work or have ended their work.
  const areTechniciansNotWorking = () => {
    if (!isTechTimeTrackingEnabled) return true;
    const areTechniciansInactive =
      state?.service?.technicians?.length === 0 ||
      state?.service?.technicians?.every(
        technician => technician?.status === "STOP"
      ) ||
      state?.service?.technicians?.every(technician =>
        ["NOT_STARTED", null, undefined].includes(technician?.status)
      );
    return areTechniciansInactive ?? true;
  };
  const cannotCompleteServiceLine =
    !areTechniciansNotWorking() || state.serviceType === serviceTypes.MENU
      ? hasPartsPending || !isMenuServiceSelected
      : hasPartsPending;

  // TODO: enable and review this condition after data comes from backend
  // const cannotCompleteServiceLine =
  //   state.serviceType === serviceTypes.MENU
  //     ? hasPartsPending || !isMenuServiceSelected
  //     : isTechTimeTrackingEnabled
  //     ? hasPartsPending || isWorkEnded
  //     : hasPartsPending;

  useEffect(() => {
    const selectedBoxValues = [];
    if (
      state.currentEditingService !== null &&
      state?.serviceType !== serviceTypes.MENU
    ) {
      if (state?.currentEditingService?.completedTime !== null) {
        selectedBoxValues.push("1");
      }
      if (
        state?.currentEditingService?.paymentStatus !== null &&
        state?.currentEditingService?.paymentStatus !== "notReady"
      ) {
        selectedBoxValues.push("2");
      }
      if (
        state.currentEditingService?.payTypeCode === "W" &&
        (isUseOEMWarrantyEnabled
          ? state?.currentEditingService?.warranty?.warrantySubmissionState ===
            warrantySubmissionStates.READY
          : state?.currentEditingService?.warranty?.warrantySubmissionState ===
            warrantySubmissionStates.SUBMITTED)
      ) {
        selectedBoxValues.push("3");
      }
      setSelectedValues(selectedBoxValues);
      setInitialSelectedValues(selectedBoxValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.currentEditingService]);

  // @note: same logic as for individual services, but relying on state.service for menu services.
  useEffect(() => {
    if (state?.service !== null && state?.serviceType === serviceTypes.MENU) {
      const selectedBoxValues = [];
      if (state?.service !== null) {
        if (state?.service?.completedTime !== null) {
          selectedBoxValues.push("1");
        }
        if (
          state?.service?.paymentStatus !== null &&
          state?.service?.paymentStatus !== "notReady"
        ) {
          selectedBoxValues.push("2");
        }
        if (
          state.service?.payTypeCode === "W" &&
          (isUseOEMWarrantyEnabled
            ? state?.service?.warranty?.warrantySubmissionState ===
              warrantySubmissionStates.READY
            : state?.service?.warranty?.warrantySubmissionState ===
              warrantySubmissionStates.SUBMITTED)
        ) {
          selectedBoxValues.push("3");
        }
        setSelectedValues(selectedBoxValues);
        setInitialSelectedValues(selectedBoxValues);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.service]);

  const onSelect = cxEvent => {
    if (!cxEvent.target.value.includes("1")) {
      setSelectedValues([]);
      dispatch({
        type: Actions.SET_SERVICE_LINE_COMPLETE,
        payload: null
      });
      dispatch({
        type: Actions.SET_READY_FOR_INVOICING,
        payload: "notReady"
      });
      if (state?.service?.payTypeCode === "W")
        dispatch({
          type: Actions.SET_SUBMITTED_TO_OEM,
          payload: null
        });
    } else {
      if (
        cxEvent.target.value.includes("1") &&
        !cxEvent.target.value.includes("2") &&
        !selectedValues.includes("2")
      ) {
        dispatch({
          type: Actions.SET_SERVICE_LINE_COMPLETE,
          payload: new Date().toISOString()
        });
      }
      if (selectedValues.includes("2") && !cxEvent.target.value.includes("2")) {
        dispatch({
          type: Actions.SET_READY_FOR_INVOICING,
          payload: "notReady"
        });
      }
      if (cxEvent.target.value.includes("2")) {
        dispatch({
          type: Actions.SET_READY_FOR_INVOICING,
          payload: "ready"
        });
      }
      if (
        state?.service?.payTypeCode === "W" &&
        cxEvent.target.value.includes("3")
      ) {
        dispatch({
          type: Actions.SET_SUBMITTED_TO_OEM,
          payload: isUseOEMWarrantyEnabled
            ? warrantySubmissionStates.READY
            : warrantySubmissionStates.SUBMITTED
        });
      }
      if (
        state?.service?.payTypeCode === "W" &&
        selectedValues.includes("3") &&
        !cxEvent.target.value.includes("3")
      ) {
        dispatch({
          type: Actions.SET_SUBMITTED_TO_OEM,
          payload: null
        });
      }
      if (
        state?.service?.payTypeCode === "W" &&
        !cxEvent.target.value.includes("2")
      ) {
        dispatch({
          type: Actions.SET_SUBMITTED_TO_OEM,
          payload: null
        });
      }
      setSelectedValues(cxEvent.target.value);
    }

    const changed = !isEqual(cxEvent.target.value, initialSelectedValues);
    dispatch({
      type: Actions.SET_CHANGED,
      payload: {
        field: "serviceLineCheckBoxes",
        value: changed
      }
    });
  };

  const getTitleForLineComplete = () => {
    let title = "";
    if (!isMenuServiceSelected) {
      title = "Cannot complete line of services not selected";
    } else if (hasPartsPending) {
      title = "Cannot complete line until all parts are approved";
    }
    return title;
  };

  const options = [
    {
      value: "1",
      disabled: cannotCompleteServiceLine,
      title: getTitleForLineComplete(),
      label: (
        <Badge
          htmlId="badgeCount"
          color={`${selectedValues.includes("1") ? "purple" : "gray"}`}
        >
          Service line complete
        </Badge>
      )
    },
    {
      value: "2",
      label: (
        <Badge
          htmlId="badgeCount"
          color={`${selectedValues.includes("2") ? "purple" : "gray"}`}
        >
          Ready for invoicing
        </Badge>
      ),
      disabled: !selectedValues.includes("1")
    }
  ];
  const isWarrantyPaytype =
    state?.service?.payTypeCode === payTypeCodes.WARRANTY;
  if (isWarrantyPaytype) {
    options.push({
      value: "3",
      label: (
        <Badge
          htmlId="badgeCount"
          color={`${selectedValues.includes("3") ? "purple" : "gray"}`}
        >
          {isUseOEMWarrantyEnabled
            ? warrantyBadgeLabelsSubmit.READY_TO_SUBMIT_TO_OEM
            : warrantyBadgeLabelsSubmit.SUBMITTED_TO_OEM}
        </Badge>
      ),
      disabled: !(selectedValues.includes("1") && selectedValues.includes("2"))
    });
  }
  return state?.userPermissions?.canMarkServiceComplete ? (
    <CheckBoxList
      htmlId="checkBoxListServiceLine"
      name="checkboxServiceLine"
      onChange={onSelect}
      options={options}
      values={selectedValues}
      inline
    />
  ) : null;
};

export default ServiceLineCompleteComponent;
