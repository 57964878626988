/* This objects are freezed, used for cell renderer  */
export const UnitOfMeasureMap = {
  Gallons: "gal",
  Grams: "g",
  Inches: "in",
  Liters: "l",
  Milliliters: "ml",
  Millimeters: "mm",
  Ounces: "oz",
  Pints: "pt",
  Quarts: "qt"
};

export const IncludedInspectionTags = [
  "inspect",
  "disclaimer",
  "lubricate",
  "reset",
  "tighten",
  "test"
];

export const GlobalOpsServiceType = {
  GLOBALCATALOG: "GlobalCatalog",
  DEALERCATALOG: "DealerPublishedCatalog",
  DIAGNOSIS: "Diagnose",
  DECLINED: "declined",
  AUTORECALL: "autorecall"
};

export const DealerPublishedCategory = {
  REPAIR: "repair",
  MAINTENANCE: "maintenance"
};

export const saveActionTypes = {
  NA: null,
  SAVE: "SAVE",
  SAVEANOTHER: "SAVEANOTHER"
};
