import React from "react";

const MenuInspections = props => {
  const { inspections } = props;
  const menuInspections = inspections.map(ins => {
    return (
      <div className="inspection" key={`menuService-${ins.extServiceId}`}>
        <div className="sq-overflow-word">{ins.serviceName}</div>
      </div>
    );
  });

  return (
    <>
      <hr className="menuService__divider" />
      <div className="menuService">
        <div className="menuService__title">
          Included Inspection ({inspections.length})
        </div>

        <hr className="menuService__divider" />
        <div className="inspections-row">{menuInspections}</div>
      </div>
    </>
  );
};

export default MenuInspections;
