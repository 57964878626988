import React from "react";

const FeeNotSelectedComponent = ({ errorMessage }) => {
  return (
    <div className="catalog-fee-not-selected-component">
      <span>{errorMessage}</span>
    </div>
  );
};

export default FeeNotSelectedComponent;
