import Col from "@cx/ui/Col";
import PriceInput from "@cx/ui/PriceInput";
import Row from "@cx/ui/Row";
import React from "react";
import NumericInput from "@cx/ui/NumericInput";
import DatePicker from "@cx/ui/DatePicker";
import TextInput from "@cx/ui/TextInput";
import "./csr.scss";
import { getNoOfDays } from "../../utils/time";

const WarrantyExpenseRentalCarFieldComponent = ({
  expense,
  updateExpenseRentalCarFieldData
}) => {
  const onSelect = cxEvent => {
    switch (cxEvent.target.name) {
      case "startDate":
        updateExpenseRentalCarFieldData({
          ...expense,
          startDate: cxEvent?.target?.value,
          totalDays: getNoOfDays(cxEvent?.target?.value, expense?.endDate) + ""
        });
        break;
      case "endDate":
        updateExpenseRentalCarFieldData({
          ...expense,
          endDate: cxEvent?.target?.value,
          totalDays:
            getNoOfDays(expense?.startDate, cxEvent?.target?.value) + ""
        });
        break;
      default:
        updateExpenseRentalCarFieldData({
          ...expense,
          [cxEvent.target.name]:
            cxEvent?.target?.value !== "" ? cxEvent?.target?.value : null
        });
    }
  };
  return (
    <Row className="warranty-expense-rental-row-container">
      <Col className="warranty-expense-rental-vin-input-col">
        <TextInput
          htmlId="rentalVinInput"
          label="Rental VIN"
          name="rentalVin"
          onChange={onSelect}
          value={(expense?.rentalVin && expense?.rentalVin) || ""}
        />
      </Col>
      <Col className="m-l" style={{ width: "15%" }}>
        <NumericInput
          htmlId="mielageOutInput"
          label="Mileage out"
          name="mileageOut"
          onChange={onSelect}
          value={expense?.mileageOut + "" || ""}
        />
      </Col>
      <Col className="m-l" style={{ width: "15%" }}>
        <NumericInput
          htmlId="mileageInInput"
          label="Mileage in"
          name="mileageIn"
          onChange={onSelect}
          value={expense?.mileageIn || ""}
        />
      </Col>
      <Col className="warranty-expense-rental-start-date-picker-col">
        <DatePicker
          htmlId="startdatePicker"
          label="Start date"
          className="warranty-expense-rental-date-picker"
          name="startDate"
          dateFormat="MM/dd/yyyy"
          maxDate={new Date(expense?.endDate) || ""}
          selected={expense?.startDate || ""}
          onChange={onSelect}
        />
      </Col>
      <Col className="m-l-0 warranty-expense-rental-end-date-picker-col">
        <DatePicker
          htmlId="enddatePicker"
          label="End date"
          name="endDate"
          minDate={new Date(expense?.startDate) || ""}
          className="warranty-expense-rental-date-picker"
          dateFormat="MM/dd/yyyy"
          selected={expense?.endDate || ""}
          onChange={onSelect}
        />
      </Col>
      <Col className="m-l-0" style={{ width: "15%" }}>
        <PriceInput
          htmlId="coverage3Input"
          label="Daily rate"
          className="warranty-expense-price-input"
          name="dailyRate"
          onChange={onSelect}
          value={(expense?.dailyRate && expense?.dailyRate + "") || ""}
        />
      </Col>
      <Col className="m-l warranty-expense-rental-total-days">
        <NumericInput
          htmlId="totalDaysInInput"
          label="Total days"
          name="totalDays"
          onChange={onSelect}
          value={expense?.totalDays + "" || "0"}
        />
      </Col>
    </Row>
  );
};

export default WarrantyExpenseRentalCarFieldComponent;
