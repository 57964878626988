export const dashboardQuoteStatus = {
  IN_PROGRESS: "IN_PROGRESS",
  SENT: "SENT",
  CONVERTED_TO_APPOINTMENT: "CONVERTED_TO_APPOINTMENT",
  NO_QUOTES: "NO_QUOTES",
  EXPIRED: "EXPIRED",
  REQUEST_ASSISTANCE: "REQUEST_ASSISTANCE",
  READY_TO_SEND: "READY_TO_SEND"
};

export const services = {
  DEALER_PUB_MAINT_OPS: "DEALER_PUB_MAINT_OPS", // Maintenance
  DEALER_PUB_REPAIR_OPS: "DEALER_PUB_REPAIR_OPS", //  Diagnosis
  DECLINED: "DECLINED", // Declined
  GLOBAL_REPAIR_OPS: "GLOBAL_REPAIR_OPS", // Reapirs
  MENU: "MENU", // Packages
  RECALL: "RECALL" // Recalls
};

export const quotesBreakdownColorsObject = {
  IN_PROGRESS: "#E5EDFD",
  SENT: "#D6C2FF",
  CONVERTED_TO_APPOINTMENT: "#CFFDC3",
  NO_QUOTES: "#E4E9EF",
  EXPIRED: "#E4E9EF"
};

export const quotesBreakdownColorsArray = [
  quotesBreakdownColorsObject.IN_PROGRESS,
  quotesBreakdownColorsObject.SENT,
  quotesBreakdownColorsObject.CONVERTED_TO_APPOINTMENT,
  quotesBreakdownColorsObject.EXPIRED,
  quotesBreakdownColorsObject.NO_QUOTES
];

export const potentialValueColorsObject = {
  SENT: "#D6C2FF",
  CONVERTED_TO_APPOINTMENT: "#CFFDC3",
  NO_QUOTES: "#E4E9EF"
};

export const potentialValueColorsArray = [
  potentialValueColorsObject.SENT,
  potentialValueColorsObject.CONVERTED_TO_APPOINTMENT,
  potentialValueColorsObject.NO_QUOTES
];
