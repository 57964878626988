import {
  DealerSettingsConstant,
  SettingFields
} from "../dealer-settings/constants";
import { parseBool } from "../../utils/value.util";

/**
 *
 * @param {*} settingDataArray (read from t_setting)
 * @param {*} dealerSettingDataArray (read from t_dealer_setting)
 * @returns settingObject as below
 * {
    "quoteExpirationDays": "",
    "displayLaborHours": "",
    "displayPartNumbers": "",
    "disclaimer": "",
    "displayLaborPartsDetails": "",
    "quoteAssistance": true,
    "partsEmail": "raja.pasupuleti@coxautoinc.com",
    "serviceEmail": "raja.pasupuleti@coxautoinc.com"
}
 */
export const prepareDealerSettingsObject = (
  dealerSettingDataArray,
  settingDataArray
) => {
  const settingObject = transformSettingObject(settingDataArray);
  console.log(
    "prepareDealerSettingsObject",
    settingDataArray,
    dealerSettingDataArray,
    settingObject
  );
  settingDataArray.forEach(setting => {
    dealerSettingDataArray.forEach(dealerSetting => {
      if (setting.settingId === dealerSetting.settingId) {
        const dbColumnName = DealerSettingsConstant[setting.settingName];
        if (setting.settingName === dbColumnName) {
          if (setting.dataType === "boolean") {
            settingObject[setting.fieldName] = !dealerSetting.settingValue
              ? null
              : parseBool(dealerSetting.settingValue);
          } else {
            settingObject[setting.fieldName] = dealerSetting.settingValue;
          }
        }
      }
    });
  });
  console.log("FINAL setting obj", settingObject);
  return { ...settingObject };
};
// TODO - settingId switch case logic to be finalized based on DB column
export const updateDealerSettingsObject = (
  prevDealerSettings,
  { settingId, settingValue }
) => {
  switch (settingId) {
    case 6: {
      return {
        ...prevDealerSettings,
        quoteAssistance:
          typeof settingValue === "string"
            ? JSON.parse(settingValue)
            : settingValue
      };
    }
    case 7: {
      return {
        ...prevDealerSettings,
        partsEmail: settingValue
      };
    }
    case 8: {
      return {
        ...prevDealerSettings,
        serviceEmail: settingValue
      };
    }
  }
};

export const transformSettingObject = settingAPIData => {
  const setting = {};
  settingAPIData.forEach(function (item) {
    const fieldName = SettingFields[item.settingName]; // DB Column Value
    item.fieldName = fieldName;
    setting[fieldName] = "";
  });
  return setting;
};
