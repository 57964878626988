import React from "react";
import Alert from "@cx/ui/Alert";
import { logError } from "../../../services/log.service";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      errorMessage: props.errorMessage
    };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error };
  }

  componentDidCatch(error, _errorInfo) {
    logError(error, "ErrorBoundary.componentDidCatch");
  }

  render() {
    if (this.state.hasError) {
      return (
        <Alert
          htmlId="errorBoundaryAlert"
          type="danger"
          className="error-boundary-alert"
        >
          <div className="error-boundary-message">
            {this.state.errorMessage ?? "Error"}
          </div>
        </Alert>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
