// This class provides various utility functions that don't fall under one of the other utility classes.
export default class util {
  /**
   * Returns the specified value's data type, as a string.
   * Possible results: Undefined, Null, Object, Boolean, Array, String, Number,
   *   Date, Symbol, Function, Math, Promise, RegExp, NaN
   * @param val Any value you want to check the type of.
   * @returns {string}
   */
  static getType(val) {
    const typeofResult = typeof val;

    // The toString call below will fail for a Symbol, so check for that first.
    if (typeofResult === "symbol") {
      return "Symbol";
    }

    const toStringResult = {}.toString.call(val).match(/\s([a-zA-Z]+)/)[1];

    // NaN is considered a Number, but we want to identify it for what it really is... NOT a Number.
    if (toStringResult === "Number" && isNaN(val)) {
      return "NaN";
    }

    return toStringResult;
  }

  /**
   * Checks whether a value is a Number.
   * Note that any NaN value will return false.
   * @param val Value to check.
   * @returns {boolean} Boolean true if the value is a Number, otherwise false.
   */
  static isNumber(val) {
    return typeof val === "number" && !isNaN(val);
  }

  /**
   * Rounds a currency value off to the nearest cent, rounding up
   * (toward positive infinity) for half-cent values.
   * Will automatically attempt to parse string values as a number.
   * @param {Number|String} val Currency value.
   * @returns {Number} Currency value rounded to the nearest cent,
   * or NaN if the value is not recognizable as a number.
   */
  static roundCurrencyValue(val) {
    const type = this.getType(val);
    const valueToRound =
      type === "Number"
        ? val
        : type === "String"
        ? parseFloat(val.trim().replace(/^\s*\$/, ""))
        : NaN;
    return isNaN(valueToRound)
      ? NaN
      : Math.round(parseFloat((valueToRound * 100).toFixed(3))) / 100;
  }

  /**
   * Regex to verify that a VIN matches the basic expected pattern (length 17, and a valid allowed character at each position).
   * It does not verify whether the check digit (9th character) is actually correct; just that it's a valid allowed character (0-9 or X).
   * It also does not verify whether the full VIN can actually be decoded or not; just that it looks valid as far as its characters go.
   * Breakdown by position (1-based):
   * - 1-8: Must be either numeric digits (0-9) or any uppercase letters besides I, O, or Q.
   * - 9: Must be either a numeric digit (0-9) or an uppercase X. (This is the "check digit".)
   * - 10-11: Same restrictions as the first 8 characters.
   * - 12-17: Most sources say these have to be numeric digits (0-9), but in reality, they can also be letters.
   * @type {RegExp}
   */
  static #vinRegex = /^[A-HJ-NPR-Z\d]{8}[\dX][A-HJ-NPR-Z\d]{2}[A-Z0-9]{6}$/;

  /**
   * Calculates the check digit for a VIN, based on the other 16 characters of the VIN.
   * @param {String} vin VIN from which to calculate the check digit.
   * @returns {String} The check digit, which is either a single digit or the letter X.
   * @see https://myvindecoder.com/vin-decoding/check-digit/
   */
  static calculateVinCheckDigit(vin) {
    if (!this.#vinRegex.test(vin)) {
      throw "Invalid input; must be a 17-character string matching the VIN RegExp pattern.";
    }
    const map = "0123456789X";
    const weights = "8765432X098765432";
    const sum = vin.split("").reduce(function (sum, char, index) {
      const charValue =
        "0123456789.ABCDEFGH..JKLMN.P.R..STUVWXYZ".indexOf(char) % 10;
      return sum + charValue * map.indexOf(weights[index]);
    }, 0);
    return map[sum % 11];
  }

  /**
   * Checks whether a VIN is valid by ensuring that it has the correct length, pattern, and check digit.
   * This implementation is based on the validation code from the CX VinInput component (see link below).
   * @param {String} vin
   * @returns {boolean}
   * @see https://ghe.coxautoinc.com/DMS/DTDMS.Payments.Configuration.UI/blob/master/src/components/cx/internal/validation/vinValidation.js.
   */
  static isValidVin(vin) {
    return (
      this.#vinRegex.test(vin) && vin[8] === this.calculateVinCheckDigit(vin)
    );
  }

  /**
   Parses a JavaScript stack frame.
   @param {string} frame Stack frame to parse.
   @returns {object} Object containing details parsed from the frame.
   @example
   // Sample return object:
   {
     componentName: "PartsLookup",
     filePath: "http://localhost:3000/static/js/main.js",
     lineNumber: 224190,
     columnNumber: 38
   }
   */
  static parseFrame(frame) {
    try {
      const result = {};
      const chunks = frame
        .replace(/^\s*at\s+/, "")
        .replace(/[()]/g, "")
        .split(" ");
      chunks.forEach(chunk => {
        if (/:\d+:\d+$/.test(chunk)) {
          const matches = chunk.match(/(.*):(\d+):(\d+)$/);
          result.filePath = matches[1];
          result.lineNumber = parseInt(matches[2], 10);
          result.columnNumber = parseInt(matches[3], 10);
        } else {
          result.componentName = chunk;
        }
      });
      return result;
    } catch (err) {
      return { parseFrameError: err };
    }
  }

  /**
   * Parses a JavaScript error object.
   * @param {object} error Error object to parse.
   * @returns {object} Object containing details parsed from the error.
   * @example
   * // Sample return object:
   * {
   *   errorType: "TypeError",
   *   errorMessage: "Cannot read properties of null (reading 'receivePartsPricingAndInventory')"
   *   componentName: "PartsLookup",
   *   filePath: "http://localhost:3000/static/js/main.js",
   *   lineNumber: 224190,
   *   columnNumber: 38
   * }
   */
  static parseErrorObject(error) {
    try {
      const lines = error?.stack?.split("\n");
      const firstLine = lines && lines[0];
      const firstLineMatch = firstLine?.match(/(\S+):\s*(.*)/);
      const firstFrame = lines && lines[1];
      const firstFrameInfo = firstFrame && this.parseFrame(firstFrame);

      return {
        errorType: error.name ?? (firstLineMatch && firstLineMatch[1]),
        errorMessage: error.message ?? (firstLineMatch && firstLineMatch[2]),
        stack: error.stack,
        ...firstFrameInfo
      };
    } catch (err) {
      return { parseErrorObjectError: err };
    }
  }
}
