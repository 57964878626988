import React from "react";
import { PropTypes } from "prop-types";
import Card from "@cx/ui/Card";
import SuccessIcon from "@cx/ui/Icons/IconComplete";
import IconClose from "@cx/ui/Icons/IconClose";
import IconError from "@cx/ui/Icons/IconError";
import InfoIcon from "@cx/ui/Icons/IconInfoOutline";
import { assistanceRequestStatus } from "../../../../constants/assiatance-request.constants";
import "./request-banner.scss";
import capitalize from "lodash/capitalize";

const RequestBanner = props => {
  const {
    requestType,
    requestStatus,
    state,
    confirmationId,
    onCloseBanner,
    bannerType
  } = props;
  let icon;
  switch (bannerType) {
    case "success":
      icon = <SuccessIcon htmlId="successIcon" />;
      break;
    case "info":
      icon = <InfoIcon htmlId="infoIcon" />;
      break;
    case "error":
      icon = <IconError htmlId="iconError" />;
      break;
  }
  switch (requestStatus) {
    case assistanceRequestStatus.COMPLETED:
      return (
        <Card htmlId="requestBannerContainer">
          <div id="iconContainer" className="success">
            {icon}
          </div>
          <span id="bannerText">
            Service quote assistance request sent to the {requestType}{" "}
            department for quote {confirmationId}
          </span>
          <div id="iconContainer" className="close" onClick={onCloseBanner}>
            <IconClose htmlId="closeIcon" />
          </div>
        </Card>
      );
    case assistanceRequestStatus.REQUESTED:
      return (
        <Card htmlId="requestBannerContainer">
          <div id="iconContainer" className="success">
            {icon}
          </div>
          <span id="bannerText">
            {state === assistanceRequestStatus.REQUEST
              ? `${capitalize(
                  requestType
                )} request performed for quote ${confirmationId}`
              : `Resolved ${requestType} quote assistance for quote ${confirmationId}`}
          </span>
          <div id="iconContainer" className="close" onClick={onCloseBanner}>
            <IconClose htmlId="closeIcon" />
          </div>
        </Card>
      );
    case assistanceRequestStatus.REQUESTED_FAIL:
      return (
        <Card htmlId="requestBannerContainer">
          <div id="iconContainer" className="danger">
            {icon}
          </div>
          <span id="bannerText">
            Service quote assistance request failed. Please try again later.
          </span>
          <div id="iconContainer" className="close" onClick={onCloseBanner}>
            <IconClose htmlId="closeIcon" />
          </div>
        </Card>
      );
    case assistanceRequestStatus.COMPLETED_FAIL:
      return (
        <Card htmlId="requestBannerContainer">
          <div id="iconContainer" className="danger">
            {icon}
          </div>
          <span id="bannerText">
            Service quote resolution failed. Please try again later.
          </span>
          <div id="iconContainer" className="close" onClick={onCloseBanner}>
            <IconClose htmlId="closeIcon" />
          </div>
        </Card>
      );
  }
};

RequestBanner.defaultProps = {
  requestType: "",
  requestStatus: "",
  state: "",
  confirmationId: "",
  onCloseBanner: () => {},
  bannerType: "success"
};

RequestBanner.propTypes = {
  requestType: PropTypes.string,
  requestStatus: PropTypes.string,
  state: PropTypes.string,
  confirmationId: PropTypes.string,
  onCloseBanner: PropTypes.func,
  bannerType: PropTypes.string
};

export default RequestBanner;
