// EXTERNAL LIBRARIES
import React from "react";
import Button from "@cx/ui/Button";
import ModalDialog from "@cx/ui/ModalDialog";
// STYLES
import "./remove-service-contract-modal.scss";
import PropTypes from "prop-types";

// eslint-disable-next-line react/display-name
const RemoveServiceContractModal = React.forwardRef(
  (
    { showConfirmationModal, serviceContract, id, onCancel, onConfirm },
    ref
  ) => {
    return (
      <ModalDialog
        htmlId="RemoveServiceContractConfirmationModal"
        show={showConfirmationModal}
        onHide={onCancel}
        className="remove-service-contract-modal"
        header={<ModalDialog.Title>Remove Service Contract</ModalDialog.Title>}
        footer={
          <div className="comeback-button-container">
            <div>
              <Button
                htmlId="remove-service-contract-modal-close-btn"
                buttonStyle="secondary"
                onClick={onCancel}
              >
                Cancel
              </Button>
              <Button
                htmlId="remove-service-contract-modal-danger-btn"
                buttonStyle="danger"
                onClick={() => {
                  onConfirm(id);
                }}
              >
                Remove
              </Button>
            </div>
          </div>
        }
      >
        <p>
          Are you sure you want to remove the {serviceContract} from this
          vehicle?
        </p>
      </ModalDialog>
    );
  }
);

export default RemoveServiceContractModal;

RemoveServiceContractModal.prototype = {
  // handlers
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  // data
  showConfirmationModal: PropTypes.bool,
  serviceContract: PropTypes.string,
  id: PropTypes.string
};
