// @ts-check
import React from "react";
import clsx from "clsx";
// eslint-disable-next-line unused-imports/no-unused-imports
import * as CommonTypes from "../../../../../../@types/common.types";

/**
 * Quotes Breakdown Board Component
 * @param {{formattedValue?: string, label: string, position: string}} props Prop Types
 * @returns {CommonTypes.Component}
 */
const PotentialValueBoard = props => {
  const { formattedValue, label, position } = props;
  const boardCls = clsx(
    "potential-value__board",
    `potential-value__board--${position}`
  );
  const labelCls = clsx("label", `label--${position}`);
  const totalCls = clsx("total", `total--${position}`);
  return (
    <div className={boardCls}>
      <div className={totalCls}>{`$${formattedValue}`}</div>
      <div className={labelCls}>{label}</div>
    </div>
  );
};

export default PotentialValueBoard;
