/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable unused-imports/no-unused-imports */
/* eslint-disable react/jsx-max-depth */
// WIP remove lint exceptions once component is completed ******
import React, { useEffect, useState } from "react";
import Card from "@cx/ui/Card";
import SearchableSelect from "@cx/ui/SearchableSelect";
import Grid from "@cx/ui/Grid";
import Row from "@cx/ui/Row";
import Col from "@cx/ui/Col";
import NumericInput from "@cx/ui/NumericInput";
import TextArea from "@cx/ui/TextArea";
import DatePicker from "@cx/ui/DatePicker";
import TextInput from "@cx/ui/TextInput";
import Button from "@cx/ui/Button";
import themePrefixer from "@cx/ui/themePrefixer";
import {
  addMonthsToDate,
  calculateMonthsBetweenDates,
  formatNumberWithThousands
} from "../utils/format";
import "./service-contracts.scss";
import RemoveServiceContractModal from "./remove-service-contract-modal.component";
import { formatVendorProduct } from "./utils/service-contracts.util";

const initialState = {
  vendorName: "",
  productName: "",
  contractNumber: "",
  expirationDate: "",
  expirationMileage: "",
  deductible: "",
  startingMileage: "",
  startingDate: "",
  contractLength: "",
  coverageMileage: "",
  planCode: "",
  productCode: "",
  notes: "",
  phone: "--",
  website: "--",
  email: "--"
};

const ModalDialogWithHeader = ({
  onChange,
  onCancel,
  onSave,
  onRemove,
  showRemove = false,
  disabledCancel,
  disabledSave,
  data = initialState,
  isNewRecord = true,
  isModal = false,
  dealerProperties,
  ...props
}) => {
  const displayDealerProperties =
    dealerProperties?.DISPLAY_CONTACT_DETAILS === "Y" ? true : false;
  const [state, setState] = useState({
    ...data,
    contractLength: data?.contractLength || "",
    coverageMileage: data?.coverageMileage || ""
  });
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const gridStyle = {
    leftContainer: {
      xs: isModal ? 7 : 8,
      md: isModal ? 7 : 8
    },
    rightContainer: {
      xs: isModal ? 5 : 4,
      md: isModal ? 5 : 4
    }
  };

  const handleInputChange = event => {
    const { name, value } = event.target;
    const newValue =
      [
        "startingMileage",
        "contractLength",
        "deductible",
        "coverageMileage"
      ].includes(name) && value
        ? parseFloat(value)
        : value;

    setState(prevState => ({
      ...prevState,
      [name]: name === "vendorName" ? value?.[0]?.value || "" : newValue
    }));
  };

  useEffect(() => {
    if (state.startingMileage && state.expirationMileage) {
      setState(prevState => ({
        ...prevState,
        coverageMileage: prevState.expirationMileage - prevState.startingMileage
      }));
    }
  }, [state.startingMileage, state.expirationMileage]);

  useEffect(() => {
    if (state.startingDate && state.expirationDate) {
      setState(prevState => ({
        ...prevState,
        contractLength: calculateMonthsBetweenDates(
          prevState.startingDate,
          prevState.expirationDate
        )
      }));
    }
  }, [state.startingDate, state.expirationDate]);

  return (
    <div className="service-contract-main-container">
      <RemoveServiceContractModal
        showConfirmationModal={showRemoveModal}
        serviceContract={state.vendorName}
        id={state?.id}
        onCancel={() => setShowRemoveModal(false)}
        onConfirm={() => {
          onRemove(state.id);
          setShowRemoveModal(false);
        }}
      />
      <Card htmlId="serviceContractCard" className="service-contract-card">
        <div className="service-contract-main-container">
          <Grid
            htmlId="service-contract-main-grid"
            className={`service-contract-main-grid ${
              isNewRecord ? "service-contract-main-grid--white" : ""
            }`}
          >
            <Row className="show-grid">
              <div className="form-heading">Service Contract Details</div>
            </Row>
            <Row className="show-grid">
              <Col
                xs={gridStyle.leftContainer.xs}
                md={gridStyle.leftContainer.md}
                className={!isModal ? "service-contract--left-iframe" : ""}
              >
                <div className="service-contract-form">
                  <TextInput
                    htmlId={
                      isModal
                        ? "searchableSelectAllowSingleSelection"
                        : "searchableSelectAllowSingleSelection-iframe"
                    }
                    label="Service contract"
                    name="vendorName"
                    layout="horizontal"
                    value={formatVendorProduct(
                      state?.vendorName,
                      state?.productName
                    )}
                    onChange={handleInputChange}
                    readOnly
                    disabled
                  />
                  <Grid htmlId="service-contract-inputs-grid">
                    <Row className="show-grid">
                      <Col xs={6} md={6}>
                        <NumericInput
                          autoInsertCommas
                          htmlId="startingMiles"
                          name="startingMileage"
                          label="Starting miles"
                          onChange={handleInputChange}
                          value={state.startingMileage}
                          maxLength={20}
                          layout="horizontal"
                          readOnly
                          disabled
                        />
                        <NumericInput
                          htmlId="numericInputSuffix"
                          inputSuffix="Months"
                          label="Contract length"
                          name="contractLength"
                          onChange={handleInputChange}
                          value={state.contractLength}
                          layout="horizontal"
                          readOnly
                          disabled
                        />
                        <NumericInput
                          allowDecimal
                          inputPrefix="$"
                          htmlId="numericInputAllowSpecialCharacters"
                          label="Deductible"
                          name="deductible"
                          onChange={handleInputChange}
                          value={state.deductible}
                          maxLength={20}
                          layout="horizontal"
                          readOnly
                          disabled
                        />
                        <NumericInput
                          autoInsertCommas
                          htmlId="numericInputAutoInsertCommas"
                          name="coverageMileage"
                          label="Coverage miles"
                          onChange={handleInputChange}
                          value={state.coverageMileage}
                          maxLength={20}
                          layout="horizontal"
                          readOnly
                          disabled
                        />
                      </Col>
                      <Col xs={6} md={6}>
                        <DatePicker
                          htmlId={
                            isModal
                              ? "datePickerDefaultSettings"
                              : "datePickerDefaultSettings-iframe"
                          }
                          label="Starting date"
                          name="startingDate"
                          dateFormat="MM/dd/yyyy"
                          selected={state.startingDate}
                          onChange={handleInputChange}
                          layout="horizontal"
                          disabled
                        />
                        <TextInput
                          htmlId="contractNumber"
                          label="Contract number"
                          name="contractNumber"
                          layout="horizontal"
                          maxLength={50}
                          onChange={handleInputChange}
                          value={state.contractNumber}
                          readOnly
                          disabled
                        />
                        <TextInput
                          htmlId="planCode"
                          label="Plan code"
                          name="planCode"
                          layout="horizontal"
                          maxLength={50}
                          onChange={handleInputChange}
                          value={state.planCode}
                          readOnly
                          disabled
                        />
                        <TextInput
                          htmlId="productCode"
                          label="Product code"
                          name="productCode"
                          layout="horizontal"
                          maxLength={50}
                          onChange={handleInputChange}
                          value={state.productCode}
                          readOnly
                          disabled
                        />
                      </Col>
                    </Row>
                  </Grid>
                  {/* <TextArea
                    htmlId={
                      isModal
                        ? "textAreaHorizontal"
                        : "textAreaHorizontal-iframe"
                    }
                    label="Notes"
                    name="notes"
                    onChange={handleInputChange}
                    value={state.notes}
                    layout="horizontal"
                    readOnly
                  /> */}
                </div>
              </Col>
              <Col
                xs={gridStyle.rightContainer.xs}
                md={gridStyle.rightContainer.md}
                className="widget-column"
              >
                <div
                  className={
                    !isModal
                      ? "cards-container cards-container--iframe"
                      : "cards-container"
                  }
                >
                  <Card>
                    {/* Hard coded info needs to be in this card */}
                    <div
                      className={
                        displayDealerProperties
                          ? "expiration-info"
                          : "hide-details"
                      }
                    >
                      <div className="info-container">
                        <span className="info-container--bold">
                          Portfolio (Assurant) Online VSC Claims
                        </span>
                        <span>
                          Dealer Portal:&nbsp;
                          <span className="info-container--link">
                            twgservice.my.site.com/DealerLogin
                          </span>
                        </span>
                        <span>
                          Portfolio/Horizon service contract:&nbsp;
                          <span className="info-container--bold">
                            (833) 271-7452
                          </span>
                        </span>
                      </div>
                      <div className="info-container">
                        <span className="info-container--bold">
                          Portfolio Ancillary Claims
                        </span>
                        <span>
                          Portal:&nbsp;
                          <span className="info-container--link">
                            www.Claims.Portfolioco.com
                          </span>
                        </span>
                      </div>
                      <div className="info-container">
                        <span className="info-container--bold">
                          GAP/Theft/Brake Plus/Deposit Protect
                        </span>
                        <span>
                          Claims Phone:&nbsp;
                          <span className="info-container--bold">
                            833.823.4501
                          </span>
                        </span>
                        <span>
                          Fax:&nbsp;
                          <span className="info-container--bold">
                            480.897.7507
                          </span>
                        </span>
                        <span>
                          Email:&nbsp;
                          <span className="info-container--link">
                            gapclaims@portfolioco.com
                          </span>
                        </span>
                      </div>
                      <div className="info-container">
                        <span className="info-container--bold">
                          Protection Non-VSC Claims
                        </span>
                        <span>
                          Phone:&nbsp;
                          <span className="info-container--bold">
                            877.705.4001
                          </span>
                        </span>
                        <span>
                          Text:&nbsp;
                          <span className="info-container--bold">
                            949.245.6404
                          </span>
                        </span>
                        <span>
                          Fax:&nbsp;
                          <span className="info-container--bold">
                            480.897.7507
                          </span>
                        </span>
                        <span>
                          Email:&nbsp;
                          <span className="info-container--link">
                            proclaims@portfolioco.com
                          </span>
                        </span>
                      </div>
                      <div className="info-container">
                        <span className="info-container--bold">
                          Portfolio One Online Claims
                        </span>
                        <span>
                          Portal:&nbsp;
                          <span className="info-container--link">
                            twgservice.my.site.com/DealerLogin
                          </span>
                        </span>
                        <span>
                          Phone number:&nbsp;
                          <span className="info-container--bold">
                            (833) 487-5446
                          </span>
                        </span>
                      </div>
                      <div className="info-container">
                        <span className="info-container--bold">
                          Global Administrators
                        </span>
                        <span>
                          Dealer Portal:&nbsp;
                          <span className="info-container--link">
                            https://dealer-portal.globaladmin.com/
                          </span>
                        </span>
                        <span>
                          Global claims:&nbsp;
                          <span className="info-container--link">
                            claims@globaladmin.com
                          </span>
                        </span>
                        <span>
                          Global support:&nbsp;
                          <span className="info-container--link">
                            support@globaladmin.com
                          </span>
                        </span>
                        <span>
                          Phone number:&nbsp;
                          <span className="info-container--bold">
                            888-686-2225
                          </span>
                        </span>
                      </div>
                    </div>
                  </Card>
                </div>
              </Col>
            </Row>
          </Grid>
          {/* uncomment lines below when buttons are added back to the form */}

          {/* <div
            className={`service-contract-buttons-container ${
              isNewRecord ? "service-contract-buttons-container--white" : ""
            }`}
          >
            <div className={`${themePrefixer()}btn-toolbar`}>
              {showRemove && (
                <Button
                  buttonStyle="danger"
                  htmlId="btnDanger"
                  onClick={() => setShowRemoveModal(true)}
                >
                  Remove
                </Button>
              )}
            </div>
            <div>
              <Button
                buttonStyle="secondary"
                htmlId="btnSecondary"
                disabled={disabledCancel}
                onClick={onCancel}
              >
                Cancel
              </Button>
              <Button
                buttonStyle="primary"
                htmlId="btnPrimary"
                disabled={disabledSave}
                onClick={() => onSave(state)}
              >
                Save
              </Button>
            </div>
          </div> */}
        </div>
      </Card>
    </div>
  );
};

export default ModalDialogWithHeader;
