import { getServiceContracts } from "../services/service-contracts.service";
import { getCustomerAndVehicle } from "../../page-wrapper/services/customer-vehicle.service";
import isEmpty from "lodash/isEmpty";

/**
 * Retrieves service contracts information based on dealer code, VIN, and common consumer ID.
 * If 'TEST_HOOK_NO_DATA_FOUND' is set to '1' in the URL parameters, simulates a data retrieval failure.
 *
 * @async
 * @function getServiceContractsInfo$
 * @param {string} dealerCode - The code of the dealer.
 * @param {string} vin - The vehicle identification number.
 * @param {string} commonConsumerId - The common consumer ID.
 * @param {string} schemaName - The schema name for the database.
 * @returns {Promise<Object|null>} The response from the service contracts retrieval or null if an error occurs.
 * @throws Will throw an error if the data retrieval fails.
 */
const getServiceContractsInfo$ = async (
  dealerCode,
  vin,
  commonConsumerId,
  schemaName
) => {
  try {
    const searchParams = new URLSearchParams(window.location.search);
    const testHook = searchParams.get("TEST_HOOK_NO_DATA_FOUND") === "1";

    if (testHook) {
      throw new Error(`Failed to retrieve data for ${vin || "this vehicle"}`);
    }

    const vehicleVin = searchParams.get("vin") || vin;
    const customerCommonConsumerId =
      searchParams.get("commonConsumerId") || commonConsumerId;
    const vehicleId = searchParams.get("vehicleId");
    const customerId = searchParams.get("customerId");

    if (
      isEmpty(vehicleVin) &&
      isEmpty(customerCommonConsumerId) &&
      !isEmpty(vehicleId) &&
      !isEmpty(customerId)
    ) {
      const { customer, vehicle } = await getCustomerAndVehicle({
        customerId,
        vehicleId,
        schemaName,
        dealerCode
      });

      return await getServiceContracts({
        dealerCode,
        vin: vehicle?.vin,
        commonConsumerId: customer?.ccId
      });
    }

    if (vehicleVin) {
      return await getServiceContracts({
        dealerCode,
        vin: vehicleVin,
        commonConsumerId: customerCommonConsumerId
      });
    }

    return [];
  } catch (error) {
    console.error("Error retrieving service contracts:", error);
    return null;
  }
};

/**
 * Formats vendor and product names into a string.
 *
 * @param {string} vendorName - The name of the vendor.
 * @param {string} productName - The name of the product.
 * @returns {string} A formatted string with the vendor and product names, or just one if the other is unavailable.
 * - If both vendorName and productName are provided, returns `${vendorName} - ${productName}`.
 * - If only vendorName is provided, returns the vendorName.
 * - If only productName is provided, returns the productName.
 * - If neither is provided, returns an empty string.
 */
function formatVendorProduct(vendorName, productName) {
  if (vendorName && productName) {
    return `${vendorName} - ${productName}`;
  } else if (vendorName) {
    return vendorName;
  } else if (productName) {
    return productName;
  } else {
    return "";
  }
}

/**
 * Transforms a list of service contracts into a vendor list that is categorized by "Service Contracts" and "Insurance".
 * The resulting list includes disabled labels and separators between the categories.
 *
 * @param {Array} serviceContracts - An array of service contract objects to be transformed.
 * @param {string} serviceContracts[].vendorName - The name of the vendor.
 * @param {string} serviceContracts[].productName - The name of the product.
 * @param {string} serviceContracts[].internalProductId - The internal ID of the product.
 * @param {string|null} serviceContracts[].type - The type of the contract (null for service contracts, "INS" for insurance).
 *
 * @returns {Array} The transformed vendor list including categories and separators.
 */
const getVendorListTransformed = serviceContracts => {
  let vendorList = [];

  if (Array.isArray(serviceContracts)) {
    // Add a disabled label for "Service Contracts"
    vendorList.push({
      vendor: "SERVICE CONTRACTS",
      internalProductId: "Service Contracts",
      disabled: true
    });

    // Filter and map service contracts (type null)
    const serviceContractsFiltered = serviceContracts
      .filter(sc => sc.type === null && sc?.vendorId)
      .map(sc => ({
        vendor: formatVendorProduct(sc?.vendorName, sc?.productName),
        internalProductId: sc.internalProductId,
        type: sc?.type
      }));

    // If no service contracts, add a "Does not apply" option
    if (serviceContractsFiltered.length === 0) {
      serviceContractsFiltered.push({
        vendor: "Does not apply",
        internalProductId: null,
        type: null,
        disabled: true
      });
    }

    // Concatenate service contracts to vendor list
    vendorList = vendorList.concat(serviceContractsFiltered);

    // Add a disabled label for "Insurance"
    vendorList.push({
      vendor: "INSURANCE",
      internalProductId: "Insurance",
      disabled: true
    });

    // Filter and map insurance contracts (type INS)
    const insuranceContractsFiltered = serviceContracts
      .filter(sc => sc.type === "INS" && sc?.vendorId)
      .map(sc => ({
        vendor: formatVendorProduct(sc?.vendorName, sc?.productName),
        internalProductId: sc.internalProductId,
        type: sc?.type,
        ...sc
      }));

    // If no insurance contracts, add a "Does not apply" option
    if (insuranceContractsFiltered.length === 0) {
      insuranceContractsFiltered.push({
        vendor: "Does not apply",
        internalProductId: null,
        type: "INS",
        disabled: true
      });
    }

    // Concatenate insurance contracts to vendor list
    vendorList = vendorList.concat(insuranceContractsFiltered);
  }

  return vendorList;
};

/**
 * Filters and returns only the service contracts from the provided list.
 * Service contracts are identified by having a `null` type.
 *
 * @param {Array} serviceContracts - An array of service contract objects to filter.
 * @param {string} serviceContracts[].vendorName - The name of the vendor.
 * @param {string} serviceContracts[].productName - The name of the product.
 * @param {string} serviceContracts[].internalProductId - The internal ID of the product.
 * @param {string|null} serviceContracts[].type - The type of the contract (null for service contracts, others for different contract types).
 *
 * @returns {Array} An array containing only service contracts (type `null`), or an empty array if the input is invalid or no contracts are found.
 */
const filterServiceContracts = serviceContracts => {
  if (!Array.isArray(serviceContracts)) return [];

  return serviceContracts.filter(sc => sc.type === null && sc?.vendorId);
};

/**
 * Checks if the given service contract is of type "INS" (insurance).
 *
 * @param {Object} serviceContract - The service contract object.
 * @param {string} serviceContract.type - The type of the service contract.
 * @returns {boolean} - Returns true if the service contract type is "INS", otherwise false.
 */
const isServiceContractInsuranceSelected = serviceContract => {
  return serviceContract?.type === "INS";
};

export {
  getServiceContractsInfo$,
  getVendorListTransformed,
  formatVendorProduct,
  filterServiceContracts,
  isServiceContractInsuranceSelected
};
