import { makeSecureRestApi } from "../../../../api/xmmAxios";

const sendEmail = (appEnv, appSource, data, dealerCode, confirmationId) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      appSource,
      appEnv
    };
    makeSecureRestApi(
      {
        url: `/notifications/${dealerCode}/email/${confirmationId}`,
        method: "post",
        data,
        headers
      },
      response => {
        resolve(response);
      },
      error => {
        reject(error);
      },
      "Unable to send email."
    );
  });
};

const getEmailConfig = (appEnv, appSource, schema, dealerCode) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      appSource,
      appEnv
    };
    makeSecureRestApi(
      {
        url: `/dealer/schema/${schema}/${dealerCode}/emails`,
        method: "get",
        headers
      },
      response => {
        resolve(response);
      },
      error => {
        reject(error);
      },
      "Unable to retrieve email configuration."
    );
  });
};

const getSMSConfig = (appEnv, appSource, schema, dealerCode) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      appSource,
      appEnv
    };
    makeSecureRestApi(
      {
        url: `/dealer/schema/${schema}/${dealerCode}/sms`,
        method: "get",
        headers
      },
      response => {
        resolve(response);
      },
      error => {
        reject(error);
      },
      "Unable to retrieve text message configuration."
    );
  });
};

const sendSMS = (
  appEnv,
  appSource,
  schemaName,
  dealerCode,
  data,
  confirmationId
) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      appSource,
      appEnv
    };
    makeSecureRestApi(
      {
        url: `/notifications/${schemaName}/dealerCode/${dealerCode}/sms/${confirmationId}`,
        method: "post",
        data,
        headers
      },
      response => {
        resolve(response);
      },
      error => {
        reject(error);
      },
      "Unable to send text message."
    );
  });
};

export default {
  sendEmail,
  getEmailConfig,
  sendSMS,
  getSMSConfig
};
