import React, { useState } from "react";
import SubletDetails from "./components/sublet-details.component";
import SplitCostSection from "./components/split-cost-section.container";
import PropTypes from "prop-types";
import { splitCostSubletProps } from "./sublet.types";
import { stripSubletPrefix } from "./utils/sublet.util";
import { SPLIT_SUBLET_DEFAULT } from "../../constants/adjustment.constant";

/**
 * Input form component for m2sublets.
 * Should be used within a PriceAdjustmentModal, which contains the header bar,
 * the model validation logic, and the Remove & Save buttons.
 * @param {object} props {object} Component configuration.
 * @param {object} props.sublet The sublet object.
 * @param {function} props.onUpdate Callback handler for when the sublet model changes.
 * @returns {JSX.Element}
 */
const SplitCostSublet = ({
  initialSublet = SPLIT_SUBLET_DEFAULT,
  onUpdate = () => {}
}) => {
  if (location.href.includes("CAUSE_SUBLET_MODAL_ERROR_FOR_TESTING")) {
    throw new Error("Causing an intentional error for testing purposes.");
  }

  const [sublet, setSublet] = useState(initialSublet);

  const updateSubletModel = payload => {
    setSublet(payload);
    onUpdate(payload);
  };

  const updatePOPricing = payload => {
    updateSubletModel({
      ...sublet,
      poPricing: { ...sublet.poPricing, ...payload }
    });
  };

  const handleTextInputChange = event => {
    updateSubletModel({
      ...sublet,
      [stripSubletPrefix(event.target.name)]: event.target.value
    });
  };

  const handleTextInputBlur = event => {
    updateSubletModel({
      ...sublet,
      [stripSubletPrefix(event.target.name)]: event.target.value.trim()
    });
  };

  if (!initialSublet?.poPricing) {
    return null;
  }

  return (
    <div className="sublet-form">
      <SubletDetails
        sublet={sublet}
        onUpdate={handleTextInputChange}
        onBlur={handleTextInputBlur}
      />
      <div className="separator" />
      <SplitCostSection
        initialPoPricing={initialSublet.poPricing}
        poPricing={sublet.poPricing}
        onUpdate={updatePOPricing}
      />
    </div>
  );
};

SplitCostSublet.propTypes = {
  initialSublet: splitCostSubletProps,
  onUpdate: PropTypes.func.isRequired
};

export default SplitCostSublet;
