const getAddedRecallServices = quoteConfirmationId => {
  const storageKey = `added-quote-${quoteConfirmationId}`;
  const sessionState = JSON.parse(sessionStorage.getItem(storageKey));
  const recallServices =
    sessionState !== null && sessionState.recallServices
      ? sessionState.recallServices
      : [];

  return recallServices;
};

export default {
  getAddedRecallServices
};
