/*
    Write constants, render functions, formatter, util methods used in past quotes grid component here
*/
import { QuoteStatusMap } from "../../../constants/module.constants";
import {
  formatDateTimezone,
  formatDateAndTime,
  formatTimezone
} from "../../../utils/date.util";
import { doesEmpty } from "../../../utils/object";
import isEmpty from "lodash/isEmpty";
import QuoteStatusConstant from "../../../constants/quote-status.constants";
import { technicianStatus } from "../../../constants/app.constants";
const QuoteStatusOptions = [
  {
    value: "ALL",
    label: QuoteStatusMap["ALL"]
  },
  {
    value: "IN_PROGRESS",
    label: QuoteStatusMap["IN_PROGRESS"]
  },
  {
    value: "REQUEST_ASSISTANCE",
    label: QuoteStatusMap["REQUEST_ASSISTANCE"]
  },
  {
    value: "EXPIRED",
    label: QuoteStatusMap["EXPIRED"]
  },
  {
    value: "CONVERTED_TO_APPOINTMENT",
    label: QuoteStatusMap["CONVERTED_TO_APPOINTMENT"]
  }
];
// @csr-logic
const CsrStatusOptions = [
  {
    value: "ALL",
    label: QuoteStatusMap["ALL"]
  },
  {
    value: "WITH_ADVISOR",
    label: QuoteStatusMap["WITH_ADVISOR"]
  },
  {
    value: "IN_PROCESS",
    label: QuoteStatusMap["IN_PROCESS"]
  },
  {
    value: "WORK_FINISHED",
    label: QuoteStatusMap["WORK_FINISHED"]
  },
  {
    value: "PRE_INVOICE",
    label: QuoteStatusMap["PRE_INVOICE"]
  },
  {
    value: "FINALIZED",
    label: QuoteStatusMap["FINALIZED"]
  }
];

function priceFormatter(params) {
  return `${formatPrice.format(params.value || 0)}`;
}

const formatPrice = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD"
});

function dateFormatter(params) {
  const dateStr = params?.value && new Date(params.value);
  return formatDateAndTime(dateStr, "en_US");
}

function expirationDateFormatter(params) {
  const { quoteStatus } = params.data;
  if (quoteStatus !== QuoteStatusConstant.CONVERTED_TO_APPOINTMENT) {
    const localDate = new Date(params.value);
    return formatDateTimezone(localDate, true, "en_US");
  } else {
    return "- -";
  }
}
function numberParser(params) {
  return Number(params.newValue);
}

const vehicleTooltipValueGetter = params => ({ value: params.value });

function allowEditQuote(quoteStatus) {
  return (
    quoteStatus === QuoteStatusConstant.PROGRESS ||
    quoteStatus === QuoteStatusConstant.REQUEST_ASSISTANCE ||
    quoteStatus === QuoteStatusConstant.SENT ||
    quoteStatus === QuoteStatusConstant.READY_TO_SEND ||
    quoteStatus === QuoteStatusConstant.CONVERTED_TO_APPOINTMENT ||
    quoteStatus === QuoteStatusConstant.EXPIRED
  );
}
// TODO - placeholder to stop opening edit quote slider for specific status
function proceedEditQuote(quoteStatus) {
  return !isEmpty(quoteStatus);
}

// @csr-logic
function allowEditCSR(csrStatus) {
  return (
    csrStatus === "WITH_ADVISOR" ||
    csrStatus === "IN_PROCESS" ||
    csrStatus === "WORK_FINISHED" ||
    csrStatus === "PRE_INVOICE" ||
    csrStatus === "FINALIZED"
  );
}

// method enhanced to support SQ vs CSR
function getStatusBadgeColor(status) {
  let color = "gray";
  switch (status) {
    case "IN_PROGRESS":
      color = "blue";
      break;
    case "CONVERTED_TO_APPOINTMENT":
      color = "green";
      break;
    case "REQUEST_ASSISTANCE":
      color = "red";
      break;
    case "SENT":
      color = "purple";
      break;
    case "READY_TO_SEND":
      color = "yellow";
      break;
    // @csr-logic
    case "WITH_ADVISOR":
      color = "gray";
      break;
    case "IN_PROCESS":
      color = "blue";
      break;
    case "WORK_FINISHED":
      color = "green";
      break;
    case "PRE_INVOICE":
      color = "orange";
      break;
    case "FINALIZED":
      color = "purple";
      break;
    default:
      break;
  }
  return color;
}

function allowEditAccessFromAction(quoteStatus) {
  const allowEdit =
    quoteStatus === "IN_PROGRESS" ||
    quoteStatus === "REQUEST_ASSISTANCE" ||
    quoteStatus === "SENT" ||
    quoteStatus === "READY_TO_SEND" ||
    quoteStatus === "CONVERTED_TO_APPOINTMENT" ||
    // @csr-logic
    quoteStatus === "WITH_ADVISOR" ||
    quoteStatus === "IN_PROCESS" ||
    quoteStatus === "PRE_INVOICE";
  return allowEdit;
}

function allowCopyAccess(customerId, vehicleId, quoteStatus) {
  if (doesEmpty(customerId) && doesEmpty(vehicleId)) {
    return false;
  }
  const allowEdit = quoteStatus === "IN_PROGRESS" || quoteStatus === "EXPIRED";
  return allowEdit;
}

function blankValueFormatter(params) {
  if (!params.data || !params.value) {
    return "- -";
  } else {
    return params.value;
  }
}

function addTimeZoneToHeader(header) {
  const now = new Date();
  const timezone = formatTimezone(now);
  return `${header} (${timezone})`;
}

function getTechnicianWorkingStatus(status) {
  switch (status) {
    case null:
      return technicianStatus.NOT_STARTED;
    case "WORKING":
      return technicianStatus.WORKING;
    case "STOP":
      return technicianStatus.STOP;
    case "HOLD_PARTS":
      return technicianStatus.HOLD_PARTS;
    case "HOLD_APPROVAL":
      return technicianStatus.HOLD_APPROVAL;
    case "BREAK":
      return technicianStatus.BREAK;
    default:
      return technicianStatus.NOT_STARTED;
  }
}

export {
  QuoteStatusOptions,
  CsrStatusOptions,
  priceFormatter,
  dateFormatter,
  expirationDateFormatter,
  numberParser,
  vehicleTooltipValueGetter,
  allowEditQuote,
  allowEditCSR,
  getStatusBadgeColor,
  allowEditAccessFromAction,
  allowCopyAccess,
  proceedEditQuote,
  blankValueFormatter,
  addTimeZoneToHeader,
  getTechnicianWorkingStatus
};
