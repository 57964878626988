// @ts-check
import React from "react";
// eslint-disable-next-line unused-imports/no-unused-imports
import * as CommonTypes from "../../../../../@types/common.types";

/**
 * @description Layout component only. Used to wrap the entire dashboard.
 * @param {{children: any}} props
 * @returns {CommonTypes.Component}
 */
const DashboardWrapper = ({ children }) => {
  return <div className="dashboard__wrapper">{children}</div>;
};

export default DashboardWrapper;
