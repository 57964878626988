import React from "react";
import PropTypes from "prop-types";
import Card from "@cx/ui/Card";
import WarningIcon from "@cx/ui/Icons/IconWarning";
import CompleteIcon from "@cx/ui/Icons/IconComplete";
import CloseIcon from "@cx/ui/Icons/IconClose";
import IconInfoOutline from "@cx/ui/Icons/IconInfoOutline";

import "./Banner.scss";

const Banner = props => {
  const { type, children, ...restProps } = props;

  const iconMap = {
    warning: (
      <div id="iconContainer" className="warning">
        <WarningIcon id="warningIcon" />
      </div>
    ),
    info: (
      <div id="iconContainer" className="info">
        <CompleteIcon id="infoIcon" />
      </div>
    ),
    danger: (
      <div id="iconContainer" className="danger">
        <CloseIcon id="dangerIcon" />
      </div>
    ),
    notice: (
      <div id="iconContainer" className="info">
        <IconInfoOutline id="noticeIcon" />
      </div>
    )
  };

  return (
    <Card id="bannerContainer" {...restProps}>
      {iconMap[type]}
      <span id="bannerText">{children}</span>
    </Card>
  );
};

Banner.defaultProps = {
  type: "info"
};

Banner.propTypes = {
  type: PropTypes.oneOf(["info", "warning", "danger", "notice"])
};

export default Banner;
