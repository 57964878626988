import React, { useContext } from "react";
import { AppContext } from "../../../../state/app-context";
import { Header, CustomerVehicleDetails, EventLog } from "./components";

const ArchivedEventLog = () => {
  const appContext = useContext(AppContext);
  const { dealer } = appContext;

  const repairOrder = JSON.parse(window["repairOrder"]);

  if (!repairOrder) {
    return "No data found";
  }

  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop)
  });
  const roNumber = params.roNumber;
  const { customer, vehicle, mileageIn, mileageOut, eventLogs } = repairOrder;

  const getActivityLogs = () => {
    // event logs can be null whichs need to be handled
    if (!eventLogs || !Array.isArray(eventLogs)) return [];

    return eventLogs.map(
      ({ description, event, sequenceNumber, timestamp, user }) => {
        const getDisplayName = () => {
          // To match old service log component behavior, display lastName and firstName separated by comma and space
          const [lastName, firstName] = user ? user.split(",") : [];

          if (lastName && firstName) {
            return `${lastName.trim()}, ${firstName.trim()}`;
          } else if (lastName) {
            return lastName.trim();
          } else if (firstName) {
            return firstName.trim();
          }

          return "--";
        };

        return {
          description,
          event,
          serviceLineNumber: sequenceNumber,
          timestamp,
          user: getDisplayName()
        };
      }
    );
  };

  return (
    <div className="activity-log-container">
      <Header roNumber={roNumber} />
      <CustomerVehicleDetails
        customer={customer}
        vehicle={vehicle}
        dealerCountryCode={dealer?.dealerCountryCode}
        mileageIn={mileageIn}
        mileageOut={mileageOut}
      />
      <EventLog roNumber={roNumber} logs={getActivityLogs()} />
    </div>
  );
};

export default ArchivedEventLog;
