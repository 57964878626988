import React from "react";
import Button from "@cx/ui/Button";
import Alert from "@cx/ui/Alert";
import PropTypes from "prop-types";
import "./service-comeback.scss";

const ServiceComebackBanner = ({
  canModify,
  comebackRO,
  containerClassName,
  onModify
}) => (
  <Alert
    htmlId="serviceComebackBanner"
    type="info"
    className={containerClassName}
  >
    <div className="service-comeback-banner-content">
      {comebackRO
        ? `This service is a comeback for RO #${comebackRO}.`
        : "This service was marked as not a comeback."}
      {canModify ? (
        <Button
          buttonStyle="link"
          htmlId="modifyComebackButton"
          size="small"
          onClick={onModify}
        >
          Modify
        </Button>
      ) : null}
    </div>
  </Alert>
);

ServiceComebackBanner.defaultProps = {
  canModify: false,
  comebackRO: "",
  containerClassName: ""
};

ServiceComebackBanner.propTypes = {
  canModify: PropTypes.bool,
  comebackRO: PropTypes.number,
  containerClassName: PropTypes.string,
  onModify: PropTypes.func
};

export default ServiceComebackBanner;
