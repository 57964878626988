import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import ButtonToolbar from "react-bootstrap/lib/ButtonToolbar";
import ModalDialog from "@cx/ui/ModalDialog";
import themePrefixer from "@cx/ui/themePrefixer";
import Button from "@cx/ui/Button";
import PriceInput from "@cx/ui/PriceInput";

const ModifyDeductibleModal = ({
  show,
  deductible,
  vendorName,
  onCancel,
  onSave
}) => {
  const [amount, setAmount] = useState(deductible?.amount || "");

  const handleChangeAmount = (cxEvent, isValid, domEvent) => {
    setAmount(cxEvent.target.value);
  };

  const handleSave = () => {
    const payload = {
      transfers: [{ ...deductible, amount: Number(amount) }],
      payerId: deductible.targetPayer
    };

    onSave(payload);
  };

  return (
    <ModalDialog
      htmlId="removePartModal"
      className="core-return-modal"
      show={show}
      backdrop="static"
      header={
        <h4 className={`${themePrefixer()}modal-title`}>Modify deductible</h4>
      }
      footer={
        <div className="lookup-modal-footer">
          <ButtonToolbar>
            <Button
              htmlId="removePartCancelBtn"
              type="button"
              buttonStyle="secondary"
              onClick={onCancel}
            >
              Cancel
            </Button>
            <Button
              htmlId="removePartSaveBtn"
              type="button"
              buttonStyle="primary"
              onClick={handleSave}
              disabled={!amount}
            >
              Apply
            </Button>
          </ButtonToolbar>
        </div>
      }
      onHide={onCancel}
    >
      <>
        <p>{vendorName || deductible?.name}</p>
        <PriceInput
          htmlId="deductibleInput"
          label="Deductible"
          name="deductibleInput"
          onChange={handleChangeAmount}
          value={String(amount)}
          required
        />
      </>
    </ModalDialog>
  );
};

ModifyDeductibleModal.propTypes = {
  show: PropTypes.bool.isRequired,
  deductible: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired
};

export default ModifyDeductibleModal;
