/* eslint-disable react/prop-types */
import React, { Component } from "react";
import {
  isCopyAndPaste,
  isDeleteOrBackspace,
  isKeyPressedPrintable,
  isLeftOrRight,
  isNavKeyPressed
} from "../utils/keyNavigation";
import { sanitize } from "../utils/string";

const KEY_F2 = 113;

export default class TextEditor extends Component {
  constructor(props) {
    super(props);

    this.inputRef = React.createRef();
    this.cancelBeforeStart = false;
    this.state = this.createInitialState(props);

    this.onKeyDown = this.onKeyDown.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    this.inputRef.current.addEventListener("keydown", this.onKeyDown);
    this.afterGuiAttached();
  }

  componentWillUnmount() {
    this.inputRef.current.removeEventListener("keydown", this.onKeyDown);
  }

  // getMaxLength(field) {
  //   if (field === "priceCaption") {
  //     return 64;
  //   } else if (field === "priorityOpcodeStrings") {
  //     return 32;
  //   } else if (field === "description") {
  //     return 32;
  //   } else if (field === "laborRateCode") {
  //     return 10;
  //   }
  //   return -1;
  // }
  createInitialState(props) {
    let startValue;
    let highlightAllOnFocus = true;
    const { maxLength } = props;
    const maxlength = maxLength ? maxLength : -1;
    // this.getMaxLength(props.colDef.field);

    const event = { keyCode: props.keyPress };
    if (isDeleteOrBackspace(event)) {
      // if backspace or delete pressed, we clear the cell
      startValue = "";
    } else if (props.charPress) {
      // if a letter was pressed, we start with the letter
      startValue = props.charPress;
      highlightAllOnFocus = false;
    } else {
      // otherwise we start with the current value
      startValue = props.value;
      if (props.keyPress === KEY_F2) {
        highlightAllOnFocus = false;
      }
    }

    return {
      maxlength,
      value: startValue,
      highlightAllOnFocus
    };
  }

  afterGuiAttached() {
    // get ref from React component
    const eInput = this.inputRef.current;
    eInput.focus();
    if (this.state.highlightAllOnFocus) {
      eInput.select();

      this.setState({
        highlightAllOnFocus: false
      });
    } else {
      // when we started editing, we want the carot at the end, not the start.
      // this comes into play in two scenarios: a) when user hits F2 and b)
      // when user hits a printable character, then on IE (and only IE) the carot
      // was placed after the first character, thus 'apply' would end up as 'pplea'
      const length = eInput.value ? eInput.value.length : 0;
      if (length > 0) {
        eInput.setSelectionRange(length, length);
      }
    }
  }

  getValue() {
    return this.state.value;
  }

  isCancelBeforeStart() {
    return this.cancelBeforeStart;
  }

  // will reject the number if it greater than 1,000,000
  // not very practical, but demonstrates the method.
  isCancelAfterEnd() {
    return false; // this.state.value > 1000000;
  }

  onKeyDown(event) {
    if (isLeftOrRight(event) || isDeleteOrBackspace(event)) {
      event.stopPropagation();
      return false;
    }

    if (
      !(
        isNavKeyPressed(event) ||
        isKeyPressedPrintable(event) ||
        isCopyAndPaste(event)
      )
    ) {
      if (event.preventDefault) {
        event.preventDefault();
        return false;
      }
    }
    return true;
  }
  handleChange(event) {
    // prevent copy and paste of non-numeric character
    const { value } = event.target;
    let newValue = value;
    if (this.props.colDef && this.props.colDef.field === "userName" && value) {
      newValue = value.replace(" ", "");
    }
    // if (isEmptyString(value) || isAllDigits(value)) {
    this.setState({ value: sanitize(newValue) });
    // }
  }
  render() {
    return (
      <input
        ref={this.inputRef}
        maxLength={this.state.maxlength}
        value={this.state.value}
        onChange={this.handleChange}
        // style={{ width: "100%" }}
        className="xmm-cell-editor"
      />
    );
  }
}
