/**
 * Common util can be call directly to apply masking effect to cover entire browser page
 */
function showBodyMask() {
  const rootElement = document.getElementById("bodymask");
  const name = "xmm-loading-mask";
  const arr = rootElement.className.split(" ");
  if (arr.indexOf(name) === -1) {
    rootElement.className += " " + name;
  }
}
// Common Util to remove masking effect covered at browser page
function hideBodyMask() {
  const rootElement = document.getElementById("bodymask");
  rootElement.className = rootElement.className.replace(
    /\bxmm-loading-mask\b/g,
    ""
  );
}

export { showBodyMask, hideBodyMask };
