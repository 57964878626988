import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import Button from "@cx/ui/Button";
import NumericInput from "@cx/ui/NumericInput";
import PriceInput from "@cx/ui/PriceInput";
import TextArea from "@cx/ui/TextArea";
import TextInput from "@cx/ui/TextInput";
import format from "../../utils/format";
import parse from "../../utils/parse";
import csrUtil from "../../utils/csr-util";
import {
  SUBLET_MAX_BASE_COST,
  SUBLET_MAX_MARKUP_DOLLARS,
  SUBLET_MAX_MARKUP_PERCENT
} from "../../constants/adjustment.constant";
import "./sublet.scss";

/*
DEV NOTES:

- The form fields correspond to the sublet data model properties as follows:
  - Description: vendorNotes
  - PO Number: poNumber
  - Invoice Number: invoiceNumber
  - Vendor: vendor
  - Cost: poPricing.baseCost
  - Markup: either poPricing.markupDollars or poPricing.markupPercent, depending on the toggle
- The sublet.poPricing.markupType field should be a string containing either "$" or "%".
- The other sublet.poPricing fields (baseCost, markupDollars, markupPercent, subletTotalCost)
  should always contain numbers, not strings.
- The raw input values for the Cost and Markup form fields are stored separately in state as
  costInputValue & markupInputValue, which are always strings.
*/

/**
 * Input form component for sublets.
 * Should be used within a PriceAdjustmentModal, which contains the header bar,
 * the model validation logic, and the Remove & Save buttons.
 * @param {object} props {object} Component configuration.
 * @param {object} props.sublet The sublet object.
 * @param {function} props.onUpdate Callback handler for when the sublet model changes.
 * @returns {JSX.Element}
 */
function Sublet(props) {
  // TEST HOOK
  if (location.href.includes("CAUSE_SUBLET_MODAL_ERROR_FOR_TESTING")) {
    throw new Error("Causing an intentional error for testing purposes.");
  }

  // Formats the markup input value depending on the markup type.
  const formatMarkupInputValue = poPricing => {
    return poPricing.markupType === "$"
      ? format.currencyInput(poPricing.markupDollars)
      : format.decimalInput(poPricing.markupPercent, 3);
  };

  const [isEditingCost, setIsEditingCost] = useState(false);
  const [isEditingMarkup, setIsEditingMarkup] = useState(false);
  const [sublet, setSublet] = useState(props.sublet);
  const [costInputValue, setBaseCostInputValue] = useState(
    props.sublet ? format.currencyInput(props.sublet.poPricing.baseCost) : ""
  );
  const [markupInputValue, setMarkupInputValue] = useState(
    props.sublet ? formatMarkupInputValue(props.sublet.poPricing) : ""
  );

  // Formats the numeric input field values from the sublet state.
  const formatNumericInputValues = useCallback(() => {
    setBaseCostInputValue(format.currencyInput(sublet.poPricing.baseCost));
    setMarkupInputValue(formatMarkupInputValue(sublet.poPricing));
  }, [sublet.poPricing]);

  useEffect(
    function onSubletUpdated() {
      if (!isEditingCost && !isEditingMarkup) {
        formatNumericInputValues();
      }
    },
    [formatNumericInputValues, isEditingCost, isEditingMarkup, sublet]
  );

  const updateSubletModel = payload => {
    setSublet(payload);
    props.onUpdate(payload);
  };

  const updateSubletPOPricing = payload => {
    updateSubletModel({
      ...sublet,
      poPricing: payload
    });
  };

  const changeMarkupType = cxEvent => {
    const markupType = cxEvent.target.value;
    const markupDollars =
      markupType === "$" ? parse.currency(markupInputValue, 0) : null;
    const markupPercent =
      markupType === "%" ? parse.decimal(markupInputValue, 3, 0) : null;
    const subletTotalCost = csrUtil.calculateSubletTotalCost(
      sublet.poPricing.baseCost,
      markupType,
      markupDollars,
      markupPercent
    );
    updateSubletModel({
      ...sublet,
      poPricing: {
        ...sublet.poPricing,
        markupType,
        markupDollars,
        markupPercent,
        subletTotalCost
      }
    });
  };

  const onInputChange = cxEvent => {
    const fieldName = cxEvent.target.name;
    const fieldValue = cxEvent.target.value;

    if (fieldName === "subletBaseCost") {
      setBaseCostInputValue(fieldValue);
      const baseCost = parse.currency(fieldValue, 0);
      const subletTotalCost = csrUtil.calculateSubletTotalCost(
        baseCost,
        sublet.poPricing.markupType,
        sublet.poPricing.markupDollars,
        sublet.poPricing.markupPercent
      );
      updateSubletPOPricing({ ...sublet.poPricing, baseCost, subletTotalCost });
    } else if (fieldName === "subletMarkup") {
      setMarkupInputValue(fieldValue);
      const markupDollars =
        sublet.poPricing.markupType === "$"
          ? parse.currency(fieldValue, 0)
          : null;
      const markupPercent =
        sublet.poPricing.markupType === "%"
          ? parse.decimal(fieldValue, 3, 0)
          : null;
      const subletTotalCost = csrUtil.calculateSubletTotalCost(
        sublet.poPricing.baseCost,
        sublet.poPricing.markupType,
        markupDollars,
        markupPercent
      );
      updateSubletPOPricing({
        ...sublet.poPricing,
        markupDollars,
        markupPercent,
        subletTotalCost
      });
    } else {
      // When another input value changes, just update that sublet field in the state.
      switch (fieldName) {
        case "subletVendorNotes":
          updateSubletModel({ ...sublet, vendorNotes: fieldValue });
          break;
        case "subletPoNumber":
          updateSubletModel({ ...sublet, poNumber: fieldValue });
          break;
        case "subletInvoiceNumber":
          updateSubletModel({ ...sublet, invoiceNumber: fieldValue });
          break;
        case "subletVendor":
          updateSubletModel({ ...sublet, vendor: fieldValue });
          break;
      }
    }
  };

  // When one of the input fields loses focus, tidy up the user input.
  const onInputBlur = cxEvent => {
    const fieldValue = cxEvent.target.value;
    switch (cxEvent.target.name) {
      case "subletBaseCost":
        setIsEditingCost(false);
        setBaseCostInputValue(format.currencyInput(sublet.poPricing.baseCost));
        break;
      case "subletMarkup":
        setIsEditingMarkup(false);
        setMarkupInputValue(formatMarkupInputValue(sublet.poPricing));
        break;
      case "subletVendorNotes":
        updateSubletModel({ ...sublet, vendorNotes: fieldValue.trim() });
        break;
      case "subletPoNumber":
        updateSubletModel({ ...sublet, poNumber: fieldValue.trim() });
        break;
      case "subletInvoiceNumber":
        updateSubletModel({ ...sublet, invoiceNumber: fieldValue.trim() });
        break;
      case "subletVendor":
        updateSubletModel({ ...sublet, vendor: fieldValue.trim() });
        break;
    }
  };

  if (!props?.sublet?.poPricing) {
    return <div />;
  }

  return (
    <div className="sublet-form">
      <TextArea
        required
        maxLength={200}
        htmlId="subletVendorNotes"
        name="subletVendorNotes"
        label="Description"
        value={sublet.vendorNotes}
        onChange={onInputChange}
        onBlur={onInputBlur}
        autoFocus
      />
      <TextInput
        required
        maxLength={30}
        htmlId="subletPoNumber"
        name="subletPoNumber"
        label="PO number"
        value={sublet.poNumber}
        onChange={onInputChange}
        onBlur={onInputBlur}
      />
      <TextInput
        maxLength={30}
        htmlId="subletInvoiceNumber"
        name="subletInvoiceNumber"
        label="Invoice number"
        value={sublet.invoiceNumber}
        onChange={onInputChange}
        onBlur={onInputBlur}
      />
      <TextInput
        required
        maxLength={50}
        htmlId="subletVendor"
        name="subletVendor"
        label="Vendor"
        value={sublet.vendor}
        onChange={onInputChange}
        onBlur={onInputBlur}
      />
      <PriceInput
        required
        minValue={0.01}
        maxValue={SUBLET_MAX_BASE_COST}
        maxLength={10}
        htmlId="subletBaseCost"
        name="subletBaseCost"
        label="Cost"
        value={costInputValue}
        onFocus={() => setIsEditingCost(true)}
        onChange={onInputChange}
        onBlur={onInputBlur}
      />
      <div className="sublet-markup-group">
        <label htmlFor="subletMarkup" className="required-field">
          Markup
        </label>
        <div className="sublet-markup-value-group">
          <div className="btn-group">
            <Button
              htmlId="subletMarkupDollarButton"
              buttonStyle={
                sublet.poPricing.markupType === "$" ? "primary" : "secondary"
              }
              value="$"
              onClick={changeMarkupType}
            >
              $
            </Button>
            <Button
              buttonStyle={
                sublet.poPricing.markupType === "%" ? "primary" : "secondary"
              }
              htmlId="subletMarkupPercentButton"
              value="%"
              onClick={changeMarkupType}
            >
              %
            </Button>
          </div>
          <NumericInput
            required
            label=""
            htmlId="subletMarkup"
            name="subletMarkup"
            className="sublet-markup"
            allowDecimal
            decimalMaxLength={2}
            maxLength={7}
            autoInsertCommas={true}
            value={markupInputValue}
            maxValue={
              sublet.poPricing.markupType === "$"
                ? SUBLET_MAX_MARKUP_DOLLARS
                : SUBLET_MAX_MARKUP_PERCENT
            }
            onFocus={() => setIsEditingMarkup(true)}
            onChange={onInputChange}
            onBlur={onInputBlur}
          />
        </div>
      </div>
      <div
        className={`sublet-cost-section ${
          sublet.poPricing.subletTotalCost >= 100000000000 ? "invalid" : ""
        }`}
      >
        <span className="total-sublet-label">TOTAL SUBLET</span>
        <span className="sublet-cost">
          {format.currency(sublet.poPricing.subletTotalCost)}
        </span>
      </div>
    </div>
  );
}

Sublet.propTypes = {
  sublet: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired
};

export default Sublet;
