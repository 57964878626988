/**
 * TEMPORARY CODE ONLY:
 * Assigns granular application permissions based on the user's coarse-grained "role permissions".
 * This function can be deleted once the granular permissions are set up in Exeter and mapped to roles.
 */
const applyCSRPermissions = userPermissions => {
  // Service Manager permissions
  if (userPermissions.canPerformServiceManagerActions) {
    Object.assign(userPermissions, {
      canSearchArchivedROs: true,
      canUpdateROAttributes: true,
      canViewDocuments: true,
      canApproveEstimate: true,
      canStartInvoicing: true,
      canFinalizeRO: true,
      canViewAllROServices: true,
      canAddService: true,
      canRemoveService: true,
      canModifyComebackService: true,
      canAddParts: true,
      canUpdatePartsQuantity: true,
      canUpdatePartsDescription: true,
      canUpdatePartsPricing: true,
      canRemoveParts: true,
      canUpdateServiceAttributes: true,
      canViewTaxCode: true,
      canViewLaborHours: true,
      canViewCostDepartmentAllocation: true,
      canUpdateCustomerConcern: true,
      canAddTechRowsForAnyone: true,
      canUpdateExistingTechRowTechnician: true,
      canUpdateTechRowCorrectionCode: true,
      canUpdateTechRowDate: true,
      canUpdateTechRowFlagTime: true,
      canUpdateTechRowWorkType: true,
      canRunOwnTechnicianClock: true,
      canRunAnyTechnicianClock: true,
      canUpdatePrimaryTechnician: true,
      canRemoveTechnicianRow: true,
      canOverrideTechTime: true,
      canViewPrices: true,
      canViewFeesDiscountsSublets: true,
      canUpdateFeesDiscountsSublets: true,
      canEditService: true,
      canMarkServiceComplete: true
    });
  }

  // Service Advisor and Warranty Admin permissions (currently identical)
  if (
    userPermissions.canPerformServiceAdvisorActions ||
    userPermissions.canPerformWarrantyAdminActions
  ) {
    Object.assign(userPermissions, {
      canSearchArchivedROs: true,
      canUpdateROAttributes: true,
      canViewDocuments: true,
      canApproveEstimate: true,
      canStartInvoicing: true,
      canFinalizeRO: true,
      canViewAllROServices: true,
      canAddService: true,
      canRemoveService: true,
      canModifyComebackService: true,
      canAddParts: true,
      canUpdatePartsQuantity: true,
      canUpdatePartsDescription: true,
      canUpdatePartsPricing: true,
      canRemoveParts: true,
      canUpdateServiceAttributes: true,
      canViewTaxCode: true,
      canViewLaborHours: true,
      canViewCostDepartmentAllocation: true,
      canUpdateCustomerConcern: true,
      canAddTechRowsForAnyone: true,
      canUpdateExistingTechRowTechnician: true,
      canUpdateTechRowCorrectionCode: true,
      canUpdateTechRowFlagTime: true,
      canUpdateTechRowWorkType: true,
      canUpdatePrimaryTechnician: true,
      canRemoveTechnicianRow: true,
      canViewPrices: true,
      canViewFeesDiscountsSublets: true,
      canUpdateFeesDiscountsSublets: true,
      canEditService: true,
      canMarkServiceComplete: true
    });
  }

  // Technician permissions
  if (userPermissions.canPerformTechnicianActions) {
    Object.assign(userPermissions, {
      canUpdateROMileageOut: true,
      canAddTechRowsForSelf: true,
      canRunOwnTechnicianClock: true,
      canEditService: true,
      canMarkServiceComplete: true
    });
  }

  // Parts permissions
  if (userPermissions.canPerformPartsActions) {
    Object.assign(userPermissions, {
      canViewAllROServices: true,
      canAddParts: true,
      canUpdatePartsQuantity: true,
      canUpdatePartsDescription: true,
      canApproveParts: true,
      canRemoveParts: true,
      canViewPrices: true,
      canPrintPickList: true,
      canAddToPurchaseOrder: true
    });
  }

  // Set granular permissions that are implied by (or necessitated by) other permissions.
  // This is to account for likely human errors when assigning permissions in Exeter.
  if (userPermissions.canUpdateROAttributes) {
    userPermissions.canUpdateROMileageOut = true;
  }
  if (userPermissions.canUpdateFeesDiscountsSublets) {
    userPermissions.canViewFeesDiscountsSublets = true;
  }

  return userPermissions;
};

/**
 * Builds a simple object for checking user permissions.
 * @param rawPermissions {object} Permissions array from the user details API response.
 * @returns {object} New object containing a boolean property for each user permission.
 */
// prettier-ignore
const buildUserPermissions = (user, appType) => {
  const isCSR = appType === "CSR";
  const isQuoting = appType === "SQ";
  const rawPermissions = user.serviceQuotingPermissions;

  // Checks whether there is a permission with the specified key.
  const hasPermission = key => rawPermissions.some(p => p.key === key);

  // Builds the full object with a property for each application permission.
  let userPermissions = {
    // older permissions implemented for Quoting
    canAccessServiceQuoting: hasPermission("ACCESS_SERVICE_QUOTING"),
    canCreateQuote: hasPermission("CREATE_QUOTE"),
    canEditQuote: hasPermission("ALLOW_EDIT_QUOTE"),
    canUpdateQuoteService: hasPermission("ALLOW_UPDATE_QUOTE_SERVICE"),
    canUpdateQuoteServicePart: hasPermission("ALLOW_UPDATE_QUOTE_SERVICE_PART"),
    canCreateReservationForConsumer: hasPermission("CREATE_RESERVATION_FOR_CONSUMER"),
    canAccessDealerQuoteSettings: hasPermission("ALLOW_DEALER_QUOTE_SETTINGS"),
    // role-level permissions
    canPerformServiceManagerActions: hasPermission("IS_SERVICE_MANAGER"),
    canPerformServiceAdvisorActions: hasPermission("IS_SERVICE_ADVISOR"),
    canPerformWarrantyAdminActions: hasPermission("IS_WARRANTY_ADMIN"),
    canPerformTechnicianActions: hasPermission("IS_TECHNICIAN"),
    canPerformPartsActions: hasPermission("IS_PARTS_PERSON"),
    // granular permissions for Service RO
    canSearchArchivedROs: hasPermission("SEARCH_ARCHIVED_ROS"),
    canUpdateROAttributes: hasPermission("UPDATE_RO_ATTRIBUTES"),
    canUpdateROAdvisor: hasPermission("UPDATE_RO_ADVISOR"),
    canUpdateROPrimaryTechnician: hasPermission("UPDATE_RO_PRIMARY_TECHNICIAN"),
    canUpdateROHangTag: hasPermission("UPDATE_RO_HANG_TAG"),
    canUpdateROTransportationType: hasPermission("UPDATE_RO_TRANSPORTATION_TYPE"),
    canUpdateROPromisedTime: hasPermission("UPDATE_RO_PROMISED_TIME"),
    canUpdateROMileageIn: hasPermission("UPDATE_RO_MILEAGE_IN"),
    canUpdateROMileageOut: hasPermission("UPDATE_RO_MILEAGE_OUT"),
    canViewDocuments: hasPermission("VIEW_DOCUMENTS"),
    canApproveEstimate: hasPermission("APPROVE_ESTIMATE"),
    canStartInvoicing: hasPermission("START_INVOICING"),
    canFinalizeRO: hasPermission("FINALIZE_RO"),
    canViewAllROServices: isQuoting || hasPermission("VIEW_ALL_RO_SERVICES"),
    canAddService: isQuoting || hasPermission("ADD_SERVICE"),
    canRemoveService: isQuoting || hasPermission("REMOVE_SERVICE"),
    canModifyComebackService: isQuoting || hasPermission("MODIFY_COMEBACK_SERVICE"),
    canAddParts: isQuoting || hasPermission("ADD_PARTS"),
    canUpdatePartsQuantity: isQuoting || hasPermission("UPDATE_PARTS_QUANTITY"),
    canUpdatePartsDescription: isQuoting || hasPermission("UPDATE_PARTS_DESCRIPTION"),
    canUpdatePartsPricing: isQuoting || hasPermission("UPDATE_PARTS_PRICING"),
    canApproveParts: hasPermission("APPROVE_PARTS"),
    canRemoveParts: isQuoting || hasPermission("REMOVE_PARTS"),
    canUpdateServiceAttributes: isQuoting || hasPermission("UPDATE_SERVICE_ATTRIBUTES"),
    canViewTaxCode: isQuoting || hasPermission("VIEW_TAX_CODE"),
    canViewLaborHours: isQuoting || hasPermission("VIEW_LABOR_HOURS"),
    canViewCostDepartmentAllocation: isQuoting || hasPermission("VIEW_COST_DEPARTMENT_ALLOCATION"),
    canUpdateCustomerConcern: isQuoting || hasPermission("UPDATE_CUSTOMER_CONCERN"),
    canAddTechRowsForSelf: isQuoting || hasPermission("ADD_TECH_ROWS_FOR_SELF"),
    canAddTechRowsForAnyone: isQuoting || hasPermission("ADD_TECH_ROWS_FOR_ANYONE"),
    canUpdateExistingTechRowTechnician: isQuoting || hasPermission("UPDATE_TECH_ROW_TECHNICIAN"),
    canUpdateTechRowDate: isQuoting || hasPermission("UPDATE_TECH_ROW_DATE"),
    canUpdateTechRowCorrectionCode: isQuoting || hasPermission("UPDATE_TECH_ROW_CORRECTION_CODE"),
    canUpdateTechRowFlagTime: isQuoting || hasPermission("UPDATE_TECH_ROW_FLAG_TIME"),
    canUpdateTechRowWorkType: isQuoting || hasPermission("UPDATE_TECH_ROW_WORK_TYPE"),
    canRunOwnTechnicianClock: hasPermission("RUN_OWN_TECHNICIAN_CLOCK"),
    canRunAnyTechnicianClock: hasPermission("RUN_ANY_TECHNICIAN_CLOCK"),
    canUpdatePrimaryTechnician: isQuoting || hasPermission("UPDATE_PRIMARY_TECHNICIAN"),
    canRemoveTechnicianRow: isQuoting || hasPermission("DELETE_TECHNICIAN_ROW"),
    canOverrideTechTime: hasPermission("OVERRIDE_TECH_TIME"),
    canViewPrices: isQuoting || hasPermission("VIEW_PRICES"),
    canViewFeesDiscountsSublets: isQuoting || hasPermission("VIEW_FEES_DISCOUNTS_SUBLETS"),
    canUpdateFeesDiscountsSublets: isQuoting || hasPermission("UPDATE_FEES_DISCOUNTS_SUBLETS"),
    canAddToPurchaseOrder: isQuoting || hasPermission("ADD_TO_PURCHASE_ORDER"),
    canPrintPickList: isQuoting || hasPermission("PRINT_PICK_LIST"),
    canEditService: isQuoting || hasPermission("EDIT_SERVICE"),
    canMarkServiceComplete: hasPermission("MARK_SERVICE_COMPLETE")
  };

  if (isCSR) {
    userPermissions = applyCSRPermissions(userPermissions);
  }

  // Temporary property to provide backwards compatibility for the PartsLookupModule subcomponent.
  // Keep in place until the released version of PartsLookupModule does not contain any references
  // to hasUpdateQuoteServicePartPermission.
  userPermissions.hasUpdateQuoteServicePartPermission = userPermissions.canUpdateQuoteServicePart;

  return userPermissions;
};

export { buildUserPermissions };
