import { makeSecureRestApi } from "../../../api/xmmAxios";

const getPastQuotes = (dealerCode, numberOfDays, quoteStatus) => {
  const headers = {
    Accept: "application/json"
  };

  let filteredQuoteStatus = "";
  if (quoteStatus && quoteStatus !== "ALL") {
    filteredQuoteStatus = quoteStatus;
  }

  const restEndPoint = `/pastQuotes/${dealerCode}`;

  return new Promise((resolve, reject) => {
    makeSecureRestApi(
      {
        url: restEndPoint,
        method: "get",
        data: {},
        params: {
          numberOfDays,
          ...(filteredQuoteStatus ? { statuses: filteredQuoteStatus } : {})
        },
        headers
      },
      response => {
        resolve(response);
      },
      error => {
        reject(error);
      },
      "Unable to load CSR details."
    );
  });
};

const getPastQuotesForTechnicianView = (
  dealerCode,
  numberOfDays,
  quoteStatus,
  techId
) => {
  const headers = {
    Accept: "application/json"
  };

  let filteredQuoteStatus = "";
  if (quoteStatus && quoteStatus !== "ALL") {
    filteredQuoteStatus = quoteStatus;
  }

  const restEndPoint = `/pastQuotes/${dealerCode}`;

  return new Promise((resolve, reject) => {
    makeSecureRestApi(
      {
        url: restEndPoint,
        method: "get",
        data: {},
        params: {
          numberOfDays,
          techId,
          ...(filteredQuoteStatus ? { statuses: filteredQuoteStatus } : {})
        },
        headers
      },
      response => {
        resolve(response);
      },
      error => {
        reject(error);
      },
      "Unable to load list of repair orders."
    );
  });
};

// API call to update quote notes under quote
const updatePastQuoteNotes = ({
  dealerCode,
  confirmationId,
  lastModByUserId,
  notes
}) => {
  const restUrl = `quote/${dealerCode}/${confirmationId}`;
  return new Promise((resolve, reject) => {
    makeSecureRestApi(
      {
        url: restUrl,
        method: "patch",
        data: {
          notes,
          lastModByUserId
        }
      },
      response => {
        resolve(response);
      },
      error => {
        reject(error);
      },
      "Unable to save notes."
    );
  });
};

const getFinalizedPastQuotes = (dealerCode, customerId) => {
  const headers = {
    Accept: "application/json"
  };

  const filteredQuoteStatus = ["FINALIZED"].toString();

  const restEndPoint = `/pastQuotes/${dealerCode}`;

  return new Promise((resolve, reject) => {
    makeSecureRestApi(
      {
        url: restEndPoint,
        method: "get",
        data: {},
        params: {
          customerId,
          ...(filteredQuoteStatus ? { statuses: filteredQuoteStatus } : {})
        },
        headers
      },
      response => {
        resolve(response);
      },
      error => {
        reject(error);
      },
      "Unable to load finalized CSR details."
    );
  });
};

export default {
  getPastQuotes,
  updatePastQuoteNotes,
  getFinalizedPastQuotes,
  getPastQuotesForTechnicianView
};
