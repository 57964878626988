import React, { Component } from "react";
export default class TextEditor extends Component {
  constructor(props) {
    super(props);
    this.textInput = React.createRef();
  }

  onKeyPress(event) {
    if (!isValidText(event.nativeEvent)) {
      event.preventDefault();
    }

    // Note As of now, only these special characters are allowed: () [] {} -
    // Todo: Extend this editor component to accept parameters to customize which special characters to allow
    function isValidText(event) {
      const re = /^[a-zA-Z0-9_ (){}[\]-]*$/;
      return re.test(event.key);
    }
  }

  onKeyDown(event) {
    if (event.keyCode === 39 || event.keyCode === 37) {
      event.stopPropagation();
    }
  }

  onStopEditing() {
    this.props.stopEditing();
  }

  afterGuiAttached() {
    if (this.textInput) this.textInput.current.focus();
  }

  getValue() {
    return this.textInput.current.value;
  }

  componentDidMount() {
    this.textInput.current.addEventListener("keydown", this.onKeyDown);
    this.textInput.current.addEventListener("focusout", () => {
      this.onStopEditing();
    });
  }

  componentWillUnmount() {
    this.textInput.current.removeEventListener("focusout", () => {
      this.onStopEditing();
    });
  }

  render() {
    return (
      <input
        onKeyPress={this.onKeyPress}
        ref={this.textInput}
        defaultValue={this.props.value}
        className="xmm-cell-editor"
      />
    );
  }
}
