const localeEnAu = {
  "sq.newquote.edit_menu_service.no_service_selected_msg":
    "At least one service must be included before the package can be Saved-au",
  "sq.search.menu.edit_service_detail_lbl": "Edit service details-au",
  "sq.search.menu.edit_back_review_package_details_lbl":
    "Back to review package details-au",
  "sq.search.common.edit_button": "Edit-au",
  "sq.search.common.cancel_button": "Cancel-au",
  "sq.search.common.save_button": "Save-au",
  "sq.search.common.alert_lbl": "Alert!-au",
  "sq.search.common.leaving_menu_package_edit":
    "Are you sure you want to leave without saving? All unsaved changes will be lost.-au",
  "sq.search.common.leaving_edit_page":
    "Are you sure you want to leave without saving? All unsaved changes will be lost.",
  "sq.search.common.proceed_button": "Proceed-au",
  "sq.search.common.edit_service.summary.labor_lbl": "Labor-au",
  "sq.search.common.edit_service.summary.qty_lbl": "Qty-au",
  "sq.search.common.edit_service.summary.parts_lbl": "Parts-au",
  "sq.search.edit_service.summary.show_details_lbl": "Show details-au",
  "sq.search.edit_service.summary.included_inspection":
    "Included inspection-au",
  "sq.search.menu.package.customize": "This package can not be customized-au",
  "sq.search.menu.package.selectable":
    "This package includes optional services-au",
  "sq.search.menu.package.modifiable":
    "This package contains services that can be modified-au",
  "sq.search.menu.package.opcode":
    "By changing the op code the package will show as a single service line on the RO-au",
  "sq.search.errors.read_service_quoting_enabled_property":
    "There was an error while fetching Service Quoting Enabled property-au.",
  "sq.search.customer.add_to_quote_lbl": "Add to quote-au",
  "sq.search.diagnose.service.note.title":
    "Additional notes, customer concerns, etc-au.",
  "sq.search.declined.service.note.error.msg":
    "Additional notes are required-au",
  "sq.search.diagnose.service.note.error.msg":
    "additional notes are required-au",
  "sq.editservice.save_error":
    "Please review the following information to save this item to the quote "
};

export default localeEnAu;
