/* eslint-disable no-console */
/*
Add util and transform objects used within ServiceSearchModal
*/
import has from "lodash/has";
import isEmpty from "lodash/isEmpty";
import { doesEmpty, convertHoursToMinutes } from "./quote.util";

// Add Page related object transform utils here
function jsonStringfy(object) {
  return JSON.parse(JSON.stringify(object));
}

const getPreselectedServices = services => {
  const selectedOps = [];
  services.forEach(service => {
    let isDefault = false;
    if (!service.selectable && !service.selectByDefault) {
      isDefault = true;
    }
    // selected[service.id] = selectByDefault;
    if (service.selectByDefault || isDefault) selectedOps.push(service);
  });
  return selectedOps;
};

function updateVin(vehicle) {
  const vin =
    has(vehicle, "vin") && !isEmpty(vehicle.vin) ? vehicle.vin.toString() : "";
  return vin;
}

/* @note - Method will validate raw response for missing keys, values with null/undefined
 */
function transformGlobalRepairService(response) {
  const modifiedService = { ...response };
  if (!isEmpty(response)) {
    // check for missing objects
    const orgLaborApps = has(response, "laborApps") ? response.laborApps : [];
    const orgVehicleAttr = has(response, "vehicleAttributes")
      ? response.vehicleAttributes
      : {};
    let orgParts = orgLaborApps.reduce((acc, next) => {
      return [...acc, ...next.parts];
    }, []);
    orgParts = orgParts.reduce((acc, next) => {
      if (!acc.some(item => item.partId === next.partId)) {
        acc.push(next);
      }
      return acc;
    }, []);
    const parts = orgParts.filter(part => part.partType === "part");
    const fluids = orgParts.filter(part => part.partType === "fluid");
    if (!isEmpty(orgLaborApps)) {
      orgLaborApps.forEach(labor => {
        labor.preselected = true;
        labor.name = has(labor, "name") ? labor.name : "";
        labor.position = has(labor, "position") ? labor.position : "";
        labor.laborHours = has(labor, "laborHours") ? labor.laborHours || 0 : 0;
        const laborHours = doesEmpty(labor.laborHours) ? 0 : labor.laborHours;
        // @note: convert hours to minutes, send this laborMins in quote payload
        labor.laborMinutes = convertHoursToMinutes(laborHours);
      });
    }
    modifiedService.laborApps = orgLaborApps;
    if (!isEmpty(parts)) {
      parts.forEach(part => {
        part.preselected = true;
        part.partName = has(part, "partName") ? part.partName : "";
        part.quantity =
          !part.quantity || doesEmpty(part.quantity) ? 0 : part.quantity;
        part.oemPartNumber = has(part, "oemPartNumber")
          ? part.oemPartNumber
          : "";
        part.notes = has(part, "notes") ? part.notes : "";
        part.unitPrice = has(part, "unitPrice") ? part.unitPrice : 0;
        part.totalPrice = part.quantity * part.unitPrice;
      });
    }
    modifiedService.parts = parts;
    if (!isEmpty(fluids)) {
      fluids.forEach(fluid => {
        fluid.preselected = true;
        fluid.partName = has(fluid, "partName") ? fluid.partName : "";
        fluid.exactQuantity = has(fluid, "exactQuantity")
          ? parseFloat(fluid.exactQuantity || 0)
          : 0;
        fluid.unitOfMeasure = has(fluid, "unitOfMeasure")
          ? fluid.unitOfMeasure
          : "";
        fluid.oemPartNumber = has(fluid, "oemPartNumber")
          ? fluid.oemPartNumber
          : "";
        fluid.notes = has(fluid, "notes") ? fluid.notes : "";
        fluid.unitPrice = has(fluid, "unitPrice") ? fluid.unitPrice : 0;
        fluid.totalPrice = fluid.exactQuantity * fluid.unitPrice;
      });
    }
    modifiedService.fluids = fluids;
    if (!isEmpty(orgVehicleAttr)) {
      const attr = {
        "Sub Model": has(orgVehicleAttr, "Sub Model")
          ? orgVehicleAttr["Sub Model"]
          : "",
        "Transmission Control Type":
          orgVehicleAttr["Transmission Control Type"],
        "Drive Type": orgVehicleAttr["Drive Type"]
      };
      modifiedService.vehicleAttributes = {
        ...orgVehicleAttr,
        attr
      };
    } else {
      modifiedService.vehicleAttributes = orgVehicleAttr;
    }
  }
  console.log("refined edit global repair", modifiedService);
  return modifiedService;
}

export {
  jsonStringfy,
  updateVin,
  getPreselectedServices,
  transformGlobalRepairService
};
