/**
 * Filters and returns only the service contracts from the provided list.
 * Service contracts are identified by having a `null` type.
 *
 * @param {Array} serviceContracts - An array of service contract objects to filter.
 * @param {string} serviceContracts[].vendorName - The name of the vendor.
 * @param {string} serviceContracts[].productName - The name of the product.
 * @param {string} serviceContracts[].internalProductId - The internal ID of the product.
 * @param {string|null} serviceContracts[].type - The type of the contract (null for service contracts, others for different contract types).
 *
 * @returns {Array} An array containing only service contracts (type `null`), or an empty array if the input is invalid or no contracts are found.
 */
const filterServiceContracts = serviceContracts => {
  if (!Array.isArray(serviceContracts)) return [];

  return serviceContracts.filter(sc => sc.type === null && sc?.vendorId);
};

export { filterServiceContracts };
