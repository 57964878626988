import { doesEmpty } from "../../utils/object";
import has from "lodash/has";
import isEmpty from "lodash/isEmpty";
import { assistanceRequestStatus } from "../../constants/assiatance-request.constants";

export function jsonStringfy(object) {
  return JSON.parse(JSON.stringify(object));
}
export function transformCustomer(customer, NewCustomerTemplate) {
  let newCustomer = Object.assign({}, NewCustomerTemplate);
  if (!isEmpty(customer)) {
    newCustomer = {
      personId: customer.personId,
      lifecycleState: true,
      firstName: customer.firstName,
      lastName: customer.lastName,
      loanerAllowed: 0,
      valetAllowed: 0,
      email: customer.email,
      contactInfo: {
        phoneNumbers: {
          HOME: customer.phoneNumbers[0].digits,
          WORK: customer.phoneNumbers[1].digits,
          MOBILE: customer.phoneNumbers[2].digits
        },
        emailAddress: customer.email,
        address: {
          city: "",
          state: "",
          postalCode: "",
          country: ""
        },
        useEmail: true,
        providerId: ""
      },
      vehicles: customer.vehicles
    };
  }
  return newCustomer;
}
export function updateVin(vehicle) {
  let vin = has(vehicle, "vin") ? vehicle.vin : "";
  vin = !isEmpty(vin) ? vin.toString() : "";
  return vin;
}

// TODO: This util is important for edit vehicle and skip customer cases. Refactor this later
export function transformVehicle(vehicle, VehicleSelectorTemplate) {
  let newVehicle = Object.assign({}, VehicleSelectorTemplate);
  if (!isEmpty(vehicle)) {
    const engineSizeType =
      isEmpty(vehicle.engineSize) || isEmpty(vehicle.engineType)
        ? ""
        : vehicle.engineSize.concat(",", vehicle.engineType);
    newVehicle = {
      make: vehicle.make,
      variant: vehicle.variant,
      vin: has(vehicle, "vin") && !doesEmpty(vehicle.vin) ? vehicle.vin : "",
      metaVehicleId:
        has(vehicle, "metaVehicleId") && !doesEmpty(vehicle.metaVehicleId)
          ? vehicle.metaVehicleId
          : "",
      vehicleId:
        has(vehicle, "vehicleId") && !doesEmpty(vehicle.vehicleId)
          ? vehicle.vehicleId
          : "",
      year: !vehicle.year ? "" : vehicle.year.toString(),
      model: !vehicle.model ? "" : vehicle.model,
      trim: !vehicle.trim ? "" : vehicle.trim,
      transmissionType: !vehicle.transmissionType
        ? ""
        : vehicle.transmissionType,
      mileage:
        has(vehicle, "mileage") && !doesEmpty(vehicle.mileage)
          ? vehicle.mileage
          : "",
      mileageDt:
        has(vehicle, "mileageDt") && !doesEmpty(vehicle.mileageDt)
          ? vehicle.mileageDt
          : "",
      engineType: vehicle.engineType,
      engineSize: vehicle.engineSize,
      engineSizeType,
      driveType: !vehicle.driveType ? "" : vehicle.driveType,
      drivingConditions: !vehicle.drivingConditions
        ? ""
        : vehicle.drivingConditions,
      defaultDrivingCondition: !vehicle.defaultDrivingCondition
        ? ""
        : vehicle.defaultDrivingCondition,
      showDrivingCondition: !vehicle.showDrivingCondition
        ? ""
        : vehicle.showDrivingCondition
    };
    newVehicle = jsonStringfy(newVehicle);
  }
  return newVehicle;
}
export function updateCustomers(customers, updatedCustomer) {
  customers.map(customer => {
    if (customer.personId === updatedCustomer.personId) {
      customer.firstName = updatedCustomer.firstName;
      customer.lastName = updatedCustomer.lastName;
      customer.email = updatedCustomer.email;
      customer.phoneNumbers = [
        {
          digits: updatedCustomer.contactInfo.phoneNumbers.HOME,
          type: "HOME"
        },
        {
          digits: updatedCustomer.contactInfo.phoneNumbers.WORK,
          type: "WORK"
        },
        {
          digits: updatedCustomer.contactInfo.phoneNumbers.MOBILE,
          type: "MOBILE"
        }
      ];
    }
    return customer;
  });

  return customers;
}

export const convertMinutesToHours = minutes => {
  const hours = Number.parseFloat(minutes / 60);
  return hours.toFixed(2).endsWith("0") ? hours.toFixed(1) : hours.toFixed(2);
};

// TODO: Note: xmm rest API required to pass default variant as "dummy" if variant is missing
export function getVariantForVehicle(vehicle, makes) {
  const { make, variant } = vehicle;
  let variantValue = "dummy";
  if (has(vehicle, "variant") && !isEmpty(variant)) {
    variantValue = variant;
  } else {
    variantValue = findVariant(makes, make);
    if (isEmpty(variantValue)) variantValue = "dummy";
  }
  return variantValue;
}

export function findVariant(makelist, make) {
  const matches = makelist.filter(m => {
    return m.make === make;
  });
  return matches.length !== 0 ? matches[0].variant : "";
}

export const getDrivingConditionsFromTrims = (trims, vehicle) => {
  let drivingConditions = [];
  if (!trims) {
    return drivingConditions;
  }

  trims.forEach(trim => {
    if (trim.data === vehicle.trim) {
      trim.engineTypes.forEach(engineType => {
        if (engineType.data === vehicle.engineType) {
          engineType.engineSizes.forEach(engineSize => {
            if (engineSize.data === vehicle.engineSize) {
              engineSize.driveTypes.forEach(driveType => {
                if (driveType.data === vehicle.driveType) {
                  driveType.transmissionTypes.forEach(transmissionType => {
                    if (transmissionType.data === vehicle.transmissionType) {
                      drivingConditions = transmissionType.drivingConditions;
                    }
                  });
                }
              });
            }
          });
        }
      });
    }
  });

  const drivingConditionsOptions = drivingConditions.map(drivingCondition => {
    return {
      value: drivingCondition.data,
      label: drivingCondition.label
    };
  });

  return !drivingConditionsOptions ? [] : drivingConditionsOptions;
};

// @workaround: since getQuote() API not returning personId key for customer, vehicle objects, we add personId with customerId
// For New quote flow, we read personId prop from customer/vehicle objects to get decline services and other get() APIs
export const transformQuoteCustomerAndVehicle = data => {
  const { customer, vehicle } = data;
  if (!isEmpty(vehicle)) {
    // vehicle.metaVehicleId = data.metaVehicleId;
    vehicle.personId = "";
    if (!isEmpty(customer)) {
      vehicle.personId = !customer.personId ? "" : customer.personId;
    }
  }
  return data;
};

export function verifyVehicleYMM(vehicle) {
  let valid = !doesEmpty(vehicle.vehicleId);
  const year = has(vehicle, "year") ? vehicle.year : "";
  const make = has(vehicle, "make") ? vehicle.make : "";
  const model = has(vehicle, "model") ? vehicle.model : "";
  valid = !isEmpty(year) && !isEmpty(make) && !isEmpty(model);
  return valid;
}

/**
 * Extracts the types tags from each attention tag object within the workflowAttentionTag array and formats them accordingly
 * @param {object[]} attentionTagsObjectArray
 * @returns {string[]|[]} capitalizedTagsArray
 */
export function extractAndFormatAttentionTags(attentionTagsObjectArray) {
  if (
    !isEmpty(attentionTagsObjectArray) &&
    Array.isArray(attentionTagsObjectArray)
  ) {
    const typesArray = attentionTagsObjectArray.map(obj => {
      if (
        has(obj, "quoteAssistanceType") &&
        obj.quoteAssistanceState === assistanceRequestStatus.REQUEST
      ) {
        return obj.quoteAssistanceType;
      } else {
        return null;
      }
    });
    const capitalizedTagsArray = typesArray.map(type => {
      if (!isEmpty(type)) {
        const tagType = type.toString().toLowerCase();
        return tagType.charAt(0).toUpperCase() + tagType.slice(1);
      } else {
        return "";
      }
    });
    return capitalizedTagsArray;
  } else {
    return [];
  }
}

// Function created to render "- -" in empty cells for attention column after request assistance is completed
export function hasTwoEqualStrings(array) {
  for (let i = 0; i < array.length; i++) {
    const currentString = array[i];

    // Check if the current string appears more than once in the array
    if (array.indexOf(currentString) !== array.lastIndexOf(currentString)) {
      return "- -";
    }
  }

  return array;
}

// common util can be used to extract notes string from Quote object or QuoteService.dealershipNotes
export function extractNotes(notesObject) {
  let strNotes = null;
  if (!isEmpty(notesObject) && !isEmpty(notesObject[0])) {
    const noteObj = notesObject[0];
    strNotes = !isEmpty(noteObj.note) ? String(noteObj.note).trim() : null;
    return strNotes;
  }
}
