/* eslint-disable no-console */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { NavLink, useHistory } from "react-router-dom";
import { AppContext } from "../../../../state/app-context";
import DiscardQuoteModal from "../../modals/DiscardQuoteModal";
// import NewQuoteBtn from "./NewQuoteBtn";

const Nav = props => {
  const [showDiscardQuoteModal, setShowDiscardQuoteModal] = useState(false);
  const [selectedPath, setSelectedPath] = useState("");
  const history = useHistory();
  const { links } = props;
  const { localeStrings } = React.useContext(AppContext);
  const handleNavLinkClick = (event, path) => {
    event.preventDefault();
    setSelectedPath(path);
    history.push(`${path}${window.location.search}`);
  };

  const handleConfirmPopupOkClick = () => {
    setShowDiscardQuoteModal(false);
    history.push(`${selectedPath}${window.location.search}`);
  };

  const handleConfirmPopupCancelClick = () => {
    setShowDiscardQuoteModal(false);
  };
  return (
    <>
      <div className="nav-container hide">
        <ul className="nav__pills nav">
          {links.map(({ path, localekey }) => (
            <li key={path}>
              <NavLink
                to={`${path}${window.location.search}`}
                exact
                className="nav__link"
                activeClassName="nav__link--active"
                onClick={e => handleNavLinkClick(e, path)}
              >
                {localeStrings[localekey]}
              </NavLink>
            </li>
          ))}
        </ul>
        <div className="nav-btn-cell">
          {/* <NewQuoteBtn disabled={!userPermissions.canCreateQuote} /> */}
        </div>
      </div>

      <DiscardQuoteModal
        onProceed={handleConfirmPopupOkClick}
        onCancel={handleConfirmPopupCancelClick}
        showModal={showDiscardQuoteModal}
      />
    </>
  );
};

Nav.propTypes = {
  links: PropTypes.array.isRequired
};

export default Nav;
