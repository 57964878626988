// This file is used for summary flow
import has from "lodash/has";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import isNumber from "lodash/isNumber";
import cloneDeep from "lodash/cloneDeep";
import operationDetailsSchema from "../../quote/schemas/operation-details.schema";
import {
  GlobalOpsServiceType,
  QuoteServiceTypes
} from "../constants/page-wrapper.constants";
import { convertHoursToMinutes } from "./quote-util";
import { priceSourceLabels } from "../../../PartsLookupModule/constants/parts.constants";

const updateOperationObject = (operation, source) => {
  const operationService = {
    ...operation,
    categoryId: source.categoryId,
    categoryName: source.categoryName,
    categoryGroupId: source.categoryGroupId,
    categoryGroupName: source.categoryGroupName,
    serviceKind: source.serviceKind,
    operationSource: source.operationSource
  };
  return operationService;
};
// Method returns formatted unified response data extracted from quoteService {all service types} exclude menu case
const formatServiceToRawOperationDetails = currentEditingService => {
  const globalOperationDetails = extractUnifiedServiceObject(
    currentEditingService
  );
  const service = extractEditService(currentEditingService);
  const rawRecord = service.rawRecord;
  let formattedService = null;
  if (rawRecord.operationSource) {
    let globalOpsService = globalOperationDetails;
    globalOpsService = operationDetailsSchema.cast(globalOpsService);
    const rawService = JSON.stringify(globalOpsService);

    // IMPORTANT: unified API response must be unchanged for all service types; parts, labor, prices should be unchanged
    if (rawRecord.operationSource === GlobalOpsServiceType.DEALERCATALOG) {
      if (!isEmpty(globalOpsService.laborApps)) {
        let operation = globalOpsService.laborApps[0];
        const laborHours = has(operation, "laborHours")
          ? operation.laborHours || 0
          : 0;
        // @note: convert hours to minutes, send this laborMins in quote payload
        operation.laborMinutes = convertHoursToMinutes(laborHours);
        operation = updateOperationObject(operation, rawRecord);
        globalOpsService.laborApps[0] = operation;
      }
    } else {
      // For non-dealer publish case: unified API response must be unchanged
      globalOpsService = updateOperationObject(globalOpsService, rawRecord);
      globalOpsService = {
        ...globalOpsService,
        id: rawRecord.operationId,
        name: rawRecord.operationName,
        parts: has(globalOpsService, "parts") ? globalOpsService.parts : []
      };
    }
    formattedService = {
      ...globalOpsService,
      id: rawRecord.operationId,
      serviceKind: rawRecord.serviceKind,
      operationSource: rawRecord.operationSource,
      quoteRawService: { rawService }
    };
  } else {
    const rawService = JSON.stringify(rawRecord);
    formattedService = {
      ...rawRecord,
      serviceId: has(rawRecord, "id") ? rawRecord.id.toString() : "",
      quoteRawService: { rawService }
    };
  }
  return formattedService;
};

// Method to convert quoteService{rawService} into unified response, used as prop {rawOperationDetails} to edit service
const extractUnifiedServiceObject = quoteService => {
  // quoteRawService.rawService is copy of unified response and should not be modified
  let unifiedResponse = null;
  if (!isEmpty(quoteService)) {
    unifiedResponse = quoteService.quoteRawService
      ? JSON.parse(quoteService.quoteRawService.rawService)
      : {};
  }
  return unifiedResponse;
};

// @todo-edit: Method to construct formatted service {rawRecord} using quoteService object used as prop to edit service module
const extractEditService = quoteService => {
  // Note: quoteRawService.rawService is copy of unified API response and will not have calculated, overriden, final fields
  let unifiedResponse = null;
  let editService = null;
  const nonGLobalServiceTypes = ["DECLINED", "RECALL"];
  const isNonGlobalService =
    has(quoteService, "quoteServiceType") &&
    nonGLobalServiceTypes.includes(quoteService.quoteServiceType);
  if (!isEmpty(quoteService)) {
    unifiedResponse = quoteService.quoteRawService
      ? JSON.parse(quoteService.quoteRawService.rawService)
      : {};
    editService = {
      recordId: quoteService.extServiceId || "",
      serviceName: quoteService.serviceName,
      price: quoteService.servicePrice,
      categoryId: quoteService.serviceCategoryId,
      categoryGroupName: "",
      opCode: quoteService.dmsOpcode,
      subTypeId: quoteService.subTypeId,
      allocationSubTypeId: quoteService.allocationSubTypeId,
      internalAccount: quoteService.internalAccount,
      synonyms: [],
      ...(isNonGlobalService && {
        parts: quoteService.parts
      }),
      rawRecord: {
        // id: unifiedResponse.operationId || "",
        id: has(unifiedResponse, "operationId")
          ? unifiedResponse.operationId
          : has(unifiedResponse, "id")
          ? unifiedResponse.id
          : "",
        // operationId: unifiedResponse.operationId,
        operationId: has(unifiedResponse, "operationId")
          ? unifiedResponse.operationId
          : has(unifiedResponse, "id")
          ? unifiedResponse.id
          : "",
        operationName: quoteService.serviceName,
        operationSource: quoteService.operationSource,
        serviceKind: quoteService.serviceKind,
        opCode: quoteService.dmsOpcode,
        subTypeId: quoteService.subTypeId,
        allocationSubTypeId: quoteService.allocationSubTypeId,
        internalAccount: quoteService.internalAccount,
        quoteServiceType: quoteService.quoteServiceType,
        categoryId: quoteService.serviceCategoryId,
        categoryName: quoteService.serviceCategory,
        categoryGroupId: "",
        categoryGroupName: "",
        price: quoteService.servicePrice,
        ...(isNonGlobalService && {
          parts: quoteService.parts
        })
      }
    };
  }
  return editService;
};
const convertMinutesToHours = minutes => +(+minutes / 60).toFixed(1);

// Method returns formatted unified response prepared using rawMenuService from quoteMenuService {only menus}
const convertQuoteMenuServiceIntoUnifiedRawOperation = quoteMenuService => {
  // read rawService{} (catalog API menuservice) from quoteMenuService
  const service = extractUnifiedServiceObject(quoteMenuService);
  const newService = cloneDeep(service);
  delete newService.part;
  newService.displayName = service.name;
  newService.laborAppId = null;
  newService.opCode = service.dmsOpcode;
  newService.subTypeId = service.subTypeId;
  newService.allocationSubTypeId = service.allocationSubTypeId;
  newService.notes = null; // always null for menu
  newService.laborPrice = has(service, "scheduledLaborPrice")
    ? service.scheduledLaborPrice
    : 0;
  newService.laborHours = has(service, "durationMins")
    ? convertMinutesToHours(service.durationMins)
    : 0;
  newService.laborPriceOverridden = false;
  newService.partsPriceOverridden = false;
  newService.totalPrice = service.price;
  newService.partsPrice = service.partsPrice;
  // fix: set totalPriceOverride flag based on totalPrice != partsPrice + laborPrice values
  newService.totalPriceOverridden = isNumber(
    newService.totalPrice !== newService.partsPrice + newService.laborPrice
  );
  newService.dealerCode = service.dealerCode;
  newService.payTypeGroup = service.payTypeGroup;
  newService.payTypeCode = service.payTypeCode;
  newService.payTypeDescription = service.payTypeDescription;
  newService.operationSource = QuoteServiceTypes.MENU;
  newService.parts = has(service, "parts")
    ? convertMenuServicePartsIntoUnifiedResponse(service.parts)
    : [];
  const laborApps = [];
  laborApps.push(newService);

  const rawObject = {
    laborApps,
    abbreviation: null,
    catalogSource: QuoteServiceTypes.MENU,
    operationSource: QuoteServiceTypes.MENU,
    commentsRequired: false,
    dealerCode: "",
    defaultLaborRate: 0, // for menus, defaultLaborRate not exists in catalog API
    description: null,
    enabled: null,
    extId: null,
    laborTimeMarkupPercent: null,
    loanerAllowed: null,
    make: "",
    operationId: service.id,
    parentId: null,
    mandatoryInAppt: null,
    notApplicableParts: [],
    selectableVehicleAttributes: [],
    serviceKind: null,
    serviceCategoryId: service.serviceCategoryId,
    serviceCategoryName: service.serviceCategoryName,
    vehicleAttributes: null,
    variant: null,
    // @note: payType values are updated for selectedMenu and fowarded to each menuService when paytypes API is ready
    payTypeGroup: service.payTypeGroup,
    payTypeCode: service.payTypeCode,
    payTypeDescription: service.payTypeDescription
  };
  return rawObject;
};

// @note - Transform parts object for Menu service, return parts datamodel same like unified response API datamodel
const convertMenuServicePartsIntoUnifiedResponse = parts => {
  const partsWithPartId = parts.map(part => {
    return {
      ...part,
      // @todo-poc: add rowId field to support ag-grid getRowNodeId() binded with rowId for poc.
      // @note: partId is a string used for filter logic accross parts grid.
      ...(has(part, "id") && { partId: part.id.toString() }),
      ...(!has(part, "id") &&
        has(part, "partId") && { partId: part.partId.toString() }),
      // @note: rowId is a number.
      ...(has(part, "id") && { rowId: Number(part.id) }),
      ...(!has(part, "id") &&
        has(part, "partId") && { rowId: Number(part.partId) }),
      ...(has(part, "description") ? { partName: part.description } : ""),
      ...(!has(part, "description") && has(part, "partName")
        ? { description: part.partName }
        : ""),
      quantity: has(part, "adjustedQuantity") ? part.adjustedQuantity : 0,
      unitPrice: has(part, "unitPrice") ? part.unitPrice : 0,
      oemPartNumber: has(part, "oemPartNumber") ? part.oemPartNumber : "",
      oilType: has(part, "oilType") ? part.oilType : "",
      unitOfMeasure: has(part, "adjustedUom") ? part.adjustedUom : "",
      // @workaround: copy {manufacturerCode} if returned in catalog menus API to field {dtDmsPartCode}
      // DMS opentrack API payload expecting this field {dtDmsPartCode} to return DMS parts
      dtDmsPartCode: get(
        part,
        "dtDmsPartCode",
        get(part, "manufacturerCode", null)
      ),
      priceSource:
        has(part, "priceSource") && part.priceSource
          ? part.priceSource
          : priceSourceLabels.MSRP,
      ...(has(part, "partPriceSource") && {
        partPriceSource: part.partPriceSource
      }),
      position: "",
      notes: [],
      relationship: null,
      qualifiers: null,
      selected: false
    };
  });
  return partsWithPartId;
};

const formatMenuServiceToRawOpeartionDetails = quoteMenuService => {
  return convertQuoteMenuServiceIntoUnifiedRawOperation(quoteMenuService);
};
export {
  formatServiceToRawOperationDetails,
  convertQuoteMenuServiceIntoUnifiedRawOperation,
  formatMenuServiceToRawOpeartionDetails
};
