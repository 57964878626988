// useEffect to load customer, vehicle or quoteId then only it renders summary page
import React, { useEffect, useContext } from "react";
import isEmpty from "lodash/isEmpty";
import { useNewQuoteContext, Actions } from "../../../state/NewQuoteContext";
import ServiceSummary from "../../quote-summary/ServiceSummary";
import { AppContext } from "../../../state/app-context";
import useComponentDidMount from "../../../hooks/useComponentDidMount";
import ModifyPartsWrapper from "./ModifyPartsWrapper";
import PriceAdjustmentWrapper from "./PriceAdjustmentWrapper";
import CatalogFee from "../../repair-order/components/catalog-fee/catalog-fee.component";
import {
  CATALOG_DISCOUNT_ACTIONS,
  CATALOG_FEE_ACTIONS
} from "../../repair-order/constants/adjustment.constant";

import CatalogDiscount from "../../repair-order/components/catalog-discount/catalog-discount.component";
const QutoeSummaryWrapper = () => {
  const appContext = useContext(AppContext);
  const { dispatch, state } = useNewQuoteContext();
  const { localeStrings } = appContext;
  const { vehicle, quoteSummary, isModifyParts, priceAdjustmentType } = state;
  const { confirmationId, quoteServices } = quoteSummary;
  /**
   * Case 1: Vehicle is always present for Skip Customer Flow
   * Case 2: Vehicle and confirmationId present for New Quote flow and Edit Quote flow
   */
  const showSummary = () => {
    switch (true) {
      case !isEmpty(vehicle):
        return true;
      case !isEmpty(vehicle) && !isEmpty(confirmationId):
        return true;
      default:
        return false;
    }
  };

  useEffect(() => {
    if (quoteServices.length >= 0) {
      console.log(
        `Quote Summary page, quoteId ${confirmationId} has quoteServices.length: ${quoteServices.length} `
      );
    }
  }, [quoteServices, confirmationId]);

  useComponentDidMount(() => {
    dispatch({
      type: Actions.SET_PAGE_TITLE,
      payload: localeStrings["sq.newquote.customer.service_summary_lbl"]
    });
  });

  return (
    <div>
      {showSummary() ? (
        <div>
          <ServiceSummary />
          {isModifyParts ? <ModifyPartsWrapper /> : null}
          {CATALOG_DISCOUNT_ACTIONS.includes(priceAdjustmentType) ? (
            <CatalogDiscount />
          ) : CATALOG_FEE_ACTIONS.includes(priceAdjustmentType) ? (
            <CatalogFee />
          ) : !priceAdjustmentType ? null : (
            <PriceAdjustmentWrapper />
          )}
        </div>
      ) : (
        <div>Loading ...</div>
      )}
    </div>
  );
};
export default QutoeSummaryWrapper;
