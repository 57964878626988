// This component is used to render TopServices, Diagnosis, Declined, Recall services
import React, { useCallback } from "react";
import PropTypes from "prop-types";
import IconKeyboardArrowRight from "@cx/ui/Icons/IconKeyboardArrowRight";
import Badge from "@cx/ui/Badge";
import { priceValueFormatter, formatDate } from "../../utils/format.util";
import has from "lodash/has";
import throttle from "lodash/throttle";
import clsx from "clsx";
import { doesEmpty } from "../../utils/quote.util";
import { useServiceSearchContext } from "../../state/service-search.context";
import { quickFilterPages } from "../../constants/quick-filters.constants";
import "../../components/service-list/ServiceList.scss";

const ServicesList = props => {
  const {
    services,
    viewType,
    onSelectService,
    parentCls,
    msgResults,
    ...restProps
  } = props;
  const { state, ctxOnEditService } = useServiceSearchContext();
  const { debugQuote, appConfig } = state;

  const clsService = clsx(
    "service",
    viewType === "DECLINED" && "service-declined"
  );

  const handleOnClick = throttle(service => onSelectService(service), 500, {
    leading: true,
    trailing: false
  });
  const handleOnClickMemo = useCallback(service => {
    ctxOnEditService(true);
    handleOnClick(service);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderDetailForParentApp = service => {
    let content = null;
    // @note: show price, opcode based on appConfig options passed as props
    if (appConfig.showPrice) {
      if (has(service, "price") && !doesEmpty(service.price)) {
        content = (
          <span className="service-price">
            {priceValueFormatter(service.price)}
          </span>
        );
      } else {
        content = (
          <span className="service-price">
            <IconKeyboardArrowRight
              key={`serviceicon-${service.recordId}`}
              onClick={() => handleOnClickMemo(service)}
            />
          </span>
        );
      }
    }
    return content;
  };

  return (
    <div className={parentCls}>
      <div className="search-no-results">{msgResults}</div>
      <div id="topServicesQuickFilterPageContainer">
        <div id="serviceListContainer" {...restProps}>
          <ul id="serviceList">
            {services.map(service => (
              <li
                id={`service-${service.recordId}`}
                key={`servicekey-${service.recordId}`}
                className={clsService}
                onClick={() => handleOnClickMemo(service)}
              >
                <div className="service-info">
                  <span className="service-name">
                    {service.serviceName} &nbsp;
                  </span>
                  {appConfig.showOpcode ? (
                    <span className="service-opcode">{service.opCode}</span>
                  ) : null}
                  {renderDetailForParentApp(service)}
                </div>
                {!has(service, "asrNotes") || !has(service, "declinedDate") ? (
                  ""
                ) : (
                  <div className="service-info declined-container">
                    <div className="service-notes-container">
                      {service.asrNotes ? (
                        <>
                          <span className="service-notes">
                            Original ASR Notes
                          </span>
                          <p className="service-notes service-notes-body">
                            {service.asrNotes}
                          </p>
                        </>
                      ) : null}
                    </div>
                    {has(service, "declinedDate") ? (
                      <Badge
                        htmlId="badgeDefault"
                        className="font-weight-400 padding-top-4 padding-bottom-4 service-date"
                      >
                        Declined on{" "}
                        {formatDate(
                          service.declinedDate,
                          state.locale,
                          "short"
                        )}
                      </Badge>
                    ) : (
                      ""
                    )}
                  </div>
                )}
                {!debugQuote ? (
                  ""
                ) : (
                  <div className="service-info">
                    {!service.synonyms || !debugQuote ? (
                      ""
                    ) : (
                      <div className="search-truncate-text tags-div">
                        <i className="tags">
                          {service.categoryId} {service.synonyms.toString()}{" "}
                        </i>
                      </div>
                    )}
                    <div className="txt-upper">
                      {service.opCode} {service.rawRecord.operationSource}
                    </div>
                    <div className="txt-caps">
                      {service.rawRecord.serviceKind}
                    </div>
                  </div>
                )}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

ServicesList.defaultProps = {
  parentCls: "",
  msgResults: "",
  services: [],
  viewType: quickFilterPages.TOP_SERVICES,
  onSelectService: () => {}
};

ServicesList.propTypes = {
  parentCls: PropTypes.string,
  msgResults: PropTypes.string,
  onSelectService: PropTypes.func,
  viewType: PropTypes.string,
  services: PropTypes.arrayOf(
    PropTypes.shape({
      recordId: PropTypes.string.isRequired,
      serviceName: PropTypes.string.isRequired,
      price: PropTypes.string,
      synonyms: PropTypes.array,
      categoryId: PropTypes.number,
      categoryGroupName: PropTypes.string,
      source: PropTypes.string.isRequired
    })
  )
};

export default ServicesList;
