import { makeSecureRestApi } from "../../../../api/xmmAxios";

const catalogJSONPatchFunction = async (
  appContext,
  quoteSummary,
  service,
  payload
) => {
  const { confirmationId } = quoteSummary;
  const { dealer } = appContext;
  const { dealerCode } = dealer;
  const { quoteServiceId } = service;
  return new Promise((resolve, reject) => {
    makeSecureRestApi(
      {
        url: `service/${quoteServiceId}/dealerCode/${dealerCode}/${confirmationId}/jsonPatch`,
        method: "patch",
        data: payload
      },
      response => {
        resolve(response);
      },
      error => {
        reject(error);
      },
      "Unable to complete request."
    );
  });
};

// NOTE: catalogLog api for getting all fees url: `https://catalogadmin-qa5.xtimeappsnp.xtime.com/opsadmin/dealer/getFees?dealerCode=${dealerCode}`,
const getCatalogROLevelFees = async appContext => {
  const { dealer } = appContext;
  const { dealerCode } = dealer;
  return new Promise((resolve, reject) => {
    makeSecureRestApi(
      {
        // TODO: update isNotExpired=1 when expiryDate is defect is fixed from catalog
        url: `quote/services/${dealerCode}/fees?isNotExpired=1&isForRO=1`,
        method: "get"
      },
      response => {
        resolve(response);
      },
      error => {
        reject(error);
      },
      "Unable to complete request."
    );
  });
};

// * For adding
const addCatalogRoLevelFeesAndDiscounts = async (
  appContext,
  quoteSummary,
  payload,
  payerId
) => {
  const { confirmationId } = quoteSummary;
  const { dealer } = appContext;
  const { dealerCode } = dealer;
  return new Promise((resolve, reject) => {
    makeSecureRestApi(
      {
        url: `payer/${payerId}/dealercode/${dealerCode}/quote/${confirmationId}`,
        method: "patch",
        data: payload
      },
      response => {
        resolve(response);
      },
      error => {
        reject(error);
      },
      "Unable to complete request."
    );
  });
};

export {
  catalogJSONPatchFunction,
  getCatalogROLevelFees,
  addCatalogRoLevelFeesAndDiscounts
};
