// @ts-check
import React from "react";
import Tooltip from "@cx/ui/Tooltip";
import IconInfoOutline from "@cx/ui/Icons/IconInfoOutline";
// eslint-disable-next-line unused-imports/no-unused-imports
import * as CommonTypes from "../../../../../../@types/common.types";

/**
 * @description Header component for "Quotes and trends section".
 * @param {{header: string, tooltipMessage: string}} props
 * @returns {CommonTypes.Component}
 */
const QuotesAndTrendsHeader = props => {
  const { header, tooltipMessage } = props;
  return (
    <>
      <h4>{header}</h4>
      <div className="tooltip__wrapper">
        <Tooltip
          htmlId="tooltipRight"
          tooltipContent={
            <>
              <p className="tooltip__title">{header}</p>
              <p>{tooltipMessage}</p>
            </>
          }
          position="right"
        >
          <IconInfoOutline htmlId="iconFee" />
        </Tooltip>
      </div>
    </>
  );
};

export default QuotesAndTrendsHeader;
