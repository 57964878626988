import PropTypes from "prop-types";

const subletPropType = PropTypes.shape({
  subletId: PropTypes.number.isRequired,
  vendor: PropTypes.string.isRequired,
  poNumber: PropTypes.string.isRequired,
  poPricing: PropTypes.shape({
    subletTotalCost: PropTypes.number.isRequired
  })
});

const feePropType = PropTypes.shape({
  feeId: PropTypes.number,
  feesAmount: PropTypes.number,
  feesDescription: PropTypes.string
});

const discountPropType = PropTypes.shape({
  discountId: PropTypes.number,
  discountAmount: PropTypes.number,
  discountDescription: PropTypes.string
});

export { subletPropType, feePropType, discountPropType };
