import React from "react";
import { func } from "prop-types";
import Button from "@cx/ui/Button";

const Footer = ({ onPrint, onExport }) => {
  return (
    <div className="footer-service-log">
      <hr className="footer-divider" />
      <div className="buttons-service-log">
        <div>
          <Button
            buttonStyle="secondary"
            onClick={onPrint}
            htmlId="print-button"
          >
            Print
          </Button>
          <Button
            className="export-button"
            buttonStyle="secondary"
            htmlId="export-button"
            onClick={onExport}
          >
            Export
          </Button>
        </div>
        <div>
          <Button
            htmlId="close-button"
            buttonStyle="primary"
            onClick={() => window.close()}
          >
            Close
          </Button>
        </div>
      </div>
    </div>
  );
};

Footer.propTypes = {
  onPrint: func,
  onExport: func
};

export default Footer;
