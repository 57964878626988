/* eslint-disable no-console */
// custom console-polyfill script add as first line in src/index.js
import { handleConsole } from "../config/console-util";
import "../config/app-polyfill.js";
import "../config/crypto-polyfill.js";
import React from "react";
import { render } from "react-dom";
import App from "./pages/App";
import axios from "axios";
import { BrowserRouter as Router, Route } from "react-router-dom";
import * as serviceWorker from "./registerServiceWorker";
import { localStorageLimit } from "./api/cache/local-storage.util";
import { isDev, isDev6, isQa6, isStage4 } from "./utils/is-dev";
import { fromQueryString } from "./api/app.util";
import { logUnhandledRejection } from "./services/log.service";
import { isTrue } from "./utils/value.util";
import CxThemeProvider from "@cx/ui/CxThemeProvider";
// @note: below if block required for cx5 while development
// Hot Module Replacement is one thing that you can do in the src/index.js file to improve your experience as a developer
if (module.hot) {
  module.hot.accept();
}
if (!window.location.origin) {
  window.location.origin =
    window.location.protocol +
    "//" +
    window.location.hostname +
    (window.location.port ? ":" + window.location.port : "");
}
// Global axios defaults
axios.defaults.baseURL = window.location.origin;
axios.defaults.headers.post["Content-Type"] = "application/json";

// Reference your top level component directly instead of <BrowserRouter> and the child <Route> below
const ParentApp = () => (
  <main className="main-layout">
    <Router onUpdate={() => window.scrollTo(0, 0)}>
      <Route path="/" component={App} />
    </Router>
  </main>
);

// read query params from browser URL
const baseParams = fromQueryString();
let debugOn = baseParams?.debugMode || "";
debugOn = isTrue(debugOn);
const nonProd = isDev() || isDev6() || isQa6() || isStage4();
let showLogs = false;
// PROD case - disable console logs
if (!nonProd) {
  showLogs = false;
} else {
  showLogs = !debugOn ? nonProd : debugOn;
}
handleConsole(showLogs);
localStorageLimit();
const rootElement = document.getElementById("root");
render(
  <CxThemeProvider loadCxStylesLast={true}>
    <ParentApp />
  </CxThemeProvider>,
  rootElement
);

window.onunhandledrejection = event => {
  console.warn(`UNHANDLED PROMISE REJECTION: ${event.reason}`);
  logUnhandledRejection(event);
};

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.

serviceWorker.unregister();

/* eslint-enable no-console */
