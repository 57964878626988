/* eslint-disable no-async-promise-executor */
import axiosService, { showApiFailureMessage } from "../../../api/xmmAxios";
// import { getAppEnvironment } from "./dealer-tire.util";
/**
 * Gets a new handle, secret from dealerTire API
 * @param {dealerCode=xtimemotors, appEnv=qa5/ua9, appSource=IND/SCH }
 */

// {
//     "handle": "32BBB1B7-AE87-46B9-ABCB-F8C0358CFDDA",
//     "secret": "kgMWbNP2DgMivvh2uaNPYGpcHnPEuoZ38tG3MX6PyPqexSBpPv",
//     "expiration": "2022-05-06T22:37:58.951Z",
//     "statusCode": 0
// }
const getDealerTireHandle = async (dealerCode, appEnv, appSource) => {
  return new Promise(async (resolve, reject) => {
    const axios = axiosService.getInstance();
    const url = `/dealertire/handler`;
    try {
      const response = await axios.get(url, {
        params: {
          appEnv,
          appSource
        }
      });
      resolve(response.data);
    } catch (error) {
      showApiFailureMessage("Unable to connect to Dealer Tire.", error);
      reject(error);
    }
  });
};

const getServiceQuotingApiUrl = () => {
  // const env = getAppEnvironment();
  const apiUrl = axiosService.getQuotingApiUrl();
  return apiUrl;
};

export default {
  getDealerTireHandle,
  getServiceQuotingApiUrl
};
