/* eslint-disable no-console */
import React, { useContext } from "react";
import PropTypes from "prop-types";

import ConfirmPopup from "../../modals/ConfirmPopup";
import { AppContext } from "../../../../state/app-context";
import quoteService from "../../../quote-summary/services/quote.service";
import isEmpty from "lodash/isEmpty";
import { toast } from "@cx/ui/Toast";
import { Actions, useNewQuoteContext } from "../../../../state/NewQuoteContext";
import QuoteStatusConstant from "../../../../constants/quote-status.constants";

function ConfirmationAlertPopup(props) {
  const appContext = useContext(AppContext);
  const { localeStrings } = appContext;
  const { dispatch } = useNewQuoteContext();

  const closeConfirmationModal = () => {
    props.cancelAction();
  };

  /**
	 Handles the update of a confirmation quote's status and performs related actions based on the current mode.
	 This function updates the quote status in the database and triggers appropriate dispatch actions.
	 It also handles different modes like 'remove', 'edit', and 'modify' to perform corresponding actions.
	 @throws {Error} If there's an issue during the process, an error message is displayed.
 */
  const handleConfirmationQuoteUpdate = async () => {
    try {
      // Extract necessary data from the app context and current state
      const { dealer, user } = appContext;
      const { dealerCode } = dealer;
      const confirmationId = props.quoteSummary?.confirmationId;

      // Executes callback action
      if (props.okAction) {
        props.okAction();

        if (props?.stopUpdateQuoteStatus) return;
      }

      // @note: read response when quote status updated in DB
      const response = await quoteService.updateQuoteStatus({
        dealerCode,
        confirmationId,
        lastModByUserId: user.quoteUserId,
        quoteStatus: props?.status
      });

      // If response is valid, dispatch appropriate actions for quote update and status change
      if (!isEmpty(response)) {
        const { confirmationId } = response;
        if (!isEmpty(confirmationId)) {
          dispatch({
            type: Actions.UPDATE_QUOTE,
            payload: response
          });
        }
      }
    } catch (error) {
      // Handle errors and display appropriate message
      const msg = error["message"]
        ? error.message
        : localeStrings["sq.errors.network.error"];
      if (
        error.hasOwnProperty("response") &&
        error["response"].status === 422
      ) {
        toast.error(
          "This quote has already been converted to an appointment, so it cannot be edited.  Please refresh the page to see the current quote status.",
          {
            closeOnClick: true
          }
        );
      }
      console.error(msg);
    }
  };

  return (
    <ConfirmPopup
      title={localeStrings["sq.common.alert_lbl"]}
      message={
        localeStrings[
          "sq.newquote.location_bar.sent_quote-status-restart_modal_message"
        ]
      }
      show={props.showConfirmation}
      okText={localeStrings["sq.common.proceed_button"]}
      cancelText={localeStrings["sq.common.cancel_button"]}
      okAction={handleConfirmationQuoteUpdate}
      cancelAction={closeConfirmationModal}
      buttonStyle="danger"
    />
  );
}

export default ConfirmationAlertPopup;

ConfirmationAlertPopup.defaultProps = {
  showConfirmation: false,
  stopUpdateQuoteStatus: false,
  status: QuoteStatusConstant.PROGRESS,
  quoteSummary: {},
  okAction: () => {},
  cancelAction: () => {}
};

ConfirmationAlertPopup.propTypes = {
  showConfirmation: PropTypes.bool,
  stopUpdateQuoteStatus: PropTypes.bool,
  status: PropTypes.string,
  quoteSummary: PropTypes.object,
  okAction: PropTypes.func,
  cancelAction: PropTypes.func
};
