import Button from "@cx/ui/Button";
import IconDelete from "@cx/ui/Icons/IconDelete";
import PriceInput from "@cx/ui/PriceInput";
import SelectInput from "@cx/ui/SelectInput";
import TextInput from "@cx/ui/TextInput";
import React, { useState } from "react";
import PaymentNotes from "./payment-notes.component";

const CreditCardPaymentComponent = props => {
  const { paymentLine, removePaymentLine, updateSelectedPaymentLines } = props;
  const [currentPaymentLine, setCurrentPaymentLine] = useState(paymentLine);

  const onInputChange = cxEvent => {
    setCurrentPaymentLine({
      ...paymentLine,
      [cxEvent.target.name]: cxEvent.target.value
    });
    updateSelectedPaymentLines({
      ...paymentLine,
      [cxEvent.target.name]: cxEvent.target.value
    });
  };

  const creditcardOptions = [
    { value: "Select", label: "Select", disabled: true },
    { value: "Master Card", label: "Master Card" },
    { value: "Visa", label: "Visa" },
    { value: "AmEx", label: "AmEx" },
    { value: "Discover", label: "Discover" }
  ];

  return (
    <div className="credit-card-wrapper">
      <div className="credit-card-title">Credit Card</div>
      <div className="credit-card-payment-fields">
        <SelectInput
          htmlId="creditcardProvider"
          name="provider"
          label="Provider"
          options={creditcardOptions}
          value={currentPaymentLine?.provider || ""}
          displayDeselectOption={false}
          onChange={onInputChange}
          className="input-field credit-card-provider-input"
          autoFocus={true}
          required
          aria-label="providerSelectInput"
        />
        <TextInput
          htmlId="creditcardTransactionCode"
          name="transactionCode"
          label="Transaction code"
          className="input-field credit-card-TransactionCode-input"
          onChange={onInputChange}
          value={currentPaymentLine?.transactionCode || ""}
          maxLength={50}
          required
          autoComplete="off"
        />
        <PriceInput
          htmlId="creditCardAmount"
          name="paymentAmount"
          label="Amount"
          inputPrefix="$"
          className="input-field credit-card-amount-input"
          onChange={onInputChange}
          value={
            currentPaymentLine?.paymentAmount
              ? String(currentPaymentLine?.paymentAmount)
              : ""
          }
          required
          autoComplete="off"
          minValue={0.01}
        />
        <Button
          aria-label="credit card delete button"
          className="credit-card-delete-button"
          htmlId="creditcardDeleteIcon"
          icon={<IconDelete className="icon-delete-svg" />}
          type="button"
          onClick={() => {
            removePaymentLine(currentPaymentLine);
          }}
        />
      </div>
      <PaymentNotes
        onPaymentNoteChange={onInputChange}
        currentPaymentLine={currentPaymentLine}
      />
    </div>
  );
};

export default CreditCardPaymentComponent;
