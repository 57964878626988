/* eslint-disable no-alert */
import React, { useRef } from "react";
import PropTypes from "prop-types";
import Badge from "@cx/ui/Badge";
import AccordionGroup from "@cx/ui/AccordionGroup";
const ObjectViewer = ({ label, data }) => {
  const preRef = useRef();

  const copyDataToClipboard = async () => {
    if (navigator.clipboard) {
      try {
        await navigator.clipboard.writeText(preRef.current.innerHTML);
        alert("Data copied successfully");
      } catch (error) {
        alert("Error copying data to clipboard");
      }
    }
  };

  return (
    <div className="sq-flex-grid-container padding-top-10">
      <AccordionGroup.Container htmlId="quoteStateGroup" independent>
        <AccordionGroup.Item
          header={label}
          eventKey="1"
          htmlId="quoteStateTrace"
          onToggle={() => {
            /* Do nothing. This prop must be declared to supress a warning. */
          }}
        >
          <div className="sq-block-underline">
            <span className="sq-link-h5" onClick={copyDataToClipboard}>
              <Badge htmlId="badgeGreen" color="green">
                Copy to clipboard
              </Badge>
            </span>
            <span>
              <Badge htmlId="badgeDefault">{label}</Badge>
            </span>
          </div>
          <pre
            ref={preRef}
            style={{
              background: "#FAFAFA",
              color: "#000",
              fontSize: ".75rem",
              padding: ".5rem"
            }}
          >
            {JSON.stringify(data, null, 2)}
            {/* Example on how to show only some fields */}
            {/* {JSON.stringify(
                          {
                              customer: data.customer,
                              searchCustomer: data.searchCustomer,
                              vehicle: data.vehicle,
                              navigationHistory: data.navigationHistory,
                              quoteSummary: data.quoteSummary,
                              quoteStatus: data.quoteStatus,
                          },
                          null,
                          2
                      )} */}
          </pre>
        </AccordionGroup.Item>
      </AccordionGroup.Container>
    </div>
  );
};

ObjectViewer.defaultProps = {
  label: "Context type"
};

ObjectViewer.propTypes = {
  data: PropTypes.object.isRequired,
  label: PropTypes.string
};

export default ObjectViewer;
