import React, { useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import GlobalServiceList from "../../components/global-service-list/GlobalServiceList";
import EditGlobalRepair from "./views/EditGlobalRepair";
import EditDiagnosePage from "../diagnosis-quick-filter/EditDiagnosePage";
import EditDealerPublished from "./views/EditDealerPublished";
import isEmpty from "lodash/isEmpty";
import { isLocalDev } from "../../utils/quote.util";
import {
  useServiceSearchContext,
  Actions
} from "../../state/service-search.context";
import { GlobalOpsServiceType } from "../../constants/search.constants";

const GlobalServiceSearchPage = props => {
  const { services, searchType, EditServiceModule } = props;
  const { dispatch, ctxOnEditService } = useServiceSearchContext();
  const [showEditCmp, setShowEditCmp] = useState(false);
  const [showEditDiag, setshowEditDiag] = useState(false);
  const [showEditDealerPublished, setShowEditDealerPublished] = useState(false);
  const [globalService, setGlobalService] = useState(null);
  const filteredServices = services.filter(service =>
    service.rawRecord.serviceKind === GlobalOpsServiceType.AUTORECALL ||
    service.rawRecord.serviceKind === GlobalOpsServiceType.DECLINED
      ? false
      : true
  );

  useEffect(() => {
    ctxOnEditService(showEditCmp || showEditDiag || showEditDealerPublished);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showEditCmp, showEditDiag, showEditDealerPublished]);

  const updateParentEditDiagnosis = useCallback(closeEdit => {
    setshowEditDiag(closeEdit);
  }, []);

  const updateParentEditCatalog = useCallback(closeEdit => {
    setShowEditCmp(closeEdit);
  }, []);

  const updateParentEditDealerPublished = useCallback(closeEdit => {
    setShowEditDealerPublished(closeEdit);
  }, []);

  const updateParentState = useCallback(
    (serviceType, closeEdit) => {
      if (serviceType === "Diagnose") {
        updateParentEditDiagnosis(closeEdit);
      }
      if (serviceType === "GlobalCatalog") {
        updateParentEditCatalog(closeEdit);
      }
      if (serviceType === "DealerPublishedCatalog") {
        updateParentEditDealerPublished(closeEdit);
      }
      dispatch({
        type: Actions.REMOVE_GLOBAL_OPERATION_DETAILS
      });
    },
    [
      dispatch,
      updateParentEditDiagnosis,
      updateParentEditCatalog,
      updateParentEditDealerPublished
    ]
  );

  const updateService = useCallback(service => {
    if (!isEmpty(service)) {
      setGlobalService(service);
      // console.log("global service read from children cmp", service);
    }
  }, []);

  const msgResults =
    !isEmpty(filteredServices) && filteredServices.length > 0
      ? `${filteredServices.length} result${
          filteredServices.length > 1 ? "s" : ""
        } found`
      : "No results found";

  const editCatalog =
    showEditCmp && !isEmpty(globalService) ? (
      <EditGlobalRepair
        updateParentState={updateParentState}
        service={globalService}
        EditServiceModule={EditServiceModule}
      />
    ) : (
      ""
    );

  const editDiagnose =
    showEditDiag && !isEmpty(globalService) ? (
      <EditDiagnosePage
        updateParentState={updateParentEditDiagnosis}
        service={globalService}
        EditServiceModule={EditServiceModule}
      />
    ) : (
      ""
    );

  const editDealerPublished =
    showEditDealerPublished && !isEmpty(globalService) ? (
      <EditDealerPublished
        updateParentState={updateParentState}
        service={globalService}
        EditServiceModule={EditServiceModule}
      />
    ) : (
      ""
    );

  const parentCls =
    (showEditCmp || showEditDiag || showEditDealerPublished) &&
    !isEmpty(globalService)
      ? "hide-ele"
      : "empty-cls";
  return (
    <>
      <div className={parentCls}>
        <div className="search-no-results">
          {msgResults}
          <div className="right-block">
            {isLocalDev() ? (
              <div className="search-plain-label hide-ele">
                TYPE: {searchType}
              </div>
            ) : null}
          </div>
        </div>
        <GlobalServiceList
          services={filteredServices}
          viewType="GLOBAL"
          updateParentState={updateParentState}
          updateService={updateService}
        />
      </div>
      {editCatalog}
      {editDiagnose}
      {editDealerPublished}
    </>
  );
};

export default GlobalServiceSearchPage;

GlobalServiceSearchPage.propTypes = {
  searchType: PropTypes.string,
  services: PropTypes.array
};
