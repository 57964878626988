import React from "react";
import PropTypes from "prop-types";
import Badge from "@cx/ui/Badge";
import "../adjustment-information.scss";
import format from "../../../utils/format";

const AdjustmentInformationHeader = ({ title, count, total }) => {
  return (
    <div className="adjustment-information-header">
      <div className="adjustment-information-header-title">
        <span>{title}&nbsp;</span>
        {count > 0 ? (
          <Badge htmlId="adjustmentInformationBadge">{count}</Badge>
        ) : null}
      </div>
      <div className="adjustment-information-header-total price">
        {format.currency(total)}
      </div>
    </div>
  );
};

export default AdjustmentInformationHeader;

AdjustmentInformationHeader.defaultProps = {
  count: 0,
  total: 0
};

AdjustmentInformationHeader.propTypes = {
  title: PropTypes.string.isRequired,
  count: PropTypes.number,
  total: PropTypes.number
};
