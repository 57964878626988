import Badge from "@cx/ui/Badge";
import React from "react";
import format from "../../utils/format";
import { PAYER_TYPES } from "../../constants/csr.constants";
import moment from "moment";
import { warrantySubmissionStates } from "../../../../constants/quote-status.constants";

const PayTypesPaymentDisplayComponent = ({
  payerConfig,
  updatePayerChecked,
  quoteSummary,
  dealerProperties
}) => {
  const isUseOEMWarrantyEnabled =
    dealerProperties?.ENGG_USE_OEM_WARRANTY === "Y" ? true : false;

  const getStatus = () => {
    let status = "Pending";
    let statusClass = "gray";

    if (payerConfig?.payer?.closedDate !== null) {
      return {
        status: `Closed - ${moment(payerConfig?.payer?.closedDate).format(
          "MM/DD/YYYY"
        )}`,
        statusClass: "purple"
      };
    }
    if (isUseOEMWarrantyEnabled) {
      if (payerConfig?.payer?.payType === PAYER_TYPES.W) {
        const warrantyServices = quoteSummary?.quoteServices?.filter(
          service => service?.payTypeCode === PAYER_TYPES.W
        );
        const areSomeServicesNotReady = warrantyServices.some(
          w => w?.warranty?.warrantySubmissionState !== "READY"
        );
        if (areSomeServicesNotReady) return { status, statusClass };

        const allWarrantyReadyDates = warrantyServices
          ?.map(w =>
            w?.warranty?.warrantySubmissionState ===
            warrantySubmissionStates.READY
              ? moment(w?.warranty?.warrantyReadyDate)
              : null
          )
          ?.filter(date => date !== null);
        const maxDate = moment.max(allWarrantyReadyDates);
        // we are not showing Ready prompt if no Ready Date available for any service
        if (allWarrantyReadyDates.length > 0 && maxDate.isValid()) {
          status = `Ready - ${moment(maxDate).format("MM/DD")}`;
          statusClass = "green";
        }
      }
    } else {
      if (payerConfig?.payer?.payType === PAYER_TYPES.W) {
        const warrantyService = quoteSummary?.quoteServices?.find(
          service => service?.payTypeCode === PAYER_TYPES.W
        );
        if (warrantyService?.warranty?.warrantySubmissionDate !== null)
          status = `Submitted- ${moment(
            warrantyService?.warranty?.warrantySubmissionDate
          ).format("MM/DD")}`;
        statusClass = "green";
      }
    }
    return { status, statusClass };
  };

  return (
    <div className="other-payType-container">
      <input
        data-testid="paytype-checkbox"
        type="checkbox"
        checked={payerConfig.isChecked}
        value={payerConfig?.payType}
        onChange={cxEvent => {
          updatePayerChecked(payerConfig?.payType, cxEvent.target.checked);
        }}
        id={`payerCheckbox${payerConfig.payType}`}
        name={`payerCheckbox${payerConfig?.payType}`}
        disabled={payerConfig?.isDisabled}
        title={payerConfig?.tooltip}
        hidden={payerConfig?.payer?.closedDate !== null}
      />
      <div className="other-payType-container-content">
        <span className="payType-text">
          {payerConfig?.isClosed
            ? payerConfig?.closedPaytypeText
            : payerConfig.checkboxLabel}
        </span>
        <div className="status-badge">
          <Badge
            htmlId="payment-status-badge"
            data-testid="payment-status-badge"
            color={getStatus().statusClass}
          >
            {getStatus().status}
          </Badge>
        </div>
        <span className="amount-text">
          {format.currency(payerConfig?.payer?.finalPrice)}
        </span>
      </div>
    </div>
  );
};

export default PayTypesPaymentDisplayComponent;
