import Button from "@cx/ui/Button";
import IconDelete from "@cx/ui/Icons/IconDelete";
import NumericInput from "@cx/ui/NumericInput";
import PriceInput from "@cx/ui/PriceInput";
import TextInput from "@cx/ui/TextInput";
import React, { useState } from "react";
import PaymentNotes from "./payment-notes.component";

const CheckPayment = props => {
  const { paymentLine, removePaymentLine, updateSelectedPaymentLines } = props;

  const [currentPaymentLine, setCurrentPaymentLine] = useState(paymentLine);

  const onInputChange = cxEvent => {
    setCurrentPaymentLine({
      ...paymentLine,
      [cxEvent.target.name]: cxEvent.target.value
    });
    updateSelectedPaymentLines({
      ...paymentLine,
      [cxEvent.target.name]: cxEvent.target.value
    });
  };

  // ! NOTE this validation for unique check number is not required as of now
  // const getCheckNumberErrorMessage = () => {
  //   const message = selectedPaymentLines?.some(
  //     line =>
  //       line.paymentId !== currentPaymentLine?.paymentId &&
  //       line.checkNumber === currentPaymentLine.checkNumber &&
  //       currentPaymentLine.checkNumber !== "" &&
  //       currentPaymentLine.checkNumber !== null
  //   )
  //     ? "Duplicate check number not allowed"
  //     : "";
  //   return message;
  // };

  return (
    <div className="check-wrapper">
      <div className="check-title">Check</div>
      <div className="check-payment-fields">
        <TextInput
          htmlId="checkdrawerId"
          name="drawerNumber"
          label="Drawer"
          className="input-field check-drawer-input"
          onChange={onInputChange}
          value={currentPaymentLine?.drawerNumber || ""}
          autoFocus={true}
          maxLength={50}
          required
          autoComplete="off"
        />
        <NumericInput
          htmlId="checknumber"
          label="Check number"
          name="checkNumber"
          onChange={onInputChange}
          value={currentPaymentLine?.checkNumber || ""}
          className="input-field check-number-input"
          required
          autoComplete="off"
        />
        <PriceInput
          htmlId="checkAmount"
          name="paymentAmount"
          label="Amount"
          inputPrefix="$"
          className="input-field check-amount-input"
          allowDecimal={true}
          decimalMaxLength={2}
          decimalMinLength={2}
          minValue={0.01}
          onChange={onInputChange}
          value={
            currentPaymentLine?.paymentAmount
              ? String(currentPaymentLine?.paymentAmount)
              : ""
          }
          autoComplete="off"
          required
        />
        <Button
          aria-label="check delete button"
          htmlId="checkDeleteButton"
          className="check-delete-button"
          icon={<IconDelete className="icon-delete-svg" />}
          type="button"
          onClick={() => {
            removePaymentLine(currentPaymentLine);
          }}
        />
      </div>
      <PaymentNotes
        onPaymentNoteChange={onInputChange}
        currentPaymentLine={currentPaymentLine}
      />
    </div>
  );
};

export default CheckPayment;
