const isSublet = data => data.subletId !== undefined;

export const calculatePriceAdjustmentTotal = data => {
  if (!data?.length > 0) return 0;
  return data.reduce((prev, curr) => {
    if (isSublet(curr)) {
      return prev + curr.poPricing.subletTotalCost;
    } else {
      return 0;
    }
  }, 0);
};
