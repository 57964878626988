import React from "react";
import PropTypes from "prop-types";
import ModalDialog from "@cx/ui/ModalDialog";
import SendEmailTextToCustomer from "../../quote/send-email-and-text/SendEmailTextToCustomer";

/**
 * * Reusable modal window, pass props {showModal, title, okAction, cancelAction} from parent
 * @component SendEmailTextModal
 */
const SendEmailTextModal = props => {
  const { okAction, cancelAction, showModal, data } = props;
  const { confirmationId, email, mobile } = data;
  const handleCancel = () => {
    cancelAction();
  };
  const handleSend = () => {
    okAction();
  };

  return (
    <ModalDialog
      htmlId="SendEmailModalDialog"
      className="sample-modal-dialog half-width"
      show={showModal}
      header={
        <ModalDialog.Title>{`QuoteID: ${confirmationId}`}</ModalDialog.Title>
      }
      footer={null}
      onHide={handleCancel}
    >
      <SendEmailTextToCustomer
        onCancel={handleCancel}
        onSend={handleSend}
        data={{ confirmationId, email, mobile }}
      />
    </ModalDialog>
  );
};

export default SendEmailTextModal;

SendEmailTextModal.propTypes = {
  // events
  cancelAction: PropTypes.func,
  okAction: PropTypes.func.isRequired,
  // data
  data: PropTypes.shape({
    confirmationId: PropTypes.string,
    email: PropTypes.string,
    mobile: PropTypes.string
  }),
  showModal: PropTypes.bool
};
SendEmailTextModal.defaultProps = {
  // events
  cancelAction: () => {},
  okAction: () => {},
  // data
  data: {
    confirmationId: "",
    email: "",
    mobile: ""
  },
  showModal: false
};
