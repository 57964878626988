/* eslint-disable no-console */
import React from "react";
import PropTypes from "prop-types";
import FullPageModal from "@cx/ui/FullPageModal";
import { DealerList } from ".";

function DealerModal(props) {
  const hide = () => {
    props.closeModal();
  };

  const modalHeader = <FullPageModal.Title />;

  return (
    <FullPageModal
      htmlId="dealerSelectorModal"
      show={props.show}
      onHide={hide}
      backdrop="static"
      header={modalHeader}
      className=""
    >
      <DealerList show={props.show} hide={hide} />
    </FullPageModal>
  );
}

export default DealerModal;

DealerModal.propTypes = {
  closeModal: PropTypes.func,
  show: PropTypes.bool
};

DealerModal.defaultProps = {
  show: false
};

/* eslint-enable no-console */
