export const getMetaTags = () => document.getElementsByTagName("meta");
/**
 *
 * @param {string} type
 * @param {string} valueType
 * @returns {string}
 */
export const getMetaTagContent = (type, valueType) => {
  const metas = getMetaTags();
  let i = 0;
  for (i; i < metas.length; i++) {
    if (metas[i].getAttribute(type) === valueType) {
      return metas[i].content;
    }
  }
  return null;
};

export const getMetaTagContentByType = valueType =>
  getMetaTagContent("type", valueType) || null;
export const getMetaTagContentByName = valueType =>
  getMetaTagContent("name", valueType) || null;
