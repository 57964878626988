/**
 * Sets a value in the specified cache.
 *
 * @param {string} cacheName - The name of the cache.
 * @param {string} key - The key under which the value should be stored.
 * @param {*} value - The value to be stored.
 * @returns {Promise<void>} - A promise that resolves when the value has been set.
 */
export const setCacheValue = async (cacheName, key, value) => {
  const cache = await caches.open(cacheName);
  const data = new Response(JSON.stringify({ value }), {
    headers: { "Content-Type": "application/json" }
  });
  await cache.put(`/${key}`, data);
};

/**
 * Gets a value from the specified cache.
 *
 * @param {string} cacheName - The name of the cache.
 * @param {string} key - The key of the value to be retrieved.
 * @returns {Promise<*>} - A promise that resolves with the value, or null if the key does not exist.
 */
export const getCacheValue = async (cacheName, key) => {
  const cache = await caches.open(cacheName);
  const response = await cache.match(`/${key}`);
  if (response) {
    const data = await response.json();
    return data.value;
  }
  return null;
};
