import React, { useContext, useState, useEffect } from "react";
import { useNewQuoteContext, Actions } from "../../../state/NewQuoteContext";
import { AppContext } from "../../../state/app-context";
import Button from "@cx/ui/Button";
import Dropdown from "@cx/ui/Dropdown";
import isEmpty from "lodash/isEmpty";
import IconError from "@cx/ui/Icons/IconError";
import {
  formatCustomerMobileNumber,
  formatPrice
} from "../../../utils/formatter.util";
import get from "lodash/get";
import * as gtmEvent from "../../utils/gtag/gtag-event.util";
import { updateOpenerWindow } from "../../../utils/window.util";
import QuoteStatusConstant from "../../../constants/quote-status.constants.js";
import SendEmailTextModal from "../../ui/modals/send-email-text-modal.component";
import {
  hasScheduledPermission,
  hasSendQuotePermission,
  hasAssistanceAccess
} from "../../page-wrapper/utils/quote-util";
import { extractAndFormatAttentionTags } from "../../utils/data-transformer";
import quoteAssistanceService from "../../../services/quote-assistance-service";
import { toast } from "@cx/ui/Toast";
import quoteService from "../services/quote.service";
import ConfirmPopup from "../../ui/modals/ConfirmPopup";
import { loadQuote } from "../../page-wrapper/services/quote-api.service";
import { sleep } from "../../../utils/helper.util";

const QuoteSummary = () => {
  const appContext = useContext(AppContext);
  const {
    localeStrings,
    userPermissions,
    dealerSettings,
    appType,
    user,
    dealer,
    webKey
  } = appContext;
  const { state, dispatch } = useNewQuoteContext();
  const { customer, quoteSummary } = state;
  const { confirmationId, workflowAttentionTag } = quoteSummary;
  const [showSendQuoteModal, setShowSendQuoteModal] = useState(false);
  const [scheduledAccess, setScheduledAccess] = useState(false);
  const [sendQuoteAccess, setSendQuoteAccess] = useState(false);
  const [attentionTags, setAttentionTags] = useState(workflowAttentionTag);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [currentModeAndRequestStatus, setCurrentModeAndRequestStatus] =
    useState({
      mode: null,
      requestStatus: null,
      type: null
    });

  const isSent = quoteSummary.quoteStatus === QuoteStatusConstant.SENT;

  useEffect(() => {
    if (!isEmpty(quoteSummary)) {
      setScheduledAccess(hasScheduledPermission(quoteSummary, userPermissions));
      setSendQuoteAccess(hasSendQuotePermission(quoteSummary));
    }
  }, [quoteSummary, userPermissions]);
  // watch for attention tags
  useEffect(() => {
    if (workflowAttentionTag) {
      setAttentionTags(workflowAttentionTag);
    }
  }, [workflowAttentionTag]);
  // call this to show body masking controlled by new quote context
  const showPageLoading = showPageMask => {
    dispatch({
      type: Actions.SET_PAGE_MASK,
      payload: showPageMask
    });
  };
  const handleLaunchScheduleApp = () => {
    if (
      quoteSummary.quoteStatus ===
        QuoteStatusConstant.CONVERTED_TO_APPOINTMENT ||
      quoteSummary.quoteStatus === QuoteStatusConstant.EXPIRED ||
      quoteSummary.appointmentCode ||
      userPermissions.canCreateReservationForConsumer === false
    ) {
      return;
    }
    const { confirmationId } = quoteSummary;
    const { dealer, loginURL } = appContext;
    const { webKey } = dealer;
    const scheduleAppURL = `${loginURL}/?w=${webKey}&act=mqa&qc=${confirmationId}`;
    gtmEvent.trackGAEventWithParam(
      "ga.newquote.schedule_service_appointment_click",
      {
        result: `${dealer.dealerCode} - ${confirmationId}`
      }
    );
    updateOpenerWindow(scheduleAppURL, "_Scheduling");
    dispatch({
      type: Actions.SET_TOGGLE_MODAL,
      payload: true
    });
  };

  // handlers triggered to show/hide  send quote customer modal
  const openSendQuoteCustomerModal = () => {
    setShowSendQuoteModal(true);
    dispatch({
      type: Actions.SET_SEND_MAIL_TO_CUSTOMER,
      payload: { [quoteSummary.confirmationId]: false }
    });
  };
  const closeSendQuoteCustomerModal = () => {
    setShowSendQuoteModal(false);
  };
  // callback triggered when quote sent to customer and received ack from API,then update new quote context and show banner in summary page
  const callbackSendQuoteCustomer = () => {
    dispatch({
      type: Actions.SET_SEND_MAIL_TO_CUSTOMER,
      payload: { [quoteSummary.confirmationId]: true }
    });

    sleep(5000).then(() => {
      dispatch({
        type: Actions.SET_SEND_MAIL_TO_CUSTOMER,
        payload: { [quoteSummary.confirmationId]: false }
      });
    });

    setShowSendQuoteModal(false);
    callbackLoadQuote();
  };
  // callback getQuote API to read latest quote and update in new quote context
  const callbackLoadQuote = async () => {
    const { dealer } = appContext;
    const response = await loadQuote({
      confirmationId: quoteSummary.confirmationId,
      localeStrings: appContext.localeStrings,
      dealerCode: dealer.dealerCode,
      appContext
    });
    if (!isEmpty(response)) {
      dispatch({
        type: Actions.UPDATE_QUOTE,
        payload: response
      });
    }
  };

  const showConfirmationModal = (mode, requestStatus, type) => {
    setShowConfirmation(true);
    setCurrentModeAndRequestStatus({ mode, requestStatus, type });
  };

  const closeConfirmationModal = () => {
    setShowConfirmation(false);
  };

  /**
    Handles the update of a confirmation quote's status and performs related actions based on the current mode.
    This function updates the quote status in the database and triggers appropriate dispatch actions.
    It also handles different modes like 'request' to perform corresponding actions.
    @throws {Error} If there's an issue during the process, an error message is displayed.
  */
  const handleConfirmationQuoteUpdate = async () => {
    try {
      // Extract necessary data from the app context and current state
      const { dealerCode } = dealer;
      const { confirmationId } = quoteSummary;
      const { mode, requestStatus, type } = currentModeAndRequestStatus;
      const status = QuoteStatusConstant.REQUEST_ASSISTANCE;

      // Handle different modes: 'request'
      if (mode === "request") {
        handleQuoteAssistance(requestStatus, type);
        // When mode === "request" the updateQuoteStaus call below should not be made.
        return;
      }

      // @note: read response when quote status updated in DB
      const response = await quoteService.updateQuoteStatus({
        dealerCode,
        confirmationId,
        lastModByUserId: user.quoteUserId,
        quoteStatus: status
      });

      // If response is valid, dispatch appropriate actions for quote update and status change
      if (!isEmpty(response)) {
        const { confirmationId } = response;
        if (!isEmpty(confirmationId)) {
          dispatch({
            type: Actions.UPDATE_QUOTE,
            payload: response
          });
        }
      }
    } catch (error) {
      // Handle errors and display appropriate message
      const msg = error["message"]
        ? error.message
        : localeStrings["sq.errors.network.error"];
      if (
        error.hasOwnProperty("response") &&
        error["response"].status === 422
      ) {
        toast.error(
          "This quote has already been converted to an appointment, so it cannot be edited.  Please refresh the page to see the current quote status.",
          {
            closeOnClick: true
          }
        );
      }
      console.error(msg);
    }
  };
  let email = "";
  let formattedPhone = "";
  if (!isEmpty(customer)) {
    email = !customer.email ? "" : customer.email;
    formattedPhone = formatCustomerMobileNumber(
      customer.contactInfo.phoneNumbers
    );
  }
  const sendQuoteCustomerModal = showSendQuoteModal && (
    <SendEmailTextModal
      data={{
        confirmationId,
        email,
        mobile: formattedPhone
      }}
      okAction={callbackSendQuoteCustomer}
      cancelAction={closeSendQuoteCustomerModal}
      showModal={showSendQuoteModal}
    />
  );
  const requestAccess = hasAssistanceAccess(quoteSummary);
  // @skip customer flow: No customer added for quote, disable buttons - SEND, SCHEDULED, Assistance
  const scheduleDisabled = !scheduledAccess || isEmpty(customer);
  const sendQuoteDisabled = !sendQuoteAccess || isEmpty(customer);
  const requestActionDisabled = !requestAccess || isEmpty(customer);
  // Request Assistance dropdown items added as below
  const dropDownOptions = [];
  const isItemPresent = (array, item) => {
    return array.includes(item);
  };
  const tags = extractAndFormatAttentionTags(attentionTags);
  const isServicePresent = isItemPresent(tags, "Service");
  const isPartsPresent = isItemPresent(tags, "Parts");

  const partsEmail = dealerSettings?.partsEmail || "";
  const serviceEmail = dealerSettings?.serviceEmail || "";
  if (appType === "SQ" && requestAccess && dealerSettings.quoteAssistance) {
    if (!isEmpty(serviceEmail)) {
      const label = !isServicePresent
        ? localeStrings["sq.pastquotes.request_service_assistance_lbl"]
        : localeStrings["sq.pastquotes.complete_service_assistance_lbl"];
      const value = !isServicePresent
        ? "requestServiceAssistance"
        : "completeServiceAssistance";
      const serviceRequestStatus = !isServicePresent ? "request" : "resolve";
      const type = "service";
      dropDownOptions.push({
        label,
        value,
        disabled: requestActionDisabled,
        onSelect: isSent
          ? () => showConfirmationModal("request", serviceRequestStatus, type)
          : () => handleQuoteAssistance(serviceRequestStatus, type)
      });
    }
    if (!isEmpty(partsEmail)) {
      const label = !isPartsPresent
        ? localeStrings["sq.pastquotes.request_parts_assistance_lbl"]
        : localeStrings["sq.pastquotes.complete_parts_assistance_lbl"];
      const value = !isPartsPresent
        ? "requestPartsAssistance"
        : "completePartsAssistance";
      const partsRequestStatus = !isPartsPresent ? "request" : "resolve";
      const type = "parts";
      dropDownOptions.push({
        label,
        value,
        disabled: requestActionDisabled,
        onSelect: isSent
          ? () => showConfirmationModal("request", partsRequestStatus, type)
          : () => handleQuoteAssistance(partsRequestStatus, type)
      });
    }
  }
  const handleQuoteAssistance = async (requestStatus, type) => {
    showPageLoading(true);
    await quoteAssistanceService
      .processQuoteAssistance(
        user,
        dealer.dealerCode,
        confirmationId,
        requestStatus,
        type,
        webKey
      )
      .then(response => {
        showPageLoading(false);
        if (response) {
          const workflowAttentionTagResponse =
            response?.quoteTransfer?.workflowAttentionTag || [];
          dispatch({
            type: Actions.UPDATE_WORKFLOW_ATTENTION_TAG,
            payload: {
              workflowAttentionTag: workflowAttentionTagResponse,
              confirmationId: response?.quoteTransfer?.confirmationId,
              quoteStatus: response?.quoteTransfer?.quoteStatus
            }
          });

          if (requestStatus === "request") {
            dispatch({
              type: Actions.SET_REQUEST_STATUS,
              payload: {
                requestStatus: requestStatus === "request",
                type
              }
            });

            sleep(5000).then(() => {
              dispatch({
                type: Actions.SET_REQUEST_STATUS,
                payload: {
                  requestStatus: false,
                  type
                }
              });
            });
          } else {
            dispatch({
              type: Actions.SET_REQUEST_STATUS,
              payload: {
                requestStatus: requestStatus === "resolve",
                type,
                complete: true
              }
            });

            sleep(5000).then(() => {
              dispatch({
                type: Actions.SET_REQUEST_STATUS,
                payload: {
                  requestStatus: false,
                  type,
                  complete: true
                }
              });
            });
          }
        }
      })
      .catch(error => {
        showPageLoading(false);
        console.error(error);
        dispatch({
          type: Actions.SET_REQUEST_STATUS,
          payload: {
            requestStatus: true,
            type:
              requestStatus === "request" ? "requested_fail" : "completed_fail"
          }
        });
        hideDefaultTimeoutBanner(requestStatus);
      });
  };
  // Method called to hide assistance banner after 30 seconds
  const hideDefaultTimeoutBanner = requestStatus => {
    setTimeout(function () {
      dispatch({
        type: Actions.SET_REQUEST_STATUS,
        payload: {
          requestStatus: false,
          type:
            requestStatus === "request" ? "requested_fail" : "completed_fail"
        }
      });
    }, 30000);
  };

  const modifyQuotePopup = (
    <ConfirmPopup
      title={localeStrings["sq.common.alert_lbl"]}
      message={
        localeStrings[
          "sq.newquote.location_bar.sent_quote-status-restart_modal_message"
        ]
      }
      show={showConfirmation}
      okText={localeStrings["sq.common.proceed_button"]}
      cancelText={localeStrings["sq.common.cancel_button"]}
      okAction={handleConfirmationQuoteUpdate}
      cancelAction={closeConfirmationModal}
      buttonStyle="danger"
    />
  );

  return (
    <>
      <div className="quote-tax-box">
        <div className="tax-labels">
          {localeStrings["sq.newquote.summary.sub_total_lbl"]}:
          <span className="total-lbl">
            {localeStrings["sq.newquote.summary.total_lbl"]}:
          </span>
        </div>
        <div className="tax-values">
          <span>{formatPrice(get(quoteSummary, "subtotalPrice", "0"))}</span>
          <span className="total-lbl bolder">
            {formatPrice(get(quoteSummary, "totalPrice", "0"))}
          </span>
        </div>
      </div>
      <div className="button-container">
        <div className="service-and-assistance-container">
          <Button
            className="send-button"
            buttonStyle="secondary"
            htmlId="sendQuoteBtn"
            onClick={() => openSendQuoteCustomerModal()}
            disabled={sendQuoteDisabled}
          >
            {localeStrings["sq.newquote.summary.send_button"]}
          </Button>
          <Dropdown
            buttonStyle="secondary"
            htmlId="assistanceDropdownBtn"
            name="assistanceDropdown"
            className="assistance-dropdown"
            options={dropDownOptions}
            pullRight
            disabled={requestActionDisabled}
          >
            {localeStrings["sq.newquote.summary.assistance_dropdown"]}
          </Dropdown>
        </div>
        <button
          type="button"
          id="scheduleServiceAppointmentBtn"
          className="savequote-btn-group"
          onClick={handleLaunchScheduleApp}
          disabled={scheduleDisabled}
        >
          <div className="btn-content">
            {
              localeStrings[
                "sq.newquote.confirmation.schedule_service_appointment_action"
              ]
            }
          </div>
        </button>
        {isEmpty(customer) ? (
          <div className="help-block__wrapper flex">
            <IconError className="error-icon" />
            <span className="help-block">
              {localeStrings["sq.newquote.skip_customer_schedule_warning"]}
            </span>
          </div>
        ) : null}
      </div>
      {sendQuoteCustomerModal}
      {modifyQuotePopup}
    </>
  );
};
export default QuoteSummary;
