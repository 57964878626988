export const DealerSettingsConstant = {
  QUOTE_EXPIRATION_DAYS: "QUOTE_EXPIRATION_DAYS",
  DISCLAIMER: "DISCLAIMER",
  DISPLAY_LABOR_HOURS: "DISPLAY_LABOR_HOURS",
  DISPLAY_PART_NUMBERS: "DISPLAY_PART_NUMBERS",
  DISPLAY_LABOR_PARTS_DETAILS: "DISPLAY_LABOR_PARTS_DETAILS",
  DEFAULT_DISCLAIMER_MSG:
    "This quote has been provided at the request of the customer, based on the information provided. It does not cover any additional parts or labor which may be required after the components have been disassembled. Diagnostic services include the price for diagnosis only, and the actual labor or parts required to fix the concern are not included in the original quoted price. Therefore the above quoted prices may differ from the final invoice price.",
  QUOTE_ASSISTANCE: "QUOTE_ASSISTANCE",
  SERVICE_EMAIL: "SERVICE_EMAIL",
  PARTS_EMAIL: "PARTS_EMAIL"
};

export const SettingFields = {
  QUOTE_EXPIRATION_DAYS: "quoteExpirationDays",
  DISCLAIMER: "disclaimer",
  DISPLAY_LABOR_HOURS: "displayLaborHours",
  DISPLAY_PART_NUMBERS: "displayPartNumbers",
  DISPLAY_LABOR_PARTS_DETAILS: "displayLaborPartsDetails",
  QUOTE_ASSISTANCE: "quoteAssistance",
  SERVICE_EMAIL: "serviceEmail",
  PARTS_EMAIL: "partsEmail"
};
