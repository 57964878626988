import React from "react";
import PropTypes from "prop-types";
import Badge from "@cx/ui/Badge";
import { getStatusProperties } from "./status-badge.util";

/**
 * @description Reusable component to render status badges.
 * It accepts status in ALL_CAPS with underscore ("IN_PROGRESS").
 * It can be reused for other usecases like "Parts". In this case the status prop should be the label string formatted as intended and, preferebly, passed the second optional color parameter.
 * It also accepts an optional className parameter for any further styling custimization
 * @param {{children: any, status: string, color?: string, className?: string}} props
 * @return React Component
 */
const StatusBadge = props => {
  const { status, color, className } = props;
  const statusArgs = { status, ...(color ? { color } : {}) };
  const statusProps = getStatusProperties(statusArgs);
  return (
    <Badge
      className={className || ""}
      color={statusProps.color}
      htmlId="statusBadge"
    >
      {statusProps.label}
    </Badge>
  );
};

export default StatusBadge;

StatusBadge.defaultProps = {
  status: "IN_PROGRESS",
  color: null,
  className: null
};

StatusBadge.propTypes = {
  status: PropTypes.string.isRequired,
  color: PropTypes.string,
  className: PropTypes.string
};
