const CUSTOMER_SEARCH = "CUSTOMER_SEARCH";
const ADD_CUSTOMER = "ADD_CUSTOMER";
const ADD_CUSTOMER_ADD_VEHICLE = "ADD_CUSTOMER_ADD_VEHICLE";
const EDIT_CUSTOMER = "EDIT_CUSTOMER";
const VEHICLE_RESULTS = "VEHICLE_RESULTS";
const ADD_VEHICLE = "ADD_VEHICLE";
const EDIT_VEHICLE = "EDIT_VEHICLE";
const SKIP_CUSTOMER = "SKIP_CUSTOMER";
const ADD_SERVICES = "ADD_SERVICES";
const SERVICE_SUMMARY = "SERVICE_SUMMARY";
const SEARCH_SERVICE = "SEARCH_SERVICE";

const customerPages = [CUSTOMER_SEARCH, ADD_CUSTOMER, EDIT_CUSTOMER];
const vehiclePages = [
  VEHICLE_RESULTS,
  ADD_VEHICLE,
  EDIT_VEHICLE,
  SKIP_CUSTOMER
];
const servicesPages = [ADD_SERVICES, SERVICE_SUMMARY, SEARCH_SERVICE];

const CUSTOMER_SEARCH_WRAPPER = "CUSTOMER_SEARCH_WRAPPER";
const QUOTE_SUMMARY_WRAPPER = "QUOTE_SUMMARY_WRAPPER";
const SEARCH_SERVICE_WRAPPER = "SEARCH_SERVICE_WRAPPER";
const EDIT_SERVICE_WRAPPER = "EDIT_SERVICE_WRAPPER";
const EDIT_MENU_PACKAGE_WRAPPER = "EDIT_MENU_PACKAGE_WRAPPER";

export {
  CUSTOMER_SEARCH,
  ADD_CUSTOMER,
  ADD_CUSTOMER_ADD_VEHICLE,
  EDIT_CUSTOMER,
  VEHICLE_RESULTS,
  ADD_VEHICLE,
  EDIT_VEHICLE,
  SKIP_CUSTOMER,
  ADD_SERVICES,
  SERVICE_SUMMARY,
  SEARCH_SERVICE,
  customerPages,
  vehiclePages,
  servicesPages,
  CUSTOMER_SEARCH_WRAPPER,
  QUOTE_SUMMARY_WRAPPER,
  SEARCH_SERVICE_WRAPPER,
  EDIT_SERVICE_WRAPPER,
  EDIT_MENU_PACKAGE_WRAPPER
};

export const GlobalOpsServiceType = {
  GLOBALCATALOG: "GlobalCatalog",
  DEALERCATALOG: "DealerPublishedCatalog"
};

export const priceSourceLabels = {
  MSRP: "MSRP",
  DMS: "DMS",
  MANUAL: "MANUAL"
};
