import React, { useState, useContext } from "react";
import Button from "@cx/ui/Button";
import TextInput from "@cx/ui/TextInput";
import isEmpty from "lodash/isEmpty";
import _get from "lodash/get";
import { toast } from "@cx/ui/Toast";
import { AppContext } from "../../state/app-context";
import CustomerCard from "./CustomerCard";
import {
  ADD_CUSTOMER,
  VEHICLE_RESULTS,
  SEARCH_SERVICE,
  SERVICE_SUMMARY,
  SKIP_CUSTOMER
} from "../../constants/pages.constants";
import { useNewQuoteContext, Actions } from "../../state/NewQuoteContext";
import customerService from "./services/search-customer.service";
import useComponentDidMount from "../../hooks/useComponentDidMount";
import { getVariantForVehicle, updateVin } from "../utils/data-transformer";
import vehicleService from "../vehicle/services/vehicle.service";
import vehicleSchema from "../vehicle/schemas/vehicle.schema";
import {
  getPayTypeDetails,
  getServiceTypeDetails,
  addMetaVehicle,
  loadDrivingConditionsData
} from "../page-wrapper/services/customer-vehicle.service";
import * as gtmEvent from "../utils/gtag/gtag-event.util";
import { doesServiceContractExists } from "../../utils/quote.util";
import {
  filterServiceContracts,
  getServiceContractsInfo$,
  getVendorListTransformed
} from "../service-contract/utils/service-contracts.util";
import { appTypes } from "../../constants/app.constants";

const SearchCustomer = () => {
  const appContext = useContext(AppContext);
  const { localeStrings, dealer, locale, appType } = useContext(AppContext);
  const { state, dispatch } = useNewQuoteContext();
  const [searchTerm, setSearchTerm] = useState(state.searchCustomer.query);
  const [isLoaded, setIsLoaded] = useState(false);
  const countMsg =
    isLoaded && state.searchCustomer.customers.length > 0
      ? `${state.searchCustomer.customers.length} ${localeStrings["sq.common.results_found_lbl"]}`
      : "";
  const isCSRApp = appType === appTypes.CSR;

  useComponentDidMount(() => {
    dispatch({
      type: Actions.SET_PAGE_TITLE,
      payload: localeStrings["sq.newquote.customer.search_customer_lbl"]
    });
  });

  const handleSkipCustomer = () => {
    gtmEvent.trackGAEventWithParam("ga.newquote.skip_customer_click", {
      result: appContext.dealer.dealerCode
    });
    dispatch({
      type: Actions.SET_IS_SKIP_CUSTOMER_FLOW,
      payload: true
    });
    dispatch({
      type: Actions.SET_CURRENT_PAGE,
      payload: SKIP_CUSTOMER
    });
  };

  const handleNewCustomer = () => {
    gtmEvent.trackGAEvent("ga.newquote.new_customer_click");
    dispatch({
      type: Actions.SET_CURRENT_PAGE,
      payload: ADD_CUSTOMER
    });
  };

  const saveCustomersToContext = customers => {
    dispatch({
      type: Actions.SET_SEARCH_CUSTOMER,
      payload: {
        customers,
        query: searchTerm
      }
    });
  };

  const handleSearchTermChange = e => {
    setSearchTerm(e.target.value);
  };

  const showPageLoading = showPageMask => {
    dispatch({
      type: Actions.SET_PAGE_MASK,
      payload: showPageMask
    });
  };

  const handleFormSubmit = async e => {
    e.preventDefault();
    showPageLoading(true);
    setIsLoaded(false);

    try {
      const response = await customerService.searchCustomers({
        appContext,
        searchTerm
      });
      if (response && response.success) {
        response.customers.sort((lastCustomer, nextCustomer) => {
          const lastCustomerFullName =
            `${lastCustomer.firstName} ${lastCustomer.lastName}`.toLocaleLowerCase();
          const nextCustomerFullName =
            `${nextCustomer.firstName} ${nextCustomer.lastName}`.toLocaleLowerCase();

          return nextCustomerFullName < lastCustomerFullName ? 1 : -1;
        });
        saveCustomersToContext(response.customers);
      }
    } catch (error) {
      const msg = !error ? "Error occurred while loading data" : error.message;
      toast.error(msg, {
        closeOnClick: true
      });
      saveCustomersToContext([]);
    } finally {
      showPageLoading(false);
      setIsLoaded(true);
    }
  };

  const handleSelectCustomer = async customerId => {
    try {
      const response = await customerService.searchCustomerById({
        appContext,
        customerId
      });

      if (response && response.success) {
        gtmEvent.trackGAEvent("ga.newquote.select_customer_click");
        if (isEmpty(_get(response.customers, "vehicles", []))) {
          response.customers.vehicles = [];
        }
        dispatch({
          type: Actions.SET_CUSTOMER,
          payload: {
            customer: response.customers,
            isNewCustomer: false
          }
        });
        // @change: when search by VIN case, page should auto select vehicle with matching VIN and land on service search page
        const customerObj = response.customers;
        const vehicleFound = findVehicleInCustomerUsingVIN(
          customerObj,
          searchTerm
        );
        console.log("vehicle record", vehicleFound);
        if (!isEmpty(vehicleFound)) {
          callbackSearchServices(vehicleFound, customerObj);
        } else {
          dispatch({
            type: Actions.SET_CURRENT_PAGE,
            payload: VEHICLE_RESULTS
          });
        }
      }
    } catch (error) {
      const msg = !error ? "Error occurred while loading data" : error.message;
      toast.error(msg, {
        closeOnClick: true
      });
    }
  };
  // Method to check if selected customer has one vehicle using matched VIN
  const findVehicleInCustomerUsingVIN = (customer, searchWord) => {
    let vehicleObj = null;
    const vehicles = customer.vehicles || [];
    const matches = vehicles.filter(function (obj) {
      const vehicleVin = obj["vin"] || "";
      return vehicleVin === searchWord;
    });
    if (matches.length === 1) {
      vehicleObj = matches[0];
    }
    return vehicleObj;
  };
  // method to call dependent APIs before landing Search service page
  const callbackSearchServices = async (selectedVehicle, customer) => {
    showPageLoading(true);
    const { makes, quoteSummary } = state;
    const { confirmationId, quoteServices } = quoteSummary;
    try {
      const hasAttributes =
        vehicleSchema.vehicleCardSchema.isValidSync(selectedVehicle);
      if (hasAttributes) {
        const payTypesData = await getPayTypeDetails(
          dealer.dealerCode,
          selectedVehicle.make
        );
        dispatch({
          type: Actions.SET_PAY_TYPES,
          payload: payTypesData
        });
        const serviceTypesData = await getServiceTypeDetails(
          dealer.dealerCode,
          selectedVehicle.make,
          dealer.dmsType
        );
        dispatch({
          type: Actions.SET_SERVICE_TYPES,
          payload: serviceTypesData
        });
        if (doesServiceContractExists(payTypesData)) {
          if (isCSRApp) {
            const serviceContracts = await getServiceContractsInfo$(
              dealer.dealerCode,
              selectedVehicle?.vin,
              customer?.commonConsumerId
            );

            dispatch({
              type: Actions.SET_SERVICE_CONTRACTS,
              payload: filterServiceContracts(serviceContracts)
            });

            dispatch({
              type: Actions.SET_VENDOR_LIST,
              payload: getVendorListTransformed(serviceContracts)
            });
          }
          // TODO: uncomment this lines once vendors API is working
          // const vendorListData = await getVendorListDetails(
          //   dealer.dealerCode,
          //   selectedVehicle.make
          // );
          // dispatch({
          //   type: Actions.SET_VENDOR_LIST,
          //   payload: vendorListData || []
          // });
        }
      }
      let updatedVehicle = await addMetaVehicle(selectedVehicle, dealer);
      if (!isEmpty(updatedVehicle)) {
        updatedVehicle.vin = updateVin(selectedVehicle);
        updatedVehicle.variant = getVariantForVehicle(selectedVehicle, makes);
        updatedVehicle = await loadDrivingConditionsData(
          updatedVehicle,
          dealer.dealerCode,
          locale
        );
      }
      dispatch({
        type: Actions.SET_VEHICLE,
        payload: updatedVehicle
      });
      const futureAppointment = await vehicleService.getHasFutureAppointments({
        customer,
        vehicle: selectedVehicle,
        appConfig: dealer
      });
      dispatch({
        type: Actions.SET_VEHICLE_FUTURE_APPOINTMENT,
        payload: futureAppointment
      });
      if (quoteServices.length === 0) {
        dispatch({
          type: Actions.SET_CURRENT_PAGE,
          payload: SEARCH_SERVICE
        });
      } else {
        if (!isEmpty(confirmationId)) {
          dispatch({
            type: Actions.SET_CURRENT_PAGE,
            payload: SERVICE_SUMMARY
          });
        }
      }
    } catch (error) {
      console.error("Error occurred in callbackSearchServices", error);
    } finally {
      showPageLoading(false);
    }
  };
  return (
    <div className="cust-outer">
      <form autoComplete="off" onSubmit={handleFormSubmit}>
        <TextInput
          htmlId="customerSearch"
          label={localeStrings["sq.newquote.customer.search_by_lbl"]}
          name="searchTerm"
          onChange={handleSearchTermChange}
          value={searchTerm}
          autoComplete="off"
          appendChild={
            <Button htmlId="custSearchBtn" type="submit">
              <i className="fa fa-search" aria-hidden="true" />
            </Button>
          }
        />
      </form>
      <div className="cust-msg">{countMsg}</div>
      {state.searchCustomer.customers.length === 0 && isLoaded ? (
        <div className="sq-no-results">
          <b>{localeStrings["sq.common.no_results_found_lbl"]}</b>
        </div>
      ) : null}

      {state.searchCustomer.customers.length > 0 ? (
        <div className="cust-grid">
          {state.searchCustomer.customers.map(customer => {
            return (
              <CustomerCard
                key={`key-${customer.personId.toString()}`}
                rowRecord={customer}
                clicked={() => handleSelectCustomer(customer.personId)}
              />
            );
          })}
        </div>
      ) : null}

      <div className="cust-hr-style" />
      <div className="cust-flex-messanger">
        <span
          className="hand-cursor"
          id="newCustomerLink"
          onClick={handleNewCustomer}
        >
          {localeStrings["sq.newquote.customer.new_customer_lbl"]}
        </span>
        <span
          className="hand-cursor"
          id="skipCustomerLink"
          onClick={handleSkipCustomer}
        >
          {localeStrings["sq.newquote.customer.skip_customer_lbl"]}
        </span>
      </div>
    </div>
  );
};

export default SearchCustomer;
