import React from "react";
import {
  ResponsiveContainer,
  AreaChart,
  XAxis,
  YAxis,
  Area,
  Tooltip
} from "recharts";
// eslint-disable-next-line unused-imports/no-unused-imports
import * as ModelsTypes from "../../../../../../@types/models.types";
// eslint-disable-next-line unused-imports/no-unused-imports
import * as CommonTypes from "../../../../../../@types/common.types";
import CustomTooltip from "../widgets/custom-tooltip.component";

/**
 * @description Quotes timeline area chart component.
 * @param {{width: string, height: number, data: Array<ModelsTypes.AreaChartData>, dateRange: string}} props
 * @returns {CommonTypes.Component}
 */
const QuotesAreaChart = props => {
  const { width, height, data, dateRange } = props;
  /** @type {number} */
  const inProgress = data.reduce((acc, curr) => {
    return acc + curr.inProgress;
  }, 0);
  /** @type {number} */
  const sent = data.reduce((acc, curr) => {
    return acc + curr.sent;
  }, 0);
  /** @type {number} */
  const converted = data.reduce((acc, curr) => {
    return acc + curr.converted;
  }, 0);
  const expired = data.reduce((acc, curr) => {
    return acc + curr.expired;
  }, 0);
  /** @type {boolean} */
  const noQuotes =
    inProgress === 0 && sent === 0 && converted === 0 && expired === 0;
  return (
    <div
      className={`area-chart__wrapper${
        dateRange === "90" && !noQuotes ? "--large" : ""
      }`}
    >
      {noQuotes ? (
        <div className="area-chart__no-quotes">No quotes created</div>
      ) : null}
      <ResponsiveContainer width={width} height={height}>
        <AreaChart
          width={500}
          height={400}
          data={data}
          margin={{
            top: 10,
            right: 30,
            left: 0,
            bottom: 0
          }}
        >
          <XAxis
            type="category"
            ticks={[data[0].name, data[data.length - 1].name]}
            dataKey="name"
            tickLine={false}
            tickMargin={5}
            interval="preserveStartEnd"
            allowDataOverflow={true}
            axisLine={{ strokeWidth: "0.5" }}
          />
          <YAxis
            type="number"
            interval={0}
            tickCount={6}
            allowDecimals={false}
            tickLine={false}
            tickMargin={5}
            axisLine={{ strokeWidth: "0.5" }}
          />
          <Tooltip content={<CustomTooltip />} cursor={false} />
          <Area
            type="linear"
            dataKey="converted"
            stackId="1"
            stroke={converted !== 0 ? "#CFFDC3" : "none"}
            fill="#CFFDC3"
          />
          <Area
            type="linear"
            dataKey="inProgress"
            stackId="1"
            stroke={inProgress !== 0 ? "#E5EDFD" : "none"}
            fill="#E5EDFD"
          />
          <Area
            type="linear"
            dataKey="sent"
            stackId="1"
            stroke={sent !== 0 ? "#D6C2FF" : "none"}
            fill="#D6C2FF"
            activeDot={{ fill: "#2B6BDD" }}
          />
          <Area
            type="linear"
            dataKey="expired"
            stackId="1"
            stroke={sent !== 0 ? "#E4E9EF" : "none"}
            fill="#E4E9EF"
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

export default QuotesAreaChart;
