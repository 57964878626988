import PropTypes from "prop-types";

export const splitPoPricingProps = PropTypes.shape({
  cost: PropTypes.number,
  price: PropTypes.number,
  laborCost: PropTypes.number,
  laborPrice: PropTypes.number,
  partsCost: PropTypes.number,
  partsPrice: PropTypes.number,
  subletTotalCost: PropTypes.number.isRequired
}).isRequired;

export const splitCostSubletProps = PropTypes.shape({
  vendorNotes: PropTypes.string.isRequired,
  poNumber: PropTypes.string,
  invoiceNumber: PropTypes.string,
  vendor: PropTypes.string.isRequired,
  poPricing: splitPoPricingProps
}).isRequired;
