import React from "react";
import format from "../../../utils/format";
import TruncateText from "@cx/ui/TruncateText";
import {
  getFormattedContentForPaymentMethod,
  getFormattedTextForPaymentMethod
} from "../../../utils/payers.util";

const CustomerPayTypePaymentMethodsComponent = ({ payerConfig }) => {
  const paymentMethods = payerConfig?.payer?.payments;

  return paymentMethods?.length > 0
    ? paymentMethods?.map(method => {
        return (
          <div
            className="customer-payment-method-container"
            key={`${method}${method?.paymentId}`}
          >
            <span className="customer-payment-method-text">
              {getFormattedTextForPaymentMethod(method)}
            </span>

            <div className="customer-payment-method-id">
              <div className="customer-payment-method-content">
                <TruncateText htmlId="TruncateTextTooltip">
                  {getFormattedContentForPaymentMethod(method)}
                </TruncateText>
              </div>
              <div className="customer-payment-method-notes">
                <TruncateText htmlId="TruncateTextTooltip">
                  {method?.notes}
                </TruncateText>
              </div>
            </div>

            <span className="customer-payment-method-amount">
              {format.currency(method?.paymentAmount)}
            </span>
          </div>
        );
      })
    : null;
};

export default CustomerPayTypePaymentMethodsComponent;
