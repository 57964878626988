// @ts-check
import isEmpty from "lodash/isEmpty";
import { dashboardQuoteStatus } from "../constants/dashboard.constants";
// eslint-disable-next-line unused-imports/no-unused-imports
import * as ModelsTypes from "../../../../@types/models.types";

/**
 * @description Refindes quotes data, filtering properties that aren't needed for charts.
 * @param {Array<ModelsTypes.QuoteDto>} quotes
 * @returns {Array<ModelsTypes.RefineQuote>}
 */
const refineQuotesDataSet = quotes => {
  const optimizedQuotes = quotes.map(quote => {
    const {
      confirmationId,
      quoteId,
      createdBy,
      creationDateTime,
      quoteStatus,
      appointmentCode,
      totalPrice,
      items
    } = quote;
    return {
      confirmationId,
      quoteId,
      createdBy,
      creationDateTime,
      quoteStatus,
      appointmentCode,
      totalPrice,
      items
    };
  });
  return optimizedQuotes;
};

/**
 * @description Creates the dataset for the quotes numeric breakdown.
 * @param {Array<ModelsTypes.RefineQuote>} refinedQuotes
 * @returns {ModelsTypes.ChartData}
 */
const createQuotesNumericBreakdownDataSet = refinedQuotes => {
  const quotesInProgress = refinedQuotes.filter(
    quote =>
      quote.quoteStatus === dashboardQuoteStatus.IN_PROGRESS ||
      quote.quoteStatus === dashboardQuoteStatus.REQUEST_ASSISTANCE ||
      quote.quoteStatus === dashboardQuoteStatus.READY_TO_SEND
  );
  const quotesSent = refinedQuotes.filter(
    quote => quote.quoteStatus === dashboardQuoteStatus.SENT
  );
  const quotesConverted = refinedQuotes.filter(
    quote => quote.quoteStatus === dashboardQuoteStatus.CONVERTED_TO_APPOINTMENT
  );
  const quotesExpired = refinedQuotes.filter(
    quote => quote.quoteStatus === dashboardQuoteStatus.EXPIRED
  );

  return {
    chartData: [
      {
        name: dashboardQuoteStatus.IN_PROGRESS,
        value: quotesInProgress.length
      },
      {
        name: dashboardQuoteStatus.SENT,
        value: quotesSent.length
      },
      {
        name: dashboardQuoteStatus.CONVERTED_TO_APPOINTMENT,
        value: quotesConverted.length
      },
      {
        name: dashboardQuoteStatus.EXPIRED,
        value: quotesExpired.length
      },
      {
        name: dashboardQuoteStatus.NO_QUOTES,
        value: isEmpty(refinedQuotes) ? 1 : 0
      }
    ],
    totalQuotes: refinedQuotes.length
  };
};

/**
 * @description Formats an amount as a number with comma separators and returns a string
 * @param {number} rawTotal
 * @returns {string} Formatted total
 */
const roundAndFormatTotal = rawTotal => {
  const formattedTotalString = Math.round(rawTotal)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return formattedTotalString;
};

/**
 * @description Creates the dataset for the potential value chart.
 * @param {Array<ModelsTypes.RefineQuote>} refinedQuotes
 * @returns {ModelsTypes.ChartData}
 */
const createDataSetForPotentialValueChart = refinedQuotes => {
  /** @type {Array<ModelsTypes.RefineQuote>} */
  const sentQuotes = refinedQuotes.filter(
    quote => quote.quoteStatus === dashboardQuoteStatus.SENT
  );
  /** @type {Array<ModelsTypes.RefineQuote>} */
  const convertedQuotes = refinedQuotes.filter(
    quote => quote.quoteStatus === dashboardQuoteStatus.CONVERTED_TO_APPOINTMENT
  );
  /** @type {number} */
  const sentTotal = sentQuotes.reduce(
    (accumulator, currentValue) => accumulator + currentValue.totalPrice,
    0
  );
  /** @type {number} */
  const convertedTotal = convertedQuotes.reduce(
    (accumulator, currentValue) => accumulator + currentValue.totalPrice,
    0
  );
  return {
    chartData: [
      {
        name: dashboardQuoteStatus.SENT + "_TOTAL",
        value: sentTotal,
        formattedValue: roundAndFormatTotal(sentTotal)
      },
      {
        name: dashboardQuoteStatus.CONVERTED_TO_APPOINTMENT + "_TOTAL",
        value: convertedTotal,
        formattedValue: roundAndFormatTotal(convertedTotal)
      },
      {
        name: dashboardQuoteStatus.NO_QUOTES,
        value: isEmpty(sentTotal) && isEmpty(convertedQuotes) ? 1 : 0
      }
    ]
  };
};

/**
 * @description Formats date string to a specified format pattern
 * @param {string} date date string in format YYYY-MM-DD
 * @param {string} pattern
 * @returns {string} formattedDate

 */
const formatDate = (date, pattern) => {
  const year = date.slice(0, 4);
  const month = date.slice(5, 7);
  const day = date.slice(8, 10);
  let formattedDate;
  if (pattern === "MM/DD/YYYY") {
    formattedDate = `${month}/${day}/${year}`;
  } else if (pattern === "MM/DD") {
    formattedDate = `${month}/${day}`;
  } else if (pattern === "YYYY-MM-DD") {
    formattedDate = `${year}-${month}-${day}`;
  } else if (pattern === "M-D-YYYY") {
    formattedDate = `${parseInt(month, 10)}-${parseInt(day, 10)}-${year}`;
  } else {
    formattedDate = "";
  }
  return formattedDate;
};

/**
 * @description Removes leading zeroes from a date string provided in format "MM/DD/YYYY"
 * @param {string} date
 * @param {string} pattern
 * @returns {string}
 */
const removeLeadingZeroesFromDate = (date, pattern) => {
  const month = date.slice(0, 2);
  const day = date.slice(3, 5);
  const year = date.slice(6, 10);
  let formattedDate;
  if (pattern === "MM/DD/YYYY") {
    formattedDate = `${parseInt(month, 10)}-${parseInt(day, 10)}-${year}`;
  } else if (pattern === "MM/DD") {
    formattedDate = `${parseInt(month, 10)}-${parseInt(day, 10)}`;
  } else {
    formattedDate = "";
  }
  return formattedDate;
};

/**
 * @description Returns all dates between two specified dates
 * @param {Date} startDate
 * @param {Date} endDate
 * @returns {Date[]}
 */
const getDatesInRange = (startDate, endDate) => {
  const date = new Date(startDate.getTime());
  const dates = [];
  while (date <= endDate) {
    dates.push(new Date(date));
    date.setDate(date.getDate() + 1);
  }
  return dates;
};

/**
 * @description Creates the daily dataset for the quotes area timeline chart.
 * @param {Array<ModelsTypes.RefineQuote>} refinedQuotes
 * @param {string} dateRange
 * @returns {Array<ModelsTypes.AreaChartData>} allQuotesPerDay
 */
const createDailyDataSetForTimelineChart = (refinedQuotes, dateRange) => {
  const inProgressQuotes = refinedQuotes.filter(
    quote =>
      quote.quoteStatus === dashboardQuoteStatus.IN_PROGRESS ||
      quote.quoteStatus === dashboardQuoteStatus.REQUEST_ASSISTANCE ||
      quote.quoteStatus === dashboardQuoteStatus.READY_TO_SEND
  );
  const sentQuotes = refinedQuotes.filter(
    quote => quote.quoteStatus === dashboardQuoteStatus.SENT
  );
  const convertedQuotes = refinedQuotes.filter(
    quote => quote.quoteStatus === dashboardQuoteStatus.CONVERTED_TO_APPOINTMENT
  );
  const expiredQuotes = refinedQuotes.filter(
    quote => quote.quoteStatus === dashboardQuoteStatus.EXPIRED
  );

  const today = new Date();
  let priorDate;
  if (dateRange === "30") {
    priorDate = new Date(new Date().setDate(today.getDate() - 30));
  } else {
    priorDate = new Date(new Date().setDate(today.getDate() - 7));
  }

  const allDates = getDatesInRange(priorDate, today).map(date => {
    const dateString = date.toISOString();
    return formatDate(dateString, "MM/DD/YYYY");
  });

  const allQuotesPerDay = allDates.map(date => {
    const inProgressLength = inProgressQuotes.filter(quote => {
      return (
        formatDate(quote.creationDateTime.slice(0, 10), "MM/DD/YYYY") === date
      );
    }).length;
    const sentLength = sentQuotes.filter(quote => {
      return (
        formatDate(quote.creationDateTime.slice(0, 10), "MM/DD/YYYY") === date
      );
    }).length;
    const convertedLength = convertedQuotes.filter(quote => {
      return (
        formatDate(quote.creationDateTime.slice(0, 10), "MM/DD/YYYY") === date
      );
    }).length;
    const expiredLength = expiredQuotes.filter(quote => {
      return (
        formatDate(quote.creationDateTime.slice(0, 10), "MM/DD/YYYY") === date
      );
    }).length;
    return {
      name: date,
      inProgress: inProgressLength,
      sent: sentLength,
      converted: convertedLength,
      expired: expiredLength
    };
  });
  return allQuotesPerDay;
};

/**
 * @description Gets the ISO week number of a date
 * @param {Date} ISODate date to get week of
 * @returns {Array<any>} [year, weekNumber]
 */
const getWeekNumber = ISODate => {
  const date = new Date(ISODate);
  date.setHours(0, 0, 0, 0);
  date.setDate(date.getDate() + 4 - (date.getDay() || 7));
  const yearStart = new Date(date.getFullYear(), 0, 1);
  const weekNo = Math.ceil(
    ((Number(date) - Number(yearStart)) / 86400000 + 1) / 7
  );
  return [date.getFullYear(), weekNo];
};

/**
 * @description Parses ISO 8601 format date string to local date
 * @param {string} string
 * @returns {Date}
 */
const parseISOLocal = string => {
  const splitDate = string.split(/\D/);
  return new Date(
    Number(splitDate[0]),
    Number(splitDate[1]) - 1,
    Number(splitDate[2])
  );
};

/**
 * @description Creates the weekly dataset for the quotes area timeline chart.
 * @param {Array<ModelsTypes.RefineQuote>} refinedQuotes
 * @returns {Array<ModelsTypes.AreaChartData>} allQuotesPerWeek
 */
const createWeeklyDataSetForTimelineChart = refinedQuotes => {
  const inProgressQuotes = refinedQuotes.filter(
    quote =>
      quote.quoteStatus === dashboardQuoteStatus.IN_PROGRESS ||
      quote.quoteStatus === dashboardQuoteStatus.REQUEST_ASSISTANCE ||
      quote.quoteStatus === dashboardQuoteStatus.READY_TO_SEND
  );
  const sentQuotes = refinedQuotes.filter(
    quote => quote.quoteStatus === dashboardQuoteStatus.SENT
  );
  const convertedQuotes = refinedQuotes.filter(
    quote => quote.quoteStatus === dashboardQuoteStatus.CONVERTED_TO_APPOINTMENT
  );
  const expiredQuotes = refinedQuotes.filter(
    quote => quote.quoteStatus === dashboardQuoteStatus.EXPIRED
  );

  const today = new Date();
  const priorDate = new Date(new Date().setDate(today.getDate() - 90));
  const allDates = getDatesInRange(priorDate, today).map(date => {
    const dateString = date.toISOString();
    return formatDate(dateString, "YYYY-MM-DD");
  });

  // Prepend year and week number to dates, e.g. "201651:2016-12-20", then sort
  const datesArray = allDates
    .map(string => {
      const week = getWeekNumber(parseISOLocal(string));
      return week[0] + ("0" + week[1]).slice(-2) + ":" + string;
    })
    .sort();

  // Group in arrays by week in an object
  const groupedWeeksObject = datesArray.reduce((result, value) => {
    const weekAndDateArray = value.split(":");
    if (!result[weekAndDateArray[0]]) {
      result[weekAndDateArray[0]] = [];
    }
    result[weekAndDateArray[0]].push(weekAndDateArray[1]);
    return result;
  }, {});

  // Grab arrays in order of week number. Sort keys to maintain order
  const groupedWeeksArray = Object.keys(groupedWeeksObject)
    .sort()
    .map(key => groupedWeeksObject[key]);

  const allQuotesPerWeek = groupedWeeksArray.map(week => {
    const amountOfQuotesInProgress = inProgressQuotes
      .filter(quote => {
        return week.includes(
          formatDate(quote.creationDateTime.slice(0, 10), "YYYY-MM-DD")
        );
      })
      .map(quote =>
        formatDate(quote.creationDateTime.slice(0, 10), "MM/DD/YYYY")
      ).length;
    const amountOfQuotesSent = sentQuotes
      .filter(quote => {
        return week.includes(
          formatDate(quote.creationDateTime.slice(0, 10), "YYYY-MM-DD")
        );
      })
      .map(quote =>
        formatDate(quote.creationDateTime.slice(0, 10), "MM/DD/YYYY")
      ).length;
    const amountOfQuotesConverted = convertedQuotes
      .filter(quote => {
        return week.includes(
          formatDate(quote.creationDateTime.slice(0, 10), "YYYY-MM-DD")
        );
      })
      .map(quote =>
        formatDate(quote.creationDateTime.slice(0, 10), "MM/DD/YYYY")
      ).length;
    const amountOfQuotesExpired = expiredQuotes
      .filter(quote => {
        return week.includes(
          formatDate(quote.creationDateTime.slice(0, 10), "YYYY-MM-DD")
        );
      })
      .map(quote =>
        formatDate(quote.creationDateTime.slice(0, 10), "MM/DD/YYYY")
      ).length;
    return {
      name: `${formatDate(week[0], "MM/DD")} - ${formatDate(
        week[week.length - 1],
        "MM/DD/YYYY"
      )}`,
      inProgress: amountOfQuotesInProgress,
      sent: amountOfQuotesSent,
      converted: amountOfQuotesConverted,
      expired: amountOfQuotesExpired
    };
  });
  return allQuotesPerWeek;
};

export {
  refineQuotesDataSet,
  createQuotesNumericBreakdownDataSet,
  createDataSetForPotentialValueChart,
  roundAndFormatTotal,
  createDailyDataSetForTimelineChart,
  createWeeklyDataSetForTimelineChart,
  formatDate,
  removeLeadingZeroesFromDate
};
