import React, { useState } from "react";
import PriceInput from "@cx/ui/PriceInput";
import format from "../../../utils/format";
import PropTypes from "prop-types";

const CurrencyPriceInput = ({
  value,
  htmlId,
  name,
  label,
  minValue,
  maxValue,
  error,
  onUpdate
}) => {
  const [price, setPrice] = useState(format.currencyInput(value));
  const handleBlur = () => {
    setPrice(format.currencyInput(value));
  };

  const handleUpdate = e => {
    setPrice(e.target.value);
    onUpdate(e);
  };

  return (
    <PriceInput
      required
      minValue={minValue}
      maxValue={maxValue}
      maxLength={Number.MAX_SAFE_INTEGER}
      error={error}
      htmlId={htmlId}
      name={name}
      label={label}
      value={price}
      onChange={handleUpdate}
      onBlur={handleBlur}
    />
  );
};

CurrencyPriceInput.propTypes = {
  value: PropTypes.number,
  htmlId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  minValue: PropTypes.number,
  maxValue: PropTypes.number,
  error: PropTypes.string,
  onUpdate: PropTypes.func.isRequired
};

export default CurrencyPriceInput;
