const isSublet = data => data.subletId !== undefined;

const isLaborFee = data => data.feeId !== undefined;

const isLaborDiscount = data => data.discountId !== undefined;

export const calculateTotal = data =>
  data.reduce((prev, curr) => {
    if (isSublet(curr)) {
      return prev + curr.poPricing.subletTotalCost;
    } else if (isLaborFee(curr)) {
      return prev + curr.feesAmount;
    } else if (isLaborDiscount(curr)) {
      return prev + curr.discountAmount;
    } else {
      return 0;
    }
  }, 0);
