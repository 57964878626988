import React from "react";
import "./csr-details.scss";
import AddTechnician from "../../components/csr/add-technician.component";
import ServiceCause from "../../components/csr/cause.component";
import ServiceCorrection from "../../components/csr/correction.component";
import get from "lodash/get";

import Row from "@cx/ui/Row";
import Grid from "@cx/ui/Grid";
import Col from "@cx/ui/Col";
import ServiceComplaint from "../../components/csr/complaint.component";
import { useNewQuoteContext } from "../../../state/NewQuoteContext";
import {
  useEditServiceContext,
  Actions
} from "../../state/edit-service.context";
import {
  EDIT_SERVICE_WRAPPER,
  EDIT_MENU_PACKAGE_WRAPPER
} from "../../../constants/pages.constants";
import InternalNotes from "../../components/csr/internal-notes.component";
import CSRWarrantyDetails from "./csrWarrantyDetails.container";

const CSRDetails = ({ dealerCode, stateService }) => {
  const appContext = useNewQuoteContext();
  const { dispatch } = useEditServiceContext();
  const dispatchDealershipNotes = dealershipNotes => {
    dispatch({
      type: Actions.SET_DEALERSHIP_NOTES,
      payload: dealershipNotes
    });
  };

  const currentPage = appContext?.state?.currentPage;
  const isEditServiceOrMenu = [
    //* We dont need to have correction and cause while adding new service
    EDIT_SERVICE_WRAPPER,
    EDIT_MENU_PACKAGE_WRAPPER
  ].includes(currentPage);

  return (
    <Grid>
      {/* <Row className="m-t-md">
        <LaborPartsFields />
      </Row> */}

      <Row className="m-t-md">
        <Col md={12}>
          <ServiceComplaint />
        </Col>
        {isEditServiceOrMenu ? (
          <>
            <Col md={12}>
              <ServiceCause />
            </Col>
            <Col md={12}>
              <ServiceCorrection />
            </Col>
            <Col md={12}>
              <InternalNotes
                dealershipNotes={get(stateService, "dealershipNotes", null)}
                dispatchDealershipNotes={dispatchDealershipNotes}
              />
            </Col>
          </>
        ) : null}
      </Row>

      {isEditServiceOrMenu && stateService?.payTypeCode !== "W" ? (
        <Row className="m-t-md">
          <AddTechnician
            dealerCode={dealerCode}
            quoteId={appContext?.state?.quoteId}
          />
        </Row>
      ) : null}
      {stateService?.payTypeCode === "W" ? (
        <Row className="m-t-md m-r-0 m-l-0">
          <CSRWarrantyDetails
            dealerCode={dealerCode}
            stateService={stateService}
            quoteId={appContext?.state?.quoteId}
          />
        </Row>
      ) : null}
    </Grid>
  );
};

export default CSRDetails;
