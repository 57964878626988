import QuoteStatusConstant from "../../../../constants/quote-status.constants";

export const StatusMappings = [
  { status: QuoteStatusConstant.SENT, color: "purple", label: "Sent" },
  { status: QuoteStatusConstant.EXPIRED, color: "gray", label: "Expired" },
  {
    status: QuoteStatusConstant.PROGRESS,
    color: "blue",
    label: "In progress"
  },
  {
    status: QuoteStatusConstant.REQUEST_ASSISTANCE,
    color: "red",
    label: "Request"
  },
  {
    status: QuoteStatusConstant.CONVERTED_TO_APPOINTMENT,
    color: "green",
    label: "Scheduled"
  },
  {
    status: QuoteStatusConstant.READY_TO_SEND,
    color: "yellow",
    label: "Ready to send"
  }
];
