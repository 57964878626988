import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import IconArrowUpward from "@cx/ui/Icons/IconArrowUpward";
import LoadingIndicator from "@cx/ui/LoadingIndicator";
import isEmpty from "lodash/isEmpty";
import has from "lodash/has";
import ConfirmPopup from "../../../components/ui/modals/ConfirmPopup";
import {
  useServiceSearchContext,
  Actions
} from "../../../state/service-search.context";
import dealerTireService from "../dealer-tire-service";
import "./service-table.scss";
import { saveActionTypes } from "../../../constants/search.constants";
import selectServiceFormat from "../../../utils/select-service-format";
import serviceDataMapper from "../../../utils/service-data-mapper";
import { quickFilterPages } from "../../../constants/quick-filters.constants";

export const EditDealerTirePage = props => {
  const {
    dealerTireRecord,
    launchDealerTire,
    EditServiceModule,
    updateParentState
  } = props;
  const {
    dispatch,
    state,
    ctxRequestPartsInventoryCommon,
    ctxRequestPartsPricingAndInventory
  } = useServiceSearchContext();
  const {
    globalOperationDetails,
    appConfig,
    localeStrings,
    debugQuote,
    vehicle,
    userPermissions,
    payTypes
  } = state;
  // local state holds latest catalog service with dealertire parts
  const [catalogTireService, setCatalogTireService] = useState(null);
  const [showDiscardEditPopup, setShowDiscardEditPopup] = useState(false);
  const [serviceHasChanged, setServiceHasChanged] = useState(false);
  useEffect(() => {
    // We need this check because globalOperationDetails will not be available in context until ctxRequestOperationDetails is triggered
    // when dealer tires are selected from DealerTire component. we need to update globalOperationDetails with parts [dealerTire record] and set operation in local state.
    if (!isEmpty(globalOperationDetails)) {
      let payTypeCode = dealerTireService.getDefaultPayTypeForDealerTire(
        globalOperationDetails.serviceKind,
        globalOperationDetails.defaultPayTypeCode
      );
      payTypeCode = !isEmpty(vehicle?.stockNumber) ? "I" : payTypeCode;
      const { parts, totalPrice } = dealerTireRecord;
      const operation =
        has(globalOperationDetails, "laborApps") &&
        !isEmpty(globalOperationDetails.laborApps[0])
          ? globalOperationDetails.laborApps[0]
          : {};
      // @note: update globalOperationDetails with parts from dealer tire record
      if (!isEmpty(operation)) {
        operation.parts = !parts ? [] : parts;
        operation.partsPrice = totalPrice;
        globalOperationDetails.laborApps[0] = operation;
      }
      asyncGetDMSPartsForService(
        payTypeCode,
        props.catalogService,
        globalOperationDetails
      );
      const selectedService = selectServiceFormat.formatService(
        props.catalogService,
        globalOperationDetails
      );
      if (!isEmpty(vehicle?.stockNumber)) {
        selectedService.defaultPayTypeCode = "I";
      }
      // @note: dealer tire record updated to catalog tire service here
      const catalogSelectedService = dealerTireService.tireServiceMapper(
        dealerTireRecord,
        selectedService
      );
      const { editModuleAccess } = appConfig;
      if (editModuleAccess) {
        const { rawRecord } = props.catalogService;
        if (rawRecord.operationSource) {
          setCatalogTireService(catalogSelectedService);
          dispatch({
            type: Actions.SET_SEARCH_HEADER,
            payload: false
          });
        }
      } else {
        // !Revisit this flow - If parent application is not QUOTING, just dispatch selectedService. why for deeplink flow?
        dispatch({
          type: Actions.SET_SELECTED_GLOBAL_OPS_SERVICE,
          payload: catalogSelectedService
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalOperationDetails, appConfig]);

  // @note: callback open track api to fetch dms prices, qtyOnhand for catalog dealer publish service with dealer tire as parts
  const asyncGetDMSPartsForService = async (
    payType,
    service,
    globalOperationDetails
  ) => {
    ctxRequestPartsPricingAndInventory(
      payType,
      service,
      globalOperationDetails
    );
  };

  // @handler - clear previous service and land on dealerTire page only for Save or Cancel actions
  const backToService = actionName => {
    updateParentState(false);
    dispatch({
      type: Actions.SET_SEARCH_HEADER,
      payload: true
    });
    setCatalogTireService(null);
    if (!actionName) {
      launchDealerTire(false);
    }
  };
  // @note-paytype: trigger parent handler when paytype modified in edit service page
  const asyncUpdatePartsByPaytype = async (paytype, editedService) => {
    catalogTireService.payTypeCode = paytype;
    ctxRequestPartsInventoryCommon(catalogTireService, editedService);
  };
  const saveServiceAndGoBack = modifiedEditService => {
    const newService = serviceDataMapper.updateDealerPublished(
      catalogTireService,
      modifiedEditService
    );
    dispatch({
      type: Actions.SET_SAVE_ACTION_TYPE,
      payload: saveActionTypes.SAVEANOTHER
    });
    dispatch({
      type: Actions.SET_SELECTED_GLOBAL_OPS_SERVICE,
      payload: newService
    });
    backToService(saveActionTypes.SAVEANOTHER);
  };
  const handleServiceUpdate = modifiedEditService => {
    const newService = serviceDataMapper.updateDealerPublished(
      catalogTireService,
      modifiedEditService
    );
    dispatch({
      type: Actions.SET_SAVE_ACTION_TYPE,
      payload: saveActionTypes.SAVE
    });
    dispatch({
      type: Actions.SET_SELECTED_GLOBAL_OPS_SERVICE,
      payload: newService
    });
  };
  const handleServiceChange = serviceChanged => {
    setServiceHasChanged(serviceChanged);
  };
  const handleCancel = () => {
    if (serviceHasChanged) {
      setShowDiscardEditPopup(true);
    } else {
      backToService();
    }
  };
  const loadmask = (
    <div style={{ margin: 25 }}>
      <LoadingIndicator htmlId="editDealerTireMask" />
    </div>
  );
  const clonedEditServiceModule = !isEmpty(catalogTireService)
    ? React.cloneElement(EditServiceModule, {
        debugMode: debugQuote,
        userPermissions,
        vehicle,
        service:
          serviceDataMapper.editModulePropsForDealerTire(catalogTireService),
        localeStrings,
        rawOperationDetails: catalogTireService,
        payTypes,
        partsPricingAndInventory: state.partsPricingAndInventory,
        onCancelHandler: handleCancel,
        onSaveHandler: handleServiceUpdate,
        onSaveAnotherHandler: saveServiceAndGoBack,
        onChangePaytype: asyncUpdatePartsByPaytype,
        onServiceChange: handleServiceChange,
        editSource: quickFilterPages.TIRES
      })
    : loadmask;
  const discardEditPopup = (
    <ConfirmPopup
      title={localeStrings["sq.search.common.alert_lbl"]}
      message={localeStrings["sq.search.common.leaving_edit_page"]}
      show={showDiscardEditPopup}
      okText={localeStrings["sq.search.common.proceed_button"]}
      cancelText={localeStrings["sq.search.common.cancel_button"]}
      okAction={backToService}
      cancelAction={() => setShowDiscardEditPopup(false)}
      hideCancel={false}
      hideOk={false}
      buttonStyle="danger"
    />
  );

  return (
    <>
      <div id="editDealerTireService" className="search-flex-grid-container">
        <span
          className="back-nav-label search-back-sticky"
          onClick={handleCancel}
        >
          <IconArrowUpward
            htmlId="backArrowIcon"
            isActive={false}
            className="back-arrow"
          />
          Back
        </span>
        {clonedEditServiceModule}
      </div>
      {discardEditPopup}
    </>
  );
};

EditDealerTirePage.propTypes = {
  dealerTireRecord: PropTypes.object.isRequired,
  catalogService: PropTypes.object,
  // module React element
  EditServiceModule: PropTypes.element,
  launchDealerTire: PropTypes.func,
  updateParentState: PropTypes.func.isRequired
};

EditDealerTirePage.defaultProps = {
  dealerTireRecord: null,
  launchDealerTire: () => {},
  EditServiceModule: null,
  catalogService: null,
  updateParentState: () => {}
};
