import React from "react";
import "./api-failure-dashboard.scss";

const ApiFailureDashboard = props => {
  let content = null;
  if (props.apiStatus === "APIERROR") {
    content = (
      <div className="api-failure-wrapper">
        <h4>
          {
            props.localeStrings[
              "sq.api.failure.grid_message_connection_failure"
            ]
          }
        </h4>
        <span>
          {props.localeStrings["sq.api.failure.dashboard_message_statistics"]}
        </span>
      </div>
    );
  }

  return content;
};

export default ApiFailureDashboard;
