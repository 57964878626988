import React, { useEffect, useState } from "react";
import ButtonToolbar from "react-bootstrap/lib/ButtonToolbar";
import Row from "@cx/ui/Row";
import Col from "@cx/ui/Col";
import PriceInput from "@cx/ui/PriceInput";
import ModalDialog from "@cx/ui/ModalDialog";
import themePrefixer from "@cx/ui/themePrefixer";
import NumericInput from "@cx/ui/NumericInput";
import Button from "@cx/ui/Button";
import PropTypes from "prop-types";

const CorePartReturnModal = props => {
  // Component logic and state here
  const [showCorePartReturnModal, setShowCorePartReturnModal] = useState(false);
  const [coreReturnPart, setCoreReturnPart] = useState({
    partsPrice: 0.0,
    quantity: ""
  });

  const [invalidInput, setInvalidInput] = useState(false);

  useEffect(() => {
    setShowCorePartReturnModal(props.show);
    setCoreReturnPart(props.coreReturnPart);
  }, [props.show, props.coreReturnPart]);

  const checkValidInput = returnPart => {
    const { partPrice, quantity } = returnPart;
    const invalid =
      isNaN(partPrice) ||
      partPrice <= 0.0 ||
      partPrice > coreReturnPart.origPartPrice ||
      isNaN(quantity) ||
      quantity <= 0 ||
      quantity > coreReturnPart.origQuantity;
    setInvalidInput(invalid);
  };

  const coreReturnModal = (
    <ModalDialog
      htmlId="coreReturnModal"
      className="core-return-modal"
      show={showCorePartReturnModal}
      backdrop="static"
      header={<h4 className={`${themePrefixer()}modal-title`}>Core Return</h4>}
      footer={
        <div className="lookup-modal-footer">
          <ButtonToolbar>
            <Button
              htmlId="cancelCoreReturnBtn"
              type="button"
              buttonStyle="secondary"
              onClick={() => props.onCancel()}
            >
              Cancel
            </Button>
            <Button
              htmlId="saveCoreReturnBtn"
              type="button"
              buttonStyle="primary"
              disabled={invalidInput}
              onClick={() => props.onSave(coreReturnPart)}
            >
              Save
            </Button>
          </ButtonToolbar>
        </div>
      }
      onHide={() => props.onCancel()}
    >
      <div>
        <p>The Core return amount should match the original core fee</p>
        <Row>
          <Col xs={5} md={5}>
            <PriceInput
              autoFocus
              htmlId="partsPrice"
              name="partsPrice"
              value={coreReturnPart?.partPrice || null}
              displayLabel={true}
              label="Return amount"
              onChange={event => {
                const { value } = event.target;
                const partPrice = parseFloat(value).toFixed(2);
                const returnPart = {
                  ...coreReturnPart,
                  partPrice
                };
                setCoreReturnPart(returnPart);
                checkValidInput(returnPart);
              }}
              maxLength={7}
              minValue={0}
              maxValue={coreReturnPart?.origPartPrice || null}
            />
          </Col>
          <Col xs={4} md={4}>
            <NumericInput
              htmllId="quantity"
              displayLabel={true}
              label="Quantity"
              value={coreReturnPart?.quantity || null}
              maxLength={3}
              minValue={1}
              maxValue={coreReturnPart?.origQuantity || null}
              // autoComplete="off"
              onChange={event => {
                console.log(event);
                const { value } = event.target;
                const quantity = parseInt(value, 10);
                const returnPart = {
                  ...coreReturnPart,
                  quantity
                };
                setCoreReturnPart(returnPart);
                checkValidInput(returnPart);
              }}
            />
          </Col>
        </Row>
      </div>
    </ModalDialog>
  );

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{coreReturnModal}</>;
};

export default CorePartReturnModal;

CorePartReturnModal.propTypes = {
  show: PropTypes.bool,
  coreReturnPart: PropTypes.object,
  onCancel: PropTypes.func,
  onSave: PropTypes.func
};
