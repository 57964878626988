import React from "react";
import moment from "moment";

const StartEndTime = ({ data, localWorkDuration }) => {
  const { workStarted: startTime, workEnded: endTime } = data;

  function formatTime(time) {
    return time ? moment(time).format("hh:mm A") : "00:00";
  }

  function calculateStartTime() {
    return formatTime(startTime);
  }
  function updateEndTime(currentTime, duration) {
    if (!currentTime) return null;
    const startTimeMoment = moment(currentTime);
    const increasedTime = startTimeMoment.add(duration, "minutes");
    return increasedTime;
  }
  function calculateWorkingEndTime() {
    const currentTime = startTime;
    if (localWorkDuration === 0) return currentTime;
    const updatedTime = updateEndTime(currentTime, localWorkDuration);
    return updatedTime;
  }
  function calculateEndTime() {
    switch (data?.status) {
      case "WORKING":
        return formatTime(calculateWorkingEndTime());
      default:
        return formatTime(endTime);
    }
  }
  return (
    <span style={{ marginRight: 6 }}>
      {`${calculateStartTime()} - ${calculateEndTime()}`}
    </span>
  );
};

export default StartEndTime;
