// @ts-check
import React from "react";
import clsx from "clsx";
import Badge from "@cx/ui/Badge";
// eslint-disable-next-line unused-imports/no-unused-imports
import * as CommonTypes from "../../../../../../@types/common.types";
// eslint-disable-next-line unused-imports/no-unused-imports
import * as ModelsTypes from "../../../../../../@types/models.types";

/**
 * Quotes Breakdown Board Component
 * @param {{data: ModelsTypes.ChartData, labels: Array<string>}} props Prop Types
 * @returns {CommonTypes.Component}
 */
const QuotesBreakdownBoard = props => {
  const { data, labels } = props;
  const badgesCls = {
    inProgress: clsx(
      "numeric-breakdown-board__badge--inProgress",
      data.chartData[0].value === 0 && "no-quotes"
    ),
    sent: clsx(
      "numeric-breakdown-board__badge--sent",
      data.chartData[1].value === 0 && "no-quotes"
    ),
    converted: clsx(
      "numeric-breakdown-board__badge--converted",
      data.chartData[2].value === 0 && "no-quotes"
    ),
    expired: clsx(
      "numeric-breakdown-board__badge--expired",
      data.chartData[3].value === 0 && "no-quotes"
    )
  };
  return (
    <div className="numeric-breakdown-board">
      <div className="numeric-breakdown-board__row">
        <Badge htmlId="badgeInProgress" className={badgesCls.inProgress}>
          {data.chartData[0].value}
        </Badge>
        <span className="numeric-breakdown-board__label">{labels[0]}</span>
      </div>
      <div className="numeric-breakdown-board__row">
        <Badge htmlId="badgeSent" className={badgesCls.sent}>
          {data.chartData[1].value}
        </Badge>
        <span className="numeric-breakdown-board__label">{labels[1]}</span>
      </div>
      <div className="numeric-breakdown-board__row">
        <Badge htmlId="badgeConverted" className={badgesCls.converted}>
          {data.chartData[2].value}
        </Badge>
        <span className="numeric-breakdown-board__label">{labels[2]}</span>
      </div>
      <div className="numeric-breakdown-board__row">
        <Badge htmlId="badgeExpired" className={badgesCls.expired}>
          {data.chartData[3].value}
        </Badge>
        <span className="numeric-breakdown-board__label">{labels[3]}</span>
      </div>
    </div>
  );
};

export default QuotesBreakdownBoard;
