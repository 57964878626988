// This context behaves like app level global state
import React from "react";

export const initialAppState = {
  locale: "en_US",
  localeStrings: {},
  webKey: "",
  appSource: null,
  appEnv: null,
  dealer: {
    webKey: "",
    orgKey: "",
    dealerCode: "",
    dealerName: "",
    dmsType: ""
  },
  user: { userId: "", userName: "" },
  userPermissions: {
    // older permissions implemented for Quoting
    canCreateQuote: false,
    canUpdateQuoteService: false,
    canUpdateQuoteServicePart: false,
    canEditQuote: false,
    canAccessServiceQuoting: false,
    canCreateReservationForConsumer: false,
    canAccessDealerQuoteSettings: false,
    // roles masquerading as permissions
    canPerformServiceManagerActions: false,
    canPerformServiceAdvisorActions: false,
    canPerformTechnicianActions: false,
    canPerformPartsActions: false,
    canPerformWarrantyAdminActions: false,
    // granular permissions for Service RO
    canSearchArchivedROs: false,
    canUpdateROAttributes: false,
    canUpdateROMileageOut: false,
    canViewDocuments: false,
    canApproveEstimate: false,
    canStartInvoicing: false,
    canFinalizeRO: false,
    canViewAllROServices: false,
    canAddService: false,
    canEditService: false,
    canRemoveService: false,
    canModifyComebackService: false,
    canPrintPickList: false,
    canAddToPurchaseOrder: false,
    canAddParts: false,
    canUpdatePartsQuantity: false,
    canUpdatePartsDescription: false,
    canUpdatePartsPricing: false,
    canApproveParts: false,
    canRemoveParts: false,
    canUpdateServiceAttributes: false,
    canUpdateCustomerConcern: false,
    canAddTechRowsForSelf: false,
    canAddTechRowsForAnyone: false,
    canUpdateExistingTechRowTechnician: false,
    canUpdateTechRowDate: false,
    canUpdateTechRowCorrectionCode: false,
    canUpdateTechRowFlagTime: false,
    canRunOwnTechnicianClock: false,
    canRunAnyTechnicianClock: false,
    canUpdatePrimaryTechnician: false,
    canRemoveTechnicianRow: false,
    canOverrideTechTime: false,
    canViewPrices: false,
    canViewFeesDiscountsSublets: false,
    canUpdateFeesDiscountsSublets: false,
    canMarkServiceComplete: false
  },
  dealerSettings: {
    disclaimer: "",
    displayLaborHours: false,
    displayPartNumbers: false,
    partsEmail: "",
    quoteAssistance: false,
    quoteExpirationDays: "",
    serviceEmail: ""
  },
  isQuotingEnabled: false,
  isDealerTireEnabled: false,
  isCreatePoEnabled: false,
  dealerProperties: {
    CSR_SUBLET_FORM_ENABLED: "N",
    ENABLE_CSR_SEARCH_HISTORIC_RO: "N"
  },
  dealerCompanies: [],
  // @note- these params passed from external app to launch quote
  quote: {
    accessType: null,
    customerId: null,
    vehicleId: null,
    quoteId: null
  },
  // @csr-logic - special case to launch csr pages when these query params passed in browser
  appType: null,
  timeZone: null,
  device: {},
  search: {}
};

export const getInitStateWithUser = user => ({
  ...initialAppState,
  user
});

export const AppContext = React.createContext(initialAppState);
export const AppProvider = AppContext.Provider;
export const AppConsumer = AppContext.Consumer;
