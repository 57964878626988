/* eslint-disable no-async-promise-executor */
import axiosService, { showApiFailureMessage } from "../../../api/xmmAxios";
import has from "lodash/has";
import isEmpty from "lodash/isEmpty";

const getTopServices = async vehicle => {
  return new Promise(async (resolve, reject) => {
    const { make, metaVehicleId, variant } = vehicle;
    const vin =
      has(vehicle, "vin") && !isEmpty(vehicle.vin)
        ? vehicle.vin.toString()
        : "UNKNOWN";
    const axios = axiosService.getInstance();

    try {
      const response = await axios.get(
        `quote/services/topServices/{dealerCode}/make/${make}`,
        {
          params: {
            vin,
            metaVehicleId,
            variant
          }
        }
      );
      resolve(response.data);
    } catch (error) {
      showApiFailureMessage("Unable to retrieve top services.", error);
      reject(error);
    }
  });
};

export default {
  getTopServices
};
