/**
 * Message utility
 *
 * @param {object} errors
 * @param {object} service
 * @returns string
 */
const getWarningMessages = (errors, service) => {
  return Object.keys(errors)
    .filter(error => errors[error])
    .map(error => {
      if (error === "payTypes") {
        return `The service ${
          service.name
        } can't be added without paytype for dealer ${service.dealer} ${
          service.confirmationId ? `, quote ${service.confirmationId}` : ""
        }`;
      }

      return null;
    })
    .join(", ")
    .replace(/,$/, ".");
};

const DISPLAY_ORDER_GAP = 1000;

/**
 * Returns the modified services with display order set from null to numeric value.  If all there services's display order
 * is null (not set to numeric value) and it is invoked during a drag and drop action, all the services would be set to
 * numeric display order in descending order like 0, 1000, 2000, 3000, etc.  If all the services have null display order
 * but it is invoked not during drag and drop action (e.g. adding a service), then no change to dispaly order of any of the
 * services.
 *
 * When drag&drop occurs, the display order of the dropped service will be calulatetd as follow:
 *
 *   1. When a service is dropped before the first service, then set the display order to firstService.displayOrder - 1000
 *   2. When a service is dropped after the last service, then set the display order to lastService.displayOrder + 1000
 *   3. When a service is dropped between two services, the set the display order to (afterService.displayOrder + beforeService.displayOrder) / 2
 *
 * @param {*} serviceList - list of services displayed
 * @param {*} onDropItem - true if this funcion is invoked during a drag and drop action
 * @returns modified service(s) with displayOrder set to a numeric value
 */
const getUpdatedServicesWithDisplayOrder = (serviceList, onDropItem) => {
  let updatedServices = [];
  const serviceCount = serviceList.length;
  if (serviceCount === 0) {
    return updatedServices;
  }
  const noDisplayOrderMap = serviceList.reduce((list, service, index) => {
    if (typeof service.displayOrder !== "number") {
      list[index] = service;
    }
    return list;
  }, {});
  const noDisplayOrderIndexes = Object.keys(noDisplayOrderMap);
  const hasNoDisplayOrders = noDisplayOrderIndexes.length === serviceCount;
  // if there is no display order or number of null display order > 1 then
  if (hasNoDisplayOrders || noDisplayOrderIndexes.length > 1) {
    // return [] if there is no drag and drop ever done
    if (hasNoDisplayOrders && !onDropItem) {
      return updatedServices;
    }
    // reorder entire list of services
    updatedServices = serviceList.map((service, index) => {
      service.displayOrder = index * DISPLAY_ORDER_GAP;
      return service;
    });
    return updatedServices;
  } else {
    if (noDisplayOrderIndexes.length === 0) {
      return updatedServices;
    }
    const index = Number(noDisplayOrderIndexes[0]);
    const service = serviceList[index];
    let displayOrder = 0;
    if (index === 0) {
      // if (serviceCount !== 1) {
      displayOrder = serviceList[1].displayOrder - DISPLAY_ORDER_GAP;
      // }
    } else if (index === serviceCount - 1) {
      // if (serviceCount !== 1) {
      displayOrder = serviceList[index - 1].displayOrder + DISPLAY_ORDER_GAP;
      // }
    } else {
      displayOrder =
        (serviceList[index - 1].displayOrder +
          serviceList[index + 1].displayOrder) /
        2;
    }
    service.displayOrder = displayOrder;
    updatedServices.push(service);
  }
  return updatedServices;
};

export { getUpdatedServicesWithDisplayOrder, getWarningMessages };
