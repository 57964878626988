const IncludedInspectionTags = [
  "inspect",
  "disclaimer",
  "lubricate",
  "reset",
  "tighten",
  "test"
];

const isIncludedInspection = service => {
  const { price, serviceCategoryName } = service;
  if (price && price !== 0) {
    return false;
  }
  if (!serviceCategoryName) {
    return false;
  }
  const serviceCategoryLowercase = serviceCategoryName.toLowerCase();
  for (let index = 0; index < IncludedInspectionTags.length; index++) {
    if (
      serviceCategoryLowercase.indexOf(IncludedInspectionTags[index]) !== -1
    ) {
      return true;
    }
  }
  return false;
};

export default {
  isIncludedInspection,
  IncludedInspectionTags
};
