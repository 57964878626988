const QUICK_FILTERS = [
  {
    id: "TOP_SERVICES",
    text: "Top services",
    serviceCount: 0,
    enableCount: false
  },
  {
    id: "MENU",
    text: "Menu",
    serviceCount: 0,
    enableCount: false
  },
  {
    id: "RECALLS",
    text: "Recalls",
    serviceCount: 0,
    enableCount: true
  },
  {
    id: "DIAGNOSIS",
    text: "Diagnosis",
    serviceCount: 0,
    enableCount: true
  },
  {
    id: "DECLINED",
    text: "Declined",
    serviceCount: 0,
    enableCount: true
  },
  {
    id: "TIRES",
    text: "Tires",
    serviceCount: 0,
    enableCount: false
  }
];

const quickFilterPages = {
  TOP_SERVICES: "TOP_SERVICES",
  MENU: "MENU",
  RECALLS: "RECALLS",
  DIAGNOSIS: "DIAGNOSIS",
  DECLINED: "DECLINED",
  TIRES: "TIRES"
};

// @placeholder constant
const filters = {
  BRAKES: "Brakes",
  CLUTCH_AND_TRANSMISSION: "Clutch & Transmission",
  ELECTRICAL: "Electrical",
  ENGINE: "Engine",
  EXTERIOR: "Exterior",
  HEATING_AND_AC: "Heating & AC",
  INTERIOR: "Interior"
};

const drivingConditions = {
  NORMAL: "Normal",
  SEVERE: "Severe"
};
export { QUICK_FILTERS, quickFilterPages, filters, drivingConditions };
