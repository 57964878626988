import React, { useEffect } from "react";
import TextArea from "@cx/ui/TextArea";
import TextInput from "@cx/ui/TextInput";
import SelectInput from "@cx/ui/SelectInput";
import PropTypes from "prop-types";
import { splitCostSubletProps } from "../sublet.types";
import { SPLIT_SUBLET_DEFAULT } from "../../../constants/adjustment.constant";

const SubletDetails = ({
  sublet = SPLIT_SUBLET_DEFAULT,
  onUpdate = () => {},
  onBlur
}) => {
  useEffect(() => {
    const cursorPosition = sublet.vendorNotes?.length;
    if (cursorPosition > 0) {
      const vendorNotesTextArea = document.getElementById("subletVendorNotes");
      vendorNotesTextArea.setSelectionRange(cursorPosition, cursorPosition);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <TextArea
        required
        value={sublet.vendorNotes}
        maxLength={200}
        htmlId="subletVendorNotes"
        name="subletVendorNotes"
        label="Description"
        onChange={onUpdate}
        onBlur={onBlur}
        autoFocus
      />
      <TextInput
        value={sublet.poNumber}
        maxLength={30}
        htmlId="subletPoNumber"
        name="subletPoNumber"
        label="PO number"
        onChange={onUpdate}
        onBlur={onBlur}
      />
      <SelectInput
        required
        value={sublet.vendor}
        htmlId="subletVendor"
        label="Vendor"
        name="subletVendor"
        onChange={onUpdate}
        displayDeselectOption={false}
        options={[
          { value: "Sublet Vendor 1", label: "Sublet Vendor 1" },
          { value: "Sublet Vendor 2", label: "Sublet Vendor 2" },
          { value: "Sublet Vendor 3", label: "Sublet Vendor 3" },
          { value: "Sublet Vendor 4", label: "Sublet Vendor 4" }
        ]}
      />
      <TextInput
        value={sublet.invoiceNumber}
        maxLength={30}
        htmlId="subletInvoiceNumber"
        name="subletInvoiceNumber"
        label="Invoice number"
        onChange={onUpdate}
        onBlur={onBlur}
      />
    </>
  );
};

SubletDetails.propTypes = {
  sublet: splitCostSubletProps,
  onUpdate: PropTypes.func.isRequired,
  onBlur: PropTypes.func
};

export default SubletDetails;
