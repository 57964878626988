import React, { useCallback, useEffect, useState } from "react";
import { technicianStatus } from "../../../constants/app.constants";
import IconWarning from "@cx/ui/Icons/IconWarning";
import IconKeyboardArrowRight from "@cx/ui/Icons/IconKeyboardArrowRight";

export const CustomTechViewGroupRenderer = props => {
  const { value, node } = props;

  const [expanded, setExpanded] = useState(node.expanded);

  useEffect(() => {
    const expandListener = event => setExpanded(event.node.expanded);

    node.addEventListener("expandedChanged", expandListener);

    return () => {
      node.removeEventListener("expandedChanged", expandListener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClick = useCallback(() => node.setExpanded(!node.expanded), [node]);

  return (
    <div
      className="custom-tech-view-group-renderer-container"
      style={{
        paddingLeft: `${node.level * 15}px`
      }}
    >
      {node.group ? (
        <div
          className="custom-tech-view-group-expand-icon"
          style={{
            transform: expanded ? "rotate(90deg)" : "rotate(0deg)"
          }}
          onClick={onClick}
        >
          <IconKeyboardArrowRight htmlId="IconKeyboardArrowRightExample" />
        </div>
      ) : null}
      <div className="custom-tech-view-group-text-container">
        {value === technicianStatus.WORKING && node.allChildrenCount != 0 ? (
          <IconWarning
            className="custom-tech-view-group-warning-icon"
            htmlId="IconWarningExample"
          />
        ) : (
          ""
        )}
        <span>
          {value} ({node.allChildrenCount})
        </span>
      </div>
    </div>
  );
};
