/* eslint-disable no-console */
//  Write regex or field validators here
import { EMAIL_REGEX, PHONE_NUMBER } from "./regex.util";
const KEY_C = 67;
const KEY_V = 86;
const KEY_X = 88;

// Only ASCII character in that range allowed
export const isNumberKey = event => {
  const ASCIICode = !event.keyCode ? 0 : event.keyCode;
  if (ASCIICode < 48 || ASCIICode > 57) {
    event.preventDefault();
    return false;
  }
  return true;
};

export function validateSearchKey(event, regex) {
  //  const regex = CUSTOMER_SEARCH_REGEX;
  // TODO: we are using event.key against regex
  const keyChar = !event.key ? "" : event.key;
  //   console.log(event.key, keyChar, regex.test(keyChar));
  const isValid = regex.test(keyChar || "");
  if (!isValid) {
    event.preventDefault();
    return false;
  }
  return isValid;
}

export function validateKeypress(event, regex) {
  // TODO: we are using event.key against regex
  const keyChar = !event.key ? "" : event.key;
  // const keyCode = !event.keyCode ? 0 : event.keyCode;
  // console.log(event.key, keyCode, keyChar, regex.test(keyChar));
  const isValid = regex.test(keyChar || "");
  if (!isValid) {
    event.preventDefault();
    return false;
  }
  return isValid;
}

//  TODO: String.fromCharCode not working with UTF-16 codes of special chars {.,-}
export function validateCharCode(event) {
  const keyCode = !event.keyCode ? 0 : event.keyCode;
  // Regex to allow only Alphabets Numbers Dash Underscore and Space
  const pattern = /^[a-z\d\-_\s]+$/i;
  // Validating the textBox value against our regex pattern.
  const keyChar = String.fromCharCode(keyCode);
  const isValid = pattern.test(keyChar);
  // console.log(event.key, event.keyCode, isValid);
  if (!isValid) {
    event.preventDefault();
    return false;
    // "Invalid Attempt, only alphanumeric, dash , underscore and space are allowed.";
  }
  return isValid;
}

export const emailValidate = value =>
  !EMAIL_REGEX.test(value || "") && "Invalid email address";

// This util returns true - for valid email
export const validateEmail = value => EMAIL_REGEX.test(value || "");

export const validatePhoneNumber = value => PHONE_NUMBER.test(value || "");

export const isAllDigits = str => {
  return /^\d+$/.test(str);
};

export const isPriceInput = str => {
  return /^\d+(?:\.\d{0,2})$/.test(str) || isAllDigits(str);
};

export const isFloatingNumber = str => {
  return /^\d*(?:\.\d{0,2})$/.test(str) || isAllDigits(str);
};

export const isFloatingNumberWithTenths = str => {
  return /^\d*(?:\.\d{0,1})$/.test(str) || isAllDigits(str);
};
export const isFloatingNumberWithHundredths = str => {
  return /^\d*(?:\.\d{0,2})$/.test(str) || isAllDigits(str);
};

export const isCopyAndPaste = event => {
  const evt = event || window.event; // IE support
  const c = evt.keyCode;
  const ctrlDown = evt.ctrlKey || evt.metaKey; // Mac support

  // Check for Alt+Gr (http://en.wikipedia.org/wiki/AltGr_key)
  // if (ctrlDown && evt.altKey) return true
  // Check for ctrl+c, v and x
  if (ctrlDown && (c === KEY_C || c === KEY_V || c === KEY_X)) {
    return true;
  }
  return false;
};
