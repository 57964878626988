/* eslint-disable react/prop-types */
import React from "react";
const MenuInspectServiceDetails = props => {
  const { inspectServices, expandDetails } = props;

  const clsRow = expandDetails ? "pkg-quote-details" : "hide";

  // TODO: laborTime as durationMins, laborPrice as scheduledLaborPrice read from menuService

  const inspectServicesHtml = inspectServices.map(service => {
    return (
      <div
        className="pkg-quote-labor no-border margin-left-18"
        key={service.id}
      >
        <div className="labor-title">
          <span>{service.name}</span>
        </div>
      </div>
    );
  });

  return (
    <div className={[clsRow, "margin-top-8 margin-right-13"].join(" ")}>
      {inspectServicesHtml}
    </div>
  );
};

export default MenuInspectServiceDetails;
