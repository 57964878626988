const localeFrCa = {
  "sq.newquote.edit_menu_service.no_service_selected_msg":
    "At least one service must be included before the package can be Saved-fr",
  "sq.search.menu.edit_service_detail_lbl": "Edit service details-fr",
  "sq.search.menu.edit_back_review_package_details_lbl":
    "Back to review package details-fr",
  "sq.search.common.edit_button": "Edit-fr",
  "sq.search.common.cancel_button": "Cancel-fr",
  "sq.search.common.save_button": "Save-fr",
  "sq.search.common.alert_lbl": "Alert!-fr",
  "sq.search.common.leaving_menu_package_edit":
    "Are you sure you want to leave without saving? All unsaved changes will be lost.-fr",
  "sq.search.common.leaving_edit_page":
    "Are you sure you want to leave without saving? All unsaved changes will be lost.",
  "sq.search.common.proceed_button": "Proceed-fr",
  "sq.search.common.edit_service.summary.labor_lbl": "Labor-fr",
  "sq.search.common.edit_service.summary.qty_lbl": "Qty-fr",
  "sq.search.common.edit_service.summary.parts_lbl": "Parts-fr",
  "sq.search.edit_service.summary.show_details_lbl": "Show details-fr",
  "sq.search.edit_service.summary.included_inspection":
    "Included inspection-fr",
  "sq.search.menu.package.customize": "This package can not be customized-fr",
  "sq.search.menu.package.selectable":
    "This package includes optional services-fr",
  "sq.search.menu.package.modifiable":
    "This package contains services that can be modified-fr",
  "sq.search.menu.package.opcode":
    "By changing the op code the package will show as a single service line on the RO-fr",
  "sq.search.errors.read_service_quoting_enabled_property":
    "There was an error while fetching Service Quoting Enabled property.-fr",
  "sq.search.customer.add_to_quote_lbl": "Add to quote-fr",
  "sq.search.diagnose.service.note.title":
    "Additional notes, customer concerns, etc-fr.",
  "sq.search.declined.service.note.error.msg":
    "Additional notes are required-fr",
  "sq.search.diagnose.service.note.error.msg":
    "additional notes are required-fr",
  "sq.editservice.save_error":
    "Please review the following information to save this item to the quote "
};

export default localeFrCa;
