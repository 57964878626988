import React from "react";
import { string } from "prop-types";
import Alert from "@cx/ui/Alert";
import Card from "@cx/ui/Card";

const NoAccess = ({ message, title }) => {
  return (
    <Card htmlId="CardErrorPage">
      <div style={{ width: "100%" }}>
        <h3>{title}</h3>
        <Alert htmlId="systemAlert" type="danger">
          {message}
        </Alert>
      </div>
    </Card>
  );
};

NoAccess.defaultProps = {
  message: "",
  title: ""
};

NoAccess.propTypes = {
  message: string,
  title: string
};

export default NoAccess;
