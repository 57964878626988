import React from "react";
import { string } from "prop-types";

const Header = ({ roNumber }) => {
  return (
    <div className="activity-log-info">
      <span className="text-heading">
        <strong>Activity log</strong>
        <p>
          RO <strong>{roNumber}</strong>
        </p>
      </span>
    </div>
  );
};

Header.propTypes = {
  roNumber: string
};

export default Header;
