import isEmpty from "lodash/isEmpty";
import has from "lodash/has";
import get from "lodash/get";
import {
  DealerPublishedCategory,
  OperationSources,
  QuoteServiceTypes
} from "../../page-wrapper/constants/page-wrapper.constants";
import { appTypes } from "../../../constants/app.constants";
import quoteStatus, {
  payTypeCodes
} from "../../../constants/quote-status.constants";
import {
  GlobalOpsServiceType,
  priceSourceLabels
} from "../../../constants/pages.constants";
import { doesEmpty } from "../../../utils/object";
import { findMandatoryCatalogFees } from "../../page-wrapper/utils/quote-util";
import { formatNumberWithThousands } from "../../../utils/formatter.util";
import { getUpdatedServicesWithDisplayOrder } from "../../page-wrapper/utils/service.util";
import { getQuoteStatusAndWorkflowAttentionTag } from "../../../utils/quote.util";
import editQuoteService from "./edit-quote.service";
import modifyPartsService from "./modify-parts.service";
import selectServiceFormat from "../../menu-package/select-service-format";
import { appType, makeSecureRestApi } from "../../../api/xmmAxios";
import { generatePartId } from "../../../utils/helper.util";

// API is not returning parts so we map them from the payload
// @verify - WHY we need this util now?
/*
const mapParts = (payload, response) => {
  response.quoteServices = response.quoteServices.map(service => {
    // TODO: revisit this logic, why we copy parts of payload
    const payloadService = payload.quoteServices.find(
      _service =>
        _service.extServiceId.toString() === service.extServiceId.toString()
    );

    if (!isEmpty(payloadService)) {
      service.parts = payloadService.parts;
    } else {
      service.parts = [];
    }
    return service;
  });

  return response;
};
*/
// called for declined, recall, alacarte services only
const createQuote = ({ appContext, customer, vehicle, selectedService }) => {
  const { dealer, user } = appContext;
  const { dealerCode, schemaName } = dealer;
  const restUrl = `quote/${dealerCode}`;
  return new Promise((resolve, reject) => {
    const payload = createPayload(
      user,
      customer,
      vehicle,
      selectedService,
      schemaName
    );
    makeSecureRestApi(
      {
        url: restUrl,
        method: "post",
        data: payload
      },
      response => {
        // resolve(mapParts(payload, response));
        resolve(response);
      },
      error => {
        reject(error);
      },
      "Unable to save CSR."
    );
  });
};

// called for declined, recall, alacarte service only
const updateQuote = ({
  appContext,
  customer,
  vehicle,
  selectedService,
  quoteSummary
}) => {
  const { confirmationId } = quoteSummary;
  const { dealer, user } = appContext;
  const { dealerCode, schemaName } = dealer;
  const restUrl = `quote/${dealerCode}/${confirmationId}`;
  return new Promise((resolve, reject) => {
    const payload = updatePayload(
      user,
      customer,
      vehicle,
      selectedService,
      quoteSummary,
      schemaName
    );
    // * Removing warranty payload from payload for services
    payload?.quoteServices?.map(service => {
      if (has(service, "warranty")) {
        delete service?.warranty;
      }
    });

    makeSecureRestApi(
      {
        url: restUrl,
        method: "put",
        data: payload
      },
      response => {
        resolve(response);
      },
      error => {
        reject(error);
      },
      "Unable to save changes."
    );
  });
};
// @note: patch API called to update quote status, customer, vehicle etc
const patchQuote = ({
  appContext,
  quoteSummary,
  status,
  customer,
  vehicle
}) => {
  const { confirmationId } = quoteSummary;
  const { dealer, user } = appContext;
  const { dealerCode, schemaName } = dealer;
  const restUrl = `quote/${dealerCode}/${confirmationId}`;
  const customerObj = getCustomerPayload(customer, schemaName);
  const vehicleObj = getVehiclePayload(vehicle, schemaName);
  const payload = {
    quoteStatus: status,

    lastModByUserId: user.quoteUserId,
    customer: customerObj,
    vehicle: vehicleObj
  };
  return new Promise((resolve, reject) => {
    makeSecureRestApi(
      {
        url: restUrl,
        method: "patch",
        data: payload
      },
      response => {
        resolve(response);
      },
      error => {
        reject(error);
      },
      "Unable to save changes."
    );
  });
};
// util return customer{}
const getCustomerPayload = (customer, schema) => {
  let customerObj = null;
  if (!isEmpty(customer)) {
    customerObj = {
      personId: customer.personId,
      firstName: customer.firstName,
      lastName: customer.lastName,
      email: customer.email,
      commonConsumerId: customer.commonConsumerId || "",
      schema,
      mobilePhone: customer?.contactInfo?.phoneNumbers?.mobile || ""
    };
  }
  return customerObj;
};
// util returns vehicle {}
const getVehiclePayload = (vehicle, schema) => {
  let vehicleObj = null;
  if (!isEmpty(vehicle)) {
    vehicleObj = {
      vehicleId: vehicle.vehicleId,
      make: vehicle.make,
      model: vehicle.model,
      year: vehicle.year,
      trim: vehicle.trim,
      engineType: vehicle.engineType,
      engineSize: vehicle.engineSize,
      driveType: vehicle.driveType,
      transmissionType: vehicle.transmissionType,
      mileage: vehicle.mileage,
      schema,
      metaVehicleId: vehicle.metaVehicleId,
      vin: vehicle.vin
    };
  }
  return vehicleObj;
};
// Util called - prepare payload for declined, recall
const createPayload = (user, customer, vehicle, selectedService, schema) => {
  const expirationDays = 30;
  let lastModByUserId = 0,
    createdByUserId = 0;
  if (!isEmpty(user)) {
    lastModByUserId = user.quoteUserId;
    createdByUserId = user.quoteUserId;
  }
  const customerObj = getCustomerPayload(customer, schema);
  const vehicleObj = getVehiclePayload(vehicle, schema);
  const serviceRecord = getServiceToSave(selectedService, lastModByUserId);
  const quoteServices = isEmpty(serviceRecord) ? [] : [serviceRecord];

  return {
    totalPrice: null,
    totalTaxes: null,
    totalPriceOverride: null,
    totalPartsPriceOverride: null,
    totalLaborPriceOverride: null,
    calculatedTotalPartsPrice: null,
    calculatedServicePrice: null,
    finalLaborPrice: null,
    quoteStatus: quoteStatus.PROGRESS,
    vehicle: vehicleObj,
    customer: customerObj,
    lastModByUserId,
    createdByUserId,
    expirationDays,
    quoteServices
  };
};

const getMenuPackageRecord = (
  menuPackage,
  lastModByUserId,
  quoteServiceType
) => {
  let menuServices = [];
  let completedTime = null;
  let paymentStatus = null;
  if (
    (!isEmpty(menuPackage) && !doesEmpty(menuPackage.id)) ||
    !doesEmpty(menuPackage.quoteServiceId)
  ) {
    let extServiceId = has(menuPackage, "id") ? menuPackage.id.toString() : "";
    extServiceId = has(menuPackage, "extServiceId")
      ? menuPackage.extServiceId.toString()
      : extServiceId;
    const quoteRawService = menuPackage.quoteRawService;
    const mileage = menuPackage.mileage;
    const units = menuPackage.units;
    const rank = has(menuPackage, "rank") ? menuPackage.rank : "";
    const selectAll = has(menuPackage, "selectAll")
      ? menuPackage.selectAll
      : false;
    const selectable = has(menuPackage, "selectable")
      ? menuPackage.selectable
      : false;
    const servicePrice = has(menuPackage, "totalPrice")
      ? menuPackage.totalPrice
      : 0;
    const formattedMileage = formatNumberWithThousands(mileage);
    let serviceDescription = get(menuPackage, "description", "");
    serviceDescription = has(menuPackage, "serviceDescription")
      ? menuPackage.serviceDescription
      : serviceDescription;
    let serviceName = has(menuPackage, "description")
      ? `${formattedMileage} ${units} - ${serviceDescription}`
      : "";
    serviceName = has(menuPackage, "serviceName")
      ? `${formattedMileage} ${units} - ${serviceDescription}`
      : serviceName;
    const shopDuration = has(menuPackage, "shopDuration")
      ? menuPackage.shopDuration
      : 0;
    const dmsOpcode = has(menuPackage, "dmsOpcode")
      ? menuPackage.dmsOpcode
      : null;
    const subTypeId = has(menuPackage, "subTypeId")
      ? menuPackage.subTypeId
      : null;
    const allocationSubTypeId = has(menuPackage, "allocationSubTypeId")
      ? menuPackage.allocationSubTypeId
      : null;
    const internalAccount = has(menuPackage, "internalAccount")
      ? menuPackage.internalAccount
      : null;
    const finalLaborPrice = has(menuPackage, "laborPrice")
      ? trimPrice(menuPackage.laborPrice)
      : null;
    // @note: Menu Package always read from partsPrice field
    const finalPartsPrice = has(menuPackage, "partsPrice")
      ? trimPrice(menuPackage.partsPrice)
      : null;
    const payTypeGroup = has(menuPackage, "payTypeGroup")
      ? menuPackage.payTypeGroup
      : "";
    const payTypeCode = has(menuPackage, "payTypeCode")
      ? menuPackage.payTypeCode
      : "";
    const payTypeDescription = has(menuPackage, "payTypeDescription")
      ? menuPackage.payTypeDescription
      : "";
    const drivingCondition = has(menuPackage, "drivingCondition")
      ? menuPackage.drivingCondition
      : "";
    if (!isEmpty(menuPackage.services)) {
      // Transform `menuPackage.services` to include additional properties and remove unnecessary ones.
      menuServices = menuPackage.services.map(service => {
        // Convert `id` to string and assign it to `serviceId`.
        service.serviceId = service.id.toString();

        // Generate a service record with required details.
        const menuService = getServiceRecord(
          service,
          lastModByUserId,
          quoteServiceType
        );

        // @note: menuService should not pass quoteServiceId in payload
        // Remove properties that should not be included in the payload.
        delete menuService.quoteServiceId;
        delete menuService.menuServices;

        // @support sort by order: update payload of menuService by reading "order" field set as "rank"
        // If `service` has an "order" field, use it as "rank"; otherwise, set rank to null.
        const rank = has(service, "order") ? service.order : null;
        menuService.rank = rank;

        return menuService;
      });

      if (appType === appTypes.CSR) {
        // Filter out services that do not include inspections.
        const nonInspectionServices = menuServices.filter(
          service => !selectServiceFormat.isIncludedInspection(service)
        );

        // Check if all non-inspection services have a completed time.
        const allCompletedTimeChecked = nonInspectionServices.every(
          service => service.completedTime
        );

        // Check if all non-inspection services are ready for payment.
        const allPaymentStatusChecked = nonInspectionServices.every(
          service => service.paymentStatus === "ready"
        );

        // Update each service with the global `completedTime` and `paymentStatus` if they don't have their own values.
        menuServices = menuServices.map(service => {
          return {
            ...service,
            completedTime: allCompletedTimeChecked
              ? service?.completedTime || new Date().toISOString()
              : service?.completedTime || null,
            paymentStatus: allPaymentStatusChecked
              ? service?.paymentStatus || "ready"
              : service?.paymentStatus || null
          };
        });

        // Set `completedTime` at menu package level if all services have a completed time; otherwise, set to null.
        completedTime = allCompletedTimeChecked
          ? new Date().toISOString()
          : null;

        // Set `paymentStatus` at menu package level based on all services being ready; otherwise, set to null.
        paymentStatus = allPaymentStatusChecked ? "ready" : null;
      }
    }
    let totalPriceOverride = null;
    // @fix: check if menuPackage has totalPriceOverridden, then treat totalPrice as override
    if (menuPackage.totalPriceOverridden) {
      totalPriceOverride = menuPackage.totalPrice;
    }
    // IMPORTANT: Remove extra fields added by UI comp before sending menu payload;
    if (has(menuPackage, "menuOptions")) {
      delete menuPackage.menuOptions;
    }
    return {
      units,
      ...(appType === appTypes.CSR ? { completedTime } : {}),
      ...(appType === appTypes.CSR ? { paymentStatus } : {}),
      extServiceId,
      quoteRawService,
      mileage,
      quoteServiceType,
      // Menu case: parts will be null in quoteService {}
      parts: null,
      rank,
      selectAll,
      selectable,
      menuServices,
      lastModByUserId,
      servicePrice,
      serviceName,
      serviceDescription,
      shopDuration,
      dmsOpcode,
      subTypeId,
      allocationSubTypeId,
      internalAccount,
      finalLaborPrice,
      finalPartsPrice,
      totalPriceOverride,
      payTypeGroup,
      payTypeCode,
      payTypeDescription,
      ...(has(menuPackage, "defaultPayTypeCode")
        ? { defaultPayTypeCode: menuPackage.defaultPayTypeCode }
        : {}),
      ...(has(menuPackage, "quoteId") ? { quoteId: menuPackage.quoteId } : {}),
      ...(has(menuPackage, "quoteServiceId")
        ? { quoteServiceId: menuPackage.quoteServiceId }
        : {}),
      ...(has(menuPackage, "menuOptions") ? {} : {}), // remove fields added in UI comp;
      drivingCondition
    };
  } else {
    return null;
  }
};

// @note: Method called For declined, recall, menus service cases for add/edit quote flows
// return quoteService payload with parts[], labor{} objects for given service
const getServiceRecord = (service, lastModByUserId, quoteServiceTypeParam) => {
  let quoteServiceId = null;
  let serviceMenuId = null;
  let quoteRawService = null;
  let parts = [];
  // @note: edit menu flow, should have quoteServiceLaborId passed in menu service
  const quoteServiceLaborId = get(service, "quoteServiceLaborId", null);

  if (
    !isEmpty(service) &&
    (!doesEmpty(service.serviceId) ||
      !doesEmpty(service.id) ||
      !doesEmpty(service.recordId))
  ) {
    const isDeclinedService =
      service?.operationSource === OperationSources.DECLINED;
    const extServiceId = isDeclinedService
      ? service?.operationId
      : service.serviceId || service.id || service.recordId || "";
    const catalogOperationSource = service?.catalogOperationSource;
    const serviceName =
      get(service, "name", "") || get(service, "serviceName", "");
    // Menu service case - read from either description or serviceDescription
    // Declined/recall - read from serviceDescription
    const serviceDescription =
      get(service, "serviceDescription", "") || get(service, "description", "");

    const servicePrice = has(service, "price") ? service.price : 0;
    // supporting fields for menu service
    const dmsOpcode =
      get(service, "dmsOpcode", "") || get(service, "opCode", "");

    quoteRawService = has(service, "quoteRawService")
      ? service.quoteRawService
      : null;
    // Menu case: category fields
    const serviceCategory = get(service, "serviceCategory", "");
    const serviceCategoryName = get(service, "serviceCategoryName", "");
    const serviceCategoryId = get(service, "serviceCategoryId", "");
    const shopDuration = get(service, "shopDuration", null);
    const waiterAllowed = get(service, "waiterAllowed", null);
    const loanerAllowed = get(service, "loanerAllowed", null);
    const laborTaxCode = get(service, "laborTaxCode", null);
    const complaint = get(service, "complaint", null);

    let cause;
    let correction;
    let completedTime;
    let paymentStatus;
    let warranty;
    let technicians = null;
    // RO case: These fields are only added in menu services for RO
    if (
      quoteServiceTypeParam === QuoteServiceTypes.MENU &&
      appType === appTypes.CSR
    ) {
      cause = get(service, "cause", null);
      correction = get(service, "correction", null);
      completedTime = get(service, "completedTime", null);
      paymentStatus = get(service, "paymentStatus", null);
      warranty = get(service, "warranty", null);
      technicians =
        get(service, "technicians", null) && !isEmpty(service.technicians)
          ? service.technicians
          : null;
    }
    // price overrides fields
    const totalPriceOverride = get(service, "totalPriceOverride", null);
    const totalPartsPriceOverride = get(
      service,
      "totalPartsPriceOverride",
      null
    );
    const totalLaborPriceOverride = get(
      service,
      "totalLaborPriceOverride",
      null
    );
    const calculatedTotalPartsPrice = trimPrice(
      get(service, "calculatedTotalPartsPrice", 0)
    );
    const calculatedServicePrice = trimPrice(
      get(service, "calculatedServicePrice", 0)
    );
    const finalLaborPrice = trimPrice(get(service, "finalLaborPrice", 0));
    // Declined, Recall case
    let finalPartsPrice = trimPrice(get(service, "finalPartsPrice", 0));
    // @note: For MENU, menu service object should has finalPartsPrice field upon saving Menu pkg page.
    if (quoteServiceTypeParam === QuoteServiceTypes.MENU) {
      finalPartsPrice = trimPrice(get(service, "finalPartsPrice", 0));
    }
    // paytype fields
    const payTypeGroup = get(service, "payTypeGroup", "");
    const payTypeCode = get(service, "payTypeCode") ? service.payTypeCode : "";
    const payTypeDescription = get(service, "payTypeDescription", "");
    const selectable = has(service, "selectable") ? service.selectable : null;
    const serviceTypeCode = get(service, "serviceTypeCode", null);
    const serviceTypeDescription = get(service, "serviceTypeDescription", null);
    const serviceContract = get(service, "serviceContract", null);
    const operationSource = get(service, "operationSource", null);
    const serviceKind = get(service, "serviceKind", null);
    // subtype fields
    const subTypeId =
      payTypeCode === payTypeCodes.INTERNAL ? get(service, "subTypeId") : null;
    const allocationSubTypeId =
      payTypeCode === payTypeCodes.INTERNAL
        ? get(service, "allocationSubTypeId")
        : null;
    const internalAccount =
      payTypeCode === payTypeCodes.INTERNAL
        ? get(service, "internalAccount")
        : null;
    // @note: asrNotes exists for declined service only
    let asrNotes = "";
    // @note: For Declined, Recall, Menu Service case: Expected String value from service.dealershipNotes field
    const dealershipNotes = has(service, "dealershipNotes")
      ? [{ note: service?.dealershipNotes?.trim() }]
      : "";
    const labor = {};
    // Default case: set extLaborId as null for recall, declined, menu cases
    labor.extLaborId = null;
    labor.quoteServiceLaborId = quoteServiceLaborId;
    labor.defaultLaborRate = get(service, "defaultLaborRate", null);
    labor.dealerLaborRateId = get(service, "dealerLaborRateId", null);
    // Note: Menu service returned with labor price with field as "scheduledLaborPrice"
    // @csr-logic - field added in labor object for service
    if (appType === appTypes.CSR) {
      labor.laborCostMethod = service?.laborCostMethod;
      labor.laborCostOverride = service?.laborCostOverride;
    }
    if (quoteServiceTypeParam === QuoteServiceTypes.MENU) {
      labor.laborPrice = get(service, "scheduledLaborPrice", 0);
      // For Menus, add service level description to labor.description
      labor.description =
        get(service, "serviceDescription", "") ||
        get(service, "description", "");
      serviceMenuId = get(service, "serviceMenuId", null);
    } else if (
      quoteServiceTypeParam === QuoteServiceTypes.DECLINED ||
      quoteServiceTypeParam === QuoteServiceTypes.RECALL
    ) {
      labor.laborPrice = get(service, "laborPrice", 0);
      // For Recall/Declined, add service level description to labor.description
      labor.description = get(service, "serviceDescription", "");
      asrNotes = get(service, "asrNotes", "");
      quoteServiceId = get(service, "quoteServiceId", null);
    }
    labor.laborTime = get(service, "durationMins", 0);
    labor.lastModByUserId = lastModByUserId;
    labor.laborRate = service?.laborRate || null;

    // For menu service case,"part" object in service;
    // For declined, recall service case,  "parts" object in service
    let rawParts = [];
    if (quoteServiceTypeParam === QuoteServiceTypes.MENU) {
      rawParts = has(service, "part") ? service.part : [];
    } else {
      rawParts = has(service, "parts") ? service.parts : [];
    }
    parts = getPartsPayload(rawParts, lastModByUserId);
    const catalogFees = findMandatoryCatalogFees(
      service,
      payTypeCode,
      service.finalLaborPrice
    );

    return {
      quoteServiceId,
      ...(serviceMenuId ? { serviceMenuId } : {}),
      quoteServiceType:
        quoteServiceTypeParam === QuoteServiceTypes.MENU
          ? null
          : quoteServiceTypeParam, // pass null for Menu service level
      serviceName,
      serviceDescription,
      serviceCategory,
      serviceCategoryId,
      shopDuration,
      extServiceId,
      catalogOperationSource,
      servicePrice,
      dmsOpcode,
      subTypeId,
      allocationSubTypeId,
      internalAccount,
      lastModByUserId,
      serviceCategoryName,
      parts,
      labor,
      totalPartsPriceOverride,
      totalLaborPriceOverride,
      totalPriceOverride,
      calculatedTotalPartsPrice,
      calculatedServicePrice,
      finalLaborPrice,
      finalPartsPrice,
      loanerAllowed,
      waiterAllowed,
      selectable,
      payTypeGroup,
      payTypeCode,
      serviceTypeCode,
      serviceTypeDescription,
      serviceContract,
      laborTaxCode,
      // These three fields we treat them as optional in the payload
      ...(cause ? { cause } : {}),
      ...(complaint ? { complaint } : {}),
      ...(correction ? { correction } : {}),
      ...(completedTime ? { completedTime } : {}),
      ...(paymentStatus ? { paymentStatus } : {}),
      ...(warranty ? { warranty } : {}),
      ...(technicians ? { technicians } : {}),
      ...(operationSource ? { operationSource } : {}),
      ...(serviceKind ? { serviceKind } : {}),
      payTypeDescription,
      quoteRawService,
      asrNotes,
      dealershipNotes,
      ...(catalogFees.length > 0 ? { catalogFees } : {})
    };
  } else {
    return null;
  }
};
// Declined, recall cases - returns payload of quoteService
const getServiceToSave = (selectedService, lastModByUserId) => {
  let serviceType = "";
  if (selectedService.operationSource === "DECLINED") {
    serviceType = "DECLINED";
  } else if (selectedService.operationSource === "RECALL") {
    serviceType = "RECALL";
  }
  const record = getServiceRecord(
    selectedService,
    lastModByUserId,
    serviceType
  );
  // @note: Recall,Declined services should not pass serviceMenuId in payload
  delete record.serviceMenuId;
  return record;
};
// called for declined, recall
const updatePayload = (
  user,
  customer,
  vehicle,
  selectedService,
  quoteSummary,
  schema
) => {
  const { confirmationId, quoteServices } = quoteSummary;
  let payload = null;
  let lastModByUserId = 0;
  if (!isEmpty(user)) {
    lastModByUserId = user.quoteUserId;
  }
  let newServiceList = [];
  let isServiceExist = false;
  if (!isEmpty(confirmationId)) {
    if (!isEmpty(quoteServices)) {
      // Note: find for duplicate service from quoteServices
      isServiceExist = findService(
        quoteServices,
        selectedService.serviceId ||
          selectedService.id ||
          selectedService.recordId
      );

      const serviceRecord = getServiceToSave(selectedService, lastModByUserId);

      if (!isServiceExist) {
        quoteServices.push(serviceRecord);
        newServiceList = quoteServices;
      } else {
        newServiceList = removeServiceFromList(
          quoteServices,
          selectedService.serviceId ||
            selectedService.id ||
            selectedService.recordId
        );
        newServiceList.push(serviceRecord);
      }
    } else {
      // When all services are removed from quote, allow to add service in quote
      const serviceRecord = getServiceToSave(selectedService, lastModByUserId);
      quoteServices.push(serviceRecord);
      newServiceList = quoteServices;
    }
    payload = quotePayload(
      user,
      customer,
      vehicle,
      newServiceList,
      quoteSummary,
      schema
    );
  }
  return {
    ...payload,
    ...(appType === appTypes.CSR
      ? { quoteStatus: quoteSummary?.quoteStatus }
      : getQuoteStatusAndWorkflowAttentionTag(appType, quoteSummary))
  };
};
// Util returns quote object
const quotePayload = (
  user,
  customer,
  vehicle,
  services,
  quoteSummary,
  schema
) => {
  const expirationDays = 30;
  const totalSubPrice = 0;
  const {
    creationDateTime,
    confirmationId,
    quoteExpirationDateTime,
    serviceWriter,
    notes,
    partsPerson,
    counterPartsPerson,
    hangTag
  } = quoteSummary;
  let lastModByUserId = 0;
  if (!isEmpty(user)) {
    lastModByUserId = user.quoteUserId;
  }
  const customerObj = getCustomerPayload(customer, schema);
  const vehicleObj = getVehiclePayload(vehicle, schema);
  const finalPayload = {
    totalSubPrice,
    vehicle: vehicleObj,
    customer: customerObj,
    lastModByUserId,
    expirationDays,
    creationDateTime,
    confirmationId,
    serviceWriter,
    hangTag,
    notes,
    quoteExpirationDateTime,
    quoteServices: services
  };
  if (appType === appTypes.CSR) {
    const mileageIn = has(quoteSummary, "mileageIn")
      ? quoteSummary?.mileageIn
      : null;
    const checkedInDateTime = has(quoteSummary, "checkedInDateTime")
      ? quoteSummary?.checkedInDateTime
      : null;
    finalPayload.partsPerson = partsPerson;
    finalPayload.counterPartsPerson = counterPartsPerson;
    finalPayload.mileageIn = mileageIn;
    finalPayload.checkedInDateTime = checkedInDateTime;
    finalPayload.pickUpDateTime = quoteSummary?.pickUpDateTime;
    finalPayload.transportation = quoteSummary?.transportation;
  }
  getUpdatedServicesWithDisplayOrder(finalPayload.quoteServices);
  return finalPayload;
};

const findService = (services, serviceId) => {
  const matches = services.filter(service => {
    return !service.extServiceId
      ? String(service.id) === String(serviceId)
      : service.extServiceId === serviceId.toString();
  });
  return matches.length !== 0;
};

/**
 * To find menus under existing quote
 *
 * deprecated - not used for now
 * @param {*} quoteServices
 * @param {*} menuId
 * @param {*} mileage
 * @returns boolean {true = menu matches}
 */
const findMenuPackage = (quoteServices, menuId, mileage) => {
  const matches = quoteServices.filter(
    service =>
      service.quoteServiceType === QuoteServiceTypes.MENU &&
      service.extServiceId.toString() === menuId.toString() &&
      service.mileage === mileage
  );
  return matches.length !== 0;
};

const removeServiceFromQuote = async ({ deletedService, quoteSummary }) => {
  const { dealerCode, confirmationId } = quoteSummary;
  const { quoteServiceId: serviceId } = deletedService;
  const restUrl = `service/${serviceId}/quote/${dealerCode}/${confirmationId}`;
  return new Promise((resolve, reject) => {
    makeSecureRestApi(
      {
        url: restUrl,
        method: "delete"
      },
      response => {
        resolve(response);
      },
      error => {
        reject(error);
      },
      "Unable to remove service."
    );
  });
};

const removeServiceFromList = (services, serviceId) => {
  const matches = services.filter(s => {
    return String(s.extServiceId) !== serviceId.toString();
  });
  return matches;
};

const cleanMenuServices = service => {
  const matches = service.filter(s => {
    return s.quoteServiceType !== QuoteServiceTypes.MENU;
  });
  return matches;
};

const clearQuoteServices = ({
  appContext,
  customer,
  vehicle,
  quoteSummary
}) => {
  const { confirmationId } = quoteSummary;
  const { dealer, user } = appContext;
  const { dealerCode, schemaName } = dealer;
  const restUrl = `quote/${dealerCode}/${confirmationId}`;
  return new Promise((resolve, reject) => {
    const payload = clearQuoteServicesPayload(
      user,
      customer,
      vehicle,
      quoteSummary,
      schemaName
    );
    makeSecureRestApi(
      {
        url: restUrl,
        method: "put",
        data: payload
      },
      response => {
        resolve(response);
      },
      error => {
        reject(error);
      },
      "Unable to remove services."
    );
  });
};

const clearQuoteServicesPayload = (
  user,
  customer,
  vehicle,
  quoteSummary,
  schemaName
) => {
  const { confirmationId } = quoteSummary;
  let payload = null;
  const serviceList = [];
  if (!isEmpty(confirmationId)) {
    // pass empty quoteServices[] in payload
    payload = quotePayload(
      user,
      customer,
      vehicle,
      serviceList,
      quoteSummary,
      schemaName
    );
  }
  return {
    ...payload,
    quoteStatus: quoteStatus.PROGRESS
  };
};
const createQuoteWithMenuPackage = ({
  appContext,
  customer,
  vehicle,
  selectedService
}) => {
  const { dealer, user } = appContext;
  const { dealerCode, schemaName } = dealer;
  const restUrl = `quote/${dealerCode}`;
  return new Promise((resolve, reject) => {
    // TODO: update this fun
    const payload = createMenuPayload(
      user,
      customer,
      vehicle,
      selectedService,
      schemaName
    );
    makeSecureRestApi(
      {
        url: restUrl,
        method: "post",
        data: payload
      },
      response => {
        resolve(response);
      },
      error => {
        reject(error);
      },
      "Unable to save CSR."
    );
  });
};
/**
 * Permanently deletes any parts that were removed from any of the menu child services.
 * @param {object} appContext App context.
 * @param {object} quoteSummary Full quote/RO object. Still contains parts that were removed.
 * @param {object} selectedService Menu service being modified. Its child services still contain parts that were removed.
 * @param {object} payload Modified quote/RO object. Does not contain removed parts.
 * @returns {Promise<void>}
 */
const deleteRemovedMenuServiceParts = async (
  appContext,
  quoteSummary,
  selectedService,
  payload
) => {
  const originalMenuService = selectedService;
  const modifiedMenuService = payload.quoteServices.find(
    s => s.quoteServiceId === selectedService.quoteServiceId
  );

  for (const originalChildService of originalMenuService.menuServices) {
    const modifiedChildService = modifiedMenuService.menuServices.find(
      s =>
        s.serviceMenuId &&
        s.serviceMenuId === originalChildService.serviceMenuId
    );
    if (modifiedChildService) {
      const modifiedPartsList = modifiedChildService.parts;
      await modifyPartsService.deleteRemovedParts(
        appContext,
        modifiedPartsList,
        originalChildService,
        quoteSummary
      );
    }
  }
};
const updateQuoteWithMenuPackage = async ({
  appContext,
  customer,
  vehicle,
  selectedService,
  quoteSummary
}) => {
  const { confirmationId } = quoteSummary;
  const { dealer, user } = appContext;
  const { dealerCode, schemaName } = dealer;

  const payload = updateMenuPayload(
    user,
    customer,
    vehicle,
    selectedService,
    quoteSummary,
    schemaName
  );

  await deleteRemovedMenuServiceParts(
    appContext,
    quoteSummary,
    selectedService,
    payload
  );

  return new Promise((resolve, reject) => {
    makeSecureRestApi(
      {
        url: `quote/${dealerCode}/${confirmationId}`,
        method: "put",
        data: payload
      },
      response => {
        resolve(response);
      },
      error => {
        reject(error);
      },
      "Unable to save changes."
    );
  });
};
// parse menu package record and create payoad for quote object
const createMenuPayload = (
  user,
  customer,
  vehicle,
  selectedMenuPackage,
  schemaName
) => {
  const expirationDays = 30;
  let lastModByUserId = 0,
    createdByUserId = 0;
  if (!isEmpty(user)) {
    lastModByUserId = user.quoteUserId;
    createdByUserId = user.quoteUserId;
  }
  const customerObj = getCustomerPayload(customer, schemaName);
  const vehicleObj = getVehiclePayload(vehicle, schemaName);
  const menuRecord = getMenuPackageRecord(
    selectedMenuPackage,
    lastModByUserId,
    QuoteServiceTypes.MENU
  );
  console.log("menuRecord", menuRecord);
  let quoteServices = isEmpty(menuRecord) ? [] : [menuRecord];

  quoteServices = JSON.parse(JSON.stringify(quoteServices));
  quoteServices = quoteServices.map(service => {
    // Special case - check if we pass empty objects in parts, labor
    if (service.quoteServiceType === QuoteServiceTypes.MENU) {
      delete service.parts;
      delete service.labor;
    }
    return service;
  });

  return {
    totalPrice: null,
    totalTaxes: null,
    quoteStatus: quoteStatus.PROGRESS,
    vehicle: vehicleObj,
    customer: customerObj,
    expirationDays,
    quoteServices,
    lastModByUserId,
    createdByUserId
  };
};
const updateMenuPayload = (
  user,
  customer,
  vehicle,
  selectedMenuPackage,
  quoteSummary,
  schemaName
) => {
  const { confirmationId } = quoteSummary;
  let { quoteServices } = quoteSummary;
  let payload = null;
  let lastModByUserId = 0;

  if (!isEmpty(user)) {
    lastModByUserId = user.quoteUserId;
  }
  if (!isEmpty(confirmationId)) {
    if (!isEmpty(quoteServices)) {
      quoteServices = cleanMenuServices(quoteServices);
      const menuRecord = getMenuPackageRecord(
        selectedMenuPackage,
        lastModByUserId,
        QuoteServiceTypes.MENU
      );
      quoteServices.push(menuRecord);
    } else {
      // When all services are removed from quote, allow to add service in quote
      const menuRecord = getMenuPackageRecord(
        selectedMenuPackage,
        lastModByUserId,
        QuoteServiceTypes.MENU
      );
      quoteServices.push(menuRecord);
    }

    payload = quotePayload(
      user,
      customer,
      vehicle,
      quoteServices,
      quoteSummary,
      schemaName
    );
  }

  payload = JSON.parse(JSON.stringify(payload));
  payload.quoteServices = payload.quoteServices.map(service => {
    if (service.quoteServiceType === QuoteServiceTypes.MENU) {
      delete service.parts;
      delete service.labors;
      delete service.labor;
    }
    return service;
  });

  return {
    ...payload,
    // Note: For CSR case, always pass current quote status
    ...(appType === appTypes.CSR
      ? { quoteStatus: quoteSummary?.quoteStatus }
      : getQuoteStatusAndWorkflowAttentionTag(appType, quoteSummary))
  };
};

const baseQuotePayload = (user, customer, vehicle, schemaName) => {
  console.log("baseQuotePayload", customer, vehicle);
  const expirationDays = 30;
  let lastModByUserId = 0,
    createdByUserId = 0;
  if (!isEmpty(user)) {
    lastModByUserId = user.quoteUserId;
    createdByUserId = user.quoteUserId;
  }
  const customerObj = getCustomerPayload(customer, schemaName);
  const vehicleObj = getVehiclePayload(vehicle, schemaName);

  return {
    totalPrice: null,
    totalTaxes: null,
    quoteStatus: quoteStatus.PROGRESS,
    vehicle: vehicleObj,
    customer: customerObj,
    lastModByUserId,
    createdByUserId,
    expirationDays
  };
};

const updateQuoteStatus = ({
  dealerCode,
  confirmationId,
  lastModByUserId,
  quoteStatus
}) => {
  const restUrl = `quote/${dealerCode}/${confirmationId}`;
  return new Promise((resolve, reject) => {
    makeSecureRestApi(
      {
        url: restUrl,
        method: "patch",
        data: {
          quoteStatus,
          lastModByUserId
        }
      },
      response => {
        resolve(response);
      },
      error => {
        reject(error);
      },
      "Unable to update CSR status."
    );
  });
};
// API call to update quote notes under quote - scope within quote summary page
const updateQuoteNotes = ({
  dealerCode,
  confirmationId,
  lastModByUserId,
  notes
}) => {
  const restUrl = `quote/${dealerCode}/${confirmationId}`;
  return new Promise((resolve, reject) => {
    makeSecureRestApi(
      {
        url: restUrl,
        method: "patch",
        data: {
          notes,
          lastModByUserId
        }
      },
      response => {
        resolve(response);
      },
      error => {
        reject(error);
      },
      "Unable to save notes."
    );
  });
};

const updateQuoteMileageOut = ({
  dealerCode,
  confirmationId,
  lastModByUserId,
  mileageOut
}) => {
  const restUrl = `quote/${dealerCode}/${confirmationId}`;
  return new Promise((resolve, reject) => {
    makeSecureRestApi(
      {
        url: restUrl,
        method: "patch",
        data: {
          mileageOut,
          lastModByUserId
        }
      },
      response => {
        resolve(response);
      },
      error => {
        reject(error);
      },
      "Unable to save mileage out."
    );
  });
};

const updatePaymentLinesUsingJsonPatch = ({
  dealerCode,
  confirmationId,
  payerId,
  diffOps
}) => {
  const restUrl = `payer/${payerId}/patchpayer/dealercode/${dealerCode}/quote/${confirmationId}`;
  return new Promise((resolve, reject) => {
    makeSecureRestApi(
      {
        url: restUrl,
        method: "patch",
        data: diffOps
      },
      response => {
        resolve(response);
      },
      error => {
        reject(error);
      },
      "Unable to save payment lines."
    );
  });
};

// @new - create quote - common method for global, dealer publish services
const createQuoteForGlobalOps = ({
  appContext,
  customer,
  vehicle,
  selectedService
}) => {
  const { dealer, user } = appContext;
  const { dealerCode, schemaName } = dealer;
  const restUrl = `quote/${dealerCode}`;
  return new Promise((resolve, reject) => {
    let payload = null;
    const quoteServiceType = findQuoteServiceType(selectedService);
    payload = createGlobalOpsPayloadCommon(
      user,
      customer,
      vehicle,
      selectedService,
      schemaName,
      quoteServiceType
    );
    if (!payload) {
      reject("Failed to create quote payload");
    }
    makeSecureRestApi(
      {
        url: restUrl,
        method: "post",
        data: payload
      },
      response => {
        resolve(response);
      },
      error => {
        reject(error);
      },
      "Unable to save CSR."
    );
  });
};
// @new - common method for globalops, dealer publish, diagnosis service
/**
 *
 * @param {object} param
 * @returns
 */
const updateQuoteForGlobalOps = async ({
  appContext,
  customer,
  vehicle,
  selectedService,
  quoteSummary
}) => {
  const { dealer, user } = appContext;
  const { schemaName } = dealer;

  const quoteServiceType = findQuoteServiceType(selectedService);
  const { payload, deletedService } = updateGlobalOpsPayloadCommon(
    user,
    customer,
    vehicle,
    selectedService,
    schemaName,
    quoteSummary,
    quoteServiceType
  );

  // @csr-logic : Removing warranty payload from payload for services
  payload?.quoteServices?.map(service => {
    if (has(service, "warranty")) {
      delete service?.warranty;
    }
  });

  if (!payload) {
    throw new Error("Failed to update quote payload");
  }

  if (deletedService) {
    await removeServiceFromQuote({ deletedService, quoteSummary });
  }

  return await editQuoteService.updateQuote(payload);
};

// @new - common method returns quoteServiceType for a service
const findQuoteServiceType = selectedService => {
  let quoteServiceType;
  const catalogSource = selectedService.operationSource;
  if (catalogSource === GlobalOpsServiceType.DEALERCATALOG) {
    if (
      selectedService.serviceKind === DealerPublishedCategory.REPAIR &&
      selectedService.operationSource === GlobalOpsServiceType.DEALERCATALOG
    ) {
      quoteServiceType = QuoteServiceTypes.DEALER_PUB_REPAIR_OPS;
    } else if (
      selectedService.serviceKind === DealerPublishedCategory.MAINTENANCE &&
      selectedService.operationSource === GlobalOpsServiceType.DEALERCATALOG
    ) {
      quoteServiceType = QuoteServiceTypes.DEALER_PUB_MAINT_OPS;
    }
  } else if (catalogSource === GlobalOpsServiceType.GLOBALCATALOG) {
    quoteServiceType = QuoteServiceTypes.GLOBAL_REPAIR_OPS;
  }
  return quoteServiceType;
};
// @new - common method returns quoteService{} for non-global {dealer pub, diagnosis} and globalops repair only
/**
 *
 * @param {object} service - object prepared from service-data-mapper
 * @param {number} lastModByUserId
 * @param {string} quoteServiceType
 * @returns payload of quote service
 */
const prepareQuoteService = (service, lastModByUserId, quoteServiceType) => {
  let parts = [];
  let finalPayload = null;
  if (!isEmpty(service)) {
    const quoteRawService = service?.quoteRawService || { rawService: "" };
    const operationSource = service.operationSource;
    // @issue- check for id or extServiceId must exist in service
    const extServiceId =
      get(service, "id", "") || get(service, "extServiceId", "");
    const serviceDescription = get(service, "serviceDescription", "");
    // For Dealer Publish, Global Repair case - Expected string value from service.dealershipNotes
    const dealershipNotes = has(service, "dealershipNotes")
      ? [{ note: service?.dealershipNotes?.trim() }]
      : null;
    const serviceName = has(service, "serviceName") ? service.serviceName : "";
    const servicePrice = has(service, "servicePrice")
      ? service.servicePrice
      : 0;
    const dmsOpcode = has(service, "dmsOpcode")
      ? service.dmsOpcode
      : service.opCode || "";
    const subTypeId = has(service, "subTypeId") ? service.subTypeId : null;
    const allocationSubTypeId = has(service, "allocationSubTypeId")
      ? service.allocationSubTypeId
      : null;
    const internalAccount = has(service, "internalAccount")
      ? service.internalAccount
      : null;
    const serviceCategory = has(service, "serviceCategory")
      ? service.serviceCategory
      : "";
    const serviceCategoryId = has(service, "serviceCategoryId")
      ? service.serviceCategoryId
      : "";
    // price overrides
    const totalPriceOverride = has(service, "totalPriceOverride")
      ? service.totalPriceOverride
      : null;
    const totalPartsPriceOverride = has(service, "totalPartsPriceOverride")
      ? service.totalPartsPriceOverride
      : null;
    const totalLaborPriceOverride = has(service, "totalLaborPriceOverride")
      ? service.totalLaborPriceOverride
      : null;
    const calculatedTotalPartsPrice = has(service, "calculatedTotalPartsPrice")
      ? trimPrice(service.calculatedTotalPartsPrice)
      : null;
    const calculatedServicePrice = has(service, "calculatedServicePrice")
      ? trimPrice(service.calculatedServicePrice)
      : null;
    const finalLaborPrice = has(service, "finalLaborPrice")
      ? trimPrice(service.finalLaborPrice)
      : null;
    const finalPartsPrice = has(service, "finalPartsPrice")
      ? trimPrice(service.finalPartsPrice)
      : null;
    const payTypeGroup = has(service, "payTypeGroup")
      ? service.payTypeGroup
      : "";
    const payTypeCode = has(service, "payTypeCode") ? service.payTypeCode : "";
    const payTypeDescription = has(service, "payTypeDescription")
      ? service.payTypeDescription
      : "";
    const serviceTypeCode = has(service, "serviceTypeCode")
      ? service.serviceTypeCode
      : "";
    const serviceContract = has(service, "serviceContract")
      ? service.serviceContract
      : null;
    const serviceKind = has(service, "serviceKind") ? service.serviceKind : "";
    const shopDuration = has(service, "shopDuration")
      ? service.shopDuration
      : null;
    const commentsRequired = has(service, "commentsRequired")
      ? service.commentsRequired
      : null;
    const waiterAllowed = has(service, "waiterAllowed")
      ? service.waiterAllowed
      : null;
    const loanerAllowed = has(service, "loanerAllowed")
      ? service.loanerAllowed
      : null;
    const selectedVehicleAttributes = has(service, "vehicleAttributes")
      ? JSON.stringify(service.vehicleAttributes)
      : null;
    // @note: labor{} object - For dealer pub, global ops case, will labor.description read from catalogAPI "laborApp[index].displayName"
    /**
     * labor : {extLaborId, description,laborPrice, laborTime, defaultLaborRate, dealerLaborRateId, quoteServiceLaborId}
     */
    const labor = has(service, "labor") ? service.labor : {};
    labor.quoteServiceLaborId = get(service, "quoteServiceLaborId", null);
    labor.lastModByUserId = lastModByUserId;
    labor.laborRate = service?.labor?.laborRate || null;
    const rawParts = get(service, "parts", []);
    parts = getPartsPayload(rawParts, lastModByUserId);
    // @csr fields
    const cause = service?.cause || null;
    const complaint = service?.complaint || null;
    const correction = service?.correction || null;
    const technicians = service?.technicians || [];
    const laborTaxCode = service?.laborTaxCode || null;
    const catalogFees = findMandatoryCatalogFees(
      service,
      payTypeCode,
      service.finalLaborPrice
    );

    finalPayload = {
      operationSource,
      serviceDescription,
      dealershipNotes,
      quoteServiceType,
      serviceName,
      serviceCategory,
      serviceCategoryId,
      shopDuration,
      serviceKind,
      extServiceId,
      servicePrice,
      dmsOpcode,
      subTypeId,
      allocationSubTypeId,
      internalAccount,
      quoteRawService,
      parts,
      labor,
      lastModByUserId,
      totalLaborPriceOverride,
      totalPartsPriceOverride,
      totalPriceOverride,
      calculatedTotalPartsPrice,
      calculatedServicePrice,
      finalLaborPrice,
      finalPartsPrice,
      commentsRequired,
      waiterAllowed,
      loanerAllowed,
      payTypeGroup,
      payTypeCode,
      payTypeDescription,
      serviceTypeCode,
      serviceContract,
      selectedVehicleAttributes,
      cause,
      complaint,
      correction,
      catalogFees
    };
    // csr-fields
    if (appType === appTypes.CSR) {
      finalPayload.laborTaxCode = laborTaxCode;
    }
    if (appType === appTypes.CSR && payTypeCode === "W") {
      finalPayload.technicians = technicians;
      finalPayload.complaint = complaint;
    }
  }
  console.log("prepareQuoteService payload", finalPayload);
  return finalPayload;
};
/**
 *
 * @param {float} price  eg: 68.53999999999999
 * @returns outpur eg: 68.54
 */
const trimPrice = price => {
  const newPrice = !price ? 0 : price;
  return Number(newPrice.toFixed(2));
};
// @new - common method called for both non-globalops {dealer pub, diagnosis} and global ops services only
/**
 *
 * @param {object} user
 * @param {object} customer
 * @param {object} vehicle
 * @param {object} selectedService
 * @param {string} schemaName
 * @param {string} quoteServiceType
 * @returns payload for quote
 */
const createGlobalOpsPayloadCommon = (
  user,
  customer,
  vehicle,
  selectedService,
  schemaName,
  quoteServiceType
) => {
  try {
    const payload = baseQuotePayload(user, customer, vehicle, schemaName);
    const mappedService = prepareQuoteService(
      selectedService,
      payload.lastModByUserId,
      quoteServiceType
    );

    console.log("prepareQuoteService > service", mappedService);
    // TODO: TBD apply schema validation for quote service
    // apply schema validator here; if validation fails, return payload as null
    // await quoteServiceSchema.validate(mappedService);
    const quoteServices = isEmpty(mappedService) ? [] : [mappedService];

    return {
      ...payload,
      quoteServices
    };
  } catch (error) {
    console.error("quoteServiceSchema", error?.errors);
    return null;
  }
};
// @new - update quote case - common util called for both global repair & non-globalops repair {dealer pub, diagnosis}
/**
 *
 * @param {object} user
 * @param {object} customer
 * @param {object} vehicle
 * @param {object} selectedService
 * @param {string} schemaName
 * @param {object} quoteSummary
 * @param {string} quoteServiceType
 * @returns payload of quote
 */
const updateGlobalOpsPayloadCommon = (
  user,
  customer,
  vehicle,
  selectedService,
  schemaName,
  quoteSummary,
  quoteServiceType
) => {
  let payload = null;
  let deletedService = null;

  try {
    const { confirmationId, quoteServices } = quoteSummary;
    let newQuoteServices = [];
    let lastModByUserId = 0;
    if (!isEmpty(user)) {
      lastModByUserId = user.quoteUserId;
    }
    let isServiceExist = false;
    if (!isEmpty(confirmationId)) {
      const serviceRecord = prepareQuoteService(
        selectedService,
        lastModByUserId,
        quoteServiceType
      );
      // TODO: TBD apply schema validation for quote service
      // await quoteServiceSchema.validate(serviceRecord);
      if (!isEmpty(quoteServices)) {
        // NOTE: serviceId should send. Otherwise the same service gets saved multiple times
        const serviceId =
          get(selectedService, "id", null) ||
          get(selectedService, "extServiceId", null);

        if (serviceId) {
          isServiceExist = findService(quoteServices, serviceId);
        }
        if (!isServiceExist) {
          newQuoteServices = [...quoteServices, serviceRecord];
        } else {
          const existingServiceId = quoteServices.findIndex(serv => {
            const id = !serv.extServiceId
              ? String(serv.id)
              : String(serv.extServiceId);
            return id === String(serviceId);
          });
          deletedService = quoteServices[existingServiceId];
          newQuoteServices = [
            ...quoteServices.slice(0, existingServiceId),
            serviceRecord,
            ...quoteServices.slice(existingServiceId + 1)
          ];
          console.log("update case", newQuoteServices);
        }
      } else {
        // When all services are removed from quote, allow to add service in quote
        newQuoteServices = [...quoteServices, serviceRecord];
      }
      // read final quote payload
      payload = quotePayload(
        user,
        customer,
        vehicle,
        newQuoteServices,
        quoteSummary,
        schemaName
      );
    }

    payload = {
      dealerCode: quoteSummary.dealerCode,
      ...payload,
      ...(appType === appTypes.CSR
        ? { quoteStatus: quoteSummary?.quoteStatus }
        : getQuoteStatusAndWorkflowAttentionTag(appType, quoteSummary))
    };
    console.log("updateGlobalOpsPayloadCommon payload", payload);
    return {
      payload,
      deletedService
    };
  } catch (error) {
    console.error("updateGlobalOpsPayloadCommon", error?.errors);
    return {
      payload: null,
      deletedService: null
    };
  }
};

// @new - common method return quote Parts for given service
/**
 *
 * @param {Array of objects} rawParts
 * @param {number} lastModByUserId
 * @returns
 */
const getPartsPayload = (rawParts, lastModByUserId) => {
  let parts = [];
  if (!isEmpty(rawParts) && rawParts.length > 0) {
    // Part case:
    parts = rawParts.map(part => {
      const partRecord = {};
      partRecord.quoteServicePartId = get(part, "quoteServicePartId", null);
      const genPartId = generatePartId();
      // FIX - if partId or id missing, set random generated partId as "extPartId" (Fallback logic)
      partRecord.extPartId = get(part, "partId", get(part, "id", genPartId));
      partRecord.lastModByUserId = lastModByUserId;
      partRecord.description = get(
        part,
        "partName",
        get(part, "description", "")
      );
      const adjustedQuantity = get(
        part,
        "quantity",
        get(part, "adjustedQuantity", 0)
      );

      partRecord.adjustedQuantity = adjustedQuantity;
      const unitPrice = parseFloat(get(part, "unitPrice", 0));
      partRecord.unitPrice = unitPrice;
      // Always derive partPrice
      partRecord.partPrice = parseFloat(unitPrice * adjustedQuantity);
      partRecord.partType = has(part, "partType") ? part.partType : "part";

      partRecord.dtDmsPartCode = has(part, "dtDmsPartCode")
        ? part.dtDmsPartCode
        : null;
      partRecord.position = has(part, "position") ? part.position : null;

      // Case: when unified response returns either "oemPartNumber"/ oePartNumber for dealer pub,global catalog, menu etc
      let partNumber = "";
      if (has(part, "oemPartNumber")) {
        partNumber = part.oemPartNumber;
      } else if (has(part, "oePartNumber")) {
        partNumber = part.oePartNumber;
      }
      // common fields for  parts/fluids
      partRecord.oemPartNumber = partNumber;
      partRecord.manufacturer = "OEM";
      partRecord.unitCost = part?.unitCost ?? null;
      const partsNotes = has(part, "notes") ? part.notes : null;
      partRecord.notes =
        !isEmpty(partsNotes) && Array.isArray(partsNotes) ? partsNotes : null;
      // DB column part_price_source -> read from part.partPriceSource
      partRecord.priceSource = get(
        part,
        "partPriceSource",
        priceSourceLabels.MSRP
      );

      // Fluid case: either oilType or oemPartNumber should be displayed
      if (part.partType === "fluid") {
        partRecord.oilType = has(part, "oilType") ? part.oilType : "";
        partRecord.adjustedUom = has(part, "unitOfMeasure")
          ? part.unitOfMeasure
          : "";
        const adjustedQuantity = get(
          part,
          "quantity",
          get(part, "adjustedQuantity", 0)
        );

        partRecord.adjustedQuantity = adjustedQuantity;
        const unitPrice = parseFloat(get(part, "unitPrice", 0));
        partRecord.unitPrice = unitPrice;
        // Always derive partPrice
        partRecord.partPrice = parseFloat(unitPrice * adjustedQuantity);
      }

      if (appType === appTypes.CSR) {
        return {
          ...partRecord,
          ...part
        };
      }

      return partRecord;
    });
  }
  return parts;
};
const getEmergencyParts = (appContext, roNumber) => {
  const { dealer } = appContext;
  const { dealerCode } = dealer;
  const restUrl = `csr/emergencyparts/dealers/id/${dealerCode}/inventory-parts/ro/${roNumber}/parts`;
  return new Promise((resolve, reject) => {
    makeSecureRestApi(
      {
        url: restUrl,
        method: "get"
      },
      async response => {
        resolve(response);
      },
      error => {
        reject(error);
      },
      "Unable to save CSR."
    );
  });
};
const getPurchaseOrderList = appContext => {
  const { dealer } = appContext.appContext;
  const { dealerCode } = dealer;
  const restUrl = `csr/emergencyparts/dealers/id/${dealerCode}/inventory-parts/purchase-orders?repairOrderNumber=&status=NEW,CHANGE,PROGRESS&typeahead=&procurementType=DROP`;
  return new Promise((resolve, reject) => {
    makeSecureRestApi(
      {
        url: restUrl,
        method: "get"
      },
      response => {
        resolve(response);
      },
      error => {
        reject(error);
      },
      "Unable to save CSR."
    );
  });
};
const getParsedPartsList = (parts, roNumber) => {
  const partsList = [];
  parts.forEach(item => {
    partsList.push({
      cost: {
        amount: item.unitCost,
        currency: "USD"
      },
      customerId: "",
      repairOrderNumber: roNumber,
      repairOrderPart: {
        location: item.location,
        manufacturerCode: item.dtDmsPartCode,
        partNumber: item.oemPartNumber,
        partSequenceNumber: item.roPartNum,
        procurementType: item.subtypeOfPurchase.toUpperCase(),
        quantity: item.adjustedQuantity,
        receiptRequired: null
      },
      stockNumber: "",
      totalCost: null,
      unitOfMeasure: item.unitOfMeasure,
      vin: ""
    });
  });
  return partsList;
};
const updatePurchaseOrder = ({
  dealerCode,
  quoteSummary,
  selectedPurchaseOrder,
  selectedParts
}) => {
  const { roNumber } = quoteSummary;
  const partsList = getParsedPartsList(selectedParts, roNumber);
  const { purchaseOrderNumber } = selectedPurchaseOrder.value;
  const restUrl = `csr/emergencyparts/dealers/id/${dealerCode}/inventory-parts/purchase-orders/id/${purchaseOrderNumber}/lines`;
  return new Promise((resolve, reject) => {
    makeSecureRestApi(
      {
        url: restUrl,
        method: "post",
        data: {
          ...selectedPurchaseOrder.value,
          purchaseOrderLines: [partsList]
        }
      },
      response => {
        resolve(response);
      },
      error => {
        reject(error);
      },
      "Unable to update CSR status."
    );
  });
};

const getEmergencyPartsBuyers = dealerCode => {
  const restUrl = `csr/emergencyparts/dealers/id/${dealerCode}/inventory-parts/buyers`;
  return new Promise((resolve, reject) => {
    makeSecureRestApi(
      {
        url: restUrl,
        method: "get"
      },
      response => {
        resolve(response);
      },
      error => {
        reject(error);
      },
      "Unable to retrieve buyer list."
    );
  });
};

const getEmergencyPartsManufacturers = dealerCode => {
  const restUrl = `csr/emergencyparts/dealers/id/${dealerCode}/inventory-parts/manufacturers`;
  return new Promise((resolve, reject) => {
    makeSecureRestApi(
      {
        url: restUrl,
        method: "get",
        params: {
          type: ""
        }
      },
      response => {
        resolve(response);
      },
      error => {
        reject(error);
      },
      "Unable to retrieve manufacturers list."
    );
  });
};
const createPurchaseOrder = ({
  dealerCode,
  quoteSummary,
  purchaseOrder,
  selectedParts
}) => {
  const { roNumber } = quoteSummary;
  const partsList = getParsedPartsList(selectedParts, roNumber);
  const restUrl = `csr/emergencyparts/dealers/id/${dealerCode}/inventory-parts/purchase-orders`;
  return new Promise((resolve, reject) => {
    makeSecureRestApi(
      {
        url: restUrl,
        method: "post",
        data: {
          ...purchaseOrder,
          purchaseOrderLines: partsList
        }
      },
      response => {
        resolve(response);
      },
      error => {
        reject(error);
      },
      "Unable to update CSR status."
    );
  });
};

export default {
  findMenuPackage,
  createQuote,
  updateQuote,
  updateQuoteStatus,
  updateQuoteNotes,
  updateQuoteMileageOut,
  clearQuoteServices,
  removeServiceFromQuote,
  patchQuote,
  createQuoteWithMenuPackage,
  updateQuoteWithMenuPackage,
  createQuoteForGlobalOps,
  updateQuoteForGlobalOps,
  updatePaymentLinesUsingJsonPatch,
  getEmergencyParts,
  getPurchaseOrderList,
  updatePurchaseOrder,
  getEmergencyPartsBuyers,
  getEmergencyPartsManufacturers,
  createPurchaseOrder
};
