import React, { useState, useContext } from "react";
import Grid from "@cx/ui/Grid";
import Row from "@cx/ui/Row";
import Col from "@cx/ui/Col";
import TextInput from "@cx/ui/TextInput";
import NumericInput from "@cx/ui/NumericInput";
import Advisor from "../change-advisor/change-advisor.component";
import { Actions, useNewQuoteContext } from "../../../../state/NewQuoteContext";
import "./sub-header.scss";
import { isEmptyObject } from "../../../../utils/object";
import { QuoteServiceTypes } from "../../../page-wrapper/constants/page-wrapper.constants";
import { getUniqueTechs } from "../../../../utils/quote.util";
import { formatInTimeZone } from "date-fns-tz";
import { AppContext } from "../../../../state/app-context";
import moment from "moment";

const SubHeader = () => {
  const { state, dispatch } = document.location.href.includes(
    "&TEST_HOOK_SUBHEADER_UNDEFINED_STATE=1"
  )
    ? {}
    : // eslint-disable-next-line react-hooks/rules-of-hooks
      useNewQuoteContext();
  const { advisors, quoteSummary } = state;
  const {
    hangTag,
    vehicle,
    mileageIn,
    quoteStatus,
    advisor: assignedAdvisor,
    quoteServices,
    counterPartsPerson
  } = quoteSummary;
  const [localVin, setLocalVin] = useState(vehicle.vin);
  const appContext = useContext(AppContext);
  const { timeZone } = appContext;
  const quoteSummaryWithAdvisors = { quoteSummary, advisors };

  const techniciansList = quoteServices.map(c => c.technicians);
  let uniqueTechs = getUniqueTechs(techniciansList);

  quoteServices.forEach(qService => {
    if (qService.quoteServiceType === QuoteServiceTypes.MENU) {
      const menuTechnicians =
        qService?.menuServices
          ?.filter(service => service.technicians.length > 0)
          ?.map(service => service.technicians) || [];
      const uniqueMenuTechs = getUniqueTechs(menuTechnicians);
      uniqueTechs = uniqueTechs.concat(uniqueMenuTechs);
    }
  });
  const formatHeaderDate = date =>
    moment.parseZone(date).local().format("M/D/YY - h:mm A");

  const getStartDateTime = () =>
    quoteSummary.checkedInDateTime
      ? formatInTimeZone(
          quoteSummary.checkedInDateTime,
          timeZone,
          "M/d/yy - h:mm a"
        )
      : "- -";
  const getEndDateTime = () =>
    quoteSummary.closedDateTime
      ? formatHeaderDate(quoteSummary.closedDateTime)
      : "- -";

  const updateAdvisor = payload => {
    dispatch({
      type: Actions.SET_ADVISOR,
      payload: payload.serviceWriter
    });
  };

  const updateVin = event => {
    setLocalVin(event.target.value);
    dispatch({
      type: Actions.SET_VIN,
      payload: event.target.value
    });
  };

  const updateMileageIn = payload => {
    dispatch({
      type: Actions.SET_MILEAGE_IN,
      payload: payload.target.value
    });
  };

  const updateHangTag = payload => {
    dispatch({
      type: Actions.SET_HANG_TAG,
      payload: payload.target.value
    });
  };

  return (
    <>
      {quoteStatus !== "WITH_ADVISOR" ? (
        <Grid className="sub-header">
          <Row className="row sub-header-container">
            <Col className="col m-r-lg">
              <Row>
                <span className="grey-text" data-testid="advisor-label">
                  Advisor
                </span>
              </Row>
              <Row>
                <span className="value-text" data-testid="advisor-text">
                  {isEmptyObject(assignedAdvisor) !== true
                    ? `${assignedAdvisor?.firstName || ""} ${
                        assignedAdvisor?.lastName || ""
                      }`
                    : "- -"}
                </span>
              </Row>
            </Col>
            <Col className="col m-r-lg">
              <Row className="grey-text" data-testid="tech-label">
                Technicians
              </Row>
              <Row className="value-text" data-testid="tech-text">
                {uniqueTechs?.length > 0
                  ? uniqueTechs.length > 2
                    ? "Multiple techs assigned"
                    : uniqueTechs.join(", ")
                  : "- -"}
              </Row>
            </Col>
            <Col className="col m-r-lg">
              <Row className="grey-text" data-testid="parts-label">
                Current parts counterperson
              </Row>
              <Row className="value-text" data-testid="parts-text">
                {(counterPartsPerson &&
                  (counterPartsPerson?.firstName || "") +
                    " " +
                    (counterPartsPerson?.lastName || "")) ||
                  "- -"}
              </Row>
            </Col>
            <Col className="col m-r-lg">
              <Row className="grey-text" data-testid="start-label">
                Started
              </Row>
              <Row className="value-text" data-testid="start-text">
                {getStartDateTime()}
              </Row>
            </Col>
            <Col className="col m-r-lg">
              <Row className="grey-text" data-testid="end-label">
                Ended
              </Row>
              <Row className="value-text" data-testid="end-text">
                {getEndDateTime()}
              </Row>
            </Col>
          </Row>
        </Grid>
      ) : null}
      {quoteStatus === "WITH_ADVISOR" ? (
        <Grid className="sub-header">
          <Row className="row sub-header-container">
            <Col className="col margin-right-36 advisor-dropdown" md={2}>
              <Advisor
                quote={quoteSummaryWithAdvisors}
                onUpdate={updateAdvisor}
                onEnterKeyPressOnUpdate={() => {}}
                required
              />
            </Col>
            <Col className="col margin-right-36 vin" md={1}>
              <TextInput
                htmlId="vin"
                name="vin"
                label="VIN"
                value={localVin ?? ""}
                minLength={17}
                maxLength={17}
                autoComplete="off"
                onChange={updateVin}
                required
              />
            </Col>
            <Col
              className="col margin-right-36"
              md={1}
              style={{ width: "136px" }}
            >
              <NumericInput
                htmlId="mileageInSubHeader"
                label="Mileage"
                name="mileageIn"
                getError={(_lbl, val) => {
                  if (!val) return "";
                  if (val == 0) return "Mileage cannot be 0";
                }}
                onChange={updateMileageIn}
                value={mileageIn ?? ""}
                maxLength={6}
                required
              />
            </Col>
            <Col
              className="col margin-right-36"
              md={1}
              style={{ width: "136px" }}
            >
              <TextInput
                htmlId="hangTagSubHeader"
                label="Hang tag"
                name="hangTag"
                onChange={updateHangTag}
                value={hangTag ?? ""}
                required
              />
            </Col>
          </Row>
        </Grid>
      ) : null}
    </>
  );
};

export default SubHeader;
