import React, { useEffect, useRef, useState } from "react";
import isEmpty from "lodash/isEmpty";
import isNull from "lodash/isNull";
import { useEditServiceContext, Actions } from "../state/edit-service.context";
import AccordionGroup from "@cx/ui/AccordionGroup";
import SearchableSelect from "@cx/ui/SearchableSelect";
import Button from "@cx/ui/Button";
import Badge from "@cx/ui/Badge";
import NumericInput from "@cx/ui/NumericInput";
import CompleteIcon from "@cx/ui/Icons/IconComplete";
import "./ServiceOptions.scss";
import { isEmptyObject } from "../../utils/object";

export const ServiceOptions = () => {
  const qtyRef = useRef();
  const { dispatch, state } = useEditServiceContext();
  const {
    currentEditingService,
    completedSteps,
    vehicleAttributes: vehicleAttrFilter,
    rawOperationDetails
  } = state;
  const [qtyState, setQtyState] = useState("1");
  const { laborApps, selectableVehicleAttributes } = rawOperationDetails;
  const [options, setOptions] = useState([]);
  const [laborAppDone, setLaborAppDone] = useState({});
  const [isDone, setIsDone] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [currentLaborApps, setCurrentLaborApps] = useState([]);

  const [expandedStatus, setExpandedStatus] = useState({
    disabled: true,
    defaultExpanded: false
  });

  // Filters the labor apps with the selected vehicle attributes
  const filterIfExist = laborAttr => {
    let validAttr = true;
    const keySet = Object.keys(vehicleAttrFilter);
    Object.entries(laborAttr).forEach(([key]) => {
      if (keySet.includes(key)) {
        if (
          vehicleAttrFilter[key] !== "idk" &&
          vehicleAttrFilter[key] !== laborAttr[key]
        ) {
          validAttr = false;
        }
      }
    });
    return validAttr;
  };
  // This method maps the available service options to the dropdown options
  const mapToDropDown = (laborApp, index) => {
    const selection = options;
    selection.push({
      value: index,
      label: laborApp.displayName,
      name: laborApp.displayName,
      notes: laborApp.notes.join(", "),
      laborHour: laborApp.laborHours,
      ...(laborApp.vehicleAttributes !== null && {
        vehicleAttr: laborApp.vehicleAttributes
      })
    });
    setOptions(selection);
  };

  useEffect(() => {
    const emptyState = [];
    setOptions(emptyState);
    setLaborAppDone({});
    if (
      selectableVehicleAttributes === null ||
      selectableVehicleAttributes.length <= 0
    ) {
      laborApps.forEach((laborApp, index) => {
        mapToDropDown(laborApp, index);
      });
      setCurrentLaborApps(laborApps);
    } else if (
      completedSteps.vehicleAttr &&
      selectableVehicleAttributes.length > 0
    ) {
      laborApps.forEach((laborApp, index) => {
        if (
          laborApp.vehicleAttributes === null ||
          state.vehicleAttributes === null
        ) {
          mapToDropDown(laborApp, index);
        } else if (
          laborApp.vehicleAttributes !== null &&
          filterIfExist(laborApp.vehicleAttributes)
        ) {
          mapToDropDown(laborApp, index);
        }
      });
      setCurrentLaborApps(laborApps);
    }
    if (options.length === 1) {
      setLaborAppDone(laborAppDone => ({
        ...laborAppDone,
        ...laborApps[0]
      }));
      setSelectedIndex(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [completedSteps.vehicleAttr, selectableVehicleAttributes]);
  const onInputChange = cxEvent => {
    parseInt(cxEvent.target.value, 10) >= 1 &&
      setQtyState(cxEvent.target.value);
  };
  const onBlurQty = () => {
    const changed = true;
    const quantity = parseInt(qtyState, 10);
    // TODO - Edit Global service case - we disable service options fields; we should do null check for selectedIndex
    const hourVal = isNull(selectedIndex)
      ? 0
      : currentLaborApps?.[selectedIndex].laborHours;
    const lbrHour = quantity * hourVal;
    setLaborAppDone(laborAppDone => ({
      ...laborAppDone,
      laborHours: lbrHour
    }));
    dispatch({
      type: Actions.SET_CHANGED,
      payload: {
        field: "laborTime",
        value: changed
      }
    });
  };
  useEffect(() => {
    if (!completedSteps.vehicleAttr) {
      setExpandedStatus({ disabled: true, expanded: false });
    } else {
      setExpandedStatus({ expanded: true });
    }
  }, [completedSteps, setExpandedStatus]);
  useEffect(() => {
    if (expandedStatus.expanded) {
      setExpandedStatus({ disabled: false, defaultExpanded: true });
    }
  }, [expandedStatus.expanded, setExpandedStatus]);
  useEffect(() => {
    if (!expandedStatus.expanded && completedSteps.serviceOptions) {
      setExpandedStatus({ defaultExpanded: false });
    }
  }, [completedSteps, expandedStatus.expanded, setExpandedStatus]);
  const handleDoneClick = () => {
    if (!isEmptyObject(laborAppDone)) {
      dispatch({
        type: Actions.SET_LABOR_APP,
        payload: { ...laborAppDone }
      });
    } else {
      const labor = currentLaborApps[0];
      setLaborAppDone(laborAppDone => ({
        ...laborAppDone,
        ...currentLaborApps[0]
      }));
      dispatch({
        type: Actions.SET_LABOR_APP,
        payload: { ...labor }
      });
      dispatch({
        type: Actions.SET_CHANGED,
        payload: {
          field: "laborTime",
          value: true
        }
      });
    }
    setExpandedStatus({ expanded: false });
    dispatch({
      type: Actions.SET_CHANGED,
      payload: {
        field: "laborApp",
        value: true
      }
    });
    dispatch({
      type: Actions.SET_STEP_COMPLETED,
      payload: { step: "serviceOptions", completed: true }
    });
    dispatch({
      type: Actions.SET_ERRORS,
      payload: { field: "serviceOptions", value: false }
    });
    setIsDone(true);
  };
  // handle selection of service options
  const handleSelect = cxEvent => {
    dispatch({
      type: Actions.SET_STEP_COMPLETED,
      payload: { step: "serviceOptions", completed: false }
    });
    // this clears laborApp context attr, so we can detect it was changed
    dispatch({
      type: Actions.SET_LABOR_APP,
      payload: {}
    });
    setLaborAppDone({});
    if (!isEmpty(cxEvent.target.value) && cxEvent.target.value.length > 0) {
      setLaborAppDone(laborAppDone => ({
        ...laborAppDone,
        ...currentLaborApps[cxEvent.target.value[0].value]
      }));
      setSelectedIndex(cxEvent.target.value[0].value);
    }
    setIsDone(false);
  };

  const customSelectableItem = option => {
    const { name, laborHour, vehicleAttr, notes } = option;

    return (
      <div className="options-wrapper">
        <div className="top-line">
          <strong>
            <span className="d-block">{name}</span>
          </strong>
          <div>
            <span>Labor hours:</span>

            <span className="vehicleAttr">{laborHour}</span>
          </div>
        </div>
        {vehicleAttr
          ? Object.entries(vehicleAttr).map(key => {
              return (
                <div key={key}>
                  <span>{key[0]}: </span>
                  <strong>
                    <span className="vehicleAttr">{key[1]}</span>
                  </strong>
                </div>
              );
            })
          : null}
        <div className="notes customSelectable-bottomLine">
          <span className="d-block">{notes}</span>
        </div>
      </div>
    );
  };

  /** With custom selectable item */
  /* eslint-disable react/no-multi-comp */
  const searchableSelectCustomSelectableItem = () => {
    return (
      <SearchableSelect
        autoComplete="service-options"
        className={`serviceOptionsInputSectiondrop-down ${
          options.length > 1 ? "" : "disabled-select"
        }`}
        customSelectableItem={customSelectableItem}
        htmlId="searchableSelectCustomSelectableItem"
        label="Service options"
        name="searchableSelectCustomSelectableItem"
        value={options.length === 1 ? options : null}
        onChange={handleSelect}
        options={options}
        enableMultiSelect={false}
        disabled={options.length > 1 ? false : true}
      />
    );
  };

  useEffect(() => {
    if (!isEmpty(currentEditingService)) {
      if (!isEmpty(state.laborApp)) {
        setLaborAppDone({ ...state.laborApp });
      }
      setIsDone(true);
    }
  }, [currentEditingService]);

  return (
    <AccordionGroup.Container htmlId="serviceOptionsComponent">
      <AccordionGroup.Item
        htmlId="serviceOptions"
        key="2"
        header={
          <div className="service-options-header">
            <div className="service-options-wrapper">
              <span>Service options</span>
              {laborAppDone &&
              laborAppDone.displayName &&
              completedSteps.serviceOptions ? (
                <div className="service-options-badges">
                  <Badge
                    key="service-option"
                    htmlId="serviceOption"
                    className="service-options-badge"
                  >
                    {laborAppDone.displayName}{" "}
                  </Badge>
                  <Badge
                    key="service-option-qty"
                    htmlId="serviceOption-qty"
                    className="service-options-badge"
                  >
                    {qtyState}{" "}
                  </Badge>
                </div>
              ) : null}
            </div>
            {isDone && completedSteps.serviceOptions ? (
              <div id="submoduleCompleted" className="step-completed">
                <CompleteIcon id="serviceOptionsCompleted" />
              </div>
            ) : null}
          </div>
        }
        onToggle={() => {}}
        {...expandedStatus}
        disabled={!isEmpty(currentEditingService)}
      >
        <div className="serviceOptions">
          <div className="serviceOptionsInputSection">
            {searchableSelectCustomSelectableItem()}
            {!isEmptyObject(laborAppDone) ? (
              <NumericInput
                htmlId="numericInputDefault"
                label="Qty"
                name="name"
                className="serviceOptionsInputSectionQty"
                ref={qtyRef}
                onChange={onInputChange}
                minValue={1}
                autoFocus={!isEmpty(currentEditingService) ? false : true}
                onBlur={onBlurQty}
                value={qtyState}
                maxLength={4}
                allowDecimal={false}
              />
            ) : null}
          </div>
          <div className="serviceOptionsControl">
            <Button
              htmlId="doneServiceOptions"
              buttonStyle="secondary"
              disabled={isEmptyObject(laborAppDone) ? true : false}
              onClick={handleDoneClick}
            >
              Done
            </Button>
          </div>
        </div>
      </AccordionGroup.Item>
    </AccordionGroup.Container>
  );
};

ServiceOptions.defaultProps = {};
ServiceOptions.propTypes = {};
