import React, { useContext, useEffect, useRef, useState } from "react";
import { toast } from "@cx/ui/Toast";
import {
  useNewQuoteContext,
  Actions as newQuoteActions
} from "../../../state/NewQuoteContext";
import { AppContext } from "../../../state/app-context";
import useComponentDidMount from "../../../hooks/useComponentDidMount";
import axiosService from "../../../api/xmmAxios";
import isEmpty from "lodash/isEmpty";
import PriceAdjustmentModal from "../../repair-order/components/price-adjustment-modal.component";
import {
  usePriceAdjustmentContext,
  PriceAdjustmentProvider,
  Actions
} from "../../../state/price-adjustment.context";
import * as subletService from "../../repair-order/components/sublet/sublet.service";
import { LINE_SUBLET } from "../../repair-order/constants/adjustment.constant";
import { loadQuote } from "../services/quote-api.service";
import { showToastWithUndo } from "../utils/quote-util";

const PriceAdjustmentComponent = () => {
  const appContext = useContext(AppContext);
  const {
    user: { quoteUserId },
    dealerProperties
  } = appContext;
  const {
    blockUntilCompleted,
    state: newQuoteState,
    dispatch: dispatchToNewQuote
  } = useNewQuoteContext();
  const {
    priceAdjustmentType,
    priceAdjustmentItem,
    vehicle,
    quoteSummary,
    currentEditingService,
    userPermissions,
    showPageMask,
    debugMode
  } = newQuoteState;
  const { state, dispatch } = usePriceAdjustmentContext();
  const { initialized } = state;
  const [isLoaded, setIsLoaded] = useState(false);

  const isMounted = useRef(false);
  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  useComponentDidMount(() => {
    axiosService.setupAxios(appContext);
    // @note: this block will trigger when quote service is edited
    if (!isEmpty(currentEditingService)) {
      console.log(
        "Price Adj Component Didmount",
        quoteUserId,
        vehicle,
        userPermissions,
        currentEditingService,
        debugMode
      );
      dispatch({
        type: Actions.INITIALIZE,
        payload: {
          currentEditingService,
          quoteSummary
        }
      });
      console.log(
        "After context initialized",
        currentEditingService,
        initialized
      );
    }
  });

  useEffect(() => {
    if (isLoaded) {
      // set actionType to null when data is saved
      dispatchToNewQuote({
        type: newQuoteActions.SET_PRICE_ADJUSTMENT_TYPE,
        payload: null
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoaded]);

  const updateQuoteContext = quoteObject => {
    if (!isEmpty(quoteObject)) {
      dispatchToNewQuote({
        type: newQuoteActions.UPDATE_QUOTE,
        payload: quoteObject
      });
      setIsLoaded(true);
    }
  };

  const saveSublet = async modalPayload => {
    const response = await subletService.saveSublet(
      appContext,
      quoteSummary,
      currentEditingService,
      modalPayload
    );
    // This has to be queued, otherwise it can sometimes trigger the following:
    // "Warning: Can't perform a React state update on an unmounted component."
    setTimeout(() => {
      updateQuoteContext(response);
    }, 0);
  };

  const removeSublet = async () => {
    const sublet =
      dealerProperties?.CSR_SUBLET_FORM_ENABLED === "Y"
        ? priceAdjustmentItem
        : currentEditingService.sublets[0];
    const subletResponse = await subletService.removeSublet(
      appContext,
      quoteSummary,
      currentEditingService,
      sublet.subletId
    );
    // The "remove sublet" API call does not return the full updated quote, so we need to fetch it separately.
    if (!isEmpty(subletResponse)) {
      const response = await loadQuote({
        confirmationId: quoteSummary.confirmationId,
        localeStrings: appContext.localeStrings,
        dealerCode: appContext.dealer.dealerCode,
        appContext
      });
      updateQuoteContext(response);
      const restoreSublet = async () => {
        // Rebuild the removed sublet object, but with its PK ID removed,
        // otherwise the backend would try to update the one we just deleted.
        const payload = {
          ...sublet,
          subletId: null
        };
        await blockUntilCompleted(async () => {
          await saveSublet(payload);
          toast.success(
            `The sublet has been restored on line ${currentEditingService.serviceLineNumber}.`
          );
        });
      };
      showToastWithUndo({
        message: `The sublet has been removed from line ${currentEditingService.serviceLineNumber}.`,
        undoAction: restoreSublet
      });
    }
  };

  // remove event from modal
  const handleRemoveAction = async () => {
    await blockUntilCompleted(async () => {
      switch (priceAdjustmentType) {
        case LINE_SUBLET:
          await removeSublet();
          break;
        default:
          console.error(
            `priceAdjustmentType {priceAdjustmentType} not accounted for`
          );
      }
    });

    if (isMounted.current) {
      setIsLoaded(true);
    }
  };

  // save event from modal
  const handleSaveAction = async modalPayload => {
    console.log("save action triggered", modalPayload);

    await blockUntilCompleted(async () => {
      switch (priceAdjustmentType) {
        case LINE_SUBLET:
          await saveSublet(modalPayload);
          toast.success(
            `The sublet has been saved on line ${currentEditingService.serviceLineNumber}.`
          );
          break;
        default:
          console.error(
            `priceAdjustmentType {priceAdjustmentType} not accounted for`
          );
      }
    });

    if (isMounted.current) {
      setIsLoaded(true);
    }
  };

  // close event from modal
  const handleCloseAction = () => {
    console.log("close action triggered");
    setIsLoaded(true);
  };

  // TODO: API call to update service state as completed by technician
  const serviceCompleted =
    priceAdjustmentType === "WORK_FINISHED" ? true : false;
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {!showPageMask && !serviceCompleted ? (
        <PriceAdjustmentModal
          quoteSummary={quoteSummary}
          service={currentEditingService}
          currentModalItem={priceAdjustmentItem}
          currentModalType={priceAdjustmentType}
          callbackCloseAction={handleCloseAction}
          callbackRemoveAction={handleRemoveAction}
          callbackSaveAction={handleSaveAction}
        />
      ) : null}
    </>
  );
};

const PriceAdjustmentWrapper = props => {
  return (
    <PriceAdjustmentProvider>
      <PriceAdjustmentComponent {...props} />
    </PriceAdjustmentProvider>
  );
};

export default PriceAdjustmentWrapper;
