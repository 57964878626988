import COLORS from "./colors.constants";

export const RO_STATUSES = {
  WITH_ADVISOR: {
    value: "WITH_ADVISOR",
    displayText: "With advisor",
    cxColor: "gray",
    cssClass: "with-advisor",
    cssColor: COLORS.Titanium400
  },
  IN_PROCESS: {
    value: "IN_PROCESS",
    displayText: "In process",
    cxColor: "gray",
    cssClass: "in-process",
    cssColor: COLORS.Titanium400
  },
  WORK_FINISHED: {
    value: "WORK_FINISHED",
    displayText: "Work finished",
    cxColor: "green",
    cssClass: "work-finished",
    cssColor: COLORS.Krypton200
  },
  PRE_INVOICE: {
    value: "PRE_INVOICE",
    displayText: "Pre invoice",
    cssClass: "pre-invoice",
    cssColor: COLORS.Krypton400
  },
  FINALIZED: {
    value: "FINALIZED",
    displayText: "Finalized",
    cxColor: "purple",
    cssClass: "finalized",
    cssColor: COLORS.Haze200
  }
};

export const TECH_STATUSES = {
  ASSIGNED: {
    value: "ASSIGNED",
    displayText: "Assigned",
    cxColor: "green",
    cssClass: "assigned",
    cssColor: COLORS.Krypton200
  },
  UNASSIGNED: {
    value: "UNASSIGNED",
    displayText: "Unassigned",
    cxColor: "gray",
    cssClass: "unassigned",
    cssColor: COLORS.Titanium400
  }
};

export const PAY_TYPE_GROUPS = [
  { value: "C", displayText: "Customer", payerLabel: "Customer pay" },
  { value: "W", displayText: "Warranty", payerLabel: "Warranty" },
  { value: "I", displayText: "Internal", payerLabel: "Internal" },
  {
    value: "S",
    displayText: "Service Contract",
    payerLabel: "Service Contract"
  }
];

export const PAYMENT_METHODS = [
  { value: "CREDIT_CARD", displayText: "Credit card" },
  { value: "CASH", displayText: "Cash" },
  { value: "CHECK", displayText: "Check" },
  { value: "CHARGE", displayText: "Charge account" }
];

export const PAYMENT_TYPES = {
  CREDIT_CARD: "CREDIT_CARD",
  CASH: "CASH",
  CHECK: "CHECK",
  CHARGE: "CHARGE",
  DEALER_POLICY: "DEALER",
  SPLIT: "SPLIT"
};

export const PAYER_TYPES = {
  C: "C",
  I: "I",
  W: "W",
  S: "S"
};

export const GOOD = "GOOD";
export const ON_HOLD = "ON HOLD";
