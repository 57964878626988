import { toast } from "@cx/ui/Toast";
import { makeSecureRestApi } from "../api/xmmAxios";
import { isTrue } from "../utils/value.util";

const isDealerTireEnabled = async dealerCode => {
  const restUrl = `/dealer/dealerproperty/${dealerCode}/property/DEALER_TIRE_ENABLED`;
  return new Promise((resolve, reject) => {
    makeSecureRestApi(
      {
        url: restUrl,
        method: "get",
        data: {}
      },
      response => {
        let isEnabled = false;
        if (response && response.data) {
          isEnabled = isTrue(response.data);
        }
        resolve(isEnabled);
      },
      error => {
        reject(error);
      },
      "Unable to retrieve dealer property."
    );
  });
};

// Todo: create a method for handling global properties calls instead of doing it individually
const getCreatePoEnabled = async dealerCode => {
  const restUrl = `/dealer/dealerproperty/${dealerCode}/property/CREATE_PO_ENABLED`;
  return new Promise((resolve, reject) => {
    makeSecureRestApi(
      {
        url: restUrl,
        method: "get",
        data: {}
      },
      response => {
        let isEnabled = false;
        if (response?.success) {
          isEnabled = isTrue(response.success);
        }
        resolve(isEnabled);
      },
      error => {
        reject(error);
      },
      "Unable to retrieve dealer property."
    );
  });
};
const getChargeAccountInfo = (dealerCode, commonConsumerId) => {
  return new Promise((resolve, reject) => {
    makeSecureRestApi(
      {
        url: `csr/accounting/dealers/id/${dealerCode}/charge-accounts/id/${commonConsumerId}`,
        method: "get"
      },
      response => {
        resolve(response);
      },
      error => {
        if (
          window.location.href.includes("localhost") &&
          window.location.href.includes("&chargeAccount={")
        ) {
          // Since this API call fails when testing on localhost, this gives us a way to fake the response.
          // Sample usage: &chargeAccount={"isInGoodStanding":true,"availableCredit":{"amount":984}}
          const json = new URLSearchParams(window.location.search).get(
            "chargeAccount"
          );
          try {
            resolve(JSON.parse(json));
          } catch (e) {
            toast.error(
              "Failed to parse chargeAccount query param JSON. " +
                "See browser console for details."
            );
            reject(e);
          }
        } else {
          reject(error);
        }
      }
    );
  });
};

// @note - call and load synonyms in AppContext
const loadSynonyms = (dealerCode, localeStrings) => {
  const restUrl = `/quote/services/${dealerCode}/categorySynonyms`;
  return new Promise((resolve, reject) => {
    try {
      makeSecureRestApi(
        {
          url: restUrl,
          method: "get"
        },
        response => {
          const items = response.items || [];
          const dataList = items.map(category => {
            return {
              ...category,
              categoryGroupId: category.categoryGroupId || "",
              categoryGroupName: category.categoryGroupName || ""
            };
          });
          resolve({ items: dataList });
        },
        error => {
          const msg = error["message"]
            ? error.message
            : localeStrings["sq.errors.api_failure_msg"];
          console.error(msg);
          reject({
            items: []
          });
        },
        "Unable to retrieve category aliases."
      );
    } catch (error) {
      return { items: [] };
    }
  });
};
// read t_setting table data and set in App context
const loadSettingEnumForQuoting = () => {
  const restEndPoint = `/settings`;

  return new Promise((resolve, reject) => {
    makeSecureRestApi(
      {
        url: restEndPoint,
        method: "get",
        data: {}
      },
      response => {
        resolve(response);
      },
      error => {
        reject(error);
      },
      "Unable to retrieve application settings."
    );
  });
};
// read t_dealer_setting table data and use in App context
const loadQuoteDealerSettings = dealerCode => {
  const restEndPoint = `/settings/${dealerCode}`;

  return new Promise((resolve, reject) => {
    makeSecureRestApi(
      {
        url: restEndPoint,
        method: "get",
        data: {}
      },
      response => {
        resolve(response);
      },
      error => {
        reject(error);
      },
      "Unable to retrieve dealer settings."
    );
  });
};
// rest API returns t_setting table data at App level
const loadDefaultSettings = () => {
  const restEndPoint = `/settings`;

  return new Promise((resolve, reject) => {
    makeSecureRestApi(
      {
        url: restEndPoint,
        method: "get",
        data: {}
      },
      response => {
        resolve(response);
      },
      error => {
        reject(error);
      },
      "Unable to retrieve default settings."
    );
  });
};

const loadDealerProperties = dealerCode => {
  const restEndPoint = `/dealer/${dealerCode}/properties`;
  return new Promise((resolve, reject) => {
    makeSecureRestApi(
      {
        url: restEndPoint,
        method: "get",
        data: {}
      },
      response => {
        // Test hook to override dealer properties for testing.
        // Example: Adding the following to the URL:
        // &TEST_HOOK_OVERRIDE_DEALER_PROPERTY_USE_SPLIT_PAYMENT_FEATURE=Y
        // will set dealerProperties.USE_SPLIT_PAYMENT_FEATURE to "Y".
        // (Just in the current app state, not in Exeter.)
        const isNonProd =
          document.location.hostname === "localhost" ||
          document.location.hostname.includes("xtimeappsnp.xtime.com");
        const dealerPropertyOverridePrefix =
          "TEST_HOOK_OVERRIDE_DEALER_PROPERTY_";
        if (
          isNonProd &&
          document.location.search.includes(dealerPropertyOverridePrefix)
        ) {
          const queryParams = new URLSearchParams(window.location.search);
          for (const [key, value] of queryParams.entries()) {
            if (key.startsWith(dealerPropertyOverridePrefix)) {
              const propertyName = key.replace(
                dealerPropertyOverridePrefix,
                ""
              );
              response.properties[propertyName] = value;
            }
          }
        }
        // end test hook

        resolve(response);
      },
      error => {
        reject(error);
      },
      "Unable to retrieve properties for dealer."
    );
  });
};
const loadDealerCompanies = dealerCode => {
  const restEndPoint = `/dealer/listOfCompaniesForDealer/dealerCode/${dealerCode}`;
  return new Promise((resolve, reject) => {
    makeSecureRestApi(
      {
        url: restEndPoint,
        method: "get",
        data: {}
      },
      response => {
        resolve(response);
      },
      error => {
        reject(error);
      },
      "Unable to retrieve companies for dealer."
    );
  });
};
export default {
  isDealerTireEnabled,
  getCreatePoEnabled,
  getChargeAccountInfo,
  loadSynonyms,
  loadDefaultSettings,
  loadSettingEnumForQuoting,
  loadQuoteDealerSettings,
  loadDealerProperties,
  loadDealerCompanies
};
