import React from "react";
import PropTypes from "prop-types";
import { ButtonToolbar } from "react-bootstrap";
import ModalDialog from "@cx/ui/ModalDialog";
import themePrefixer from "@cx/ui/themePrefixer";
import Button from "@cx/ui/Button";
import classNames from "classnames";

const RemovePartModalConfirmation = ({ show, setShow, title, message }) => {
  const handleConfirm = () => setShow(false);

  return (
    <ModalDialog
      htmlId="removePartModalConfirmation"
      className="core-return-modal"
      show={show}
      backdrop="static"
      header={
        <h4 className={classNames(`${themePrefixer()}modal-title`)}>{title}</h4>
      }
      footer={
        <div className="lookup-modal-footer">
          <ButtonToolbar>
            <Button
              htmlId="removePartSaveBtn"
              type="button"
              buttonStyle="primary"
              onClick={handleConfirm}
            >
              OK
            </Button>
          </ButtonToolbar>
        </div>
      }
      onHide={handleConfirm}
    >
      <div>{message}</div>
    </ModalDialog>
  );
};

RemovePartModalConfirmation.propTypes = {
  show: PropTypes.bool.isRequired,
  setShow: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired
};

export default RemovePartModalConfirmation;
