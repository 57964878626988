import React from "react";
import Tabs from "@cx/ui/Tabs";
import Tab from "@cx/ui/Tab";
import PastQuotesGrid from "../past-quotes-grid.component";
import ArchiveRoHistory from "./archive-ro-history.component";
import "./repair-orders-tabs.scss";

function RepairOrdersTabs() {
  return (
    <div className="repair-orders-tabs repair-order-tabs--justify-right">
      <h1 className="repair-orders-tabs-header">Repair Orders</h1>
      <Tabs htmlId="repairOrdersTabs">
        <Tab label="Active" htmlId="repairOrdersTabsActive">
          <PastQuotesGrid />
        </Tab>
        <Tab label="Archived" htmlId="repairOrdersTabsArchived">
          <ArchiveRoHistory />
        </Tab>
      </Tabs>
    </div>
  );
}

export default RepairOrdersTabs;
