import React from "react";
import PropTypes from "prop-types";

import ConfirmPopup from "./ConfirmPopup";
import { AppContext } from "../../../state/app-context";
import quoteStatus from "../../../constants/quote-status.constants";
import { useNewQuoteContext } from "../../../state/NewQuoteContext";

const DiscardQuoteModal = props => {
  const { onProceed, onCancel, showModal } = props;
  const { localeStrings } = React.useContext(AppContext);
  const { state } = useNewQuoteContext();

  const currentQuoteStatus = state?.quoteSummary?.quoteStatus || "";
  const modalMessage =
    currentQuoteStatus === quoteStatus.PROGRESS
      ? localeStrings["sq.newquote.leaving_newquote_modal.in_progress_message"]
      : localeStrings["sq.newquote.leaving_newquote_modal.started_message"];

  const handleOkClick = () => {
    onProceed();
  };

  const handleConfirmPopupCancelClick = () => {
    onCancel();
  };

  return (
    <ConfirmPopup
      title={
        localeStrings["sq.newquote.location_bar.leaving_newquote_modal_title"]
      }
      message={modalMessage}
      show={showModal}
      okText={localeStrings["sq.common.proceed_button"]}
      cancelText={localeStrings["sq.common.cancel_button"]}
      okAction={handleOkClick}
      cancelAction={handleConfirmPopupCancelClick}
      hideCancel={false}
      hideOk={false}
      buttonStyle="primary"
    />
  );
};

DiscardQuoteModal.propTypes = {
  // events
  onCancel: PropTypes.func.isRequired,
  onProceed: PropTypes.func.isRequired,
  // data
  showModal: PropTypes.bool
};

DiscardQuoteModal.defaultProps = {
  // events
  onCancel: () => {},
  onProceed: () => {},
  // data
  showModal: false
};

export default DiscardQuoteModal;
