import Col from "@cx/ui/Col";
import PriceInput from "@cx/ui/PriceInput";
import Row from "@cx/ui/Row";
import SearchableSelect from "@cx/ui/SearchableSelect";
import React from "react";
import "./csr.scss";

const WarrantyExpenseCommonFieldComponent = ({
  expense,
  updateExpenseCommonFieldData
}) => {
  const coverageOptions = [
    {
      value: "CM",
      label: "CM"
    },
    {
      value: "CP",
      label: "CP"
    },
    {
      value: "DI",
      label: "DI"
    },
    {
      value: "FG",
      label: "FG"
    },
    {
      value: "FW",
      label: "FW"
    },
    {
      value: "PD",
      label: "PD"
    },
    {
      value: "PJ",
      label: "PJ"
    },
    {
      value: "RC",
      label: "RC"
    },
    {
      value: "SC",
      label: "SC"
    },
    {
      value: "TP",
      label: "TP"
    },
    {
      value: "TR",
      label: "TR"
    }
  ];

  const lineTypeOptions = [
    {
      value: "CM",
      label: "CM - Campaign"
    },
    {
      value: "CP",
      label: "CP - Customer Pay"
    },
    {
      value: "DI",
      label: "DI - Dealer Internal"
    },
    {
      value: "FG",
      label: "FG - Factory Goodwill"
    },
    {
      value: "FW",
      label: "FW - Factory Warranty"
    },
    {
      value: "PD",
      label: "PD - Pre-delivery Inspection"
    },
    {
      value: "PJ",
      label: "PJ - Project"
    },
    {
      value: "RC",
      label: "RC - Reconditioned"
    },
    {
      value: "SC",
      label: "SC - Service Contract"
    },
    {
      value: "TP",
      label: "TP- Third Party"
    },
    {
      value: "TR",
      label: "TR - Transportation"
    }
  ];
  const onSelect = cxEvent => {
    switch (cxEvent.target.name) {
      case "lineType":
        updateExpenseCommonFieldData({
          ...expense,
          lineType: cxEvent?.target?.value[0]?.value
        });
        break;
      case "coverage":
        updateExpenseCommonFieldData({
          ...expense,
          coverageAmount:
            cxEvent?.target?.value !== "" ? cxEvent?.target?.value : null
        });
        break;
      case "coverage1Code":
        updateExpenseCommonFieldData({
          ...expense,
          coverageOne: {
            ...expense.coverageOne,
            coverageCode: cxEvent?.target?.value[0]?.value
          }
        });
        break;
      case "coverage1Amount":
        updateExpenseCommonFieldData({
          ...expense,
          coverageOne: {
            ...expense.coverageOne,
            coverageAmount:
              cxEvent?.target?.value !== "" ? cxEvent?.target?.value : null
          }
        });
        break;
      case "coverage2Code":
        updateExpenseCommonFieldData({
          ...expense,
          coverageTwo: {
            ...expense.coverageTwo,
            coverageCode: cxEvent?.target?.value[0]?.value
          }
        });
        break;
      case "coverage2Amount":
        updateExpenseCommonFieldData({
          ...expense,
          coverageTwo: {
            ...expense.coverageTwo,
            coverageAmount:
              cxEvent?.target?.value !== "" ? cxEvent?.target?.value : null
          }
        });
        break;
      case "coverage3Code":
        updateExpenseCommonFieldData({
          ...expense,
          coverageThree: {
            ...expense.coverageThree,
            coverageCode: cxEvent?.target?.value[0]?.value
          }
        });
        break;
      case "coverage3Amount":
        updateExpenseCommonFieldData({
          ...expense,
          coverageThree: {
            ...expense.coverageThree,
            coverageAmount:
              cxEvent?.target?.value !== "" ? cxEvent?.target?.value : null
          }
        });
        break;
      default:
        null;
    }
  };
  return (
    <Row
      style={{
        display: "flex"
      }}
    >
      <Col className="warranty-expense-common-line-type">
        <SearchableSelect
          htmlId="lineTypeSelect"
          label="Line type"
          name="lineType"
          onChange={onSelect}
          options={lineTypeOptions}
          enableMultiSelect={false}
          value={expense?.lineType || ""}
        />
      </Col>
      <Col className="warranty-expense-common-coverage-input-col">
        <PriceInput
          htmlId="priceInputDefault"
          className="warranty-expense-price-input"
          label="Req amount"
          name="coverage"
          onChange={onSelect}
          value={
            (expense?.coverageAmount && expense?.coverageAmount + "") || ""
          }
        />
      </Col>
      <Col className="warranty-expense-common-coverage">
        <span className="warranty-expense-common-coverage-code-text">
          Coverage code 1
        </span>
        <div className="warranty-expense-common-coverage-div">
          <SearchableSelect
            htmlId="coverage1Select"
            name="coverage1Code"
            onChange={onSelect}
            options={coverageOptions}
            enableMultiSelect={false}
            value={expense?.coverageOne?.coverageCode || ""}
          />
          <PriceInput
            htmlId="coverage1Input"
            label=""
            style={{
              marginRight: "5px"
            }}
            className="warranty-expense-price-input"
            name="coverage1Amount"
            onChange={onSelect}
            value={
              (expense?.coverageOne?.coverageAmount &&
                expense?.coverageOne?.coverageAmount + "") ||
              ""
            }
          />
        </div>
      </Col>
      <Col className="warranty-expense-common-coverage">
        <span className="warranty-expense-common-coverage-code-text">
          Coverage code 2
        </span>
        <div className="warranty-expense-common-coverage-div">
          <SearchableSelect
            htmlId="coverage2Select"
            name="coverage2Code"
            onChange={onSelect}
            options={coverageOptions}
            enableMultiSelect={false}
            value={expense?.coverageTwo?.coverageCode || ""}
          />
          <PriceInput
            htmlId="coverage2Input"
            label=""
            className="warranty-expense-price-input"
            name="coverage2Amount"
            onChange={onSelect}
            value={
              (expense?.coverageTwo?.coverageAmount &&
                expense?.coverageTwo?.coverageAmount + "") ||
              ""
            }
          />
        </div>
      </Col>
      <Col className="warranty-expense-common-coverage">
        <span className="warranty-expense-common-coverage-code-text">
          Coverage code 3
        </span>
        <div className="warranty-expense-common-coverage-div">
          <SearchableSelect
            htmlId="coverage3Select"
            name="coverage3Code"
            onChange={onSelect}
            options={coverageOptions}
            enableMultiSelect={false}
            value={expense?.coverageThree?.coverageCode || ""}
          />
          <PriceInput
            htmlId="coverage3Input"
            label=""
            className="warranty-expense-price-input"
            name="coverage3Amount"
            onChange={onSelect}
            value={
              (expense?.coverageThree?.coverageAmount &&
                expense?.coverageThree?.coverageAmount + "") ||
              ""
            }
          />
        </div>
      </Col>
    </Row>
  );
};

export default WarrantyExpenseCommonFieldComponent;
