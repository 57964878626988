import React, { useState } from "react";
import IconArrowUpward from "@cx/ui/Icons/IconArrowUpward";
import IconMaximize from "@cx/ui/Icons/IconMaximize";
import IconMinimize from "@cx/ui/Icons/IconMinimize";
import ModalDialog from "@cx/ui/ModalDialog";
import TextArea from "@cx/ui/TextArea";
import "./csr.scss";
import {
  useEditServiceContext,
  Actions
} from "../../state/edit-service.context";

const ServiceCorrection = ({ serviceData }) => {
  const { dispatch, state } = useEditServiceContext();
  const [isModalExpand, setIsModalExpand] = useState(false);
  const [correction, setCorrection] = useState(
    state?.service?.correction || ""
  );
  const [correctionCharCount, setCorrectionCharCount] = useState(
    state?.service?.correction?.length || 0
  );

  const onCorrectionIconClick = () => {
    setIsModalExpand(!isModalExpand);
  };

  const onChangeInput = event => {
    setCorrection(event.target.value);
    setCorrectionCharCount(event.target.value.length);
    dispatch({
      type: Actions.SET_CORRECTION,
      payload: event.target.value
    });
  };

  const onBlurCorrection = event => {
    dispatch({
      type: Actions.SET_CORRECTION,
      payload: event.target.value
    });
    const changed =
      state?.service?.correction !==
      (event.target.value !== "" ? event.target.value : null);
    dispatch({
      type: Actions.SET_CHANGED,
      payload: {
        field: "correction",
        value: changed
      }
    });
  };
  return (
    <>
      <div className="service-csr-nput-label-content">
        <div>
          <span className="label-color" data-testid="correction-label">
            Correction
          </span>
          {state?.service.payTypeCode === "W" ? (
            <span className="icon-required-csr" data-testid="icon-required-csr">
              &#42;
            </span>
          ) : null}
          <IconMaximize
            htmlId="IconCorrection"
            className="icon-maximize-minimize"
            data-testid="icon-maximize-correction"
            onClick={onCorrectionIconClick}
            hidden={isModalExpand}
          />
        </div>
        <span
          className="character-string label-color"
          data-testid="correction-character-count"
        >
          Character count: {correctionCharCount} /2000
        </span>
      </div>
      <TextArea
        htmlId="CorrectionInput"
        className="input-box"
        label="Correction"
        data-testid="correction-input-box"
        displayLabel={false}
        name="correction"
        value={correction}
        required={state?.service.payTypeCode === "W"}
        rows={4}
        onBlur={onBlurCorrection}
        onChange={onChangeInput}
        maxLength={2000}
      />
      <ModalDialog
        htmlId="ModalDialog"
        className="sample-modal-dialog half-width"
        show={isModalExpand}
        displayCloseButton={false}
        backdrop="static"
      >
        <div>
          <span className="back-nav-label">
            <IconArrowUpward
              htmlId="backArrowIcon"
              isActive={false}
              className="back-arrow"
              data-testid="back-Arrow-Icon-correction"
              onClick={() => {
                setIsModalExpand(!isModalExpand);
              }}
            />
            Edit Service: {serviceData?.serviceName}
          </span>
          <div className="service-csr-nput-label-content">
            <div>
              <span className="label-color">Correction</span>
              {state?.service.payTypeCode === "W" ? (
                <span className="icon-required-csr">&#42;</span>
              ) : null}
            </div>
            <div>
              <span className="label-color">
                Character count: {correctionCharCount} /2000
              </span>
              <IconMinimize
                className="icon-maximize-minimize"
                htmlId="IconMaxExample"
                data-testid="icon-minimize-correction"
                onClick={onCorrectionIconClick}
                hidden={!isModalExpand}
              />
            </div>
          </div>
          <TextArea
            htmlId="CorrectionInput"
            className="expanded-text-area input-box"
            displayLabel={false}
            label="Correction"
            name="correction"
            value={correction}
            required={state?.service.payTypeCode === "W"}
            onBlur={onBlurCorrection}
            maxLength={2000}
            onChange={onChangeInput}
          />
        </div>
      </ModalDialog>
    </>
  );
};

export default ServiceCorrection;
