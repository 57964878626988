import { makeSecureRestApi } from "../../../api/xmmAxios";

export const loadSupportedMakes = (dealer, localeStrings) => {
  const { dealerCode } = dealer;
  const restUrl = `/vehicle/${dealerCode}/makes/`;
  return new Promise((resolve, reject) => {
    makeSecureRestApi(
      {
        url: restUrl,
        method: "get",
        data: {}
      },
      response => {
        const makeList = [];
        const makeVariantMap = {};
        if (response) {
          const datalist = response.data;
          if (datalist && datalist.length > 0) {
            datalist.forEach(m => {
              if (m.make === "ANY") {
                // TODO-raja: ask product before adding ANY make
              } else if (m.isStaging === 0) {
                m.label = m.make;
                m.value = m.make;
                makeList.push(m);
                // TODO-raja: staged makes are ignored
              } else if (m.isStaging === 1) {
                m.label = m.make;
                m.value = m.make;
                m.dealerCode = m.useDealerCode;
              }
              makeVariantMap[m.make] = m;
            });
          }
        }
        resolve({ makeList, makeVariantMap });
      },
      error => {
        const msg = error["message"]
          ? error.message
          : localeStrings["sq.errors.api_failure_msg"];
        console.error(msg);
        reject({ makeList: [], makeVariantMap: {} });
      },
      "Unable to retrieve vehicle makes."
    );
  });
};
