import * as Yup from "yup";

const vehicle = Yup.object().shape({
  metaVehicleId: Yup.number(),
  vehicleId: Yup.number(),
  avgMileage: Yup.number().required().default(0),
  color: Yup.string()
    .required()
    .default("")
    .transform(value => (value === null ? "" : value)),
  default: Yup.boolean().required().default(true),
  description: Yup.string()
    .required()
    .default("")
    .transform(value => (value === null ? "" : value)),
  driveType: Yup.string()
    .required()
    .default("")
    .transform(value => (value === null ? "" : value)),
  engineSize: Yup.string()
    .required()
    .default("")
    .transform(value => (value === null ? "" : value)),
  engineType: Yup.string()
    .required()
    .default("")
    .transform(value => (value === null ? "" : value)),
  licenseNumber: Yup.string()
    .required()
    .default("")
    .transform(value => (value === null ? "" : value)),
  lifecycleState: Yup.string()
    .required()
    .default("")
    .transform(value => (value === null ? "" : value)),
  make: Yup.string()
    .required()
    .default("")
    .transform(value => (value === null ? "" : value)),
  metaVehicleDetailId: Yup.number().required().default(0),
  mileage: Yup.number().required().default(0),
  mileageDate: Yup.string()
    .required()
    .default("")
    .transform(value => (value === null ? "" : value)),
  model: Yup.string()
    .required()
    .default("")
    .transform(value => (value === null ? "" : value)),
  name: Yup.string()
    .required()
    .default("")
    .transform(value => (value === null ? "" : value)),
  orgUnitId: Yup.number().required().default(0),
  personId: Yup.number().required().default(0),
  roHistoryCnt: Yup.number().required().default(0),
  transmissionType: Yup.string()
    .required()
    .default("")
    .transform(value => (value === null ? "" : value)),
  trim: Yup.string()
    .required()
    .default("")
    .transform(value => (value === null ? "" : value)),
  variant: Yup.string()
    .required()
    .default("")
    .transform(value => (value === null ? "" : value)),
  vin: Yup.string()
    .required()
    .default("")
    .transform(value => (value === null ? "" : value)),
  year: Yup.number().required().default(0),
  userRegDate: Yup.string()
});

// This vehicle schema used to validate vehicle object while create/update quote
const quoteVehicleSchema = Yup.object().shape({
  quoteVehicleId: Yup.number(),
  metaVehicleId: Yup.number().default(0),
  metaVehicleDetailId: Yup.number().default(0),
  vehicleId: Yup.number().default(0),
  avgMileage: Yup.number().default(0),
  make: Yup.string()
    .required()
    .default("")
    .transform(value => (value === null ? "" : value)),
  model: Yup.string()
    .required()
    .default("")
    .transform(value => (value === null ? "" : value)),
  year: Yup.number().required().default(0),
  transmissionType: Yup.string()
    .required()
    .default("")
    .transform(value => (value === null ? "" : value)),
  trim: Yup.string()
    .required()
    .default("")
    .transform(value => (value === null ? "" : value)),
  driveType: Yup.string()
    .required()
    .default("")
    .transform(value => (value === null ? "" : value)),
  engineSize: Yup.string()
    .required()
    .default("")
    .transform(value => (value === null ? "" : value)),
  engineType: Yup.string()
    .required()
    .default("")
    .transform(value => (value === null ? "" : value)),
  lifecycleState: Yup.string()
    .default("")
    .transform(value => (value === null ? "" : value)),

  mileage: Yup.number().required().default(0),
  mileageDt: Yup.string()
    .required()
    .default("")
    .transform(value => (value === null ? "" : value)),
  schmea: Yup.string()
    .required()
    .default("")
    .transform(value => (value === null ? "" : value)),
  vin: Yup.string()
    .required()
    .default("")
    .transform(value => (value === null ? "" : value))
});
export default {
  vehicle,
  quoteVehicleSchema
};
