/* eslint-disable no-console */
import React, { Component } from "react";
import PropTypes from "prop-types";
import isEmpty from "lodash/isEmpty";
import IconMore from "@cx/ui/Icons/IconMore";
import Dropdown from "@cx/ui/Dropdown";
import { doesEmpty } from "../../../utils/object";
import { AppContext } from "../../../state/app-context";
import { extractAndFormatAttentionTags } from "../../utils/data-transformer";
import QuoteStatusConstant from "../../../constants/quote-status.constants";
import { emailValidate } from "../../../utils/field.validator";
import { assistanceRequestStatus } from "../../../constants/assiatance-request.constants";
import { hasNotesAccess } from "../../page-wrapper/utils/quote-util";
import * as gtmEvent from "../../utils/gtag/gtag-event.util";
import { getNodeJsPrintUrl } from "../../../api/xmmAxios";
import { updateOpenerWindow } from "../../../utils/window.util.js";

export default class QuoteActionMenu extends Component {
  static contextType = AppContext;
  constructor(props, context) {
    super(props, context);
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.state = this.createInitialState(props, context);
  }

  createInitialState(props, context) {
    const highlightAllOnFocus = true;
    const { data } = props;
    const { workflowAttentionTag, quoteStatus, confirmationId } = data;
    const attentionTagsArray =
      extractAndFormatAttentionTags(workflowAttentionTag);
    const { dealerSettings } = context;
    const { quoteAssistance, serviceEmail, partsEmail } = dealerSettings;
    const serviceEmailIsValid =
      emailValidate(serviceEmail) !== "Invalid email address";
    const partsEmailIsValid =
      emailValidate(partsEmail) !== "Invalid email address";

    return {
      quoteRecord: data,
      menuItemsUp: false,
      highlightAllOnFocus,
      attentionTags: attentionTagsArray,
      quoteStatus,
      confirmationId,
      quoteAssistance,
      serviceEmailIsValid,
      partsEmailIsValid
    };
  }

  onClick = event => {
    console.log("ActionMenu.onClick", event);
  };

  getValue() {
    return this.state.quoteRecord;
  }

  // @todo - testing
  // eslint-disable-next-line unused-imports/no-unused-vars
  setValue(newValue) {}

  isCancelBeforeStart() {
    return false;
  }

  isCancelAfterEnd() {
    return false;
  }
  shouldShowContentPanelUp = (parentId, childNode, panelHeight) => {
    const gridBoundingBox = document
      .querySelector(parentId)
      .getBoundingClientRect();
    const childBoundingBox = childNode.getBoundingClientRect();
    return childBoundingBox.bottom + panelHeight > gridBoundingBox.bottom;
  };

  afterGuiAttached() {
    if (this.state.highlightAllOnFocus) {
      const menuItemsUp = this.shouldShowContentPanelUp(
        "#grid-wrapper",
        this.wrapperRef,
        60
      );
      this.setState({
        highlightAllOnFocus: false,
        menuItemsUp
      });
    }
  }

  isPopup() {
    return true;
  }

  allowCopyAccess = (customerId, vehicleId, userPermissions) => {
    return (
      !doesEmpty(customerId) &&
      !doesEmpty(vehicleId) &&
      !doesEmpty(userPermissions) &&
      userPermissions.canCreateQuote
    );
  };

  allowSendEmailToCustomer = (customerId, email, mobilePhone, quoteStatus) => {
    const allowedQuoteStatuses = [
      QuoteStatusConstant.PROGRESS,
      QuoteStatusConstant.READY_TO_SEND,
      QuoteStatusConstant.CONVERTED_TO_APPOINTMENT,
      QuoteStatusConstant.SENT
    ];

    return (
      !doesEmpty(customerId) &&
      (!doesEmpty(email) || !doesEmpty(mobilePhone)) &&
      allowedQuoteStatuses.includes(quoteStatus)
    );
  };

  setWrapperRef = node => {
    this.wrapperRef = node;
  };

  viewQuotePdf = confirmationId => {
    const { dealer } = this.context;
    gtmEvent.trackGAEventWithParam("ga.newquote.view_pdf_click", {
      result: dealer.dealerCode
    });
    const nodeJsPrintUrl = getNodeJsPrintUrl();
    const pdfUrl = new URL("/svcQuote/pdf", nodeJsPrintUrl);
    pdfUrl.searchParams.append("quoteConfirmationId", confirmationId);
    pdfUrl.searchParams.append("dealerCode", dealer.dealerCode);
    window.open(pdfUrl.toString(), "_PreviewQuote");
  };

  renderButtons = () => {
    const { parentHandle } = this.props;
    const { quoteRecord, menuItemsUp } = this.state;
    const {
      copyActionHandler,
      deleteActionHandler,
      handleQuoteAssistance,
      openQuoteNotesModal,
      openSendEmailTextModal,
      openConfirmationAlertPopup,
      context
    } = parentHandle;
    const { localeStrings, userPermissions } = context;
    const { customerId, quoteStatus } = quoteRecord;
    const duplicateLabel = localeStrings["sq.pastquotes.duplicate_quote_lbl"];
    const seeQuotePdfLabel = localeStrings["sq.pastquotes.see_quote_pdf_lbl"];
    const deleteLabel = localeStrings["sq.pastquotes.delete_quote_lbl"];
    const requestServiceAssistanceLabel =
      localeStrings["sq.pastquotes.request_service_assistance_lbl"];
    const requestPartsAssistanceLabel =
      localeStrings["sq.pastquotes.request_parts_assistance_lbl"];
    const completeServiceAssistanceLabel =
      localeStrings["sq.pastquotes.complete_service_assistance_lbl"];
    const completePartsAssistanceLabel =
      localeStrings["sq.pastquotes.complete_parts_assistance_lbl"];
    const addNotesLabel = localeStrings["sq.pastquotes.add_notes"];
    const reviewNotesLabel = localeStrings["sq.pastquotes.review_notes"];
    // eslint-disable-next-line unused-imports/no-unused-vars
    const editLabel = localeStrings["sq.pastquotes.edit_quote_lbl"];
    const sendToCustomerLabel =
      localeStrings["sq.pastquotes.send_quote_to_customer_lbl"];
    const scheduleServiceAppointment =
      localeStrings[
        "sq.newquote.confirmation.schedule_service_appointment_action"
      ];
    let menuOptions = [];
    const partsEmailIsValid = this.state.partsEmailIsValid;
    const serviceEmailIsValid = this.state.serviceEmailIsValid;
    const notesAccess = hasNotesAccess(this.props.data);
    const isScheduledQuote =
      this.state.quoteStatus === QuoteStatusConstant.CONVERTED_TO_APPOINTMENT;
    const isExpiredQuote = quoteStatus === QuoteStatusConstant.EXPIRED;
    const isInProgress = quoteStatus === QuoteStatusConstant.PROGRESS;
    const isRequestedAssistance =
      quoteStatus === QuoteStatusConstant.REQUEST_ASSISTANCE;
    const isReady = quoteStatus === QuoteStatusConstant.READY_TO_SEND;
    // Request assistance related
    const serviceLabel = !this.state.attentionTags.includes("Service")
      ? requestServiceAssistanceLabel
      : completeServiceAssistanceLabel;
    const serviceValue = !this.state.attentionTags.includes("Service")
      ? "request-service-assistance"
      : "complete-service-assistance";
    const serviceRequestStatus = !this.state.attentionTags.includes("Service")
      ? assistanceRequestStatus.REQUESTED
      : assistanceRequestStatus.COMPLETED;
    const partsLabel = !this.state.attentionTags.includes("Parts")
      ? requestPartsAssistanceLabel
      : completePartsAssistanceLabel;
    const partsValue = !this.state.attentionTags.includes("Parts")
      ? "request-parts-assistance"
      : "complete-parts-assistance";
    const partsRequestStatus = !this.state.attentionTags.includes("Parts")
      ? assistanceRequestStatus.REQUESTED
      : assistanceRequestStatus.COMPLETED;
    const noQuoteServices = quoteRecord?.items === 0;
    const noNotes = isEmpty(this.props?.data?.notes);
    // skip-customer flow - disabled request menu items
    const requestActionDisabled =
      !customerId || noQuoteServices || isScheduledQuote;

    const handleLaunchScheduleApp = () => {
      if (
        this.state.quoteStatus ===
          QuoteStatusConstant.CONVERTED_TO_APPOINTMENT ||
        this.state.quoteStatus === QuoteStatusConstant.EXPIRED ||
        this.state.quoteRecord.appointmentCode ||
        userPermissions.canCreateReservationForConsumer === false
      ) {
        return;
      }
      const { confirmationId } = this.state.quoteRecord;
      const { dealer, loginURL } = this.context;
      const { webKey } = dealer;
      const scheduleAppURL = `${loginURL}/?w=${webKey}&act=mqa&qc=${confirmationId}`;
      gtmEvent.trackGAEventWithParam(
        "ga.newquote.schedule_service_appointment_click",
        {
          result: `${dealer.dealerCode} - ${confirmationId}`
        }
      );
      updateOpenerWindow(scheduleAppURL, "_Scheduling");
    };
    if (userPermissions && userPermissions.canEditQuote) {
      menuOptions = [
        /* {
            label: editLabel,
            value: "edit-quote",
            onSelect: () => {
              editActionHandler(quoteRecord);
              this.props.api.stopEditing();
            }
          },*/
        {
          label: deleteLabel,
          value: "delete-quote",
          onSelect: () => {
            deleteActionHandler(quoteRecord);
            this.props.api.stopEditing();
          },
          disabled: isScheduledQuote
        },
        {
          label: notesAccess && noNotes ? addNotesLabel : reviewNotesLabel,
          value: notesAccess && noNotes ? "add-notes" : "review-notes",
          onSelect: () => {
            openQuoteNotesModal(quoteRecord);
            this.props.api.stopEditing();
          },
          disabled: !notesAccess && noNotes
        },
        {
          label: duplicateLabel,
          value: "copy-quote",
          onSelect: () => {
            if (quoteRecord.quoteStatus === QuoteStatusConstant.SENT) {
              openConfirmationAlertPopup(
                quoteRecord,
                () => {
                  copyActionHandler(quoteRecord);
                  this.props.api.stopEditing();
                },
                true
              );
            } else {
              copyActionHandler(quoteRecord);
              this.props.api.stopEditing();
            }
          },
          disabled: isScheduledQuote
        },
        {
          label: seeQuotePdfLabel,
          value: "see-quote-pdf",
          onSelect: () => this.viewQuotePdf(this.props?.data?.confirmationId),
          disabled: isInProgress || isRequestedAssistance || isReady
        },
        {
          label: sendToCustomerLabel,
          value: "send-quote-to-customer",
          onSelect: () => {
            openSendEmailTextModal(quoteRecord);
            this.props.api.stopEditing();
          },
          disabled:
            isRequestedAssistance || isExpiredQuote || requestActionDisabled
        },
        {
          label: serviceLabel,
          value: serviceValue,
          disabled:
            requestActionDisabled || isExpiredQuote || !serviceEmailIsValid,
          onSelect: () => {
            if (quoteRecord.quoteStatus === QuoteStatusConstant.SENT) {
              openConfirmationAlertPopup(quoteRecord, () => {
                handleQuoteAssistance(
                  this.state.confirmationId,
                  serviceRequestStatus,
                  "service"
                );
                this.props.api.stopEditing();
              });
            } else {
              handleQuoteAssistance(
                this.state.confirmationId,
                serviceRequestStatus,
                "service"
              );
              this.props.api.stopEditing();
            }
          }
        },
        {
          label: partsLabel,
          value: partsValue,
          disabled:
            requestActionDisabled || isExpiredQuote || !partsEmailIsValid,
          onSelect: () => {
            if (quoteRecord.quoteStatus === QuoteStatusConstant.SENT) {
              openConfirmationAlertPopup(quoteRecord, () => {
                handleQuoteAssistance(
                  this.state.confirmationId,
                  partsRequestStatus,
                  "parts"
                );
                this.props.api.stopEditing();
              });
            } else {
              handleQuoteAssistance(
                this.state.confirmationId,
                partsRequestStatus,
                "parts"
              );
              this.props.api.stopEditing();
            }
          }
        },
        {
          label: scheduleServiceAppointment,
          value: "scheduleServiceAppointmentValue",
          disabled:
            isScheduledQuote ||
            isExpiredQuote ||
            isRequestedAssistance ||
            requestActionDisabled,
          onSelect: () => {
            handleLaunchScheduleApp();
          }
        }
      ];
    }

    return (
      <Dropdown
        icon={<IconMore isActive={true} />}
        htmlId="quoteActionBtn"
        name="quoteActionBtn"
        className="xmm-dotted-dropdown btn--icon"
        buttonStyle="link"
        displayCaret={false}
        size="small"
        dropup={menuItemsUp}
        defaultOpen={true}
        options={menuOptions}
        pullRight
      />
    );
  };
  render() {
    const buttonList = this.renderButtons();
    return <div ref={this.setWrapperRef}>{buttonList}</div>;
  }
}

QuoteActionMenu.propTypes = {
  data: PropTypes.object, // @note- this data is default prop passed in cellEditorParams
  parentHandle: PropTypes.object
};
