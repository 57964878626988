/* eslint-disable no-console */
import KeyCode from "./keyCodes";

export const KEY_ENTER = 13;
export const KEY_LEFT = 37;
export const KEY_UP = 38;
export const KEY_RIGHT = 39;
export const KEY_DOWN = 40;
/**
 *
 * applyCustomKeyNavigation
 * @param {object} params
 * @return {boolen}
 */
export function applyCustomKeyNavigation(params) {
  const { colDef, event, api, node, column } = params;
  const { shiftKey } = event;
  const keyPress = event.which;
  const suppress = keyPress === KEY_ENTER;
  // console.log("key navigation: ", params);
  // console.log("cell is editing: " + params.editing);
  // console.log("keyboard event:", params.event);
  if (suppress) {
    event.preventDefault();
    event.stopPropagation();
    // Enter key is pressed - key down or up based on Enter/Shirt-Enter
    const currentCell = { column, rowIndex: node.rowIndex };
    const arrowKey = shiftKey ? KEY_UP : KEY_DOWN;
    if (api.rowRenderer && api.rowRenderer.navigateToNextCell) {
      api.rowRenderer.navigateToNextCell(event, arrowKey, currentCell);
    }
    // Make the next cell editable if possible
    let rowIndex = node.rowIndex + (shiftKey ? -1 : 1);
    if (rowIndex < 0) {
      rowIndex = 0;
    }
    const colKey = colDef.field;
    setTimeout(() => {
      api.startEditingCell({
        rowIndex,
        colKey,
        keyPress
      });
    }, 0);
  }
  return suppress;
}

export function applyCustomTextAreaKeyNavigation(params) {
  const keyPress = params.event.which;
  return keyPress === KEY_ENTER;
}

// export function navigateToNextCell(params) {
//   const previousCell = params.previousCellPosition;
//   const suggestedNextCell = params.nextCellPosition;
//   switch (params.key) {
//     case KEY_ENTER: {
//       const nextRowIndex = previousCell.rowIndex + 1;
//       if (nextRowIndex < 0) {
//         return null;
//       } else {
//         return {
//           rowIndex: nextRowIndex,
//           column: previousCell.column,
//           floating: previousCell.floating
//         };
//       }
//     }
//     default:
//       break;
//   }
//   return suggestedNextCell;
// }

export function isCharNumeric(charStr) {
  return !!/\d/.test(charStr);
}

export function getCharCodeFromEvent(event) {
  // eslint-disable-next-line no-param-reassign
  event = event || window.event;
  return typeof event.which === "undefined" ? event.keyCode : event.which;
}

export function isKeyPressedNumeric(event) {
  const charCode = getCharCodeFromEvent(event);
  return (
    (charCode >= 48 && charCode <= 58) ||
    (charCode >= 96 && charCode <= 105) ||
    charCode === 110 ||
    charCode === 190
  );
  // const charStr = event.key ? event.key : String.fromCharCode(charCode);
  // return this.isCharNumeric(charStr);
}

export const isCopyAndPaste = event => {
  const evt = event || window.event; // IE support
  const c = evt.keyCode;
  const ctrlDown = evt.ctrlKey || evt.metaKey; // Mac support

  // Check for Alt+Gr (http://en.wikipedia.org/wiki/AltGr_key)
  // if (ctrlDown && evt.altKey) return true
  // Check for ctrl+c, v and x
  if (ctrlDown && (c === KeyCode.c || c === KeyCode.v || c === KeyCode.x)) {
    return true;
  }
  return false;
};

export const isNavKeyPressed = event => {
  const { keyCode } = event;
  return (
    [
      KeyCode.enter,
      KeyCode.tab,
      KeyCode.esc
      // KeyCode.leftArrow,
      // KeyCode.rightArrow
    ].indexOf(keyCode) !== -1
  );
};

export const isKeyPressedPrintable = event => {
  const { keyCode } = event;
  return (
    (keyCode > 47 && keyCode < 58) || // number keys
    keyCode === 32 ||
    keyCode === 13 || // spacebar & return key(s) (if you want to allow carriage returns)
    (keyCode > 64 && keyCode < 91) || // letter keys
    (keyCode > 95 && keyCode < 112) || // numpad keys
    (keyCode > 185 && keyCode < 193) || // ;=,-./` (in order)
    (keyCode > 218 && keyCode < 223)
  );
};

export function isEnter(event) {
  const { keyCode } = event;
  return KeyCode.enter === keyCode;
}

export function isLeftOrRight(event) {
  const { keyCode } = event;
  return [KeyCode.leftArrow, KeyCode.rightArrow].indexOf(keyCode) > -1;
}

export function isDeleteOrBackspace(event) {
  const { keyCode } = event;
  return [KeyCode.delete, KeyCode.backspace].indexOf(keyCode) > -1;
}
