import isEmpty from "lodash/isEmpty";
import { doesEmpty } from "./quote.util";

const sortServices = (a, b) => {
  if (!isEmpty(a) && !isEmpty(b)) {
    if (!doesEmpty(a.serviceName) && !doesEmpty(b.serviceName)) {
      if (a.serviceName < b.serviceName) {
        return -1;
      }
      if (a.serviceName > b.serviceName) {
        return 1;
      }
    }
  }
  return 0;
};
const filterByName = (searchTokens, resultset) => {
  let nameList = resultset;
  searchTokens.forEach(t => {
    const list1 = nameList.filter(item => {
      const serviceName = !item["serviceName"]
        ? ""
        : item["serviceName"].toString().toLowerCase();
      return serviceName.indexOf(t) !== -1;
    });
    // @note: replace results of each token as master list
    nameList = list1;
    console.log(searchTokens.toString(), list1.length);
  });
  console.log("service name results", nameList.length);
  return nameList;
};
const filterBySynonyms = (searchTokens, resultset) => {
  let synonymList = resultset;
  searchTokens.forEach(t => {
    const list2 = synonymList.filter(item => {
      const synonyms = !item["synonyms"]
        ? ""
        : item["synonyms"].toString().toLowerCase();
      return synonyms.indexOf(t) !== -1;
    });
    // @note: replace results of each token as master list
    synonymList = list2;
    console.log(searchTokens.toString(), list2.length);
  });
  console.log("synonyms results", synonymList.length);
  return synonymList;
};
// @placeholder - we return ANY of the “words” contained in either the Service Name or synonyms list
const filterAcrossServices = (searchTokens, services) => {
  if (isEmpty(services)) {
    return [];
  }
  let nameList = services;
  let synonymList = services;
  searchTokens.forEach(t => {
    nameList = nameList.filter(item => {
      const serviceName = !item["serviceName"]
        ? ""
        : item["serviceName"].toString().toLowerCase();
      return serviceName.indexOf(t) !== -1;
    });
    synonymList = synonymList.filter(item => {
      const synonyms = !item["synonyms"]
        ? ""
        : item["synonyms"].toString().toLowerCase();
      return synonyms.indexOf(t) !== -1;
    });
    console.log(searchTokens.toString(), nameList.length, synonymList.length);
  });
  const bothList = nameList.concat(synonymList);
  const setList = new Set(bothList);
  const filterList = [...setList];
  filterList.sort(sortServices);
  return filterList;
};

// @note: we should return ALL of the “words” must be contained in either the Service Name or synonyms list.
// @input words -  replace oil filter, cleaning replace engine, replace engine rpl, diag engine check
const customFilterByTokens = (searchTokens, services) => {
  if (isEmpty(services)) {
    return [];
  }
  const tokenOne = searchTokens.shift();
  const firstList = customHomeSearch([tokenOne], services);
  console.log("first token results for ", tokenOne, searchTokens, firstList);
  if (isEmpty(firstList)) {
    return [];
  }
  const searchList = [...firstList];
  const nameList = filterByName(searchTokens, searchList);
  const synonymList = filterBySynonyms(searchTokens, searchList);
  const bothList = nameList.concat(synonymList);
  const setList = new Set(bothList);
  const filterList = [...setList];
  filterList.sort(sortServices);
  return filterList;
};

// @note: we should return ANY combination of the “words” contained in either the Service Name or synonyms list.
const customHomeSearch = (searchTokens, services) => {
  let list = services;
  searchTokens.forEach(token => {
    if (list.length === 0) {
      return [];
    }
    const regex = /[()*-]/g;
    // Replace special chars [()*-] with ""
    const t = token.toLowerCase().replace(regex, "");
    list = list.filter(item => {
      const serviceName = !item["serviceName"]
        ? ""
        : item["serviceName"]
            .toString()
            .replace(/[()]/g, "")
            .toLowerCase()
            .split(" ");
      const synonyms = !item["synonyms"]
        ? []
        : item["synonyms"].toString().toLowerCase().split(",");
      const regexObj = new RegExp(`^${t}`, "i");
      const nameMatches = serviceName.some(
        name => name.indexOf(t) > -1 && regexObj.test(name)
      );
      const anySynonymMatches = isEmpty(synonyms)
        ? false
        : synonyms.some(
            synonym => synonym.indexOf(t) > -1 && regexObj.test(synonym)
          );
      const opCode = !item["opCode"]
        ? ""
        : item["opCode"].toString().toLowerCase();
      const opCodeMatches = opCode.indexOf(t) !== -1 && regexObj.test(opCode);
      return nameMatches || anySynonymMatches || opCodeMatches;
    });
  });

  list.sort(sortServices);
  return list;
};
export {
  sortServices,
  filterAcrossServices,
  customFilterByTokens,
  customHomeSearch,
  filterByName,
  filterBySynonyms
};
