/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from "react";
// import ServiceList from "../../components/service-list/ServiceList";
import "./TopServicesQuickFilterPage.scss";
import {
  useServiceSearchContext,
  Actions
} from "../../state/service-search.context";
import selectServiceFormat from "../../utils/select-service-format";
import EditTopServicePage from "./EditTopServicePage";
import EditDiagnosePage from "../diagnosis-quick-filter/EditDiagnosePage";
import isEmpty from "lodash/isEmpty";
import { GlobalOpsServiceType } from "../../constants/search.constants";
import serviceDataMapper from "../../utils/service-data-mapper";
import ServicesList from "../ServiceList/services-list.component";

const TopServicesQuickFilterPage = props => {
  const { EditServiceModule } = props;
  const {
    state,
    dispatch,
    ctxRequestOperationDetails,
    ctxOnEditService,
    ctxGtmEvent
  } = useServiceSearchContext();
  const { topServices, globalOperationDetails, appConfig, vehicle } = state;
  const [showEditCmp, setShowEditCmp] = useState(false);
  const [showEditDiag, setShowEditDiag] = useState(false);
  const [rawService, setRawService] = useState(null);
  const [formattedService, setFormattedService] = useState(null);
  const filteredTopServices = topServices.filter(service =>
    service.rawRecord.serviceKind === GlobalOpsServiceType.AUTORECALL ||
    service.rawRecord.serviceKind === GlobalOpsServiceType.DECLINED
      ? false
      : true
  );

  useEffect(() => {
    ctxOnEditService(showEditCmp || showEditDiag);
  }, [showEditCmp, showEditDiag]);

  const updateParentEditCatalog = useCallback(
    showEdit => {
      if (!showEdit) {
        setRawService(null);
        setFormattedService(null);
        dispatch({
          type: Actions.REMOVE_GLOBAL_OPERATION_DETAILS
        });
      }
      setShowEditCmp(showEdit);
    },
    [dispatch]
  );

  const updateParentDiagnosis = useCallback(
    showEdit => {
      if (!showEdit) {
        setRawService(null);
        setFormattedService(null);
        dispatch({
          type: Actions.REMOVE_GLOBAL_OPERATION_DETAILS
        });
      }
      setShowEditDiag(showEdit);
    },
    [dispatch]
  );

  const updateParentState = useCallback(
    (serviceType, showEdit) => {
      if (
        serviceType === GlobalOpsServiceType.GLOBALCATALOG ||
        serviceType === GlobalOpsServiceType.DEALERCATALOG
      ) {
        updateParentEditCatalog(showEdit);
      } else if (serviceType === GlobalOpsServiceType.DIAGNOSIS) {
        updateParentDiagnosis(showEdit);
      }
    },
    [updateParentEditCatalog, updateParentDiagnosis]
  );

  const updateParentService = useCallback(service => {
    if (!isEmpty(service)) {
      setFormattedService(service);
    }
  }, []);

  const editServiceClick = useCallback(
    (serviceType, service) => {
      updateParentService(service);
      updateParentState(serviceType, true);
      dispatch({
        type: Actions.SET_SEARCH_HEADER,
        payload: false
      });
    },
    [dispatch, updateParentState, updateParentService]
  );

  useEffect(() => {
    // We need this check because globalOperationDetails will not be available in context until ctxRequestOperationDetails is triggered
    // by handleServiceClick below. This setup requires service in local state, so we also need to check for it.
    if (!isEmpty(rawService) && !isEmpty(globalOperationDetails)) {
      const selectedService = selectServiceFormat.formatService(
        rawService,

        globalOperationDetails
      );
      if (!isEmpty(vehicle?.stockNumber)) {
        selectedService.defaultPayTypeCode = "I";
      }
      const { editModuleAccess } = appConfig;
      if (editModuleAccess) {
        const { rawRecord } = rawService;
        if (rawRecord.operationSource) {
          if (serviceDataMapper.isGlobalCatalog(rawService)) {
            editServiceClick(
              GlobalOpsServiceType.GLOBALCATALOG,
              selectedService
            );
          } else if (serviceDataMapper.isDiagnosisService(rawService)) {
            editServiceClick(GlobalOpsServiceType.DIAGNOSIS, selectedService);
          } else if (
            serviceDataMapper.isDealerPublishedMaintenance(rawService) ||
            serviceDataMapper.isDealerPublishedRepair(rawService)
          ) {
            editServiceClick(
              GlobalOpsServiceType.DEALERCATALOG,
              selectedService
            );
          } else {
            // * note: when this service updated in context, search modal will trigger event to forward service to quote summary page
            dispatch({
              type: Actions.SET_SELECTED_GLOBAL_OPS_SERVICE,
              payload: selectedService
            });
          }
        } else {
          dispatch({
            type: Actions.SET_SELECTED_ALACARTE_SERVICE,
            payload: selectedService
          });
        }
      } else {
        // @note: If parent application is not QUOTING, decline edit page access
        dispatch({
          type: Actions.SET_SELECTED_GLOBAL_OPS_SERVICE,
          payload: selectedService
        });
      }
    }
  }, [
    rawService,
    globalOperationDetails,
    dispatch,
    editServiceClick,
    appConfig,
    appConfig.editModuleAccess
  ]);

  // ?faq: Edit Pages Save click will dispatch selectedGlobalOpsService to Search Context then save Quote
  const handleServiceClick = async service => {
    // eslint-disable-next-line unused-imports/no-unused-vars
    const { appConfig } = state;
    ctxGtmEvent?.trackGAEventWithParam("ga.newquote.select_service_click", {
      result: service.operationSource,
      location: "topservices-quick-filter"
    });
    ctxRequestOperationDetails(service);
    setRawService(service);
    setFormattedService(null);
  };

  const editPage =
    showEditCmp && !isEmpty(formattedService) ? (
      <EditTopServicePage
        updateParentState={updateParentEditCatalog}
        service={formattedService}
        EditServiceModule={EditServiceModule}
      />
    ) : (
      ""
    );

  const editDiagPage =
    showEditDiag && !isEmpty(formattedService) ? (
      <EditDiagnosePage
        updateParentState={updateParentEditCatalog}
        service={formattedService}
        EditServiceModule={EditServiceModule}
      />
    ) : (
      ""
    );

  const parentCls =
    (showEditCmp || showEditDiag) && !isEmpty(formattedService)
      ? "hide-ele"
      : "empty-cls";

  const msgResults =
    filteredTopServices.length === 0 ? "No services found" : "";
  return (
    <>
      {/* <div className={parentCls}>
        <div className="search-no-results">{msgResults}</div>
        <div id="topServicesQuickFilterPageContainer">
          <ServiceList
            services={filteredTopServices}
            viewType="TOP_SERVICES"
            onSelectService={handleServiceClick}
          />
        </div>
      </div> */}
      <ServicesList
        parentCls={parentCls}
        msgResults={msgResults}
        services={filteredTopServices}
        viewType="TOP_SERVICES"
        onSelectService={handleServiceClick}
      />
      {editPage}
      {editDiagPage}
    </>
  );
};

export default TopServicesQuickFilterPage;
