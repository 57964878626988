/* This Component used for No rows found case only.
 */
import React from "react";
import PropTypes from "prop-types";
const CustomNoRowsOverlay = props => {
  const { noRowsMessage, apiStatus } = props;
  let content = null;
  // for any API failure, show this message
  if (apiStatus === "APIERROR") {
    content = (
      <div className="grid-api-error">
        <h4>Connection failure</h4>
        <span>{noRowsMessage}</span>
      </div>
    );
    // If there are no quotes matching the criteria
  } else if (apiStatus === "NODATA") {
    content = <h4 className="no-data-message">{noRowsMessage}</h4>;
  } else {
    content = (
      <div className="xmm-loader-msg">
        <i className="fa fa-frown-o" />
        <span> {noRowsMessage}</span>
      </div>
    );
  }

  return content;
};

export default CustomNoRowsOverlay;

CustomNoRowsOverlay.defaultProps = {
  noRowsMessage: "No quotes found.",
  apiStatus: "NODATA"
};
CustomNoRowsOverlay.propTypes = {
  noRowsMessage: PropTypes.string,
  apiStatus: PropTypes.string
};
