import { makeSecureRestApi } from "../../../api/xmmAxios";

export const getAdvisorTechnicianList = async (
  { dealerCode, schemaName },
  localeStrings
) => {
  const restUrl = `/user/dealer/${dealerCode}/listOfSpecializedUsers`;
  return new Promise((resolve, reject) => {
    makeSecureRestApi(
      {
        url: restUrl,
        method: "get",
        params: {
          schema: schemaName
        }
      },
      response => {
        resolve(response);
      },
      error => {
        const msg = error["message"]
          ? error.message
          : localeStrings["sq.errors.api_failure_msg"];
        console.error(msg);
        reject(error);
      },
      "Unable to retrieve employee lists."
    );
  });
};

export const getTransportationList = async ({ dealerCode }) => {
  const restUrl = `/transport/dealerCode/${dealerCode}/getTransportOptions`;
  return new Promise((resolve, reject) => {
    makeSecureRestApi(
      {
        url: restUrl,
        method: "get"
      },
      response => {
        resolve(response);
      },
      error => {
        const msg = error["message"] ? error.message : error.message;
        console.error(msg);
        reject(error);
      },
      "Unable to retrieve transportation options."
    );
  });
};
