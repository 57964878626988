/* eslint-disable react/prop-types */
import React, { useEffect, useState, useCallback } from "react";
import LoadingIndicator from "@cx/ui/LoadingIndicator";
import {
  useServiceSearchContext,
  Actions
} from "../../state/service-search.context";
import isEmpty from "lodash/isEmpty";
import dealerTireService from "./dealer-tire-service";
import {
  paramsToObject,
  getServiceUrl,
  dealerCodeToDealerId,
  getCacheToken,
  setCacheToken,
  dateToUtcFormat
} from "./dealer-tire-util";
import { EditDealerTirePage } from "./views/EditDealerTirePage";
import "./dealer-tire.scss";

const DealerTire = props => {
  const { state, dispatch, ctxRequestOperationDetails } =
    useServiceSearchContext();
  const { vehicle, dealerTireAuth, dealerTireParams } = state;
  const { isDealerTireEnabled, dealer, localeStrings, appEnv, locale } =
    dealerTireParams;
  const [dealerTireEnabled, setDealerTireEnabled] = useState(null);
  const [dealerTireUrl, setDealerTireUrl] = useState(null);
  const [requestComplete, setRequestComplete] = useState(false);
  const [authMsg, setAuthMsg] = useState(null);
  const [iframeStyle, setIframeStyle] = useState({
    display: "none"
  });
  const [errorMsg, setErrorMsg] = useState(null);
  const [dealerTireServices, setDealerTireServices] = useState(null);
  const [catalogService, setCatalogService] = useState(null);
  const [catalogError, setCatalogError] = useState(false);
  const renderDealerTire = useCallback(
    (dealerTireConfig, vehicleInfo) => {
      const { handle, secret } = dealerTireConfig;
      const { dealerCode } = dealer;
      const nonceDate = new Date();
      const eventName =
        "productCatalogByVehicle_complete_" + nonceDate.getTime();
      const localeCode = locale.replace("_", "-");
      const { styleUrl, apiUrl } = dealerTireParams;
      // const styleUrl = "https://x7ua9.xtime.com/servicetabweb/resources/css/dealerTire.css";
      // const returnUrl =  "https://servicequotingapi.qa6.xtimeappsnp.xtime.com/rest/dealertire/construct";
      const returnUrl = apiUrl + "/dealertire/construct";
      const width =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;
      const height =
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight;
      const frameHeight = Number(height - 200);

      const serviceUrl = getServiceUrl(
        appEnv,
        secret,
        {
          mode: "productCatalogByVehicle",
          dealer: dealerCodeToDealerId(dealerCode),
          vin: vehicleInfo.vin,
          year: vehicleInfo.year,
          make: vehicleInfo.make,
          model: vehicleInfo.model,
          trim: vehicleInfo.trim,
          language: localeCode,
          version: "2.0",
          theme: "PRODUCT_LIST_VIEW",
          canvas: width + "x" + frameHeight,
          style: styleUrl,
          options: "NO_CONTENT",
          // @note: This will be modified internally to add customParam as query string
          return: returnUrl,
          handle,
          nonce: dateToUtcFormat(nonceDate)
        },
        {
          eventName
        }
      );
      return serviceUrl;
    },
    [appEnv, dealer, dealerTireParams, locale]
  );

  const loadDealerTireBundle = useCallback(() => {
    const cacheKey = "dealertire-token";
    try {
      let dealerTireConfig = dealerTireAuth;
      const cached = getCacheToken(cacheKey);
      if (cached && new Date() < cached.expiration) {
        // console.log("DealerTire - read token from cache: ", cached);
        dealerTireConfig = cached;
      }
      let serviceUrl = null;
      const vehicleInfo = {
        year: "",
        make: "",
        model: "",
        trim: "",
        vin: ""
      };
      if (!isEmpty(vehicle)) {
        // @note: Since dealerTire has different YMM values, pass only Year when vin is missing
        if (!vehicle.vin) {
          vehicleInfo.year = vehicle.year;
          vehicleInfo.make = vehicle.make;
          vehicleInfo.model = vehicle.model;
        } else {
          vehicleInfo.vin = vehicle.vin;
        }
      }
      if (!isEmpty(dealerTireConfig)) {
        const { handle, secret, expiration } = dealerTireConfig;
        const cacheObject = {
          handle,
          secret,
          expiration: new Date(expiration)
        };
        setCacheToken(cacheKey, cacheObject);
        serviceUrl = renderDealerTire(dealerTireConfig, vehicleInfo);
        setDealerTireUrl(serviceUrl);
      } else {
        setAuthMsg(
          "An issue occurred while processing this request, please try again.  If the issue persists please contact your Xtime representative at +1-(888)-123-4567"
        );
      }
      return serviceUrl;
    } catch (error) {
      const msg = !error
        ? "Error occured while accessing Dealer Tire"
        : error.message;
      console.error(msg);
      setAuthMsg(
        "An issue occurred while processing Dealer Tire for this dealer."
      );
      return null;
    } finally {
      setAuthMsg(null);
    }
  }, [dealerTireAuth, renderDealerTire, vehicle]);

  const getDealerTireEnabled = useCallback(() => {
    let respUrl = null;
    try {
      if (!isDealerTireEnabled) {
        setAuthMsg("Dealer Tire not enabled for this dealer.");
        setRequestComplete(false);
      } else {
        respUrl = loadDealerTireBundle();
        if (respUrl) {
          setRequestComplete(true);
          setIframeStyle({
            border: "none",
            display: "block"
          });
        }
      }
      setDealerTireEnabled(isDealerTireEnabled);
      return respUrl;
    } catch (error) {
      const msg = error["message"]
        ? error.message
        : localeStrings[
            "sq.search.errors.read_service_quoting_enabled_property"
          ];
      console.error(msg);
      return respUrl;
    }
  }, [isDealerTireEnabled, loadDealerTireBundle, localeStrings]);

  useEffect(() => {
    if (!isEmpty(dealerTireAuth)) {
      getDealerTireEnabled();
    }
  }, [dealerTireAuth, getDealerTireEnabled]);

  const dealerTireProductCatalogResponse = useCallback(
    (respPostParams, respGetParams) => {
      if (respGetParams.mode === "productCatalogByVehicle") {
        dealerTireProductCatalogSuccess(respPostParams);
        return;
      }
      if (respGetParams.mode === "PERMISSION")
        setErrorMsg(
          "PERMISSION ERROR: The dealership doesn't have permission to expose their data using this interface. Please contact Xtime support at: (866) 984-6355 and provide them with the error message."
        );
      else if (respGetParams.mode === "SIGNATURE")
        setErrorMsg(
          "SIGNATURE ERROR: The signature provided on the request is invalid. Please contact Xtime support at: (866) 984-6355 and provide them with the error message."
        );
      else if (respGetParams.mode === "TIMESTAMP")
        setErrorMsg(
          "TIMESTAMP ERROR: The timestamp has an error. Please contact Xtime support at: (866) 984-6355 and provide them with the error message."
        );
      else if (respGetParams.mode === "HANDLE")
        setErrorMsg(
          "HANDLE ERROR: The Service Provider is unable to locate the handle. Please contact Xtime support at: (866) 984-6355 and provide them with the error message."
        );
      else if (respGetParams.mode === "DEALER")
        setErrorMsg(
          "DEALER ERROR: The Dealer ID can't be found. Please contact Xtime support at: (866) 984-6355 and provide them with the error message."
        );
      else if (respGetParams.mode === "NORESULTS")
        setErrorMsg(
          "NO RESULTS ERROR: The dealership doesn't list products available for the vehicle. See your Parts Department or DealerTire for Info."
        );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  // @todo: event handler fired when dealertire "Add to quote" clicked, response returned as html document
  const onDealerTireEvent = useCallback(
    (queryString, rawPost) => {
      const respGetParams = paramsToObject(queryString.replace(/\+/g, "%20"));
      const respPostParams = paramsToObject(rawPost.replace(/\+/g, "%20"));
      destroyIframe();
      dealerTireProductCatalogResponse(respPostParams, respGetParams);
    },
    [dealerTireProductCatalogResponse]
  );

  // @note: This window.event will trigger many times, so add event.data check
  const triggerDealerTireEvent = useCallback(
    event => {
      if (event && event.data) {
        if (event.data.ST7_event === "dealer_tire_add") {
          onDealerTireEvent(event.data.get, event.data.post);
        }
      }
    },
    [onDealerTireEvent]
  );
  // @note - window event postMessage will read dealerTire html response
  useEffect(() => {
    window.addEventListener("message", triggerDealerTireEvent, false);
    return () => {
      window.removeEventListener("message", triggerDealerTireEvent, false);
    };
  }, [triggerDealerTireEvent]);

  const dealerTireProductCatalogSuccess = params => {
    const parameters = JSON.parse(JSON.stringify(params));
    const tireService = dealerTireService.processDealerTireServices(parameters);
    // console.log("DEALER TIRE Record ", parameters, tireService);
    setDealerTireServices(tireService);
    findCatalogServiceFromContext(tireService);
  };

  const destroyIframe = () => {
    // Important: set null to destroy iframe here
    setDealerTireUrl(null);
    setIframeStyle({
      display: "none"
    });
  };
  // @method - find catalog service which matches with selected dealer tire count
  const findCatalogServiceFromContext = tireService => {
    if (!isEmpty(tireService)) {
      const { allOperations } = state;
      const service = dealerTireService.filterServiceByTag(
        allOperations,
        tireService
      );
      // @note: if catalog service found match with tags, then callback open track api
      if (!isEmpty(service)) {
        // @workaround - add dealerTire record as parts to catalog service here
        const { parts } = tireService;
        service.parts = !parts ? [] : parts;
        getCatalogServiceDetails(service);
        setCatalogError(false);
      } else {
        setCatalogError(true);
        setCatalogService(null);
        launchDealerTireIframe(true);
      }
    }
  };

  // @note - handler to get global ops details for matched DealerTire service
  const getCatalogServiceDetails = async service => {
    ctxRequestOperationDetails(service);
    setCatalogService(service);
    launchDealerTireIframe(true);
  };

  const launchDealerTireIframe = showIframe => {
    if (!showIframe) {
      setCatalogService(null);
      if (!isEmpty(dealerTireAuth)) {
        getDealerTireEnabled();
      }
      dispatch({
        type: Actions.REMOVE_GLOBAL_OPERATION_DETAILS
      });
    }
  };
  const resetDealerTireIframe = () => {
    setCatalogError(false);
    launchDealerTireIframe(false);
  };

  const resetDealerTireService = useCallback(() => {
    setCatalogService(null);
    dispatch({
      type: Actions.REMOVE_GLOBAL_OPERATION_DETAILS
    });
  }, [dispatch]);

  const loadmask = (
    <div style={{ margin: 25 }}>
      <LoadingIndicator htmlId="LoadingMask" size="large" />
    </div>
  );
  const widget = !dealerTireUrl ? null : (
    <iframe
      title="dealerTire"
      id="dealerTireIframe"
      style={iframeStyle}
      src={dealerTireUrl}
      width="100%"
      height="100%"
    />
  );

  const serviceNotFound = !catalogError ? null : (
    <>
      <div className="dt-flex-messanger">
        <span className="dt-msg">
          We are unable to find a Tire Replacement service for the quantity of
          tires selected.Please ensure that the “Factory” Tire services (at
          least 1-4) are enabled for the appropriate vehicles.This is necessary
          for the Tire integration to work in all applications, so that we can
          map the quantity of tires selected with the appropriate service in the
          catalog.
        </span>
      </div>
      <div>
        <span className="ss-link-h5" onClick={resetDealerTireIframe}>
          Try again
        </span>
      </div>
    </>
  );
  const renderEditPage = !isEmpty(catalogService) ? (
    <EditDealerTirePage
      dealerTireRecord={dealerTireServices}
      launchDealerTire={launchDealerTireIframe}
      catalogService={catalogService}
      EditServiceModule={props.EditServiceModule}
      updateParentState={resetDealerTireService}
    />
  ) : (
    serviceNotFound
  );
  const widgetCls = !dealerTireUrl ? "hide-iframe" : "show-iframe";
  let content = loadmask;
  content =
    !dealerTireEnabled && !requestComplete ? (
      !authMsg ? (
        loadmask
      ) : (
        <span className="ss-msg"> {authMsg} </span>
      )
    ) : (
      <div className={widgetCls}>{widget}</div>
    );
  return (
    <div className="dealer-tire-container">
      <span className="ss-error-msg">{errorMsg}</span>
      {content}
      {renderEditPage}
    </div>
  );
};

export default DealerTire;
