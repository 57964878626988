import React, { useState } from "react";
import PropTypes from "prop-types";
import "./change-mileage-Out.scss";
import NumericInput from "@cx/ui/NumericInput";
const ChangeMileageOut = props => {
  const [mileageOut, setMileageOut] = useState(
    props?.quoteSummary?.mileageOut || ""
  );

  const onInputChange = cxEvent => {
    setMileageOut(cxEvent.target.value);
    props.onUpdate({
      mileageOut: cxEvent.target.value
    });
  };

  const handleEnterPress = event => {
    /* istanbul ignore else*/
    if (event.key === "Enter") {
      // Call the callback function passed from parent on press of enter
      props.onEnterKeyPressOnUpdate();
    }
  };
  const checkMileageOutLessThanMileageIn = () => {
    const check =
      parseFloat(mileageOut) < parseFloat(props?.quoteSummary?.mileageIn);
    return check;
  };
  return (
    <div className="change-mileageOut-container">
      <NumericInput
        htmlId="mileageOutInput"
        name="mileageOutInput"
        label="Mileage out"
        onChange={onInputChange}
        value={mileageOut ? mileageOut + "" : ""}
        maxLength={6}
        onKeyDown={handleEnterPress}
        required={true}
        error={
          checkMileageOutLessThanMileageIn()
            ? "Mileage out cannot be less than mileage in."
            : ""
        }
      />
    </div>
  );
};

export default ChangeMileageOut;

ChangeMileageOut.propTypes = {
  quoteSummary: PropTypes.object,
  onUpdate: PropTypes.func,
  onEnterKeyPressOnUpdate: PropTypes.func
};

ChangeMileageOut.defaultProps = {
  quoteSummary: {},
  onUpdate: () => {},
  onEnterKeyPressOnUpdate: () => {}
};
