/* eslint-disable no-console */
import React from "react";
import PropTypes from "prop-types";
import Button from "@cx/ui/Button";
import TextInput from "@cx/ui/TextInput";
import IconSearch from "@cx/ui/Icons/IconSearch";
import { AutoSizer, List } from "react-virtualized";

import { queryStringToJSON, toQueryString } from "./dealer-selector.util";
import { AppContext } from "../../state/app-context";
import isEmpty from "lodash/isEmpty";
import keyCodes from "./keycodes.constants";
import * as gtmEvent from "../utils/gtag/gtag-event.util";
import "./dealer-selector.scss";
import { ErrorNotification } from "../ui/reusable/errormodule";

class DealerList extends React.Component {
  static contextType = AppContext;
  constructor(props, context) {
    super(props, context);
    this.changeSearchText = this.changeSearchText.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.filterSearch = this.filterSearch.bind(this);
    this.itemSelector = this.itemSelector.bind(this);
    this.updateWebKey = this.updateWebKey.bind(this);
    this.clearSearch = this.clearSearch.bind(this);
    this.renderDealers = this.renderDealers.bind(this);
    // Read values from App context
    const { scopedDealers } = context;
    let hasContext = false;
    hasContext = !isEmpty(scopedDealers) ? true : false;
    this.state = {
      color: "",
      dealers: hasContext ? scopedDealers : [],
      filteredData: hasContext ? scopedDealers : [],
      totalCount: hasContext ? scopedDealers.length : 0,
      searchTerm: "",
      recordId: null,
      dealerName: null,
      record: null,
      user: context.user,
      appSource: context.appSource,
      appEnv: context.appEnv
    };
  }

  componentDidMount() {
    console.log("dealerselector -> CmpDidMount()", this.context);
  }

  changeSearchText = cxEvent => {
    const searchTerm = cxEvent.target.value;
    const { dealers } = this.state;
    if (!searchTerm) {
      this.setState({
        searchTerm,
        filteredData: dealers
      });
    } else {
      this.setState(
        {
          searchTerm
        },
        () => {
          this.filterSearch();
        }
      );
    }
  };
  onKeyDown(event) {
    if (event.keyCode === keyCodes.enter) {
      this.filterSearch();
      event.preventDefault();
      return false;
    }
  }

  // Filter logic to match substrings of dealer Name or dealer Code using searchTokens[]
  filterByTokens = (searchTokens, dealers) => {
    let list = dealers;
    searchTokens.forEach(t => {
      if (list.length === 0) {
        return [];
      }
      list = list.filter(item => {
        const dealerName = !item["dealerName"]
          ? ""
          : item["dealerName"].toString().toLowerCase();
        const dealerCode = !item["dealerCode"]
          ? ""
          : item["dealerCode"].toString().toLowerCase();

        return dealerName.indexOf(t) !== -1 || dealerCode.indexOf(t) > -1;
      });
    });
    return list;
  };
  filterSearch = () => {
    const { dealers, searchTerm } = this.state;
    if (searchTerm !== "") {
      const searchValue = searchTerm.toString().toLowerCase();
      const tokens = searchValue.split(" ");
      const filteredData = this.filterByTokens(tokens, dealers);
      this.setState({ filteredData });
    } else {
      this.setState(() => ({
        filteredData: dealers
      }));
    }
  };
  clearSearch = () => {
    const { dealers } = this.state;
    this.setState({
      searchTerm: "",
      filteredData: dealers
    });
  };
  updateWebKey = record => {
    console.log("updateWebKey", this.context);
    const { name, orgKey, webKey, dmsType } = record;
    const dealer = {
      webKey,
      orgKey,
      dealerCode: orgKey,
      dealerName: name,
      dmsType
    };
    this.context.setWebKey(record.webKey);
    this.context.setDealer(dealer);
  };
  itemSelector = record => {
    if (record) {
      gtmEvent.trackGAEventWithParam("ga.dashboard.change_dealership_click", {
        result: record.dealerCode
      });
      this.setState({
        recordId: record.orgKey,
        record,
        dealerName: record.name
      });
      // update App context with selected webKey
      const href = window.location.href;
      const search = window.location.search;
      const origin = window.location.origin;
      let appUrl = "";
      if (!search) {
        appUrl = origin + window.location.pathname + "?webKey=" + record.webKey;
      } else {
        const baseParams = queryStringToJSON();
        baseParams.webKey = record.webKey;
        // if (baseParams.hasOwnProperty("webKey")) {} // no need to check
        const queryStr = toQueryString(baseParams);
        appUrl = origin + window.location.pathname + "?" + queryStr;
      }
      console.log("Curernt URL", href, "refine URL", appUrl);
      this.updateWebKey(record);
      // TODO: reload page with new webkey selection
      window.location.replace(appUrl);
    }
  };

  renderDealers({ index, style }) {
    const dealers = this.state.filteredData;
    const dealer = dealers[index];
    const { webKey } = this.context;
    const classes =
      webKey === dealer.webKey ? "dealer-box dealer-active" : "dealer-box";
    const squareStyle = {
      color: this.state.color
    };
    const city = !dealer.city ? "" : dealer.city.toLowerCase();
    const location = !dealer.districtName
      ? ""
      : dealer.districtName.toLowerCase();
    return (
      <div
        key={dealer.webKey}
        className={classes}
        id={dealer.webKey}
        style={style}
        onClick={() => this.itemSelector(dealer, this)}
      >
        <div className="dealer-name">
          <span style={squareStyle}>{dealer.dealerName}</span>
        </div>

        <div className="dealer-info">
          <div className="dealer-code">{dealer.dealerCode}</div>
          <div className="dealer-city">{city + ", " + location}</div>
        </div>
      </div>
    );
  }

  render() {
    const { searchTerm, filteredData } = this.state;
    const { localeStrings, isDebug } = this.context;
    const countMsg = !filteredData
      ? localeStrings["sq.common.loading"]
      : filteredData.length +
        " " +
        localeStrings["sq.common.results_found_lbl"];
    const clearBtn = !searchTerm ? null : (
      <Button
        buttonStyle="link"
        className="search-clear-btn"
        htmlId="clearBtn"
        onClick={this.clearSearch}
      >
        Clear
      </Button>
    );
    return (
      <>
        <div className="dealer-search-head">
          {isDebug ? <ErrorNotification /> : null}
          <h3>{localeStrings["sq.dealerselector.title"]}</h3>
          <TextInput
            htmlId="dealerSearchInput"
            label={localeStrings["sq.dealerselector.search_lbl"]}
            name="searchTerm"
            onChange={e => this.changeSearchText(e)}
            onKeyDown={e => this.onKeyDown(e)}
            value={searchTerm}
            autoComplete="off"
            appendChild={clearBtn}
            className="dealer-search-input"
            prependChild={
              <IconSearch
                htmlId="dealerSearchIcon"
                className="dealer-search-icon"
              />
            }
          />
          <div className="sq-msg">{countMsg}</div>
        </div>
        <div className="dealer-content">
          <AutoSizer>
            {({ height, width }) => (
              <List
                height={height}
                rowCount={filteredData.length}
                rowHeight={75}
                rowRenderer={this.renderDealers}
                width={width}
              />
            )}
          </AutoSizer>
        </div>
      </>
    );
  }
}

export default DealerList;

DealerList.propTypes = {
  hide: PropTypes.func,
  show: PropTypes.bool
};

DealerList.defaultProps = {
  show: false
};
