import React from "react";
import PropTypes from "prop-types";
import IconMore from "@cx/ui/Icons/IconMore";
import IconKeyboardArrowRight from "@cx/ui/Icons/IconKeyboardArrowRight";
const VehicleCard = props => {
  const { vehicleRow, clicked, showActionIcon } = props;
  const clsBox = showActionIcon ? "vehicle-box add-border" : "vehicle-box";
  return (
    <div className={clsBox} onClick={clicked} id={vehicleRow.vehicleId}>
      <div className="vehicle-leftside">
        <div
          className="vehicle-img"
          style={{ backgroundImage: `url(${vehicleRow.image})` }}
        />
        <div className="vehicle-info">
          <div className="vehicle-make-year">
            {vehicleRow.year || ""} {vehicleRow.make || ""}{" "}
            {vehicleRow.model || ""}
          </div>
          <div className="vehicle-empty">
            {vehicleRow.trim || ""} {vehicleRow.engineSize || ""}{" "}
            {vehicleRow.engineType || ""}
            {vehicleRow.driveType || ""} {vehicleRow.transmissionType || ""}
          </div>
          <div className="vehicle-empty">{vehicleRow.vin || ""}</div>
        </div>
      </div>
      <div className="vehicle-action">
        {showActionIcon ? (
          <IconKeyboardArrowRight htmlId="VehicleSelectIcon" />
        ) : (
          <IconMore htmlId="VehicleEditIcon" />
        )}
      </div>
    </div>
  );
};

export default VehicleCard;
VehicleCard.propTypes = {
  clicked: PropTypes.func,
  showActionIcon: PropTypes.bool,
  vehicleRow: PropTypes.object
};
