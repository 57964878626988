/* eslint-disable react/no-multi-comp */
import React, { Component } from "react";
import { AgGridReact } from "ag-grid-react";
import SelectInput from "@cx/ui/SelectInput";
import IconMore from "@cx/ui/Icons/IconMore";
import IconNote from "@cx/ui/Icons/IconNote";
import Badge from "@cx/ui/Badge";
import Dropdown from "@cx/ui/Dropdown";
import Tooltip from "@cx/ui/Tooltip";
import { toast } from "@cx/ui/Toast";
import isEmpty from "lodash/isEmpty";
import moment from "moment";
import { AppContext } from "../../../state/app-context";
// @import custom components
import NewQuoteBtn from "../../ui/layouts/Nav/NewQuoteBtn";
import QuoteSlider from "../../quote-slider";
import CustomLoadingOverlay from "../../ui/loadingmask/CustomLoadingOverlay";
import CustomNoRowsOverlay from "../../ui/loadingmask/CustomNoRowsOverlay";
import ConfirmPopup from "../../ui/modals/ConfirmPopup";
import RichTooltip from "../../repair-order/components/rich-tooltip/rich-tooltip.component";
import QuoteActionMenu from "./quote-action-menu.component";
import CsrActionMenu from "../../repair-order/components/csr-action-menu.component";
import NotesTextAreaModal from "../../ui/modals/notes-text-modal.component";
import SendEmailTextModal from "../../ui/modals/send-email-text-modal.component";
import DocumentsModal from "../../repair-order/components/documents-modal/documents-modal.component";
import RequestBanner from "../../ui/banners/request-banner/request-banner.component";
import DashboardProvider from "../../dashboards/dashboard-charts/dashboard.provider";
import ApiFailureDashboard from "../../dashboards/api-failure/api-failure-dashboard.component";
import BodyMask from "../../ui/reusable/common/body-mask.component";
// @import services
import pastQuotesService from "../services/past-quotes.service";
import {
  copyQuote,
  deleteQuote
} from "../../page-wrapper/services/quote-api.service";
import serviceTypesService from "../services/service-types.service";
import quoteAssistanceService from "../../../services/quote-assistance-service";
import { assignPartCounterPersonToRO } from "../services/csr-past-quotes.service";
import csrService from "../../quote-summary/services/csr.service";
// @import utils
import { hideBodyMask, showBodyMask } from "../../../utils/page-mask.util";
import * as gtmEvent from "../../utils/gtag/gtag-event.util";
import { QuoteStatusMap } from "../../../constants/module.constants";
import { loadAgGridLocale } from "../../../i18n/LocaleSender";
import {
  verifyVehicleYMM,
  extractAndFormatAttentionTags,
  extractNotes,
  hasTwoEqualStrings
} from "../../utils/data-transformer";
import { formatNumberWithThousands } from "../../../utils/formatter.util";
import { sleep } from "../../../utils/helper.util";
import { xlate } from "../../../utils/i18n/locales";
import { assistanceRequestStatus } from "../../../constants/assiatance-request.constants";
import {
  QuoteStatusOptions,
  CsrStatusOptions,
  priceFormatter,
  dateFormatter,
  expirationDateFormatter,
  numberParser,
  vehicleTooltipValueGetter,
  allowEditQuote,
  allowEditCSR,
  getStatusBadgeColor,
  allowEditAccessFromAction,
  allowCopyAccess,
  blankValueFormatter,
  addTimeZoneToHeader,
  getTechnicianWorkingStatus
} from "../utils/past-quotes.util";
// @import css
import "../../../theme/agtheme/grid-style.scss";
import AlertBox from "../../ui/banners/AlertBox";
import ConfirmationAlertPopup from "../../ui/reusable/confirmation/confirmation-alert-popup.component";
import { YES } from "../../repair-order/constants/adjustment.constant";
import QuoteStatusConstant from "../../../constants/quote-status.constants.js";
import { partStatus, technicianStatus } from "../../../constants/app.constants";
import IconWarning from "@cx/ui/Icons/IconWarning";
import { CustomTechViewGroupRenderer } from "./custom-tech-view-group-renderer.component";
import cloneDeep from "lodash/cloneDeep";
class PastQuotesGrid extends Component {
  static contextType = AppContext;

  constructor(props, context) {
    super(props, context);
    const { appSource, appType, isPartsView, userPermissions } = context;

    const isTechView =
      userPermissions.canPerformTechnicianActions &&
      !userPermissions.canPerformServiceManagerActions &&
      !userPermissions.canPerformServiceAdvisorActions &&
      !isPartsView;

    // Bind grid functions in constructor
    this.getRowNodeId = this.getRowNodeId.bind(this);
    this.handleColumnResized = this.handleColumnResized.bind(this);
    this.onSearchBoxChanged = this.onSearchBoxChanged.bind(this);
    this.onCellValueChanged = this.onCellValueChanged.bind(this);
    this.onCellClickedEvent = this.onCellClickedEvent.bind(this);
    this.onDateViewChange = this.onDateViewChange.bind(this);
    this.onStatusChange = this.onStatusChange.bind(this);
    this.renderActionButton = this.renderActionButton.bind(this);
    this.renderStatusBadge = this.renderStatusBadge.bind(this);
    this.renderAttentionBadge = this.renderAttentionBadge.bind(this);
    this.documentsActionHandler = this.documentsActionHandler.bind(this);
    this.searchArchiveHandler = this.searchArchiveHandler.bind(this);
    this.formatPromisedDateTime = this.formatPromisedDateTime.bind(this);
    this.printSelectedDocumentsHandler =
      this.printSelectedDocumentsHandler.bind(this);
    this.editActionHandler = this.editActionHandler.bind(this);
    this.copyActionHandler = this.copyActionHandler.bind(this);
    this.deleteActionHandler = this.deleteActionHandler.bind(this);
    this.openQuoteNotesModal = this.openQuoteNotesModal.bind(this);
    this.openSendEmailTextModal = this.openSendEmailTextModal.bind(this);
    this.openConfirmationAlertPopup =
      this.openConfirmationAlertPopup.bind(this);
    this.allowEditAccessFromLink = this.allowEditAccessFromLink.bind(this);
    this.refreshButtonHandler = this.refreshButtonHandler.bind(this);
    this.handleQuoteAssistance = this.handleQuoteAssistance.bind(this);
    // @auto-refresh properties and methods
    // @note: This is the time limit for user being idle. It is 60 seconds. We can change idle time limit later.
    this.idleUserSessionTimeLimit = 120000; // 120000ms = 120 seconds = 2 mins
    this.userActivityEvents = [
      "keypress",
      "keydown",
      "keyup",
      "click",
      "mouseover",
      "mousemove",
      "mouseleave",
      "mouseup",
      "mousedown",
      "mousewheel",
      "pointerover",
      "pointerenter",
      "pointermove",
      "pointerout"
    ];
    this.timer = null;

    // bind other external actions
    const localeStrings = context.localeStrings;
    this.initializeLocaleValues();
    const isCSR = appType === "CSR";
    const isCSRAdvisorView = isCSR && !isPartsView && !isTechView;
    const gridOptions = {
      // other state props
      isCSR,
      isCSRAdvisorView,
      isPartsView,
      isTechView,
      statusFilterOption: isCSR ? CsrStatusOptions : QuoteStatusOptions,
      hideApiFailuredashboard: true,
      showStatistics: !isCSR,
      selectedQuoteId: null,
      showPopup: false,
      showPartsConfirmPopup: false, // @note - for displaying confirm popup  to assign parts person to RO
      clickedRORecord: null, // @note - for holding selected RO data to assign parts person to RO
      quoteRecord: null,
      showSlide: appSource === "IND" ? false : true,
      flexWidth: true,
      sliderWidth: "85vw", // @note - cx recommended to pass width in viewpoint treated as 85%
      searchKey: "",
      dateRange: "30",
      quoteStatus: "ALL",
      // ag-grid props
      rowSelection: "single", // allows single row selection
      multiSortKey: "ctrl",
      rowData: null, // should be null - fix to skip "No records found" msg on grid load.
      serviceTypes: null,
      columnDefs: this.getColumnList(isCSR, isPartsView, isTechView),
      defaultColDef: {
        floatingFilter: false, // true - enable column header filters
        flex: 1,
        sortable: true,
        resizable: true,
        editable: false, // default disable editor
        enableRowGroup: true,
        suppressMenu: false,
        sortingOrder: ["asc", "desc", null],
        minWidth: 120,
        autoHeight: true,
        menuTabs: isCSRAdvisorView ? ["generalMenuTab", "filterMenuTab"] : null, // used for adding filters  on column ["filterMenuTab", "generalMenuTab","columnsMenuTab"]
        filter: "agTextColumnFilter",
        filterParams: { buttons: ["clear"] },
        headerClass: "ag-text-header",
        getQuickFilterText: params => {
          if (!params.column.visible) {
            return null;
          } else {
            return params.value;
          }
        }
      },
      columnTypes: {
        numberColumn: {
          maxWidth: 150,
          minWidth: 120,
          filter: "agNumberColumnFilter",
          filterParams: {
            includeBlanksInEquals: false,
            includeBlanksInLessThan: false,
            includeBlanksInGreaterThan: false,
            suppressMiniFilter: true,
            buttons: ["clear"]
          }
        },
        nonEditableColumn: {
          editable: false
        },
        noFilterColumn: {
          width: 100,
          columnGroupShow: "open",
          filter: false
        },
        dateColumn: {
          filter: "agDateColumnFilter",
          filterParams: {
            comparator(filterLocalDateAtMidnight, cellValue) {
              const dateAsString = cellValue;

              if (dateAsString == null) return -1;
              const dateParts = dateAsString.split("-");

              if (dateParts.length < 3) {
                return -1;
              }
              const day = Number(dateParts[2].substring(0, 2));
              const month = Number(dateParts[1]) - 1;
              const year = Number(dateParts[0]);

              const cellDate = new Date(year, month, day);
              if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
                return 0;
              }

              if (cellDate < filterLocalDateAtMidnight) {
                return -1;
              }

              if (cellDate > filterLocalDateAtMidnight) {
                return 1;
              }
            }
          }
        }
      },
      // eslint-disable-next-line unused-imports/no-unused-vars
      isRowSelectable(rowNode) {
        return true; // to see checkbox
      },
      frameworkComponents: {
        quoteActionMenu: QuoteActionMenu,
        csrActionMenu: CsrActionMenu,
        customLoadingOverlay: CustomLoadingOverlay,
        customNoRowsOverlay: CustomNoRowsOverlay,
        warningRenderer: this.WarningRenderer
      },
      loadingOverlayComponent: "customLoadingOverlay",
      loadingOverlayComponentParams: {
        isLoading: true
      },
      noRowsOverlayComponent: "customNoRowsOverlay",
      noRowsOverlayComponentParams: {
        noRowsMessage: "No quotes found.",
        apiStatus: "NODATA"
      },
      statusBar: {
        statusPanels: isTechView
          ? [
              {
                statusPanel: "agTotalAndFilteredRowCountComponent",
                align: "right"
              },
              {
                statusPanel: "warningRenderer",
                key: "warningRenderer",
                align: "left"
              }
            ]
          : [
              {
                statusPanel: "agTotalAndFilteredRowCountComponent",
                align: "left"
              },
              {
                statusPanel: "agFilteredRowCountComponent"
              },
              {
                statusPanel: "agSelectedRowCountComponent",
                align: "left"
              }
            ]
      },
      localeText: loadAgGridLocale(localeStrings),
      onColumnMoved: this.refreshGrid,
      onColumnPinned: this.refreshGrid,
      sideBar: {
        toolPanels: [
          {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel",
            toolPanelParams: {
              suppressPivots: true,
              suppressPivotMode: true,
              suppressValues: true,
              suppressRowGroups: false
            }
          },
          {
            id: "filters",
            labelDefault: "Filters",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel"
          }
        ],
        hiddenByDefault: false,
        // @note: for ERP
        position: "left"
      }
    };
    this.state = {
      ...gridOptions,
      showNotesModal: false,
      showSendEmailTextModal: false,
      // alert popup state begins
      showAlertPopup: false,
      // callback action that when occurs when proceed button is clicked in Confirmation Alert Popup
      handleAlertPopupCallback: () => {},
      // flag to verify if the quote status needs to be updated
      stopUpdateQuoteStatus: false,
      // alert popup state ends
      // placeholder to apply masking on grid page
      gridPageMask: false,
      isIdle: false,
      idleTimeCounter: 0,
      intervalAndEventsAdded: false
    };
  }

  componentDidUpdate() {
    if (this.context.sliderIsOpen) {
      if (this.state.intervalAndEventsAdded) {
        this.removeUserActivityEventsAndHandleTimeout();
      }
    } else {
      if (!this.state.intervalAndEventsAdded) {
        this.addUserActivityEventsAndHandleTimeout();
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener(
      "refreshPastQuotes",
      this.refreshGridData,
      false
    );
    this.removeUserActivityEventsAndHandleTimeout();
    // The document.visibilityState read-only property returns the visibility of the document ("visible", "hidden", etc.)
    if ("visibilityState" in document) {
      // The visibilitychange event is fired at the document when the contents of its tab have become visible or have been hidden.
      document.removeEventListener(
        "visibilitychange",
        this.handleVisibilityChange,
        false
      );
    }
  }
  // ag-grid uses onGridReady as React life-cycle method componentDidmount()
  onGridReady = params => {
    this.gridParams = params;
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.context.isPartsView && this.gridApi.expandAll();
    this.getPastQuotesSource();
    this.gridApi.closeToolPanel();
    this.applySortConfig();
    this.sizeToFit();

    window.addEventListener("refreshPastQuotes", this.refreshGridData, false);
    gtmEvent.trackGAPageview("dashboard");
    // The document.visibilityState read-only property returns the visibility of the document ("visible", "hidden", etc.)
    if ("visibilityState" in document) {
      // The visibilitychange event is fired at the document when the contents of its tab have become visible or have been hidden.
      document.addEventListener(
        "visibilitychange",
        this.handleVisibilityChange
      );
    }
  };

  formatPromisedDateTime = val => {
    if (!val) {
      return "- -";
    }

    const promised = moment(val);
    const promisedDate = promised.isSame(new Date(), "day")
      ? ""
      : promised.format("M/D");
    const promisedTime = promised.format("h:mm A");

    return `${promisedDate} ${promisedTime}`.trim();
  };

  keyCreator(params) {
    return isEmpty(params.value) ? "- -" : params.value;
  }

  sortComparator(nodeA, nodeB) {
    const statusA = nodeA.key || "";
    const statusB = nodeB.key || "";

    if (statusA === partStatus.REQUESTED) {
      return -1; // ? Desceding order
    } else if (statusB === partStatus.APPROVED) {
      return 1; // ? Ascending order
    } else {
      return 0; // ? unordered
    }
  }

  // @auto-refresh-start
  // These methods deal with idle user detection and handling
  addUserActivityEventsAndHandleTimeout = () => {
    this.userActivityEvents.forEach(eventName => {
      window.addEventListener(eventName, this.timeoutFn, false);
    });
    this.setState({ intervalAndEventsAdded: true });
  };

  removeUserActivityEventsAndHandleTimeout = () => {
    this.userActivityEvents.forEach(eventName => {
      window.removeEventListener(eventName, this.timeoutFn, false);
    });
    clearTimeout(this.timer);
    this.setState({ intervalAndEventsAdded: false });
  };
  // This function will refresh the grid and call timeoutFn (resetting the timeout),
  // but only if visibilityState is visible (the user is in quoting tab);
  // if the user is in a differnt tab, the refresh will happen only once after switching back to quoting.
  callbackGridRefresh = () => {
    if (
      document.visibilityState === "visible" &&
      !window.location.href.includes("&suppressAutoRefresh=1")
    ) {
      this.refreshGridData(true);
      this.timeoutFn();
      // Uncomment console log to test
      /* console.log(
        "visibilityState === 'visible' - Slider is closed: Grid refreshed"
      ); */
    }
  };
  timeoutFn = () => {
    if (typeof this.timer != "undefined") {
      this.timer = clearTimeout(this.timer);
    }
    this.timer = setTimeout(
      this.callbackGridRefresh,
      this.idleUserSessionTimeLimit
    );
  };
  // @auto-refresh-end

  initializeLocaleValues() {
    this.appointmentIdLabel = xlate("sq.pastquotes.appointment_id_lbl");
    this.serviceTypesLabel = xlate("sq.dashboard.service_types_header");
    this.statusLabel = xlate("sq.pastquotes.status_lbl");
    this.customerLabel = xlate("sq.pastquotes.customer_lbl");
    this.vehicleLabel = xlate("sq.pastquotes.vehicle_lbl");
    this.dateCreatedLabel = xlate("sq.pastquotes.date_created_lbl");
    this.sendToCustomerLabel = xlate("sq.pastquotes.sent_to_customer_lbl");
    this.createdByLabel = xlate("sq.pastquotes.createdby_lbl");
    this.servicesLabel = xlate("sq.pastquotes.services_lbl");
    this.totalLabel = xlate("sq.pastquotes.total_lbl");
    this.actionsLabel = xlate("sq.pastquotes.actions_lbl");
    this.vinLabel = xlate("sq.pastquotes.vin_lbl");
    this.expirationDateLabel = xlate("sq.pastquotes.exp_date_lbl");
    this.attentionLabel = xlate("sq.pastquotes.attention_lbl");
    this.notesLabel = xlate("sq.pastquotes.notes_lbl");

    // @ERP Parts person
    this.partsPersonLabel = "Parts person";
    this.techLabel = "Tech";
    this.advisorLabel = "Advisor";
    this.partStatusLabel = "Parts status";
    this.techWorkingStatusLabel = "Technician Status";
    this.hangTagLabel = "Hang tag";
  }
  /* This method can be called to refresh single or multi rows */
  refreshGrid(params) {
    params.api.refreshCells({ force: true });
  }
  /**
   * param {forceFilter} true - to read state values and apply filter values; false - reset filters
   */
  refreshGridData = (forceFilter = false) => {
    if (forceFilter) {
      const { dateRange, quoteStatus } = this.state;
      quoteStatus === "W"
        ? this.getPastQuotesSource()
        : this.getPastQuotesSource(dateRange, quoteStatus);
    } else {
      this.getPastQuotesSource();
    }
  };
  // This function executes when document.visibilityState changes.
  // If the no sliders are open, callbackGridRefresh is called.
  // However, callbackGridRefresh will not refresh the grid if visibilityState is not visible.
  handleVisibilityChange = () => {
    if (!this.context.sliderIsOpen) {
      this.callbackGridRefresh();
      // Uncomment console log to test
      /* console.log(
        "visibilityState has changed - Slider is closed: callbackGridRefresh"
      ); */
    }
  };
  refreshButtonHandler = () => {
    gtmEvent.trackGAEvent("ga.dashboard.refresh_quotes");
    this.refreshGridData(true);
  };

  toggleStatistics = () => {
    gtmEvent.trackGAEvent("ga.dashboard.toggle_statistics");
    this.setState(prevState => {
      return { showStatistics: !prevState.showStatistics };
    });
  };
  allowEditAccessFromLink = quoteStatus => {
    return allowEditQuote(quoteStatus) || allowEditCSR(quoteStatus); // @csr-logic
  };

  formatQuotesDataForTechnicianView = quotesReponse => {
    const formattedQuotesResponse = [];
    quotesReponse.map(quote => {
      if (!quote?.statusByTech) {
        const quoteObject = {
          ...quote,
          technicianWorkingStatus: getTechnicianWorkingStatus(
            quote?.statusByTech
          )
        };
        formattedQuotesResponse.push(quoteObject);
      } else {
        const technicianSpecificStatus =
          quote.statusByTech[this.context.user.quoteUserId];
        const statuses = technicianSpecificStatus?.map(status => status.trim());

        statuses?.forEach(status => {
          const quoteObject = {
            ...quote,
            technicianWorkingStatus: getTechnicianWorkingStatus(status)
          };
          formattedQuotesResponse.push(quoteObject);
        });
      }
    });
    return formattedQuotesResponse;
  };

  // Method called to fetch quotes with/wo filters for both apps - CSR, Quoting
  async getPastQuotesSource(range, filterStatus) {
    const { dealer } = this.context;
    const { dealerCode } = dealer;
    const { isCSR, isCSRAdvisorView, isTechView, quoteStatus } = this.state;
    let numberOfDays = this.state.dateRange;
    if (range && range !== numberOfDays) {
      numberOfDays = range;
    }
    try {
      // For Quoting case, status filter default value = "";
      let statusParam =
        !filterStatus || filterStatus === "ALL" ? "" : filterStatus;
      // For CSR case, Days filter always empty
      // For CSR case, append all csr status text as default value before passing to  API
      // @csr-logic
      if (isCSR) {
        numberOfDays = "";
        const csrStatusList = [
          "WITH_ADVISOR",
          "IN_PROCESS",
          "WORK_FINISHED",
          "PRE_INVOICE",
          "FINALIZED"
        ];
        statusParam = !statusParam ? csrStatusList.toString() : statusParam;
        if (isTechView) {
          statusParam = "IN_PROCESS,WORK_FINISHED,PRE_INVOICE,FINALIZED";
        }
        if (quoteStatus === "W") {
          statusParam = "PRE_INVOICE,FINALIZED";
        }
      }
      showBodyMask();
      this.gridApi && this.gridApi.showLoadingOverlay();
      let response = null;
      if (isTechView) {
        const quoteResponse =
          await pastQuotesService.getPastQuotesForTechnicianView(
            dealerCode,
            numberOfDays,
            statusParam,
            this.context.user.quoteUserId
          );
        response = this.formatQuotesDataForTechnicianView(quoteResponse);
      } else {
        response = await pastQuotesService.getPastQuotes(
          dealerCode,
          numberOfDays,
          statusParam
        );
      }
      let serviceTypesResponse = [];
      if (!isCSR) {
        serviceTypesResponse = await serviceTypesService.getServiceTypes(
          numberOfDays,
          dealerCode,
          filterStatus
        );
      }
      this.gridApi && this.gridApi.hideOverlay();
      hideBodyMask();
      let pastQuotes = [];
      let serviceTypes = [];
      // when API has records
      if (!isEmpty(response)) {
        serviceTypes = serviceTypesResponse;
        pastQuotes = response.map(quote => {
          const customerObj = this.readCustomerObj(quote);
          const vehicleObj = this.readVehicleObj(quote);
          const quoteNotes = extractNotes(quote.quoteNotes);
          return {
            ...quote,
            notes: quoteNotes,
            formattedPromisedDateTime:
              isCSRAdvisorView || isTechView
                ? this.formatPromisedDateTime(quote.promisedDate)
                : null,
            customer: customerObj.customer,
            email: customerObj.email,
            vehicle: vehicleObj.vehicle
          };
        });
        if (this.state.quoteStatus === "W") {
          const initialPastQuotes = cloneDeep(pastQuotes);
          pastQuotes = initialPastQuotes.filter(row => row.pay?.includes("W"));
        }
      } else {
        // API returns []
        this.setState({
          noRowsOverlayComponentParams: {
            noRowsMessage: "No results found",
            apiStatus: "NODATA"
          }
        });
        this.gridApi && this.gridApi.showNoRowsOverlay();
      }
      this.setState({
        hideApiFailuredashboard: true,
        rowData: pastQuotes,
        serviceTypes,
        columnDefs: this.getColumnList(
          this.state?.isCSR,
          this.state?.isPartsView,
          this.state?.isTechView
        )
      });
    } catch (error) {
      hideBodyMask();
      this.gridApi && this.gridApi.hideOverlay();
      // when API connection failure with status code !== 200 or 201
      this.setState({
        noRowsOverlayComponentParams: {
          noRowsMessage: "Unable to connect with service quotes",
          apiStatus: "APIERROR"
        },
        hideApiFailuredashboard: false
      });
      // sleep helps us mitigate racing issue (showNoRowsOverlay below called before state has finished updating)
      sleep(100).then(() => {
        this.gridApi && this.gridApi.showNoRowsOverlay();
      });
      const msg = !error ? "Error occurred while loading data" : error.message;
      toast.error(msg, {
        closeOnClick: true
      });
    }
  }
  // Util called to parse past quotes response for customer fields
  readCustomerObj = data => {
    const firstName = !data.firstName ? "" : `, ${data.firstName}`;
    const customerObj = {};
    customerObj.customer = !data.lastName
      ? data.customerId
      : `${data.lastName} ${firstName}`.trim();
    customerObj.email = data?.email || "";
    return customerObj;
  };
  // Util called to parse past quotes response for vehicle fields
  readVehicleObj = quote => {
    const obj = {};
    obj.vehicle =
      !quote.year || !quote.make || !quote.model
        ? ""
        : `${quote.year} ${quote.make} ${quote.model}`.trim();
    return obj;
  };
  handleColumnResized = () => {
    this.gridApi.resetRowHeights();
  };
  handleGridSizeChanged = () => {
    this.sizeToFit();
  };
  sizeToFit() {
    this.gridApi?.sizeColumnsToFit();
  }
  applySortConfig() {
    const { isCSR } = this.state;
    const defaultSortModel = [
      {
        colId: isCSR ? "roNumber" : "creationDateTime",
        sortIndex: 0,
        sort: "desc"
      }
    ];
    this.assignColumnState(defaultSortModel);
  }
  assignColumnState = defaultSortModel => {
    this.gridColumnApi?.applyColumnState({
      state: defaultSortModel,
      defaultState: {
        // important to say 'null' as undefined means 'do nothing'
        sort: null
      }
    });
  };

  /* call this to remove sidebar */
  // hideSidebar() {
  //   if (this.gridApi) {
  //     this.gridApi.setSideBarVisible(!this.gridApi.isSideBarVisible());
  //   }
  // }
  // @note- this function required for CRUD operations, to getRowNode()
  getRowNodeId(data) {
    if (this.state.isTechView)
      return `${data.confirmationId}-${data.technicianWorkingStatus}`;
    return data.confirmationId; // primary or unique key of record
  }
  // This event fired after a cell has been changed with default editing
  onCellValueChanged() {
    // TODO: callback logic save cell edits
  }
  openSlider(record) {
    const { vehicleId, customerId, confirmationId, roNumber } = record;
    const quoteParams = {
      accessType: !confirmationId ? null : "EDIT",
      quoteId: confirmationId,
      vehicleId,
      customerId,
      roNumber
    };
    this.context.updateQuoteParams(quoteParams);
    gtmEvent.trackGAPageview("editquote");
    // @todo: quote params should update app context before opening slider
    sleep(1000).then(() => {
      // show slider for quote status in-progress, completed
      this.setState({
        showSlide: true,
        selectedQuoteId: confirmationId,
        quoteRecord: record
      });
    });
  }
  /* "cellClicked" event handler  */
  onCellClickedEvent(params) {
    const field = params.colDef.field;
    const record = params.data;
    const { quoteStatus } = record;
    const allowEdit = this.allowEditAccessFromLink(quoteStatus);
    const { isCSR } = this.state;
    if (!isCSR) {
      if (field === "confirmationId") {
        // TODO: Fix -skip customer flow, allow edit if YMM data exist for vehicle.
        const vehicleExist = verifyVehicleYMM(record);
        if (vehicleExist && allowEdit) {
          gtmEvent.trackGAEvent("ga.dashboard.grid_cellclick_quote_click");
          this.openSlider(record);
          this.context.handleSliderStatus(true);
        }
      } else if (field === "notes") {
        // open Notes modal when notes column clicked
        this.openQuoteNotesModal(record);
      }
    } else if (isCSR) {
      if (field === "roNumber") {
        // @csr-logic
        if (params.event?.ctrlKey) {
          const detailsURL =
            window.location.href.replace(/\/dashboard/i, "/rodetails") +
            `&quoteId=${params.data.confirmationId}` +
            `&roNumber=${csrService.getRONumberDisplay(params.data)}`;
          window.open(detailsURL, "_blank");
          gtmEvent.trackGAPageview("rodetails");
          return;
        }
        //* for assigning parts counterperson to RO on ERP when parts person click on RO details
        if (this.context.isPartsView && record?.partsPersonName === null) {
          this.setState({
            showPartsConfirmPopup: true,
            clickedRORecord: record
          });
        } else {
          this.openSlider(record);
          this.context.handleSliderStatus(true);
        }
      }
    }
  }
  /* Close Handler for Slider and click event outside the drawer
     use this context callback to show speed bump when Form is dirty
  */
  closeSliderPage = event => {
    if (event) {
      event.preventDefault();
    }
    this.context.handleSliderStatus(false);
    this.setState(
      { showSlide: false, selectedQuoteId: null, quoteRecord: null },
      () => {
        this.refreshGridData(true);
      }
    );
  };
  callbackActionHandler = () => {
    console.log("New Quote ok action triggered");
  };

  /* "filterChanged" - listen to the column filter events; can be used to  clear column filters */
  onFilterChanged = () => {
    if (this.gridApi) {
      this.clearGridSelections();
    }
  };
  /* Un-select all rows, regardless of filtering from grid */
  clearGridSelections = () => {
    if (this.gridApi) {
      this.gridApi.deselectAll();
      this.setState({ selectionlist: [] });
    }
  };
  getColumnList(isCSR, isPartsView, isTechView) {
    let baseCols = [];
    if (isPartsView) {
      baseCols = [
        {
          field: "roNumber",
          headerName: "RO",
          headerClass: "ag-text-header",
          pinned: "left",
          sortingOrder: ["desc", "asc"],
          minWidth: 120,
          width: 150,
          maxWidth: 150,
          cellClass: "xmm-link-cell",
          cellRendererFramework(params) {
            return csrService.getRONumberDisplay(params.data);
          },
          keyCreator: params => {
            return csrService.keyCreatorRoNumber(params);
          }
        },
        {
          field: "confirmationId",
          headerName: xlate("sq.pastquotes.quote_id_lbl"),
          headerClass: "ag-text-header",
          sortingOrder: ["asc", "desc"],
          minWidth: 120,
          width: 150,
          maxWidth: 150,
          keyCreator: this.keyCreator,
          cellRendererFramework(params) {
            return isEmpty(params.value) ? "" : params.value;
          }
        },
        {
          field: "serviceTypeCodes",
          headerName: this.serviceTypesLabel,
          headerClass: "ag-text-header",
          pinned: "left",
          minWidth: 120,
          width: 150,
          maxWidth: 150,
          keyCreator: this.keyCreator
        },
        {
          field: "partsPersonName",
          tooltipField: "partsPersonName",
          headerName: this.partsPersonLabel,
          headerClass: "ag-text-header",
          sortingOrder: ["asc", "desc"],
          minWidth: 150,
          width: 150,
          maxWidth: 150,
          cellRendererFramework: blankValueFormatter,
          keyCreator: this.keyCreator
        },
        {
          field: "partsStatus",
          headerName: this.partStatusLabel,
          headerClass: "ag-text-header",
          sortingOrder: ["desc", "asc"],
          minWidth: 120,
          width: 150,
          maxWidth: 150,
          rowGroup: true,
          hide: true,
          cellRendererFramework: this.partStatusRenderer,
          keyCreator: this.keyCreator,
          valueFormatter(params) {
            return params.value;
          }
        },
        {
          field: "customer",
          headerName: this.customerLabel,
          editable: false,
          sortable: true,
          minWidth: 150,
          width: 200,
          maxWidth: 300,
          cellClass: "xmm-wrap-text",
          sortingOrder: ["asc", "desc"],
          cellRendererFramework: this.renderCustomer,
          keyCreator: this.keyCreator,
          valueFormatter(params) {
            return params.value;
          }
        },
        {
          field: "vehicle",
          headerName: this.vehicleLabel,
          editable: false,
          sortable: true,
          minWidth: 150,
          width: 200,
          maxWidth: 300,
          cellClass: "xmm-wrap-text",
          sortingOrder: ["asc", "desc"],
          cellRendererFramework: this.renderVehicle,
          valueFormatter(params) {
            return params.value;
          }
        },
        {
          field: "vin",
          headerName: this.vinLabel,
          editable: false,
          sortable: true,
          minWidth: 100,
          width: 120,
          maxWidth: 220,
          tooltipField: "vin",
          cellClass: "xmm-wrap-text",
          sortingOrder: ["asc", "desc"],
          cellRendererFramework(params) {
            if (!params.value) {
              return "";
            }
            return `...${params?.value.substring(params?.value.length - 6)}`;
          },
          keyCreator: this.keyCreator
        },
        {
          field: "technicianName",
          tooltipField: "technicianName",
          headerName: this.techLabel,
          editable: false,
          sortable: true,
          minWidth: 150,
          width: 180,
          maxWidth: 220,
          cellClass: "xmm-wrap-text",
          sortingOrder: ["asc", "desc"],
          cellRendererFramework(params) {
            return isEmpty(params.value) ? "" : params.value;
          },
          keyCreator: this.keyCreator
        },
        {
          field: "advisor",
          tooltipField: "advisor",
          headerName: this.advisorLabel,
          editable: false,
          sortable: true,
          minWidth: 150,
          width: 180,
          maxWidth: 220,
          cellClass: "xmm-wrap-text",
          sortingOrder: ["asc", "desc"],
          cellRendererFramework(params) {
            return isEmpty(params.value) ? "" : params.value;
          },
          keyCreator: this.keyCreator
        },

        {
          field: "hangTag",
          headerName: this.hangTagLabel,
          editable: false,
          sortable: true,
          minWidth: 120,
          width: 180,
          maxWidth: 220,
          cellClass: "xmm-wrap-text",
          keyCreator: this.keyCreator,
          sortingOrder: ["asc", "desc"]
        }
      ];
    } else if (isTechView && isCSR) {
      baseCols = [
        {
          field: "roNumber",
          headerName: "RO",
          headerClass: "ag-text-header",
          pinned: "left",
          sortingOrder: ["desc", "asc"],
          minWidth: 120,
          width: 150,
          maxWidth: 150,
          cellClass: "xmm-link-cell",
          cellRendererFramework(params) {
            return csrService.getRONumberDisplay(params.data);
          },
          keyCreator: params => {
            return csrService.keyCreatorRoNumber(params);
          }
        },
        {
          field: "confirmationId",
          headerName: xlate("sq.pastquotes.quote_id_lbl"),
          headerClass: "ag-text-header",
          sortingOrder: ["asc", "desc"],
          minWidth: 120,
          width: 150,
          maxWidth: 150,
          hide: isCSR,
          keyCreator: this.keyCreator,
          cellRendererFramework(params) {
            return isEmpty(params.value) ? "" : params.value;
          }
        },
        {
          field: "appointmentCode",
          headerName: this.appointmentIdLabel,
          headerClass: "ag-text-header",
          sortingOrder: ["asc", "desc"],
          minWidth: isCSR ? 180 : 155,
          width: isCSR ? 180 : 150,
          maxWidth: isCSR ? null : 180,
          cellRendererFramework: blankValueFormatter,
          keyCreator: this.keyCreator,
          hide: isCSR
        },
        {
          field: "quoteStatus",
          headerName: this.statusLabel,
          cellClass: "xmm-wrap-cell",
          minWidth: isCSR ? 130 : 115,
          width: isCSR ? 130 : 120,
          maxWidth: isCSR ? null : 150,
          cellRendererFramework: this.renderStatusBadge,
          keyCreator: params => QuoteStatusMap[params.value],
          getQuickFilterText: params => {
            if (params.value === QuoteStatusConstant.CONVERTED_TO_APPOINTMENT) {
              return "scheduled";
            }
            return params.value;
          },
          filter: "agSetColumnFilter",
          filterParams: {
            buttons: ["clear"]
          }
        },
        {
          field: "hangTag",
          headerName: this.hangTagLabel,
          editable: false,
          sortable: true,
          minWidth: 120,
          width: 180,
          maxWidth: 220,
          cellClass: "xmm-wrap-text",
          keyCreator: this.keyCreator,
          sortingOrder: ["asc", "desc"]
        },
        {
          field: "serviceTypeCodes",
          headerName: this.serviceTypesLabel,
          headerClass: "ag-text-header",
          minWidth: 120,
          width: 150,
          maxWidth: 150,
          keyCreator: this.keyCreator
        },
        {
          field: "vehicle",
          headerName: this.vehicleLabel,
          editable: false,
          sortable: true,
          minWidth: 150,
          width: 200,
          maxWidth: 300,
          cellClass: "xmm-wrap-text",
          sortingOrder: ["asc", "desc"],
          cellRendererFramework: this.renderVehicle,
          valueFormatter(params) {
            return params.value;
          }
        },
        {
          field: "vin",
          headerName: this.vinLabel,
          editable: false,
          sortable: true,
          minWidth: 100,
          width: 120,
          maxWidth: 220,
          tooltipField: "vin",
          cellClass: "xmm-wrap-text",
          sortingOrder: ["asc", "desc"],
          cellRendererFramework(params) {
            if (!params.value) {
              return "";
            }
            return `...${params?.value.substring(params?.value.length - 6)}`;
          },
          keyCreator: this.keyCreator
        },
        {
          field: "advisor",
          tooltipField: "advisor",
          headerName: this.advisorLabel,
          editable: false,
          sortable: true,
          minWidth: 150,
          width: 180,
          maxWidth: 220,
          cellClass: "xmm-wrap-text",
          sortingOrder: ["asc", "desc"],
          cellRendererFramework(params) {
            return isEmpty(params.value) ? "" : params.value;
          },
          keyCreator: this.keyCreator
        },
        {
          field: "partsPersonName",
          tooltipField: "partsPersonName",
          headerName: this.partsPersonLabel,
          headerClass: "ag-text-header",
          sortingOrder: ["asc", "desc"],
          minWidth: 150,
          width: 150,
          maxWidth: 150,
          cellRendererFramework: blankValueFormatter,
          keyCreator: this.keyCreator
        },
        {
          field: "technicianWorkingStatus",
          headerName: this.techWorkingStatusLabel,
          headerClass: "ag-text-header",
          sortingOrder: ["asc", "desc"],
          minWidth: 150,
          width: 160,
          maxWidth: 180,
          rowGroup: true,
          hide: true,
          cellRendererFramework: this.technicianStatusRenderer,
          keyCreator: this.keyCreator,
          valueFormatter(params) {
            return params.value;
          }
        },
        {
          field: "partsStatus",
          headerName: this.partStatusLabel,
          headerClass: "ag-text-header",
          sortingOrder: ["desc", "asc"],
          minWidth: 120,
          width: 150,
          maxWidth: 150,
          rowGroup: false,
          hide: true,
          cellRendererFramework: this.partStatusRenderer,
          keyCreator: this.keyCreator,
          valueFormatter(params) {
            return params.value;
          }
        },
        {
          field: "customer",
          headerName: this.customerLabel,
          editable: false,
          sortable: true,
          minWidth: 150,
          width: 200,
          maxWidth: 300,
          cellClass: "xmm-wrap-text",
          sortingOrder: ["asc", "desc"],
          cellRendererFramework: this.renderCustomer,
          keyCreator: this.keyCreator,
          valueFormatter(params) {
            return params.value;
          }
        },
        {
          field: "promisedDate",
          headerName: "Promised",
          editable: false,
          sortable: true,
          minWidth: 140,
          cellClass: "xmm-wrap-text",
          sortingOrder: ["asc", "desc"],
          cellRendererFramework: params => {
            return params?.data ? params.data?.formattedPromisedDateTime : "";
          },
          keyCreator: this.keyCreator,
          filterValueGetter: params => {
            return params.data?.formattedPromisedDateTime;
          },
          suppressColumnsToolPanel: !isCSR,
          suppressFiltersToolPanel: !isCSR,
          hide: !isCSR
        },
        {
          field: "technicianName",
          tooltipField: "technicianName",
          headerName: this.techLabel,
          editable: false,
          sortable: true,
          minWidth: 150,
          width: 180,
          maxWidth: 220,
          hide: isCSR,
          cellClass: "xmm-wrap-text",
          sortingOrder: ["asc", "desc"],
          cellRendererFramework(params) {
            return isEmpty(params.value) ? "" : params.value;
          },
          keyCreator: this.keyCreator
        },
        {
          field: "pay",
          headerName: "P",
          editable: false,
          sortable: true,
          minWidth: 100,
          width: 100,
          maxWidth: 100,
          cellClass: "xmm-wrap-text",
          sortingOrder: ["asc", "desc"],
          suppressColumnsToolPanel: !isCSR,
          suppressFiltersToolPanel: !isCSR,
          hide: !isCSR
        },
        {
          field: "creationDateTime",
          headerName: addTimeZoneToHeader(this.dateCreatedLabel),
          headerClass: "ag-text-header",
          editable: false,
          cellClass: "xmm-wrap-text",
          minWidth: 200,
          width: 165,
          maxWidth: 250,
          type: ["dateColumn", "nonEditableColumn"],
          valueFormatter: dateFormatter,
          suppressColumnsToolPanel: isCSR,
          suppressFiltersToolPanel: isCSR,
          hide: isCSR
        },
        {
          field: "lastSentDate",
          headerName: addTimeZoneToHeader(this.sendToCustomerLabel),
          headerClass: "ag-text-header",
          editable: false,
          cellClass: "xmm-wrap-text",
          minWidth: 220,
          width: 175,
          maxWidth: 250,
          type: ["dateColumn", "nonEditableColumn"],
          valueFormatter: dateFormatter,
          suppressColumnsToolPanel: isCSR,
          suppressFiltersToolPanel: isCSR,
          hide: isCSR
        },
        {
          field: "createdBy",
          headerName: this.createdByLabel,
          editable: false,
          minWidth: 180,
          width: 200,
          maxWidth: 250,
          cellClass: "xmm-wrap-text",
          filter: "agSetColumnFilter",
          filterParams: {
            buttons: ["clear"]
          },
          suppressColumnsToolPanel: isCSR,
          suppressFiltersToolPanel: isCSR,
          hide: isCSR
        },
        {
          field: "items",
          headerName: this.servicesLabel,
          headerClass: "ag-text-header",
          cellClass: "xmm-wrap-cell",
          type: "numberColumn",
          suppressSizeToFit: true,
          valueParser: numberParser,
          minWidth: isCSR ? 130 : 120,
          width: isCSR ? 130 : 90,
          maxWidth: isCSR ? 130 : 130,
          hide: true
        },
        {
          field: "notes",
          headerName: this.notesLabel,
          headerClass: "ag-text-header",
          sortingOrder: ["asc", "desc"],
          cellClass: "quote-notes",
          minWidth: 100,
          maxWidth: 120,
          suppressColumnsToolPanel: isCSR,
          suppressFiltersToolPanel: isCSR,
          filter: false,
          hide: isCSR,
          valueFormatter: blankValueFormatter,
          cellRendererFramework: this.renderQuoteNotes
        },
        {
          field: "quoteExpirationDateTime",
          headerName: this.expirationDateLabel,
          headerClass: "ag-text-header",
          editable: false,
          cellClass: "xmm-wrap-text",
          minWidth: 170,
          width: 170,
          maxWidth: 250,
          type: ["dateColumn", "nonEditableColumn"],
          valueFormatter: expirationDateFormatter,
          suppressColumnsToolPanel: isCSR,
          suppressFiltersToolPanel: isCSR,
          hide: true
        },
        {
          headerName: "",
          resizable: !isCSR,
          sortable: !isCSR,
          pinned: "right",
          editable: true,
          cellClass: "editable-cell",
          cellEditorSelector(params) {
            if (isCSR) {
              return { component: "csrActionMenu" };
            }
            const { quoteStatus } = params.data;
            const allowEdit = allowEditAccessFromAction(quoteStatus);
            const allowDelete =
              quoteStatus === "IN_PROGRESS" || quoteStatus === "EXPIRED";
            const vehicleExist = verifyVehicleYMM(params.data);
            if ((allowEdit && vehicleExist) || allowDelete) {
              if (allowEditCSR(quoteStatus)) {
                return { component: "csrActionMenu" };
              }
              return { component: "quoteActionMenu" };
            }
            params.api.stopEditing();
            return null;
          },
          cellEditorParams: {
            parentHandle: this
          },
          cellRendererFramework: this.renderActionButton,
          enableRowGroup: false,
          maxWidth: 65,
          minWidth: 65,
          width: 65,
          filter: false,
          suppressSizeToFit: true,
          suppressMenu: true,
          suppressColumnsToolPanel: true
        }
      ];
    } else {
      baseCols = [
        {
          field: "roNumber",
          headerName: "RO",
          headerClass: "ag-text-header",
          pinned: "left",
          sortingOrder: ["desc", "asc"],
          minWidth: 120,
          width: 150,
          maxWidth: 150,
          cellClass: "xmm-link-cell",
          cellRendererFramework: params => {
            return csrService.getRONumberDisplay(params.data);
          },
          keyCreator: params => {
            return csrService.keyCreatorRoNumber(params);
          },
          suppressColumnsToolPanel: !isCSR,
          suppressFiltersToolPanel: !isCSR,
          hide: !isCSR
        },
        {
          field: "confirmationId",
          headerName: xlate("sq.pastquotes.quote_id_lbl"),
          headerClass: "ag-text-header",
          pinned: isCSR ? null : "left",
          sortingOrder: ["asc", "desc"],
          minWidth: isCSR ? 150 : 120,
          width: 150,
          maxWidth: isCSR ? null : 150,
          hide: isCSR,
          cellClass: params => {
            if (!params.data) {
              return "";
            }
            const { quoteStatus } = params.data;
            const vehicleExist = verifyVehicleYMM(params.data);
            const allowEdit = this.allowEditAccessFromLink(quoteStatus);
            if (vehicleExist && !isCSR) {
              return allowEdit ? "xmm-link-cell" : "xmm-wrap-cell";
            } else {
              return "xmm-wrap-cell";
            }
          },
          valueFormatter(params) {
            return params.value;
          }
        },
        {
          field: "serviceTypeCodes",
          headerName: this.serviceTypesLabel,
          headerClass: "ag-text-header",
          pinned: this.state?.quoteStatus === "W" ? null : "left",
          minWidth: 120,
          width: 150,
          maxWidth: 150,
          keyCreator: this.keyCreator,
          hide: !isCSR
        },
        {
          field: "appointmentCode",
          headerName: this.appointmentIdLabel,
          headerClass: "ag-text-header",
          sortingOrder: ["asc", "desc"],
          minWidth: isCSR ? 180 : 155,
          width: isCSR ? 180 : 150,
          maxWidth: isCSR ? null : 180,
          cellRendererFramework: blankValueFormatter,
          keyCreator: this.keyCreator,
          hide: isCSR
        },
        {
          field: "quoteStatus",
          headerName: this.statusLabel,
          cellClass: "xmm-wrap-cell",
          minWidth: isCSR ? 130 : 115,
          width: isCSR ? 130 : 120,
          maxWidth: isCSR ? null : 150,
          cellRendererFramework: this.renderStatusBadge,
          keyCreator: params => QuoteStatusMap[params.value],
          getQuickFilterText: params => {
            if (params.value === QuoteStatusConstant.CONVERTED_TO_APPOINTMENT) {
              return "scheduled";
            }
            return params.value;
          },
          filter: "agSetColumnFilter",
          filterParams: {
            buttons: ["clear"]
          }
        },
        {
          field: "workflowAttentionTag",
          headerName: this.attentionLabel,
          cellClass: "xmm-wrap-cell",
          minWidth: 150,
          width: 135,
          maxWidth: 150,
          suppressColumnsToolPanel: isCSR,
          suppressFiltersToolPanel: isCSR,
          hide: isCSR,
          cellRendererFramework: this.renderAttentionBadge
        },
        {
          field: "customerId",
          headerName: "Customer ID",
          editable: false,
          sortable: true,
          minWidth: 130,
          width: 150,
          maxWidth: 300,
          cellClass: "xmm-wrap-text",
          sortingOrder: ["asc", "desc"],
          valueFormatter(params) {
            return params.value;
          },
          suppressColumnsToolPanel: isCSR,
          suppressFiltersToolPanel: isCSR,
          hide: true
        },
        {
          field: "customer",
          headerName: this.customerLabel,
          editable: false,
          sortable: true,
          minWidth: 150,
          width: 200,
          cellClass: "xmm-wrap-text",
          sortingOrder: ["asc", "desc"],
          cellRendererFramework: this.renderCustomer,
          valueFormatter(params) {
            return params.value;
          }
        },
        {
          field: "partsPersonName",
          tooltipField: "partsPersonName",
          headerName: this.partsPersonLabel,
          headerClass: "ag-text-header",
          sortingOrder: ["asc", "desc"],
          minWidth: 150,
          width: 150,
          maxWidth: 150,
          cellRendererFramework: blankValueFormatter,
          keyCreator: this.keyCreator,
          hide: !(
            isCSR &&
            this.state?.quoteStatus === "W" &&
            this.context.dealerProperties?.ENGG_USE_OEM_WARRANTY === YES
          )
        },
        {
          field: "technicianName",
          tooltipField: "technicianName",
          headerName: this.techLabel,
          editable: false,
          sortable: true,
          minWidth: 150,
          width: 180,
          maxWidth: 220,
          cellClass: "xmm-wrap-text",
          sortingOrder: ["asc", "desc"],
          cellRendererFramework(params) {
            return isEmpty(params.value) ? "" : params.value;
          },
          keyCreator: this.keyCreator,
          hide: !(
            isCSR &&
            this.state?.quoteStatus === "W" &&
            this.context.dealerProperties?.ENGG_USE_OEM_WARRANTY === YES
          )
        },
        {
          field: "promisedDate",
          headerName: "Promised",
          editable: false,
          sortable: true,
          minWidth: 140,
          cellClass: "xmm-wrap-text",
          sortingOrder: ["asc", "desc"],
          cellRendererFramework: params => {
            return params?.data ? params.data?.formattedPromisedDateTime : "";
          },
          keyCreator: this.keyCreator,
          filterValueGetter: params => {
            return params.data?.formattedPromisedDateTime;
          },
          suppressColumnsToolPanel: !isCSR,
          suppressFiltersToolPanel: !isCSR,
          hide: !isCSR || this.state?.quoteStatus === "W"
        },
        {
          field: "vehicleId",
          headerName: "Vehicle ID",
          editable: false,
          minWidth: 150,
          width: 180,
          maxWidth: 200,
          cellClass: "xmm-wrap-text",
          valueFormatter(params) {
            return params.value;
          },
          filter: "agSetColumnFilter",
          filterParams: {
            buttons: ["clear"]
          },
          suppressColumnsToolPanel: isCSR,
          suppressFiltersToolPanel: isCSR,
          hide: true
        },
        {
          field: "vehicle",
          headerName: this.vehicleLabel,
          editable: false,
          sortable: true,
          minWidth: 150,
          width: 200,
          cellClass: "xmm-wrap-text",
          sortingOrder: ["asc", "desc"],
          cellRendererFramework: this.renderVehicle,
          valueFormatter(params) {
            return params.value;
          }
        },
        {
          field: "advisor",
          editable: false,
          sortable: true,
          minWidth: 150,
          width: 200,
          maxWidth: isCSR ? null : 300,
          cellClass: "xmm-wrap-text",
          filter: isCSR ? "agSetColumnFilter" : "agTextColumnFilter",
          sortingOrder: ["asc", "desc"],
          tooltipField: "advisor",
          tooltipComponentParams: { field: "advisor" },
          tooltipValueGetter: vehicleTooltipValueGetter,
          cellRendererFramework: isCSR ? blankValueFormatter : null,
          keyCreator: this.keyCreator,
          suppressColumnsToolPanel: !isCSR,
          suppressFiltersToolPanel: !isCSR,
          hide: !isCSR
        },
        {
          field: "creationDateTime",
          headerName: addTimeZoneToHeader(this.dateCreatedLabel),
          headerClass: "ag-text-header",
          editable: false,
          cellClass: "xmm-wrap-text",
          minWidth: 200,
          width: 165,
          maxWidth: 250,
          type: ["dateColumn", "nonEditableColumn"],
          valueFormatter: dateFormatter,
          suppressColumnsToolPanel: isCSR,
          suppressFiltersToolPanel: isCSR,
          hide: isCSR
        },
        {
          field: "lastSentDate",
          headerName: addTimeZoneToHeader(this.sendToCustomerLabel),
          headerClass: "ag-text-header",
          editable: false,
          cellClass: "xmm-wrap-text",
          minWidth: 220,
          width: 175,
          maxWidth: 250,
          type: ["dateColumn", "nonEditableColumn"],
          valueFormatter: dateFormatter,
          suppressColumnsToolPanel: isCSR,
          suppressFiltersToolPanel: isCSR,
          hide: isCSR
        },
        {
          field: "createdBy",
          headerName: this.createdByLabel,
          editable: false,
          minWidth: 180,
          width: 200,
          maxWidth: 250,
          cellClass: "xmm-wrap-text",
          filter: "agSetColumnFilter",
          filterParams: {
            buttons: ["clear"]
          },
          suppressColumnsToolPanel: isCSR,
          suppressFiltersToolPanel: isCSR,
          hide: isCSR
        },
        {
          field: "items",
          headerName: this.servicesLabel,
          headerClass: "ag-text-header",
          cellClass: "xmm-wrap-cell",
          type: "numberColumn",
          suppressSizeToFit: true,
          valueParser: numberParser,
          minWidth: isCSR ? 130 : 120,
          width: isCSR ? 130 : 90,
          maxWidth: isCSR ? 130 : 130,
          hide: true
        },
        {
          field: "totalPrice",
          headerName: this.totalLabel,
          editable: false,
          type: "numberColumn",
          cellClass: "xmm-grid-price",
          cellStyle: { "text-align": "right" },
          headerClass: isCSR ? null : "ag-right-aligned-header",
          valueFormatter: priceFormatter,
          valueParser: numberParser,
          minWidth: isCSR ? 130 : 100,
          width: 130,
          maxWidth: 150,
          hide: this.state?.quoteStatus === "W"
        },
        {
          field: "notes",
          headerName: this.notesLabel,
          headerClass: "ag-text-header",
          sortingOrder: ["asc", "desc"],
          cellClass: "quote-notes",
          minWidth: 100,
          maxWidth: 120,
          suppressColumnsToolPanel: isCSR,
          suppressFiltersToolPanel: isCSR,
          filter: false,
          hide: isCSR,
          valueFormatter: blankValueFormatter,
          cellRendererFramework: this.renderQuoteNotes
        },
        {
          field: "quoteExpirationDateTime",
          headerName: this.expirationDateLabel,
          headerClass: "ag-text-header",
          editable: false,
          cellClass: "xmm-wrap-text",
          minWidth: 170,
          width: 170,
          maxWidth: 250,
          type: ["dateColumn", "nonEditableColumn"],
          valueFormatter: expirationDateFormatter,
          suppressColumnsToolPanel: isCSR,
          suppressFiltersToolPanel: isCSR,
          hide: true
        },
        {
          field: "vin",
          headerName: this.vinLabel,
          editable: false,
          sortable: true,
          minWidth: isCSR ? 120 : 150,
          width: isCSR ? 120 : 180,
          maxWidth: isCSR ? 120 : 220,
          cellClass: "xmm-wrap-text",
          sortingOrder: ["asc", "desc"],
          tooltipField: isCSR ? "vin" : null,
          keyCreator: this.keyCreator,
          cellRendererFramework: params => {
            if (isCSR) {
              return params.value
                ? `...${params?.value.substring(params?.value.length - 7)}`
                : "- -";
            } else {
              return params.value;
            }
          },
          hide: !isCSR
        },
        {
          field: "hangTag",
          headerName: "Tag",
          editable: false,
          sortable: true,
          minWidth: 100,
          width: 100,
          maxWidth: 100,
          cellClass: "xmm-wrap-text",
          sortingOrder: ["asc", "desc"],
          cellRendererFramework: blankValueFormatter,
          keyCreator: this.keyCreator,
          suppressColumnsToolPanel: !isCSR,
          suppressFiltersToolPanel: !isCSR,
          hide: !isCSR
        },
        {
          field: "pay",
          headerName: "P",
          editable: false,
          sortable: true,
          minWidth: 100,
          width: 100,
          maxWidth: 100,
          cellClass: "xmm-wrap-text",
          sortingOrder: ["asc", "desc"],
          suppressColumnsToolPanel: !isCSR,
          suppressFiltersToolPanel: !isCSR,
          hide: !isCSR
        },
        {
          headerName: "",
          resizable: !isCSR,
          sortable: !isCSR,
          pinned: "right",
          editable: true,
          cellClass: "editable-cell",
          cellEditorSelector(params) {
            if (isCSR) {
              return { component: "csrActionMenu" };
            }
            const { quoteStatus } = params.data;
            const allowEdit = allowEditAccessFromAction(quoteStatus);
            const allowDelete =
              quoteStatus === "IN_PROGRESS" || quoteStatus === "EXPIRED";
            const vehicleExist = verifyVehicleYMM(params.data);
            if ((allowEdit && vehicleExist) || allowDelete) {
              if (allowEditCSR(quoteStatus)) {
                return { component: "csrActionMenu" };
              }
              return { component: "quoteActionMenu" };
            }
            params.api.stopEditing();
            return null;
          },
          cellEditorParams: {
            parentHandle: this
          },
          cellRendererFramework: this.renderActionButton,
          enableRowGroup: false,
          maxWidth: 65,
          minWidth: 65,
          width: 65,
          filter: false,
          suppressSizeToFit: true,
          suppressMenu: true,
          suppressColumnsToolPanel: true
        }
      ];
    }
    return baseCols;
  }

  autoGroupColumnDef = {
    headerName: this.techWorkingStatusLabel,
    field: "technicianWorkingStatus",
    cellRenderer: "agGroupCellRenderer",
    cellRendererParams: {
      suppressCount: true
    }
  };

  renderActionButton(params) {
    if (!params.data) {
      return "";
    }
    const { quoteStatus } = params.data;
    const { isCSR } = this.state;

    // const customerExist = !doesEmpty(customerId) && !doesEmpty(vehicleId);
    const vehicleExist = verifyVehicleYMM(params.data);
    const allowEdit = allowEditAccessFromAction(quoteStatus);
    const allowDelete =
      quoteStatus === "IN_PROGRESS" || quoteStatus === "EXPIRED";
    return isCSR || (vehicleExist && allowEdit) || allowDelete ? (
      <div className="grid-action-menu-renderer">
        <IconMore isActive={true} />
      </div>
    ) : (
      ""
    );
  }
  partStatusRenderer = params => {
    if (!params.data) {
      return "--";
    }
    return csrService.buildPartStatusBadge(params?.value);
  };

  technicianStatusRenderer = params => {
    if (!params.data) {
      return "--";
    }
    return this.buildTechnicianStatusBadge(params?.value);
  };

  buildTechnicianStatusBadge = techStatus => {
    if (!techStatus) {
      return null;
    }
    return (
      <Badge
        color={
          techStatus === technicianStatus.WORKING
            ? "blue"
            : techStatus === technicianStatus.STOP
            ? "purple"
            : "gray"
        }
        htmlId="partStatusBadge"
      >
        {techStatus}
      </Badge>
    );
  };

  // Custom comparator function for techStatus
  techStatusComparator = (valueA, valueB) => {
    // Define the desired order
    const order = [
      technicianStatus.NOT_STARTED,
      technicianStatus.WORKING,
      technicianStatus.HOLD_PARTS,
      technicianStatus.HOLD_APPROVAL,
      technicianStatus.BREAK,
      technicianStatus.STOP
    ];

    // Get the indices of the values in the order array
    const indexA = order.indexOf(valueA.key);
    const indexB = order.indexOf(valueB.key);

    // Handle cases where the value is not in the order array
    if (indexA === -1) return 1;
    if (indexB === -1) return -1;

    return indexA - indexB;
  };

  // renderer for customer with tooltip
  renderCustomer = params => {
    if (!params.data) {
      return "";
    }
    const { customer, customerId, email } = params.data;
    if (!customer) {
      return this.state.isCSRAdvisorView ? "- -" : "";
    } else {
      const contentHTML = (
        <div className="sq-tooltip-card">
          <div className="sq-tooltip-card__header">{customer}</div>
          <ul className="tip-row">
            <li>Email: {email}</li>
          </ul>
        </div>
      );
      const renderHTML = (
        <span className="sq-flex-container">
          <Tooltip
            htmlId={`id_${customerId}`}
            tooltipContent={contentHTML}
            position="left"
            className="sq-tooltip-list"
          >
            {customer?.toString()}
          </Tooltip>
        </span>
      );
      return renderHTML;
    }
  };
  // renderer for vehicle cell with tooltip
  renderVehicle = params => {
    if (!params.data) {
      return "";
    }
    const { vehicle, vehicleId, vin, mileage } = params.data;
    const quote = params.data;
    if (!vehicle) {
      return this.state.isCSRAdvisorView ? "- -" : "";
    } else {
      const mileageVal = formatNumberWithThousands(mileage || "");
      const vehicleInfo = `${quote?.trim || ""} ${quote?.engineSize || ""} ${
        quote?.engineType || ""
      } ${quote.transmissionType || ""}`;
      const contentHTML = (
        <div className="sq-tooltip-card">
          <div className="sq-tooltip-card__header">{vehicle}</div>
          <ul className="tip-row">
            <li>Trim: {vehicleInfo}</li>
            <li>VIN: {vin || ""}</li>
            <li>
              Mileage: {mileageVal} {!mileageVal ? "" : "miles"}
            </li>
          </ul>
        </div>
      );
      const renderHTML = (
        <span className="sq-flex-container">
          <Tooltip
            htmlId={`id_${vehicleId}`}
            tooltipContent={contentHTML}
            className="sq-tooltip-list"
            position="left"
          >
            {vehicle}
          </Tooltip>
        </span>
      );
      return renderHTML;
    }
  };
  renderStatusBadge(params) {
    if (!params.data) {
      return "";
    }
    const { quoteStatus } = params.data;
    if (!quoteStatus) {
      return this.state.isCSRAdvisorView ? "- -" : "";
    }
    const { isCSR } = this.state;
    const colorProp = getStatusBadgeColor(quoteStatus);
    return isCSR && (params?.data?.techStatus || params?.data?.partsStatus) ? (
      <RichTooltip
        elementContent={
          <div className="ro-badge-lbl">
            <Badge htmlId="statusBadge" color={colorProp}>
              {QuoteStatusMap[quoteStatus]}
            </Badge>
          </div>
        }
        tooltipContent={
          <div className="ro-status-tooltip">
            <div className="ro-status">RO status</div>
            <div className="tech-status">
              Tech status: {params.data.techStatus ?? "- -"}
            </div>
            <div className="parts-status">
              Parts status: {params.data.partsStatus ?? "- -"}
            </div>
          </div>
        }
      />
    ) : (
      <div className="quote-badge-lbl">
        <Badge htmlId="stausBadge" color={colorProp}>
          {QuoteStatusMap[quoteStatus]}
        </Badge>
      </div>
    );
  }
  renderAttentionBadge(params) {
    if (!params.data) {
      return "";
    }
    const { workflowAttentionTag } = params.data;
    // When request is completed attentionTagsArray will rerun an empty string inside of the array
    const attentionTagsArray =
      extractAndFormatAttentionTags(workflowAttentionTag);
    const attentionBadges =
      !isEmpty(attentionTagsArray) && Array.isArray(attentionTagsArray)
        ? attentionTagsArray.map((tag, index) => {
            if (!isEmpty(tag)) {
              return (
                <div
                  key={`tag-${index + 1}`}
                  className="quote-badge-lbl--attention"
                >
                  <Badge htmlId="stausBadge" color="red">
                    {tag}
                  </Badge>
                </div>
              );
            } else {
              // Handles array when contains empty strings
              const filteredArray = attentionTagsArray.filter(
                item => item !== ""
              );
              return isEmpty(filteredArray) ? "- -" : null;
            }
          })
        : "- -";

    return (
      <div className="attention-badges-container">
        {hasTwoEqualStrings(attentionBadges)}
      </div>
    );
  }

  WarningRenderer = () => {
    return (
      <div style={{ display: "flex", alignItems: "center", marginTop: "5px" }}>
        <IconWarning
          className="icon-warning-tech-view"
          htmlId="IconWarningExample"
        />
        <span
          style={{
            fontSize: "12",
            color: "#16171A",
            fontWeight: "400",
            wordWrap: "break-word"
          }}
        >
          There is time running on repair order - Remember to stop the clock
          when finished.
        </span>
      </div>
    );
  };

  renderQuoteNotes(params) {
    if (!params.data) {
      return "";
    }
    const { data } = params;
    const iconColor = !isEmpty(data.notes) ? "#2B6BDD" : "#CACFD9";
    const tooltipMessage = data.notes
      ? xlate("sq.pastquotes.review_notes_tooltip")
      : xlate("sq.pastquotes.add_notes_tooltip");
    return (
      <div className="quote-notes__container">
        <Tooltip
          htmlId="quoteNotesTooltip"
          tooltipContent={tooltipMessage}
          position="top"
        >
          <IconNote
            htmlId="notesIcon"
            className="quote-notes__icon"
            color={iconColor}
          />
        </Tooltip>
      </div>
    );
  }
  async blockUntilCompleted(asyncFunction) {
    try {
      this.setState({ gridPageMask: true });
      await asyncFunction();
    } catch (e) {
      console.error(e);
    } finally {
      this.setState({ gridPageMask: false });
    }
  }
  documentsActionHandler(quote) {
    this.setState({
      documentsQuote: quote,
      showDocumentsModal: true
    });
  }
  async searchArchiveHandler() {
    await this.blockUntilCompleted(async () => {
      this.setState({ showDocumentsModal: false });
      const documentsURL = await csrService.getDocumentsURL({
        dealerCode: this.state.documentsQuote.dealerCode,
        csrnumber: this.state.documentsQuote.confirmationId
      });
      window.open(documentsURL);
    });
  }
  async printSelectedDocumentsHandler(docNames) {
    await this.blockUntilCompleted(async () => {
      this.setState({ showDocumentsModal: false });
      await csrService.printSelectedDocuments(
        this.state.documentsQuote.dealerCode,
        this.state.documentsQuote.quoteId,
        docNames,
        this.state?.documentsQuote
      );
    });
  }
  editActionHandler(quote) {
    const { quoteStatus, vehicleId, customerId, confirmationId } = quote;
    const { dealer } = this.context;
    const allowEdit = this.allowEditAccessFromLink(quoteStatus);
    // TODO: Fix - skip customer flow, allow edit if YMM data exist for vehicle.
    const vehicleExist = verifyVehicleYMM(quote);
    if (vehicleExist && allowEdit) {
      const quoteParams = {
        accessType: !confirmationId ? null : "EDIT",
        quoteId: confirmationId,
        vehicleId,
        customerId
      };
      this.context.updateQuoteParams(quoteParams);
      gtmEvent.trackGAEventWithParam("ga.dashboard.edit_quote_click", {
        result: dealer.dealerCode
      });
      // @todo: quote params should update app context before opening slider
      sleep(1000).then(() => {
        // show slider for quote status in-progress, completed
        this.setState({
          showSlide: true,
          selectedQuoteId: confirmationId,
          quoteRecord: quote
        });
      });
    }
  }
  async copyActionHandler(quote) {
    // eslint-disable-next-line unused-imports/no-unused-vars
    const { dealer, user } = this.context;
    const copiedQuote = await copyQuote(
      quote,
      user.quoteUserId,
      dealer.dealerCode
    );
    const { quoteStatus, customer, vehicle, confirmationId } = copiedQuote;
    const { personId: customerId } = customer;
    const { vehicleId } = vehicle;
    const allowEdit = allowCopyAccess(customerId, vehicleId, quoteStatus);
    // TODO: Fix - skip customer flow, allow edit if YMM data exist for vehicle.
    const vehicleExist = verifyVehicleYMM(quote);
    if (vehicleExist && allowEdit) {
      this.context.handleSliderStatus(true);
      const quoteParams = {
        accessType: !confirmationId ? null : "EDIT",
        quoteId: confirmationId,
        vehicleId,
        customerId
      };
      this.context.updateQuoteParams(quoteParams);
      gtmEvent.trackGAEvent("ga.dashboard.copy_quote_click");
      // @todo: quote params should update app context before opening slider
      sleep(1000).then(() => {
        // show slider for quote status in-progress, completed
        this.setState({
          showSlide: true,
          selectedQuoteId: confirmationId,
          quoteRecord: quote
        });
      });
    }
  }
  deleteActionHandler(quote) {
    gtmEvent.trackGAEvent("ga.dashboard.delete_quote_click");
    this.setState({
      showPopup: true,
      selectedQuoteId: quote.confirmationId
    });
  }
  // Quick filter handler
  /**
   * Handles changes to the search input box, updating the grid filter based on the user's input.
   * It also manages the state and clears any grid selections.
   *
   * @param {Event} event - The event object from the input change.
   */
  onSearchBoxChanged = event => {
    // Prevent the default action if event object is present
    if (event) {
      event.preventDefault();
    }
    // Only proceed if gridApi is available
    if (this.gridApi) {
      const searchKey = document.querySelector("#past-quotes-search-box").value;
      const searchValue = searchKey.toLowerCase().toString().trim();
      this.gridApi.setQuickFilter(searchValue);
      // Clear any previous selections in the grid
      this.clearGridSelections();
      // Update the component's state with the new search key
      this.setState({
        searchKey
      });
    }
  };

  /* Handler to filter based on date range */
  onDateViewChange = cxEvent => {
    const { value } = cxEvent.target;
    this.setState({
      dateRange: value.toString()
    });
    const status = this.state.quoteStatus;
    this.getPastQuotesSource(value.toString(), status);
    gtmEvent.trackGAEventWithParam("ga.dashboard.select_day_filter", {
      result: value.toString()
    });
  };
  // handler to apply server-sider filtering
  onStatusChange = cxEvent => {
    const { value, name } = cxEvent.target;
    const filterValue = !value ? "ALL" : value;
    this.setState(
      {
        [name]: filterValue
      },
      () => {
        this.applyStatusFilter(filterValue);
        gtmEvent.trackGAEventWithParam(
          "ga.dashboard.select_quote_status_filter",
          { result: filterValue }
        );
        this.sizeToFit();
      }
    );
  };
  // call rest api to load quotes and set quoteStatus filter value
  applyStatusFilter = filterVal => {
    const dateRange = this.state.dateRange;
    const status = !filterVal || filterVal === "ALL" ? "" : filterVal;
    this.getPastQuotesSource(dateRange, status);
  };

  //* for assigning parts counterperson to RO on ERP when parts person click on RO details
  onAssignPartsCounterPersonConfirm = async () => {
    const quoteData = this.state.clickedRORecord;
    this.setState({ gridPageMask: true });
    try {
      await assignPartCounterPersonToRO({
        appContext: this.context,
        quote: quoteData
      });
      toast.success(`RO ${quoteData.roNumber} is assigned to you`, {
        autoClose: 4000,
        closeOnClick: true
      });
    } catch (error) {
      console.error(error);
      toast.error(
        "This RO has not been assigned to you due to some error. Please try again",
        {
          autoClose: 2000,
          closeOnClick: true
        }
      );
    } finally {
      this.setState({ gridPageMask: false });
    }
    this.openSlider(quoteData);
    this.context.handleSliderStatus(true);
    this.setState({ showPartsConfirmPopup: false, clickedRORecord: null });
  };

  closeAssignPartsCounterPersonConfirm = () => {
    this.setState({ showPartsConfirmPopup: false, clickedRORecord: null });
  };

  closeDeleteModal = () => {
    this.setState({
      showPopup: false
    });
  };

  deleteQuoteHandler = async () => {
    const { dealer } = this.context;
    const { dealerCode } = dealer;
    try {
      await deleteQuote(dealerCode, this.state.selectedQuoteId);
      await this.refreshGridData(true);
    } catch (error) {
      console.error(error);
    }
    this.setState({
      showPopup: false
    });
  };
  // We call this to refresh the row for a quote which has assistance has been rquested/resolved for
  callRefreshAfterMillis(params, gridApi, timeout) {
    setTimeout(function () {
      gridApi && gridApi.refreshCells(params);
    }, timeout);
  }
  // We call this to hide fail banners after 30 seconds
  hideDefaultTimeoutBanner(type) {
    const { handleAssistanceRequestBanner } = this.context;
    setTimeout(function () {
      handleAssistanceRequestBanner(type, false);
    }, 30000);
  }
  // We call this to hide success banners after 5 seconds
  hideAssistanceBanner(type) {
    sleep(5000).then(() => {
      const { handleAssistanceRequestBanner } = this.context;
      handleAssistanceRequestBanner(type, false);
    });
  }
  /**
   *
   *This methods handles calling quoteAssistance api to update quote
   with quoteTransferWorkflowTags to the quote object. It also update status
   of menu option items and Banner for assistance workflow.
   */
  async handleQuoteAssistance(confirmationId, actionStatus, type) {
    const {
      dealer,
      webKey,
      user,
      updateAssistanceStatus,
      handleAssistanceRequestBanner
    } = this.context;

    let requestStatus = actionStatus;
    showBodyMask();
    this.gridApi && this.gridApi.showLoadingOverlay();
    await quoteAssistanceService
      .processQuoteAssistance(
        user,
        dealer.dealerCode,
        confirmationId,
        actionStatus,
        type,
        webKey
      )
      .then(response => {
        if (response) {
          if (this.gridApi) {
            const rowNode =
              this.gridApi && this.gridApi.getRowNode(confirmationId);
            if (rowNode) {
              const workflowAttentionTag =
                response?.quoteTransfer?.workflowAttentionTag || [];
              rowNode.data["workflowAttentionTag"] = workflowAttentionTag;
              rowNode.data["quoteStatus"] =
                response?.quoteTransfer?.quoteStatus || "";
              rowNode.setData(rowNode.data);
              // rowNodes expects an array
              const rowNodes = [rowNode];
              const params = {
                force: true,
                columns: ["workflowAttentionTag"],
                rowNodes
              };
              this.callRefreshAfterMillis(params, this.gridApi, 10);
              const tagObj = workflowAttentionTag.find(
                tags => tags.type === type
              );
              const state =
                workflowAttentionTag.find(
                  tag => tag?.quoteAssistanceType?.toLowerCase() === type
                )?.quoteAssistanceState || assistanceRequestStatus.REQUEST;

              requestStatus = !tagObj
                ? assistanceRequestStatus.REQUESTED
                : assistanceRequestStatus.COMPLETED;
              updateAssistanceStatus({
                type,
                requestStatus,
                confirmationId,
                state
              });
              handleAssistanceRequestBanner(type, true);
              this.hideAssistanceBanner(type);
            }
          }
          this.gridApi && this.gridApi.hideOverlay();
          hideBodyMask();
        }
      })
      .catch(error => {
        console.error(error);
        const failType =
          requestStatus === assistanceRequestStatus.REQUESTED
            ? "requested_fail"
            : "completed_fail";
        handleAssistanceRequestBanner(failType, true);
        this.gridApi && this.gridApi.hideOverlay();
        hideBodyMask();
        this.hideDefaultTimeoutBanner(failType);
      });
  }
  // common handler - used to open Notes modal by clicking on Action Menu vs Notes column
  openQuoteNotesModal = record => {
    this.setState({
      showNotesModal: true,
      selectedQuoteId: record.confirmationId,
      quoteRecord: record
    });
  };
  updateQuoteNotesHandler = async notes => {
    this.callbackUpdateQuoteAPI(notes, this.state.quoteRecord);
    this.setState(
      { showNotesModal: false, selectedQuoteId: null, quoteRecord: null },
      () => {}
    );
  };

  closeQuoteNotesModal = () => {
    this.setState({
      showNotesModal: false,
      selectedQuoteId: null,
      quoteRecord: null
    });
  };

  openSendEmailTextModal = record => {
    this.setState(
      {
        showSendEmailTextModal: true,
        selectedQuoteId: record.confirmationId,
        quoteRecord: record
      },
      () => {
        this.context.handleSendQuoteToCustommerBanner(false);
      }
    );
  };
  updateSendEmailTextHandler = () => {
    this.setState(
      {
        showSendEmailTextModal: false,
        selectedQuoteId: null,
        quoteRecord: null
      },
      () => {
        this.context.handleSendQuoteToCustommerBanner(true);
        this.refreshGridData(true);
      }
    );

    sleep(5000).then(() => {
      const { handleSendQuoteToCustommerBanner } = this.context;
      handleSendQuoteToCustommerBanner(false);
    });
  };

  closeSendEmailTextModal = () => {
    this.setState({
      showSendEmailTextModal: false,
      selectedQuoteId: null,
      quoteRecord: null
    });
  };

  openConfirmationAlertPopup = (
    record,
    handleAlertPopupCallback,
    stopUpdateQuoteStatus = false
  ) => {
    this.setState({
      showAlertPopup: true,
      selectedQuoteId: record.confirmationId,
      quoteRecord: record,
      handleAlertPopupCallback,
      stopUpdateQuoteStatus
    });
  };

  closeConfirmationAlertPopup = () => {
    this.setState({
      showAlertPopup: false,
      selectedQuoteId: null,
      quoteRecord: null,
      handleAlertPopupCallback: () => {},
      stopUpdateQuoteStatus: false
    });
  };

  callbackUpdateQuoteAPI = async (notesData, quoteRecord) => {
    try {
      const { dealer, user } = this.context;
      const { dealerCode } = dealer;
      const { confirmationId } = quoteRecord;
      const notesObj = { note: !notesData ? "" : notesData };
      const notesArray = !notesData ? null : [notesObj];
      // @note: read response when quote status updated in DB
      showBodyMask();
      this.gridApi && this.gridApi.showLoadingOverlay();
      const response = await pastQuotesService.updatePastQuoteNotes({
        dealerCode,
        confirmationId,
        lastModByUserId: user.quoteUserId,
        notes: notesArray
      });
      if (!isEmpty(response)) {
        const { confirmationId, notes } = response;
        if (this.gridApi) {
          const rowNode =
            this.gridApi && this.gridApi.getRowNode(confirmationId);
          if (rowNode) {
            rowNode.data["notes"] = extractNotes(notes);
            rowNode.setData(rowNode.data);
            // rowNodes expects an array
            const rowNodes = [rowNode];
            const params = {
              force: true,
              columns: ["notes"],
              rowNodes
            };
            this.callRefreshAfterMillis(params, this.gridApi, 10);
          }
        }
        this.gridApi && this.gridApi.hideOverlay();
        hideBodyMask();
      }
    } catch (error) {
      this.gridApi && this.gridApi.hideOverlay();
      hideBodyMask();
      console.error(error);
    }
  };
  render() {
    const { isPartsView, localeStrings, userPermissions } = this.context;
    const { isCSR } = this.state;

    const actionOptions = [
      {
        label: "Refresh",
        value: "refresh",
        onSelect: this.refreshButtonHandler
      },
      {
        label: "Toggle quote statistics",
        value: this.state.showStatistics
          ? "hide-statistics"
          : "show-statistics",
        onSelect: this.toggleStatistics
      }
    ];

    // Remove condition when testing is finished
    if (isCSR) {
      actionOptions.splice(1, 1);
    }

    // @csr-logic
    const newQuotebtn = isCSR ? null : (
      <NewQuoteBtn disabled={!userPermissions.canCreateQuote} />
    );

    const clsShowQuoting = isCSR ? "hide" : "";

    const getQuoteStatusSelectOption = () => {
      let options = [];
      if (isCSR) {
        if (this.state.isTechView) {
          options = [
            {
              value: "ALL",
              label: QuoteStatusMap["ALL"]
            },
            {
              value: "IN_PROCESS",
              label: QuoteStatusMap["IN_PROCESS"]
            },
            {
              value: "WORK_FINISHED",
              label: QuoteStatusMap["WORK_FINISHED"]
            },
            {
              value: "PRE_INVOICE",
              label: QuoteStatusMap["PRE_INVOICE"]
            },
            {
              value: "FINALIZED",
              label: QuoteStatusMap["FINALIZED"]
            }
          ];
        } else
          options = [
            {
              value: "ALL",
              label: QuoteStatusMap["ALL"]
            },
            {
              value: "WITH_ADVISOR",
              label: QuoteStatusMap["WITH_ADVISOR"]
            },
            {
              value: "IN_PROCESS",
              label: QuoteStatusMap["IN_PROCESS"]
            },
            {
              value: "WORK_FINISHED",
              label: QuoteStatusMap["WORK_FINISHED"]
            },
            {
              value: "PRE_INVOICE",
              label: QuoteStatusMap["PRE_INVOICE"]
            },
            {
              value: "FINALIZED",
              label: QuoteStatusMap["FINALIZED"]
            }
          ];
        if (this.context.dealerProperties?.ENGG_USE_OEM_WARRANTY === YES) {
          options.push({
            value: "W",
            label: "Warranty"
          });
        }
      } else {
        options = [
          {
            value: "ALL",
            label: QuoteStatusMap["ALL"]
          },
          {
            value: "IN_PROGRESS",
            label: QuoteStatusMap["IN_PROGRESS"]
          },
          {
            value: "REQUEST_ASSISTANCE",
            label: QuoteStatusMap["REQUEST_ASSISTANCE"]
          },
          {
            value: "READY_TO_SEND",
            label: QuoteStatusMap["READY_TO_SEND"]
          },
          {
            value: "SENT",
            label: QuoteStatusMap["SENT"]
          },
          {
            value: "EXPIRED",
            label: QuoteStatusMap["EXPIRED"]
          },
          {
            value: "CONVERTED_TO_APPOINTMENT",
            label: QuoteStatusMap["CONVERTED_TO_APPOINTMENT"]
          }
        ];
      }
      return options;
    };

    const header = (
      <div className="content-header">
        <div className="sq-left-items">
          <div className={clsShowQuoting}>
            <SelectInput
              htmlId="DateRangeSelect"
              placeholder="Select"
              className="cx-select-date-range"
              disabled={false}
              displayLabel={false}
              displayPlaceholder={false}
              displayDeselectOption={false}
              maxHeight={100}
              name="viewMode"
              onChange={this.onDateViewChange}
              label="View"
              value={this.state.dateRange}
              options={[
                {
                  value: "7",
                  label: "Last 7 days"
                },
                {
                  value: "30",
                  label: "Last 30 days"
                },
                {
                  value: "90",
                  label: "Last 90 days"
                }
              ]}
            />
          </div>
          <SelectInput
            htmlId="quoteStatusSelect"
            className="cx-select-quote-status"
            placeholder="Select"
            disabled={false}
            displayDeselectOption={false}
            displayLabel={false}
            displayPlaceholder={false}
            maxHeight={180}
            name="quoteStatus"
            onChange={this.onStatusChange}
            label="Status"
            value={this.state.quoteStatus}
            options={getQuoteStatusSelectOption()}
          />
        </div>
        <div className="sq-right-items">
          <div className="xmm-input-search  float-right">
            <input
              type="text"
              id="past-quotes-search-box"
              className="xmm-input sq-input-width"
              placeholder={
                isPartsView ? "Search repair orders" : "Filter by..."
              }
              onChange={this.onSearchBoxChanged}
              value={this.state.searchKey}
              autoComplete="off"
            />
            <i className="fa fa-search test" aria-hidden="true" />
          </div>
          <div className="float-right">{newQuotebtn}</div>
          <Dropdown
            icon={<IconMore />}
            htmlId="pastquotesActionBtn"
            name="pastquotesActionBtn"
            className="xmm-dotted-dropdown btn--icon"
            buttonStyle="link"
            displayCaret={false}
            size="small"
            options={actionOptions}
            pullRight
          />
        </div>
      </div>
    );
    // @tbd: add config prop to Dashboard component
    const dashboard = (
      <DashboardProvider
        serviceTypes={
          !isEmpty(this.state.serviceTypes) ? this.state.serviceTypes : []
        }
        quotes={!isEmpty(this.state.rowData) ? this.state.rowData : []}
        localeStrings={localeStrings}
        dateRange={this.state.dateRange}
      />
    );
    const apiFailureDashboard = (
      <ApiFailureDashboard
        apiStatus={this.state.noRowsOverlayComponentParams.apiStatus}
        localeStrings={localeStrings}
      />
    );

    const title = isCSR
      ? "RO Detail"
      : localeStrings["sq.pastquotes.edit_quote_lbl"];

    const quoteSlider = (
      <QuoteSlider
        title={title}
        showSlide={this.state.showSlide}
        sliderWidth={this.state.sliderWidth}
        flexWidth={this.state.flexWidth}
        closeModal={this.closeSliderPage}
        okAction={this.callbackActionHandler}
      />
    );

    const deleteConfirm = (
      <ConfirmPopup
        title={localeStrings["sq.pastquotes.delete_quote_lbl"]}
        message={localeStrings["sq.pastquote.delete_quote_msg"]}
        show={this.state.showPopup}
        okText={localeStrings["sq.pastquote.delete_quote_ok_button"]}
        cancelText={
          localeStrings["sq.newquote.location_bar.restart_modal_cancel_button"]
        }
        okAction={this.deleteQuoteHandler}
        cancelAction={this.closeDeleteModal}
        hideCancel={false}
        hideOk={false}
        buttonStyle="danger"
      />
    );

    const partsCounterPersonAssignConfirm = (
      <ConfirmPopup
        title="Alert !"
        message="By clicking on that you will be assigned this RO. Do you want to continue? "
        show={this.state.showPartsConfirmPopup}
        okText="Assign"
        cancelText="Cancel"
        okAction={this.onAssignPartsCounterPersonConfirm}
        cancelAction={this.closeAssignPartsCounterPersonConfirm}
        hideCancel={false}
        hideOk={false}
        buttonStyle="primary"
      />
    );

    const serviceRequestBanner = (
      <RequestBanner
        requestType="service"
        requestStatus={this.context.serviceAssistanceStatus.requestStatus}
        confirmationId={this.context.serviceAssistanceStatus.confirmationId}
        state={this.context.serviceAssistanceStatus.state}
        onCloseBanner={() =>
          this.context.handleAssistanceRequestBanner("service", false)
        }
        bannerType="success"
      />
    );
    const partsRequestBanner = (
      <RequestBanner
        requestType="parts"
        requestStatus={this.context.partsAssistanceStatus.requestStatus}
        confirmationId={this.context.partsAssistanceStatus.confirmationId}
        state={this.context.partsAssistanceStatus.state}
        onCloseBanner={() =>
          this.context.handleAssistanceRequestBanner("parts", false)
        }
        bannerType="success"
      />
    );
    const requestedFailBanner = (
      <RequestBanner
        requestType="requestedFail"
        requestStatus={assistanceRequestStatus.REQUESTED_FAIL}
        onCloseBanner={() =>
          this.context.handleAssistanceRequestBanner("requested_fail", false)
        }
        bannerType="error"
      />
    );
    const completedFailBanner = (
      <RequestBanner
        requestType="completedFail"
        requestStatus={assistanceRequestStatus.COMPLETED_FAIL}
        onCloseBanner={() =>
          this.context.handleAssistanceRequestBanner("completed_fail", false)
        }
        bannerType="error"
      />
    );
    const sendQuoteToCustomerBanner = (
      <AlertBox
        htmlId="sentQuoteToCustomer"
        type="success"
        closeButton={true}
        message="Quote successfully sent"
        onClose={() => this.context.handleSendQuoteToCustommerBanner(false)}
      />
    );
    // common notes modal to render when "Add Notes" action menu clicked for each grid row level
    const quoteNotesModal = this.state.showNotesModal && (
      <NotesTextAreaModal
        quoteId={
          !this.state.quoteRecord ? null : this.state.quoteRecord.confirmationId
        }
        notes={!this.state.quoteRecord ? null : this.state.quoteRecord.notes}
        okAction={this.updateQuoteNotesHandler}
        cancelAction={this.closeQuoteNotesModal}
        showModal={this.state.showNotesModal}
        legend={localeStrings["sq.pastquotes.add_notes.legend"]}
        notesTitle={localeStrings["sq.pastquotes.add_notes.notes"]}
        quoteStatus={
          !this.state.quoteRecord ? null : this.state.quoteRecord.quoteStatus
        }
      />
    );
    const sendEmailTextModal = this.state.showSendEmailTextModal && (
      <SendEmailTextModal
        data={{
          confirmationId: this.state.quoteRecord?.confirmationId,
          email: this.state.quoteRecord?.email,
          mobile: this.state.quoteRecord?.mobilePhone
        }}
        okAction={this.updateSendEmailTextHandler}
        cancelAction={this.closeSendEmailTextModal}
        showModal={this.state.showSendEmailTextModal}
      />
    );

    const confirmationAlertPopup = this.state.showAlertPopup && (
      <ConfirmationAlertPopup
        quoteSummary={this.state.quoteRecord}
        okAction={this.state.handleAlertPopupCallback}
        cancelAction={this.closeConfirmationAlertPopup}
        showConfirmation={this.state.showAlertPopup}
        stopUpdateQuoteStatus={this.state.stopUpdateQuoteStatus}
      />
    );
    return (
      <>
        {this.context.showResolvingRequestFailBanner
          ? completedFailBanner
          : null}
        {this.context.showAttentionRequestFailBanner
          ? requestedFailBanner
          : null}
        {this.context.serviceAssistanceStatus.requestStatus !==
          assistanceRequestStatus.NOT_REQUESTED &&
        this.context.showServiceAssistanceRequestBanner
          ? serviceRequestBanner
          : null}
        {this.context.partsAssistanceStatus.requestStatus !==
          assistanceRequestStatus.NOT_REQUESTED &&
        this.context.showPartsAssistanceRequestBanner
          ? partsRequestBanner
          : null}
        {this.context.showSendQuoteToCustomerBanner
          ? sendQuoteToCustomerBanner
          : null}
        {header}
        {!this.state.hideApiFailuredashboard
          ? apiFailureDashboard
          : this.state.showStatistics
          ? dashboard
          : null}
        <div id="grid-wrapper">
          <div
            id="pastQuotesGrid"
            // @note - For Quoting - when dashboard show/hide use same ag-grid theme class "ag-theme-balham"
            // For CSR - ag-grid theme class should be "ag-theme-alpine" (dashbord hidden always)
            className={`ag-grid-container ag-theme-alpine ${
              this.state.showStatistics
                ? "grid-and-dashboard"
                : isCSR &&
                  !isPartsView &&
                  this.context.dealerProperties
                    ?.ENABLE_CSR_SEARCH_HISTORIC_RO === YES
                ? "active-quotes-grid"
                : "past-quotes-grid"
            }`}
          >
            <AgGridReact
              localeText={this.state.localeText}
              rowData={this.state.rowData}
              columnDefs={this.state.columnDefs}
              groupDisplayType="multipleColumns"
              groupRowsSticky={true}
              defaultColDef={this.state.defaultColDef}
              frameworkComponents={this.state.frameworkComponents}
              loadingOverlayComponent={this.state.loadingOverlayComponent}
              loadingOverlayComponentParams={
                this.state.loadingOverlayComponentParams
              }
              noRowsOverlayComponent={this.state.noRowsOverlayComponent}
              noRowsOverlayComponentParams={
                this.state.noRowsOverlayComponentParams
              }
              autoGroupColumnDef={this.autoGroupColumnDef}
              groupRowRendererFramework={CustomTechViewGroupRenderer}
              onGridReady={this.onGridReady}
              onCellValueChanged={this.onCellValueChanged}
              onCellClicked={this.onCellClickedEvent}
              onColumnResized={this.handleColumnResized}
              onGridSizeChanged={this.handleGridSizeChanged}
              getRowNodeId={this.getRowNodeId}
              onRowSelected={this.handleRowSelected}
              rowHeight={50}
              statusBar={isPartsView ? false : this.state.statusBar}
              sideBar={this.state.sideBar}
              enterMovesDownAfterEdit={true}
              enterMovesDown={true}
              enableRangeSelection={false}
              enableCellTextSelection={true}
              // @note: true - use browser default tooltip instead of ag-grid tooltip
              enableBrowserTooltips={true}
              suppressMenuHide={false}
              suppressContextMenu={true}
              suppressRowClickSelection={true}
              singleClickEdit={true}
              animateRows={true}
              rowSelection={this.state.rowSelection}
              rowDeselection={true}
              columnTypes={this.state.columnTypes}
              multiSortKey={this.state.multiSortKey}
              // @note: for ERP Grouping
              groupUseEntireRow={true}
              // * @csr: parts view sorting in desc order
              defaultGroupSortComparator={
                this.state.isTechView
                  ? this.techStatusComparator
                  : this.sortComparator
              }
              groupDefaultExpanded={
                isPartsView || this.state.isTechView ? 1 : 0
              }
              // rowGroupPanelShow={'always'}   // used for adding row group panel on top
            />
          </div>
        </div>
        {quoteSlider}
        {deleteConfirm}
        {quoteNotesModal}
        {sendEmailTextModal}
        {confirmationAlertPopup}
        {partsCounterPersonAssignConfirm}
        {this.state.gridPageMask ? <BodyMask loadingText="" /> : null}
        {this.state.showDocumentsModal ? (
          <DocumentsModal
            show={this.state.showDocumentsModal}
            quoteSummary={this.state.documentsQuote}
            closeHandler={() => this.setState({ showDocumentsModal: false })}
            searchArchiveHandler={this.searchArchiveHandler}
            documentsActionHandler={this.documentsActionHandler}
            printSelectedDocumentsHandler={this.printSelectedDocumentsHandler}
            dealerProperties={this.context.dealerProperties}
          />
        ) : null}
      </>
    );
  }
}

export default PastQuotesGrid;
