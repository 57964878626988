import React, { useEffect, useContext, useState } from "react";
import PropTypes from "prop-types";
import ModalDialog from "@cx/ui/ModalDialog";
import { AppContext } from "../../state/app-context";
import ToggleSwitch from "@cx/ui/ToggleSwitch";
import TextArea from "@cx/ui/TextArea";
import "./dealer-settings-style.scss";
import Button from "@cx/ui/Button";
import NumericInput from "@cx/ui/NumericInput";
import useComponentDidMount from "../../hooks/useComponentDidMount";
import appServices from "../../services/app.service";
import { saveQuoteDealerSettingSvc } from "./services/quoteDealerSetting.service";
import isEmpty from "lodash/isEmpty";
import StatusBox from "../ui/banners/StatusBox";
import { DealerSettingsConstant } from "./constants";
import ServiceQuotingAssistance from "./service-quoting-assistance-component";

const DealerSettingsModal = props => {
  const {
    localeStrings,
    dealer,
    user,
    appType,
    getQuoteDealerSettings,
    quoteSetting
  } = useContext(AppContext);
  const [dirtyField, setDirtyField] = useState({
    quoteExpirationDays: false,
    displayLaborPartsDetails: false,
    displayLaborHours: false,
    displayPartNumber: false,
    disclaimer: false,
    quoteAssistance: false,
    serviceEmail: false,
    partsEmail: false
  });
  const [quoteDealerSettings, setQuoteDealerSettings] = useState([]);
  const [settings, setSettings] = useState([]);
  const [msg, setMsg] = useState("");
  const [tempRecord, setTempRecord] = useState({
    quoteExpirationDays: "30",
    displayLaborPartsDetails: true,
    displayLaborHours: true,
    displayPartNumber: true,
    disclaimer: DealerSettingsConstant.DEFAULT_DISCLAIMER_MSG,
    quoteAssistance: false,
    serviceEmail: "",
    partsEmail: ""
  });
  const [error, setError] = useState({
    quoteExpirationDays: false,
    disclaimer: false,
    serviceEmail: false,
    partsEmail: false
  });
  const [dealerQuoteExpirationSetting, setDealerQuoteExiprationSetting] =
    useState({
      settingId: null,
      dealerCode: dealer.dealerCode,
      settingValue: "",
      quoteDealerSettingId: null
    });
  const [dealerQuoteDisclaimerSetting, setDealerQuoteDisclaimerSetting] =
    useState({
      settingId: null,
      dealerCode: dealer.dealerCode,
      settingValue: "",
      quoteDealerSettingId: null
    });

  const [dealerQuoteDisplayLaborSetting, setDealerQuoteDisplayLaborSetting] =
    useState({
      settingId: null,
      dealerCode: dealer.dealerCode,
      settingValue: "true",
      quoteDealerSettingId: null
    });

  const [
    dealerQuoteDisplayPartNumberSetting,
    setDealerQuoteDisplayPartNumberSetting
  ] = useState({
    settingId: null,
    dealerCode: dealer.dealerCode,
    settingValue: "true",
    quoteDealerSettingId: null
  });

  const [
    dealerQuoteDisplayLaborAndPartsDetailsSetting,
    setDealerQuoteDisplayLaborAndPartsDetailsSetting
  ] = useState({
    settingId: null,
    dealerCode: dealer.dealerCode,
    settingValue: "true",
    quoteDealerSettingId: null
  });

  // Quote assistance settings
  const [quoteAssistanceSetting, setQuoteAssistance] = useState({
    settingId: null,
    dealerCode: dealer.dealerCode,
    settingValue: "true",
    quoteDealerSettingId: null
  });

  const [serviceEmailSetting, setServiceEmail] = useState({
    settingId: null,
    dealerCode: dealer.dealerCode,
    settingValue: "",
    quoteDealerSettingId: null
  });

  const [partsEmailSetting, setPartsEmail] = useState({
    settingId: null,
    dealerCode: dealer.dealerCode,
    settingValue: "",
    quoteDealerSettingId: null
  });

  const [record, setRecord] = useState({
    quoteExpirationDays: "",
    displayLaborPartsDetails: true,
    displayLaborHours: true,
    displayPartNumber: true,
    disclaimer: "",
    quoteAssistance: true,
    serviceEmail: "",
    partsEmail: ""
  });
  useEffect(() => {
    setRecord(tempRecord);
  }, [tempRecord]);

  // Get information from service-quoting-assistance-component
  const getServiceQuoteAssistanceStatus = status => {
    const { name, value } = status;

    const prevRecord = record;
    setTempRecord({
      ...prevRecord,
      [name]: value === "checked"
    });
    setDirtyField({
      ...dirtyField,
      [name]: true
    });
  };

  const getAssistanceEmails = (assistanceEmails, isValid) => {
    const { name, value } = assistanceEmails;

    setError({
      ...error,
      [name]: !isValid
    });
    const prevRecord = record;
    setTempRecord({
      ...prevRecord,
      [name]: value
    });

    setDirtyField({
      ...dirtyField,
      [name]: true
    });
  };

  // remove or change this function later, just dummy
  const onChangeField = event => {
    const { name, value } = event.target;
    let updatedValue = value;
    if (
      name === "displayLaborHours" ||
      name === "displayPartNumber" ||
      name === "displayLaborPartsDetails"
    ) {
      updatedValue = value === "checked" ? true : false;
    } else if (name === "quoteExpirationDays") {
      setError({
        ...error,
        [name]: updatedValue > 0 && updatedValue < 91 ? false : true
      });
    }
    const prevRecord = record;
    setTempRecord({
      ...prevRecord,
      [name]: updatedValue
    });

    setDirtyField({
      ...dirtyField,
      [name]: true
    });
  };

  // remove or change this function later, just dummy
  const onDisclaimerChange = event => {
    const { name, value } = event.target;

    setError({
      ...error,
      [name]: !isEmpty(value) ? false : true
    });
    const prevRecord = record;
    setTempRecord({
      ...prevRecord,
      [name]: value
    });

    setDirtyField({
      ...dirtyField,
      [name]: true
    });
  };

  const onBlurField = () => {
    if (isEmpty(tempRecord.quoteExpirationDays)) {
      setTempRecord({
        ...record,
        ["quoteExpirationDays"]: "30"
      });
    }
  };

  useComponentDidMount(() => {
    readAllSettingsEnum();
  });

  const readAllSettingsEnum = async () => {
    const response = await appServices.loadSettingEnumForQuoting();
    if (response) {
      setSettings(response || []);
      readQuoteDealerSettings(response);
    }
  };

  const readQuoteDealerSettings = async () => {
    const response = await appServices.loadQuoteDealerSettings(
      dealer.dealerCode
    );
    if (response) {
      setQuoteDealerSettings(response || []);
    }
  };

  useEffect(() => {
    if (!isEmpty(quoteDealerSettings)) {
      const quoteExpirationSetting = settings.find(element => {
        return (
          element.settingName === DealerSettingsConstant.QUOTE_EXPIRATION_DAYS
        );
      });
      const disclaimerSetting = settings.find(element => {
        return element.settingName === DealerSettingsConstant.DISCLAIMER;
      });

      const displayLaborSetting = settings.find(element => {
        return (
          element.settingName === DealerSettingsConstant.DISPLAY_LABOR_HOURS
        );
      });

      const displayPartNumberSetting = settings.find(element => {
        return (
          element.settingName === DealerSettingsConstant.DISPLAY_PART_NUMBERS
        );
      });

      const displayLaborAndPartsSetting = settings.find(element => {
        return (
          element.settingName ===
          DealerSettingsConstant.DISPLAY_LABOR_PARTS_DETAILS
        );
      });

      const enableQuoteAssistanceSetting = settings.find(element => {
        return element.settingName === DealerSettingsConstant.QUOTE_ASSISTANCE;
      });

      const serviceEmailSetting = settings.find(element => {
        return element.settingName === DealerSettingsConstant.SERVICE_EMAIL;
      });

      const partsEmailSetting = settings.find(element => {
        return element.settingName === DealerSettingsConstant.PARTS_EMAIL;
      });

      const localDealerQuoteExpirationSetting = quoteDealerSettings.find(
        element => {
          return element.settingId === quoteExpirationSetting.settingId;
        }
      );

      const localDealerQuoteDisclaimerSetting = quoteDealerSettings.find(
        element => {
          return element.settingId === disclaimerSetting.settingId;
        }
      );

      const localDealerQuoteLaborSetting = quoteDealerSettings.find(element => {
        return element.settingId === displayLaborSetting.settingId;
      });

      const localDealerQuoteDisplayPartSetting = quoteDealerSettings.find(
        element => {
          return element.settingId === displayPartNumberSetting.settingId;
        }
      );

      const localDealerQuoteDisplayLaborPartsDetailsSetting =
        quoteDealerSettings.find(element => {
          return element.settingId === displayLaborAndPartsSetting.settingId;
        });

      const localEnableServiceQuotingAssistanceSetting =
        quoteDealerSettings.find(element => {
          return element.settingId === enableQuoteAssistanceSetting.settingId;
        });

      const localServiceEmailSetting = quoteDealerSettings.find(element => {
        return element.settingId === serviceEmailSetting.settingId;
      });

      const localPartsEmailSetting = quoteDealerSettings.find(element => {
        return element.settingId === partsEmailSetting.settingId;
      });

      let quoteExpirationDays = "30";
      let disclaimer = DealerSettingsConstant.DEFAULT_DISCLAIMER_MSG;
      let displayLaborHours = true;
      let displayPartNumber = true;
      let displayLaborPartsDetails = true;
      let quoteAssistance = false;
      let serviceEmail = "";
      let partsEmail = "";

      if (!isEmpty(localDealerQuoteExpirationSetting)) {
        setDealerQuoteExiprationSetting(localDealerQuoteExpirationSetting);
        quoteExpirationDays = localDealerQuoteExpirationSetting.settingValue;
      }
      if (!isEmpty(localDealerQuoteDisclaimerSetting)) {
        setDealerQuoteDisclaimerSetting(localDealerQuoteDisclaimerSetting);
        disclaimer = localDealerQuoteDisclaimerSetting.settingValue;
      }
      if (!isEmpty(localDealerQuoteLaborSetting)) {
        setDealerQuoteDisplayLaborSetting(localDealerQuoteLaborSetting);
        displayLaborHours =
          localDealerQuoteLaborSetting.settingValue === "true";
      }
      if (!isEmpty(localDealerQuoteDisplayPartSetting)) {
        setDealerQuoteDisplayPartNumberSetting(
          localDealerQuoteDisplayPartSetting
        );
        displayPartNumber =
          localDealerQuoteDisplayPartSetting.settingValue === "true";
      }
      if (!isEmpty(localDealerQuoteDisplayLaborPartsDetailsSetting)) {
        setDealerQuoteDisplayLaborAndPartsDetailsSetting(
          localDealerQuoteDisplayLaborPartsDetailsSetting
        );
        displayLaborPartsDetails =
          localDealerQuoteDisplayLaborPartsDetailsSetting.settingValue ===
          "true";
      }
      if (!isEmpty(localEnableServiceQuotingAssistanceSetting)) {
        setQuoteAssistance(localEnableServiceQuotingAssistanceSetting);
        quoteAssistance =
          localEnableServiceQuotingAssistanceSetting.settingValue === "true";
      }

      if (!isEmpty(localServiceEmailSetting)) {
        setServiceEmail(localServiceEmailSetting);
        serviceEmail = localServiceEmailSetting.settingValue;
      }

      if (!isEmpty(localPartsEmailSetting)) {
        setPartsEmail(localPartsEmailSetting);
        partsEmail = localPartsEmailSetting.settingValue;
      }

      setTempRecord({
        ...tempRecord,
        quoteExpirationDays,
        disclaimer,
        displayLaborHours,
        displayPartNumber,
        displayLaborPartsDetails,
        quoteAssistance,
        serviceEmail,
        partsEmail
      });
      console.log("printing temRecord", tempRecord);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quoteDealerSettings]);

  // save handler
  const saveHandler = async () => {
    const payload = [];

    console.log("TESTING DEALER SETTINGS");
    getDealerQuoteExpirationDaysSetting(payload);
    getDealerQuoteDisplayLaborAndPartsDetailsSetting(payload);
    getDealerQuoteDisplayLaborSetting(payload);
    getDealerQuoteDisplayPartNumberSetting(payload);
    getDealerQuoteDisclaimerSetting(payload);
    getServiceQuotingAssistanceEmailSetting(payload);
    getServiceEmailSetting(payload);
    getPartsEmailSetting(payload);

    console.log("payload", payload);
    const response = await saveQuoteDealerSettingSvc(
      dealer.dealerCode,
      payload
    );
    if (response) {
      setMsg("Saved");
      setTimeout(() => setMsg(""), 3000);
      setDirtyField({
        quoteExpirationDays: false,
        displayLaborHours: false,
        displayPartNumber: false,
        disclaimer: false,
        quoteAssistance: false,
        serviceEmail: false,
        partsEmail: false
      });
      // FIX - pass t_setting table data stored as "quoteSetting" in app context
      getQuoteDealerSettings(quoteSetting);
    }
  };

  const getDealerQuoteExpirationDaysSetting = payload => {
    if (dirtyField["quoteExpirationDays"]) {
      const localQuoteExpirationSetting = settings.find(element => {
        return (
          element.settingName === DealerSettingsConstant.QUOTE_EXPIRATION_DAYS
        );
      });
      dealerQuoteExpirationSetting.settingId =
        localQuoteExpirationSetting.settingId;
      dealerQuoteExpirationSetting.settingValue =
        tempRecord.quoteExpirationDays;
      dealerQuoteExpirationSetting.lastModUserId = user.quoteUserId;
      payload.push(dealerQuoteExpirationSetting);
    }
  };

  const getDealerQuoteDisplayLaborAndPartsDetailsSetting = payload => {
    if (dirtyField["displayLaborPartsDetails"]) {
      const localDisplayLaborPartsSetting = settings.find(element => {
        return (
          element.settingName ===
          DealerSettingsConstant.DISPLAY_LABOR_PARTS_DETAILS
        );
      });

      dealerQuoteDisplayLaborAndPartsDetailsSetting.settingId =
        localDisplayLaborPartsSetting.settingId;
      dealerQuoteDisplayLaborAndPartsDetailsSetting.settingValue =
        tempRecord.displayLaborPartsDetails ? "true" : "false";
      dealerQuoteDisplayLaborAndPartsDetailsSetting.lastModUserId =
        user.quoteUserId;
      payload.push(dealerQuoteDisplayLaborAndPartsDetailsSetting);
    }
  };

  const getDealerQuoteDisplayLaborSetting = payload => {
    if (dirtyField["displayLaborHours"]) {
      const localDisplayLaborSetting = settings.find(element => {
        return (
          element.settingName === DealerSettingsConstant.DISPLAY_LABOR_HOURS
        );
      });

      dealerQuoteDisplayLaborSetting.settingId =
        localDisplayLaborSetting.settingId;
      dealerQuoteDisplayLaborSetting.settingValue = tempRecord.displayLaborHours
        ? "true"
        : "false";
      dealerQuoteDisplayLaborSetting.lastModUserId = user.quoteUserId;
      payload.push(dealerQuoteDisplayLaborSetting);
    }
  };

  const getDealerQuoteDisplayPartNumberSetting = payload => {
    if (dirtyField["displayPartNumber"]) {
      const localDisplayPartNumberSetting = settings.find(element => {
        return (
          element.settingName === DealerSettingsConstant.DISPLAY_PART_NUMBERS
        );
      });

      dealerQuoteDisplayPartNumberSetting.settingId =
        localDisplayPartNumberSetting.settingId;
      dealerQuoteDisplayPartNumberSetting.settingValue =
        tempRecord.displayPartNumber ? "true" : "false";
      dealerQuoteDisplayPartNumberSetting.lastModUserId = user.quoteUserId;
      payload.push(dealerQuoteDisplayPartNumberSetting);
    }
  };

  const getDealerQuoteDisclaimerSetting = payload => {
    if (dirtyField["disclaimer"]) {
      const localDisclaimerSetting = settings.find(element => {
        return element.settingName === DealerSettingsConstant.DISCLAIMER;
      });
      dealerQuoteDisclaimerSetting.settingId = localDisclaimerSetting.settingId;
      dealerQuoteDisclaimerSetting.settingValue = tempRecord.disclaimer;
      dealerQuoteDisclaimerSetting.lastModUserId = user.quoteUserId;
      payload.push(dealerQuoteDisclaimerSetting);
    }
  };

  const getServiceQuotingAssistanceEmailSetting = payload => {
    if (dirtyField["quoteAssistance"]) {
      const localEnableServiceQuotingAssistanceSetting = settings.find(
        element => {
          return (
            element.settingName === DealerSettingsConstant.QUOTE_ASSISTANCE
          );
        }
      );
      quoteAssistanceSetting.settingId =
        localEnableServiceQuotingAssistanceSetting.settingId;
      quoteAssistanceSetting.settingValue = tempRecord.quoteAssistance;
      quoteAssistanceSetting.lastModUserId = user.quoteUserId;
      payload.push(quoteAssistanceSetting);
    }
  };

  const getServiceEmailSetting = payload => {
    if (dirtyField["serviceEmail"]) {
      const localServiceEmailSetting = settings.find(element => {
        return element.settingName === DealerSettingsConstant.SERVICE_EMAIL;
      });
      serviceEmailSetting.settingId = localServiceEmailSetting.settingId;
      serviceEmailSetting.settingValue = tempRecord.serviceEmail;
      serviceEmailSetting.lastModUserId = user.quoteUserId;
      payload.push(serviceEmailSetting);
    }
  };

  const getPartsEmailSetting = payload => {
    if (dirtyField["partsEmail"]) {
      const localPartsEmailSetting = settings.find(element => {
        return element.settingName === DealerSettingsConstant.PARTS_EMAIL;
      });
      partsEmailSetting.settingId = localPartsEmailSetting.settingId;
      partsEmailSetting.settingValue = tempRecord.partsEmail;
      partsEmailSetting.lastModUserId = user.quoteUserId;
      payload.push(partsEmailSetting);
    }
  };

  const statusHtml = msg ? (
    <StatusBox
      htmlId="statusBox"
      type="success"
      autoClose={true}
      linkHtml={null}
      message={msg}
      errorInTooltip={false}
    />
  ) : (
    ""
  );

  const getDealerSettingsContent = () => {
    const content = (
      <div>
        <div className="pdf-quote-main">
          <h4>{localeStrings["sq.common.dealer_settings_pdf_quote_title"]}</h4>
          <div className="dealer-settings-pdf-quote-container">
            <div className="containers labels-class expiration-label">
              <div>
                {
                  localeStrings[
                    "sq.common.dealer_settings_quotes_expires_after"
                  ]
                }{" "}
                <span className="required-red-label"> * </span>
              </div>
            </div>
            <div className="containers labels-class labor-parts-details-label">
              <div>
                {
                  localeStrings[
                    "sq.common.dealer_settings_display_labor_parts_details"
                  ]
                }
              </div>
            </div>
            <div
              className="containers labels-class hours-label"
              hidden={
                tempRecord.displayLaborPartsDetails === false ? true : false
              }
            >
              <div>
                {
                  localeStrings[
                    "sq.common.dealer_settings_display_labor_hours_to_customers"
                  ]
                }
              </div>
            </div>
            <div
              className="containers labels-class parts-label"
              hidden={
                tempRecord.displayLaborPartsDetails === false ? true : false
              }
            >
              <div>
                {
                  localeStrings[
                    "sq.common.dealer_settings_display_part_numbers_to_customers"
                  ]
                }
              </div>
            </div>
            <div className="containers labels-class disclaimer-label">
              <div>
                {localeStrings["sq.common.dealer_settings_disclaimer"]}{" "}
                <span className="required-red-label"> * </span>
              </div>
            </div>
            <div className="containers inputs-class expiration-input">
              <NumericInput
                displayLabel={false}
                htmlId="QuoteExpirationSelect"
                label="Label"
                inputSuffix={localeStrings["sq.common.dealer_settings_days"]}
                name="quoteExpirationDays"
                autoComplete="off"
                onChange={onChangeField}
                onBlur={onBlurField}
                value={tempRecord.quoteExpirationDays}
                maxValue={90}
                minValue={1}
              />
            </div>

            <div className="containers inputs-class labor-parts-input">
              <ToggleSwitch
                htmlId="ToggleSwitchWithLabel3"
                name="displayLaborPartsDetails"
                checked={tempRecord.displayLaborPartsDetails}
                onClick={onChangeField}
              />
            </div>

            <div
              className="containers inputs-class hours-input"
              hidden={
                tempRecord.displayLaborPartsDetails === false ? true : false
              }
            >
              <ToggleSwitch
                htmlId="ToggleSwitchWithLabel1"
                name="displayLaborHours"
                checked={tempRecord.displayLaborHours}
                onClick={onChangeField}
              />
            </div>
            <div
              className="containers inputs-class parts-input"
              hidden={
                tempRecord.displayLaborPartsDetails === false ? true : false
              }
            >
              <ToggleSwitch
                htmlId="ToggleSwitchWithLabel2"
                name="displayPartNumber"
                checked={tempRecord.displayPartNumber}
                onClick={onChangeField}
              />
            </div>
            <div className="containers inputs-class disclaimer-input">
              <TextArea
                htmlId="textAreaDefault"
                name="disclaimer"
                onChange={onDisclaimerChange}
                value={tempRecord.disclaimer}
                label="Disclaimer"
                displayLabel={false}
                required={true}
                autoComplete="none"
                maxLength={1000}
                rows={8}
              />
            </div>
          </div>
        </div>
        {appType === "SQ" ? (
          <ServiceQuotingAssistance
            localeStrings={localeStrings}
            serviceQuotingAssistanceStatus={getServiceQuoteAssistanceStatus}
            assistanceEmails={getAssistanceEmails}
            enableQuoteAssistanceValue={tempRecord.quoteAssistance}
            serviceEmailValue={tempRecord.serviceEmail}
            partsEmailValue={tempRecord.partsEmail}
          />
        ) : (
          ""
        )}
        <div className="save-button-container">
          {statusHtml}
          <Button
            htmlId="btnPrimary"
            disabled={
              error["disclaimer"] ||
              error["quoteExpirationDays"] ||
              error["partsEmail"] ||
              error["serviceEmail"]
            }
            onClick={saveHandler}
          >
            {localeStrings["sq.common.save_button"]}
          </Button>

          <Button
            buttonStyle="secondary"
            disabled={false}
            htmlId="btnDanger"
            onClick={props.closeDialog}
          >
            {localeStrings["sq.search.common.cancel_button"]}
          </Button>
        </div>
      </div>
    );

    return content;
  };

  return (
    <ModalDialog
      htmlId="DealerSettingsModal"
      className="sample-modal-dialog"
      show={props.show}
      header={
        <ModalDialog.Title>
          {localeStrings["sq.common.dealer_settings_title"]}
        </ModalDialog.Title>
      }
      onHide={props.closeDialog}
    >
      <div>{getDealerSettingsContent()}</div>
    </ModalDialog>
  );
};

export default DealerSettingsModal;
DealerSettingsModal.propTypes = {
  closeDialog: PropTypes.func,
  show: PropTypes.bool
};
