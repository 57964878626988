import { makeSecureRestApi } from "../../../api/xmmAxios";

const getActivityLog = (dealer, quoteId) => {
  const restUrl = `csr/${dealer.dealerCode}/${quoteId}/servicelog?page=0&size=1000`;
  return new Promise((resolve, reject) => {
    makeSecureRestApi(
      {
        url: restUrl,
        method: "get"
      },
      response => {
        resolve(response);
      },
      error => {
        reject(error);
      },
      "Unable to retrieve activity log."
    );
  });
};

const getServiceHistory = (dealer, vin) => {
  // https://csrapi.dev6.xtimeappsnp.xtime.com/rest/swagger-ui/?urls.primaryName=Quoting%20Api#/vehicle-resource/getVehicleHistoryUsingGET
  const restUrl = `vehicle/vin/${vin}/vehicleServiceHistory/${dealer.dealerCode}?schema=${dealer.schemaName}`;
  return new Promise((resolve, reject) => {
    makeSecureRestApi(
      {
        url: restUrl,
        method: "get"
      },
      response => {
        resolve(response);
      },
      error => {
        reject(error);
      },
      "Unable to retrieve service history."
    );
  });
};

export default {
  getActivityLog,
  getServiceHistory
};
