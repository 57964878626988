// EXTERNAL LIBRARIES
import React, { useImperativeHandle, useState } from "react";
import Button from "@cx/ui/Button";
import ModalDialog from "@cx/ui/ModalDialog";
// CUSTOM COMPONENTS
import ServiceHistory from "../../repair-order/components/service-history/service-history.component";
// STYLES
import "./comeback-modal.scss";
import PropTypes from "prop-types";

// eslint-disable-next-line react/display-name
const ComebackModalDialog = React.forwardRef(({ vin, onSave }, ref) => {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showServiceHistory, setShowServiceHistory] = useState(false);
  const [serviceId, serServiceId] = useState("");
  const [overridePrevRONumber, setOverridePrevRONumber] = useState(null);
  const [prevRONumber, setPrevRONumber] = useState(null);
  // Expose "openConfirmationModal" function to parent components
  useImperativeHandle(ref, () => ({ openConfirmationModal }));
  const isComebackService = !!prevRONumber && !overridePrevRONumber;

  const openConfirmationModal = ({
    serviceId,
    overridePrevRONumber,
    prevRONumber
  }) => {
    setShowConfirmationModal(true);
    serServiceId(serviceId);
    setPrevRONumber(prevRONumber);
    setOverridePrevRONumber(overridePrevRONumber);
  };
  const closeConfirmationModal = () => {
    setShowConfirmationModal(false);
  };
  const openServiceHistoryModal = () => {
    closeConfirmationModal();
    setShowServiceHistory(true);
  };
  const closeServiceHistoryModal = () => {
    setShowServiceHistory(false);
  };
  return (
    <>
      <ModalDialog
        htmlId="ComeBackConfirmationModal"
        show={showConfirmationModal}
        onHide={closeConfirmationModal}
        className="comeback-confirmation-modal"
        header={
          <ModalDialog.Title>
            {isComebackService ? "Modify Service" : "Modify comeback Service"}
          </ModalDialog.Title>
        }
        footer={
          <div className="comeback-button-container">
            {!isComebackService ? (
              <Button
                htmlId="comeback-modal-danger-btn"
                buttonStyle="danger"
                onClick={() => {
                  closeConfirmationModal();
                  onSave(null, serviceId);
                }}
              >
                Decline
              </Button>
            ) : (
              <div />
            )}
            <div>
              <Button
                htmlId="comeback-modal-close-btn"
                buttonStyle="secondary"
                onClick={closeConfirmationModal}
              >
                Cancel
              </Button>
              <Button
                htmlId="comeback-modal-modify-btn"
                onClick={openServiceHistoryModal}
              >
                Modify
              </Button>
            </div>
          </div>
        }
      >
        <p>
          {isComebackService
            ? "This service was marked as not a comeback. If this is a comeback, click Modify to associate this service with a previous RO."
            : `This service appears to be a comeback for RO #${
                overridePrevRONumber || prevRONumber
              }. Click modify to change RO or Decline if this isn't comeback service`}
        </p>
      </ModalDialog>
      <ModalDialog
        htmlId="ServiceHistoryModalDialog"
        show={showServiceHistory}
        onHide={closeServiceHistoryModal}
        displayCloseButton={false}
        className="comeback-modal-container"
      >
        <ServiceHistory
          roNumber={overridePrevRONumber || prevRONumber}
          isRoSelectable={true}
          vin={vin}
          onHideComeBack={closeServiceHistoryModal}
          onSaveRoSelected={roIdSelected => {
            closeServiceHistoryModal();
            onSave(roIdSelected, serviceId);
          }}
        />
      </ModalDialog>
    </>
  );
});

export default ComebackModalDialog;

ComebackModalDialog.prototype = {
  // handlers
  onSave: PropTypes.func,
  // data
  vin: PropTypes.string
};
