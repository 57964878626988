// @ts-check
import React from "react";
// eslint-disable-next-line unused-imports/no-unused-imports
import * as ModelsTypes from "../../../../../../@types/models.types";
// eslint-disable-next-line unused-imports/no-unused-imports
import * as CommonTypes from "../../../../../../@types/common.types";

/**
 * @description Custom tooltip component for the quotes timeline area chart.
 * @param {{active: boolean, payload: ModelsTypes.TooltipPayload, label: string}} props
 * @returns {CommonTypes.Component|null}
 */
const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <div className="area-chart__tooltip">
        <p className="area-chart__tooltip-label">{payload[0].payload.name}</p>
        <div className="area-chart__tooltip-block">
          <span className="area-chart__tooltip-block--number">
            {payload[0].payload.inProgress}
          </span>
          <span className="area-chart__tooltip-block--label">In progress</span>
        </div>
        <div className="area-chart__tooltip-block">
          <span className="area-chart__tooltip-block--number">
            {payload[0].payload.sent}
          </span>
          <span className="area-chart__tooltip-block--label">Sent</span>
        </div>
        <div className="area-chart__tooltip-block">
          <span className="area-chart__tooltip-block--number">
            {payload[0].payload.converted}
          </span>
          <span className="area-chart__tooltip-block--label">Scheduled</span>
        </div>
        <div className="area-chart__tooltip-block">
          <span className="area-chart__tooltip-block--number">
            {payload[0].payload.expired}
          </span>
          <span className="area-chart__tooltip-block--label">Expired</span>
        </div>
        <div className="area-chart__tooltip-block">
          <span className="area-chart__tooltip-block--number">
            {payload[0].payload.inProgress +
              payload[0].payload.sent +
              payload[0].payload.converted +
              payload[0].payload.expired}
          </span>
          <span className="area-chart__tooltip-block--label">Total</span>
        </div>
      </div>
    );
  }
  return null;
};

export default CustomTooltip;
