import React from "react";
import "./AsrNotes.scss";
import { useEditServiceContext } from "../state/edit-service.context";
const AsrNotes = () => {
  const { state } = useEditServiceContext();
  const { asrNotes } = state.service;
  return (
    <div id="asrNotes" className="search-flex-grid-container">
      <span className="edit-global-repair-header">ASR Notes</span>
      <span className="asrNotes-text">{asrNotes}</span>
    </div>
  );
};

export default AsrNotes;
