import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import ModalDialog from "@cx/ui/ModalDialog";
import Button from "@cx/ui/Button";
import TextArea from "@cx/ui/TextArea";
import QuoteStatusConstant from "../../../constants/quote-status.constants";
import "./notes-text-modal.scss";
/**
 * * Reusable modal window, pass props {showModal, title, okAction, cancelAction} from parent
 * @component NotesTextAreaModal
 */
const NotesTextAreaModal = props => {
  const {
    okAction,
    cancelAction,
    showModal,
    quoteId,
    notes,
    cancelBtnStyle,
    okBtnStyle,
    okText,
    cancelText,
    notesTitle,
    legend,
    quoteStatus
  } = props;
  const [quoteNotes, setQuoteNotes] = useState(!notes ? "" : notes);

  useEffect(() => {
    setQuoteNotes(!notes ? "" : notes);
  }, [notes]);

  const handleUpdateClick = () => {
    const notes = String(quoteNotes).trim() || "";
    okAction(notes);
    setQuoteNotes("");
  };

  const handleCancelClick = () => {
    setQuoteNotes("");
    cancelAction();
  };

  const hide = () => {
    setQuoteNotes("");
    cancelAction();
  };

  const onChangeInput = event => {
    const { value } = event.target;
    setQuoteNotes(!value ? "" : value.trim());
  };

  const readonlyQuoteStatuses = [
    QuoteStatusConstant.CONVERTED_TO_APPOINTMENT,
    QuoteStatusConstant.EXPIRED
  ];

  return (
    <ModalDialog
      htmlId="NotesModalDialog"
      className="sample-modal-dialog half-width"
      show={showModal}
      header={<ModalDialog.Title>{`QuoteID: ${quoteId}`}</ModalDialog.Title>}
      footer={
        <div>
          {!readonlyQuoteStatuses.includes(quoteStatus) ? (
            <>
              <Button
                htmlId="modalCancelbtn"
                onClick={handleCancelClick}
                buttonStyle={cancelBtnStyle}
              >
                {cancelText}
              </Button>
              <Button
                htmlId="modalOkbtn"
                onClick={handleUpdateClick}
                buttonStyle={okBtnStyle}
              >
                {okText}
              </Button>
            </>
          ) : null}
        </div>
      }
      onHide={hide}
    >
      <div>
        <p>{notesTitle}</p>
        {!readonlyQuoteStatuses.includes(quoteStatus) ? (
          <TextArea
            htmlId="NotesTextArea"
            className="notes-input"
            label="Notes"
            name="quoteNotes"
            value={quoteNotes}
            autoComplete="none"
            displayLabel={false}
            onChange={onChangeInput}
            maxLength={1000}
            rows={8}
          />
        ) : (
          <p className="notes-text">{quoteNotes}</p>
        )}
        <span className="sq-msg">{legend}</span>
      </div>
    </ModalDialog>
  );
};

export default NotesTextAreaModal;

NotesTextAreaModal.propTypes = {
  // events
  cancelAction: PropTypes.func,
  okAction: PropTypes.func.isRequired,
  // data
  quoteId: PropTypes.string,
  notes: PropTypes.string,
  showModal: PropTypes.bool,
  okBtnStyle: PropTypes.string,
  cancelBtnStyle: PropTypes.string,
  okText: PropTypes.string,
  cancelText: PropTypes.string,
  legend: PropTypes.string,
  notesTitle: PropTypes.string,
  quoteStatus: PropTypes.string
};
NotesTextAreaModal.defaultProps = {
  // events
  cancelAction: () => {},
  okAction: () => {},
  // data
  quoteId: null,
  showModal: false,
  okText: "Update",
  cancelText: "Cancel",
  okBtnStyle: "primary",
  cancelBtnStyle: "secondary",
  legend: "",
  notesTitle: "Notes",
  quoteStatus: QuoteStatusConstant.PROGRESS
};
