import React from "react";
import Badge from "@cx/ui/Badge";
import isEmpty from "lodash/isEmpty";

const ServiceTypesBoard = props => {
  const { labels, servicesTotals } = props;
  return (
    <div className="service-types">
      <div className="service-types__column">
        <div className="service-types__row--service">
          {/* Packages */}
          <Badge htmlId="packagesBadge" className="service-types__badge">
            {!isEmpty(servicesTotals) ? servicesTotals.packages : null}
          </Badge>
          <span>{labels[4]}</span>
        </div>

        {/* Diagnosis */}
        <div className="service-types__row--service">
          <Badge htmlId="diagnosisBadge" className="service-types__badge">
            {!isEmpty(servicesTotals) ? servicesTotals.diagnosis : null}
          </Badge>
          <span>{labels[1]}</span>
        </div>

        {/* Declined */}
        <div className="service-types__row--service">
          <Badge htmlId="declinedBadge" className="service-types__badge">
            {!isEmpty(servicesTotals) ? servicesTotals.declined : null}
          </Badge>
          <span>{labels[2]}</span>
        </div>
      </div>

      <div className="service-types__column">
        {/* Maintenance */}
        <div className="service-types__row--service">
          <Badge htmlId="maintenanceBadge" className="service-types__badge">
            {!isEmpty(servicesTotals) ? servicesTotals.maintenance : null}
          </Badge>
          <span>{labels[0]}</span>
        </div>

        {/* Repairs */}
        <div className="service-types__row--service">
          <Badge htmlId="repairsBadge" className="service-types__badge">
            {!isEmpty(servicesTotals) ? servicesTotals.repairs : null}
          </Badge>
          <span>{labels[3]}</span>
        </div>

        {/* Recalls */}
        <div className="service-types__row--service">
          <Badge htmlId="recallsBadge" className="service-types__badge">
            {!isEmpty(servicesTotals) ? servicesTotals.recalls : null}
          </Badge>
          <span>{labels[5]}</span>
        </div>
      </div>
    </div>
  );
};

export default ServiceTypesBoard;
