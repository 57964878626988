import isEmpty from "lodash/isEmpty";
// eslint-disable-next-line unused-imports/no-unused-imports
import * as ModelsTypes from "../../../../@types/models.types";
import { services } from "../constants/dashboard.constants";

/**
 * @description Obtain all services count object
 * @param {Array<ModelsTypes.ServiceTypes>} services
 * @returns {Object<ModelsTypes.AllServiceTypes>}
 */

const servicesCount = serviceTypesCount => {
  const maintenance = serviceTypesCount.find(serviceType => {
    return serviceType.serviceTypeName === services.DEALER_PUB_MAINT_OPS;
  });
  const diagnosis = serviceTypesCount.find(serviceType => {
    return serviceType.serviceTypeName === services.DEALER_PUB_REPAIR_OPS;
  });
  const declined = serviceTypesCount.find(serviceType => {
    return serviceType.serviceTypeName === services.DECLINED;
  });
  const repairs = serviceTypesCount.find(serviceType => {
    return serviceType.serviceTypeName === services.GLOBAL_REPAIR_OPS;
  });
  const packages = serviceTypesCount.find(serviceType => {
    return serviceType.serviceTypeName === services.MENU;
  });
  const recalls = serviceTypesCount.find(serviceType => {
    return serviceType.serviceTypeName === services.RECALL;
  });

  return {
    maintenance: !isEmpty(maintenance) ? maintenance.serviceCount : 0,
    diagnosis: !isEmpty(diagnosis) ? diagnosis.serviceCount : 0,
    declined: !isEmpty(declined) ? declined.serviceCount : 0,
    repairs: !isEmpty(repairs) ? repairs.serviceCount : 0,
    packages: !isEmpty(packages) ? packages.serviceCount : 0,
    recalls: !isEmpty(recalls) ? recalls.serviceCount : 0
  };
};

export default servicesCount;
