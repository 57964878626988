import React from "react";
import Button from "@cx/ui/Button";
import csrService from "../services/csr.service";
import { useNewQuoteContext } from "../../../state/NewQuoteContext";

const PrintPickListComponent = () => {
  const { blockUntilCompleted, state } = useNewQuoteContext();

  // * NOTE : removing this check for bournes, but later can be used
  // eslint-disable-next-line unused-imports/no-unused-vars
  const checkROHasAnyApprovedPart = quoteSummary => {
    let hasAnyApprovedPart = false;
    quoteSummary?.quoteServices?.map(service => {
      if (service?.quoteServiceType === "MENU") {
        service?.menuServices?.map(serv => {
          serv?.parts?.map(part => {
            if (part.approver !== null) {
              hasAnyApprovedPart = true;
            }
          });
        });
      } else
        service?.parts?.map(part => {
          if (part.approver !== null) {
            hasAnyApprovedPart = true;
          }
        });
    });
    return hasAnyApprovedPart;
  };

  const checkROHasAnyPart = quoteSummary => {
    let hasAnyPart = false;
    quoteSummary?.quoteServices?.map(service => {
      if (service?.quoteServiceType === "MENU") {
        service?.menuServices?.map(serv => {
          if (serv.parts !== null && serv.parts?.length !== 0) {
            hasAnyPart = true;
          }
        });
      } else if (service.parts !== null && service.parts?.length !== 0) {
        hasAnyPart = true;
      }
    });
    return hasAnyPart;
  };

  const printPickList = async () => {
    await blockUntilCompleted(async () => {
      await csrService.printSelectedDocuments(
        state.quoteSummary.dealerCode,
        state.quoteSummary.quoteId,
        ["partsPickList"],
        state.quoteSummary
      );
    });
  };

  return (
    <Button
      className="quote-summary-action"
      size="sm"
      buttonStyle="secondary"
      htmlId="printPickListButton"
      onClick={() => printPickList()}
      disabled={!checkROHasAnyPart(state.quoteSummary)}
      title={
        !checkROHasAnyPart(state.quoteSummary)
          ? "This RO does not have any part."
          : ""
      }
    >
      Print pick list
    </Button>
  );
};

export default PrintPickListComponent;
