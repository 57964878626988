/* eslint-disable no-async-promise-executor */
import axiosService, { showApiFailureMessage } from "../../../api/xmmAxios";
import serviceCache from "../utils/service-cache";

const getDeclinedAndRecallServices = async ({
  vehicle,
  config,
  quoteConfirmationId,
  personId
}) => {
  return new Promise(async (resolve, reject) => {
    const { make, vin, vehicleId, variant } = vehicle;
    const axios = axiosService.getInstance();
    const newVin = !vin ? "novin" : vin;
    let declinedServices = [];
    let recallServices = [];

    try {
      const response = await axios.get(
        `quote/services/schema/${config.schemaName}/recallDeclined/{dealerCode}/vin/${newVin}`,
        {
          params: {
            personId,
            vehicleId,
            variant,
            make,
            isDP7Request: true,
            recallServices: true
          }
        }
      );
      // IMPORTANT: copy opCodes,declinedServicesEnabled,declinedServicesPlaceHolderId from root API response to each declined service {} object
      declinedServices = response.data.declinedServices
        ? response.data.declinedServices.map(declinedService => {
            return {
              ...declinedService,
              opCode: response.data.opCodes || "",
              declinedServicesEnabled: response.data.declinedServicesEnabled,
              declinedServicesPlaceHolderId:
                response.data.declinedServicesPlaceHolderId
            };
          })
        : [];
      // IMPORTANT: copy recallServiceParentOPCode,recallControl,recallServiceParentDuration,recallServiceParentId from root API response to each recall service {} object
      recallServices = response.data.recallInfo.autoRecalls
        ? response.data.recallInfo.autoRecalls.map(recallService => {
            return {
              ...recallService,
              opCode: response.data.recallInfo.recallServiceParentOPCode || "",
              recallControl: response.data.recallInfo.recallControl,
              recallServiceParentDuration:
                response.data.recallInfo.recallServiceParentDuration,
              recallServiceParentId:
                response.data.recallInfo.recallServiceParentId
            };
          })
        : [];
      const cacheRecallServices =
        serviceCache.getAddedRecallServices(quoteConfirmationId);
      recallServices = recallServices.filter(
        recallService =>
          !cacheRecallServices.find(
            cacheService => cacheService.id === recallService.id
          )
      );

      resolve({ declinedServices, recallServices });
    } catch (error) {
      showApiFailureMessage(
        "Unable to retrieve declined and recall services.",
        error
      );
      reject(error);
    }
  });
};

export default {
  getDeclinedAndRecallServices
};
