/*
CryptoJS v3.1.2
*/   
import CryptoJS from "crypto-js";

(function(){
    if (typeof window === "undefined") {
        return;
    }
    window.CryptoJS = CryptoJS;
})();