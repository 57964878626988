/* eslint-disable no-console */
import mapKeys from "lodash/mapKeys";
import _isObject from "lodash/isObject";
/*
 * Util methods to validate objects, array, string etc.
 */

// This Speed up calls to hasOwnProperty
const doesOwnProperty = Object.prototype.hasOwnProperty;

// call this method to check if object has valid propertyKey
export const isObject = (obj, propertyKey) => {
  // If it isn't an object at this point
  // it is empty, but it can't be anything *but* empty
  // Is it empty?  Depends on your application.
  if (doesOwnProperty.call(obj, propertyKey)) return true;
};

export const isObjEmpty = obj => {
  return JSON.stringify(obj) === "{}";
};

/* Util to check if key exist in object */
export const doesKeyExist = (obj, key) => {
  return obj.hasOwnProperty(key);
};

// @note- util scoped to check for null, object, string only
export const isEmptyObject = obj => {
  // null or undefined are "empty"
  if (obj === null || obj === undefined) return true;

  // Assume if it has a length property with a non-zero value
  // that property is correct.
  if (obj.length > 0) return false;
  if (obj.length === 0) return true;

  // If it isn't an object at this point
  // it is empty, but it can't be anything *but* empty
  // Is it empty?  Depends on your application.
  if (typeof obj !== "object") return true;

  // Otherwise, does it have any properties of its own?
  // Note that this doesn't handle
  // toString and valueOf enumeration bugs in IE < 9
  for (const key in obj) {
    if (doesOwnProperty.call(obj, key)) return false;
  }
  return true;
};

/**
 * Returns true if the passed value is empty, false otherwise. The value is deemed to be empty if it is either:
 *
 * - `null`
 * - `undefined`
 * - a zero-length array
 * - a zero-length string (Unless the `allowEmptyString` parameter is set to `true`)
 *
 * @param {Object} value The value to test
 * @param {Boolean} allowEmptyString (optional) true to allow empty strings (defaults to false)
 * @return {Boolean}
 * @markdown
 */
export const doesEmpty = (value, allowEmptyString) => {
  return (
    value === null ||
    value === undefined ||
    (!allowEmptyString ? value === "" : false) ||
    (isArrayExist(value) && value.length === 0)
  );
};

/**
 * Returns true if the passed value is a JavaScript Array, false otherwise.
 *
 * @param {Object} target The target to test
 * @return {Boolean}
 * @method
 */
export const isArrayExist = value => {
  return Object.prototype.toString.call(value) === "[object Array]";
};

export const isArrayList = val => {
  return Array.isArray(val);
};

// check if object not null and not array
export const isObjectOnly = val => {
  return typeof val === "object" && !Array.isArray(val) && val !== null;
};

export function cloneObject(obj) {
  return JSON.parse(JSON.stringify(obj));
}

export function deepCopyFromTemplate(source, template, alreadyCloned) {
  const cloneObject = alreadyCloned ? template : Object.assign({}, template);
  const keys = Object.keys(cloneObject);
  keys.forEach(key => {
    const defaultValue = cloneObject[key];
    const value = source[key];
    cloneObject[key] = value === undefined ? defaultValue : value;
  });
  return cloneObject;
}

export const objectStringify = data => {
  /*
  const myObj = {
    name: "Skip",
    age: 2,
    favoriteFood: "Steak"
  };
  */
  const result = JSON.stringify(data);
  return result;
};

export const boolToInt = object => {
  const newObject = {};
  /* eslint-disable array-callback-return */
  Object.keys(object).map(x => {
    newObject[x] = object[x] === true ? 1 : object[x] === false ? 0 : object[x];
  });
  /* eslint-enable array-callback-return */
  return newObject;
};

export const compareObject = (obj1, obj2) => {
  const result = {};
  if (Object.is(obj1, obj2)) {
    return undefined;
  }
  if (!obj2 || typeof obj2 !== "object") {
    return obj2;
  }
  Object.keys(obj1 || {})
    .concat(Object.keys(obj2 || {}))
    .forEach(key => {
      if (obj2[key] !== obj1[key] && !Object.is(obj1[key], obj2[key])) {
        result[key] = obj2[key];
      }
      if (typeof obj2[key] === "object" && typeof obj1[key] === "object") {
        const value = compareObject(obj1[key], obj2[key]);
        if (value !== undefined) {
          result[key] = value;
        }
      }
    });
  return result;
};

export const objectToArrayOfLabelValue = obj => {
  const arr = [];
  for (const key in obj) {
    arr.push({ value: key, label: obj[key] });
  }
  return arr;
};

// This func() filters the given "key" that should be deleted from Object,
// then builds a new object from the remaining keys and the initial object.
export function removeByKey(initObj, deleteKey) {
  return Object.keys(initObj)
    .filter(key => key !== deleteKey)
    .reduce((result, current) => {
      result[current] = initObj[current];
      return result;
    }, {});
}
/* Filter array of items based on matchValue; return only single record from list */
export function findRecord(list, key, match) {
  let cloneObj = {};
  // returns array of matched items
  const found = list.filter(function (obj) {
    return obj[key] === match;
  });

  if (found.length === 1) {
    cloneObj = Object.assign({}, found[0]);
  }
  return cloneObj;
}

export function findVariant(makelist, make) {
  const matches = makelist.filter(m => {
    return m.make === make;
  });
  return matches.length !== 0 ? matches[0].variant : "";
}

export function getMakeFromDealerCatalogs(makelist, make) {
  const matches = makelist.filter(m => {
    return m.make === make;
  });
  return matches.length !== 0 ? matches[0] : null;
}

/* Filter array of items based on query; return filtered list[] */
/* Example:
const heroes = [
  {name: "Batman", franchise: "DC"},
  {name: "Ironman", franchise: "Marvel"},
  {name: "Thor", franchise: "Marvel"},
  {name: "Superman", franchise: "DC"}
];
const marvelHeroes =  heroes.filter(function(hero) {
  return hero.franchise == "Marvel";
});
*/
export function filterRecords(data, key, query) {
  const filteredList = data.filter(function (obj) {
    return obj[key] === query;
  });
  return filteredList;
}

/* Filter array of items based on search criteria (query)  */
export function filterArrayObjects(data, key, query) {
  const final = data.filter(op => {
    return op[key].toLowerCase().indexOf(query.toLowerCase()) !== -1;
  });
  return final;
}

export const encodeNullValues = values => {
  // Iterate each property and check for a null value - then reset
  // to empty string if null is found - iterate recursivly for objects
  Object.keys(values).forEach(key => {
    const value = values[key];
    if (value === null || value === undefined) {
      values[key] = "";
    } else if (typeof value === "number") {
      values[key] = value.toString();
    } else if (isObject(value)) {
      values[key] = encodeNullValues(value);
    }
  });
  return values;
};

export function convertBooleanToBinary(values) {
  const keys = Object.keys(values);
  for (let index = 0; index < keys.length; index++) {
    const key = keys[index];
    if (typeof values[key] === "boolean") {
      values[key] = values[key] | 0;
    }
  }
  return values;
}

export function convertObjectValuesToUppercase(localeStringsObj) {
  const uppercasesLocaleStrings = {};
  Object.keys(localeStringsObj).forEach(key => {
    uppercasesLocaleStrings[key] = "*"
      // .concat(localeStringsObj[key].toUpperCase())
      .concat(key)
      .concat("*");
  });
  return uppercasesLocaleStrings;
}

export const formatKeysToLowerCase = obj =>
  mapKeys(obj, (value, key) => key.toLowerCase());

export const formatKeysToUpperCase = obj =>
  mapKeys(obj, (value, key) => key.toUpperCase());

export const freezeObject = obj => {
  const objKeys = Object.keys(obj);

  for (const objKey of objKeys) {
    if (_isObject(obj[objKey])) {
      obj[objKey] = Object.freeze(obj[objKey]);
    }
  }
  return Object.freeze(obj);
};
