// EXTERNAL LIBRARIES
import SimpleTable from "@cx/ui/SimpleTable";
import React from "react";
import PropTypes from "prop-types";
import format from "../../../utils/format";

const RoParts = ({ parts }) => {
  if (parts && parts.length > 0) {
    return (
      <div className="ro-service-history parts m-t-md">
        <SimpleTable htmlId="parts-table" comfortable hover={false}>
          <thead>
            <tr>
              <th className="tr-heading-text width-70" scope="col">
                Part description
              </th>
              <th className="tr-heading-text width-15" scope="col">
                Part number
              </th>
              <th
                className="tr-heading-text width-10 text-align-right"
                scope="col"
              >
                Price
              </th>
            </tr>
          </thead>
          <tbody className="services-body">
            {parts.map(part => {
              return (
                <tr key={`part${part?.roId}${part?.roItemNum}`}>
                  <th scope="row">
                    <span className="service-desc">
                      {part.partDesc || "--"}
                    </span>
                  </th>
                  <td className="service-desc ">{part.partNum || "--"}</td>
                  <td className="service-desc text-align-right">
                    {format.currency(part.partPrice)}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </SimpleTable>
      </div>
    );
  } else return false;
};

export default RoParts;

RoParts.prototype = {
  // data
  parts: PropTypes.array
};
