import { makeSecureRestApi } from "../api/xmmAxios";
import { assistanceRequestStatus } from "../constants/assiatance-request.constants";

/**
 //  * @description add/remove assistanceTags to quote and send email
 * @param {*} user 
 * @param {*} dealerCode 
 * @param {*} confirmationId 
 * @param {*} requestStatus 
 * @param {*} requestType 
 * @param {*} webKey 
 * @returns response
 */
const processQuoteAssistance = (
  user,
  dealerCode,
  confirmationId,
  requestStatus,
  requestType,
  webKey
) => {
  const headers = {
    Accept: "application/json"
  };
  const quoteAssistanceState =
    requestStatus === assistanceRequestStatus.REQUESTED
      ? assistanceRequestStatus.REQUEST
      : requestStatus === assistanceRequestStatus.COMPLETED
      ? assistanceRequestStatus.RESOLVE
      : requestStatus.toUpperCase();
  const quoteAssistanceType = requestType.toUpperCase();

  const requestBody = {
    user,
    webKey,
    quoteAssistanceType,
    quoteAssistanceState
  };
  const restEndPoint = `quoteAssistance/${dealerCode}/${confirmationId}`;

  return new Promise((resolve, reject) => {
    makeSecureRestApi(
      {
        url: restEndPoint,
        method: "post",
        data: { ...requestBody },
        params: {},
        headers
      },
      response => {
        resolve(response);
      },
      error => {
        reject(error);
      },
      "Unable to save CSR assistance request."
    );
  });
};

export default {
  processQuoteAssistance
};
