import _get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import { makeSecureRestApi } from "../../../api/xmmAxios";

const searchCustomers = ({ appContext, searchTerm }) => {
  const { dealer, schemaName } = appContext;
  const { dealerCode } = dealer;

  return new Promise((resolve, reject) => {
    makeSecureRestApi(
      {
        url: `/customer/search/${schemaName}/${dealerCode}`,
        method: "get",
        data: {},
        params: {
          searchKey: searchTerm,
          limit: 51
        }
      },
      response => {
        resolve(response);
      },
      error => {
        reject(error);
      },
      "Unable to search customers."
    );
  });
};

const searchCustomerById = ({ appContext, customerId }) => {
  const { dealer, schemaName } = appContext;
  const { dealerCode } = dealer;

  return new Promise((resolve, reject) => {
    makeSecureRestApi(
      {
        url: `/customer/${schemaName}/${dealerCode}/${customerId}`,
        method: "get"
      },
      response => {
        if (!response.success) {
          if (isEmpty(_get(response, "customers", []))) {
            response.customers = null;
          }
        }
        resolve(response);
      },
      error => {
        reject(error);
      },
      "Unable to find customer."
    );
  });
};

export default {
  searchCustomers,
  searchCustomerById
};
