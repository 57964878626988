import React, { useEffect, useContext, useState } from "react";
import PropTypes from "prop-types";
import ModalDialog from "@cx/ui/ModalDialog";
import platform from "platform";

import { detectBrowser } from "../../../../utils/browser.util";
import { getMetaTagContentByName } from "../../../../utils/dom.util";
import { AppContext } from "../../../../state/app-context";
import { AboutUsTemplate } from "../../../../constants/module.constants";
import "./about-style.scss";
import { checkIfIsCSR } from "../../../../api/app.util";

const AboutModal = props => {
  const { localeStrings, locale, user, webKey, windowSize, dealer, appType } =
    useContext(AppContext);
  const [aboutConfig, setAboutConfig] = useState(AboutUsTemplate);
  const isCSR = checkIfIsCSR(appType);

  useEffect(() => {
    const config = {
      dealerName: !dealer.dealerName ? "" : dealer.dealerName,
      dealerCode: !dealer.dealerCode ? "" : dealer.dealerCode,
      application: localeStrings["sq.common.app_name"],
      url: window.location.href,
      // buildVersion: getMetaTagContentByName("xtime:gitBranch").concat(
      //   " - ",
      //   getMetaTagContentByName("xtime:buildNumber")
      // ),
      buildVersion: getMetaTagContentByName("xtime:buildNumber"),
      browser: detectBrowser(),
      operatingSystem: platform.os.toString(),
      userName: user.userName,
      copyYear: new Date().getFullYear(),
      size: windowSize,
      locale
    };
    setAboutConfig(config);
  }, [
    webKey,
    localeStrings,
    user.userName,
    windowSize,
    locale,
    dealer.dealerCode,
    dealer.dealerName
  ]);

  const getContent = () => {
    const { size } = aboutConfig;
    const content = (
      <div className="about-card">
        <div className="about-info">
          <div className="about-key">
            {localeStrings["sq.common.dealer_name"]}
          </div>
          <div className="about-value">{aboutConfig.dealerName}</div>
        </div>
        <div className="about-info">
          <div className="about-key">
            {localeStrings["sq.common.dealer_code"]}
          </div>
          <div className="about-value">{aboutConfig.dealerCode}</div>
        </div>
        <div className="about-info">
          <div className="about-key">
            {localeStrings["sq.common.all_caps_url"]}
          </div>
          <div className="about-value abt-word-wrap">{aboutConfig.url}</div>
        </div>
        <div className="about-info">
          <div className="about-key">
            {localeStrings["sq.common.app_version"]}
          </div>
          <div className="about-value abt-word-wrap">
            {aboutConfig.buildVersion}
          </div>
        </div>
        <div className="about-info">
          <div className="about-key">{localeStrings["sq.common.username"]}</div>
          <div className="about-value">{aboutConfig.userName}</div>
        </div>
        <div className="about-info">
          <div className="about-key">{localeStrings["sq.common.language"]}</div>
          <div className="about-value">{aboutConfig.locale}</div>
        </div>
        <div className="about-info">
          <div className="about-key">{localeStrings["sq.common.browser"]}</div>
          <div className="about-value">{aboutConfig.browser}</div>
        </div>
        <div className="about-info">
          <div className="about-key">{localeStrings["sq.common.os"]}</div>
          <div className="about-value">{aboutConfig.operatingSystem}</div>
        </div>
        <div className="about-info">
          <div className="about-key">
            {localeStrings["sq.common.resolution"]}
          </div>
          <div className="about-value">
            {size.width}px / {size.height}px
          </div>
        </div>
      </div>
    );
    return content;
  };

  return (
    <ModalDialog
      htmlId="AboutModal"
      className="sample-modal-dialog"
      show={props.show}
      header={
        <ModalDialog.Title>
          {
            localeStrings[
              isCSR ? "csr.common.about_title" : "sq.common.about_title"
            ]
          }
        </ModalDialog.Title>
      }
      onHide={props.closeDialog}
    >
      <div>
        {getContent()}
        <div className="sq-footer">
          <span>
            <strong>
              {"\xA9 "} {aboutConfig.copyYear}{" "}
              {localeStrings["sq.common.copyright"]}
            </strong>
          </span>
        </div>
      </div>
    </ModalDialog>
  );
};

export default AboutModal;
AboutModal.propTypes = {
  closeDialog: PropTypes.func,
  show: PropTypes.bool
};
