// This util will sort menuServices by "rank"

import isEmpty from "lodash/isEmpty";

export const sortingMenuServices = menuServices => {
  menuServices.sort((a, b) => {
    if (!isEmpty(a) && !isEmpty(b)) {
      const orderA = !a.rank ? 0 : a.rank;
      const orderB = !b.rank ? 0 : b.rank;

      if (orderA && orderB) {
        if (orderA < orderB) {
          return -1;
        }
        if (orderA > orderB) {
          return 1;
        }
      }
    }
    return 0;
  });
  return menuServices;
};

export const sortByLastTwoCharacters = coreParts => {
  return coreParts?.sort((a, b) => {
    const lastTwoA = a?.oemPartNumber?.slice(-2);
    const lastTwoB = b?.oemPartNumber?.slice(-2);
    return lastTwoA?.localeCompare(lastTwoB);
  });
};
