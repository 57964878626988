import { namespace } from "../../../api/xmmAxios";

/**
 * Created by raja on 05/05/2023.
 * Util methods defined to support support Goggle Tag Manager
 * How to push event dimensions to dataLayer
 * Example:
    window.dataLayer.push({
    "common": {
        "dataLayerVersion": 1,
        "user": {
            "applicationUser": "rajap",
            "userType": "XTEMPLOYEE",
            "isInternalUser": true
        },
        "application": {
            "businessUnitName": "Xtime",
            "name": "ServiceQuoting",
            "version": "8.8.0-DEVELOP - 53", // eg: build version
            "environment": "dev6", //eg: qa6, dev6,stg4, ua9, production
            "isProduction": false
        },
        "context": {
            "dealershipId": "XTIMEQUOTINGXX1",
            "dealershipName": "XTIME QUOTING TEST"
        }
    },
    "appName": "ServiceQuoting",
    "appSource": "IND", // eg: IND, SCH
    "appVersion": "8.8.0-DEVELOP - 53", // eg: build version
    "environment": "dev6",  //eg: qa6, dev6,stg4, ua9, production
    "user": 131000035347,
    "dealerCode": "XTIMEQUOTINGXX1",
    "dmsType": "OTHER",
    "schemaName": "x7",  //eg: x5,x7
    "trainingRole": "XTEMPLOYEE",
    "userDomain": "coxautoinc.com",
    "readOnly": false, // true - readonly access or false - full access
    "webKey": "xtimequotingxx1",
    "dealerCountry": "US",
    "locale": "en_US"
});
*/

/* eslint-disable no-console */
export const isObjEmpty = obj => {
  return JSON.stringify(obj) === "{}";
};

export function getDataLayer() {
  const _dataLayer = (window.dataLayer = window.dataLayer || []);
  return _dataLayer;
}

export function logDataLayer() {
  const _dataLayer = getDataLayer();
  console.log(_dataLayer);
}

/**
 * Track page navigation using GTM track type "pageview"; hits are logged in GA (under realtime content, behavior/ site content)
 * @param {String} url The URL to track.
 * GA Event structure: 
  const eventObj =  {
    event: "productEvent",
    eventName: "virtualPageview",
    eventProperties: {
      location: "dashboard" // eg: "newquote"
    }
  }; 
 */
export function trackPage(pageObj) {
  const _dataLayer = getDataLayer();
  if (typeof pageObj === "object" && Object.keys(pageObj).length !== 0) {
    // pageObj.eventPathname = pageObj.trackPageUrl;
    _dataLayer.push(pageObj);
  }
}

/**
 * Track a specific event like button click, dropdown, element click from app
 * @param {String} event - defined as "productEvent" (required)
 * @param {String} eventName - user defined name given for element action (required)
 * @param {Object} eventProperties
 * @param {String} result - define about expected result (optional)
 * @param {String} location - page name when event is fired (optional)
 */
/* GA Event structure:
const eventObj = {
    event: "productEvent",
    eventName: "Save Quote Click"
    eventProperties: {
        result: "",
        location: "confirmation-page" 
      }
 };
*/
export function trackEvent(dataObj) {
  const _dataLayer = getDataLayer();

  if (!isObjEmpty(dataObj)) {
    _dataLayer.push(dataObj);
  }
  // logDataLayer();
}

/**
 * Push application/user related dynamic values to dataLayer (used in GA dimensions)
 * @param {Object} dataObj pushed to dataLayer.
 * Usage:
 *  pushDimensions({
		 	'isMember' :  true
		});
 */
export function pushDimensions(dataObj) {
  const _dataLayer = getDataLayer();
  if (!isObjEmpty(dataObj)) {
    _dataLayer.push(dataObj);
    logDataLayer();
  }
}

/**
 * returns environment to be added to GTM data layer
 * @returns {*}
 */
export function getEnvironment() {
  const env =
    namespace === "prod" || namespace === "prodaus" ? "production" : namespace;
  return env;
}

// Quoting app methods - NEW
// For GTM session data, we want to initialize the data layer, fire off a page view, and then collect User events
export function pushGTagAppDetails(dataLayerObj) {
  pushDimensions(dataLayerObj);
  window.dataLayer.push({
    event: "dataLayerReady",
    dataLayerReady: true
  });
}

/**
 * Track specific restful service load time using GTM event "userTiming"
 * @param {String} event, defined as "userTiming"
 * @param {String} timingCategory
 * @param {String} timingVar The action itself.
 * @param {String} timingLabel The label of the action.
 * @param {String} timingValue, should be Integer (capture counts, US dollars, price values) - optional
 * @param {String} trackPageUrl (used to log rest API Url)
 */
export function trackAPIRunTime(dataObj) {
  const _dataLayer = getDataLayer();

  if (!isObjEmpty(dataObj)) {
    _dataLayer.push(dataObj);
  }
  // logDataLayer();
}

// !deprecated: push specific rest API URLs to GA
export function logRestAPI(logObj, response) {
  const { serviceName, method } = logObj;
  // track dealer APIs
  if (serviceName.indexOf("/rest/dealer/settings/") > -1) {
    console.log("logRestAPI", serviceName, response);
    trackGTagPageLoadTime(logObj, "/dealer/settings/" + method);
  }
  // track customer APIs
  if (serviceName.indexOf("/rest/customer/search/") > -1) {
    trackGTagPageLoadTime(logObj, "/customer/search/" + method);
  }
  // track vehicle APIs
  if (serviceName.indexOf("/rest/vehicle/getMetaVehicleId/") > -1) {
    trackGTagPageLoadTime(logObj, "/vehicle/getMetaVehicleId/" + method);
  }
  // track service APIs
  if (serviceName.indexOf("/payTypes/") > -1) {
    trackGTagPageLoadTime(logObj, "/service/payTypes/" + method);
  }
  if (serviceName.indexOf("/quote/services/topServices/") > -1) {
    trackGTagPageLoadTime(logObj, "/services/topServices/" + method);
  }
  if (serviceName.indexOf("/quote/services/globalOperations/") > -1) {
    trackGTagPageLoadTime(logObj, "/services/globalOperations/" + method);
  }
  if (serviceName.indexOf("/quote/services/menus/") > -1) {
    trackGTagPageLoadTime(logObj, "/services/menus/" + method);
  }
  if (serviceName.indexOf("/recallDeclined/") > -1) {
    trackGTagPageLoadTime(logObj, "/services/recallDeclined/" + method);
  }
  if (serviceName.indexOf("/partsLookup/pricingAndInventory/") > -1) {
    trackGTagPageLoadTime(
      logObj,
      "/services/partsLookup/pricingAndInventory/" + method
    );
  }
  if (serviceName.indexOf("/quote/services/labor/") > -1) {
    trackGTagPageLoadTime(logObj, "/services/labor/" + method);
  }
  // track quote APIs
  if (serviceName.indexOf("/rest/pastQuotes/") > -1) {
    trackGTagPageLoadTime(logObj, "/dashboard/pastQuotes/" + method);
  }
  if (serviceName.indexOf("/rest/quote/") > -1) {
    let restName = "/quote/create/";
    if (method === "POST") restName = "/quote/create/";
    else if (method === "PUT") restName = "/quote/update/";
    else if (method === "PATCH") restName = "/quote/patch/";
    trackGTagPageLoadTime(logObj, restName + method);
  }
  if (serviceName.indexOf("/copyQuote/") > -1) {
    trackGTagPageLoadTime(logObj, "/quote/copy/" + method);
  }
}
// !deprecated - no need to track API run time in quoting via GA; instead use NewRelic dashboard
/**
 * Track specific restful service load time using GTM event "userTiming"
 * @param {object} capture services
 * @param {String} pageName
 * @param {String} loadTime; total response time of service
 */
export function trackGTagPageLoadTime(logObj, pageName) {
  // const pathArray = logObj.serviceName.split("/");
  // const newServiceName = pathArray[pathArray.length - 1];
  const timingobj = {
    event: "userTiming",
    timingCategory: "Pageload",
    timingVar: pageName,
    timingLabel: "Track load time of Service: " + pageName,
    timingValue: logObj.loadTime, // send total service response time as custom dimension value to GA via GTM dataLayer
    serviceName: logObj.serviceName, // send only serviceName value as custom dimension to GA via GTM dataLayer, instead of service urlPath
    trackLoadTime: logObj.loadTime, // send custom dimension value to GA via GTM dataLayer
    make: "",
    dealerCode: ""
  };
  // console.log("trackGTagPageLoadTime", newServiceName, timingobj);
  trackAPIRunTime(timingobj);
}
