// EXTERNAL LIBRARIES
import LoadingIndicator from "@cx/ui/LoadingIndicator";
import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
// CUSTOM COMPONENTS
import ServiceHistoryHeader from "./components/service-history-header.component";
import ServiceHistoryRepairOrders from "./components/service-history-repair-orders.component";
import ServiceHistoryFooter from "./components/service-history-footer.component";
// UTILS
import { AppContext } from "../../../../state/app-context";
import {
  getVehicleInfo$,
  searchByRoIdServiceNameOrOpcode
} from "./utils/service-history-utils";
// STYLES
import "./service-history.scss";

const ServiceHistory = ({
  isRoSelectable,
  vin,
  onHideComeBack,
  onSaveRoSelected,
  roNumber
}) => {
  const appContext = useContext(AppContext);
  const { dealer, windowSize } = appContext;
  const [vehicleInfo, setVehicleInfo] = useState(null);
  const [search, setSearch] = useState("");
  const [roIdSelected, setRoIdSelected] = useState(roNumber);
  const [isFetchingData, setIsFetchingData] = useState(false);
  const repairOrdersFiltered = searchByRoIdServiceNameOrOpcode(
    search,
    vehicleInfo?.repairOrders || []
  );
  useEffect(() => {
    const fetchData = async () => {
      const vehicleData = await getVehicleInfo$(dealer, vin);
      setVehicleInfo(vehicleData);
      setIsFetchingData(false);
    };
    setIsFetchingData(true);
    fetchData().then();
  }, [dealer, vin]);
  /**
   * Update the state with the new filter value used for filtering repair orders.
   * @param {string} searchText
   */
  const onChangeSearchValue = searchText => {
    setSearch(searchText);
  };
  /**
   * Handler function to toggle the checkbox status
   * @param {string} roId
   */
  const handleCheckboxChange = roId => {
    setRoIdSelected(prevRoId => (roId === prevRoId ? "" : roId));
  };
  /**
   * Close regular service history window pop-up
   */
  const closeWindowPopUp = () => {
    window.close();
  };
  /**
   * Handler function to save RoId selected
   */
  const handleRoSelected = () => {
    onSaveRoSelected(roIdSelected);
  };
  return (
    <div className="service-history-container">
      {vehicleInfo !== null ? (
        <>
          <ServiceHistoryHeader
            vehicleInfo={vehicleInfo}
            countryCode={dealer.dealerCountryCode}
            windowSize={windowSize}
            onChangeSearchValue={onChangeSearchValue}
            showSearchBar={isRoSelectable}
          />

          {repairOrdersFiltered.length > 0 ? (
            <ServiceHistoryRepairOrders
              repairOrders={repairOrdersFiltered}
              isRoSelectable={isRoSelectable}
              roIdSelected={roIdSelected}
              handleCheckboxChange={handleCheckboxChange}
            />
          ) : (
            <span className="no-data-found m-t-lg">
              No service records found
            </span>
          )}
        </>
      ) : isFetchingData ? (
        <LoadingIndicator
          htmlId="ServiceHistoryIndicator"
          size="large"
          className="service-history-loader"
        />
      ) : (
        <span className="no-data-found">No data found</span>
      )}
      <ServiceHistoryFooter
        isComeBackModal={isRoSelectable}
        onCloseWindow={closeWindowPopUp}
        onCancelModal={onHideComeBack}
        onSave={handleRoSelected}
      />
    </div>
  );
};

export default ServiceHistory;

ServiceHistory.propTypes = {
  // handlers
  onHideComeBack: PropTypes.func,
  onSaveRoSelected: PropTypes.func,
  // data
  isRoSelectable: PropTypes.bool,
  vin: PropTypes.string,
  roNumber: PropTypes.string
};
