import { makeSecureRestApi } from "../../../api/xmmAxios";

/**
 * Retrieves service contracts information for a specific dealer, vehicle, and consumer.
 *
 * @function getServiceContracts
 * @param {Object} params - The parameters for retrieving service contracts.
 * @param {string} params.dealerCode - The code of the dealer.
 * @param {string} params.vin - The vehicle identification number.
 * @param {string} params.commonConsumerId - The common consumer ID.
 * @returns {Promise<Object>} A promise that resolves with the service contracts data or rejects with an error.
 */
export const getServiceContracts = ({ dealerCode, vin, commonConsumerId }) => {
  const headers = {
    Accept: "application/json"
  };
  const restEndPoint = `csr/servicecontract/dealers/id/${dealerCode}/vin/${vin}`;

  return new Promise((resolve, reject) => {
    makeSecureRestApi(
      {
        url: restEndPoint,
        method: "get",
        data: {},
        params: {
          commonConsumerId
        },
        headers
      },
      response => {
        resolve(response);
      },
      error => {
        const data = error?.response?.data;

        if (Array.isArray(data)) {
          resolve(data);
        } else {
          reject(error);
        }
      },
      "Service Contracts failed, please try again in a few minutes."
    );
  });
};
