/* eslint-disable react/jsx-key */
/* eslint-disable no-console */
import React from "react";
import PropTypes from "prop-types";
import CustomLoadingOverlay from "./reusable/loadingmask/CustomLoadingOverlay";
import { priceFormatter, toEmptyStringIfUndefined } from "./utils/helper";
import NumericEditor from "./editors/NumericEditor";
import { AgGridReact } from "ag-grid-react";
import IconInfoOutline from "@cx/ui/Icons/IconInfoOutline";
import IconWarning from "@cx/ui/Icons/IconWarning";
import Badge from "@cx/ui/Badge";
import Popover from "@cx/ui/Popover";
import Tooltip from "@cx/ui/Tooltip";
import LoadingIndicator from "@cx/ui/LoadingIndicator";
// import Table from "@cx/ui/Table";
import SimpleTable from "@cx/ui/SimpleTable";
// import { toNumberZeroIfUndefined } from "./utils/string";
import IconDelete from "@cx/ui/Icons/IconDelete";
// import RemoveRowButton from "./RemoveRowButton";
import Confirmation from "./modal/Confirmation";
import TextEditor from "./editors/TextEditor";
// import { applyCustomKeyNavigation } from "./utils/keyNavigation";

class PartsGridExt extends React.Component {
  // static contextType = PreviewContext;
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.parts !== prevState.parts) {
      const {
        disableAllParts,
        parts,
        naNrNssParts,
        selectedVehicleAttributeMap
        // fluidHasQualifiers,
        // disableQtyEdit
      } = nextProps;
      const { fluidsAndParts, additionalColumns } =
        filterPartsBySelectedVehicleAttributeMap(
          parts,
          selectedVehicleAttributeMap
        );
      const selectedParts = fluidsAndParts.filter(p => p.selected);
      const columnDefs = prevState.getColumnList(
        fluidsAndParts,
        // disableAllParts,
        // showCheckboxes,
        // showRemove,
        additionalColumns,
        prevState.parentHandle
      );
      setTimeout(() => {
        prevState.adjustGridColumns();
        prevState.parentHandle.preselectRow();
        prevState.parentHandle.refreshGrid();
      }, 200);
      return {
        disableAllParts,
        rowData: fluidsAndParts,
        parts,
        selectionlist: selectedParts,
        naNrNssParts,
        selectedVehicleAttributeMap,
        columnDefs
      };
    }
    return null;
  }
  constructor(props, context) {
    super(props, context);
    this.onCellValueChanged = this.onCellValueChanged.bind(this);
    this.onGridReady = this.onGridReady.bind(this);
    this.onFilterChanged = this.onFilterChanged.bind(this);
    this.handleSelectionChanged = this.handleSelectionChanged.bind(this);
    this.adjustGridColumns = this.adjustGridColumns.bind(this);
    this.sizeToFit = this.sizeToFit.bind(this);
    this.setAutoHeight = this.setAutoHeight.bind(this);
    this.handleGridSizeChanged = this.handleGridSizeChanged.bind(this);
    this.removePartRow = this.removePartRow.bind(this);
    this.clickOnRemoveButton = this.clickOnRemoveButton.bind(this);
    this.getColumnList = this.getColumnList.bind(this);
    this.renderPageTitle = this.renderPageTitle.bind(this);
    this.refreshGrid = this.refreshGrid.bind(this);
    const {
      parts,
      partsGridLabel,
      naNrNssParts,
      selectedVehicleAttributeMap,
      noRowMessage
    } = props;

    const { fluidsAndParts, additionalColumns } =
      filterPartsBySelectedVehicleAttributeMap(
        parts,
        selectedVehicleAttributeMap
      );
    const selectedParts = fluidsAndParts.filter(p => p.selected);
    const gridOptions = {
      errors: {},
      // disableAllParts,
      // disableQtyEdit,
      // other state props
      pageTitle: partsGridLabel,
      // ag-grid props
      rowData: fluidsAndParts,
      parts,
      naNrNssParts,
      selectedVehicleAttributeMap,
      selectionlist: selectedParts,
      columnDefs: this.getColumnList(
        fluidsAndParts,
        // disableAllParts,
        // showCheckboxes,
        // showRemove,
        additionalColumns,
        this
      ),
      defaultColDef: {
        // cellClass: "xmm-wrap-cell",
        sortable: true,
        resizable: true,
        editable: false, // default disable editor
        enableRowGroup: false,
        sortingOrder: ["asc", "desc", null],
        filter: "agTextColumnFilter",
        filterParams: {
          suppressMiniFilter: false,
          buttons: ["clear"]
        },
        menuTabs: ["filterMenuTab"],
        headerComponentParams: {
          template: `
          <div class="ag-cell-label-container" role="presentation">
            <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>
            <div ref="eLabel" class="ag-header-cell-label" role="presentation">
              <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
              <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>
              <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span>
              <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span>
              <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon" ></span>
            </div>
          </div>
          `
        },
        // suppressKeyboardEvent: applyCustomKeyNavigation,
        floatingFilter: false, // true - enable column header filters
        suppressMenu: true, // disabled menu filters
        rowGroup: false
      },
      multiSortKey: "ctrl",
      frameworkComponents: {
        // removeRowButton: RemoveRowButton,
        customLoadingOverlay: CustomLoadingOverlay,
        numericEditor: NumericEditor,
        textEditor: TextEditor
      },
      loadingOverlayComponent: "customLoadingOverlay",
      loadingOverlayComponentParams: {
        loadingMessage: "Loading",
        isLoading: true,
        noRows: false
      },

      noRowsOverlayComponent: "customLoadingOverlay",
      noRowsOverlayComponentParams: {
        loadingMessage: noRowMessage,
        isLoading: false,
        noRows: true
      },

      // true - use browser default tooltip instead of ag-grid tooltip
      enableBrowserTooltips: true,
      rowSelection: "multiple", // allows multiple row selections with check column
      // eslint-disable-next-line unused-imports/no-unused-vars
      isRowSelectable(rowNode) {
        return true; // to see checkbox
      },
      columnTypes: {
        nonEditableColumn: { editable: false },
        numberColumn: {
          width: 160,
          filter: "agNumberColumnFilter"
        },
        // priceColumn: {
        //   editable: false,
        //   valueFormatter: priceFormatter,
        //   filter: "agNumberColumnFilter",
        //   cellClass: "xmm-wrap-cell xmm-grid-price",
        //   cellStyle: {
        //     color: "black",
        //     textAlign: "left"
        //   }
        // },
        actionColumn: {
          filter: false,
          editable: false,
          sortable: false,
          suppressMenu: true,
          enableRowGroup: false
        }
      },
      onColumnMoved: this.refreshGrid,
      onColumnPinned: this.refreshGrid,
      domLayout: "autoHeight",
      // popupParent: document.body,
      sideBar: false,
      getColumnList: this.getColumnList,
      adjustGridColumns: this.adjustGridColumns,
      parentHandle: this,
      showDeleteModal: false,
      deleteParams: {}
    };
    this.state = gridOptions;
  }
  // This life cyle event was fired before grid rownodes are set - so error prone
  // componentDidUpdate(prevProps, prevState) {
  //   if (prevState.rowData !== this.state.rowData) {
  //     this.adjustGridColumns();
  //     this.preselectRow();
  //     this.refreshGrid();
  //   }
  //   console.log(prevProps);
  // }
  defaultToZeroIfNullOrEmpty = value => {
    return !value ? 0 : Number(value);
  };
  calculateTotal(parts) {
    let total = 0;
    // if (typeof(this.props.partsOverridePrice) === "number" && this.props.partsOverridePrice > 0) {
    if (this.defaultToZeroIfNullOrEmpty(this.props.partsOverridePrice) > 0) {
      total = this.props.partsOverridePrice;
    } else {
      parts.forEach(p => {
        total +=
          this.defaultToZeroIfNullOrEmpty(p.quantity) *
          this.defaultToZeroIfNullOrEmpty(p.unitPrice);
      });
    }
    return priceFormatter(total);
  }
  getColumnList(
    fluidsAndParts,
    // disableAllParts,
    // showCheckboxes,
    // showRemove,
    additionalColumns,
    parentHandle
  ) {
    const {
      allowDescriptionEdit,
      allowPriceEdit,
      allowPartRelationshipEdit,
      disableAllParts,
      disableQtyEdit,
      fluidHasQualifiers,
      showCheckboxes,
      showPartRelationship,
      showRemove
    } = parentHandle.props;

    const optionalParts = fluidsAndParts.filter(
      p => p.relationship === "Related/Optional"
    );
    // eslint-disable-next-line unused-imports/no-unused-vars
    const relationshipOrder = {
      Primary: "0",
      Recommended: "1",
      "Related/Optional": "2"
    };
    const optionalPartsFlag =
      showPartRelationship && optionalParts.length !== 0;
    const columns = [
      {
        headerName: "",
        headerCheckboxSelection: fluidsAndParts.length && !disableAllParts,
        headerCheckboxSelectionFilteredOnly: !disableAllParts,
        checkboxSelection: true,
        pinned: "left",
        field: "checked",
        type: "actionColumn",
        hide: !showCheckboxes,
        cellStyle: () => {
          return disableAllParts
            ? { "pointer-events": "none", opacity: "0.4" }
            : "";
        },
        suppressSizeToFit: true,
        suppressColumnsToolPanel: true, // hide item in sidebar.columns
        maxWidth: 37,
        minWidth: 37,
        width: 37
      },
      {
        field: "",
        headerName: "Remove",
        hide: !showRemove,
        editable: false,
        cellClass: "editable-cell",
        // cellEditorSelector(params) {
        //   params.api.stopEditing();
        //   return { component: "removeRowButton" };
        // },
        // cellEditorParams: {
        //   parentHandle: this
        // },
        cellRendererFramework: removeCellRenderer,
        cellRendererParams: {
          parentHandle: this
        },
        enableRowGroup: false,
        maxWidth: 80,
        minWidth: 80,
        filter: false,
        suppressSizeToFit: true,
        suppressMenu: true,
        suppressColumnsToolPanel: true
      },
      {
        headerName: "Qty",
        headerClass: "ag-text-header",
        // pinned: "left",
        field: "quantity",
        editable: !disableAllParts && !disableQtyEdit,
        cellClass: "d-block-cell",
        cellEditor: "numericEditor",
        cellEditorParams(params) {
          const { data } = params;
          let allowDecimals = false;
          let maxLength = -1;
          const maxValue = 100;
          if (data) {
            const { partType } = data;
            if (partType === "part") {
              maxLength = 3;
            } else {
              allowDecimals = true;
              maxLength = 4;
            }
          }
          return { parentHandle, allowDecimals, maxLength, maxValue };
        },
        cellStyle: {
          color: "black",
          textAlign: "left"
        },
        cellRendererFramework: quantityCellRenderer,
        type: "numberColumn",
        maxWidth: 140,
        minWidth: 85
        // width: 75
      },
      {
        headerName: "Description",
        field: "partName",
        tooltipField: "partName",
        headerClass: "ag-text-header",
        editable: allowDescriptionEdit,
        cellEditor: "textEditor",
        cellEditorParams: {
          maxLength: 200
          // cellHeight: 25
        },
        cellRendererFramework: descriptionCellRenderer,
        // maxWidth: 500,
        minWidth: 200
        // width: 280,
      },
      {
        headerName: "Part relationship",
        field: "relationship",
        hide: !optionalPartsFlag && !allowPartRelationshipEdit,
        // valueFormatter: relFormatter,
        headerClass: "ag-text-header",
        editable: allowPartRelationshipEdit,
        enableRowGroup: false,
        cellEditorPopup: true,
        cellEditorSelector() {
          return {
            component: "agRichSelectCellEditor",
            params: {
              values: Object.keys(RelationShipMap)
            }
          };
        },
        cellClass: "editable-caret-cell",
        refData: RelationShipMap,
        maxWidth: 150
      },
      {
        headerName: "Part number",
        field: "oemPartNumber",
        headerClass: "ag-text-header",
        cellRendererFramework: partNumberCellRenderer,
        // suppressSizeToFit: true,
        maxWidth: 150,
        minWidth: 100
        // width: 200
      },
      {
        headerName: "Price/item",
        field: "unitPrice",
        headerClass: "ag-numeric-header",
        editable: allowPriceEdit,
        cellClass: "d-block-cell",
        cellRendererFramework: priceCellRenderer,
        cellEditor: "numericEditor",
        cellEditorParams() {
          const allowDecimals = true;
          const decimalPlaces = 2;
          const maxLength = 6;
          const maxValue = 999.99;
          return {
            parentHandle,
            allowDecimals,
            decimalPlaces,
            maxLength,
            maxValue
          };
        },
        suppressSizeToFit: true,
        type: "numberColumn",
        maxWidth: 140,
        minWidth: 140
        // width: 110
      },
      {
        field: "partId",
        headerName: "Part ID",
        headerClass: "ag-text-header",
        minWidth: 80,
        maxWidth: 80,
        hide: !this.props.showPartId,
        suppressSizeToFit: false,
        suppressColumnsToolPanel: true
      },
      // @todo: show this column for dev-testing
      {
        field: "rowId",
        headerName: "Row ID",
        headerClass: "ag-text-header",
        minWidth: 80,
        maxWidth: 80,
        hide: !this.props.showPartId,
        suppressSizeToFit: false,
        suppressColumnsToolPanel: true
      }
    ];
    if (fluidHasQualifiers) {
      columns.push({
        headerName: "Qualifiers",
        field: "qualifiers",
        headerClass: "ag-text-header",
        tooltipField: "qualifiers",
        // suppressSizeToFit: true,
        // maxWidth: 300,
        // minWidth: 500,
        width: 350
      });
    }
    columns.push({
      headerName: "Notes",
      field: "notes",
      headerClass: "ag-text-header",
      cellRendererFramework: noteCellRenderer,
      maxWidth: 80,
      minWidth: 80,
      // hide: !parentHandle.props.showNotes
      hide: true
    });
    if (additionalColumns && additionalColumns.length !== 0) {
      additionalColumns.forEach(column => {
        const { field, headerName } = column;
        columns.push({
          headerName,
          field,
          tooltipField: field,
          headerClass: "ag-text-header",
          filter: "agSetColumnFilter",
          // suppressSizeToFit: true
          maxWidth: 150
          // minWidth: 150
          // width: 120
        });
      });
    }
    return columns;
  }
  onGridReady = params => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    setTimeout(() => {
      this.adjustGridColumns();
      this.preselectRow();
    }, 0);
    this.gridApi.closeToolPanel();
    this.applySortConfig();
  };
  onCellValueChanged(params) {
    const { colDef, data, newValue, oldValue } = params;
    if (
      toEmptyStringIfUndefined(oldValue) !== toEmptyStringIfUndefined(newValue)
    ) {
      if (data) {
        if (colDef.field === "unitPrice") {
          const unitPrice = parseFloat(newValue);
          data.priceSource = isNaN(unitPrice) || unitPrice <= 0 ? "" : "MANUAL";
          data.dmsPrice = "";
          this.refreshGrid(params);
          this.setState({ parts: this.state.parts });
        }
        data.modified = true;
        this.props.onModified();
      }
    }
  }
  setAutoHeight = () => {
    // this.gridApi.setDomLayout("autoHeight");
    // document.querySelector("#partsGrid").style.height = "";
    const { domLayout, rowData } = this.state;
    const { allowAutoHeight, maxRows } = this.props;
    if (rowData) {
      const newDomLayout =
        allowAutoHeight && rowData.length < maxRows ? "autoHeight" : "";
      if (domLayout !== newDomLayout) {
        this.gridApi.setDomLayout(newDomLayout);
        this.setState({ domLayout: newDomLayout });
      }
    }
  };
  sizeToFit = () => {
    this.gridApi && this.gridApi.sizeColumnsToFit();
  };
  handleGridSizeChanged = event => {
    const { clientWidth, clientHeight } = event;
    if (clientWidth && clientHeight) {
      this.sizeToFit();
    }
  };
  /* This selection handler returns selected records from grid */
  handleSelectionChanged = () => {
    if (this.gridApi) {
      const selectedRows = this.gridApi.getSelectedRows();
      const { onSelectRow } = this.props;
      const { rowData } = this.state;
      rowData.forEach(d => {
        d.selected = selectedRows.includes(d);
      });
      onSelectRow(selectedRows);
      this.setState({ selectionlist: selectedRows });
    }
  };
  /* "filterChanged" - listen to the column filter events; can be used to  clear column filters */
  onFilterChanged = () => {
    if (this.gridApi) {
      this.clearGridSelections();
    }
  };
  /* Un-select all rows, regardless of filtering from grid */
  clearGridSelections = () => {
    if (this.gridApi) {
      this.gridApi.deselectAll();
      this.setState({ selectionlist: [] });
    }
  };
  getRowNodeId(data) {
    return data.rowId;
  }
  adjustGridColumns() {
    this.sizeToFit();
    this.setAutoHeight();
  }
  preselectRow() {
    const { rowData, selectionlist } = this.state;

    if (!rowData || !rowData.length || !this.gridApi) {
      return;
    }

    if (rowData && rowData.length === 1) {
      if (rowData[0].selected) {
        this.gridApi.forEachNode(node => {
          node.setSelected(true);
        });
      }
    } else {
      if (selectionlist && selectionlist.length !== 0) {
        selectionlist.forEach(item => {
          if (item.selected) {
            const node = this.gridApi.getRowNode(item.rowId);
            if (node) {
              node.setSelected(true);
            }
          }
        });
      }
    }
  }

  applySortConfig() {
    const defaultSortModel = [
      {
        colId: "relationship",
        sort: "asc",
        sortIndex: 0
      },
      {
        colId: "partName",
        sort: "asc",
        sortIndex: 1
      }
    ];
    this.assignColumnState(defaultSortModel);
  }
  assignColumnState = defaultSortModel => {
    this.gridColumnApi &&
      this.gridColumnApi.applyColumnState({
        state: defaultSortModel,
        defaultState: {
          // important to say 'null' as undefined means 'do nothing'
          sort: null
        }
      });
  };
  /* This method can be called to refresh single or multi rows */
  refreshGrid(params) {
    if (params) {
      params.api.refreshCells({
        force: true,
        columns: ["quantity", "unitPrice"]
      });
    } else {
      this.gridApi &&
        this.gridApi.refreshCells({
          force: true,
          columns: ["quantity", "unitPrice"]
        });
    }
  }
  renderPageTitle(rowData) {
    const pageTitle = this.props.partsGridLabel;
    const countBadge = (
      <Badge htmlId="partsCount" color="gray" key="partsCount">
        {rowData.length}
      </Badge>
    );
    let totalPartsPrice = "";
    if (this.props.showTotal) {
      totalPartsPrice = (
        <div className="ops-total-count">
          Total: {this.calculateTotal(rowData)}
        </div>
      );
    }
    return (
      <div className="ops-part-description">
        <span className="ops-description">{pageTitle}</span>
        {countBadge}
        {totalPartsPrice}
      </div>
    );
  }
  rendreNaNrNssPopover(naNrNssParts) {
    const posts = naNrNssParts.map((post, index) => {
      let notes = [];
      if (post.notes && post.notes.length > 0) {
        notes = post.notes[0];
      }
      return (
        // eslint-disable-next-line react/no-array-index-key
        <tr className="ops-ant-table-row" key={`key${index}`}>
          <td>
            <span>{post.partName}</span>
          </td>
          <td>
            <span>{post.oemPartNumber}</span>
          </td>
          <td>
            <span>{notes}</span>
          </td>
        </tr>
      );
    });
    const main = (
      <div className="ops-na-table-container">
        <table>
          <thead className="ant-table-thead">
            <tr>
              <th>Parts Not Applicable</th>
            </tr>
          </thead>
          <tbody>{posts}</tbody>
        </table>
      </div>
    );
    return main;
  }
  renderNaNrNssIconWithPopover(naNrNssParts) {
    if (!naNrNssParts || naNrNssParts.length === 0) {
      return "";
    }
    const popoverContent = this.rendreNaNrNssPopover(naNrNssParts);
    return (
      <span className="ops-na-parts">
        <Popover
          htmlId="naNrNssLink"
          trigger={["click", "outsideClick"]}
          className="ops-na-parts-popover"
          popoverContent={popoverContent}
          position="right"
        >
          <IconInfoOutline className="info" />
        </Popover>
      </span>
    );
  }
  showLoadingOverlay() {
    this.gridApi && this.gridApi.showLoadingOverlay();
  }
  hideOverlay() {
    this.gridApi && this.gridApi.hideOverlay();
  }

  getPartsPricingAndInventory(serviceApiCallback, params) {
    const {
      // appSource,
      dealerCode,
      // make,
      // vin,
      // operationId,
      // operationSource,
      // payType,
      // serviceType,
      // // opcode, // not needed
      parts
    } = params;
    if (
      dealerCode &&
      parts &&
      parts.length !== 0 &&
      parts.filter(p => !!p.oemPartNumber).length !== 0
    ) {
      // let opcode = null;
      // if (labors && labors.length !== 0) {
      //   const { opCode, dmsOpcode } = labors[0];
      //   opcode = dmsOpcode ? dmsOpcode : opCode;
      // }
      this.updatePartsPricingAndInventory(serviceApiCallback, params);
    }
  }
  updatePartsPricingAndInventory(serviceApiCallback, params) {
    // const { partsAndLabor } = this.state;
    // const { parts } = partsAndLabor;
    const {
      appSource,
      // dealerCode,
      // make,
      vin,
      operationId,
      operationSource,
      payType,
      serviceType,
      parts
    } = params;

    // get unique part numbers for getting the parts pricing and inventory
    const uniquePartNumberMap = {};
    const partNumbers = parts
      .filter(p => {
        if (p.oemPartNumber) {
          p.dmsPending = true;
          if (!uniquePartNumberMap[p.oemPartNumber]) {
            uniquePartNumberMap[p.oemPartNumber] = p;
            return true;
          }
        }
        return false;
      })
      .map(p => {
        return {
          partNumber: p.oemPartNumber,
          manufacturerCode: p.dtDmsPartCode
        };
      });

    const data = {
      appSource,
      operationId,
      operationSource,
      vin,
      payType,
      serviceType,
      partNumbers
    };

    serviceApiCallback(
      data,
      response => {
        const { success, statusCode } = response;
        if (!success || statusCode !== 200) {
          // const { errors } = this.state;
          // errors.operations = "Internal error occurred.";
          // this.setState({
          //   errors: { ...errors }
          // });
          this.resetDmsPending(parts);
        } else {
          const dmsPartsMap = response.parts
            .filter(p => !p.code)
            .reduce((map, obj) => {
              map[obj.partNumber] = obj;
              return map;
            }, {});
          let dmsPriceSet = false;
          parts.forEach(p => {
            if (p.oemPartNumber) {
              p.dmsPending = false;
              const dmsPart = dmsPartsMap[p.oemPartNumber];
              if (dmsPart) {
                p.quantityAvailable = dmsPart.quantityAvailable;
                p.dmsPrice = dmsPart.partPrice;
                dmsPriceSet = true;
              }
            }
          });
          // recalculate total price if dmsPrice for parts is set
          if (dmsPriceSet) {
            // this.recalculateTotalPrice();
          }
          // refresh parts grid
          this.refreshGrid();
        }
      },
      error => {
        // let { message } = error;
        const { response } = error;
        if (!response) {
          // const { errors } = this.state;
          // errors.operations = error.message;
          // this.setState({
          //   showFilter: true,
          //   errors: { ...errors }
          // });
        } else {
          // const { success, message } = response.data;
          // if (!success && message) {
          //   const { errors } = this.state;
          //   errors.dmsPartsPricingAndInventory = message;
          //   this.setState({
          //     errors: { ...errors }
          //   });
          // }
        }
        this.resetDmsPending(parts);
        this.refreshGrid();
      }
    );
  }
  resetDmsPending(parts) {
    parts.forEach(p => {
      if (p.oemPartNumber) {
        p.dmsPending = false;
      }
    });
  }

  clickOnRemoveButton = params => {
    this.setState({ showDeleteModal: true, deleteParams: params });
  };
  removePartRow = part => {
    console.log("remove part row", part);
    const res = this.gridApi.applyTransaction({
      remove: [part]
    });
    console.log(res);
  };

  render() {
    const { allowAutoHeight, maxRows, noRowMessage } = this.props;
    const { rowData, naNrNssParts } = this.state;
    const gridClassname =
      allowAutoHeight && rowData.length < maxRows
        ? "ag-grid-container ops-auto-height no-striped-grid ops-scroll-x-hidden ag-theme-balham"
        : "ag-grid-container no-striped-grid ops-scroll-x-hidden ag-theme-balham";

    const maxHeight = 28 * maxRows + 35;
    const gridStyle =
      allowAutoHeight && rowData.length < maxRows
        ? {}
        : { height: `${maxHeight}px` };
    const pageTitle = this.renderPageTitle(rowData);
    const naNrNssPartsIcon = this.renderNaNrNssIconWithPopover(naNrNssParts);
    const header = this.props.showHeader ? (
      <h4 style={{ lineHeight: "0px" }}>
        {pageTitle}&nbsp;{naNrNssPartsIcon}
      </h4>
    ) : (
      ""
    );

    const deleteConfirmationModal = (
      <Confirmation
        htmlId="deleteRowConfirmationDialog"
        message="You are about to delete a part. Are you sure you want to proceed?"
        proceedButtonStyle="danger"
        show={this.state.showDeleteModal}
        actionFunction={() => {
          console.log(this.state.deleteParams.data);
          this.setState({ showDeleteModal: false });
          this.removePartRow(this.state.deleteParams.data);
          this.props.onRemoveRow(this.state.deleteParams.data);
        }}
        closeDialog={() => {
          this.setState({ showDeleteModal: false });
        }}
      />
    );
    return (
      <React.Fragment>
        {header}
        <div id="partsGrid" className={gridClassname} style={gridStyle}>
          <AgGridReact
            columnDefs={this.state.columnDefs}
            defaultColDef={this.state.defaultColDef}
            suppressRowClickSelection={true}
            suppressMenuHide={false}
            suppressContextMenu={true}
            getRowNodeId={this.getRowNodeId}
            rowData={rowData}
            rowSelection={this.state.rowSelection}
            rowDeselection={true}
            singleClickEdit={true}
            stopEditingWhenCellsLoseFocus={true}
            animateRows={true}
            statusBar={this.state.statusBar}
            components={this.state.components}
            multiSortKey={this.state.multiSortKey}
            enableRangeSelection={false}
            enableCellTextSelection={true}
            enableBrowserTooltips={true}
            onCellValueChanged={this.onCellValueChanged}
            onFilterChanged={this.onFilterChanged}
            onGridReady={this.onGridReady}
            onGridSizeChanged={this.handleGridSizeChanged}
            onSelectionChanged={this.handleSelectionChanged}
            frameworkComponents={this.state.frameworkComponents}
            overlayNoRowsTemplate={noRowMessage}
            loadingOverlayComponent={this.state.loadingOverlayComponent}
            loadingOverlayComponentParams={
              this.state.loadingOverlayComponentParams
            }
            isRowSelectable={this.state.isRowSelectable}
            sideBar={this.state.sideBar}
            columnTypes={this.state.columnTypes}
            domLayout={this.state.domLayout}
            // popupParent={this.state.popupParent}
          />
        </div>
        {deleteConfirmationModal}
      </React.Fragment>
    );
  }
}
export default PartsGridExt;

PartsGridExt.propTypes = {
  showCheckboxes: PropTypes.bool,
  showNotes: PropTypes.bool, // hide/show notes column
  showRemove: PropTypes.bool,
  showPartRelationship: PropTypes.bool,
  showPartId: PropTypes.bool,
  allowDescriptionEdit: PropTypes.bool,
  allowPriceEdit: PropTypes.bool,
  allowPartRelationshipEdit: PropTypes.bool,
  partsGridLabel: PropTypes.string,
  disableQtyEdit: PropTypes.bool,
  disableAllParts: PropTypes.bool, // need to set it to true for dealer published parts
  allowAutoHeight: PropTypes.bool, // allow auto height of grid
  maxRows: PropTypes.number, // max # of rows visible in the grid
  showHeader: PropTypes.bool, // show the parts title
  showTotal: PropTypes.bool, // show total part price
  noRowMessage: PropTypes.string, // show the custom message if there is no parts in the grid
  parts: PropTypes.array, // all the parts (if you have undecoded vehicle attributes)
  partsOverridePrice: PropTypes.number, // service level parts oveerride price
  naNrNssParts: PropTypes.array, // showing the not supported part numbers
  selectedVehicleAttributeMap: PropTypes.object, // selected vehicle attribute map (key is the vehicle attribute name without spaces, value is the selected vehicle attribute value)
  fluidHasQualifiers: PropTypes.bool,
  onModified: PropTypes.func,
  onSelectRow: PropTypes.func,
  onRemoveRow: PropTypes.func
};
PartsGridExt.defaultProps = {
  showCheckboxes: true,
  showNotes: true,
  showRemove: false,
  showPartId: false,
  showPartRelationship: true,
  allowDescriptionEdit: false,
  allowPriceEdit: false,
  allowPartRelationshipEdit: false,
  partsGridLabel: "Parts",
  disableAllParts: false,
  disableQtyEdit: false,
  allowAutoHeight: true,
  maxRows: 18,
  showHeader: true,
  showTotal: false,
  noRowMessage: "No records found.",
  parts: [],
  naNrNssParts: [],
  selectedVehicleAttributeMap: {},
  fluidHasQualifiers: false,
  onModified: () => {},
  onSelectRow: () => {},
  onRemoveRow: () => {}
};

// let preselectRowScheduled = false;
const SpecialPartStatus = ["Discontinued", "Superseded"];
const RelationShipMap = {
  Primary: "Primary",
  Recommended: "Recommended",
  "Related/Optional": "Optional"
};

function qtyFormatter(params) {
  if (!params || !params.data) {
    return "";
  }
  const { quantity, unitOfMeasure, partType, motorPartName, motorFluidName } =
    params.data;
  let label = "";
  if (motorPartName || partType === "part") {
    label += toEmptyStringIfUndefined(quantity);
  } else if (motorFluidName || partType === "fluid") {
    if (toEmptyStringIfUndefined(quantity) !== "" && quantity !== "NS") {
      label += quantity + " " + toEmptyStringIfUndefined(unitOfMeasure);
    }
  }
  return label;
}
// eslint-disable-next-line unused-imports/no-unused-vars
function relFormatter(params) {
  if (!params || !params.data) {
    return "";
  }
  if (params.data.relationship === "Related/Optional") {
    return "Optional";
  }
  return params.data.relationship;
}
// return object to tooltipContent but expected string
// eslint-disable-next-line unused-imports/no-unused-vars
function renderNotes(notes) {
  if (notes && Array.isArray(notes) && notes.length !== 0) {
    if (notes.length === 1) {
      return <div>{notes[0]}</div>;
    } else {
      const list = notes.map((note, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <li key={`key${i}`}>{note}</li>
      ));
      return <ul>{list}</ul>;
    }
  }
  return "";
}
function noteCellRenderer(params) {
  if (!params || !params.data) {
    return "";
  }
  const { partId, notes } = params.data;
  let label = "";
  if (notes && Array.isArray(notes)) {
    label = notes.join(",");
  }
  return !label ? (
    ""
  ) : (
    <div className="ops-notes">
      <Tooltip
        htmlId={`id_${partId}`}
        tooltipContent={label}
        className="list-tooltip"
      >
        <IconInfoOutline className="info" />
      </Tooltip>
    </div>
  );
}
function descriptionCellRenderer(params) {
  if (!params || !params.data) {
    return "";
  }
  const { partName, motorPartName, partType, notes, id } = params.data;
  if (motorPartName || partType === "part") {
    // const noteList = notes ? notes.filter((note, i) => i < 2) : [];
    const noteList = notes ? notes : [];
    const badges = noteList.slice(0, 2).map((note, i) => (
      // eslint-disable-next-line react/no-array-index-key
      <Badge htmlId={"noteBadge_" + i} color="gray" key={"key_" + i}>
        {note}
      </Badge>
    ));
    const tooltipList = noteList.slice(2);
    const label = renderNotes(tooltipList);
    if (noteList.length > 2) {
      return (
        <div className="ops-part-description">
          <span className="ops-description">{partName}</span>
          {badges}
          <Tooltip
            htmlId={`id_${id}`}
            tooltipContent={label}
            className="list-tooltip"
          >
            <IconInfoOutline className="info" />
          </Tooltip>
        </div>
      );
    } else {
      return (
        <div className="ops-part-description">
          <span className="ops-description">{partName}</span>
          {badges}
        </div>
      );
    }
  }
  return !partName ? "" : partName;
}

function quantityCellRenderer(params) {
  if (!params || !params.data) {
    return "";
  }
  if (params.data.dmsPending) {
    return (
      <LoadingIndicator
        htmlId="priceLoadingIndicator"
        size="small"
        color="gray"
      />
    );
  }
  const label = qtyFormatter(params);
  const { id, quantityAvailable } = params.data;
  const keyId = "qty_" + id;
  // const tooltipText = `Quantiy on Hand: ${toEmptyStringIfUndefined(
  //   quantityOnHand
  // )}`;
  //   <span className="badge-count">
  //   {toNumberZeroIfUndefined(quantityOnHand)}
  // </span>

  const qtySpan = !isNaN(quantityAvailable) ? (
    <span className="badge-dms">{quantityAvailable}</span>
  ) : (
    <span className="badge-msrp">0</span>
  );

  const tooltipText = "Quantity Available";
  return (
    <div>
      {toEmptyStringIfUndefined(label)}{" "}
      <span className="qty-separator">&nbsp;/&nbsp;</span>
      <Tooltip htmlId={keyId} tooltipContent={tooltipText}>
        {qtySpan}
      </Tooltip>
    </div>
  );
}

function renderPartManufacturer(params) {
  const { partMake } = params.data;
  if (!partMake) {
    return "";
  }
  const partMakeDiv = (
    <div className="cx-badge-flex">
      <span className="badge-part-make">{partMake}</span>
    </div>
  );
  return partMakeDiv;
}

function partNumberCellRenderer(params) {
  if (!params || !params.data) {
    return "";
  }
  // const oemPartNumber = params.value;
  const { rowId, oemPartNumber, status, alternateParts } = params.data;
  const id = rowId;
  let lookupIcon = "";

  const partManufacturerCode = renderPartManufacturer(params);

  if (alternateParts && alternateParts.length !== 0) {
    const popoverContent = renderSupersededParts(params, alternateParts);
    const keyId = `supersession_${id}`;
    lookupIcon = (
      <div id={`id_${id}`}>
        <Tooltip
          htmlId={keyId}
          tooltipContent="Click to show previous superseded part numbers."
        >
          <span className="parts-instock">
            <Popover
              htmlId="supersededPartsId"
              trigger={["click", "outsideClick"]}
              className="ops-superseded-parts-popover"
              popoverContent={popoverContent}
              position="top"
            >
              <i
                style={{ width: "24px", textAlign: "center" }}
                className="fa fa-history"
              />
            </Popover>
          </span>
        </Tooltip>
      </div>
    );
  }
  if (status && SpecialPartStatus.includes(status)) {
    const keyId = `status_alert_${id}`;
    const specialPartNumber = (
      <div className="ops-special-part-number ops-gridext">
        {" "}
        {oemPartNumber}
        <Tooltip htmlId={keyId} tooltipContent={status}>
          <IconWarning className="ops-part-number-warn" />
        </Tooltip>
        {lookupIcon}
      </div>
    );
    // console.log(specialPartNumber);
    return specialPartNumber;
  } else {
    const specialPartNumber = (
      <div className="ops-special-part-number ops-gridext">
        {" "}
        {oemPartNumber}
        {partManufacturerCode}
        {lookupIcon}
      </div>
    );
    // console.log(specialPartNumber);
    return specialPartNumber;
  }
}

function priceCellRenderer(params) {
  if (!params || !params.data) {
    return "";
  }
  const { dmsPending, dmsPrice, priceSource, unitPrice, unitPriceOverridden } =
    params.data;
  if (dmsPending) {
    return (
      <LoadingIndicator
        htmlId="priceLoadingIndicator"
        size="small"
        color="gray"
      />
    );
  }
  const priceType = unitPriceOverridden
    ? "MANUAL"
    : dmsPrice || (priceSource && priceSource.indexOf("DMS") !== -1)
    ? "DMS"
    : "MSRP";
  const pricePerItem = dmsPrice ? dmsPrice : unitPrice;
  const priceTypeSpan =
    priceType === "DMS" ? (
      <span className="badge-dms">{priceType}</span>
    ) : (
      <span className="badge-msrp">{priceType}</span>
    );
  return (
    <div className="cx-badge-flex">
      {priceFormatter(pricePerItem)}
      {priceTypeSpan}
    </div>
  );
}

function renderSupersededParts(params, parts) {
  const { api, data } = params;
  const { id, unitPrice } = data;
  const htmlId = `supersededPartsTable_${id}`;
  return (
    <SimpleTable htmlId={htmlId} striped={false}>
      <thead>
        <tr>
          <th>Supersession History</th>
        </tr>
        <tr>
          <th>Select a previous part number to use it instead</th>
        </tr>
        <tr>
          <th scope="col">Part number</th>
          <th scope="col">Status</th>
          <th scope="col">MSRP/Item</th>
          <th scope="col">DMS Price</th>
          <th scope="col">Qty on Hand</th>
        </tr>
      </thead>
      <tbody>
        {parts.map((d, index) => {
          const { oemPartNumber, oePartNumber, status } = d;
          const key = `key_${index}`;
          return (
            <tr
              className="parts-supersession-row"
              key={key}
              onClick={() => {
                // console.log("row", index);
                data.oemPartNumber =
                  parts[index].oemPartNumber || parts[index].oePartNumber;
                data.status = parts[index].status;
                api.refreshCells({ force: true });
                const divEl = document.querySelector(`#id_${id}`);
                if (divEl) {
                  divEl.click();
                }
              }}
            >
              <th scope="row">{oemPartNumber || oePartNumber}</th>
              <td>{status}</td>
              <td>{unitPrice}</td>
              <td>-</td>
              <td>0</td>
            </tr>
          );
        })}
      </tbody>
    </SimpleTable>
  );
}

function filterPartsBySelectedVehicleAttributeMap(
  parts,
  selectedVehicleAttributeMap
) {
  if (parts) {
    parts.forEach(part => {
      const { alternateParts } = part;
      if (alternateParts && alternateParts.length) {
        if (part.oemPartNumber) {
          alternateParts.splice(0, 0, Object.assign({}, part));
        }
      }
    });
  }
  if (
    !parts ||
    parts.length === 0 ||
    !selectedVehicleAttributeMap ||
    Object.keys(selectedVehicleAttributeMap).length === 0
  ) {
    return { fluidsAndParts: parts, additionalColumns: [] };
  }

  const additionalColumns = getAdditionalColumns(
    parts,
    selectedVehicleAttributeMap
  );

  const filteredParts = parts.filter(part => {
    const { vehicleAttributes } = part;
    if (!vehicleAttributes) {
      return true;
    }
    const vehAttrKeys = Object.keys(vehicleAttributes);
    for (let i = 0; i < vehAttrKeys.length; i++) {
      const key = vehAttrKeys[i];
      // part[key] = vehicleAttributes[key];
      if (
        selectedVehicleAttributeMap[key] !== "idk" &&
        selectedVehicleAttributeMap[key] !== vehicleAttributes[key]
      ) {
        return false;
      }
    }
    return true;
  });
  return { fluidsAndParts: filteredParts, additionalColumns };
}

function getAdditionalColumns(parts, selectedVehicleAttributeMap) {
  const vehicleAttributeNames = [];
  parts.forEach(part => {
    const { vehicleAttributes } = part;
    if (vehicleAttributes) {
      const vehAttrKeys = Object.keys(vehicleAttributes);
      for (let i = 0; i < vehAttrKeys.length; i++) {
        const key = vehAttrKeys[i];
        if (
          !vehicleAttributeNames.includes(key) &&
          selectedVehicleAttributeMap[key]
        ) {
          vehicleAttributeNames.push(key);
        }
        part[key] = vehicleAttributes[key];
      }
    }
  });
  const additionalColumns = vehicleAttributeNames.map(v => {
    const field = v;
    const headerName = v;
    return { field, headerName };
  });
  return additionalColumns;
}

function removeCellRenderer(params) {
  if (!params || !params.data) {
    return "";
  }
  return (
    <div
      className="row-remove"
      onClick={() => {
        const { parentHandle } = params;
        parentHandle.clickOnRemoveButton(params);
      }}
    >
      <IconDelete htmlId="iconDelete" className="remove" />
    </div>
  );

  // return (
  //   <div>
  //     <RemoveRowButton
  //       removeRow={e => {
  //         const { parentHandle } = params;
  //         parentHandle.removePartRow(params.data);
  //         parentHandle.props.onRemoveRow(params.data);
  //       }}
  //     />
  //   </div>
  // );
}

/* eslint-enable no-console */
