import cloneDeep from "lodash/cloneDeep";
import has from "lodash/has";
import {
  convertHoursToMinutes,
  convertMinutesToHours
} from "../page-wrapper/utils/quote-util";
import { QuoteServiceTypes } from "../page-wrapper/constants/page-wrapper.constants";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import isNumber from "lodash/isNumber";
import isNull from "lodash/isNull";
import { priceSourceLabels } from "../../PartsLookupModule/constants/parts.constants";
import { filterPartsBySelected } from "../../ServiceSearchModule/utils/service.util";
import { payTypeCodes } from "../../constants/quote-status.constants";

// @note - Transform parts object for Menu service, return parts datamodel same like global operation details API datamodel
const prepareMenuServiceParts = parts => {
  const partsWithPartId = parts.map(part => {
    return {
      ...part,
      // fix - read quoteServicePartId from existing part
      quoteServicePartId: get(part, "quoteServicePartId", null),
      // @todo-poc: add rowId field to support ag-grid getRowNodeId() binded with rowId for poc.
      // @note: partId is a string used for filter logic accross parts grid.
      ...(has(part, "id") && { partId: part.id.toString() }),
      ...(!has(part, "id") &&
        has(part, "partId") && { partId: part.partId.toString() }),
      // @note: rowId is a number.
      ...(has(part, "id") && { rowId: Number(part.id) }),
      ...(!has(part, "id") &&
        has(part, "partId") && { rowId: Number(part.partId) }),
      ...(has(part, "description") ? { partName: part.description } : ""),
      ...(!has(part, "description") && has(part, "partName")
        ? { description: part.partName }
        : ""),
      quantity: has(part, "adjustedQuantity") ? part.adjustedQuantity : 0,
      unitPrice: has(part, "unitPrice") ? part.unitPrice : 0,
      oemPartNumber: has(part, "oemPartNumber") ? part.oemPartNumber : "",
      oilType: has(part, "oilType") ? part.oilType : "",
      unitOfMeasure: has(part, "adjustedUom") ? part.adjustedUom : "",
      // @workaround: copy {manufacturerCode} if returned in catalog menus API to field {dtDmsPartCode}
      // DMS opentrack API payload expecting this field {dtDmsPartCode} to return DMS parts
      dtDmsPartCode: get(
        part,
        "dtDmsPartCode",
        get(part, "manufacturerCode", null)
      ),
      priceSource:
        has(part, "priceSource") && part.priceSource
          ? part.priceSource
          : priceSourceLabels.MSRP,
      ...(has(part, "partPriceSource") && {
        partPriceSource: part.partPriceSource
      }),
      position: "",
      notes: [],
      relationship: null,
      qualifiers: null,
      dmsPending: false,
      // fix - parts added under quote will be pre-selected, if not added - selected should be false
      selected: !has(part, "selected") ? false : part.selected
    };
  });
  return partsWithPartId;
};

// @note: change - price overrides fields are read from menu catalog API
const editModulePropsFromMenuService = (menuService, originalService) => {
  console.log(
    ">> Summary edit menu service data mapper",
    menuService,
    originalService
  );
  // @menu: these fields not returned in API - laborAppId, asrNotes, commentsRequired, defaultLaborRate; hence set default values
  const menuServiceObject = {
    name: menuService?.name || "",
    totalPrice: menuService?.price || 0,
    laborAppId: null, // always null for menu
    labor: {
      serviceId: menuService?.id || null,
      price: menuService?.scheduledLaborPrice || 0,
      notes: "",
      time: convertMinutesToHours(get(menuService, "durationMins", 0)),
      laborRate: get(menuService, "laborRate", null)
    },
    asrNotes: "", // this value not exist for menu
    commentsRequired: false, // always false
    // always set defaultLaborRate with 0; API doesn't return this value
    defaultLaborRate: 0,
    dealerLaborRateId: get(originalService, "dealerLaborRateId", null),
    cause: menuService?.cause || null,
    complaint: menuService?.complaint || null,
    correction: menuService?.correction || null,
    defaultTaxCode: menuService?.defaultTaxCode || null,
    opCode: has(menuService, "dmsOpcode")
      ? formatOpcode(menuService.dmsOpcode)
      : "",
    subTypeId: menuService?.subTypeId,
    allocationSubTypeId: menuService?.allocationSubTypeId,
    internalAccount: menuService?.internalAccount,
    description: menuService?.description || "",
    operationSource: QuoteServiceTypes.MENU,
    // Within the ui, we expect dealershipNotes as string by design
    dealershipNotes: menuService?.dealershipNotes || "",
    // check if menuService has payTypeCode field, api returns defaultPayTypeCode at menu level only
    defaultPayTypeCode: menuService?.payTypeCode || "",
    payTypeGroup: menuService?.payTypeGroup || "",
    payTypeCode: menuService?.payTypeCode || "",
    serviceContract: menuService?.serviceContract || null,
    completedTime: menuService?.completedTime || null,
    paymentStatus: menuService?.paymentStatus || null,
    warranty: menuService?.warranty || null,
    payTypeDescription: menuService?.payTypeDescription || "",
    laborTaxCode: menuService?.laborTaxCode || null,
    partsPrice: menuService?.partsPrice || 0,
    parts: has(menuService, "part")
      ? prepareMenuServiceParts(menuService.part)
      : [],
    // fix: read parts from catalog menu service which are not added to quote menu service
    originalParts: has(originalService, "originalMenuServiceParts")
      ? prepareMenuServiceParts(originalService.originalMenuServiceParts)
      : [],
    // @note: read calculated, final price, override fields from menu service updated before passed to edit menu service page
    totalPriceOverridden: menuService?.totalPriceOverridden || false,
    partsPriceOverridden: menuService?.partsPriceOverridden || false,
    laborPriceOverridden: menuService?.laborPriceOverridden || false,
    // Expected values for below price overrides {0,null,floating}
    totalPriceOverride: menuService.totalPriceOverridden
      ? menuService?.price || null
      : null,
    totalLaborPriceOverride: isNumber(menuService?.totalLaborPriceOverride)
      ? menuService?.totalLaborPriceOverride
      : null,
    totalPartsPriceOverride: isNumber(menuService?.totalPartsPriceOverride)
      ? menuService?.totalPartsPriceOverride
      : null,
    calculatedLaborPrice: menuService?.calculatedLaborPrice || 0,
    calculatedTotalPartsPrice: menuService?.calculatedTotalPartsPrice || 0,
    calculatedServicePrice: menuService?.calculatedServicePrice || 0,
    finalLaborPrice: menuService?.finalLaborPrice || 0,
    // @change - use finalPartsPrice updated from  price utils - either override or calculated price
    finalPartsPrice: menuService?.finalPartsPrice || 0,
    serviceMenuId: menuService?.serviceMenuId || null,
    technicians: menuService?.technicians || null
  };
  console.log(
    "%c editModulePropsFromMenuService",
    "color: orange;",
    menuServiceObject
  );
  return menuServiceObject;
};
// @todo-poc: transform menu service into global operation details API response data model including service, parts as well
const updateRawOperationFromMenuService = service => {
  const newService = cloneDeep(service);
  delete newService.part;
  newService.displayName = service?.name || "";
  newService.laborAppId = null; // always null for menus
  newService.opCode = service?.dmsOpcode || "";
  newService.subTypeId = service?.subTypeId;
  newService.allocationSubTypeId = service?.allocationSubTypeId;
  newService.notes = null; // always null for menu
  newService.laborPrice = service?.scheduledLaborPrice || 0;
  newService.laborHours = has(service, "durationMins")
    ? convertMinutesToHours(service.durationMins)
    : 0;
  newService.dealerLaborRateId = get(service, "dealerLaborRateId", null);
  newService.totalPrice = service?.price || 0;
  newService.partsPrice = service?.partsPrice || 0;
  newService.laborPriceOverridden = service?.laborPriceOverridden || false;
  newService.partsPriceOverridden = service?.partsPriceOverridden || false;
  newService.totalPriceOverridden = service?.totalPriceOverridden || false;
  newService.dealerCode = service?.dealerCode || "";
  newService.payTypeGroup = service?.payTypeGroup || "";
  newService.payTypeCode = service?.payTypeCode || "";
  newService.payTypeDescription = service?.payTypeDescription || "";
  newService.laborTaxCode = service?.laborTaxCode || null;
  newService.operationSource = QuoteServiceTypes.MENU;
  // fix: read parts from catalog menu service which are not added to quote menu service
  newService.parts = has(service, "originalMenuServiceParts")
    ? prepareMenuServiceParts(service.originalMenuServiceParts)
    : [];
  const laborApps = [];
  laborApps.push(newService);

  const rawObject = {
    laborApps,
    abbreviation: null,
    catalogSource: QuoteServiceTypes.MENU,
    operationSource: QuoteServiceTypes.MENU,
    commentsRequired: false, // Always false for Menu case
    dealerCode: "",
    // @note: MENU, defaultLaborRate never returned from catalog API
    defaultLaborRate: 0,
    description: null,
    enabled: null,
    extId: null,
    laborTimeMarkupPercent: null,
    loanerAllowed: null,
    make: "",
    operationId: service.id,
    parentId: null,
    mandatoryInAppt: null,
    notApplicableParts: [],
    selectableVehicleAttributes: [],
    serviceKind: null,
    serviceCategoryId: service?.serviceCategoryId || null,
    serviceCategoryName: service?.serviceCategoryName || "",
    vehicleAttributes: null,
    variant: null,
    // @note: payType values are updated for selectedMenu and fowarded to each menuService when paytypes API is ready
    payTypeGroup: service?.payTypeGroup || "",
    payTypeCode: service?.payTypeCode || "",
    payTypeDescription: service?.payTypeDescription || "",
    laborTaxCode: service?.laborTaxCode || null
  };
  return rawObject;
};

/**
 *
 * @param {Object} menuAPIService - passed service object of catalog menu API of menu case;
 * @returns {string} description for a service
 */
const getDescriptionFromApi = menuAPIService => {
  return get(menuAPIService, "description", "");
};

// @params{editStateService} - service field changes saved in edit context
// @params{selectedMenuService} - menuService selected from Review menu page
const updateMenuService = (selectedMenuService, editStateService) => {
  const newService = cloneDeep(selectedMenuService);
  newService.serviceMenuId = get(selectedMenuService, "serviceMenuId", null);
  const serviceDescription = getDescriptionFromApi(selectedMenuService);
  newService.serviceDescription = serviceDescription;
  newService.price = editStateService.totalPrice;
  newService.scheduledLaborPrice = editStateService.calculatedLaborPrice;
  newService.durationMins = convertHoursToMinutes(editStateService.labor.time);
  newService.laborRate = editStateService.labor.laborRate;
  newService.dmsOpcode = editStateService.opCode;
  newService.subTypeId = editStateService?.subTypeId || null;
  newService.allocationSubTypeId =
    editStateService?.allocationSubTypeId || null;
  // Within the ui, we expect dealershipNotes as string by design
  newService.dealershipNotes = editStateService?.dealershipNotes || "";
  newService.part = prepareMenuServiceParts(editStateService.parts);
  newService.parts = filterPartsBySelected(
    prepareMenuServiceParts(editStateService.parts)
  );
  // TBD - this field to be verify whether to pass in wrapper to quote payload util method
  newService.calculatedLaborPrice = editStateService.calculatedLaborPrice;
  newService.calculatedTotalPartsPrice =
    editStateService.calculatedTotalPartsPrice;
  newService.calculatedServicePrice = editStateService.calculatedServicePrice;
  // Fix - From UI, override allowed on Labor price, hence set labor override fields if exist from edit service page
  newService.laborPriceOverridden = !isNull(
    editStateService?.totalLaborPriceOverride
  );
  newService.totalLaborPriceOverride =
    editStateService?.totalLaborPriceOverride || null;
  newService.totalPriceOverride = editStateService?.totalPriceOverride || null;
  newService.totalPartsPriceOverride =
    editStateService?.totalPartsPriceOverride || null;
  newService.finalLaborPrice = editStateService.finalLaborPrice;
  // @note:  NEVER use or update partsPrice - This will hold catalog API original parts price
  // @note - Use finalPartsPrice updated from  price utils - either parts override or calculated price
  newService.finalPartsPrice = editStateService.finalPartsPrice;
  newService.dealerCode = editStateService.dealerCode;
  newService.payTypeGroup = editStateService?.payTypeGroup || "";
  newService.payTypeCode = editStateService?.payTypeCode || "";
  newService.payTypeDescription = editStateService?.payTypeDescription || "";
  if (editStateService?.payTypeCode === payTypeCodes.SERVICE_CONTRACT) {
    newService.serviceContract = editStateService?.serviceContract || null;
  }
  newService.laborTaxCode = editStateService?.laborTaxCode || null;
  newService.dealerLaborRateId = get(
    selectedMenuService,
    "dealerLaborRateId",
    null
  );
  // csr fields
  newService.cause = editStateService?.cause || "";
  newService.complaint = editStateService?.complaint || "";
  newService.correction = editStateService?.correction || "";
  newService.technicians = editStateService?.technicians || [];
  newService.completedTime = editStateService?.completedTime || null;
  newService.paymentStatus = editStateService?.paymentStatus || "notReady";
  newService.warranty = editStateService?.warrant || null;
  return {
    ...newService
  };
};

// util returns first word after spliting opcode string
const formatOpcode = opCode => {
  let opCodeSelected = "";
  if (opCode) {
    const opCodes = !isEmpty(opCode) ? opCode.split(";") : [];
    opCodeSelected = !isEmpty(opCodes) ? opCodes[0] : opCode;
  }
  return opCodeSelected;
};

export default {
  editModulePropsFromMenuService,
  updateMenuService,
  updateRawOperationFromMenuService
};
