// Fake Promise
export const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

/**
 * This util used to convert query strings to readable json object
 * eg: http://test.com/?simple1=nospace&not_simple=with%20spaces%20and%20characters
 * */
export function queryStringToJSON() {
  const result = {};
  const search = window.location.search;
  if (search) {
    const pairs = window.location.search.slice(1).split("&");
    pairs.forEach(function (pair) {
      const pairArr = pair.split("=");
      result[pairArr[0]] = decodeURIComponent(pairArr[1] || "");
    });
    return JSON.parse(JSON.stringify(result));
  } else {
    return null;
  }
}

/**
 * Util convert json object to query string
 *  @param {Object} params The object to encode
 *  @return {String} queryString
 * input: 
 * var params = {
    a: 1,
    b: 2,
    c: 3
    }; 
  output: "a=1&b=2&c=3"
 * */

export function toQueryString(params) {
  const queryString = Object.keys(params)
    .map(key => key + "=" + params[key])
    .join("&");
  return queryString;
}
