import Button from "@cx/ui/Button";
import IconDelete from "@cx/ui/Icons/IconDelete";
import PriceInput from "@cx/ui/PriceInput";
import SelectInput from "@cx/ui/SelectInput";
import React, { useContext, useState } from "react";
import { AppContext } from "../../../../../state/app-context";
import format from "../../../utils/format";
import PaymentNotes from "./payment-notes.component";

const DealerPolicyPaymentComponent = props => {
  const { paymentLine, removePaymentLine, updateSelectedPaymentLines } = props;
  const [currentPaymentLine, setCurrentPaymentLine] = useState(paymentLine);
  const appContext = useContext(AppContext);
  const dealerPolicyAccounts =
    appContext?.dealerProperties?.DMSP_CREDIT_ACCOUNTS;

  const onInputChange = cxEvent => {
    setCurrentPaymentLine({
      ...paymentLine,
      [cxEvent.target.name]: cxEvent.target.value
    });
    updateSelectedPaymentLines({
      ...paymentLine,
      [cxEvent.target.name]: cxEvent.target.value
    });
  };

  const generateDealerPolicyOptions = dealerPolicyAccounts => {
    const accountNameOptions = [
      { value: "Select", label: "Select", disabled: true }
    ];
    const dealerPolicyAccountArr = dealerPolicyAccounts?.split("|");
    dealerPolicyAccountArr?.map(item => {
      accountNameOptions.push({
        value: format.toPascalCase(item),
        label: format.toPascalCase(item)
      });
    });
    return accountNameOptions;
  };

  return (
    <div className="dealer-wrapper">
      <div className="dealer-title">Dealer Policy</div>
      <div className="dealer-payment-fields">
        <SelectInput
          htmlId="accountName"
          name="internalAccount"
          label="Internal account"
          options={generateDealerPolicyOptions(dealerPolicyAccounts)}
          value={currentPaymentLine?.internalAccount || ""}
          displayDeselectOption={false}
          onChange={onInputChange}
          className="input-field dealer-account-name-input"
          autoFocus={true}
          required
          aria-label="accountSelectInput"
          autoComplete="off"
        />
        <PriceInput
          htmlId="dealerAmount"
          name="paymentAmount"
          label="Amount"
          inputPrefix="$"
          className="input-field dealer-amount-input"
          onChange={onInputChange}
          value={
            currentPaymentLine?.paymentAmount
              ? String(currentPaymentLine?.paymentAmount)
              : ""
          }
          required
          minValue={0.01}
          autoComplete="off"
        />
        <Button
          aria-label="dealer delete button"
          className="dealer-delete-button"
          htmlId="dealerDeleteIcon"
          icon={<IconDelete className="icon-delete-svg" />}
          type="button"
          onClick={() => {
            removePaymentLine(currentPaymentLine);
          }}
        />
      </div>
      <PaymentNotes
        onPaymentNoteChange={onInputChange}
        currentPaymentLine={currentPaymentLine}
      />
    </div>
  );
};

export default DealerPolicyPaymentComponent;
