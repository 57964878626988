/** Declare constants commonly used in application level config */
export const DEFAULT_LOCALE = "en_US";
export const DEFAULT_APPSOURCE = "IND";
export const DENIED = "DENIED";
export const NOT_AUTHORIZED = "NOT_AUTHORIZED";
export const FULL_ACCESS = "FULL_ACCESS";
export const ACCESS_DEALER_PAGE = "DEALER_SELECTOR";
export const ACCESS_ERROR = "ERROR";
export const APPLICATIONS = {
  QUOTING: "quoting",
  CSR: "csr"
};
export const appTypes = { SQ: "SQ", CSR: "CSR" };
export const appSources = { IND: "IND", SCH: "SCH", ENG: "ENG" };
export const appSourceCodes = ["IND", "SCH", "ENG", "INS", "DTD"];
export const roStatusOptions = {
  WITH_ADVISOR: "WITH_ADVISOR",
  IN_PROCESS: "IN_PROCESS",
  WORK_FINISHED: "WORK_FINISHED",
  PRE_INVOICE: "PRE_INVOICE",
  FINALIZED: "FINALIZED"
};
export const partStatus = {
  APPROVED: "Approved",
  REQUESTED: "Requested"
};

export const technicianStatus = {
  NOT_STARTED: "Not started",
  WORKING: "Time started",
  STOP: "Time ended",
  HOLD_PARTS: "Parts hold",
  HOLD_APPROVAL: "Approval hold",
  BREAK: "Break"
};
