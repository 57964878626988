/* eslint-disable no-async-promise-executor */
import axiosService, { showApiFailureMessage } from "../../../api/xmmAxios";
import has from "lodash/has";
import isEmpty from "lodash/isEmpty";
// import { getIsCorePart } from "../../../../../../PartsLookupModule/utils/helper.util";
import {
  convertPricingAndInventoryAPIData,
  resetDmsPending
} from "../../../utils/common-parts.util";
import { getPayloadForCustomPart } from "../utils/parts.util";
// @note: read all operations
const getGlobalOperations = async vehicle => {
  return new Promise(async (resolve, reject) => {
    const { make, metaVehicleId } = vehicle;
    const axios = axiosService.getInstance();
    const vinParam =
      has(vehicle, "vin") && !isEmpty(vehicle.vin)
        ? vehicle.vin.toString()
        : "UNKNOWN";
    try {
      const response = await axios.get(
        `quote/services/globalOperations/{dealerCode}/make/${make}`,
        {
          params: {
            vin: vinParam,
            metaVehicleId
          }
        }
      );
      resolve(response.data || []);
    } catch (error) {
      showApiFailureMessage(
        "Unable to retrieve list of available services.",
        error
      );
      reject(error);
    }
  });
};

// @note: read operation details
const getGlobalOperationDetails = async (vehicle, params) => {
  return new Promise(async (resolve, reject) => {
    const { make, metaVehicleId } = vehicle;
    const { operationSource, operationId } = params;
    const axios = axiosService.getInstance();
    // TODO: Special case - when vin missing for top services via global catalog services, send VIN as UNKNOWN in payload
    const vinParam =
      has(vehicle, "vin") && !isEmpty(vehicle.vin)
        ? vehicle.vin.toString()
        : "UNKNOWN";
    try {
      const response = await axios.get(
        `quote/services/globalOperations/{dealerCode}/make/${make}/operationSource/${operationSource}/${operationId}`,
        {
          params: {
            vin: vinParam,
            metaVehicleId,
            apiVersion: 2
          }
        }
      );
      resolve(response.data);
    } catch (error) {
      showApiFailureMessage("Unable to retrieve service details.", error);
      reject(error);
    }
  });
};

/**
 * The function `getPartsPricingAndInventory` is an asynchronous function that retrieves pricing and
 * inventory information for parts based on the given operation and parameters.
 * @param operation - The `operation` parameter is used to specify the operation for extracting parts
 * with part numbers. It is an optional parameter and can be empty.
 * @param params - The `params` parameter is an object that contains various properties. The `make`
 * property is used to specify the make of the vehicle. Other properties may include `partNumbers`,
 * which is an array of part numbers, and any other relevant information needed for the API call.
 * @param abortSignal - The `abortSignal` parameter is an optional parameter that can be used to cancel
 * the asynchronous operation. It is an instance of the `AbortSignal` interface, which is used to
 * signal that an asynchronous operation should be aborted. It can be used to cancel the request if
 * needed.
 * @returns The function `getPartsPricingAndInventory` returns a promise that resolves to an array of
 * `dmsParts`.
 */
const getPartsPricingAndInventory = async (operation, params, abortSignal) => {
  return new Promise(async (resolve, reject) => {
    // const { make } = params;
    const axios = axiosService.getInstance();
    const postParams = Object.assign({}, params);
    delete postParams.make;
    let dmsParts = [];
    const restUrl = getPartsInventoryUrl(params);

    try {
      let partsParam = [];
      if (!isEmpty(operation)) {
        partsParam = await extractPartsWithPartNumber(operation);
      } else {
        // extra check
        partsParam = params.partNumbers;
      }

      if (!isEmpty(partsParam)) {
        postParams.partNumbers = partsParam;
        const response = await axios.post(
          restUrl,
          postParams,
          abortSignal ? { signal: abortSignal } : {}
        );
        const { success, statusCode } = response.data;
        if (!success || statusCode !== 200) {
          // API failure case
          resetDmsPending(dmsParts, false);
          resolve([]);
        } else {
          // API success case
          dmsParts = convertPricingAndInventoryAPIData(response, partsParam);
          resolve(dmsParts);
        }
      } else {
        resolve([]);
      }
    } catch (error) {
      showApiFailureMessage(
        "Unable to retrieve parts pricing and inventory.",
        error
      );
      resolve([]);
      reject(error);
    }
  });
};

const getPartsInventoryUrl = params => {
  const { make } = params;
  const { SPI_SQS_ENABLED } = JSON.parse(
    sessionStorage.getItem("dealerProperties")
  );
  let restUrl = `partsLookup/pricingAndInventory/{dealerCode}/make/${make}`;
  // case: DealerProperty > SPI_SQS_ENABLED -> call vdms api
  if (SPI_SQS_ENABLED === "Y") {
    restUrl = `partsLookup/pricingAndInventory/{dealerCode}/make/${make}/?version=v1`;
  }

  return restUrl;
};

const extractPartsWithPartNumber = async operation => {
  const parts = [];
  const { laborApps } = operation;
  if (!isEmpty(laborApps)) {
    laborApps.forEach(op => {
      if (!isEmpty(op.parts)) {
        parts.push(...op.parts);
      }
    });
  }
  // get unique part numbers for getting the parts pricing and inventory
  const uniquePartNumberMap = {};
  const partNumbers = parts
    .filter(p => {
      if (p.oemPartNumber) {
        p.dmsPending = true; // This will start loading in parts
        if (!uniquePartNumberMap[p.oemPartNumber]) {
          uniquePartNumberMap[p.oemPartNumber] = p;
          return true;
        }
      }
      return false;
    })
    .map(p => {
      return {
        partNumber: p.oemPartNumber,
        manufacturerCode: p.dtDmsPartCode
      };
    });
  console.log("extractPartsWithPartNumber", partNumbers);
  return partNumbers;
};

const getEmergencyPartsPricing = async (partsData, newQuoteState) => {
  return new Promise(async (resolve, reject) => {
    const axios = axiosService.getInstance();
    const { oemPartNumber, ...restPartsData } = partsData;
    const customer = newQuoteState?.customer;
    const params = {
      ...restPartsData,
      repairOrderNumber: newQuoteState?.quoteSummary?.roNumber,
      customerId: customer?.commonConsumerId || newQuoteState?.customerId,
      customerType: customer?.commonConsumerId ? "CC" : "EXT"
    };
    if (params.payType === "S") {
      params.payerId = newQuoteState?.quoteSummary?.payers[0]?.payerId;
    }
    const url = `/csr/emergencyparts/dealers/id/{dealerCode}/inventory-parts/id/${oemPartNumber}/pricing`;
    try {
      const response = await axios.get(url, {
        params
      });
      const { status } = response;
      if (status !== 200) {
        // API FAIL
        resolve([]);
      } else {
        // API SUCCESS
        const {
          data: { items }
        } = response;
        resolve(items[0]);
      }
    } catch (error) {
      showApiFailureMessage(
        "Unable to retrieve emergency parts pricing.",
        error
      );
      resolve([]);
      reject(error);
    }
  });
};

const getPricingAndInventoryForCustomPart = async (
  service,
  rawOpsDetails,
  vehicle,
  customer,
  newPart
) => {
  const { dmsOpcode, opCode } = rawOpsDetails;
  const opCodeParam = opCode ? opCode : dmsOpcode ?? "";
  const vin = !vehicle ? "" : vehicle.vin;
  const make = !vehicle ? "" : vehicle.make;
  const payTypeCode = !isEmpty(service) ? service.payTypeCode : "";
  const serviceTypeCode = !isEmpty(service) ? service.serviceTypeCode : "";
  const commonConsumerId = customer?.commonConsumerId || "";
  const postParams = getPayloadForCustomPart(
    rawOpsDetails,
    make,
    payTypeCode,
    serviceTypeCode,
    commonConsumerId,
    opCodeParam,
    vin,
    newPart
  );
  return await getPartsPricingAndInventory(null, postParams);
};

export default {
  getGlobalOperations,
  getGlobalOperationDetails,
  getPartsPricingAndInventory,
  getPricingAndInventoryForCustomPart,
  getEmergencyPartsPricing
};
