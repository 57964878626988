import React from "react";
import format from "../../repair-order/utils/format";
import "./checkout-split-payment.scss";
import {
  getFormattedContentForPaymentMethod,
  getFormattedTextForPaymentMethod
} from "../../repair-order/utils/payers.util";
const CheckoutSplitPaymentComponent = ({ payer }) => {
  return payer?.payments?.length > 0 ? (
    <div className="checkout-split-payment-container">
      <div className="hr-border" />
      <span className="checkout-split-customer-pay-text">
        Customer Payments
      </span>
      {payer?.payments?.map(payment => {
        return (
          <div
            key={`${payment?.paymentMethod}${payment?.paymentId}`}
            className="checkout-split-payments-list"
          >
            <span className="checkout-split-payments-list-method">
              {getFormattedTextForPaymentMethod(payment)}
            </span>
            <span className="checkout-split-payments-list-content">
              {getFormattedContentForPaymentMethod(payment)}
            </span>
            <span className="checkout-split-payments-list-notes">
              {payment?.notes}
            </span>
            <span className="checkout-split-payments-list-amount">
              {format.currency(payment?.paymentAmount)}
            </span>
          </div>
        );
      })}
      <div className="hr-border" />
    </div>
  ) : null;
};

export default CheckoutSplitPaymentComponent;
