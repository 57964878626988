import isEmpty from "lodash/isEmpty";

export const sortingMenuServices = menuServices => {
  menuServices.sort((a, b) => {
    if (!isEmpty(a) && !isEmpty(b)) {
      const orderA = !a.order ? 0 : a.order;
      const orderB = !b.order ? 0 : b.order;
      if (orderA && orderB) {
        if (orderA < orderB) {
          return -1;
        }
        if (orderA > orderB) {
          return 1;
        }
      }
    }
    return 0;
  });
  return menuServices;
};
