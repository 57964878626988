const alphabeticalOrder = servicesArray => {
  servicesArray.sort((prevService, nextService) => {
    if (
      prevService.serviceName.toUpperCase() >
      nextService.serviceName.toUpperCase()
    ) {
      return 1;
    }
    if (
      prevService.serviceName.toUpperCase() <
      nextService.serviceName.toUpperCase()
    ) {
      return -1;
    }
    // prevService must be equal to nextService
    return 0;
  });

  return servicesArray;
};

export { alphabeticalOrder };
