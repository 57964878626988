import { getAppEnvironment } from "../../api/app.util";

const openNewWindow = (
  modalRoute,
  vin,
  quoteId,
  roNumber,
  top = 0,
  left = 0,
  width = 1000,
  height = 600,
  title = "",
  extraData = null
) => {
  const hostUrl = window.location.origin + modalRoute;
  const url = new URL(hostUrl);
  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop)
  });
  if (params.userName) {
    url.searchParams.append("userName", params.userName);
  }
  url.searchParams.append("webKey", params.webKey);
  url.searchParams.append("appEnv", getAppEnvironment());
  if (modalRoute === "/servicehistory") url.searchParams.append("vin", vin);
  if (modalRoute === "/servicelog") {
    url.searchParams.append("quoteId", quoteId);
    url.searchParams.append("roNumber", roNumber);
  }
  if (modalRoute === "/archivedeventlog") {
    url.searchParams.append("roNumber", roNumber);
  }
  url.searchParams.append("appType", params.appType ? params.appType : "CSR");

  const newWindow = window.open(
    url.toString(),
    "_blank",
    `toolbar=no,scrollbars=yes,location=no,resizable=yes,top=${top},left=${left},width=${width},height=${height}`
  );

  if (modalRoute === "/archivedeventlog") {
    newWindow.repairOrder = extraData;
  }

  newWindow.addEventListener("load", () => {
    newWindow.document.title = title;
  });
};

export { openNewWindow };
