import _isEqual from "lodash/isEqual";
import util from "../../../utils/util";

/**
 * @param {string} fieldName sublet field name, used in sublet input fields
 * @returns {string} updated field name
 * @description stripSubletPrefix is used to trim the sublet prefixes from fieldName and return the remaining string into camel case
 */
export const stripSubletPrefix = fieldName => {
  if (!fieldName.startsWith("sublet")) return fieldName;
  const strippedSubletPrefix = fieldName?.replace(/^(sublet)/, "");
  return (
    (strippedSubletPrefix &&
      strippedSubletPrefix[0].toLowerCase() + strippedSubletPrefix.slice(1)) ||
    strippedSubletPrefix
  );
};

/**
 * Checks whether a m2 sublet's properties are all valid.
 * @param {object} sublet The sublet to validate.
 * @returns {boolean} `true` if the sublet is valid, otherwise `false`.
 */
export const isValidSplitCostSublet = sublet => {
  if (!sublet?.poPricing) {
    return false;
  }

  const hasValidSplitPricing =
    util.isNumber(sublet.poPricing.laborCost) &&
    util.isNumber(sublet.poPricing.laborPrice) &&
    util.isNumber(sublet.poPricing.partsCost) &&
    util.isNumber(sublet.poPricing.partsPrice) &&
    sublet.poPricing.partsPrice >= sublet.poPricing.partsCost &&
    sublet.poPricing.laborPrice >= sublet.poPricing.laborCost;

  const hasValidPricing =
    util.isNumber(sublet.poPricing.cost) &&
    util.isNumber(sublet.poPricing.price) &&
    sublet.poPricing.price >= sublet.poPricing.cost;

  return !!(
    sublet.vendorNotes?.length &&
    sublet.vendor?.length &&
    (hasValidPricing || hasValidSplitPricing)
  );
};

/**
 * Checks whether the sublet to be saved is valid and if it actually changed before enabling saving
 * @param {object} newSublet The sublet to validate.
 * @param {object} previousSublet The sublet to validate.
 * @returns {boolean} `true` if the new sublet is valid and it is different from the existing sublet
 */
export const canSaveSublet = (newSublet, previousSublet) => {
  const isValidSublet = isValidSplitCostSublet(newSublet);
  if (!previousSublet) {
    return isValidSublet;
  }
  return isValidSublet && !_isEqual(previousSublet, newSublet);
};
