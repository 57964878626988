import React, { createContext, useState, useEffect } from "react";
import eventBus from "../../../../../api/event-bus";
import { xlate } from "../../../../../utils/i18n/locales";
import { showApiFailureMessage } from "../../../../../api/xmmAxios";

// Creating a new context for error management.
const ErrorContext = createContext({});

// Define the APIErrorProvider component that provides an error context for its children.
export const APIErrorProvider = ({ children }) => {
  // State hook for managing an array of error messages, initialized as an empty array.
  const [errorMessages, setErrorMessages] = useState([]);

  // Effect hook to handle API error events.
  useEffect(() => {
    // Function to handle error events.
    const handleErrorEvent = data => {
      if (data?.status) {
        const newMessage = data?.message ?? xlate("sq.errors.api.unkown_error");
        // Update state only if the new message is not already in the array.
        setErrorMessages(prevMessages => {
          if (!prevMessages.includes(newMessage)) {
            return [...prevMessages, newMessage];
          }
          return prevMessages; // Return existing messages if new message is a duplicate.
        });
      }
    };

    // Registering the error handling function on the event bus for 'api-error' events.
    eventBus.on("api-error", handleErrorEvent);

    // Cleanup function to remove the event listener when the component unmounts.
    return () => {
      eventBus.off("api-error", handleErrorEvent);
    };
  }, []);

  // Effect hook to manage the clearing of error messages.
  useEffect(() => {
    if (errorMessages.length > 0) {
      // Display all error messages.
      errorMessages.forEach(message => showApiFailureMessage(message));

      // Set a timeout to clear the error messages after 5000ms.
      const timer = setTimeout(() => {
        setErrorMessages([]);
      }, 5000);

      // Cleanup the timer.
      return () => clearTimeout(timer);
    }
  }, [errorMessages]); // Depend on errorMessages to re-trigger when updated.

  // Provide the error context to children components.
  return (
    <ErrorContext.Provider value={{ errorMessages }}>
      {children}
    </ErrorContext.Provider>
  );
};
