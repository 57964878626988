import React, { useCallback, useState } from "react";
import isEmpty from "lodash/isEmpty";
// import ServiceList from "../../components/service-list/ServiceList";
import {
  useServiceSearchContext,
  Actions
} from "../../state/service-search.context";
import EditRecallServicePage from "./EditRecallServicePage";
import ServicesList from "../ServiceList/services-list.component";

const RecallServiceQuickFilterPage = props => {
  const { EditServiceModule } = props;
  const { state, dispatch, ctxGtmEvent } = useServiceSearchContext();
  const { recallServices, appConfig } = state;
  const [showEditCmp, setShowEditCmp] = useState(false);
  const [recallAPIService, setRecallAPIService] = useState(null);

  const handleServiceClick = useCallback(
    service => {
      ctxGtmEvent?.trackGAEventWithParam("ga.newquote.select_service_click", {
        result: service.operationSource,
        location: "recall-quick-filter"
      });
      setRecallAPIService(service);
      if (appConfig.editModuleAccess) {
        setShowEditCmp(true);
        dispatch({
          type: Actions.SET_SEARCH_HEADER,
          payload: false
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, appConfig.editModuleAccess]
  );

  const updateParentState = closeEdit => {
    setShowEditCmp(closeEdit);
  };

  const editPage =
    showEditCmp && !isEmpty(recallAPIService) ? (
      <EditRecallServicePage
        updateParentState={updateParentState}
        service={recallAPIService}
        EditServiceModule={EditServiceModule}
      />
    ) : (
      ""
    );

  const parentCls =
    showEditCmp && !isEmpty(recallAPIService) ? "hide-ele" : "empty-cls";

  return (
    <>
      {/* <div id="recallServicesQuickFilterPageContainer" className={parentCls}>
        <ServiceList
          services={recallServices}
          viewType="RECALL"
          onSelectService={handleServiceClick}
        />
      </div> */}
      <ServicesList
        parentCls={parentCls}
        services={recallServices}
        viewType="RECALL"
        onSelectService={handleServiceClick}
      />
      {editPage}
    </>
  );
};

export default RecallServiceQuickFilterPage;
