import React, { useCallback, useRef, useState } from "react";

import { AgGridReact } from "ag-grid-react";
import clsx from "clsx";

import IconDelete from "@cx/ui/Icons/IconDelete";
import "./csr.scss";
import IconAdd from "@cx/ui/Icons/IconAdd";
import Button from "@cx/ui/Button";

import {
  Actions,
  useEditServiceContext
} from "../../state/edit-service.context";

const WarrantyExpenseVORComponent = ({ expense }) => {
  const [rowData, setRowData] = useState(expense?.invoices || []);
  const gridRef = useRef();
  const { dispatch } = useEditServiceContext();

  function CustomHeader() {
    let newRowData = {
      invoiceNumber: null,
      partsQuantity: 0,
      amount: 0
    };
    const handleButtonClick = () => {
      gridRef.current.api.applyTransaction({ add: [newRowData] });
      const updatedRows = [];
      gridRef.current.api.forEachNode(node => {
        updatedRows.push(node.data);
      });
      dispatch({
        type: Actions.SET_WARRANTY_DETAILS_INVOICES,
        payload: {
          invoices: getUpdatedInvoiceDataForPayload(updatedRows),
          expenseName: expense?.name
        }
      });
      newRowData = {
        invoiceNumber: null,
        partsQuantity: 0,
        amount: 0
      };
    };
    return (
      <div>
        <Button
          aria-label="Add"
          htmlId="btnIcon"
          style={{ padding: "0px" }}
          icon={<IconAdd htmlId="buttonIcon-iconAdd" />}
          onClick={handleButtonClick}
          buttonStyle="secondary"
        />
      </div>
    );
  }

  const getUpdatedInvoiceDataForPayload = invoicePayload => {
    if (invoicePayload.length > 0) {
      const techList = [];
      invoicePayload.map(tech => {
        const techObject = {};
        techObject.invoiceNumber = tech?.invoiceNumber;
        techObject.partsQuantity = parseFloat(tech?.partsQuantity);
        techObject.amount = parseFloat(tech?.amount);

        techList.push(techObject);
      });
      return techList;
    }
  };

  const handleDeleteInvoice = params => {
    const rowToDelete = params?.data;

    gridRef.current.api.applyTransaction({ remove: [rowToDelete] });

    const updatedRows = [];
    gridRef.current.api.forEachNode(node => {
      updatedRows.push(node.data);
    });
    dispatch({
      type: Actions.SET_WARRANTY_DETAILS_INVOICES,
      payload: {
        invoices: getUpdatedInvoiceDataForPayload(updatedRows),
        expenseName: expense?.name
      }
    });
  };

  const removePartCellRenderer = params => {
    if (!params || !params.data) {
      return "";
    }

    return (
      <div
        className="row-remove"
        onClick={() => {
          handleDeleteInvoice(params);
        }}
      >
        <IconDelete htmlId="iconDelete" className="remove" />
      </div>
    );
  };

  const columnDefs = [
    {
      headerName: "Invoice",
      field: "invoiceNumber",
      singleClickEdit: true,
      flex: 5
    },

    {
      headerName: "Parts qty",
      field: "partsQuantity",
      singleClickEdit: true,
      flex: 2
    },

    {
      headerName: "Amount",
      field: "amount",
      singleClickEdit: true,
      flex: 2,
      valueFormatter: params => {
        return `$   ${params.value}`;
      }
    },
    {
      field: "Action",
      cellRendererFramework: removePartCellRenderer,
      headerName: "",
      maxWidth: 45,
      editable: false,
      resizable: false,
      filter: false,
      suppressMenu: true,
      sortable: false,
      lockVisible: true,
      suppressSizeToFit: false,
      suppressColumnsToolPanel: true,
      enableRowGroup: false,
      cellClass: "xmm-wrap-text",

      headerComponentFramework: CustomHeader,
      headerComponentParams: {
        onButtonClick: () => {
          // Handle button click event
          console.log("Button clicked!");
        }
      }
    }
  ];

  const [gridOptions, _setGridOptions] = useState({
    defaultColDef: {
      editable: true,
      resizable: true,
      flex: 1
    },
    rowDragManaged: true,
    animateRows: true
  });

  let columnApi;

  const onGridReady = params => {
    columnApi = params.columnApi;
    console.log(columnApi);
  };

  const partsGridCls = clsx(
    "ag-grid-container",
    "ops-technician-grid",
    // "ops-scroll-x-hidden",
    "ag-theme-balham"
  );

  const onCellValueChanged = useCallback(params => {
    console.log("onCellValueChanged", params);

    const updatedRows = [];
    gridRef.current.api.forEachNode(node => {
      updatedRows.push(node.data);
    });

    dispatch({
      type: Actions.SET_WARRANTY_DETAILS_INVOICES,
      payload: {
        invoices: getUpdatedInvoiceDataForPayload(updatedRows),
        expenseName: expense?.name
      }
    });
    setRowData(updatedRows);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div id="partsGrid" className={partsGridCls}>
      <AgGridReact
        ref={gridRef}
        rowData={rowData}
        columnDefs={columnDefs}
        gridOptions={gridOptions}
        onGridReady={onGridReady}
        editType="fullRow"
        onCellValueChanged={onCellValueChanged}
        stopEditingWhenGridLosesFocus={true}
        overlayNoRowsTemplate="No rows to show"
      />
    </div>
  );
};

export default WarrantyExpenseVORComponent;
