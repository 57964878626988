// EXTERNAL LIBRARIES
import React, { useState } from "react";
import TextInput from "@cx/ui/TextInput";
import IconSearch from "@cx/ui/Icons/IconSearch";
import PropTypes from "prop-types";
// SERVICES
import { getVehicleImage } from "../../../../../api/xmmAxios";
// UTILS
import format from "../../../utils/format";

const ServiceHistoryHeader = ({
  vehicleInfo,
  countryCode,
  windowSize,
  onChangeSearchValue,
  showSearchBar
}) => {
  const { make, model, year } = vehicleInfo;
  const clsVehicleImg = windowSize.width < 428 ? "hide" : "vehicle-img-class";
  /**
   * State to track the value of the input search.
   * @type {string}
   */
  const [search, setSearch] = useState("");
  /**
   * Returns vehicle image URL
   * @return {string}
   */
  const getImage = () => {
    return getVehicleImage(make, model, year, countryCode);
  };
  /**
   * Handler function for the input change event.
   * @param cxEvent - The input change event {target: {name, value}}
   */
  const handleInputChange = cxEvent => {
    setSearch(cxEvent.target.value);
    onChangeSearchValue?.(cxEvent.target.value);
  };
  return (
    <>
      {showSearchBar ? (
        <div className="search-bar">
          <div className="input-container">
            <IconSearch htmlId="Search-Icon" className="search-icon" />
            <TextInput
              displayLabel={false}
              label=""
              htmlId="Search-text-input"
              placeholder="RO #, OpCode, or Service"
              name="textInputPrepend"
              className="search-input"
              onChange={handleInputChange}
              value={search}
            />
          </div>
        </div>
      ) : null}
      <div className="csr-info-left">
        <div
          className={clsVehicleImg}
          style={{ backgroundImage: `url(${getImage()})` }}
        />

        <div className="csr-info-left-content">
          <span className="vehicle-make-model-text-h1">
            {year} {make} {model}
          </span>
          <span className="vehicle-make-model-text-h2">
            {vehicleInfo.trim} - {vehicleInfo.color}
          </span>

          <div className="csr-info-left-content-details">
            <div className="csr-info-left-content-details-item">
              <span className="vehicle-heading-text">Mileage</span>
              <span className="vehicle-data-text">
                {vehicleInfo.mileage === null ? "---" : vehicleInfo.mileage}
              </span>
            </div>
            <div className="csr-info-left-content-details-item">
              <span className="vehicle-heading-text">License</span>
              <span className="vehicle-data-text">
                {vehicleInfo?.licenseNumber || "---"}
              </span>
            </div>
            <div className="csr-info-left-content-details-item">
              <span className="vehicle-heading-text">VIN</span>
              <span className="vehicle-data-text">
                {vehicleInfo?.vin === null ? "---" : vehicleInfo?.vin}
              </span>
            </div>
            <div className="csr-info-left-content-details-item">
              <span className="vehicle-heading-text">Last RO</span>
              <span className="vehicle-data-text">
                {format
                  .relativeDateString(vehicleInfo?.repairOrders[0]?.roDate)
                  .replace("Invalid Date", "---")}
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServiceHistoryHeader;

ServiceHistoryHeader.propTypes = {
  // handlers
  onChangeSearchValue: PropTypes.func,
  // data
  vehicleInfo: PropTypes.object.isRequired,
  countryCode: PropTypes.string.isRequired,
  windowSize: PropTypes.object,
  showSearchBar: PropTypes.bool
};
