import Button from "@cx/ui/Button";
import IconDelete from "@cx/ui/Icons/IconDelete";
import PriceInput from "@cx/ui/PriceInput";
import TextInput from "@cx/ui/TextInput";
import React, { useState } from "react";
import PaymentNotes from "./payment-notes.component";

const CashPayment = props => {
  const { paymentLine, removePaymentLine, updateSelectedPaymentLines } = props;

  const [currentPaymentLine, setCurrentPaymentLine] = useState(paymentLine);

  const onCashInputChange = cxEvent => {
    setCurrentPaymentLine({
      ...paymentLine,
      [cxEvent.target.name]: cxEvent.target.value
    });
    updateSelectedPaymentLines({
      ...paymentLine,
      [cxEvent.target.name]: cxEvent.target.value
    });
  };

  return (
    <div className="cash-wrapper">
      <div className="cash-title">Cash</div>
      <div className="cash-payment-fields">
        <TextInput
          htmlId="cashdrawerId"
          name="drawerNumber"
          label="Drawer"
          className="input-field cash-drawer-input"
          onChange={onCashInputChange}
          value={currentPaymentLine?.drawerNumber || ""}
          autoFocus={true}
          maxLength={50}
          required
          autoComplete="off"
        />
        <PriceInput
          htmlId="cashAmount"
          name="paymentAmount"
          label="Amount"
          inputPrefix="$"
          className="input-field cash-amount-input"
          onChange={onCashInputChange}
          value={
            currentPaymentLine?.paymentAmount
              ? String(currentPaymentLine?.paymentAmount)
              : ""
          }
          minValue={0.01}
          autoComplete="off"
          required
        />
        <Button
          aria-label="cash delete button"
          className="cash-delete-button"
          htmlId="cashDeleteIcon"
          icon={<IconDelete className="icon-delete-svg" />}
          type="button"
          onClick={() => {
            removePaymentLine(currentPaymentLine);
          }}
        />
      </div>
      <PaymentNotes
        onPaymentNoteChange={onCashInputChange}
        currentPaymentLine={currentPaymentLine}
      />
    </div>
  );
};

export default CashPayment;
