import React from "react";
import { string, number, shape } from "prop-types";
import Grid from "@cx/ui/Grid";
import Col from "@cx/ui/Col";
import Row from "@cx/ui/Row";
import { getVehicleImage } from "../../../../../api/xmmAxios";
import {
  formatNumberWithThousands,
  formatPhoneNumber
} from "../../../../../utils/formatter.util";

const CustomerVehicleDetails = props => {
  const { customer, vehicle, dealerCountryCode, mileageIn, mileageOut } = props;

  const { firstName, lastName, contactInfo, email } = customer ? customer : {};

  return (
    <Grid className="customer-details">
      <Col xs={5} md={6}>
        <Row>
          <span className="text-color-grey">
            <strong>
              {firstName} {lastName}
            </strong>
          </span>
        </Row>
        <Row>
          {" "}
          <span className="text-color-grey">{email || "---"}</span>
        </Row>
        <Row>
          <span className="text-color-grey">
            {formatPhoneNumber(
              contactInfo?.phoneNumbers?.HOME ||
                contactInfo?.phoneNumbers?.MOBILE
            ) || "---"}
          </span>
        </Row>
      </Col>
      <Col xs={6} md={6}>
        <Col xs={4} md={3} style={{ borderLeft: `1px solid #cec7c7` }}>
          <div
            className="vehicle-img"
            style={{
              backgroundImage: `url(${getVehicleImage(
                vehicle?.make,
                vehicle?.model,
                vehicle?.year,
                dealerCountryCode
              )})`
            }}
          />
        </Col>
        <Col xs={8} md={6}>
          <Row>
            <span className="text-color-grey">
              <strong>
                {vehicle?.year} {vehicle?.make || "---"} {vehicle?.model} -{" "}
                {vehicle?.color || "---"}
              </strong>
            </span>
          </Row>
          <Row>
            <span className="text-color-grey">{vehicle?.vin || "---"}</span>
          </Row>
          <Row>
            <span className="text-color-grey">
              Mileage in/out: {formatNumberWithThousands(mileageIn) || "--"} /{" "}
              {formatNumberWithThousands(mileageOut) || "--"}
            </span>
          </Row>
        </Col>
      </Col>
    </Grid>
  );
};

CustomerVehicleDetails.propTypes = {
  customer: shape({
    firstName: string,
    lastName: string,
    email: string,
    contactInfo: shape({
      phoneNumbers: shape({
        HOME: string,
        MOBILE: string
      })
    })
  }),
  vehicle: shape({
    make: string,
    model: string,
    year: number,
    color: string,
    vin: string
  }),
  dealerCountryCode: string,
  mileageIn: number,
  mileageOut: number
};

export default CustomerVehicleDetails;
