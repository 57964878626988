import React, { useState } from "react";
import CurrencyPriceInput from "./currency-price-input.component";
import TotalRow from "./total-row.component";
import { stripSubletPrefix } from "../utils/sublet.util";
import parse from "../../../utils/parse";
import util from "../../../utils/util";
import { splitPoPricingProps } from "../sublet.types";
import { SPLIT_SUBLET_DEFAULT } from "../../../constants/adjustment.constant";
import PropTypes from "prop-types";

const SplitCostOff = ({
  poPricing = SPLIT_SUBLET_DEFAULT.poPricing,
  onUpdate = () => {}
}) => {
  const { price, cost } = poPricing;
  const [newPrice, setNewPrice] = useState(price);
  const [newCost, setNewCost] = useState(cost);
  const handleUpdateCost = event => {
    const numericValue = parse.currency(event.target.value, 0);
    setNewCost(numericValue);
    onUpdate({
      [stripSubletPrefix(event.target.name)]: numericValue
    });
  };

  const handleUpdatePrice = event => {
    const numericValue = parse.currency(event.target.value, 0);
    setNewPrice(numericValue);
    onUpdate({
      [stripSubletPrefix(event.target.name)]: numericValue,
      subletTotalCost: numericValue
    });
  };
  return (
    <>
      <CurrencyPriceInput
        value={newCost}
        htmlId="subletCost"
        name="subletCost"
        label="Cost"
        onUpdate={handleUpdateCost}
      />
      <CurrencyPriceInput
        value={newPrice}
        htmlId="subletPrice"
        name="subletPrice"
        error={
          util.isNumber(newPrice) && newPrice < newCost
            ? "Price is below cost"
            : undefined
        }
        label="Price"
        onUpdate={handleUpdatePrice}
      />
      <div className="separator" />
      <div className="separator" />
      <TotalRow
        label="Total sublet"
        value={price}
        priceClassName="total-amount"
      />
    </>
  );
};

SplitCostOff.propTypes = {
  poPricing: splitPoPricingProps,
  onUpdate: PropTypes.func.isRequired
};

export default SplitCostOff;
