// EXTERNAL LIBRARIES
import React from "react";
import Button from "@cx/ui/Button";
import PropTypes from "prop-types";

const ServiceHistoryFooter = ({
  isComeBackModal,
  onCloseWindow,
  onCancelModal,
  onSave
}) => {
  return (
    <div className="footer-service-history">
      <hr className="footer-divider" />
      <div className="buttons-service-history">
        {isComeBackModal ? (
          <>
            <Button
              htmlId="cancel-button"
              buttonStyle="secondary"
              onClick={onCancelModal}
            >
              Cancel
            </Button>
            <Button
              htmlId="close-button"
              buttonStyle="primary"
              onClick={onSave}
            >
              Save
            </Button>
          </>
        ) : (
          <Button
            htmlId="close-button"
            buttonStyle="primary"
            onClick={onCloseWindow}
          >
            Done
          </Button>
        )}
      </div>
    </div>
  );
};

export default ServiceHistoryFooter;

ServiceHistoryFooter.propTypes = {
  // handlers
  onCloseWindow: PropTypes.func,
  onCancelModal: PropTypes.func,
  onSave: PropTypes.func,
  // data
  isComeBackModal: PropTypes.bool
};
