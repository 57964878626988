/* eslint-disable no-console */
import React, { useState } from "react";
import PropTypes from "prop-types";
import ModalDialog from "@cx/ui/ModalDialog";
import FeedbackForm from "./feedback-form.component";
import "./feedback.scss";
// TODO: Keep this component until product team ask to delete this feature
const FeedbackModal = props => {
  const [alert, setAlert] = useState(false);
  // const [feedbackState, setFeedbackState] = useState("");
  // useEffect - Similar to componentDidMount and componentDidUpdate
  // useEffect(() => {
  // });

  const hide = () => {
    props.closeModal();
  };

  const saveFeedbackState = feedbackData => {
    // setFeedbackState(feedbackData);
    console.log(feedbackData);
  };
  const toggleBanner = () => setAlert(!alert);

  const onClose = () => {
    toggleBanner();
    hide();
  };
  const screen = !props.screenName ? "Service Quoting" : props.screenName;
  const modalHeader = (
    <ModalDialog.Title>Tell us what you think about {screen}</ModalDialog.Title>
  );
  let banner = null;
  if (alert) {
    banner = "";
  }
  return (
    <ModalDialog
      htmlId="feedbackModal"
      show={props.show}
      onHide={hide}
      backdrop="static"
      header={modalHeader}
      footer={false}
      className="xmm-feebback-modal"
    >
      <span> {banner} </span>

      <FeedbackForm
        screenName={props.screenName}
        // feedbackState={feedbackState}
        close={onClose}
        saveFeedbackState={saveFeedbackState}
      />
    </ModalDialog>
  );
};

export default FeedbackModal;
FeedbackModal.defaultProps = {
  closeModal: true,
  okAction: () => {},
  screenName: "",
  show: false
};

FeedbackModal.propTypes = {
  closeModal: PropTypes.func,
  okAction: PropTypes.func,
  screenName: PropTypes.string,
  show: PropTypes.bool
};
/* eslint-enable no-console */
