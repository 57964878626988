import { makeSecureRestApi } from "../../../api/xmmAxios";

const assignPartCounterPersonToRO = ({ appContext, quote }) => {
  const { confirmationId } = quote;
  const { dealer, user } = appContext;
  const restEndPoint = `/quote/${dealer?.dealerCode}/${confirmationId}`;

  return new Promise((resolve, reject) => {
    makeSecureRestApi(
      {
        url: restEndPoint,
        method: "patch",
        data: {
          partsPerson: user?.id,
          lastModByUserId: user?.id
        },
        params: {}
      },
      response => {
        resolve(response);
      },
      error => {
        reject(error);
      },
      "Unable to assign parts person."
    );
  });
};

export { assignPartCounterPersonToRO };
