import util from "./util";
import parse from "./parse";
import {
  SUBLET_MAX_BASE_COST,
  SUBLET_MAX_MARKUP_DOLLARS,
  SUBLET_MAX_MARKUP_PERCENT
} from "../constants/adjustment.constant";

export default class csrUtil {
  /**
   * Checks whether a sublet's properties are all valid.
   * @param {object} sublet The sublet to validate.
   * @returns {boolean} `true` if the sublet is valid, otherwise `false`.
   */
  static isValidSublet(sublet) {
    if (!sublet || !sublet.poPricing) {
      return false;
    }
    const hasValidBaseCost =
      util.isNumber(sublet.poPricing.baseCost) &&
      sublet.poPricing.baseCost <= SUBLET_MAX_BASE_COST;
    const hasValidMarkupDollars =
      sublet.poPricing.markupType === "$" &&
      sublet.poPricing.markupDollars !== null &&
      sublet.poPricing.markupDollars <= SUBLET_MAX_MARKUP_DOLLARS;
    const hasValidMarkupPercent =
      sublet.poPricing.markupType === "%" &&
      sublet.poPricing.markupPercent !== null &&
      sublet.poPricing.markupPercent <= SUBLET_MAX_MARKUP_PERCENT;
    return !!(
      sublet.vendorNotes?.length &&
      sublet.vendor?.length &&
      sublet.poNumber?.length &&
      hasValidBaseCost &&
      (hasValidMarkupDollars || hasValidMarkupPercent)
    );
  }

  /**
   * Calculates the total cost for a sublet.
   * @param {number} baseCost
   * @param {string} markupType
   * @param {number} markupDollars
   * @param {number} markupPercent
   * @returns {number} Total calculated cost for the sublet.
   */
  static calculateSubletTotalCost(
    baseCost,
    markupType,
    markupDollars,
    markupPercent
  ) {
    if (
      !util.isNumber(baseCost) ||
      (!util.isNumber(markupDollars) && !util.isNumber(markupPercent))
    ) {
      return 0;
    }
    const markupAmount =
      markupType === "$"
        ? markupDollars
        : parse.currency(baseCost * (markupPercent / 100), 0);
    return baseCost + markupAmount;
  }
}
