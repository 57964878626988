// This schema used to validate unified API response when service is searched
import isEmpty from "lodash/isEmpty";
import isNull from "lodash/isNull";
import { object, string, number, array, mixed, boolean } from "yup";

const alternateParts = object().shape({
  oemPartNumber: string(),
  serialNUmber: number(),
  status: string()
});

const parts = object().shape({
  partId: number().nullable(),
  partName: string().default("").required(),
  partType: string().oneOf(["part", "fluid"]).default("").required(),
  oemPartNumber: string()
    .default("")
    .transform(value => (isNull(value) ? "" : value))
    .nullable(),
  position: string().default("").nullable(),
  quantity: number()
    .default(0)
    .transform(value => (isNull(value) ? 0 : value))
    .nullable(),
  exactQuantity: number()
    .default(0)
    .transform(value => (isNull(value) ? 0 : value))
    .nullable(),
  unitOfMeasure: string().default("").nullable(),
  unitPrice: number()
    .default(0)
    .transform(value => (isNull(value) ? 0 : value))
    .nullable(),
  partsPrice: number()
    .default(0)
    .transform(value => (isNull(value) ? 0 : value))
    .nullable(),
  priceSource: string()
    .default("MSRP")
    .transform(value => (isNull(value) ? "MSRP" : value))
    .nullable(),
  // priceSource: mixed()
  // .oneOf(["DEALER_CALCULATED_PRICING", "DEALER_TOTAL_FLAT_PRICING", null])
  // .nullable(),
  notes: array(string()).default(null).nullable(),
  status: string().default("").nullable(),
  // Check: API documentation not consistent
  vehicleAttributes: object()
    .default(null)
    .transform(value => (isEmpty(value) ? null : value))
    .nullable(),
  alternateParts: array(alternateParts).default(null).nullable(),
  partNumberDefinedByDealerFlag: boolean()
    .default(false)
    .transform(value => (isNull(value) ? false : value))
    .nullable(),
  dtDmsPartCode: string().default("").nullable(),
  relationship: string().default("").nullable(),
  selected: boolean()
    .transform(value => (isNull(value) ? false : value))
    .nullable()
});

const partsPart = parts;

// TODO: Verify the properties below
const partsFluid = parts.shape({
  oilType: string().nullable(),
  gradeCode: string().nullable(),
  specification: string().nullable(),
  operationName: string().nullable()
});

const notApplicableParts = array().default(null).nullable();
const laborApps = object().shape({
  name: string()
    .default("")
    .transform(value => (isEmpty(value) ? "" : value))
    .nullable(),
  displayName: string()
    .default("")
    .transform(value => (isEmpty(value) ? "" : value))
    .nullable(),
  notes: array(string()).default(null).nullable(),
  position: string().default("").nullable(),
  opCode: string()
    .default("")
    .transform(value => (isEmpty(value) ? "" : value))
    .nullable(),
  laborHours: number()
    .default(0)
    .transform(value => (isNull(value) ? 0 : value))
    .nullable(),
  laborPrice: number()
    .default(0)
    .transform(value => (isNull(value) ? 0 : value))
    .nullable(),
  laborPriceOverridden: boolean()
    .default(false)
    .transform(value => (isNull(value) ? false : value))
    .nullable(),
  // this field is not used in quoting-ui bindings
  priceSource: string()
    .default("")
    .transform(value => (isEmpty(value) ? "" : value))
    .nullable(),
  parts: array(mixed().oneOf([partsPart, partsFluid])).nullable(),
  partsPrice: number()
    .default(0)
    .transform(value => (isNull(value) ? 0 : value))
    .nullable(),
  partsPriceOverridden: boolean()
    .default(false)
    .transform(value => (isNull(value) ? false : value))
    .nullable(),
  totalPrice: number()
    .default(0)
    .transform(value => (isNull(value) ? 0 : value))
    .nullable(),
  totalPriceOverridden: boolean()
    .default(false)
    .transform(value => (isNull(value) ? false : value))
    .nullable(),
  vehicleAttributes: object()
    .default(null)
    .transform(value => (isEmpty(value) ? null : value))
    .nullable(),
  laborAppSource: string().default("").nullable(),
  laborAppId: number().nullable(),
  unscheduledLaborPrice: number()
    .default(null)
    .transform(value => (isNull(value) ? 0 : value))
    .nullable()
});

// @note: This schema used to replace null values with default dataset
// laborApps - possible values [array of objects], [], null - Transform [] blank array with null
// vehicleAttributes - possible values json object {}, null, {} blank object - Transform blank object {} with null
// selectableVehicleAttributes - possible values ["Engine VIN" , "Submodel"], [], null - Transform [] with null
const operationDetailsSchema = object().shape({
  abbreviation: string().default("").nullable(),
  // add this field to set default value
  defaultPayTypeCode: string()
    .default("")
    .transform(value => (isEmpty(value) ? "" : value))
    .nullable(),
  catalogSource: string()
    .oneOf(["GlobalCatalog", "DealerPublishedCatalog"])
    .required()
    .default("")
    .transform(value => (value === null ? "" : value)),
  commentsRequired: boolean().default(false).nullable(),
  operationId: number().nullable(),
  dealerCode: string().default("").nullable(),
  make: string().nullable(),
  serviceKind: string()
    .oneOf(["repair", "maintenance", "autorecall"])
    .required()
    .default("")
    .transform(value => (value === null ? "" : value)),
  serviceCategoryId: number().nullable(),
  serviceCategoryName: string().default("").nullable(),
  defaultLaborRate: number()
    .default(0)
    .transform(value => (isNull(value) ? 0 : value))
    .nullable(),
  dealerLaborRateId: number().nullable(),
  laborTimeMarkupPercent: number().nullable(),
  variant: string().nullable(),
  notApplicableParts: array(notApplicableParts).default(null).nullable(),
  laborApps: array(laborApps)
    .transform(value => (isEmpty(value) ? null : value))
    .nullable(),
  selectableVehicleAttributes: array(string())
    .default(null)
    .transform(value => (isEmpty(value) ? null : value))
    .nullable(),
  vehicleAttributes: object()
    .default(null)
    .transform(value => (isEmpty(value) ? null : value))
    .nullable(),
  description: string().default("").nullable(),
  mandatoryInAppt: boolean().default(false).nullable()
});

export default operationDetailsSchema;
