import React, { useContext, useState, useEffect } from "react";
import isEmpty from "lodash/isEmpty";
import has from "lodash/has";
import get from "lodash/get";
import { AppContext } from "../../../state/app-context";
import useComponentDidMount from "../../../hooks/useComponentDidMount";
import {
  useNewQuoteContext,
  Actions as newQuoteActions
} from "../../../state/NewQuoteContext";
import {
  ModifyPartsProvider,
  useModifyPartsContext,
  Actions
} from "../../../state/modify-parts.context";
// @import custom comps
import { PartsLookupModule } from "../../../PartsLookupModule";
import ConfirmPopup from "../../ui/modals/ConfirmPopup";
// @import services
import axiosService from "../../../api/xmmAxios";
import modifyPartsService from "../../quote-summary/services/modify-parts.service";
import globalOperationsService from "../services/global-operations.service";
import { asyncHandleGetPartsInventoryCommon as getInStockParts } from "../../quote-summary/services/service-details.service";
// @import utils
import { isDealerTireService } from "../utils/parts.util";
import { getPayloadForPartsInventoryUsingQuoteService } from "../utils/data-util";
import {
  formatServiceToRawOperationDetails,
  convertQuoteMenuServiceIntoUnifiedRawOperation
} from "../utils/parts-service-format.util";
import { OperationSources } from "../constants/page-wrapper.constants";
import * as gtmEvent from "../../utils/gtag/gtag-event.util";
import InternalNotes from "../../../EditServiceModule/components/csr/internal-notes.component";
import csrService from "../../quote-summary/services/csr.service";

const ModifyPartsComponent = () => {
  const appContext = useContext(AppContext);
  const {
    appType,
    isPartsView,
    localeStrings,
    user: { quoteUserId, id: currentUserId }
  } = appContext;
  const { state: newQuoteState, dispatch: dispatchToNewQuote } =
    useNewQuoteContext();
  const {
    vehicle,
    quoteSummary,
    currentEditingService,
    currentEditingMenuService,
    menuPackage,
    userPermissions,
    showPageMask,
    debugMode
  } = newQuoteState;
  const { state, dispatch } = useModifyPartsContext();
  const {
    service,
    initialized,
    lookupParts,
    filteredParts,
    isMenuService,
    currentEditingService: selectedService
  } = state;
  const [isLoaded, setIsLoaded] = useState(false);
  const [showDiscardEditPopup, setShowDiscardEditPopup] = useState(false);
  const [serviceHasChanged, setServiceHasChanged] = useState(false);
  const [rawOpsDetails, setRawOpsDetails] = useState(null);
  const [partsPricingAndInventory, setPartsPricingAndInventory] = useState([]);

  useComponentDidMount(() => {
    axiosService.setupAxios(appContext);
    // @note: triggers when modify parts model rendered from summary page
    if (!isEmpty(currentEditingService)) {
      const loadDeps = async () => {
        // read dms parts for quote service
        await asyncGetDMSPartsForModifyParts(
          currentEditingService,
          currentEditingService.payTypeCode
        );
      };
      if (appType !== "CSR") {
        loadDeps();
      }
      const _rawOpsDetails = formatServiceToRawOperationDetails(
        currentEditingService
      );
      setRawOpsDetails(_rawOpsDetails);
      dispatch({
        type: Actions.INITIALIZE,
        payload: {
          currentEditingService,
          formattedRawOperationDetails: _rawOpsDetails
        }
      });
    }
    // @note: this block will trigger when a quote menu service is edited
    if (!isEmpty(currentEditingMenuService) && !isEmpty(menuPackage)) {
      const loadDeps = async () => {
        await asyncGetDMSPartsForModifyParts(
          currentEditingMenuService,
          currentEditingMenuService.payTypeCode
        );
      };
      if (appType !== "CSR") {
        loadDeps();
      }
      const _rawOpsDetails = convertQuoteMenuServiceIntoUnifiedRawOperation(
        currentEditingMenuService
      );
      setRawOpsDetails(_rawOpsDetails);
      dispatch({
        type: Actions.INITIALIZE_WITH_MENU,
        payload: {
          currentEditingService: currentEditingMenuService,
          formattedRawOperationDetails: _rawOpsDetails
        }
      });
    }
    showPageLoading(false);
  });
  useEffect(() => {
    if (isLoaded) {
      dispatchToNewQuote({
        type: newQuoteActions.SET_IS_MODIFY_PARTS,
        payload: false
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoaded]);
  useEffect(() => {
    if (initialized && !isEmpty(partsPricingAndInventory)) {
      if (!isEmpty(rawOpsDetails)) {
        const hasDealerTire = isDealerTireService(rawOpsDetails);
        // @note: no need to pass either filteredParts or lookupParts in the payload as it is already in context by this point. Also, we don't want them as dependencies of useEffect.
        dispatch({
          type: Actions.SET_DMS_LOOKUP_PARTS,
          payload: {
            partsPricingAndInventory,
            hasDealerTire
          }
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialized, partsPricingAndInventory]);
  // local handler called to read DMS parts for a quote service or menu service
  const asyncGetDMSPartsForModifyParts = async (
    currentEditingService,
    payType
  ) => {
    let partsPricingAndInventory = [];
    const { vehicle, customer } = newQuoteState;
    try {
      const payTypeCode = payType || "";
      const commonConsumerId = customer?.commonConsumerId || "";
      const postParams = getPayloadForPartsInventoryUsingQuoteService(
        currentEditingService,
        vehicle,
        payTypeCode,
        currentEditingService.operationSource,
        commonConsumerId,
        currentEditingService.serviceTypeCode
      );
      partsPricingAndInventory =
        await globalOperationsService.getPartsPricingAndInventory(
          null,
          postParams
        );
    } catch (error) {
      partsPricingAndInventory = [];
      console.log(
        "ModifyPartsWrapper - called DMS opentrack API failed with error",
        error
      );
    }
    setPartsPricingAndInventory(partsPricingAndInventory);
    return partsPricingAndInventory;
  };

  const backToSummary = () => {
    dispatchToNewQuote({
      type: newQuoteActions.SET_IS_MODIFY_PARTS,
      payload: false
    });
  };
  const handleCancel = () => {
    if (serviceHasChanged) {
      setShowDiscardEditPopup(true);
      return;
    }
    if (!serviceHasChanged) {
      backToSummary();
    }
  };
  const handleServiceChange = serviceChanged => {
    setServiceHasChanged(serviceChanged);
  };
  const showPageLoading = showPageMask => {
    dispatchToNewQuote({
      type: newQuoteActions.SET_PAGE_MASK,
      payload: showPageMask
    });
  };
  const handleServiceUpdate = async ({ serviceParts, totalPartsPrice }) => {
    showPageLoading(true);
    await modifyPartsService.deleteRemovedParts(
      appContext,
      serviceParts,
      selectedService,
      quoteSummary
    );
    const isMenuPackage =
      selectedService.operationSource === OperationSources.MENU;
    const updatePayload = {
      serviceParts,
      totalPartsPrice,
      quoteSummary,
      selectedService,
      quoteUserId,
      ...(isMenuPackage && { menuPackage })
    };
    let response;
    try {
      if (!isMenuPackage) {
        response = await modifyPartsService.updateQuoteFromModifyParts(
          updatePayload
        );
      } else {
        response = await modifyPartsService.updateQuoteFromModifyPartsWithMenu(
          updatePayload
        );
      }
      dispatchToNewQuote({
        type: newQuoteActions.UPDATE_QUOTE,
        payload: response
      });

      if (isPartsView) {
        // re-assigning of counter parts person
        await csrService.reAssignCounterPartsPerson(
          appContext,
          quoteSummary,
          currentUserId
        );
        // for CSR Parts Detail parts pricing update:
        //   skip parts pricing lookup as the newQuoteActions.UPDATE_QUOTE code will handle it
        return;
      }

      // call dispatchToNewQuote even if parts is empty in order to turn off dmsPending flag
      if (isMenuPackage) {
        // use == to comparee since service.extServiceId is a string but selectedService.extServiceId is a number
        const service = response.quoteServices.find(
          service => service.extServiceId == selectedService.extServiceId
        );
        const { vehicle, customer } = newQuoteState;
        const commonConsumerId = customer?.commonConsumerId || "";
        const parts = await getInStockParts({
          currentEditingService: isMenuPackage
            ? { ...service, operationSource: OperationSources.MENU }
            : service,
          payType: service.payTypeCode,
          vehicle,
          commonConsumerId
        });
        dispatchToNewQuote({
          type: newQuoteActions.SET_IN_STOCK_PARTS_QUOTE_MENU_SERVICE,
          payload: {
            extServiceId: service.extServiceId,
            parts
          }
        });
      }
    } catch (error) {
      const msg = error["message"]
        ? error.message
        : localeStrings["sq.errors.network.error"];
      console.error(msg);
    } finally {
      showPageLoading(false);
      setIsLoaded(true);
    }
  };

  const dispatchDealershipNotes = dealershipNotes => {
    dispatch({
      type: Actions.SET_DEALERSHIP_NOTES,
      payload: dealershipNotes
    });
  };

  const dealershipNotes =
    selectedService?.operationSource === OperationSources.MENU
      ? get(currentEditingMenuService, "dealershipNotes", null)
      : get(currentEditingService, "dealershipNotes", null);

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {initialized ? (
        <div id="editTopService" className="search-flex-grid-container">
          <div className="edit-page-wrapper">
            {!showPageMask ? (
              <PartsLookupModule
                // @required props
                debugMode={debugMode}
                gtmEvent={gtmEvent}
                appType={appContext.appType}
                userPermissions={userPermissions}
                // @required- - expected values "EDIT_SERVICE" or "MODIFY_PARTS"
                actionType="MODIFY_PARTS"
                // @csr-logic
                isPartsView={appContext.isPartsView}
                // @required- make passed to support Make dropdown filter logic in Individual Part Tab
                make={!vehicle ? "" : vehicle.make}
                dealerProperties={appContext?.dealerProperties}
                dealerMakesProperty={
                  appContext?.dealerProperties?.MANUFACTURER_MAKE_CODE_LIST
                }
                emergencyPartProperty={
                  appContext?.dealerProperties?.ENABLE_DMSPLUS_EMERGENCY_PARTS
                }
                onServiceChange={handleServiceChange}
                onCancelHandler={handleCancel}
                onSaveHandler={handleServiceUpdate}
                // @required- Add Service case (search, non-global) - This prop hold catalog getOperation API response level laborApps[0].parts
                // Add Service case (global repair case), state.lookupParts updated using Action SET_LABOR_APP
                // Summary edit case - read QuoteRawService Json string for parts[] from quoteService;
                lookupParts={lookupParts}
                // @required- - All flows - pass parts of opentrack API callback response, to refresh recommended grid and selected parts grid as well
                partsPricingAndInventory={partsPricingAndInventory}
                // @required prop - service object used to read service name, operationSource(global, non-global, menu, price override fields) for conditions.
                // Add service case - parts exist in serivce.parts(non-global);filteredParts(global);
                // Edit service summary (global/non-global)- parts are saved in "filteredParts" using this Action SET_CURRENT_EDITING_SERVICE
                service={{
                  ...service,
                  parts: [
                    ...(!isMenuService && !isEmpty(currentEditingService)
                      ? currentEditingService.parts
                      : !isEmpty(currentEditingMenuService)
                      ? currentEditingMenuService.parts
                      : [])
                  ]
                }}
                // !remove prop - Edge case - Either remove this prop and use service.parts condtions in parts lookup files; This case only used summary edit service case - filteredParts -> parts extracted from quoteService
                savedParts={
                  !currentEditingService && !currentEditingMenuService
                    ? []
                    : filteredParts
                }
                // !remove prop - Edge case to cover if global repair service has undecode vehicl attrs to show extra grid columns
                selectedVehicleAttributes={
                  has(currentEditingService, "selectedVehicleAttributes")
                    ? currentEditingService.selectedVehicleAttributes
                    : {}
                }
                // !remove prop - global repair case, these steps used whether service options dropdown selected, to allow used to click on "MODIFY Parts" button in edit service page.(find alternative)
                completedSteps={{ serviceOptions: true }}
                onPartPricingLookup={async part => {
                  return await globalOperationsService.getPricingAndInventoryForCustomPart(
                    service,
                    rawOpsDetails,
                    vehicle,
                    newQuoteState?.customer,
                    part
                  );
                }}
                onPartCostChange={async partsData => {
                  return await globalOperationsService.getEmergencyPartsPricing(
                    partsData,
                    newQuoteState
                  );
                }}
                // @required - show internal notes in Modify parts modal for QUOTING App
                dealershipNotes={
                  appType !== "CSR" ? (
                    <InternalNotes
                      dealershipNotes={dealershipNotes}
                      dispatchDealershipNotes={dispatchDealershipNotes}
                    />
                  ) : null
                }
              />
            ) : null}
          </div>
          <ConfirmPopup
            title={localeStrings["sq.search.common.alert_lbl"]}
            message={localeStrings["sq.search.common.leaving_edit_page"]}
            show={showDiscardEditPopup}
            okText={localeStrings["sq.search.common.proceed_button"]}
            cancelText={localeStrings["sq.search.common.cancel_button"]}
            okAction={backToSummary}
            cancelAction={() => setShowDiscardEditPopup(false)}
            hideCancel={false}
            hideOk={false}
            buttonStyle="danger"
          />
        </div>
      ) : (
        <div>Loading ...</div>
      )}
    </>
  );
};

const ModifyPartsWrapper = props => {
  return (
    <ModifyPartsProvider>
      <ModifyPartsComponent {...props} />
    </ModifyPartsProvider>
  );
};

export default ModifyPartsWrapper;
