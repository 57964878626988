import { object, array, string, number } from "yup";

const quoteServicesSchema = object().shape({
  extServiceId: string().nullable(),
  menuServices: array().transform(value => value ?? []),
  operationSource: string().nullable(),
  parts: array().nullable(),
  quoteRawService: object().nullable(),
  quoteServiceId: number().nullable(),
  quoteServiceType: string().nullable(),
  technicians: array().nullable()
});

const loadQuoteSchema = object().shape({
  confirmationId: string().nullable(),
  createdByUserId: number().nullable(),
  customer: object().nullable(),
  dealerCode: string().nullable(),
  payers: array().transform(value => value ?? []),
  quoteId: number().nullable(),
  quoteServices: array(quoteServicesSchema).transform(value => value ?? []),
  quoteStatus: string().nullable(),
  vehicle: object().nullable()
});

export default loadQuoteSchema;
