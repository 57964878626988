import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import DatePicker from "@cx/ui/DatePicker";
import TimePicker from "@cx/ui/TimePicker";
import "./change-promised-time.scss";
import moment from "moment";

const PromisedTime = props => {
  const {
    quoteSummary: { pickUpDateTime }
  } = props;

  let pickupDate = null;
  let pickupTime = null;

  if (pickUpDateTime) {
    const quoteDateTime = moment(pickUpDateTime);
    pickupDate = quoteDateTime.format("MM/DD/YYYY");
    pickupTime = quoteDateTime.format("h:mm A");
  }

  const [selectedTime, setSelectedTime] = useState({ value: pickupTime } || "");

  const initialDate = {
    value: pickupDate || "",
    startDate: new Date()
  };
  const [date, setDate] = useState(initialDate);

  const [finalDateTime, setFinalDateTime] = useState({ date: "", time: "" });

  useEffect(() => {
    if (finalDateTime.date === "") {
      setFinalDateTime(prev => ({ ...prev, date: pickupDate }));
    }
    if (finalDateTime.time === "") {
      setFinalDateTime(prev => ({ ...prev, time: pickupTime }));
    }
    if (finalDateTime.date !== "" && finalDateTime.time !== "") {
      props.onUpdate(finalDateTime);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [finalDateTime]);

  const onInputChange = cxEvent => {
    if (cxEvent.target.name.includes("date")) {
      setDate(prev => ({ ...prev, value: cxEvent.target.value }));
      setFinalDateTime(prev => ({ ...prev, date: cxEvent.target.value }));
    }
    if (cxEvent.target.name.includes("time")) {
      setSelectedTime(prev => ({ ...prev, value: cxEvent.target.value }));
      setFinalDateTime(prev => ({ ...prev, time: cxEvent.target.value }));
    }
  };

  return (
    <div className="change-promised-time-container">
      <DatePicker
        htmlId="changePromisedDatePicker"
        label="Date"
        name="date"
        minDate={date.startDate}
        dateFormat="MM/dd/yyyy"
        selected={date.value}
        onChange={onInputChange}
        required
      />
      <TimePicker
        htmlId="changePromisedTimePicker"
        label="Time"
        name="time"
        onChange={onInputChange}
        selected={selectedTime.value}
        required
      />
    </div>
  );
};

export default PromisedTime;

PromisedTime.propTypes = {
  quoteSummary: PropTypes.object,
  onUpdate: PropTypes.func
};

PromisedTime.defaultProps = {
  quoteSummary: {},
  onUpdate: () => {}
};
