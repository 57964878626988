const generateCategoryGroupFilters = synonyms => {
  const categoriesIds = synonyms.map(synonym => synonym.categoryGroupId);
  const categories = synonyms
    .filter(
      ({ categoryGroupId }, index) =>
        !categoriesIds.includes(categoryGroupId, index + 1)
    )
    .map(category => ({
      id: category.categoryGroupId,
      name: category.categoryGroupName || "",
      serviceCategories: synonyms.filter(
        synonym => synonym.categoryGroupId === category.categoryGroupId
      )
    }))
    .sort((prevCategory, nextCategory) =>
      prevCategory.name[0] < nextCategory.name[0] ? -1 : 1
    );

  return categories;
};

export { generateCategoryGroupFilters };
