import React from "react";
import PropTypes from "prop-types";
import format from "../../../utils/format";
import Badge from "@cx/ui/Badge";
import Button from "@cx/ui/Button";

const CatalogDiscountInformation = ({
  service,
  onModify,
  showDetails,
  modifyDisabled
}) => {
  let totalCatalogDiscountApplied = 0;
  service?.catalogDiscounts?.map(
    discount =>
      (totalCatalogDiscountApplied =
        totalCatalogDiscountApplied +
        (discount?.appliedDiscount ? discount?.appliedDiscount : 0))
  );
  return (
    <>
      <div
        className={["catalog-fee-details", "margin-left-12"].join(" ")}
        key={"catalog-discount-details-" + service?.extServiceId}
      >
        <div className="catalog-fee-details-title">
          <span>
            <strong>Discounts</strong>
          </span>
          {service?.catalogDiscounts?.length > 0 ? (
            <Badge htmlId="adjustmentInformationBadge">
              {service?.catalogDiscounts?.length}
            </Badge>
          ) : null}
        </div>
        <div className="catalog-fee-details-total-price">
          {format.currency(totalCatalogDiscountApplied)}
        </div>
      </div>
      {showDetails
        ? service?.catalogDiscounts?.map((discount, index) => {
            const elementKey =
              "catalog-discount-details-" + discount?.dealerDiscountId || index;
            return (
              <div key={`${elementKey}-discounts`}>
                <div
                  key={elementKey}
                  className={["catalog-fee-details", "margin-left-12"].join(
                    " "
                  )}
                >
                  <div className="catalog-fee-details-title">
                    <span>Discount - </span>
                    <span className="catalog-fee-details-description">
                      {discount?.description} |
                    </span>
                    <Button
                      htmlId="catalog-discount-details-modify"
                      className="catalog-discount-details-modify"
                      onClick={() => onModify(discount)}
                      buttonStyle="link"
                      disabled={modifyDisabled}
                      title={
                        modifyDisabled
                          ? "Cannot be modified for closed services"
                          : ""
                      }
                    >
                      Modify
                    </Button>
                  </div>
                  <div className="catalog-fee-details-price">
                    {format.currency(discount?.appliedDiscount)}
                  </div>
                </div>
                <div className="catalog-fee-separator margin-left-10" />
              </div>
            );
          })
        : null}
    </>
  );
};

CatalogDiscountInformation.propTypes = {
  showDetails: PropTypes?.bool
};

export default CatalogDiscountInformation;
