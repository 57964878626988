import React from "react";
import Button from "@cx/ui/Button";
import { toast } from "@cx/ui/Toast";
import isEmpty from "lodash/isEmpty";
import QuoteStatusConstant, {
  payTypeCodes,
  roStatusOptions
} from "../../../constants/quote-status.constants";
import { appType } from "../../../api/xmmAxios";
import { verifyVehicleYMM } from "../../utils/data-transformer";
import { formatDateAndTime } from "../../../utils/date.util";
import { sortingMenuServices } from "../../utils/sorting.util";
import { alphabeticalOrder } from "../../utils/sort-alphabetical.util";
import { QuoteServiceTypes } from "../constants/page-wrapper.constants";
import {
  FEES_TYPES,
  YES
} from "../../repair-order/constants/adjustment.constant";
import isNull from "lodash/isNull";
import has from "lodash/has";
import get from "lodash/get";
import moment from "moment";
import { appSources, appTypes } from "../../../constants/app.constants";

/**
 * Shows a toast message with a link to undo the action that was just completed.
 *
 * @param {string} options.message Message to display.
 * @param {function} options.undoAction Callback handler if the undo link button is clicked.
 * @param {string} [options.undoText] Display text for "undo" link button. Optional; default is "Undo".
 * @param {number} [options.duration] Milliseconds to display the toast before fading. Optional; default is 7000.
 * @param {string} [options.className] CSS class to use. Optional; no default.
 */
const showToastWithUndo = options => {
  const { message, undoAction, undoText, duration, className } = options;

  const toastContent = (
    <div className={`toast-with-undo ${className}`}>
      {message}
      <Button htmlId="undoButton" buttonStyle="link" onClick={undoAction}>
        {undoText ?? "Undo"}
      </Button>
    </div>
  );

  toast.info(toastContent, { autoClose: duration ?? 7000 });
};

/*
 * Place Util methods to support quote price calci
 */
const isLocalDev = () => {
  return process.env.NODE_ENV === "development";
};
/**
 *
 * @param {number} hours
 * @returns
 */
function convertHoursToMinutes(hours) {
  let laborMins = hours || 0;
  laborMins = hours * 60;
  return laborMins;
}
/**
 *
 * @param {number} minutes
 * @returns
 */
const convertMinutesToHours = minutes => {
  const hours = Number.parseFloat(minutes / 60);
  return hours.toFixed(2).endsWith("0") ? hours.toFixed(1) : hours.toFixed(2);
};

/**
 * Returns true if the passed value is empty, false otherwise. The value is deemed to be empty if it is either:
 *
 * - `null`
 * - `undefined`
 * - a zero-length array
 * - a zero-length string (Unless the `allowEmptyString` parameter is set to `true`)
 *
 * @param {Object} value The value to test
 * @param {Boolean} allowEmptyString (optional) true to allow empty strings (defaults to false)
 * @return {Boolean}
 * @markdown
 */
const doesEmpty = (value, allowEmptyString) => {
  return (
    value === null ||
    value === undefined ||
    (!allowEmptyString ? value === "" : false) ||
    (isArrayExist(value) && value.length === 0)
  );
};

/**
 * Returns true if the passed value is a JavaScript Array, false otherwise.
 *
 * @param {Object} target The target to test
 * @return {Boolean}
 * @method
 */
const isArrayExist = value => {
  return Object.prototype.toString.call(value) === "[object Array]";
};

function getAppEnvironment() {
  const { hostname } = window.location;
  const host = hostname.includes("localhost")
    ? "servicequoting.dev6.xtime.app.coxautoinc.com"
    : hostname;
  const hostArr = host.split(".");
  let quoteEnv = hostArr[1];
  quoteEnv = quoteEnv.includes("xtime") ? "prod" : quoteEnv;
  // console.log("getAppEnvironment", host, quoteEnv);
  let returnedEnv = "qa5";
  switch (quoteEnv) {
    case "dev6":
      returnedEnv = "qa5";
      break;
    case "qa6":
      returnedEnv = "ua9";
      break;
    case "demo":
    case "pt3":
      returnedEnv = "pt3";
      break;
    case "stg4":
      returnedEnv = "stg4";
      break;
    case "prod":
      returnedEnv = "prod";
      break;
    default:
      returnedEnv = "qa5";
  }
  return returnedEnv;
}

const checkSpecialChars = str => {
  const specialChars = /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;
  return specialChars.test(str);
};

/**
 *
 * @param {float} price  eg: 68.53999999999999
 * @returns outpur eg: 68.54
 */
const trimPrice = price => {
  const newPrice = !price ? 0 : price;
  return Number(newPrice.toFixed(2));
};
/**
 * handler called in summary page level edit actions where user has edit quote permission
 * case1: enable edit links for status PROGRESS, REQUEST, SENT, READY TO SEND
 * case2: disable buttons, hide edit links for statuses CONVERTED TO APPT and EXPIRED
 * @param {newQuoteContext} quoteSummary
 * @param {appContext} userPermissions
 * @returns true - allow edit access
 */
const hasEditQuoteAccessNew = (quoteSummary, userPermissions) => {
  const { quoteStatus } = quoteSummary;
  let result = true;
  if (appType === "SQ") {
    const currentQuoteStatus =
      quoteSummary.appointmentCode ||
      !userPermissions.canEditQuote ||
      quoteStatus === QuoteStatusConstant.CONVERTED_TO_APPOINTMENT ||
      quoteStatus === QuoteStatusConstant.EXPIRED
        ? false
        : true;
    result = currentQuoteStatus;
  }
  return result;
};

/**
 * case: enable button, for status IN PROGRESS, SENT, READY TO SEND
 * @param {newQuoteContext} quoteSummary
 * @param {appContext} userPermissions
 * @returns
 */
const hasScheduledPermission = (quoteSummary, userPermissions) => {
  const { quoteStatus: status, quoteServices } = quoteSummary;
  let result = true;
  if (appType === "SQ") {
    const hasQuoteServices = !isEmpty(quoteServices);
    const currentQuoteStatus =
      status === QuoteStatusConstant.PROGRESS ||
      status === QuoteStatusConstant.READY_TO_SEND ||
      status === QuoteStatusConstant.SENT
        ? true
        : false;
    console.log(
      "hasScheduledPermission - QuoteStatus",
      currentQuoteStatus,
      userPermissions.canCreateReservationForConsumer
    );
    result = currentQuoteStatus && hasQuoteServices;
  }
  return result;
};
// case1: enable SEND button, for status IN PROGRESS / READY TO SEND/ CONVERTED APPT / SENT
/**
 *
 * @param {newQuoteContext} quoteSummary
 * @returns
 */
const hasSendQuotePermission = quoteSummary => {
  const { quoteStatus: status, quoteServices } = quoteSummary;
  let result = true;
  if (appType === "SQ") {
    const hasQuoteServices = !isEmpty(quoteServices);
    const currentQuoteStatus =
      status === QuoteStatusConstant.PROGRESS ||
      status === QuoteStatusConstant.READY_TO_SEND ||
      status === QuoteStatusConstant.SENT ||
      status === QuoteStatusConstant.CONVERTED_TO_APPOINTMENT
        ? true
        : false;

    console.log(
      "hasSendQuotePermission - QuoteStatus",
      currentQuoteStatus,
      hasQuoteServices
    );
    result = currentQuoteStatus && hasQuoteServices;
  }
  return result;
};
// case1: allow notes edit, for status other than EXPIRED, CONVERTED APPT
const hasNotesAccess = quoteSummary => {
  const { quoteStatus: status } = quoteSummary;
  let result = true;
  if (appType === "SQ") {
    const currentQuoteStatus = !(
      status === QuoteStatusConstant.EXPIRED ||
      status === QuoteStatusConstant.CONVERTED_TO_APPOINTMENT
    );
    result = currentQuoteStatus;
  }
  return result;
};
// enable Assistance button, for status INPROGRESS, REQUEST, READY TO SEND, SENT
// WORKAROUND: COMPLETED - disable assistance btn
const hasAssistanceAccess = quoteSummary => {
  const { quoteStatus: status, quoteServices } = quoteSummary;
  let result = true;
  if (appType === "SQ") {
    const hasQuoteServices = !isEmpty(quoteServices);
    const currentQuoteStatus =
      status === QuoteStatusConstant.PROGRESS ||
      status === QuoteStatusConstant.REQUEST_ASSISTANCE ||
      status === QuoteStatusConstant.READY_TO_SEND ||
      status === QuoteStatusConstant.SENT
        ? true
        : false;
    result = currentQuoteStatus && hasQuoteServices;
  }
  return result;
};
// pastquote case: called from past quotes to show assistance menu items
const hasAssistanceAccessPastQuote = quoteRecord => {
  const { quoteStatus } = quoteRecord;
  let result = true;
  if (appType === "SQ") {
    const currentQuoteStatus =
      quoteStatus === QuoteStatusConstant.PROGRESS ||
      quoteStatus === QuoteStatusConstant.REQUEST_ASSISTANCE ||
      quoteStatus === QuoteStatusConstant.READY_TO_SEND ||
      quoteStatus === QuoteStatusConstant.SENT
        ? true
        : false;
    result = currentQuoteStatus;
  }
  return result;
};
// delete quote case - allow for all status other than CONVERTED_TO_APPOINTMENT
const hasDeleteQuoteAccess = quoteSummary => {
  const { quoteStatus } = quoteSummary;
  let result = true;
  if (appType === "SQ") {
    result = !(quoteStatus === QuoteStatusConstant.CONVERTED_TO_APPOINTMENT);
  }
  return result;
};
/**
 * copy quote case - allowed for all status where user has create quote permission
 * @param {newQuoteContext} quoteSummary
 * @param {appContext} userPermissions
 * @returns
 */
const hasCopyQuoteAccess = (quoteSummary, userPermissions) => {
  const { quoteStatus: status, customer, vehicle } = quoteSummary;
  let result = true;
  if (appType === "SQ") {
    result =
      !isEmpty(status) ||
      userPermissions.canCreateQuote ||
      allowCopyAccess(customer, vehicle)
        ? true
        : false;
  }
  return result;
};
// See quote pdf case - allowed for status SENT, EXPIRED, CONVERTED_TO_APPOINTMENT
const hasSeeQuotePDFAccess = quoteSummary => {
  const { quoteStatus: status } = quoteSummary;
  let result = true;
  if (appType === "SQ") {
    result =
      status === QuoteStatusConstant.SENT ||
      status === QuoteStatusConstant.EXPIRED ||
      status === QuoteStatusConstant.CONVERTED_TO_APPOINTMENT
        ? true
        : false;
  }
  return result;
};

const allowCopyAccess = (customer, vehicle) => {
  const vehicleExists = verifyVehicleYMM(vehicle);
  if (customer === null && !vehicleExists) {
    return false;
  }
  return true;
};

/**
 * @description Formats date and time in twelve hour format "on MM/DD/YYYY at hh:mm AM/PM".
 * @param {string} dateStr
 * @returns {string} dateAndTime
 */
function formatTwelveHourDate(dateStr) {
  if (!dateStr) {
    return "";
  }
  return formatDateAndTime(dateStr, "en_US", true);
}
/**
 * Returns a sorted list of services for Service Quoting when display order is not used.
 *
 * @param {*} quoteServices is a list of quote services.
 * @returns a sorted list of quote services based on this order:
 *
 * 1. all declined services
 * 2. all dealer published or global operations
 * 3. all recall services
 * 4. all menu services
 */
function sortQuoteServicesIfNoOrderIndex(quoteServices) {
  const menuServices = quoteServices?.filter(service => {
    if (service.quoteServiceType === QuoteServiceTypes.MENU) {
      setupMenuService(service);
      return true;
    }
    return false;
  });
  const declineServices = quoteServices.filter(
    service => service.quoteServiceType === QuoteServiceTypes.DECLINED
  );
  const recallServices = quoteServices.filter(
    service => service.quoteServiceType === QuoteServiceTypes.RECALL
  );
  // @note: refactor this logic to avoid duplicate services picked from top services vs global search vs diagnose list
  const globalOpsServices = quoteServices.filter(
    service =>
      service.quoteServiceType === QuoteServiceTypes.GLOBAL_REPAIR_OPS ||
      service.quoteServiceType === QuoteServiceTypes.DEALER_PUB_MAINT_OPS ||
      service.quoteServiceType === QuoteServiceTypes.DEALER_PUB_REPAIR_OPS
  );

  const services = [
    ...declineServices,
    ...globalOpsServices,
    ...recallServices,
    ...menuServices
  ];
  return services;
}
/**
 * Sort services within a menu service and set menu options with total labors and parts lists
 *
 * @param {*} menu is a menu service
 */
function setupMenuService(menu) {
  // WORKAROUND: For Menus, calculate total labor, total parts across menu services and send them as props.menuOptions;
  // This props read in ServiceDetails to display total laborPrice, total partsPrice at menu level in summary UI table
  const menuTotalLabors = [];
  let menuTotalParts = [];
  // TODO: these extra fields add in the past to do calculation of total labor, parts for all menu services. code cleanup - stop reading these extra fields in other files
  menu.parts = [];
  menu.labors = [];

  // For summary page "order" field is saved as "rank". Logic below will order menuServices by "rank", if rank = null will order alphabetically
  const services = menu?.menuServices || [];
  const hasOrderField = services.filter(service => {
    return service.rank != null;
  });
  const orderedMenuServices =
    hasOrderField.length !== 0
      ? sortingMenuServices(services)
      : alphabeticalOrder(services);

  for (const menuService of orderedMenuServices) {
    menuTotalParts = [...menuTotalParts, ...menuService.parts];
    if (menuService.labor) {
      // @fix: Always read labor price from service.finalLaborPrice instead of labor.laborPrice
      // WORKAROUND: add each labor{} by reading from each menu service level for labor.laborTime only;
      // use menuTotalLabors store and display total labor time at menu level in summay page
      menuTotalLabors.push(menuService.labor);
    }
  }
  const menuOptions = {
    menuTotalLabors,
    menuTotalParts
  };
  console.log("setupMenuService", menuOptions);
  menu.menuOptions = menuOptions;
}
/**
 * Return sorted services based on display order or not:
 *
 *   if display order is present then sort by displayorder in ascending order
 *   else if app type is CSR then sort by service line number in ascending order
 *   else sort by existing quoting sort order
 *
 * @param {*} services is a list of services
 * @param {*} appType is either CSR or QUOTING
 * @returns a sorted list of services
 */
function sortQuoteServices(services, appType) {
  // Sorting services based on serviceLine Number in ascending order for CSR
  let sortedServices = [];
  const hasDisplayOrder = services.some(
    s => typeof s.displayOrder === "number"
  );
  if (hasDisplayOrder) {
    sortedServices = services.sort(
      (a, b) => parseFloat(a?.displayOrder) - parseFloat(b?.displayOrder)
    );
  } else if (appType === "CSR") {
    updateMenuWithTotalLaborAndParts(services);
    sortedServices = services.sort(
      (a, b) =>
        parseFloat(a?.serviceLineNumber) - parseFloat(b?.serviceLineNumber)
    );
  } else {
    sortedServices = sortQuoteServicesIfNoOrderIndex(services);
  }
  return sortedServices;
}
// For CSR case: iterate quote which has menu and calculate total labor, parts of all menu services within menu
function updateMenuWithTotalLaborAndParts(quoteServices) {
  quoteServices.forEach(service => {
    if (service.quoteServiceType === QuoteServiceTypes.MENU) {
      setupMenuService(service);
    }
  });
}

/**
 * We force every part to be { selected: true } property
 * @param {*} parts
 */
function updatePartsWithSelectedProperty(parts) {
  if (parts && Array.isArray(parts)) {
    parts.forEach(part => {
      part.selected = true;
    });
  }
}

/**
 * function to check whether fee/discount expired or not
 * @param {*} feesORdiscount
 */
function checkExpiredFeeOrDiscount(data) {
  if (isNull(data.expiryDate)) return true;
  const isExpired = moment(
    data?.expiryDate,
    "YYYY-MM-DDTHH:mm:ssZ"
  ).isSameOrAfter(moment());

  return isExpired;
}

function checkStartDateValidFeeOrDiscount(data) {
  const isStartValid = moment(
    data?.fromDate,
    "YYYY-MM-DDTHH:mm:ssZ"
  ).isSameOrBefore(moment());

  return isStartValid;
}

/**
 * Finds mandatory fees that should be added automatically.
 * @param {object} operationDetail Operation detail from Catalog.
 * @param {string} payTypeCode Pay type code of service getting added.
 * @param {number} laborSubtotal Labor subtotal before fees.
 * @returns {object[]} Array of fees to add to the new service.
 */
function findMandatoryCatalogFees(operationDetail, payTypeCode, laborSubtotal) {
  const catalogFeesAndDiscounts = has(operationDetail, "discountsAndFees")
    ? get(operationDetail, "discountsAndFees")
    : [];
  const catalogFees = [];
  if (catalogFeesAndDiscounts?.fees?.length > 0) {
    catalogFeesAndDiscounts?.fees?.map(fee => {
      fee.payTypes = fee?.payTypes ? fee?.payTypes?.split(",") : [];
      if (
        fee?.applyFeeSvcLine === 1 &&
        fee?.payTypes?.findIndex(pay => pay === payTypeCode) !== -1 &&
        fee?.applyToOverallTotal !== 1 &&
        fee?.feesType !== "Variable" &&
        checkExpiredFeeOrDiscount(fee) &&
        checkStartDateValidFeeOrDiscount(fee)
      ) {
        if (fee.feesType === "Dollar") fee.feeMax = null;
        fee.appliedFee =
          fee.feesType === FEES_TYPES.DOLLAR
            ? fee.dollarAmount
            : fee.feesType === FEES_TYPES.PERCENT
            ? Math.min(
                ((laborSubtotal ?? 0) * fee.percentage) / 100,
                fee?.feeMax
              )
            : null;
        catalogFees.push(fee);
      }
    });
  }
  return catalogFees;
}
const checkChargeAccountValid = (appType, quoteData, dealerProperties) => {
  const customer = quoteData?.customer;
  const isRoHasCustomerPay = quoteData?.quoteServices?.some(
    payer => payer.payTypeCode === payTypeCodes.CUSTOMER
  );
  const isRoFinalized = quoteData?.quoteStatus === roStatusOptions?.FINALIZED;

  const isVehicleHasStockNumber = isEmpty(quoteData?.vehicle?.stockNumber);
  let idForChargeAccount = null;
  if (!isEmpty(customer?.firstName) && !isEmpty(customer?.lastName))
    idForChargeAccount = customer?.commonConsumerId;
  if (isEmpty(customer?.firstName)) idForChargeAccount = customer?.extId;

  const isChargeAccountFeatureEnabled =
    appType === appTypes.CSR ||
    (appType === appTypes.SQ && dealerProperties?.ENABLE_CSR_APP === YES);

  return isChargeAccountFeatureEnabled &&
    !isEmpty(idForChargeAccount) &&
    !isEmpty(quoteData?.confirmationId) &&
    isRoHasCustomerPay &&
    !isRoFinalized &&
    isVehicleHasStockNumber
    ? idForChargeAccount
    : null;
};
/**
 * Determines whether to display the tooltip for part details.
 * @param {string} partData - Description or part number.
 * @returns {boolean} - True if tooltip should be displayed, false otherwise.
 */
function showTooltip(partData = "") {
  return partData?.trim().length > 11;
}

/**
 * Determines whether the quote should be hidden for Eng appSource.
 *
 * @param {string} appSource - The source of the application, expected to be one of the defined app sources.
 * @param {Object} quoteSummary - An object containing details about the quote.
 * @param {string} quoteSummary.quoteStatus - The current status of the quote.
 * @returns {boolean} - Returns true if the quote should be hidden for Engineering, otherwise false.
 */
const shouldHideQuoteForEng = (appSource, quoteSummary) => {
  const { ENG } = appSources;
  const { WITH_ADVISOR } = roStatusOptions;

  return appSource === ENG && quoteSummary?.quoteStatus !== WITH_ADVISOR;
};

export {
  showToastWithUndo,
  isLocalDev,
  convertHoursToMinutes,
  convertMinutesToHours,
  doesEmpty,
  trimPrice,
  isArrayExist,
  getAppEnvironment,
  checkSpecialChars,
  allowCopyAccess,
  hasNotesAccess,
  hasDeleteQuoteAccess,
  hasCopyQuoteAccess,
  hasSeeQuotePDFAccess,
  hasAssistanceAccess,
  hasSendQuotePermission,
  hasScheduledPermission,
  hasEditQuoteAccessNew,
  hasAssistanceAccessPastQuote,
  formatTwelveHourDate,
  sortQuoteServices,
  updatePartsWithSelectedProperty,
  checkExpiredFeeOrDiscount,
  checkStartDateValidFeeOrDiscount,
  findMandatoryCatalogFees,
  checkChargeAccountValid,
  showTooltip,
  shouldHideQuoteForEng
};
