import React, { useState } from "react";
import ToggleSwitch from "@cx/ui/ToggleSwitch";
import SplitCostOn from "./split-cost-on.component";
import SplitCostOff from "./split-cost-off.component";
import PropTypes from "prop-types";
import "../sublet.scss";
import { splitPoPricingProps } from "../sublet.types";
import { SPLIT_SUBLET_DEFAULT } from "../../../constants/adjustment.constant";

const SplitCostSection = ({
  initialPoPricing = SPLIT_SUBLET_DEFAULT.poPricing,
  poPricing = SPLIT_SUBLET_DEFAULT.poPricing,
  onUpdate = () => {}
}) => {
  const { laborCost, laborPrice, partsCost, partsPrice } = poPricing;
  const [isToggled, setIsToggled] = useState(
    !!(laborCost ?? laborPrice ?? partsPrice ?? partsCost)
  );

  const handleSwitchChange = e => {
    resetPricingValues(e.target.value);
    setIsToggled(prev => !prev);
  };

  const resetPricingValues = switchValue => {
    const { cost, price, laborPrice, laborCost, partsPrice, partsCost } =
      initialPoPricing;
    // every time the switch is toggled ON, recall split cost values, if there is any, to facilitate the modify process of existing sublets
    if (switchValue === "checked") {
      onUpdate({
        laborPrice,
        laborCost,
        partsPrice,
        partsCost,
        cost: null,
        price: null,
        subletTotalCost: (laborPrice ?? 0) + (partsPrice ?? 0)
      });
    } else {
      // every time the switch is toggled OFF, recall cost and price values, if there is any, to facilitate the modify process of existing sublets
      onUpdate({
        cost,
        price,
        laborCost: null,
        laborPrice: null,
        partsCost: null,
        partsPrice: null,
        subletTotalCost: price ?? 0
      });
    }
  };

  const renderPricing = () => {
    if (!isToggled) {
      return <SplitCostOff poPricing={poPricing} onUpdate={onUpdate} />;
    }
    return <SplitCostOn splitPricing={poPricing} onUpdate={onUpdate} />;
  };

  return (
    <>
      <div className="pricing-row switch-section">
        <ToggleSwitch
          htmlId="subletSplitCost"
          name="subletSplitCost"
          checked={isToggled}
          onClick={handleSwitchChange}
          className="margin-bottom-0"
        />
        <label htmlFor="subletSplitCost" className="margin-bottom-0">
          Split cost
        </label>
      </div>
      {renderPricing()}
    </>
  );
};

SplitCostSection.propTypes = {
  initialPoPricing: splitPoPricingProps,
  poPricing: splitPoPricingProps,
  onUpdate: PropTypes.func.isRequired
};

export default SplitCostSection;
