import React from "react";
import PropTypes from "prop-types";
import ModalDialog from "@cx/ui/ModalDialog";
import { AddServiceContract } from "./add-service-contract.component";
import { useNewQuoteContext } from "../../state/NewQuoteContext";

const ServiceContractModal = props => {
  const { state } = useNewQuoteContext();

  return (
    <ModalDialog
      htmlId="approveEstimateModal"
      className="service-contract-modal"
      show={props.show}
      onHide={props.cancelHandler}
      header={<h4>Service Contracts</h4>}
    >
      <AddServiceContract
        commonConsumerId={state?.customer.commonConsumerId}
        vin={state?.vehicle?.vin}
        serviceContracts={state?.serviceContracts}
        isModal
      />
    </ModalDialog>
  );
};

export default ServiceContractModal;

ServiceContractModal.propTypes = {
  show: PropTypes.bool.isRequired,
  cancelHandler: PropTypes.func.isRequired
};
