// @ts-check
import React from "react";
import PropTypes from "prop-types";
import DashboardContainer from "./dashboard.container";
import { DashboardProvider as Provider } from "./state/dashboard.context";
// eslint-disable-next-line unused-imports/no-unused-imports
import * as CommonTypes from "../../../@types/common.types";
// eslint-disable-next-line unused-imports/no-unused-imports
import * as ModelsTypes from "../../../@types/models.types";

/**
 * @description Entry point of dashboard. Provides dashboard with its context.
 * @param {{children: any, quotes: ModelsTypes.QuoteDto, localeStrings: CommonTypes.LocaleStrings, dateRange: string, serviceTypes:ModelsTypes.ServiceTypes}} props
 * @returns {CommonTypes.Component}
 */
const DashboardProvider = props => {
  return (
    <Provider>
      <DashboardContainer {...props} />
    </Provider>
  );
};

// Default props
DashboardProvider.defaultProps = {
  quotes: [],
  localeStrings: {},
  dateRange: "30",
  children: null,
  serviceTypes: []
};

// Proptypes
DashboardProvider.propTypes = {
  quotes: PropTypes.array,
  localeStrings: PropTypes.object,
  dateRange: PropTypes.string,
  children: PropTypes.node,
  serviceTypes: PropTypes.array
};

export default DashboardProvider;
