import manufacturerCodes from "../constants/manufacturer-codes.constants";
import isEmpty from "lodash/isEmpty";
import isNull from "lodash/isNull";
import isUndefined from "lodash/isUndefined";
import has from "lodash/has";
import get from "lodash/get";
import { OperationSources } from "../constants/page-wrapper.constants";
import { copydtdmsPartCodeForMenuServicePart } from "./parts.util";

/**
 * FIX - Util method to return either default manufacturer code when catalog API doesn't return field {dtDmsPartCode} in parts
 * @param {string} make
 * @returns {string} code - find manufacturer code based on make from static json file
 */
function getManufacturerCode(make) {
  let code = "OT";
  if (!isEmpty(manufacturerCodes) && make) {
    const matches = manufacturerCodes.filter(m => {
      return m.make.toLowerCase() === make.toLowerCase();
    });
    code = matches.length !== 0 ? matches[0].code : "OT";
  }
  return code;
}

// This logic extracts parts from dealer published, global repair, diagnosis and menu services
function extractPartsFromService(service, code) {
  const partNumbers = [];
  const uniquePartNumberMap = {};
  const rawParts =
    has(service, "parts") && !isEmpty(service.parts)
      ? service.parts
      : has(service, "laborApps") && !isEmpty(service.laborApps[0].parts)
      ? service.laborApps[0].parts
      : [];
  if (!isEmpty(rawParts)) {
    rawParts
      .filter(p => {
        if (p.oemPartNumber) {
          p.dmsPending = true;
          if (!uniquePartNumberMap[p.oemPartNumber]) {
            uniquePartNumberMap[p.oemPartNumber] = p;
            return true;
          }
        }
        return false;
      })
      .forEach(p => {
        partNumbers.push({
          partNumber: p.oemPartNumber,
          manufacturerCode: p.dtDmsPartCode ? p.dtDmsPartCode : code
        });
      });
  }
  return partNumbers;
}
// @note - search flow util method to return payload based on service
function getPayloadForPartsPricingAndInventory(
  service,
  vehicle,
  payTypeCode,
  operationSource,
  commonConsumerId,
  serviceTypeCode
) {
  console.log(
    "getPayloadForPartsPricingAndInventory",
    payTypeCode,
    serviceTypeCode,
    operationSource,
    service,
    vehicle
  );
  service.id = service.id || service.operationId;
  const make = !vehicle.make ? "" : vehicle.make;
  const code = getManufacturerCode(make);
  service.parts = has(service, "parts") ? service.parts : [];
  const partsList = extractPartsFromService(service, code);
  const opCode = service.opCode
    ? service.opCode
    : service.dmsOpcode
    ? service.dmsOpcode
    : "";
  const params = {
    make,
    appSource: "ServiceQuoting",
    operationId: service.id || "",
    operationSource,
    vin: !vehicle.vin ? "" : vehicle.vin,
    payType: payTypeCode,
    serviceType: serviceTypeCode,
    partNumbers: [],
    commonConsumerId: commonConsumerId || "",
    opCode
  };
  params.partNumbers = partsList;
  return params;
}

// @todo-edit: This logic extracts parts from dealer published, global repair, diagnosis and menu services ( verify for declined/recall)
function extractPartsFromQuoteService(service, mfrCode) {
  const partNumbers = [];
  const uniquePartNumberMap = {};
  const rawParts = !isEmpty(service.parts) ? service.parts : [];
  if (!isEmpty(rawParts)) {
    rawParts
      .filter(p => {
        if (p.oemPartNumber) {
          p.dmsPending = true;
          if (!uniquePartNumberMap[p.oemPartNumber]) {
            uniquePartNumberMap[p.oemPartNumber] = p;
            // p.dmsPending = false;
            return true;
          }
        }
        return false;
      })
      .forEach(p => {
        partNumbers.push({
          partNumber: p.oemPartNumber,
          manufacturerCode: p.dtDmsPartCode ? p.dtDmsPartCode : mfrCode
        });
      });
  }
  return partNumbers;
}
// Review this method called from modify part wrapper and edit service hoc
// @note: summary case/modify link case - quoteService is passed, should return payload based on quoteService data model
function getPayloadForPartsInventoryUsingQuoteService(
  quoteService,
  vehicle,
  payTypeCode,
  operationSource,
  commonConsumerId = "",
  serviceTypeCode = ""
) {
  console.log(
    "getPayloadForPartsInventoryUsingQuoteService",
    payTypeCode,
    serviceTypeCode,
    operationSource,
    quoteService,
    vehicle
  );
  const make = !vehicle.make ? "" : vehicle.make;
  const mfrCode = getManufacturerCode(make);
  const localService = {};
  localService.operationId = quoteService.extServiceId || "";
  const quoteServiceParts = has(quoteService, "parts")
    ? quoteService.parts
    : [];
  const currentPartsIds = quoteServiceParts.map(part => {
    const partId = part.extPartId || part.id;
    return partId.toString();
  });
  // @note: "rawService" will be null under menuService passed as quoteService; find workaround?
  const rawService = !isEmpty(quoteService.quoteRawService)
    ? JSON.parse(quoteService.quoteRawService.rawService)
    : {};
  let rawServiceParts;
  // @note: rawService structure is different as per catalog api for menu vs other services.
  // Menu case: check if rawService.parts exist for menu i,e quoteService{}
  if (operationSource === OperationSources.MENU) {
    rawServiceParts = has(rawService, "parts") ? rawService.parts : [];
    // global service case:  parts are found under quoteService.quoteRawService
  } else if (rawService.laborApps && rawService.laborApps.length > 1) {
    const laborApp = rawService.laborApps.find(({ laborAppId }) => {
      return laborAppId === quoteService.labor.extLaborId;
    });
    rawServiceParts = laborApp.parts ? laborApp.parts : [];
    // non-global-service case
  } else if (rawService.laborApps && rawService.laborApps.length === 1) {
    rawServiceParts = rawService.laborApps[0].parts
      ? rawService.laborApps[0].parts
      : [];
  } else {
    rawServiceParts = [];
  }

  const missingParts = !isEmpty(rawServiceParts)
    ? rawServiceParts.filter(part => !currentPartsIds.includes(part.partId))
    : [];
  localService.parts = [...quoteServiceParts, ...missingParts];
  const partsList = extractPartsFromQuoteService(localService, mfrCode);
  const params = {
    make,
    appSource: "ServiceQuoting",
    operationId: localService.operationId,
    operationSource,
    vin: !vehicle.vin ? "" : vehicle.vin,
    payType: payTypeCode,
    serviceType: serviceTypeCode,
    partNumbers: partsList,
    commonConsumerId: !commonConsumerId ? "" : commonConsumerId,
    opCode: quoteService.opCode
      ? quoteService.opCode
      : quoteService.dmsOpcode
      ? quoteService.dmsOpcode
      : ""
  };
  return params;
}
// @note: Common Util - Summary page case: prepare parts payload by reading quoteService.parts or menuService.parts
function readPartsFromQuoteService(
  quoteService,
  vehicle,
  payTypeCode,
  serviceTypeCode,
  operationSource,
  commonConsumerId
) {
  console.log(
    "readPartsFromQuoteService",
    payTypeCode,
    serviceTypeCode,
    operationSource,
    quoteService,
    vehicle
  );
  const make = !vehicle.make ? "" : vehicle.make;
  const mfrCode = getManufacturerCode(make);
  const localService = {};
  localService.operationId = quoteService.extServiceId || "";
  // always extract partnumbers from quoteService.parts only for all service types
  const quoteServiceParts = has(quoteService, "parts")
    ? quoteService.parts
    : [];
  localService.parts = [...quoteServiceParts];
  const partsList = extractPartsFromQuoteService(localService, mfrCode);
  const params = {
    make,
    appSource: "ServiceQuoting",
    operationId: localService.operationId,
    operationSource,
    vin: !vehicle.vin ? "" : vehicle.vin,
    payType: payTypeCode,
    serviceType: serviceTypeCode,
    partNumbers: partsList,
    commonConsumerId: !commonConsumerId ? "" : commonConsumerId,
    opCode: has(quoteService, "dmsOpcode") ? quoteService.dmsOpcode : ""
  };
  return params;
}

function syncPartsPricingData(services) {
  services?.forEach(service => {
    if (!isEmpty(service.parts)) {
      service.parts.forEach(part => {
        part.quantity = part.adjustedQuantity;
        part.partName = part.description;
      });
    }
  });
}

/**
 * The function fixes the total price for labor apps with a price source of "DEALER_TOTAL_FLAT_PRICING"
 * if the total price has not been overridden (i,e totalPriceOverridden= false).
 * @param operationDetail - The `operationDetail` parameter is an object that contains information
 * about a particular operation. It may have a property called `laborApps`, which is an array of labor
 * applications. Each labor application object in the array may have a property called `priceSource`,
 * which indicates the source of the price for
 * Note:
 * Price source = "DEALER_TOTAL_FLAT_PRICING", catalog API returns this priceSource for Dealer publish of All Makes, ANY catalog except for global ops
 * Price source = "DEALER_CALCULATED_PRICING", catalog API returned this for Global repair service.
 * For FLAT PRICING case, method convert API service to treat as totalPrice override case.
 */
function fixDealerTotalFlatPricing(operationDetail) {
  operationDetail?.laborApps?.forEach(laborApp => {
    if (
      laborApp.priceSource === "DEALER_TOTAL_FLAT_PRICING" &&
      !laborApp.totalPriceOverridden
    ) {
      laborApp.totalPriceOverridden = true;
    }
  });
}

const findMatchingMenuService = (menuServices, serviceToFind) => {
  const foundService = menuServices.find(
    service => service.extServiceId === serviceToFind.id
  );
  return foundService;
};

const hasQuantityAvailable = quantityAvailable => {
  return !(isNull(quantityAvailable) || isUndefined(quantityAvailable));
};

function isPartInstock(quantityNeeded, quantityAvailable) {
  if (!hasQuantityAvailable(quantityAvailable)) {
    return null;
  }
  return quantityAvailable >= Number(quantityNeeded);
}

// @workaround: return {dtDmsPartCode} if exists in catalog API or Quote API;
// if not return default manufacturer code using make
// DMS opentrack API payload expecting this field {dtDmsPartCode} to return DMS parts
function getDTdmsPartCode(part, make = "") {
  const code = getManufacturerCode(make);
  const mfrCode = get(part, "dtDmsPartCode", code);
  return mfrCode;
}
/**
 * Util method called to process menu API response to get Menu level totalPrice {sum of menu-service level prices}
 * workaround - parts case - copy {manufacturerCode} if returned in catalog menus API to field {dtDmsPartCode}
 * @param {array} servicePoints
 * @param {string} make
 */
const processMenusAPIData = (servicePoints, make) => {
  servicePoints.forEach(servicePoint => {
    const { menuTypes } = servicePoint;
    if (menuTypes) {
      menuTypes.forEach(menu => {
        if (!menu.selectable && menu.totalPriceOverridden) {
          return;
        }
        menu.services = copydtdmsPartCodeForMenuServicePart(
          menu.services,
          make
        );
        const { services } = menu;
        const totalPrice =
          services && services.length >= 0
            ? services.reduce((acc, service) => {
                const { selectable, selectByDefault, price } = service;
                return selectByDefault || (!selectable && !selectByDefault)
                  ? acc + (price || 0)
                  : acc;
              }, 0)
            : 0;

        menu.totalPrice = totalPrice;
      });
    }
  });
};

export {
  fixDealerTotalFlatPricing,
  getManufacturerCode,
  getPayloadForPartsPricingAndInventory,
  getPayloadForPartsInventoryUsingQuoteService,
  readPartsFromQuoteService,
  extractPartsFromService,
  syncPartsPricingData,
  findMatchingMenuService,
  isPartInstock,
  getDTdmsPartCode,
  processMenusAPIData
};
