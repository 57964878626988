import React from "react";
import ToggleSwitch from "@cx/ui/ToggleSwitch";
import EmailInput from "@cx/ui/EmailInput";
import debounce from "lodash/debounce";

const ServiceQuotingAssistance = props => {
  const onChangeField = event => {
    props.serviceQuotingAssistanceStatus(event.target);
  };

  const onChanceAssistanceEmail = (event, isValid) => {
    props.assistanceEmails(event.target, isValid);
  };

  const debouncedServiceEmails = debounce(onChanceAssistanceEmail, 800);

  const placeholder =
    props.localeStrings[
      "sq.dealer_settings.service_quoting_assistance.email_place_holder"
    ];

  return (
    <div className="service-quoting-assistance-main-container">
      <div className="service-quoting-assistance-header">
        <h4>
          {
            props.localeStrings[
              "sq.dealer_settings.service_quoting_assistance.header"
            ]
          }
        </h4>
        <ToggleSwitch
          htmlId="serviceQuotingAssistanceSwitch"
          name="quoteAssistance"
          onClick={onChangeField}
          checked={props.enableQuoteAssistanceValue}
        />
      </div>
      <div>
        <p>
          {
            props.localeStrings[
              "sq.dealer_settings.service_quoting_assistance.message"
            ]
          }
        </p>
      </div>
      <div className="email-inputs-container">
        <div className="email-inputs-container__input">
          <EmailInput
            htmlId="serviceEmailInput"
            name="serviceEmail"
            maxLength={100}
            placeholder={placeholder}
            required={false}
            layout="horizontal"
            disabled={!props.enableQuoteAssistanceValue}
            autoComplete="no"
            label={
              props.localeStrings[
                "sq.dealer_settings.service_quoting_assistance.service_email"
              ]
            }
            onChange={debouncedServiceEmails}
            value={props.serviceEmailValue}
          />
        </div>
      </div>

      <div className="email-inputs-container">
        <div className="email-inputs-container__input">
          <EmailInput
            htmlId="partsEmailInput"
            name="partsEmail"
            maxLength={100}
            placeholder={placeholder}
            required={false}
            layout="horizontal"
            disabled={!props.enableQuoteAssistanceValue}
            autoComplete="no"
            label={
              props.localeStrings[
                "sq.dealer_setitngs.service_quoting_assistance.parts_email"
              ]
            }
            onChange={debouncedServiceEmails}
            value={props.partsEmailValue}
          />
        </div>
      </div>
    </div>
  );
};

export default ServiceQuotingAssistance;
