/* eslint-disable react/prop-types */
/* eslint-disable no-console */
/* eslint-disable react/no-multi-comp */
import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";
import Card from "@cx/ui/Card";
import VinInput from "@cx/ui/VinInput";
import NumericInput from "@cx/ui/NumericInput";
import Button from "@cx/ui/Button";
import SearchableSelect from "@cx/ui/SearchableSelect";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import capitalize from "lodash/capitalize";
import * as moment from "moment";
import { VehicleSelectorTemplate } from "../../constants/module.constants";
import { formatDateTimezone } from "../../utils/date.util";
import {
  SERVICE_SUMMARY,
  ADD_VEHICLE,
  EDIT_VEHICLE,
  SKIP_CUSTOMER,
  SEARCH_SERVICE
} from "../../constants/pages.constants";
import { findRecord, doesKeyExist } from "../../utils/object";
import { toEmptyStringIfUndefined } from "../../utils/string.util";
import {
  transformVehicle,
  getVariantForVehicle,
  getDrivingConditionsFromTrims
} from "../utils/data-transformer";
import { isAlphaNumeric } from "../../utils/form.validator";
import { makeSecureRestApi } from "../../api/xmmAxios";
import { AppContext } from "../../state/app-context";
import VehicleService from "./services/vehicle.service";
import StatusBox from "../ui/banners/StatusBox";
import {
  useNewQuoteContext,
  Actions,
  NewQuoteContext
} from "../../state/NewQuoteContext";
import vehicleSchema from "./schemas/vehicle.schema";
import quoteService from "../quote-summary/services/quote.service";
import ConfirmPopup from "../ui/modals/ConfirmPopup";
import Banner from "../ui/banners/banner/Banner";
import { isDMSPlus } from "../../api/app.util";

class VehicleFormClass extends React.Component {
  static contextType = NewQuoteContext;
  constructor(props, context) {
    super(props, context);
    // Read values from App context
    const { state } = context;
    const { makes, customer, isNewCustomer, isSkipCustomerFlow } = state;
    // Bind events
    this.callbackSaveHandler = this.callbackSaveHandler.bind(this);
    this.onSelectBlur = this.onSelectBlur.bind(this);
    this.onVinChange = this.onVinChange.bind(this);
    this.onYearChange = this.onYearChange.bind(this);
    this.onModelChange = this.onModelChange.bind(this);
    this.onTrimChange = this.onTrimChange.bind(this);
    this.onTransmissionTypeChange = this.onTransmissionTypeChange.bind(this);
    this.onMileageChange = this.onMileageChange.bind(this);
    // TODO: create and use a schema (Yup) to fill-out empty obj
    let initSearch = Object.assign({}, VehicleSelectorTemplate);
    if (props.formType === EDIT_VEHICLE) {
      initSearch = transformVehicle(props.vehicle, VehicleSelectorTemplate);
    }
    this.state = {
      customer,
      isNewCustomer,
      isSkipCustomerFlow,
      vehicleInfo: {}, // holds vehicle record from decodeVIN handler
      vinDecode: false, // true - when decode handler decodes vin
      vin: toEmptyStringIfUndefined(initSearch.vin),
      oldVin: toEmptyStringIfUndefined(initSearch.vin),
      isValidVin: true,
      makeslist: makes,
      search: initSearch, // This object binded to UI fields
      initialFormData: { ...initSearch },
      selectedMake: {},
      models: [],
      years: [],
      trims: [],
      engineSizeTypes: [],
      driveTypes: [],
      transmissionTypes: [],
      drivingConditions: [],
      warning: "", // @note: warning updated only for special cases for VIN decode vs vehicle selections
      errors: this.getErrors(),
      hasErrors: this.getHasErrors(),
      enableValidations: false,
      statusBox: {
        show: false,
        message: "",
        type: "",
        autoClose: false,
        errorInTooltip: false
      },
      isDirty: false,
      showDiscardPopup: false,
      showYMMPopup: false,
      vehicleFutureAppointment: props.vehicleFutureAppointment
    };
  }

  componentDidMount() {
    const { formType } = this.props;
    // TODO: Unsupported vehicle Issue: if we pre-select make, and years loaded using supported make. open bug: years loaded using wrong variant.
    if (formType === EDIT_VEHICLE) {
      // @note: this logic - preloadSelectors() using vehicle record same like decodeVin handler
      this.loadSelectors();
    }
  }

  getHasErrors() {
    return {
      make: false,
      year: false,
      model: false,
      trim: false,
      vin: false,
      engineSizeType: false,
      driveType: false,
      transmissionType: false
    };
  }

  getErrors() {
    return {
      make: "",
      year: "",
      model: "",
      trim: "",
      vin: "",
      engineSizeType: "",
      driveType: "",
      transmissionType: ""
    };
  }

  // This method will reset all selectors and search
  resetVehicleSelector = () => {
    const { search, errors } = this.state;
    search.make = "";
    const errorlist = this.getErrors();
    errorlist.vin = errors.vin;
    const searchObj = Object.assign({}, VehicleSelectorTemplate);
    const { vehicle } = this.props;
    if (!isEmpty(vehicle)) {
      searchObj.mileage =
        vehicle.mileage !== this.state.search.mileage
          ? this.state.search.mileage
          : vehicle.mileage;
      searchObj.mileageDt = vehicle.mileageDt;
    } else {
      searchObj.mileage = this.state.search.mileage;
    }
    this.setState(
      {
        selectedMake: {},
        warning: "",
        search: searchObj,
        errors: errorlist
      },
      () => {
        this.resetSearchListByMake();
      }
    );
  };

  resetSearchListByMake = () => {
    const { search } = this.state;
    search.year =
      search.model =
      search.trim =
      search.engineSizeType =
      search.driveType =
      search.transmissionType =
        "";
    search.engineSize = search.engineType = "";
    search.variant = "";
    this.setState({
      search,
      years: [],
      models: [],
      trims: [],
      engineSizeTypes: [],
      driveTypes: [],
      transmissionTypes: [],
      drivingConditions: []
    });
  };

  resetSearchListByYear() {
    const { search } = this.state;
    search.model =
      search.trim =
      search.engineSizeType =
      search.driveType =
      search.transmissionType =
        "";
    search.engineSize = search.engineType = "";
    this.setState({
      search,
      models: [],
      trims: [],
      engineSizeTypes: [],
      driveTypes: [],
      transmissionTypes: [],
      drivingConditions: []
    });
  }

  resetSearchListByModel() {
    const { search } = this.state;
    search.trim =
      search.engineSizeType =
      search.driveType =
      search.transmissionType =
        "";
    search.engineSize = search.engineType = "";
    search.drivingConditions = [];
    this.setState({
      search,
      trims: [],
      engineSizeTypes: [],
      driveTypes: [],
      transmissionTypes: [],
      drivingConditions: []
    });
  }
  // TODO: Please clear errors for dependable fields when parent field is selected
  resetSearchListByTrim() {
    const { search } = this.state;
    search.engineSizeType = search.driveType = search.transmissionType = "";
    search.engineSize = search.engineType = "";
    this.setState({
      search,
      engineSizeTypes: [],
      driveTypes: [],
      transmissionTypes: [],
      drivingConditions: []
    });
  }

  resetSearchListByEngineSizeType() {
    const { search } = this.state;
    search.driveType = search.transmissionType = "";
    this.setState({
      search,
      driveTypes: [],
      transmissionTypes: [],
      drivingConditions: []
    });
  }

  resetSearchListByDriveType() {
    const { search } = this.state;
    search.transmissionType = "";
    this.setState({
      search,
      transmissionTypes: [],
      drivingConditions: []
    });
  }
  // @note: handler to verify vehicle attrs and show speed bump
  callbackSaveHandler = event => {
    if (event) event.preventDefault();
    const { search, vin } = this.state;
    search.vin = vin;
    const hasAttributes =
      vehicleSchema.vehicleFormAttributeSchema.isValidSync(search);
    console.log("vehicle has attr", hasAttributes);
    if (hasAttributes) {
      this.handleFormSubmit(event);
    } else {
      this.updateStateForYMMPopup(true);
    }
  };
  // common save button handler
  handleFormSubmit = event => {
    if (event) event.preventDefault();
    const { formType } = this.props;
    const { isSkipCustomerFlow } = this.state;
    const { localeStrings } = this.props.appConfig;
    const isFormValid = this.validateFormErrors();

    if (!isFormValid) {
      this.setState({
        statusBox: {
          message:
            localeStrings["sq.newquote.customer.vehicle-form.form_error"],
          type: "error",
          autoClose: false
        }
      });
    } else {
      // TODO-rule: if Skip customer try to update same vehicle again, we should call only handleSkipCustomerFormSubmit()
      if (isSkipCustomerFlow) {
        // TODO: Skip customer - metaVehicleId not exist in this case. do a callback to read metaVehicleId and update vehicle before saving to context and forward to Add services page
        this.handleSkipCustomerFormSubmit(event);
      } else {
        if (formType === EDIT_VEHICLE) {
          // show speed bump if quote is in progress or completed
          const { quoteServices } = this.props;
          const servicesExist = quoteServices && quoteServices.length > 0;
          if (servicesExist && this.state.isDirty) {
            this.setState({
              showDiscardPopup: true
            });
          } else {
            // calls when mileage field only modified
            this.handleEditFormSubmit(event);
          }
        } else if (formType === ADD_VEHICLE) {
          // add vehicle handler for new customer vs existing customer
          this.handleAddFormSubmit(event);
        }
      }
    }
  };
  // @note: speedbump handlers
  updateStateForYMMPopup = value => {
    this.setState({
      showYMMPopup: value
    });
  };
  proceedToServices = event => {
    this.updateStateForYMMPopup(false);
    // save vehicle and go to search page
    this.handleFormSubmit(event);
  };
  // stay back in vehicle edit page
  continueToSaveVehicle = () => {
    this.closeYMMPopup();
  };
  closeYMMPopup = () => {
    this.updateStateForYMMPopup(false);
  };
  // skip customer async handler
  handleSkipCustomerFormSubmit = async event => {
    if (event) event.preventDefault();
    const { search, vin } = this.state;
    // Fix: vin field is mapped with state.vin, copy vin to search before saving
    search.vin = vin;
    search.mileageDt = formatDateTimezone(new Date(), false, "vehicle");
    const { redirectAfterSave, updateVehicle, appConfig } = this.props;
    this.setState({
      statusBox: {
        message: "Saving...",
        type: "pending",
        autoClose: false
      }
    });
    try {
      const response = await VehicleService.getMetaVehicleId({
        vehicle: search,
        appConfig
      });
      if (response && response.success) {
        const record = response.data;
        search.metaVehicleId = record && record.id;
        this.setState(
          {
            search,
            statusBox: {
              message: "Saved",
              type: "success",
              autoClose: false
            }
          },
          () => {
            updateVehicle(search);
            redirectAfterSave(SKIP_CUSTOMER);
          }
        );
      }
    } catch (error) {
      console.error(error);
      this.setState({
        statusBox: {
          message: "Error while saving vehicle",
          type: "error",
          autoClose: false
        }
      });
    }
  };

  handleAddFormSubmit = async event => {
    if (event) event.preventDefault();
    this.setState({
      statusBox: {
        message: "Saving...",
        type: "pending",
        autoClose: false
      }
    });
    const { search, customer, vin } = this.state;
    // Fix: vin field is mapped with state.vin, copy vin to search before saving
    search.vin = vin;
    const {
      redirectAfterSave,
      updateVehicle,
      appConfig,
      updateNewCustomerStatus
    } = this.props;
    try {
      const response = await VehicleService.saveVehicle({
        customer: {
          ...customer
        },
        appConfig,
        vehicle: {
          ...search
        }
      });
      this.setState({
        statusBox: {
          message: "Saved",
          type: "success",
          autoClose: false
        }
      });

      if (!response.metaVehicleId) {
        const metaVehicleIdResponse = await VehicleService.getMetaVehicleId({
          vehicle: response,
          appConfig
        });
        response.metaVehicleId = metaVehicleIdResponse.data.id;
      }

      if (!response.mileageDt) {
        response.mileageDt = formatDateTimezone(new Date(), false, "vehicle");
      }
      // TODO: remove this line when backend response with variant in payload
      response.variant = search.variant;
      response.drivingConditions = search.drivingConditions;
      response.showDrivingCondition = search.showDrivingCondition;
      response.defaultDrivingCondition = search.defaultDrivingCondition;
      response.personId = !customer.personId ? "" : customer.personId;
      updateVehicle(response);
      redirectAfterSave();
      // TODO-rule:  When New customer is saved, or existing customer added new vehicle. Update isNewCustomer=false (default state)
      updateNewCustomerStatus();
    } catch (error) {
      console.log(error);
      this.setState({
        statusBox: {
          message: "Error while saving vehicle",
          type: "error",
          autoClose: false
        }
      });
    }
  };

  verifyDirtyCheck = () => {
    const vehicleForm = vehicleSchema.vehicleDirtyCheck.cast(
      { ...this.state.search },
      {
        stripUnknown: true
      }
    );
    const initialForm = vehicleSchema.vehicleDirtyCheck.cast(
      { ...this.state.initialFormData },
      {
        stripUnknown: true
      }
    );

    return isEqual(vehicleForm, initialForm);
  };

  // add vehicle async handler
  handleEditFormSubmit = async () => {
    this.setState({
      isDirty: false,
      statusBox: {
        message: "Saving...",
        type: "pending",
        autoClose: false
      }
    });

    const { search, customer, vin } = this.state;
    const { redirectAfterSave, updateVehicle, appConfig, vehicle } = this.props;
    // Fix: vin field is mapped with state.vin, copy vin to search before saving
    search.vin = vin;
    try {
      const response = await VehicleService.editVehicle({
        customer: {
          ...customer
        },
        appConfig,
        vehicle: {
          ...search,
          vehicleId: vehicle.vehicleId
        }
      });
      this.setState({
        statusBox: {
          message: "Saved",
          type: "success",
          autoClose: false
        }
      });
      // TODO: remove this line when backend response with variant in payload
      response.variant = search.variant;
      response.personId = !customer.personId ? "" : customer.personId;
      response.showDrivingCondition = !search.showDrivingCondition
        ? ""
        : search.showDrivingCondition;
      response.defaultDrivingCondition = !search.defaultDrivingCondition
        ? ""
        : search.defaultDrivingCondition;
      const drivingConditions = !search.drivingConditions
        ? []
        : search.drivingConditions;

      response.drivingConditions = !drivingConditions ? [] : drivingConditions;

      updateVehicle(response);
      redirectAfterSave();
    } catch (error) {
      console.log(error);
      this.setState({
        isDirty: false,
        statusBox: {
          message: "Error while saving vehicle",
          type: "error",
          autoClose: false
        }
      });
    }
  };

  onCancelHandler = () => {
    const { formType, onCancel } = this.props;
    if (formType === SKIP_CUSTOMER) {
      onCancel();
    } else {
      // TODO-rule: Cancel not allowed for New Customer saved but no vehicles added
      if (!this.state.isNewCustomer) onCancel();
    }
  };

  onSelectBlur = (cxEvent, isValid, domEvent) => {
    const { name, value } = cxEvent.target;
    const { localeStrings } = this.props.appConfig;
    let errorFlag = false;
    let errorText = "";
    if (!isValid && !isEmpty(value)) {
      errorFlag = true;
      errorText = `'${domEvent.target.defaultValue}' ${localeStrings["sq.newquote.customer.vehicle-form.searchable_select_error"]}`;
    }

    this.setState(prevState => ({
      hasErrors: {
        ...prevState.hasErrors,
        [name]: errorFlag
      },
      errors: {
        ...prevState.errors,
        [name]: errorText
      }
    }));
  };

  // verify if form has at least one error
  validateFormErrors = () => {
    const { hasErrors } = this.state;
    let formIsValid = true;
    for (const fieldName of Object.keys(hasErrors)) {
      if (hasErrors[fieldName] === true) {
        formIsValid = false;
        break;
      }
    }
    return formIsValid;
  };

  updateHasError = (fieldName, fieldHasError) => {
    if (this.state.enableValidations) {
      this.setState({
        hasError: {
          ...this.state.hasError,
          [fieldName]: fieldHasError
        }
      });
    }
  };

  validateVin = value => {
    const { errors } = this.state;
    if (value.length === 0 || value.length === 17) {
      errors.vin = "";
      this.setState({ warning: "" });
    } else {
      errors.vin = "17 Characters Required";
    }
    this.setState({ errors });
  };

  onVinBlur = cxEvent => {
    const { value } = cxEvent.target;
    this.validateVin(value);
  };

  onVinChange = (cxEvent, isValid) => {
    this.setState({
      isValidVin: isValid
    });
    const { name, value } = cxEvent.target;
    // skip non-alphanumeric characters
    if (!isAlphaNumeric(value)) {
      return;
    }
    const { search, errors } = this.state;
    search[name] = value;
    this.setState({
      search,
      vin: value
    });
    if (isEmpty(value)) {
      errors.vin = "";
      this.setState({
        errors,
        warning: ""
      });
    }
  };

  decodeVinHandler = event => {
    if (event) event.preventDefault();
    const { vin, isValidVin } = this.state;
    const { localeStrings } = this.props.appConfig;

    if (vin.length !== 17) {
      this.setState({
        errors: {
          ...this.getErrors(),
          vin: localeStrings[
            "sq.newquote.customer.vehicle-form.vin_length_error"
          ]
        },
        hasErrors: this.getHasErrors(),
        warning: "",
        statusBox: {
          show: false
        }
      });
    } else if (!isValidVin) {
      return;
    } else {
      this.decodeVin(vin);
    }
  };
  markDirty = dirty => {
    this.setState({
      isDirty: dirty
    });
  };
  decodeVin(vin) {
    const { dealerCode } = this.props.appConfig;
    const { oldVin } = this.state;
    const restEndPoint = `/vehicle/${dealerCode}/decodevin/${vin}`;
    makeSecureRestApi(
      {
        url: restEndPoint,
        method: "get"
      },
      response => {
        const { success, data } = response;
        let { errors, hasErrors } = this.state;
        errors = this.getErrors();
        hasErrors = this.getHasErrors();
        this.markDirty(false);
        if (success) {
          this.resetVehicleSelector();
          // Check to verify xtime supported metaVechile record metaVehicle{} exist or not
          if (data && data.metaVehicle) {
            if (data.metaVehicle.vin !== oldVin) {
              this.markDirty(true);
            }
            errors.vin = "";
            const { metaVehicle } = data;
            const { vin } = metaVehicle;
            let vehicleDesc = {};
            if (!isEmpty(metaVehicle.vehicleDesc)) {
              vehicleDesc = metaVehicle.vehicleDesc;
            }
            if (Object.keys(vehicleDesc).length > 0) {
              const vehicleInfo = Object.assign({}, vehicleDesc);
              vehicleInfo.metaVehicleId = doesKeyExist(vehicleDesc.id)
                ? vehicleDesc.id
                : null;
              vehicleInfo.vin = vin;
              if (doesKeyExist(vehicleInfo.id)) delete vehicleInfo.id;
              this.setState(
                {
                  errors,
                  hasErrors,
                  warning: "",
                  vehicleInfo,
                  vin
                },
                () => {
                  this.loadPreviewCriteria(vehicleInfo, "VIN_DECODE");
                }
              );
            } else {
              // if vehicleDesc{} object is missing in response
              errors.vin = "";
              this.setState({
                errors,
                hasErrors,
                warning: "Not able to Decode this VIN"
              });
            }
            // Check used for valid VIN decoded from MOTOR data coxvin{}
          } else {
            let coxMake = "";
            if (!isEmpty(data.coxvin)) {
              coxMake = isEmpty(data.coxvin.make) ? "" : data.coxvin.make;
            }
            const warningText = `This VIN ${
              coxMake ? `number's make ${coxMake}` : "number"
            } is not supported for this dealer.`;
            errors.vin = warningText;
            this.setState({
              errors,
              hasErrors,
              warning: ""
            });
          }
        } else {
          // Case - when VIN is invalid with response status=200
          const { statusCode } = response;
          let { message } = response;
          if (statusCode === 1) {
            message = !message ? "An invalid VIN" : message;
          }
          errors.vin = message;
          this.setState({
            errors,
            hasErrors,
            warning: "",
            vehicleInfo: {}
          });
          return;
        }
      },
      error => {
        // Case: status = 500 are captured here
        let { message } = error;
        if (!message) {
          message = "Error in decoding vin";
        }
        let { errors, hasErrors } = this.state;
        hasErrors = this.getHasErrors();
        errors = this.getErrors();
        errors.vin = error.message;
        this.markDirty(true);
        this.setState({
          errors,
          hasErrors,
          warning: "",
          vehicleInfo: {}
        });
      },
      "Unable to decode VIN."
    );
  }

  onMakeChange = event => {
    const { name, value } = event.target;
    const { search, errors } = this.state;
    const { make } = search;
    const optionValue = value && value.length !== 0 ? value[0].value : "";
    if (optionValue === "" || optionValue === make) {
      return;
    }
    search[name] = optionValue;
    this.resetSearchListByMake();
    const warning = this.validateVinVehicleSelectors(search);
    errors.vin = "";
    this.setState({ warning, search, errors, vinDecode: false }, () => {
      if (optionValue !== "") {
        this.markDirty(true);
        this.findMake(optionValue);
      }
    });
  };

  onYearChange = cxEvent => {
    const { search, errors } = this.state;
    const { value } = cxEvent.target;
    const { year } = search;
    const optionValue = value && value.length !== 0 ? value[0].value : "";
    if (optionValue === year) {
      return;
    }
    search.year = optionValue;
    this.resetSearchListByYear();
    const warning = this.validateVinVehicleSelectors(search);
    errors.vin = "";
    this.setState({ warning, search, errors, vinDecode: false }, () => {
      if (optionValue !== "") {
        this.markDirty(true);
        this.loadModels();
      }
    });
  };

  onModelChange = cxEvent => {
    const value = cxEvent.target.value;
    const { search, errors } = this.state;
    const { model } = search;
    const optionValue = value && value.length !== 0 ? value[0].value : "";
    if (optionValue === model) {
      return;
    }
    this.resetSearchListByModel();
    const optionLabel =
      value && value.length !== 0 ? value[0].label : optionValue;
    search.model = optionLabel;
    const warning = this.validateVinVehicleSelectors(search);
    errors.vin = "";
    this.setState({ warning, search, errors, vinDecode: false }, () => {
      if (optionValue !== "") {
        this.markDirty(true);
        this.loadTrims();
      }
    });
  };

  onTrimChange = cxEvent => {
    const value = cxEvent.target.value;
    const { search, errors } = this.state;
    const { trim } = search;
    const optionValue = value && value.length !== 0 ? value[0].value : "";
    if (!optionValue || optionValue === trim) {
      return;
    }
    const optionLabel =
      value && value.length !== 0 ? value[0].label : optionValue;
    search.trim = optionLabel;
    this.resetSearchListByTrim();
    const warning = this.validateVinVehicleSelectors(search);
    errors.vin = "";
    this.setState({ warning, search, errors, vinDecode: false }, () => {
      if (optionValue !== "") {
        this.markDirty(true);
        this.loadEngineSizeTypes(search.trim);
      }
    });
  };

  onEngineSizeTypeChange = cxEvent => {
    const value = cxEvent.target.value;
    const { search, engineSizeTypes, errors } = this.state;
    const { engineSizeType } = search;
    const optionValue = value && value.length !== 0 ? value[0].value : "";
    // Special case: when random text typed, value becomes empty. it is invalid text to match with dropdown list.
    if (!optionValue || optionValue === engineSizeType) {
      return;
    }
    const optionLabel =
      value && value.length !== 0 ? value[0].label : optionValue;
    search.engineSizeType = optionLabel;
    this.resetSearchListByEngineSizeType();
    const sizeAndTypes = search.engineSizeType.split(",");
    search.engineSize = sizeAndTypes[0].trim();
    search.engineType = sizeAndTypes[1].trim();
    const warning = this.validateVinVehicleSelectors(search);
    errors.vin = "";
    this.setState({ warning, search, errors, vinDecode: false }, () => {
      if (optionValue !== "") {
        this.markDirty(true);
        this.loadDriveTypes(search.engineSizeType, engineSizeTypes);
      }
    });
  };

  onDriveTypeChange = cxEvent => {
    const value = cxEvent.target.value;
    const { search, driveTypes, errors } = this.state;
    const { driveType } = search;
    const optionValue = value && value.length !== 0 ? value[0].value : "";
    if (optionValue === driveType) {
      return;
    }
    this.resetSearchListByDriveType();
    const optionLabel =
      value && value.length !== 0 ? value[0].label : optionValue;
    search.driveType = optionLabel;
    const warning = this.validateVinVehicleSelectors(search);
    errors.vin = "";
    this.setState({ warning, search, errors, vinDecode: false }, () => {
      if (optionValue !== "") {
        this.markDirty(true);
        this.loadTransmissionTypes(search.driveType, driveTypes);
      }
    });
  };

  onTransmissionTypeChange = cxEvent => {
    const { search, errors } = this.state;
    const value = cxEvent.target.value;
    const { transmissionType } = search;
    const optionValue = value && value.length !== 0 ? value[0].value : "";
    if (optionValue === transmissionType) {
      return;
    }
    const optionLabel =
      value && value.length !== 0 ? value[0].label : optionValue;
    search.transmissionType = optionLabel;

    const drivingConditions =
      optionLabel && value[0].drivingConditions !== 0
        ? value[0].drivingConditions
        : [];

    search.drivingConditions = drivingConditions.map(drivingCondition => {
      return {
        value: drivingCondition.data,
        label: drivingCondition.label
      };
    });

    const warning = this.validateVinVehicleSelectors(search);
    errors.vin = "";
    this.setState({ warning, search, errors, vinDecode: false });
    this.markDirty(true);
    // TODO: we will call this if product asks to add driving condition field
    //   this.loadDrivingConditions(
    //       search.transmissionType,
    //       this.state.transmissionTypes
    //   );
  };
  // Note: Since mileage is not required field, no need to mark isDirty flag in state
  onMileageChange = (cxEvent, isValid, domEvent) => {
    const { value } = cxEvent.target;
    if (domEvent && domEvent.type === "blur") {
      return;
    }
    const { search } = this.state;
    // @note: please don't parse mileage value here, value can be empty i,e value = ""
    search.mileage = value;
    search.mileageDt = formatDateTimezone(new Date(), false, "vehicle");
    this.setState({
      search
    });
  };

  /* This method to find selected make record */
  findMake(makeName) {
    const rawMakes = this.state.makeslist;
    const property = "make";
    const selectedMake = findRecord(rawMakes, property, makeName);
    const { errors } = this.state;
    if (Object.keys(selectedMake).length > 0) {
      this.setState(
        prevState => {
          return {
            // vin: "",
            errors,
            // warning: "",
            selectedMake,
            search: {
              ...prevState.search,
              make: makeName,
              variant: selectedMake.variant,
              defaultDrivingCondition: selectedMake.defaultDrivingCondition
            },
            showMask: true
          };
        },
        () => {
          if (selectedMake && selectedMake.make) {
            this.loadYears(selectedMake);
          }
        }
      );
    }
  }

  loadYears(selectedMake) {
    const { dealerCode, locale } = this.props.appConfig;
    const { make, variant } = selectedMake;
    const headers = {
      Accept: "application/json"
    };
    const restEndPoint = "/vehicle/make/" + make + "/years/" + dealerCode;
    makeSecureRestApi(
      {
        url: restEndPoint,
        method: "get",
        params: {
          locale,
          variant
        },
        data: {},
        headers
      },
      data => {
        const { years } = data;
        const yearOptions = years.map(yr => {
          return { label: yr, value: yr };
        });
        // TODO: update state with selectedMake{} for decodeVin case
        this.setState(
          {
            selectedMake,
            years: yearOptions
          },
          () => {
            const { vinDecode } = this.state;
            if (vinDecode) {
              const { search, vehicleInfo } = this.state;
              // TODO: update search with variant from selectedMake{}
              search.variant = selectedMake.variant;
              search.year = vehicleInfo.year.toString();
              this.setState({ search });
              this.loadModels();
            }
          }
        );
      },
      error => {
        console.error(error.message);
      },
      "Unable to retrieve vehicle years."
    );
  }

  loadModels() {
    const { dealerCode, locale } = this.props.appConfig;
    const { search, selectedMake } = this.state;
    const { make, variant } = selectedMake;
    const headers = {
      Accept: "application/json"
    };
    const restEndPoint = "/vehicle/make/" + make + "/models/" + dealerCode;
    makeSecureRestApi(
      {
        url: restEndPoint,
        method: "get",
        params: {
          locale,
          variant,
          year: search.year
        },
        data: {},
        headers
      },
      data => {
        const { models } = data;
        const modelOptions = models.map(model => {
          return { label: model.label, value: model.data };
        });
        this.setState({ models: modelOptions }, () => {
          const { vinDecode } = this.state;
          if (vinDecode) {
            const { vehicleInfo } = this.state;
            if (modelOptions && modelOptions.length !== 0) {
              search.model = vehicleInfo.model;
              this.loadTrims();
              this.setState({ search });
            }
          } else if (modelOptions && modelOptions.length === 1) {
            this.onModelChange({
              target: { value: modelOptions }
            });
          }
        });
      },
      error => {
        console.error(error.message);
      },
      "Unable to retrieve vehicle models."
    );
  }

  loadTrims() {
    const { dealerCode, locale } = this.props.appConfig;
    const { search, selectedMake } = this.state;
    const { model, year } = search;
    const { make, variant } = selectedMake;
    const restEndPoint = `/vehicle/make/${make}/variant/${variant}/model/${model}/year/${year}/trims/${dealerCode}`;
    makeSecureRestApi(
      {
        url: restEndPoint,
        method: "get",
        params: {
          locale,
          useSkipTrim: 0
        }
      },
      data => {
        const { vehicleInfo } = data;
        const { trims } = vehicleInfo;
        if (!trims) {
          this.setState({ trims: [] });
          return;
        }
        const trimOptions = trims.map(trim => {
          trim.label = trim.display;
          trim.value = trim.data;
          return trim;
        });

        search.showDrivingCondition = vehicleInfo.showDrivingCondition;
        search.defaultDrivingCondition = vehicleInfo.defaultDrivingCondition;

        this.setState({ trims: trimOptions, search }, () => {
          const { vinDecode } = this.state;
          if (vinDecode) {
            if (trimOptions && trimOptions.length !== 0) {
              const { vehicleInfo } = this.state;
              console.log(
                "vindecode & edit case -> state.vehicleInfo",
                vehicleInfo
              );
              // TODO: DecodeVin case - set values to search criteria
              search.trim = vehicleInfo.trim;
              search.engineSize = vehicleInfo.engineSize;
              search.engineType = vehicleInfo.engineType;
              // TODO-erick: Edit vehicle case: test when vehicleInfo is null
              const engineSizeType =
                !vehicleInfo.engineSize && !vehicleInfo.engineType
                  ? ""
                  : vehicleInfo.engineSize.concat(",", vehicleInfo.engineType);
              search.engineSizeType = engineSizeType;
              search.driveType = vehicleInfo.driveType;
              search.transmissionType = vehicleInfo.transmissionType;
              // TODO: DecodeVin case - update selectors data using trim response
              const engineSizeTypes = this.loadEngineSizeTypes(search.trim);
              const driveTypes = this.loadDriveTypes(
                search.engineSizeType,
                engineSizeTypes
              );
              const transmissionTypes = this.loadTransmissionTypes(
                search.driveType,
                driveTypes
              );

              search.drivingConditions = getDrivingConditionsFromTrims(
                trimOptions,
                vehicleInfo
              );

              console.log("load transmissionTypes", transmissionTypes);
              // TODO-raja: uncomment this if product asks to add driving condition field
              // this.loadDrivingConditions(
              //   search.transmissionType,
              //   transmissionTypes
              // );
              this.setState({ search });
            }
          } else if (trimOptions && trimOptions.length === 1) {
            this.onTrimChange({
              target: { value: trimOptions }
            });
          }
        });
      },
      error => {
        console.error(error.message);
      },
      "Unable to retrieve vehicle trim options."
    );
  }

  loadEngineSizeTypes = trim => {
    const { trims } = this.state;
    const trimList = trims.filter(t => {
      return t.data === trim;
    });
    if (trimList.length === 0) {
      this.setState({ engineSizeTypes: [] });
      return [];
    }
    const engineTypeList = trimList[0].engineTypes;
    const engineSizeTypes = [];
    engineTypeList.forEach(engineType => {
      const { engineSizes } = engineType;
      engineSizes.forEach(engineSize => {
        const label = `${engineSize.display}, ${engineType.display}`;
        const value = `${engineSize.data},${engineType.data}`;
        engineSizeTypes.push({ label, value, engineType, engineSize });
      });
    });
    this.setState({ engineSizeTypes }, () => {
      if (engineSizeTypes && engineSizeTypes.length === 1) {
        this.onEngineSizeTypeChange({
          target: { value: engineSizeTypes }
        });
      }
    });
    return engineSizeTypes;
  };

  loadDriveTypes = (engineSizeType, engineSizeTypes) => {
    if (!engineSizeTypes || engineSizeTypes.length === 0) {
      this.setState({
        driveTypes: []
      });
      return [];
    }
    // * note: check is engineSizeType value has both engineSize, Type with delimiter
    const sizeAndType = !isEmpty(engineSizeType) && engineSizeType.split(",");
    const engineSize = sizeAndType[0].trim();
    const engineType = sizeAndType[1].trim();
    const engineSizeTypeList = engineSizeTypes.filter(t => {
      return (
        t.engineSize.data === engineSize && t.engineType.data === engineType
      );
    });

    if (engineSizeTypeList.length === 0) {
      this.setState({
        driveTypes: []
      });
      return [];
    }

    const driveTypeList = engineSizeTypeList[0].engineSize.driveTypes;
    const driveTypes = driveTypeList.map(driveType => {
      driveType.label = driveType.display;
      driveType.value = driveType.data;
      return driveType;
    });
    this.setState({ driveTypes }, () => {
      if (driveTypes && driveTypes.length === 1) {
        this.onDriveTypeChange({
          target: {
            value: driveTypes
          }
        });
      }
    });
    return driveTypes;
  };

  loadTransmissionTypes = (driveType, driveTypes) => {
    const driveTypeList = driveTypes.filter(t => {
      return t.data === driveType;
    });
    if (driveTypeList.length === 0) {
      this.setState({ transmissionTypes: [] });
      return [];
    }
    const transmissionTypeList = driveTypeList[0].transmissionTypes;
    const transmissionTypes = transmissionTypeList.map(transmissionType => {
      transmissionType.label = transmissionType.display;
      transmissionType.value = transmissionType.data;
      return transmissionType;
    });
    this.setState(
      {
        transmissionTypes,
        enableValidations: true
      },
      () => {
        if (transmissionTypes && transmissionTypes.length === 1) {
          this.onTransmissionTypeChange({
            target: { value: transmissionTypes }
          });
        }
      }
    );
    return transmissionTypes;
  };

  loadDrivingConditions = (transmissionType, transmissionTypes) => {
    const transmissionTypeList = transmissionTypes.filter(t => {
      return t.data === transmissionType;
    });
    if (transmissionTypeList.length === 0) {
      this.setState({ drivingConditions: [] });
      return [];
    }
    const drivingConditionList =
      transmissionTypeList && transmissionTypeList.length !== 0
        ? transmissionTypeList[0].drivingConditions
          ? transmissionTypeList[0].drivingConditions
          : []
        : [];
    const drivingConditions = drivingConditionList.map(drivingCondition => {
      drivingCondition.value = drivingCondition.data;
      return drivingCondition;
    });
    this.setState({ drivingConditions }, () => {
      if (drivingConditions && drivingConditions.length === 1) {
        this.onDrivingConditionChange({
          target: { value: drivingConditions }
        });
      }
    });
    return drivingConditions;
  };

  /*
  TODO: Bind VIN decode or Edit vehicle record only if vehicle's make matching dealer supported make
  */
  loadPreviewCriteria = (vehicleInfo, actionCode) => {
    const { localeStrings } = this.props.appConfig;
    const { search, errors } = this.state;
    let warning = "";
    search.make = vehicleInfo.make;
    search.variant = "";
    search.metaVehicleId = !vehicleInfo.metaVehicleId
      ? null
      : vehicleInfo.metaVehicleId;
    search.year =
      search.model =
      search.trim =
      search.engineSizeType =
      search.engineSize =
      search.engineType =
      search.driveType =
      search.transmissionType =
        "";
    this.setState(
      {
        vinDecode: true,
        search,
        vehicleInfo
      },
      () => {
        // Check: verify if VIN decoded Make matches with the dealer supported makes
        const { makeslist } = this.state;
        const supportedMakes = makeslist.filter(m => {
          return m.make === vehicleInfo.make;
        });
        if (supportedMakes.length !== 0) {
          const selectedMake = supportedMakes[0];
          // TODO: update state.search{} with make,variant using suppported Make
          search.make = selectedMake.make;
          search.variant = selectedMake.variant;
          this.setState(
            {
              selectedMake,
              search
            },
            () => {
              // TODO: years pulled only from dealer supported make when matched with Vin decoded make
              this.loadYears(selectedMake);
            }
          );
        } else {
          if (actionCode === "VIN_DECODE") {
            warning = localeStrings[
              "sq.newquote.vehicle.vin_unsupported_make_msg"
            ].replace("%1", vehicleInfo.make);
            errors.vin = warning;
            // TODO: validation: when VIN Decoded unsupported Make, disable save & reset fields make,year,model and dependent selectors
            search.make = "";
            search.variant = "";
            this.resetSearchListByMake();
            this.setState({
              search,
              errors,
              warning: "",
              statusBox: {
                show: false
              }
            });
          } else {
            // Special Case: when edit vehicle record is not matching dealer supported Make.
            // Fix: Form will be empty and show warning
            search.make = "";
            search.variant = "";
            this.resetSearchListByMake();
            warning = localeStrings[
              "sq.newquote.vehicle.unsupported_make_msg"
            ].replace("%1", vehicleInfo.make);
            this.setState({
              search,
              warning,
              statusBox: {
                show: false
              }
            });
          }
        }
      }
    );
  };

  // TODO: If dealer has one supported make, load years using dealer supported make only
  preSelectMake() {
    const rawMakes = this.state.makeslist;
    if (rawMakes && rawMakes.length === 1) {
      const selectedMake = rawMakes[0];
      if (Object.keys(selectedMake).length > 0) {
        const { make, variant } = selectedMake;
        // TODO: Case: When dealer has single Make, pre-select make and load years
        this.setState(
          prevState => {
            return {
              selectedMake,
              search: {
                ...prevState.search,
                make,
                variant,
                defaultDrivingCondition: selectedMake.defaultDrivingCondition
              }
            };
          },
          () => {
            this.loadYears(selectedMake);
          }
        );
      }
    }
  }

  // TODO-erick: This method will transform edit vehicle record and update in state to support preload selectors
  loadSelectors() {
    const { errors } = this.state;
    errors.vin = "";
    const warning = "";
    const { vehicle } = this.props;
    const vehicleInfo = transformVehicle(vehicle, VehicleSelectorTemplate);
    const vin = vehicleInfo.vin;
    this.setState({ errors, warning, vehicleInfo, vin }, () => {
      this.loadPreviewCriteria(vehicleInfo, "LOAD_SELECTORS");
    });
  }

  // Validator to return msg for decodeVin field
  validateVinVehicleSelectors(search) {
    const { localeStrings } = this.props.appConfig;
    const { vin, vehicleInfo } = this.state;
    if (search && vin && vehicleInfo) {
      const {
        make,
        year,
        model,
        trim,
        driveType,
        engineSize,
        engineType,
        transmissionType
      } = vehicleInfo;
      if (
        make !== search.make ||
        year !== search.year ||
        model !== search.model ||
        trim !== search.trim ||
        driveType !== search.driveType ||
        engineSize !== search.engineSize ||
        engineType !== search.engineType ||
        transmissionType !== search.transmissionType
      ) {
        return localeStrings["sq.newquote.vehicle.vin_notmatched_msg"];
      }
    }
    return "";
  }

  /* This function checks for error object has strings with {null,"", undefined} in given object
  and returns count for error strings. */
  hasErrorStrings(state) {
    const array1 = Object.values(state);
    const iterator = array1.values();
    let errCount = 0;
    for (const value of iterator) {
      if (value === "" || value === null) {
        // In case of valid error string
      } else if (value && typeof value === "string") {
        errCount++;
      }
    }
    console.log(errCount);
    return errCount === 0 ? false : true;
  }
  handleDiscardPopupProceed = async () => {
    await this.props.clearQuoteServices();
    this.handleEditFormSubmit();
  };
  handleDiscardPopupCancel = () => {
    this.setState({
      showDiscardPopup: false
    });
  };
  render() {
    const {
      vin,
      makeslist,
      years,
      models,
      trims,
      engineSizeTypes,
      driveTypes,
      transmissionTypes,
      errors,
      hasErrors,
      statusBox,
      vehicleFutureAppointment
    } = this.state;
    const {
      make,
      year,
      model,
      trim,
      engineSizeType,
      driveType,
      transmissionType,
      mileage
    } = this.state.search;
    const { localeStrings, defaultUnits } = this.props.appConfig;
    const discardPopup = (
      <ConfirmPopup
        title={localeStrings["sq.common.alert_lbl"]}
        message={localeStrings["sq.newquote.vehicle.edit_vehicle_discard_msg"]}
        show={this.state.showDiscardPopup}
        okText={localeStrings["sq.common.proceed_button"]}
        cancelText={localeStrings["sq.common.cancel_button"]}
        okAction={this.handleDiscardPopupProceed}
        cancelAction={this.handleDiscardPopupCancel}
        hideCancel={false}
        hideOk={false}
        buttonStyle="danger"
      />
    );
    const vehicleYMMPopup = (
      <ConfirmPopup
        title={localeStrings["sq.common.alert_lbl"]}
        message={localeStrings["sq.newquote.vehicle.ymm_warning"]}
        show={this.state.showYMMPopup}
        okText={localeStrings["sq.newquote.vehicle.proceed_services_lbl"]}
        cancelText={
          localeStrings["sq.newquote.vehicle.select_vehicle_attr_lbl"]
        }
        okAction={this.proceedToServices}
        cancelAction={this.continueToSaveVehicle}
        hideCancel={false}
        hideOk={false}
        specialCase={true}
        callbackClose={this.closeYMMPopup}
        cancelBtnStyle="primary"
        buttonStyle="primary"
      />
    );
    // TODO-erick: enable SAVE when required fields(make, year, model) are valid
    const isRequired = make === "" || year === "" || model === "";
    const saveDisabled = isRequired || this.verifyDirtyCheck();
    // const { localeStrings, defaultUnits } = this.props.appConfig;
    const { formType, vehicle } = this.props;
    const unitsLabel = !defaultUnits
      ? localeStrings["sq.newquote.vehicle.miles_lbl"]
      : capitalize(defaultUnits);

    const formattedFutureAppointment =
      vehicleFutureAppointment.hasFutureAppointments
        ? moment(vehicleFutureAppointment.appointmentDate).format(
            "MMMM D, YYYY | h:mm A"
          )
        : "";

    return (
      <>
        {vehicleFutureAppointment.hasFutureAppointments &&
        formType === EDIT_VEHICLE ? (
          <div className="margin-bottom-20">
            <Banner type="warning">
              <span>
                {
                  localeStrings[
                    "sq.newquote.vehicle.edit_vehicle_has_appointment_msg"
                  ]
                }
              </span>
              <span>{formattedFutureAppointment}</span>
            </Banner>
          </div>
        ) : null}
        <Card
          htmlId="decodeVinCard"
          header={localeStrings["sq.newquote.vehicle.lookup_vin_lbl"]}
        >
          <div className="sq-flex-form">
            <VinInput
              htmlId="decodeVinInput"
              label={localeStrings["sq.newquote.vehicle.decode_vin_lbl"]}
              name="vin"
              onChange={this.onVinChange}
              onPaste={() => this.setState({ errors: this.getErrors() })}
              onBlur={this.onVinBlur}
              value={vin}
              minLength={0}
              maxLength={17}
              error={errors.vin}
              autoComplete="off"
              disabled={
                // eslint-disable-next-line  react/jsx-no-leaked-render
                vehicleFutureAppointment.hasFutureAppointments && vehicle
              }
              appendChild={
                <Button
                  htmlId="decodeVinBtn"
                  buttonStyle="secondary"
                  onClick={e => this.decodeVinHandler(e)}
                  disabled={
                    // eslint-disable-next-line  react/jsx-no-leaked-render
                    vehicleFutureAppointment.hasFutureAppointments && vehicle
                  }
                >
                  {localeStrings["sq.newquote.vehicle.decode_lbl"]}
                </Button>
              }
            />
            <span className="sq-warning-label">{this.state.warning}</span>
          </div>
        </Card>
        <Card
          htmlId="vehicleFormCard"
          header={localeStrings["sq.newquote.vehicle.vehicle_info_lbl"]}
        >
          <form
            autoComplete="off"
            onSubmit={this.callbackSaveHandler}
            id="vehicleForm"
            className="sq-flex-form"
          >
            <SearchableSelect
              htmlId="makeSelect"
              name="make"
              label={localeStrings["sq.newquote.vehicle.make_lbl"]}
              placeholder={localeStrings["sq.common.select_lbl"]}
              enableMultiSelect={false}
              maxHeight={200}
              required
              value={make}
              options={makeslist}
              errorMessage={errors.make}
              hasError={hasErrors.make}
              onBlur={this.onSelectBlur}
              onChange={this.onMakeChange}
              disabled={
                // eslint-disable-next-line  react/jsx-no-leaked-render
                vehicleFutureAppointment.hasFutureAppointments && vehicle
              }
            />
            <SearchableSelect
              htmlId="yearSelect"
              name="year"
              label={localeStrings["sq.newquote.vehicle.year_lbl"]}
              placeholder={localeStrings["sq.common.select_lbl"]}
              enableMultiSelect={false}
              maxHeight={200}
              required
              value={year}
              options={years}
              errorMessage={errors.year}
              hasError={hasErrors.year}
              onBlur={this.onSelectBlur}
              onChange={this.onYearChange}
              disabled={
                // eslint-disable-next-line  react/jsx-no-leaked-render
                (vehicleFutureAppointment.hasFutureAppointments ||
                  years.length === 0) &&
                vehicle
              }
            />
            <SearchableSelect
              htmlId="modelSelect"
              name="model"
              label={localeStrings["sq.newquote.vehicle.model_lbl"]}
              placeholder={localeStrings["sq.common.select_lbl"]}
              enableMultiSelect={false}
              maxHeight={200}
              required
              value={model}
              options={models}
              errorMessage={errors.model}
              hasError={hasErrors.model}
              onBlur={this.onSelectBlur}
              onChange={this.onModelChange}
              disabled={
                // eslint-disable-next-line  react/jsx-no-leaked-render
                (vehicleFutureAppointment.hasFutureAppointments ||
                  models.length === 0) &&
                vehicle
              }
            />

            <SearchableSelect
              htmlId="trimSelect"
              name="trim"
              label={localeStrings["sq.newquote.vehicle.trim_lbl"]}
              placeholder={localeStrings["sq.common.select_lbl"]}
              enableMultiSelect={false}
              maxHeight={200}
              value={trim}
              options={trims}
              errorMessage={errors.trim}
              hasError={hasErrors.trim}
              onBlur={this.onSelectBlur}
              onChange={this.onTrimChange}
              disabled={
                // eslint-disable-next-line  react/jsx-no-leaked-render
                (vehicleFutureAppointment.hasFutureAppointments ||
                  trims.length === 0) &&
                vehicle
              }
            />
            <SearchableSelect
              htmlId="engineSizeTypeSelect"
              name="engineSizeType"
              label={localeStrings["sq.newquote.vehicle.enginesize_lbl"]}
              placeholder={localeStrings["sq.common.select_lbl"]}
              enableMultiSelect={false}
              maxHeight={200}
              value={engineSizeType}
              options={engineSizeTypes}
              errorMessage={errors.engineSizeType}
              hasError={hasErrors.engineSizeType}
              onBlur={this.onSelectBlur}
              onChange={this.onEngineSizeTypeChange}
              disabled={
                // eslint-disable-next-line  react/jsx-no-leaked-render
                (vehicleFutureAppointment.hasFutureAppointments ||
                  engineSizeTypes.length === 0) &&
                vehicle
              }
            />
            <SearchableSelect
              htmlId="driveTypeSelect"
              name="driveType"
              label={localeStrings["sq.newquote.vehicle.drivetype_lbl"]}
              placeholder={localeStrings["sq.common.select_lbl"]}
              enableMultiSelect={false}
              maxHeight={200}
              value={driveType}
              options={driveTypes}
              errorMessage={errors.driveType}
              hasError={hasErrors.driveType}
              onBlur={this.onSelectBlur}
              onChange={this.onDriveTypeChange}
              disabled={
                // eslint-disable-next-line  react/jsx-no-leaked-render
                (vehicleFutureAppointment.hasFutureAppointments ||
                  driveTypes.length === 0) &&
                vehicle
              }
            />
            <SearchableSelect
              htmlId="transmissionSelect"
              name="transmissionType"
              label={localeStrings["sq.newquote.vehicle.transmission_lbl"]}
              placeholder={localeStrings["sq.common.select_lbl"]}
              enableMultiSelect={false}
              maxHeight={200}
              value={transmissionType}
              options={transmissionTypes}
              errorMessage={errors.transmissionType}
              hasError={hasErrors.transmissionType}
              onBlur={this.onSelectBlur}
              onChange={this.onTransmissionTypeChange}
              disabled={
                // eslint-disable-next-line  react/jsx-no-leaked-render
                (vehicleFutureAppointment.hasFutureAppointments ||
                  transmissionTypes.length === 0) &&
                vehicle
              }
            />
            <NumericInput
              htmlId="mileageInput"
              label={localeStrings["sq.newquote.vehicle.mileage_lbl"]}
              inputSuffix={unitsLabel}
              name="mileage"
              autoInsertCommas
              autoComplete="off"
              onChange={this.onMileageChange}
              value={mileage}
              maxLength={7}
            />

            <div className="pull-left">
              <StatusBox
                htmlId="statusBox"
                type={statusBox.type}
                autoClose={statusBox.autoClose}
                linkHtml={null}
                message={statusBox.message}
                autoCloseTime={1500}
                errorInTooltip={statusBox.errorInTooltip}
              />
            </div>

            <div className="cust-flex-bgroup">
              <span
                className={this.state.isNewCustomer ? "hide" : "hand-cursor"}
                id="cancelVehicleButton"
                onClick={this.onCancelHandler}
              >
                {localeStrings["sq.common.cancel_button"]}
              </span>

              <Button
                htmlId="saveVehicleButton"
                buttonStyle="primary"
                type="submit"
                disabled={saveDisabled}
                // onClick={e => this.callbackSaveHandler(e)}
              >
                {formType === EDIT_VEHICLE
                  ? localeStrings["sq.newquote.vehicle.update_vehicle_lbl"]
                  : localeStrings["sq.newquote.vehicle.save_vehicle_lbl"]}
              </Button>
            </div>
          </form>
        </Card>
        {discardPopup}
        {vehicleYMMPopup}
      </>
    );
  }
}

const VehicleFormClassWrapper = props => {
  const appContext = useContext(AppContext);
  const { state, dispatch } = useNewQuoteContext();
  const { makes, customer, vehicle, quoteSummary, vehicleFutureAppointment } =
    state;
  const formType = state.currentPage;
  // TODO-erick - we will cleanup this props and wrapper once we get product design approved
  const {
    schemaName,
    webKey,
    appSource,
    appEnv,
    dealer,
    user,
    locale,
    localeStrings
  } = appContext;
  const appConfig = {
    schemaName,
    webKey,
    appSource,
    appEnv,
    dealerCode: dealer.dealerCode,
    defaultUnits: dealer.defaultUnits,
    user,
    locale,
    localeStrings
  };

  useEffect(() => {
    let pageTitle = "";
    if (formType === ADD_VEHICLE) {
      pageTitle = localeStrings["sq.newquote.customer.add_vehicle_lbl"];
    } else if (formType === SKIP_CUSTOMER) {
      pageTitle = localeStrings["sq.newquote.customer.select_vehicle_lbl"];
    } else if (formType === EDIT_VEHICLE) {
      pageTitle = localeStrings["sq.newquote.customer.edit_vehicle_lbl"];
    }

    dispatch({
      type: Actions.SET_PAGE_TITLE,
      payload: pageTitle
    });
  }, [dispatch, formType, localeStrings]);

  const handleOnCancel = () => {
    dispatch({
      type: Actions.NAVIGATION.GO_BACK
    });
  };

  const redirectAfterSave = (type = null) => {
    const { quoteServices } = quoteSummary;
    /**
     * Case 1: Customer is not present for Skip Customer flow {when same vehicle is updated many times}
     * Case 2: Customer is present for New Quote flow, when is Vehicle added or updated for New Quote {quoteServices will be dropped when vehicle attributes are updated}
     */
    const redirectPage = () => {
      if (type === SKIP_CUSTOMER && isEmpty(customer)) {
        return isEmpty(quoteServices) ? SEARCH_SERVICE : SERVICE_SUMMARY;
      }
      if (!isEmpty(customer)) {
        return isEmpty(quoteServices) ? SEARCH_SERVICE : SERVICE_SUMMARY;
      }
    };
    dispatch({
      type: Actions.SET_CURRENT_PAGE,
      payload: redirectPage()
    });
  };
  // TODO-rule:  When New customer is saved, update isNewCustomer=false when first vehicle saved. default case: existing customer will have false
  const updateNewCustomerStatus = () => {
    dispatch({
      type: Actions.SET_IS_NEW_CUSTOMER,
      payload: false
    });
  };
  const getPayTypeDetails = async (dealerCode, make) => {
    let payTypeObject;
    try {
      payTypeObject = await VehicleService.getPayTypeInformation(
        make,
        dealerCode
      );
    } catch (error) {
      payTypeObject = [];
      console.log("Call to getPaytype api failed  with error", error);
    }
    dispatch({
      type: Actions.SET_PAY_TYPES,
      payload: payTypeObject.payTypes
    });
  };
  const getServiceTypeDetails = async (dealerCode, make, dmsType) => {
    let serviceTypeObject = [];
    if (!isDMSPlus(dmsType)) return serviceTypeObject;
    try {
      serviceTypeObject = await VehicleService.getServiceTypeInformation(
        make,
        dealerCode
      );
    } catch (error) {
      serviceTypeObject = [];
      console.log("Call to getServiceType api failed with error", error);
    }
    dispatch({
      type: Actions.SET_SERVICE_TYPES,
      payload: serviceTypeObject?.serviceTypes || []
    });
  };
  // const getVendorListDetails = async (dealerCode, make) => {
  //   let vendorList;
  //   try {
  //     vendorList = await VehicleService.getVendorList(make, dealerCode);
  //   } catch (error) {
  //     vendorList = [];
  //     console.log("Call to getVendorList api failed  with error", error);
  //   }
  //   dispatch({
  //     type: Actions.SET_VENDOR_LIST,
  //     payload: vendorList
  //   });
  // };
  const updateVehicle = vehicle => {
    if (!isEmpty(vehicle)) {
      getPayTypeDetails(dealer.dealerCode, vehicle.make);
      getServiceTypeDetails(dealer.dealerCode, vehicle.make, dealer.dmsType);
      // TODO: uncomment this lines once vendors API is working
      // getVendorListDetails(dealer.dealerCode, vehicle.make);
      if (!vehicle.variant) {
        vehicle.variant = getVariantForVehicle(vehicle, makes);
      }
      dispatch({
        type: Actions.SET_VEHICLE,
        payload: vehicle
      });
      dispatch({
        type: Actions.UPDATE_VEHICLE,
        payload: vehicle
      });
    }
    dispatch({
      type: Actions.CLEAR_ALACARTE_SERVICES
    });
  };
  const clearQuoteServices = async () => {
    if (!isEmpty(quoteSummary)) {
      quoteSummary.quoteServices = [];
    }
    dispatch({
      type: Actions.UPDATE_QUOTE_SERVICE,
      payload: quoteSummary
    });
    removeQuoteServices();
  };
  // remove services from quote using rest call
  const removeQuoteServices = async () => {
    try {
      const response = await quoteService.clearQuoteServices({
        appContext,
        customer,
        vehicle,
        quoteSummary
      });
      let quoteResponse = null;
      if (!isEmpty(response)) {
        const { quoteId, confirmationId } = response;
        let message = "";
        if (!confirmationId && !quoteId) {
          message =
            "Services are not available for this vehicle at this time.  Please check back later.";
          quoteResponse = {
            quoteId: null,
            confirmationId: null,
            quoteServices: [],
            message
          };
        } else {
          if (isEmpty(response.quoteServices)) response.quoteServices = [];
          response.message = "";
          quoteResponse = Object.assign({}, response);
        }
        dispatch({
          type: Actions.UPDATE_QUOTE,
          payload: quoteResponse
        });
      }
    } catch (error) {
      const msg = error["message"]
        ? error.message
        : localeStrings["sq.errors.network.error"];
      console.error(msg);
    }
  };
  return (
    <VehicleFormClass
      {...props}
      appConfig={appConfig}
      vehicle={vehicle}
      formType={formType}
      updateVehicle={updateVehicle}
      updateNewCustomerStatus={updateNewCustomerStatus}
      redirectAfterSave={redirectAfterSave}
      onCancel={handleOnCancel}
      clearQuoteServices={clearQuoteServices}
      quoteServices={quoteSummary.quoteServices}
      vehicleFutureAppointment={vehicleFutureAppointment}
    />
  );
};

export default VehicleFormClassWrapper;

VehicleFormClassWrapper.propTypes = {
  appConfig: PropTypes.object,
  clearQuoteServices: PropTypes.func,
  formType: PropTypes.string,
  onCancel: PropTypes.func,
  redirectAfterSave: PropTypes.func,
  updateNewCustomerStatus: PropTypes.func,
  updateVehicle: PropTypes.func,
  vehicle: PropTypes.object
};
