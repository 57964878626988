import { makeSecureRestApi } from "../../../api/xmmAxios";
// eslint-disable-next-line unused-imports/no-unused-imports
import * as ModelsTypes from "../../../@types/models.types";
/**
 * @description Fetches the service types from the api.
 * @param {string} appEnv
 * @param {string} appSource
 * @param {string} numberOfDays
 * @param {string} quoteStatus
 * @returns {Promise<ModelsTypes.ServiceTypes>}
 */

const getServiceTypes = (numberOfDays, dealerCode, quoteStatus) => {
  const headers = {
    Accept: "application/json"
  };

  let filteredQuoteStatus = "";

  if (quoteStatus && quoteStatus !== "ALL") {
    filteredQuoteStatus = quoteStatus;
  }

  const restEndPoint = `pastQuotes/${dealerCode}/serviceTypes/`;

  return new Promise((resolve, reject) => {
    makeSecureRestApi(
      {
        url: restEndPoint,
        method: "get",
        data: {},
        params: {
          numberOfDays,
          ...(filteredQuoteStatus ? { statuses: filteredQuoteStatus } : {})
        },
        headers
      },
      response => {
        resolve(response);
      },
      error => {
        reject(error);
      },
      "Unable to retrieve service types."
    );
  });
};

export default {
  getServiceTypes
};
