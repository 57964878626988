/* eslint-disable no-console */
import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import IconSettings from "@cx/ui/Icons/IconSettings";
import IconNotification from "@cx/ui/Icons/IconNotification";
import Dropdown from "@cx/ui/Dropdown";

import AboutModal from "./AboutModal";
import DealerSettingsModal from "../../../dealer-settings/DealerSettingsModal";
import { DealerModal } from "../../../../pages";
import { redirectToDefaultPage, getLoginUrl } from "../../../../api/xmmAxios";
import { AppContext } from "../../../../state/app-context";
import FeedbackTool from "../../../feedback-tool";
import quoteStatus from "../../../../constants/quote-status.constants";
import { useNewQuoteContext } from "../../../../state/NewQuoteContext";
import DiscardQuoteModal from "../../modals/DiscardQuoteModal";
import useExternalScriptTags from "../../../../hooks/useExternalScriptTags";
import {
  checkIfIsCSR,
  createBeamerConfigurationObjectString
} from "../../../../api/app.util";

const Header = ({ user, appType }) => {
  const { localeStrings, dealer, scopedDealers, userPermissions } =
    useContext(AppContext);

  // const [collapsed, setCollaped] = useState(true);
  const [showAbout, setShowAbout] = useState(false);
  const [showDealerSettings, setShowDealerSettings] = useState(false);
  const [showDealers, setShowDealers] = useState(false);
  const [showFeedback, setShowFeedback] = useState(false);
  const [showDiscardQuoteModal, setShowDiscardQuoteModal] = useState(false);
  const { state } = useNewQuoteContext();
  const currentQuoteStatus = state?.quoteSummary?.quoteStatus || "";

  const baseLoginURL = getLoginUrl();
  const isCSR = checkIfIsCSR(appType);
  const beamerConfig = createBeamerConfigurationObjectString(dealer, user);
  useExternalScriptTags(
    "https://app.getbeamer.com/js/beamer-embed.js",
    beamerConfig
  );

  const openTrainingPage = () => {
    const modifiedLoginUrl = baseLoginURL.replace(
      /login(qa5|ua9|stg4|pt3)\./,
      "login."
    );
    const loginUrl = `${modifiedLoginUrl}/?pt=ht`;

    // target window reference
    let winRef = null;
    if (winRef && !winRef.closed) {
      // Window is open, refresh it
      winRef.location.reload(true);
    } else {
      winRef = window.open(loginUrl, "_XtimeUniversity");
    }
  };

  const openSupportPage = () => {
    const supportUrl = "https://xsupport-tools.com/na-support/";

    // target window reference
    let winRef = null;
    if (winRef && !winRef.closed) {
      // Window is open, refresh it
      winRef.location.reload(true);
    } else {
      winRef = window.open(supportUrl, "_CustomerSupport");
    }
  };
  // Event deprecated as below
  // const onDropdownSelect = (cxEvent, domEvent) => {
  //   onItemSelect(cxEvent, domEvent);
  // };

  const openAboutUsDialog = () => {
    setShowAbout(true);
  };

  const closeAboutUsDialog = () => {
    setShowAbout(false);
  };

  const openDealerSettingsModal = () => {
    setShowDealerSettings(true);
  };

  const closeDealerSettingsModal = () => {
    setShowDealerSettings(false);
  };

  // eslint-disable-next-line unused-imports/no-unused-vars
  const openFeedbackModal = () => {
    setShowFeedback(true);
  };

  const closeFeedbackModal = () => {
    setShowFeedback(false);
  };

  const handleConfirmPopupOkClick = () => {
    setShowDiscardQuoteModal(false);
    setShowDealers(true);
  };

  const handleConfirmPopupCancelClick = () => {
    setShowDiscardQuoteModal(false);
  };

  const onLogout = () => {
    const hostname = window.location.hostname;
    if (hostname.indexOf("localhost") !== -1) {
      window.location.replace(window.location.href.split("?")[0]);
    } else {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
      redirectToDefaultPage();
    }
  };
  const options = [
    {
      divider: true,
      label: localeStrings["sq.nav.about_lbl"],
      onSelect: openAboutUsDialog,
      value: "about"
    },
    /* {
      divider: true,
      label: localeStrings["sq.nav.feedback_lbl"],
      onSelect: openFeedbackModal,
      value: "feedback"
    },*/
    {
      label: localeStrings["sq.nav.dealer_settings_lbl"],
      onSelect: openDealerSettingsModal,
      value: "dealer-settings",
      disabled: !userPermissions.canAccessDealerQuoteSettings
    },
    {
      label: localeStrings["sq.nav.customer_support_lbl"],
      onSelect: openSupportPage,
      value: "customer-support"
    },
    {
      divider: true,
      label: localeStrings["sq.nav.training_lbl"],
      onSelect: openTrainingPage,
      value: "training"
    },
    /* {
      label: localeStrings["sq.nav.user_settings_lbl"],
      onSelect: onDropdownSelect,
      value: "user-settings"
    },*/
    {
      divider: true,
      label: localeStrings["sq.common.logout"],
      onSelect: onLogout,
      value: "logout"
    },
    {
      disabled: true,
      label: user.userName,
      value: "none"
    }
  ];

  if (isCSR) {
    options.splice(4, 1);
    options.splice(1, 1);
  }

  // remove condition when testing is finished
  // isDebug ? options : options.splice(1, 1);

  const aboutUsModal = (
    <AboutModal show={showAbout} closeDialog={closeAboutUsDialog} />
  );
  const dealerSettingsModal = showDealerSettings ? (
    <DealerSettingsModal
      show={showDealerSettings}
      closeDialog={closeDealerSettingsModal}
    />
  ) : null;

  const showDealerSelector = () => {
    if (scopedDealers.length === 1) return;
    if (currentQuoteStatus === quoteStatus.PROGRESS) {
      setShowDiscardQuoteModal(true);
    } else {
      setShowDealers(true);
    }
  };
  const closeDealerSelector = () => {
    setShowDealers(false);
  };
  const okFeedbackAction = () => {
    console.log("Feedback modal ok action triggered");
  };
  let feedbackModal = null;
  if (showFeedback) {
    feedbackModal = (
      <FeedbackTool
        screenName="Service Quoting"
        show={showFeedback}
        okAction={okFeedbackAction}
        closeModal={closeFeedbackModal}
      />
    );
  }
  let dealerModal = null;
  if (showDealers) {
    dealerModal = (
      <DealerModal show={showDealers} closeModal={closeDealerSelector} />
    );
  }
  const caretIcon =
    scopedDealers.length > 1 ? (
      <i className="fa fa-chevron-down" aria-hidden="true" />
    ) : (
      ""
    );
  const dealerName = !dealer.dealerName ? "" : dealer.dealerName;
  const clsNameContainer =
    scopedDealers.length > 1
      ? "header-dealer-name-container hand-cursor"
      : "header-dealer-name-container";
  const clsDealerName =
    scopedDealers.length > 1
      ? "head-dealer-name hand-cursor"
      : "head-dealer-name";
  const clsCaret =
    scopedDealers.length > 1 ? "head-caret hand-cursor" : "head-caret";
  return (
    <>
      <div className="header-flex">
        <div className="fixed-cell">
          <img
            alt="Xtime Icon"
            className="head-logo"
            src={require("../../../../images/xtime-logo-small.png")}
          />
          <div className="head-name">
            <span>
              {
                localeStrings[
                  isCSR ? "csr.common.app_name" : "sq.common.app_name"
                ]
              }
            </span>
          </div>
        </div>
        <div className="fluid-cell">
          <div className={clsNameContainer}>
            <div className={clsDealerName} onClick={showDealerSelector}>
              {dealerName}
            </div>
            <span className={clsCaret} onClick={showDealerSelector}>
              {caretIcon}
            </span>
          </div>
          <div
            className="beamer-block beamerTrigger hand-cursor"
            data-beamer-keypress="true"
            id="callBeamer"
          >
            <IconNotification htmlId="bellIcon" />
          </div>

          <Dropdown
            htmlId="userSettings"
            name="userSettings"
            className="head-setting-btn"
            displayCaret={false}
            icon={
              <IconSettings htmlId="settingsIcon" data-beamer-click="false" />
            }
            pullRight
            options={options}
          />
        </div>
      </div>
      {aboutUsModal}
      {dealerSettingsModal}
      {dealerModal}
      {feedbackModal}

      <DiscardQuoteModal
        onProceed={handleConfirmPopupOkClick}
        onCancel={handleConfirmPopupCancelClick}
        showModal={showDiscardQuoteModal}
      />
    </>
  );
};

Header.propTypes = {
  user: PropTypes.object.isRequired,
  appType: PropTypes.string
};

export default Header;
