const MAXIMUM_DISCOUNT_APPLIED = "MAXIMUM_DISCOUNT_APPLIED";
const MAXIMUM_DOLLAR_DISCOUNT_APPLIED = "MAXIMUM_DOLLAR_DISCOUNT_APPLIED";
const DISCOUNT_CANNOT_BE_APPLIED = "DISCOUNT_CANNOT_BE_APPLIED";
const DISCOUNT_CANNOT_BE_APPLIED_WITH_EXISTING =
  "DISCOUNT_CANNOT_BE_APPLIED_WITH_EXISTING";
const MAXIMUM_LIMIT_EXCEEDED = "Maximum limit exceeded";

const DISCOUNT_TYPES = {
  PERCENT: "Percent",
  DOLLAR: "Dollar",
  ARV: "Actual Retail Value",
  VARIABLE: "Variable"
};

export {
  DISCOUNT_TYPES,
  MAXIMUM_DISCOUNT_APPLIED,
  MAXIMUM_DOLLAR_DISCOUNT_APPLIED,
  DISCOUNT_CANNOT_BE_APPLIED,
  DISCOUNT_CANNOT_BE_APPLIED_WITH_EXISTING,
  MAXIMUM_LIMIT_EXCEEDED
};
