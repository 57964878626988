import React, { useCallback, useEffect, useState } from "react";

import PropTypes from "prop-types";

import SelectInput from "@cx/ui/SelectInput";
import FeeTotalRow from "./fee-total-row.component";
import FeeNotSelectedComponent from "./fee-not-selected.component";
import format from "../../../utils/format";
import cloneDeep from "lodash/cloneDeep";
import Alert from "@cx/ui/Alert";
import PriceInput from "@cx/ui/PriceInput";
import {
  ADD_CATALOG_LINE_FEE,
  ADD_CATALOG_RO_FEE,
  FEES_TYPES,
  MODIFY_CATALOG_LINE_FEE,
  MODIFY_CATALOG_RO_FEE
} from "../../../constants/adjustment.constant";
import isArray from "lodash/isArray";
import {
  checkExpiredFeeOrDiscount,
  checkStartDateValidFeeOrDiscount
} from "../../../../page-wrapper/utils/quote-util";
import ROFeePayerSelectComponent from "./ro-fee-payer-select.component";

const FeeRenderContentComponent = ({
  onUpdatePayload = () => {},
  availableFeeOptions,
  service,
  selectedFeeModify,
  priceAdjustmentType,
  quoteSummary
}) => {
  const [feeDropDownOptions, setFeeDropDownOptions] = useState([]);
  const [selectedFee, setSelectedFee] = useState(selectedFeeModify || null);

  const isROFeeAlreadyAdded = useCallback(
    fee => {
      return quoteSummary?.payers?.some(
        payer =>
          payer?.catalogFees &&
          payer?.catalogFees.some(
            catalogFee =>
              catalogFee?.dealerFeesId + "" === fee.dealerFeesId + ""
          )
      );
    },
    [quoteSummary?.payers]
  );

  const setFeeOptionsForServiceLine = useCallback(() => {
    const options = [];
    availableFeeOptions?.map(fee => {
      const payTypesSupported = isArray(fee?.payTypes)
        ? fee?.payTypes
        : fee?.payTypes?.split(",");
      const isSerivicePayTypeSupported = payTypesSupported.findIndex(
        type => type === service?.payTypeCode
      );
      const isFeeAlreadyAdded = service?.catalogFees.findIndex(
        serviceFee => serviceFee?.dealerFeesId + "" === fee?.dealerFeesId + ""
      );
      if (
        isSerivicePayTypeSupported !== -1 &&
        isFeeAlreadyAdded === -1 &&
        fee?.applyToOverallTotal !== 1 &&
        checkExpiredFeeOrDiscount(fee) &&
        checkStartDateValidFeeOrDiscount(fee)
      ) {
        const option = {};
        option.label = fee.description;
        option.value = fee.dealerFeesId + "";
        options.push(option);
      }
    });
    // for adding selected fee in dropdown when modifing fees
    if (selectedFeeModify !== null) {
      const feeOption = {};
      feeOption.label = selectedFeeModify?.description;
      feeOption.value = selectedFeeModify?.dealerFeesId;
      options.push(feeOption);
    }
    setFeeDropDownOptions(options);
  }, [
    availableFeeOptions,
    selectedFeeModify,
    service?.catalogFees,
    service?.payTypeCode
  ]);

  const setFeeOptionsForRO = useCallback(() => {
    const options = [];
    availableFeeOptions?.map(fee => {
      const feePayTypes = isArray(fee?.payTypes)
        ? fee?.payTypes
        : fee?.payTypes?.split(",");

      const isFeePayTypeSupportedInRo = isPayTypePresent(
        feePayTypes,
        quoteSummary?.payers
      );

      const isFeeAlreadyAddedForRO = isROFeeAlreadyAdded(fee);
      if (
        fee?.applyToOverallTotal === 1 &&
        isFeePayTypeSupportedInRo &&
        !isFeeAlreadyAddedForRO &&
        checkExpiredFeeOrDiscount(fee) &&
        checkStartDateValidFeeOrDiscount(fee)
      ) {
        const option = {};
        option.label = fee.description;
        option.value = fee.dealerFeesId + "";
        options.push(option);
      }
    });
    // for adding selected fee in dropdown when modifing fees
    if (selectedFeeModify !== null) {
      const feeOption = {};
      feeOption.label = selectedFeeModify?.description;
      feeOption.value = selectedFeeModify?.dealerFeesId;
      options.push(feeOption);
    }
    setFeeDropDownOptions(options);
  }, [
    availableFeeOptions,
    isROFeeAlreadyAdded,
    quoteSummary?.payers,
    selectedFeeModify
  ]);

  const isPayTypePresent = (payTypes, payerObject) => {
    return payerObject?.some(payer => payTypes.includes(payer.payType));
  };

  useEffect(() => {
    //* creating feesType dropdown options for both add and modify flow
    if (availableFeeOptions) {
      if (
        priceAdjustmentType === ADD_CATALOG_RO_FEE ||
        priceAdjustmentType === MODIFY_CATALOG_RO_FEE
      ) {
        setFeeOptionsForRO();
      }
      if (
        priceAdjustmentType === ADD_CATALOG_LINE_FEE ||
        priceAdjustmentType === MODIFY_CATALOG_LINE_FEE
      ) {
        setFeeOptionsForServiceLine();
      }
    }
  }, [
    availableFeeOptions,
    priceAdjustmentType,
    setFeeOptionsForRO,
    setFeeOptionsForServiceLine
  ]);

  useEffect(() => {
    onUpdatePayload(selectedFee);
  }, [onUpdatePayload, selectedFee]);

  useEffect(() => {
    if (priceAdjustmentType === MODIFY_CATALOG_RO_FEE) {
      const updatedSelectedModifyFee = cloneDeep(selectedFeeModify);
      const payer = quoteSummary?.payers?.find(
        payer => payer?.payType === selectedFeeModify?.feeDiscountPayerPayCode
      );
      updatedSelectedModifyFee.payerObject = payer;
      setSelectedFee(updatedSelectedModifyFee);
    }
  }, [priceAdjustmentType, quoteSummary?.payers, selectedFeeModify]);

  const getTotalServicePrice = () => {
    const totalPrice = service?.finalLaborPrice ?? 0;
    return totalPrice;
  };

  const onTypeChange = event => {
    let fee = availableFeeOptions?.find(
      fee => fee?.dealerFeesId + "" === event?.target?.value + ""
    );
    //* for retaining value of fee in modify flow when feesType changes
    if (
      selectedFeeModify &&
      fee?.dealerFeesId + "" === selectedFeeModify?.dealerFeesId
    ) {
      fee = selectedFeeModify;
    }

    // * For RO level fee needs to get payer Object for fee
    if (
      priceAdjustmentType === ADD_CATALOG_RO_FEE ||
      priceAdjustmentType === MODIFY_CATALOG_RO_FEE
    ) {
      const payerForFee = getPayerObject(fee);
      fee.payerObject = payerForFee;
      fee.feeDiscountPayerPayCode = payerForFee?.payType;
    }
    const updatedFee = modifyFee(fee);
    setSelectedFee(updatedFee);
  };

  const getPayerObject = updatedFee => {
    const payers = quoteSummary?.payers;
    const feePayTypes = isArray(updatedFee?.payTypes)
      ? updatedFee?.payTypes
      : updatedFee?.payTypes?.split(",");
    const isCustomerPayAvailableInFeeAndPayer =
      payers?.some(payer => payer?.payType === "C") &&
      feePayTypes?.some(type => type === "C");

    const isWarrantyPayAvailableInFeeAndPayer =
      payers?.some(payer => payer?.payType === "W") &&
      feePayTypes?.some(type => type === "W");

    const isInternalPayAvailableInFeeAndPayer =
      payers?.some(payer => payer?.payType === "I") &&
      feePayTypes?.some(type => type === "I");

    const payerPaytype = isCustomerPayAvailableInFeeAndPayer
      ? "C"
      : isWarrantyPayAvailableInFeeAndPayer
      ? "W"
      : isInternalPayAvailableInFeeAndPayer
      ? "I"
      : null;

    const feePayer = payers?.find(payer => payer?.payType === payerPaytype);
    return feePayer;
  };

  const modifyFee = fee => {
    switch (fee?.feesType) {
      case FEES_TYPES.DOLLAR:
        fee.appliedFee = fee.dollarAmount;
        break;
      case FEES_TYPES.PERCENT:
        if (
          priceAdjustmentType === ADD_CATALOG_RO_FEE ||
          priceAdjustmentType === MODIFY_CATALOG_RO_FEE
        ) {
          fee.appliedFee = Math.min(
            (fee?.payerObject?.subTotalLineLaborBasePrice * fee?.percentage) /
              100,
            fee?.feeMax
          );
        } else {
          fee.appliedFee = Math.min(
            (getTotalServicePrice() * fee?.percentage) / 100,
            fee?.feeMax
          );
        }
        break;
      case FEES_TYPES.VARIABLE:
        fee.appliedFee =
          selectedFeeModify &&
          selectedFeeModify?.dealerFeesId === fee?.dealerFeesId + ""
            ? selectedFeeModify?.appliedFee
            : 0;
        break;
    }
    return fee;
  };

  const onFeeAmountChange = event => {
    const fee = cloneDeep(selectedFee);
    fee.appliedFee = parseFloat(event?.target.value || 0);
    if (fee.feesType === FEES_TYPES.VARIABLE) {
      fee.dollarAmount = parseFloat(event?.target.value);
    }
    setSelectedFee(fee);
  };

  const getAdjustedPrice = () => {
    let adjustedPrice = 0;
    if (priceAdjustmentType === ADD_CATALOG_RO_FEE) {
      adjustedPrice = parseFloat(
        selectedFee?.payerObject?.subTotalBeforeTaxes +
          parseFloat(selectedFee?.appliedFee)
      );
    } else if (priceAdjustmentType === MODIFY_CATALOG_RO_FEE) {
      adjustedPrice = parseFloat(
        selectedFee?.payerObject?.subTotalBeforeTaxes -
          parseFloat(selectedFeeModify?.appliedFee) +
          parseFloat(selectedFee?.appliedFee)
      );
    } else {
      if (selectedFeeModify) {
        adjustedPrice = parseFloat(
          (service?.lineServicePrice ?? service?.servicePrice ?? 0) -
            parseFloat(selectedFeeModify?.appliedFee) +
            parseFloat(selectedFee?.appliedFee)
        );
      } else
        adjustedPrice = parseFloat(
          (service?.lineServicePrice ?? service?.servicePrice ?? 0) +
            parseFloat(selectedFee?.appliedFee)
        );
    }
    return adjustedPrice;
  };

  const getErrorMessage = () => {
    let errorMessage = null;
    if (selectedFee === null && feeDropDownOptions?.length !== 0) {
      errorMessage = "Select a fees to see details here";
    }
    if (selectedFee === null && feeDropDownOptions?.length === 0) {
      errorMessage = "No fees available to add";
    }
    return errorMessage;
  };

  return (
    <div className="discount-container">
      <SelectInput
        htmlId="typeDropdown"
        name="typeDropdown"
        label=""
        onChange={onTypeChange}
        disabled={feeDropDownOptions.length === 0}
        value={
          (selectedFee?.description && selectedFee?.description + "") || ""
        }
        placeholder="Select Fees"
        options={feeDropDownOptions}
        displayDeselectOption={false}
      />
      {selectedFee ? (
        <>
          <span className="catalog-fee-numeric-input-description">
            {selectedFee?.feesType} Fee{" "}
            {selectedFee?.feesType === FEES_TYPES.VARIABLE ||
            selectedFee?.feesType === FEES_TYPES.PERCENT
              ? selectedFee?.feeMax && (
                  <span
                    className={` ${
                      selectedFee?.appliedFee >= selectedFee?.feeMax
                        ? "Ferrari500"
                        : ""
                    }`}
                  >
                    - {format.currency(selectedFee?.feeMax)} Max
                  </span>
                )
              : ""}
          </span>
          {selectedFee?.feesType === FEES_TYPES.VARIABLE ? (
            <PriceInput
              autoFocus
              label="Fees Amount"
              htmlId="feesAmount"
              name="feesAmount"
              className="discount-type-input"
              allowDecimal={true}
              decimalMaxLength={2}
              maxLength={7}
              autoInsertCommas={true}
              value={
                selectedFee?.appliedFee ? selectedFee?.appliedFee + "" : ""
              }
              maxValue={selectedFee?.feeMax}
              minValue={0.01}
              onChange={onFeeAmountChange}
            />
          ) : null}
          {priceAdjustmentType === MODIFY_CATALOG_RO_FEE ||
          priceAdjustmentType === ADD_CATALOG_RO_FEE ? (
            <ROFeePayerSelectComponent
              selectedFee={selectedFee}
              quoteSummary={quoteSummary}
              setSelectedFee={setSelectedFee}
            />
          ) : null}
          <div className="catalog-fee-separator" />
          {/* <FeeTotalRow
                label="Service price"
                value={getTotalServicePrice()}
                priceClassName={"catalog-fee-total-amount"}
              /> */}
          <FeeTotalRow
            label="Fee amount"
            value={selectedFee?.appliedFee}
            priceClassName={
              selectedFee?.feesType !== FEES_TYPES.DOLLAR &&
              selectedFee?.appliedFee >= selectedFee?.feeMax
                ? "Ferrari500"
                : ""
            }
          />
          {priceAdjustmentType === MODIFY_CATALOG_LINE_FEE ||
          priceAdjustmentType === ADD_CATALOG_LINE_FEE ? (
            <>
              <div className="catalog-fee-separator" />
              <div className="catalog-fee-separator" />
              <FeeTotalRow
                label="Adjusted service price"
                value={getAdjustedPrice()}
                priceClassName="catalog-fee-total-amount"
              />
            </>
          ) : null}
          {selectedFee?.feesType !== FEES_TYPES.DOLLAR &&
          parseFloat(selectedFee?.appliedFee || 0) === selectedFee?.feeMax ? (
            <Alert
              htmlId="warningAlert"
              type="warning"
              className="margin-top-12"
            >
              Maximum fee applied
            </Alert>
          ) : null}
        </>
      ) : (
        <FeeNotSelectedComponent errorMessage={getErrorMessage()} />
      )}
    </div>
  );
};

FeeRenderContentComponent.propTypes = {
  onUpdatePayload: PropTypes.func,
  availableFeeOptions: PropTypes.array,
  service: PropTypes.object,
  selectedFeeModify: PropTypes.object
};

export default FeeRenderContentComponent;
